import ws from '../utils/ws';

/* Actions */

export const modelSetPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: MODEL_SETS_PAGINATED_LIST,
    payload: ws.get(`/model_sets/page/${pageNumber}/rows/${pageSize}`)
  });

export const findModelSets = () => dispatch =>
  dispatch({
    type: FIND_MODEL_SETS,
    payload: ws.get(`/model_sets/`)
  });

export const findModelSet = id => dispatch =>
  dispatch({
    type: FIND_MODEL_SET,
    payload: ws.get(`/model_sets/${id}`)
  });

export const modelSetValueReset = () => dispatch =>
  dispatch({
    type: CREATE_MODEL_SET_VALUE_RESET
  });

export const modelSetValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_MODEL_SET_VALUE_UPDATE,
    payload: values
  });

export const saveModelSet = modelSet => dispatch =>
  dispatch({
    type: SAVE_MODEL_SET,
    payload: ws.post('/model_sets/', modelSet)
  });

export const deleteModelSet = modelSet => dispatch =>
  dispatch({
    type: DELETE_MODEL_SET,
    payload: ws.post(`/model_sets/${modelSet.id}/delete`)
  });

export const editModelSet = id => dispatch =>
  dispatch({
    type: EDIT_MODEL_SET,
    payload: ws.get(`/model_sets/${id}`)
  });

export const createModelSetAddModel = () => dispatch =>
  dispatch({
    type: CREATE_MODEL_SET_ADD_NEW_MODEL
  });

export const modelSetValueModelValueUpdate = (index, values) => dispatch =>
  dispatch({
    type: CREATE_MODEL_SET_MODEL_VALUE_UPDATE,
    payload: {
      index,
      values
    }
  });

export const setCreateModelSetModelSelectionIndex = index => dispatch =>
  dispatch({
    type: SET_CREATE_MODEL_SET_MODEL_SELECTION_INDEX,
    payload: index
  });

export const removeCreateModelSetModel = index => dispatch =>
  dispatch({
    type: REMOVE_CREATE_MODEL_SET_MODEL,
    payload: index
  });

/* Constants */

export const MODEL_SETS_PAGINATED_LIST = 'MODEL_SETS_PAGINATED_LIST';
export const MODEL_SETS_PAGINATED_LIST_PENDING =
  'MODEL_SETS_PAGINATED_LIST_PENDING';
export const MODEL_SETS_PAGINATED_LIST_FULFILLED =
  'MODEL_SETS_PAGINATED_LIST_FULFILLED';

export const FIND_MODEL_SETS = 'FIND_MODEL_SETS';
export const FIND_MODEL_SETS_PENDING = 'FIND_MODEL_SETS_PENDING';
export const FIND_MODEL_SETS_FULFILLED = 'FIND_MODEL_SETS_FULFILLED';

export const FIND_MODEL_SET = 'FIND_MODEL_SET';
export const FIND_MODEL_SET_PENDING = 'FIND_MODEL_SET_PENDING';
export const FIND_MODEL_SET_FULFILLED = 'FIND_MODEL_SET_FULFILLED';

export const SAVE_MODEL_SET = 'SAVE_MODEL_SET';
export const SAVE_MODEL_SET_PENDING = 'SAVE_MODEL_SET_PENDING';
export const SAVE_MODEL_SET_FULFILLED = 'SAVE_MODEL_SET_FULFILLED';

export const DELETE_MODEL_SET = 'DELETE_MODEL_SET';
export const DELETE_MODEL_SET_PENDING = 'DELETE_MODEL_SET_PENDING';
export const DELETE_MODEL_SET_FULFILLED = 'DELETE_MODEL_SET_FULFILLED';

export const EDIT_MODEL_SET = 'EDIT_MODEL_SET';
export const EDIT_MODEL_SET_PENDING = 'EDIT_MODEL_SET_PENDING';
export const EDIT_MODEL_SET_FULFILLED = 'EDIT_MODEL_SET_FULFILLED';

export const CREATE_MODEL_SET_VALUE_RESET = 'CREATE_MODEL_SET_VALUE_RESET';

export const CREATE_MODEL_SET_VALUE_UPDATE = 'CREATE_MODEL_SET_VALUE_UPDATE';

export const CREATE_MODEL_SET_ADD_NEW_MODEL = 'CREATE_MODEL_SET_ADD_NEW_MODEL';

export const CREATE_MODEL_SET_MODEL_VALUE_UPDATE =
  'CREATE_MODEL_SET_MODEL_VALUE_UPDATE';

export const SET_CREATE_MODEL_SET_MODEL_SELECTION_INDEX =
  'SET_CREATE_MODEL_SET_MODEL_SELECTION_INDEX';

export const REMOVE_CREATE_MODEL_SET_MODEL = 'REMOVE_CREATE_MODEL_SET_MODEL';
