import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  TEST_SESSIONS_VIEW,
  TRAINING_SESSIONS_VIEW,
  PARTICIPANTS_VIEW,
  MODEL_CONFIGURATIONS_VIEW,
  CUSTOMERS_VIEW,
  USERS_VIEW,
  TEST_SCENARIOS_VIEW,
  TRAINING_DASHBOARD_VIEW,
  TRAINING_SCENARIOS_VIEW,
  CELL_WALKS_VIEW,
  TEST_SESSION_GROUPS_VIEW,
  TEST_SESSION_ANALYSIS_VIEW
} from 'common/Right';
import { findSessionUser } from 'actions';

const HomeRedirect = () => {
  const dispatch = useDispatch();

  const { sessionUser } = useSelector(state => state.session);

  useEffect(() => {
    let mounted = true;

    if (mounted && !sessionUser) {
      dispatch(findSessionUser());
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (!sessionUser) {
    return null;
  }

  let to;
  if (sessionUser.rights.indexOf(CELL_WALKS_VIEW) > -1) {
    to = '/walks/cell_walks/';
  } else if (sessionUser.rights.indexOf(TEST_SESSIONS_VIEW) > -1) {
    to = '/testing/sessions/';
  } else if (sessionUser.rights.indexOf(TEST_SCENARIOS_VIEW) > -1) {
    to = '/testing/scenarios/';
  } else if (sessionUser.rights.indexOf(TEST_SESSIONS_VIEW) > -1) {
    to = '/testing/sessions/';
  } else if (sessionUser.rights.indexOf(TEST_SESSION_GROUPS_VIEW) > -1) {
    to = '/testing/session_groups/';
  } else if (sessionUser.rights.indexOf(TEST_SESSION_ANALYSIS_VIEW) > -1) {
    to = '/walks/analysis/';
  } else if (sessionUser.rights.indexOf(TRAINING_SCENARIOS_VIEW) > -1) {
    to = '/training/scenarios/';
  } else if (sessionUser.rights.indexOf(TRAINING_SESSIONS_VIEW) > -1) {
    to = '/training/sessions/';
  } else if (sessionUser.rights.indexOf(TRAINING_DASHBOARD_VIEW) > -1) {
    to = '/training/dashboard/';
  } else if (sessionUser.rights.indexOf(PARTICIPANTS_VIEW) > -1) {
    to = '/reference/participants/';
  } else if (sessionUser.rights.indexOf(MODEL_CONFIGURATIONS_VIEW) > -1) {
    to = '/models/model_configurations/';
  } else if (sessionUser.rights.indexOf(CUSTOMERS_VIEW) > -1) {
    to = '/admin/customers/';
  } else if (sessionUser.rights.indexOf(USERS_VIEW) > -1) {
    to = '/admin/users/';
  } else {
    to = '/errors/error-401';
  }

  return <Redirect to={to} />;
};

export default HomeRedirect;
