import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  cellCheckListDefinitionLoading: false,
  cellCheckListDefinition: {
    createdBy: {}
  },
  expandedBuildComponentIds: [],
  searchResultBuildComponentIds: [],
  componentSearch: '',
  entityPhotos: []
};

const cellCheckListDefinitionDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.TOGGLE_CELL_CHECK_LIST_COMPONENT_EXPANDED: {
      const componentId = action.payload;
      const expandedBuildComponentIds = state.expandedBuildComponentIds.slice();

      if (expandedBuildComponentIds.indexOf(componentId) > -1) {
        expandedBuildComponentIds.splice(
          expandedBuildComponentIds.indexOf(componentId),
          1
        );
      } else {
        expandedBuildComponentIds.push(componentId);
      }

      return {
        ...state,
        expandedBuildComponentIds: expandedBuildComponentIds
      };
    }

    case actionTypes.TOGGLE_CELL_CHECK_LIST_COMPONENTS_EXPANDED: {
      return {
        ...state,
        expandedBuildComponentIds: action.payload
      };
    }

    case actionTypes.SET_SEARCH_RESULT_CELL_CHECK_LIST_COMPONENTS: {
      return {
        ...state,
        searchResultBuildComponentIds: action.payload
      };
    }

    case actionTypes.SET_CELL_CHECK_LIST_COMPONENT_SEARCH: {
      return {
        ...state,
        componentSearch: action.payload
      };
    }

    case actionTypes.FIND_CELL_CHECK_LIST_DEFINITION_PENDING: {
      return {
        ...initialState,
        cellCheckListDefinitionLoading: true
      };
    }

    case actionTypes.FIND_CELL_CHECK_LIST_DEFINITION_FULFILLED: {
      return {
        ...state,
        cellCheckListDefinitionLoading: false,
        cellCheckListDefinition: action.payload.data.result
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        cellCheckListDefinitionLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL_CHECK_LIST_DEFINITION &&
        (entityPhoto.entityId === state.cellCheckListDefinition.id ||
          entityPhoto.entityTempId === state.cellCheckListDefinition.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL_CHECK_LIST_DEFINITION &&
        (entityPhoto.entityId === state.cellCheckListDefinition.id ||
          entityPhoto.entityTempId === state.cellCheckListDefinition.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL_CHECK_LIST_DEFINITION &&
        (entityPhoto.entityId === state.cellCheckListDefinition.id ||
          entityPhoto.entityTempId === state.cellCheckListDefinition.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    default: {
      return state;
    }
  }
};

export default cellCheckListDefinitionDetailsReducer;
