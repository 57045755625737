/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_SESSION_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const EventSessionStatus = props => {
  const { sessionEvent, ...rest } = props;
  const status = !sessionEvent.event ? 'UNLINKED' : sessionEvent.status;

  return (
    <div>
      <Label
        style={{ width: 70 }}
        color={
          status === 'UNLINKED'
            ? colors.blue[600]
            : status !== 'INCLUDED'
            ? colors.red[600]
            : colors.blueGrey[600]
        }>
        {EVENT_SESSION_STATUSES[status]}
      </Label>
    </div>
  );
};

EventSessionStatus.propTypes = {
  sessionEvent: PropTypes.object.isRequired
};

export default EventSessionStatus;
