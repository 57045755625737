import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const ConfirmAction = props => {
  const { open, onClose, onConfirm, message, ...rest } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root)}>
        <CardContent>
          <Typography className={classes.field} component="p">
            {message}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button color="primary" onClick={onConfirm} variant="contained">
            Confirm
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ConfirmAction.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  entityType: PropTypes.string
};

ConfirmAction.defaultProps = {
  open: false
};

export default ConfirmAction;
