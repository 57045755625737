import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  trainingCompatibilityGroup: {
    createdBy: {}
  }
};

const trainingCompatibilityGroupDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.FIND_TRAINING_COMPATIBILITY_GROUP_PENDING: {
      return {
        ...initialState,
        loading: true
      };
    }

    case actionTypes.FIND_TRAINING_COMPATIBILITY_GROUP_FULFILLED: {
      return {
        ...state,
        loading: false,
        trainingCompatibilityGroup: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default trainingCompatibilityGroupDetailsReducer;
