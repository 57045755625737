import * as actionTypes from 'actions';

const initialState = {
  open: false,
  openConfirmDelete: false,
  deleteMessage: null,
  saved: false,
  deleted: false,
  values: {
    version: '',
    description: '',
    jiraLink: ''
  },
  validationMessages: {}
};

const cellComponentVersionCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CREATE_CELL_COMPONENT_VERSION_OPEN: {
      return {
        ...state,
        open: action.payload
      };
    }

    case actionTypes.SAVE_CELL_COMPONENT_VERSION_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CELL_COMPONENT_VERSION_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.SET_CONFIRM_DELETE_CELL_COMPONENT_VERSION_OPEN: {
      return {
        ...state,
        openConfirmDelete: action.payload
      };
    }

    case actionTypes.EDIT_CELL_COMPONENT_VERSION_FULFILLED: {
      return {
        ...state,
        values: action.payload.data.result,
        open: action.payload.data.success
      };
    }

    case actionTypes.CREATE_CELL_COMPONENT_VERSION_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_CELL_COMPONENT_VERSION_VALUE_UPDATE: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default cellComponentVersionCreateReducer;
