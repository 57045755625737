import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  CellConfigurationProperties,
  CellConfigurationRadars,
  Alert
} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetUploadCellConfiguration,
  uploadCellConfigurationFile,
  verifyUploadedConfigurationFile
} from 'actions';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Button,
  CardHeader,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1200,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  radars: {
    marginTop: theme.spacing(2)
  },
  radarContent: {
    padding: 0,
    paddingBottom: '0!important'
  }
}));

const UploadCellConfiguration = props => {
  const { cell, className } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    fileUploaded,
    verificationConfiguration,
    validationMessages,
    configurationFileUploading
  } = useSelector(state => state.uploadCellConfiguration);

  const handleClose = () => {
    dispatch(resetUploadCellConfiguration());
  };

  const handleFileChange = event => {
    event.persist && event.persist();
    const file = event.target.files[0];

    if (file) {
      dispatch(uploadCellConfigurationFile(cell.id, file));
    }

    event.target.value = null;
  };

  const handleVerify = () => {
    dispatch(verifyUploadedConfigurationFile(verificationConfiguration.id));
  };

  return (
    <>
      <Button
        className={className}
        color="primary"
        size="small"
        variant="outlined"
        component="label"
        disabled={fileUploaded}>
        Upload
        <input
          type="file"
          name="file"
          onChange={handleFileChange}
          accept=".json"
          hidden
        />
        {configurationFileUploading && (
          <CircularProgress className={classes.fileInputProgress} size={10} />
        )}
      </Button>

      {fileUploaded && (
        <Modal onClose={handleClose} open={true}>
          <Card className={classes.root}>
            <CardHeader title="Upload Cell Config" />
            <Divider />
            <CardContent className={classes.content}>
              {validationMessages.configurationFileContents ? (
                <Alert message="Unable to parse JSON from uploaded file" />
              ) : (
                <>
                  <Card>
                    <CardHeader title="Properties" />
                    <Divider />
                    <CardContent className={classes.content}>
                      <CellConfigurationProperties
                        cellConfiguration={verificationConfiguration}
                        excludedProperties={['Radars']}
                      />
                    </CardContent>
                  </Card>
                  <Card className={classes.radars}>
                    <CardHeader title="Radars" />
                    <Divider />
                    <CardContent className={classes.radarContent}>
                      <CellConfigurationRadars
                        cellConfiguration={verificationConfiguration}
                      />
                    </CardContent>
                  </Card>
                </>
              )}
            </CardContent>
            <CardActions className={classes.actions}>
              <Button
                onClick={handleClose}
                variant="contained"
                // disabled={processing}
              >
                Cancel
              </Button>
              {verificationConfiguration && (
                <Button
                  onClick={handleVerify}
                  variant="contained"
                  color="primary"
                  // disabled={processing}
                >
                  Confirm
                </Button>
              )}
            </CardActions>
          </Card>
        </Modal>
      )}
    </>
  );
};

UploadCellConfiguration.displayName = 'UploadCellConfig';

UploadCellConfiguration.propTypes = {
  cell: PropTypes.object
};

UploadCellConfiguration.defaultProps = {
  open: false
};

export default UploadCellConfiguration;
