/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import {
  SOFTWARE_PACKAGE_STATUSES,
  SOFTWARE_PACKAGE_STATUS_COLORS
} from 'common/constants';
import { Label } from 'components';

const SoftwarePackageStatus = props => {
  const { softwarePackage, ...rest } = props;

  return (
    <div>
      {softwarePackage && softwarePackage.status && (
        <Label
          {...rest}
          color={SOFTWARE_PACKAGE_STATUS_COLORS[softwarePackage.status]}>
          {SOFTWARE_PACKAGE_STATUSES[softwarePackage.status]}
        </Label>
      )}
    </div>
  );
};

SoftwarePackageStatus.propTypes = {
  cell: PropTypes.object
};

export default SoftwarePackageStatus;
