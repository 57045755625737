import * as actionTypes from 'actions';

const initialState = {
  eventDataSet: {},
  participantSummary: {},
  eventCounts: [],
  objectTypes: [],
  objectOfInterestSubjectAreas: [],
  overview: {},
  eventUserValidationCounts: [],
  scenarioEventCounts: [],
  loading: false,
  createdTime: null,
  periodEventCounts: [],
  eventDataSets: []
};

const testingDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_LATEST_TESTING_SESSION_SUMMARY_PENDING: {
      return {
        ...initialState,
        eventDataSets: state.eventDataSets,
        loading: true
      };
    }

    case actionTypes.FIND_LATEST_TESTING_SESSION_SUMMARY_FULFILLED: {
      const summary = action.payload.data.result;

      return {
        ...state,
        ...action.payload.data.result,
        eventDataSets: state.eventDataSets,
        loading: false
      };
    }

    case actionTypes.CREATE_TESTING_SESSION_SUMMARY_PENDING: {
      return {
        ...state,
        loading: true,
        creating: true
      };
    }

    case actionTypes.CREATE_TESTING_SESSION_SUMMARY_FULFILLED: {
      const summary = action.payload.data.result;

      return {
        ...state,
        ...action.payload.data.result,
        eventDataSets: state.eventDataSets,
        loading: false,
        creating: false
      };
    }

    case actionTypes.FIND_EVENT_DATA_SETS_FULFILLED: {
      return {
        ...state,
        eventDataSets: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default testingDashboardReducer;
