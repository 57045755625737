import ws from '../utils/ws';

/* Actions */

export const createModelAnalysisComparison = (
  sessionSource,
  sourceId
) => dispatch =>
  dispatch({
    type: CREATE_MODEL_ANALYSIS_COMPARISON,
    payload: ws.post(`/model_analysis_comparisons/create`, {
      sessionSource,
      sourceId,
      subModel: 0
    })
  });

export const findLatestModelAnalysisComparison = (
  sessionSource,
  sourceId
) => dispatch =>
  dispatch({
    type: FIND_LATEST_MODEL_ANALYSIS_COMPARISON,
    payload: ws.post(`/model_analysis_comparisons/latest`, {
      sessionSource,
      sourceId
    })
  });

export const findLatestModelAnalysisComparisonSilently = (
  sessionSource,
  sourceId
) => dispatch =>
  dispatch({
    type: FIND_LATEST_MODEL_ANALYSIS_COMPARISON_SILENTLY,
    payload: ws.post(`/model_analysis_comparisons/latest`, {
      sessionSource,
      sourceId
    })
  });

export const findModelAnalysisComparisonRocValues = (
  modelAnalysisComparisonId,
  modelConfigurationId
) => dispatch =>
  dispatch({
    type: FIND_MODEL_ANALYSIS_COMPARISON_ROC_VALUES,
    payload: ws.get(
      `/model_analysis_comparisons/${modelAnalysisComparisonId}/model/${modelConfigurationId}/roc_values`
    )
  });

export const findModelAnalysisComparisonPrecisionRecallValues = (
  modelAnalysisComparisonId,
  modelConfigurationId
) => dispatch =>
  dispatch({
    type: FIND_MODEL_ANALYSIS_COMPARISON_PRECISION_RECALL_VALUES,
    payload: ws.get(
      `/model_analysis_comparisons/${modelAnalysisComparisonId}/model/${modelConfigurationId}/precision_recall_values`
    )
  });

export const updateModelAnalysisComparisonWeights = weights => dispatch =>
  dispatch({
    type: UPDATE_MODEL_ANALYSIS_COMPARISON_WEIGHTS,
    payload: weights
  });

export const applyModelAnalysisComparisonWeights = (
  modelAnalysisComparison,
  weights
) => dispatch =>
  dispatch({
    type: APPLY_MODEL_ANALYSIS_COMPARISON_WEIGHTS,
    payload: ws.post(`/model_analysis_comparisons/apply_weights`, {
      modelAnalysisComparisonId: modelAnalysisComparison.id,
      ...weights
    })
  });

/* Constants */

export const CREATE_MODEL_ANALYSIS_COMPARISON =
  'CREATE_MODEL_ANALYSIS_COMPARISON';
export const CREATE_MODEL_ANALYSIS_COMPARISON_PENDING =
  'CREATE_MODEL_ANALYSIS_COMPARISON_PENDING';
export const CREATE_MODEL_ANALYSIS_COMPARISON_FULFILLED =
  'CREATE_MODEL_ANALYSIS_COMPARISON_FULFILLED';

export const FIND_LATEST_MODEL_ANALYSIS_COMPARISON =
  'FIND_LATEST_MODEL_ANALYSIS_COMPARISON';
export const FIND_LATEST_MODEL_ANALYSIS_COMPARISON_PENDING =
  'FIND_LATEST_MODEL_ANALYSIS_COMPARISON_PENDING';
export const FIND_LATEST_MODEL_ANALYSIS_COMPARISON_FULFILLED =
  'FIND_LATEST_MODEL_ANALYSIS_COMPARISON_FULFILLED';

export const FIND_LATEST_MODEL_ANALYSIS_COMPARISON_SILENTLY =
  'FIND_LATEST_MODEL_ANALYSIS_COMPARISON_SILENTLY';
export const FIND_LATEST_MODEL_ANALYSIS_COMPARISON_SILENTLY_PENDING =
  'FIND_LATEST_MODEL_ANALYSIS_COMPARISON_SILENTLY_PENDING';
export const FIND_LATEST_MODEL_ANALYSIS_COMPARISON_SILENTLY_FULFILLED =
  'FIND_LATEST_MODEL_ANALYSIS_COMPARISON_SILENTLY_FULFILLED';

export const MODEL_ANALYSIS_COMPARISON_MODEL_CALCULATED =
  'MODEL_ANALYSIS_COMPARISON_MODEL_CALCULATED';

export const UPDATE_MODEL_ANALYSIS_COMPARISON_WEIGHTS =
  'UPDATE_MODEL_ANALYSIS_COMPARISON_WEIGHTS';

export const APPLY_MODEL_ANALYSIS_COMPARISON_WEIGHTS =
  'APPLY_MODEL_ANALYSIS_COMPARISON_WEIGHTS';
export const APPLY_MODEL_ANALYSIS_COMPARISON_WEIGHTS_PENDING =
  'APPLY_MODEL_ANALYSIS_COMPARISON_WEIGHTS_PENDING';
export const APPLY_MODEL_ANALYSIS_COMPARISON_WEIGHTS_FULFILLED =
  'APPLY_MODEL_ANALYSIS_COMPARISON_WEIGHTS_FULFILLED';

export const FIND_MODEL_ANALYSIS_COMPARISON_ROC_VALUES =
  'FIND_MODEL_ANALYSIS_COMPARISON_ROC_VALUES';
export const FIND_MODEL_ANALYSIS_COMPARISON_ROC_VALUES_PENDING =
  'FIND_MODEL_ANALYSIS_COMPARISON_ROC_VALUES_PENDING';
export const FIND_MODEL_ANALYSIS_COMPARISON_ROC_VALUES_FULFILLED =
  'FIND_MODEL_ANALYSIS_COMPARISON_ROC_VALUES_FULFILLED';

export const FIND_MODEL_ANALYSIS_COMPARISON_PRECISION_RECALL_VALUES =
  'FIND_MODEL_ANALYSIS_COMPARISON_PRECISION_RECALL_VALUES';
export const FIND_MODEL_ANALYSIS_COMPARISON_PRECISION_RECALL_VALUES_PENDING =
  'FIND_MODEL_ANALYSIS_COMPARISON_PRECISION_RECALL_VALUES_PENDING';
export const FIND_MODEL_ANALYSIS_COMPARISON_PRECISION_RECALL_VALUES_FULFILLED =
  'FIND_MODEL_ANALYSIS_COMPARISON_PRECISION_RECALL_VALUES_FULFILLED';
