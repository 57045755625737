import * as actionTypes from 'actions';

const initialState = {
  training: {
    loading: false
  },
  webEncodingVideos: false,
  webVideosLoading: false,
  webVideosLoaded: false,
  videos: []
};

const trainingVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_TRAINING_VIDEO_TRAINING_PENDING: {
      return {
        ...initialState,
        training: {
          ...state.training,
          loading: true
        }
      };
    }

    case actionTypes.FIND_TRAINING_VIDEO_TRAINING_FULFILLED: {
      return {
        ...state,
        training: {
          ...action.payload.data.result,
          loading: false
        }
      };
    }

    case actionTypes.ENCODE_TRAINING_WEB_VIDEOS_PENDING: {
      return {
        ...state,
        webEncodingVideos: true
      };
    }

    case actionTypes.ENCODE_TRAINING_WEB_VIDEOS_FULFILLED: {
      return {
        ...state,
        webEncodingVideos: false,
        training: {
          ...state.training,
          webVideosEncoded: true
        }
      };
    }

    case actionTypes.FIND_TRAINING_WEB_VIDEOS_PENDING: {
      return {
        ...state,
        videos: [],
        webVideosLoading: true
      };
    }

    case actionTypes.FIND_TRAINING_WEB_VIDEOS_FULFILLED: {
      return {
        ...state,
        videos: action.payload.data.result,
        webVideosLoading: false,
        webVideosLoaded: true
      };
    }

    default: {
      return state;
    }
  }
};

export default trainingVideoReducer;
