import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat } from 'components';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  LinearProgress,
  Checkbox
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  ENTRY_FEET,
  SCORE_SIDES,
  ANALYTICS_OBJECT_LOCATIONS
} from 'common/constants';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1150
  },
  methodCell: {
    width: 100
  },
  statusCell: {
    width: 64
  },
  entryFoot: {
    width: 200
  },
  scoreSide: {
    width: 200
  },
  checkbox: {
    marginLeft: -10
  }
}));

const AccuracyOverviewAnalysisCard = props => {
  const {
    className,
    analysis,
    onSummaryObjectLocationChange,
    summaryExcludedObjectLocations,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardHeader title="Accuracy" />
        <Divider />
        <CardContent className={classes.content}>
          {analysis.loading && <LinearProgress />}
          {analysis.results && (
            <PerfectScrollbar>
              {/* <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} />
                  <TableCell colSpan={20}>Object Placement</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Entry</TableCell>
                  {analysis.objectLocations.map(objectLocation => (
                    <TableCell key={objectLocation}>{ANALYTICS_OBJECT_LOCATIONS[objectLocation]}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Include In Summary</TableCell>
                  {analysis.objectLocations.map(objectLocation => (
                    <TableCell key={objectLocation}>
                      <Checkbox
                        className={classes.checkbox}
                        size="small"
                        checked={summaryExcludedObjectLocations.indexOf(objectLocation) === -1}
                        color="primary"
                        onChange={(e) => {onSummaryObjectLocationChange(objectLocation, e.target.checked)}}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>

                {Object.keys(analysis.results).map((entryFoot) => (
                  <React.Fragment key={entryFoot}>
                    {Object.keys(analysis.results[entryFoot]).map((scoreSide, iScoreSide) => (
                      <TableRow key={scoreSide + iScoreSide} selected={iScoreSide === 0}>
                        {iScoreSide === 0 && <TableCell className={classes.entryFoot} rowSpan={Object.keys(analysis.results[entryFoot]).length}>{ENTRY_FEET[entryFoot]}</TableCell>}
                        <TableCell className={classes.scoreSide}>{SCORE_SIDES[scoreSide]}</TableCell>
                        {analysis.objectLocations.map(objectLocation => (
                          <TableCell key={objectLocation}><NumberFormat percentage value={analysis.results[entryFoot][scoreSide].objectLocationResults[objectLocation]} /></TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table> */}
            </PerfectScrollbar>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

AccuracyOverviewAnalysisCard.propTypes = {
  className: PropTypes.string,
  analysis: PropTypes.object.isRequired
};

export default AccuracyOverviewAnalysisCard;
