import ws from '../utils/ws';

export const findUsers = () => dispatch =>
  dispatch({
    type: FIND_USERS,
    payload: ws.get('/xonar_api/v1/users')
  });

export const findSessionUser = () => dispatch =>
  dispatch({
    type: FIND_SESSION_USER,
    payload: ws.get('/profile/')
  });

export const userPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: USERS_PAGINATED_LIST,
    payload: ws.get(`/users/page/${pageNumber}/rows/${pageSize}`)
  });

export const FIND_USERS = 'FIND_USERS';
export const FIND_USERS_FULFILLED = 'FIND_USERS_FULFILLED';

export const FIND_SESSION_USER = 'FIND_SESSION_USER';
export const FIND_SESSION_USER_PENDING = 'FIND_SESSION_USER_PENDING';
export const FIND_SESSION_USER_FULFILLED = 'FIND_SESSION_USER_FULFILLED';

export const USERS_PAGINATED_LIST = 'USERS_PAGINATED_LIST';
export const USERS_PAGINATED_LIST_PENDING = 'USERS_PAGINATED_LIST_PENDING';
export const USERS_PAGINATED_LIST_FULFILLED = 'USERS_PAGINATED_LIST_FULFILLED';
