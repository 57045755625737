import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  cellModelVersionLoading: false,
  cellModelVersion: {
    createdBy: {}
  },
  entityPhotos: []
};

const cellModelVersionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_CELL_MODEL_VERSION_PENDING: {
      return {
        ...initialState,
        cellModelVersionLoading: true
      };
    }

    case actionTypes.FIND_CELL_MODEL_VERSION_FULFILLED: {
      return {
        ...state,
        cellModelVersionLoading: false,
        cellModelVersion: action.payload.data.result
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        cellModelVersionLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL_MODEL_VERSION &&
        (entityPhoto.entityId === state.cellModelVersion.id ||
          entityPhoto.entityTempId === state.cellModelVersion.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL_MODEL_VERSION &&
        (entityPhoto.entityId === state.cellModelVersion.id ||
          entityPhoto.entityTempId === state.cellModelVersion.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL_MODEL_VERSION &&
        (entityPhoto.entityId === state.cellModelVersion.id ||
          entityPhoto.entityTempId === state.cellModelVersion.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    default: {
      return state;
    }
  }
};

export default cellModelVersionDetailsReducer;
