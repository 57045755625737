import ws from '../utils/ws';

/* Actions */

export const masterVersionPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: MASTER_VERSIONS_PAGINATED_LIST,
    payload: ws.get(`/master_versions/page/${pageNumber}/rows/${pageSize}`)
  });

export const masterVersionValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_MASTER_VERSION_VALUE_UPDATE,
    payload: values
  });

export const masterVersionValueReset = () => dispatch =>
  dispatch({
    type: CREATE_MASTER_VERSION_VALUE_RESET
  });

export const setMasterVersionComponentExcluded = (
  cellComponentId,
  excluded
) => dispatch =>
  dispatch({
    type: SET_MASTER_VERSION_COMPONENT_EXCLUDED,
    payload: {
      cellComponentId,
      excluded
    }
  });

export const setMasterVersionDetailsAnalysisBias = bias => dispatch =>
  dispatch({
    type: SET_MASTER_VERSION_DETAILS_ANALYSIS_BIAS,
    payload: bias
  });

export const saveMasterVersion = (
  masterVersion,
  excludedCellComponentIds,
  cellComponentVersions
) => dispatch =>
  dispatch({
    type: SAVE_MASTER_VERSION,
    payload: ws.post('/master_versions/', {
      masterVersion,
      excludedCellComponentIds,
      cellComponentVersions
    })
  });

export const deleteMasterVersion = masterVersion => dispatch =>
  dispatch({
    type: DELETE_MASTER_VERSION,
    payload: ws.post(`/master_versions/${masterVersion.id}/delete`)
  });

export const editMasterVersion = id => dispatch =>
  dispatch({
    type: EDIT_MASTER_VERSION,
    payload: ws.get(`/master_versions/${id}`)
  });

export const findMasterVersionComponentVersions = id => dispatch =>
  dispatch({
    type: FIND_MASTER_VERSION_COMPONENT_VERSIONS,
    payload: ws.get(`/master_versions/${id}/components/versions/`)
  });

export const findMasterVersions = () => dispatch =>
  dispatch({
    type: FIND_MASTER_VERSIONS,
    payload: ws.get('/master_versions/')
  });

export const findMasterVersion = id => dispatch =>
  dispatch({
    type: FIND_MASTER_VERSION,
    payload: ws.get(`/master_versions/${id}`)
  });

export const findMasterVersionTestSessions = id => dispatch =>
  dispatch({
    type: FIND_MASTER_VERSION_TEST_SESSIONS,
    payload: ws.get(`/master_versions/${id}/sessions/`)
  });

export const findMasterVersionAnalysisOverview = (
  id,
  analysisBias
) => dispatch =>
  dispatch({
    type: FIND_MASTER_VERSION_ANALYSIS_OVERVIEW,
    payload: ws.get(
      `/master_versions/${id}/analysis/overview?bias=${analysisBias}`
    )
  });

export const setMasterVersionStatus = (id, status) => dispatch =>
  dispatch({
    type: SET_MASTER_VERSION_STATUS,
    payload: ws.post(`/master_versions/${id}/status`, { status })
  });

export const clearMasterVersionDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_MASTER_VERSION_DELETE_MESSAGE
  });

export const setMasterVersionCellComponentVersion = (
  cellComponent,
  version
) => dispatch =>
  dispatch({
    type: SET_MASTER_VERSION_CELL_COMPONENT_VERSION,
    payload: {
      cellComponent,
      version
    }
  });

/* Constants */

export const MASTER_VERSIONS_PAGINATED_LIST = 'MASTER_VERSIONS_PAGINATED_LIST';
export const MASTER_VERSIONS_PAGINATED_LIST_PENDING =
  'MASTER_VERSIONS_PAGINATED_LIST_PENDING';
export const MASTER_VERSIONS_PAGINATED_LIST_FULFILLED =
  'MASTER_VERSIONS_PAGINATED_LIST_FULFILLED';

export const CREATE_MASTER_VERSION_VALUE_UPDATE =
  'CREATE_MASTER_VERSIONS_VALUE_UPDATE';
export const CREATE_MASTER_VERSION_VALUE_RESET =
  'CREATE_MASTER_VERSION_VALUE_RESET';

export const SET_MASTER_VERSION_COMPONENT_EXCLUDED =
  'SET_MASTER_VERSION_COMPONENT_EXCLUDED';

export const SAVE_MASTER_VERSION = 'SAVE_MASTER_VERSION';
export const SAVE_MASTER_VERSION_FULFILLED = 'SAVE_MASTER_VERSION_FULFILLED';

export const DELETE_MASTER_VERSION = 'DELETE_MASTER_VERSION';
export const DELETE_MASTER_VERSION_PENDING = 'DELETE_MASTER_VERSION_PENDING';
export const DELETE_MASTER_VERSION_FULFILLED =
  'DELETE_MASTER_VERSION_FULFILLED';

export const EDIT_MASTER_VERSION = 'EDIT_MASTER_VERSION';
export const EDIT_MASTER_VERSION_FULFILLED = 'EDIT_MASTER_VERSION_FULFILLED';

export const FIND_MASTER_VERSION_COMPONENT_VERSIONS =
  'FIND_MASTER_VERSION_COMPONENT_VERSIONS';
export const FIND_MASTER_VERSION_COMPONENT_VERSIONS_PENDING =
  'FIND_MASTER_VERSION_COMPONENT_VERSIONS_PENDING';
export const FIND_MASTER_VERSION_COMPONENT_VERSIONS_FULFILLED =
  'FIND_MASTER_VERSION_COMPONENT_VERSIONS_FULFILLED';

export const FIND_MASTER_VERSIONS = 'FIND_MASTER_VERSIONS';
export const FIND_MASTER_VERSIONS_PENDING = 'FIND_MASTER_VERSIONS_PENDING';
export const FIND_MASTER_VERSIONS_FULFILLED = 'FIND_MASTER_VERSIONS_FULFILLED';

export const FIND_MASTER_VERSION_TEST_SESSIONS =
  'FIND_MASTER_VERSION_TEST_SESSIONS';
export const FIND_MASTER_VERSION_TEST_SESSIONS_PENDING =
  'FIND_MASTER_VERSION_TEST_SESSIONS_PENDING';
export const FIND_MASTER_VERSION_TEST_SESSIONS_FULFILLED =
  'FIND_MASTER_VERSION_TEST_SESSIONS_FULFILLED';

export const FIND_MASTER_VERSION_ANALYSIS_OVERVIEW =
  'FIND_MASTER_VERSION_ANALYSIS_OVERVIEW';
export const FIND_MASTER_VERSION_ANALYSIS_OVERVIEW_PENDING =
  'FIND_MASTER_VERSION_ANALYSIS_OVERVIEW_PENDING';
export const FIND_MASTER_VERSION_ANALYSIS_OVERVIEW_FULFILLED =
  'FIND_MASTER_VERSION_ANALYSIS_OVERVIEW_FULFILLED';

export const FIND_MASTER_VERSION = 'FIND_MASTER_VERSION';
export const FIND_MASTER_VERSION_PENDING = 'FIND_MASTER_VERSION_PENDING';
export const FIND_MASTER_VERSION_FULFILLED = 'FIND_MASTER_VERSION_FULFILLED';

export const CLEAR_MASTER_VERSION_DELETE_MESSAGE =
  'CLEAR_MASTER_VERSION_DELETE_MESSAGE';

export const SET_MASTER_VERSION_CELL_COMPONENT_VERSION =
  'SET_MASTER_VERSION_CELL_COMPONENT_VERSION';

export const SET_MASTER_VERSION_DETAILS_ANALYSIS_BIAS =
  'SET_MASTER_VERSION_DETAILS_ANALYSIS_BIAS';

export const SET_MASTER_VERSION_STATUS = 'SET_MASTER_VERSION_STATUS';
export const SET_MASTER_VERSION_STATUS_PENDING =
  'SET_MASTER_VERSION_STATUS_PENDING';
export const SET_MASTER_VERSION_STATUS_FULFILLED =
  'SET_MASTER_VERSION_STATUS_FULFILLED';
