import ws from '../utils/ws';

/* Actions */

export const cellBuildDefinitionPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: CELL_BUILD_DEFINITIONS_PAGINATED_LIST,
    payload: ws.get(
      `/cell_build_definitions/page/${pageNumber}/rows/${pageSize}`
    )
  });

export const cellBuildDefinitionValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CELL_BUILD_DEFINITION_VALUE_UPDATE,
    payload: values
  });

export const cellBuildDefinitionValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CELL_BUILD_DEFINITION_VALUE_RESET
  });

export const toggleCellBuildComponentExpanded = buildComponentGuid => dispatch =>
  dispatch({
    type: TOGGLE_CELL_BUILD_COMPONENT_EXPANDED,
    payload: buildComponentGuid
  });

export const toggleCellBuildComponentsExpanded = buildComponentGuids => dispatch =>
  dispatch({
    type: TOGGLE_CELL_BUILD_COMPONENTS_EXPANDED,
    payload: buildComponentGuids
  });

export const setCellBuildComponentSearch = search => dispatch =>
  dispatch({
    type: SET_CELL_BUILD_COMPONENT_SEARCH,
    payload: search
  });

export const setSearchResultCellBuildComponents = buildComponentGuids => dispatch =>
  dispatch({
    type: SET_SEARCH_RESULT_CELL_BUILD_COMPONENTS,
    payload: buildComponentGuids
  });

export const setCreateBuildDefinitionEditComponent = buildComponent => dispatch =>
  dispatch({
    type: SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT,
    payload: buildComponent
  });

export const setCreateChildBuildDefinitionComponent = parentBuildComponent => dispatch =>
  dispatch({
    type: SET_CREATE_CHILD_BUILD_DEFINITION_COMPONENT,
    payload: parentBuildComponent
  });

export const setConfirmDeleteBuildDefinitionComponent = buildComponent => dispatch =>
  dispatch({
    type: SET_CONFIRM_DELETE_BUILD_DEFINITION_COMPONENT,
    payload: buildComponent
  });

export const setCreateCellBuildDefinitionOpen = open => dispatch =>
  dispatch({
    type: SET_CREATE_CELL_BUILD_DEFINITION_OPEN,
    payload: open
  });

export const setConfirmDeleteCellBuildDefinition = cellBuildDefinition => dispatch =>
  dispatch({
    type: SET_CONFIRM_DELETE_CELL_BUILD_DEFINITION,
    payload: cellBuildDefinition
  });

export const setCreateCheckListDefinitionEditCheckListItemGroupFieldValue = (
  field,
  value
) => dispatch =>
  dispatch({
    type: SET_CREATE_CHECK_LIST_DEFINITION_EDIT_CHECK_LIST_ITEM_GROUP_FIELD_VALUE,
    payload: {
      field,
      value
    }
  });

export const setCreateCheckListDefinitionEditCheckListItemFieldValue = (
  field,
  value
) => dispatch =>
  dispatch({
    type: SET_CREATE_CHECK_LIST_DEFINITION_EDIT_CHECK_LIST_ITEM_FIELD_VALUE,
    payload: {
      field,
      value
    }
  });

export const setCreateCellBuildComponentFieldValue = (
  field,
  value
) => dispatch =>
  dispatch({
    type: SET_CREATE_BUILD_DEFINITION_FIELD_VALUE,
    payload: {
      field,
      value
    }
  });

export const findLatestCellBuildDefinitionVersions = () => dispatch =>
  dispatch({
    type: FIND_LATEST_CELL_BUILD_DEFINITION_VERSIONS,
    payload: ws.get('/cell_build_definitions/latest_versions/')
  });

export const createNewCellBuildDefinition = cellBuildDefinition => dispatch =>
  dispatch({
    type: CREATE_CELL_BUILD_DEFINITION,
    payload: ws.post(`/cell_build_definitions/`, cellBuildDefinition)
  });

export const saveCellBuildDefinitionComponent = (
  cellBuildDefinitionId,
  buildComponent
) => dispatch =>
  dispatch({
    type: SAVE_CELL_BUILD_DEFINITION_COMPONENT,
    payload: ws.post(
      `/cell_build_definitions/${cellBuildDefinitionId}/components/`,
      buildComponent
    )
  });

export const deleteBuildDefinitionComponent = (
  cellBuildDefinitionId,
  buildComponent
) => dispatch =>
  dispatch({
    type: DELETE_CELL_BUILD_DEFINITION_COMPONENT,
    payload: ws.post(
      `/cell_build_definitions/${cellBuildDefinitionId}/components/delete`,
      buildComponent
    )
  });

export const saveCellBuildDefinition = cellBuildDefinition => dispatch =>
  dispatch({
    type: SAVE_CELL_BUILD_DEFINITION,
    payload: ws.post('/cell_build_definitions/', cellBuildDefinition)
  });

export const deleteCellBuildDefinition = cellBuildDefinition => dispatch =>
  dispatch({
    type: DELETE_CELL_BUILD_DEFINITION,
    payload: ws.post(`/cell_build_definitions/${cellBuildDefinition.id}/delete`)
  });

export const editCellBuildDefinition = id => dispatch =>
  dispatch({
    type: EDIT_CELL_BUILD_DEFINITION,
    payload: ws.get(`/cell_build_definitions/${id}`)
  });

export const findCellBuildDefinitions = () => dispatch =>
  dispatch({
    type: FIND_CELL_BUILD_DEFINITIONS,
    payload: ws.get('/cell_build_definitions/')
  });

export const findCellBuildDefinition = id => dispatch =>
  dispatch({
    type: FIND_CELL_BUILD_DEFINITION,
    payload: ws.get(`/cell_build_definitions/${id}`)
  });

export const clearCellBuildDefinitionDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_CELL_BUILD_DEFINITION_DELETE_MESSAGE
  });

/* Constants */

export const CELL_BUILD_DEFINITIONS_PAGINATED_LIST =
  'CELL_BUILD_DEFINITIONS_PAGINATED_LIST';
export const CELL_BUILD_DEFINITIONS_PAGINATED_LIST_PENDING =
  'CELL_BUILD_DEFINITIONS_PAGINATED_LIST_PENDING';
export const CELL_BUILD_DEFINITIONS_PAGINATED_LIST_FULFILLED =
  'CELL_BUILD_DEFINITIONS_PAGINATED_LIST_FULFILLED';

export const CREATE_CELL_BUILD_DEFINITION_VALUE_UPDATE =
  'CREATE_CELL_BUILD_DEFINITIONS_VALUE_UPDATE';
export const CREATE_CELL_BUILD_DEFINITION_VALUE_RESET =
  'CREATE_CELL_BUILD_DEFINITION_VALUE_RESET';

export const SAVE_CELL_BUILD_DEFINITION = 'SAVE_CELL_BUILD_DEFINITION';
export const SAVE_CELL_BUILD_DEFINITION_FULFILLED =
  'SAVE_CELL_BUILD_DEFINITION_FULFILLED';

export const DELETE_CELL_BUILD_DEFINITION = 'DELETE_CELL_BUILD_DEFINITION';
export const DELETE_CELL_BUILD_DEFINITION_PENDING =
  'DELETE_CELL_BUILD_DEFINITION_PENDING';
export const DELETE_CELL_BUILD_DEFINITION_FULFILLED =
  'DELETE_CELL_BUILD_DEFINITION_FULFILLED';

export const EDIT_CELL_BUILD_DEFINITION = 'EDIT_CELL_BUILD_DEFINITION';
export const EDIT_CELL_BUILD_DEFINITION_FULFILLED =
  'EDIT_CELL_BUILD_DEFINITION_FULFILLED';

export const FIND_CELL_BUILD_DEFINITIONS = 'FIND_CELL_BUILD_DEFINITIONS';
export const FIND_CELL_BUILD_DEFINITIONS_PENDING =
  'FIND_CELL_BUILD_DEFINITIONS_PENDING';
export const FIND_CELL_BUILD_DEFINITIONS_FULFILLED =
  'FIND_CELL_BUILD_DEFINITIONS_FULFILLED';

export const FIND_CELL_BUILD_DEFINITION = 'FIND_CELL_BUILD_DEFINITION';
export const FIND_CELL_BUILD_DEFINITION_PENDING =
  'FIND_CELL_BUILD_DEFINITION_PENDING';
export const FIND_CELL_BUILD_DEFINITION_FULFILLED =
  'FIND_CELL_BUILD_DEFINITION_FULFILLED';

export const FIND_LATEST_CELL_BUILD_DEFINITION_VERSIONS =
  'FIND_LATEST_CELL_BUILD_DEFINITION_VERSIONS';
export const FIND_LATEST_CELL_BUILD_DEFINITION_VERSIONS_PENDING =
  'FIND_LATEST_CELL_BUILD_DEFINITION_VERSIONS_PENDING';
export const FIND_LATEST_CELL_BUILD_DEFINITION_VERSIONS_FULFILLED =
  'FIND_LATEST_CELL_BUILD_DEFINITION_VERSIONS_FULFILLED';

export const CLEAR_CELL_BUILD_DEFINITION_DELETE_MESSAGE =
  'CLEAR_CELL_BUILD_DEFINITION_DELETE_MESSAGE';

export const TOGGLE_CELL_BUILD_COMPONENT_EXPANDED =
  'TOGGLE_CELL_BUILD_COMPONENT_EXPANDED';

export const TOGGLE_CELL_BUILD_COMPONENTS_EXPANDED =
  'TOGGLE_CELL_BUILD_COMPONENTS_EXPANDED';

export const SET_CELL_BUILD_COMPONENT_SEARCH =
  'SET_CELL_BUILD_COMPONENT_SEARCH';

export const SET_SEARCH_RESULT_CELL_BUILD_COMPONENTS =
  'SET_SEARCH_RESULT_CELL_BUILD_COMPONENTS';

export const SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT =
  'SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT';

export const SET_CREATE_CHECK_LIST_DEFINITION_EDIT_CHECK_LIST_ITEM_GROUP_FIELD_VALUE =
  'SET_CREATE_CHECK_LIST_DEFINITION_EDIT_CHECK_LIST_ITEM_GROUP_FIELD_VALUE';

export const SET_CREATE_CHECK_LIST_DEFINITION_EDIT_CHECK_LIST_ITEM_FIELD_VALUE =
  'SET_CREATE_CHECK_LIST_DEFINITION_EDIT_CHECK_LIST_ITEM_FIELD_VALUE';

export const SAVE_CELL_BUILD_DEFINITION_COMPONENT =
  'SAVE_CELL_BUILD_DEFINITION_COMPONENT';
export const SAVE_CELL_BUILD_DEFINITION_COMPONENT_PENDING =
  'SAVE_CELL_BUILD_DEFINITION_COMPONENT_PENDING';
export const SAVE_CELL_BUILD_DEFINITION_COMPONENT_FULFILLED =
  'SAVE_CELL_BUILD_DEFINITION_COMPONENT_FULFILLED';

export const SET_CREATE_CHILD_BUILD_DEFINITION_COMPONENT =
  'SET_CREATE_CHILD_BUILD_DEFINITION_COMPONENT';

export const SET_CONFIRM_DELETE_BUILD_DEFINITION_COMPONENT =
  'SET_CONFIRM_DELETE_BUILD_DEFINITION_COMPONENT';

export const DELETE_CELL_BUILD_DEFINITION_COMPONENT =
  'DELETE_CELL_BUILD_DEFINITION_COMPONENT';
export const DELETE_CELL_BUILD_DEFINITION_COMPONENT_PENDING =
  'DELETE_CELL_BUILD_DEFINITION_COMPONENT_PENDING';
export const DELETE_CELL_BUILD_DEFINITION_COMPONENT_FULFILLED =
  'DELETE_CELL_BUILD_DEFINITION_COMPONENT_FULFILLED';

export const SET_CREATE_CELL_BUILD_DEFINITION_OPEN =
  'SET_CREATE_CELL_BUILD_DEFINITION_OPEN';

export const SET_CREATE_BUILD_DEFINITION_FIELD_VALUE =
  'SET_CREATE_BUILD_DEFINITION_FIELD_VALUE';

export const CREATE_CELL_BUILD_DEFINITION = 'CREATE_CELL_BUILD_DEFINITION';
export const CREATE_CELL_BUILD_DEFINITION_PENDING =
  'CREATE_CELL_BUILD_DEFINITION_PENDING';
export const CREATE_CELL_BUILD_DEFINITION_FULFILLED =
  'CREATE_CELL_BUILD_DEFINITION_FULFILLED';

export const SET_CONFIRM_DELETE_CELL_BUILD_DEFINITION =
  'SET_CONFIRM_DELETE_CELL_BUILD_DEFINITION';
