import * as actionTypes from 'actions';

const initialState = {
  validationMessages: {},
  configurationSaving: false,
  configurationSaved: false,
  cellConfiguration: null,
  verificationConfiguration: null
};

const editCellConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_EDIT_CELL_CONFIGURATION: {
      return {
        ...initialState
      };
    }

    case actionTypes.EDIT_CELL_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        cellConfiguration: action.payload.data.result
          ? action.payload.data.result.configurationJson
          : ''
      };
    }

    case actionTypes.SET_EDITED_CELL_CONFIGURATION: {
      return {
        ...state,
        cellConfiguration: action.payload
      };
    }

    case actionTypes.SAVE_EDITED_CELL_CONFIGURATION_PENDING: {
      return {
        ...state,
        verificationConfiguration: initialState.verificationConfiguration,
        configurationSaving: true,
        configurationSaved: false
      };
    }

    case actionTypes.SAVE_EDITED_CELL_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        verificationConfiguration: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages,
        configurationSaving: false,
        configurationSaved: true
      };
    }

    case actionTypes.VERIFY_EDITED_CELL_CONFIGURATION_FULFILLED: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};

export default editCellConfigurationReducer;
