import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, colors } from '@material-ui/core';
import {
  updateEventValidationStatus,
  updateEventAnnotatedParticipantGender,
  updateEventAnnotatedParticipantClothingType,
  toggleEventSpecialInterest
} from 'actions';

const useStyles = makeStyles(theme => ({
  attributeSelection: {
    '& .MuiSvgIcon-root': {
      width: '0.8em',
      height: '0.8em',
      marginLeft: theme.spacing(1)
    },
    '& .MuiButton-root': {
      fontSize: '0.8em',
      height: 28
    },
    marginBottom: theme.spacing(1)
  },
  selectedButton: {
    backgroundColor: colors.blueGrey[50],
    boxShadow: 'inset 0 0 4px #B0BEC5'
  }
}));

const EventAnnotations = props => {
  const {
    event,
    processing,
    hideGender,
    hideValidationStatus,
    hideClothingType,
    hideSpecialInterest,
    className
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleUpdateEventValidationStatus = status => {
    dispatch(updateEventValidationStatus(event.id, status));
  };

  const handleUpdateEventParticipantGender = gender => {
    dispatch(
      updateEventAnnotatedParticipantGender(
        event.id,
        event.annotatedParticipantGender === gender ? null : gender
      )
    );
  };

  const handleUpdateEventParticipantClothingType = clothingType => {
    dispatch(
      updateEventAnnotatedParticipantClothingType(
        event.id,
        event.annotatedParticipantClothingType === clothingType
          ? null
          : clothingType
      )
    );
  };

  const handleToggleEventSpecialInterest = event => {
    dispatch(toggleEventSpecialInterest(event.id));
  };

  return (
    <div className={className}>
      {!hideValidationStatus && (
        <ButtonGroup className={classes.attributeSelection} fullWidth>
          <Button
            disabled={processing}
            onClick={() => handleUpdateEventValidationStatus('PENDING')}
            variant="outlined"
            className={
              event.validationStatus === 'PENDING' ? classes.selectedButton : ''
            }>
            Pending
          </Button>
          <Button
            disabled={processing}
            onClick={() => handleUpdateEventValidationStatus('SUSPECT')}
            variant="outlined"
            className={
              event.validationStatus === 'SUSPECT' ? classes.selectedButton : ''
            }>
            Excluded
          </Button>
          <Button
            disabled={processing}
            onClick={() => handleUpdateEventValidationStatus('VERIFIED')}
            variant="outlined"
            className={
              event.validationStatus === 'VERIFIED'
                ? classes.selectedButton
                : ''
            }>
            Verified
          </Button>
        </ButtonGroup>
      )}
      {!hideGender && (
        <ButtonGroup className={classes.attributeSelection} fullWidth>
          <Button
            disabled={processing}
            onClick={() => handleUpdateEventParticipantGender('MALE')}
            variant="outlined"
            className={
              event.annotatedParticipantGender === 'MALE'
                ? classes.selectedButton
                : ''
            }>
            Male
          </Button>
          <Button
            disabled={processing}
            onClick={() => handleUpdateEventParticipantGender('FEMALE')}
            variant="outlined"
            className={
              event.annotatedParticipantGender === 'FEMALE'
                ? classes.selectedButton
                : ''
            }>
            Female
          </Button>
        </ButtonGroup>
      )}
      {!hideClothingType && (
        <ButtonGroup className={classes.attributeSelection} fullWidth>
          <Button
            disabled={processing}
            onClick={() => handleUpdateEventParticipantClothingType('LIGHT')}
            variant="outlined"
            className={clsx(
              classes.clothingTypeButton,
              event.annotatedParticipantClothingType === 'LIGHT'
                ? classes.selectedButton
                : ''
            )}>
            Light Clothing
          </Button>
          <Button
            disabled={processing}
            onClick={() => handleUpdateEventParticipantClothingType('MEDIUM')}
            variant="outlined"
            className={clsx(
              classes.clothingTypeButton,
              event.annotatedParticipantClothingType === 'MEDIUM'
                ? classes.selectedButton
                : ''
            )}>
            Medium Clothing
          </Button>
          <Button
            disabled={processing}
            onClick={() => handleUpdateEventParticipantClothingType('HEAVY')}
            variant="outlined"
            className={clsx(
              classes.clothingTypeButton,
              event.annotatedParticipantClothingType === 'HEAVY'
                ? classes.selectedButton
                : ''
            )}>
            Heavy Clothing
          </Button>
        </ButtonGroup>
      )}
      {!hideSpecialInterest && (
        <ButtonGroup className={classes.attributeSelection} fullWidth>
          <Button
            disabled={processing}
            onClick={() => handleToggleEventSpecialInterest(event)}
            variant="outlined"
            className={event.specialInterest ? classes.selectedButton : ''}>
            Special Interest
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

EventAnnotations.propTypes = {
  event: PropTypes.object
};

export default EventAnnotations;
