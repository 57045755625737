import ws from '../utils/ws';

/* Actions */

export const localStorageDownloadRequestPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: LOCAL_STORAGE_DOWNLOAD_REQUEST_PAGINATED_LIST,
    payload: ws.get(
      `/walk_files/local_storage/download_requests/page/${pageNumber}/rows/${pageSize}`
    )
  });

export const findLocalStorageOverview = () => dispatch =>
  dispatch({
    type: FIND_LOCAL_STORAGE_OVERVIEW,
    payload: ws.get(`/walk_files/local_storage/overview`)
  });

/* Constants */

export const FIND_LOCAL_STORAGE_OVERVIEW = 'FIND_LOCAL_STORAGE_OVERVIEW';
export const FIND_LOCAL_STORAGE_OVERVIEW_PENDING =
  'FIND_LOCAL_STORAGE_OVERVIEW_PENDING';
export const FIND_LOCAL_STORAGE_OVERVIEW_FULFILLED =
  'FIND_LOCAL_STORAGE_OVERVIEW_FULFILLED';

export const LOCAL_STORAGE_DOWNLOAD_REQUEST_PAGINATED_LIST =
  'LOCAL_STORAGE_DOWNLOAD_REQUEST_PAGINATED_LIST';
export const LOCAL_STORAGE_DOWNLOAD_REQUEST_PAGINATED_LIST_PENDING =
  'LOCAL_STORAGE_DOWNLOAD_REQUEST_PAGINATED_LIST_PENDING';
export const LOCAL_STORAGE_DOWNLOAD_REQUEST_PAGINATED_LIST_FULFILLED =
  'LOCAL_STORAGE_DOWNLOAD_REQUEST_PAGINATED_LIST_FULFILLED';
