import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { EventSettings } from 'components';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {},
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

const CreateProductBuildComponent = props => {
  const {
    open,
    buildComponent,
    validationMessages,
    onCancel,
    onDelete,
    onSave,
    onFieldChange
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    onCancel();
  };

  const handleValueChange = (field, value) => {
    onFieldChange(field, value);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader
          title={`${
            buildComponent.creationParentGuid ? 'Add' : 'Edit'
          } Build Component`}
        />
        <Divider />
        <CardContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Name"
                error={validationMessages.hasOwnProperty('name')}
                value={buildComponent.name ? buildComponent.name : ''}
                helperText={validationMessages.name}
                variant="outlined"
                onChange={e => handleValueChange('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Quantity"
                error={validationMessages.hasOwnProperty('quantity')}
                value={buildComponent.quantity ? buildComponent.quantity : ''}
                helperText={validationMessages.quantity}
                variant="outlined"
                onChange={e => handleValueChange('quantity', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label="Notes"
                error={validationMessages.hasOwnProperty('notes')}
                value={buildComponent.notes ? buildComponent.notes : ''}
                helperText={validationMessages.notes}
                variant="outlined"
                onChange={e => handleValueChange('notes', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Manufacturer"
                error={validationMessages.hasOwnProperty('manufacturer')}
                value={
                  buildComponent.manufacturer ? buildComponent.manufacturer : ''
                }
                helperText={validationMessages.manufacturer}
                variant="outlined"
                onChange={e =>
                  handleValueChange('manufacturer', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Manufacturer Part Number"
                error={validationMessages.hasOwnProperty(
                  'manufacturerPartNumber'
                )}
                value={
                  buildComponent.manufacturerPartNumber
                    ? buildComponent.manufacturerPartNumber
                    : ''
                }
                helperText={validationMessages.manufacturerPartNumber}
                variant="outlined"
                onChange={e =>
                  handleValueChange('manufacturerPartNumber', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Distributor"
                error={validationMessages.hasOwnProperty('distributor')}
                value={
                  buildComponent.distributor ? buildComponent.distributor : ''
                }
                helperText={validationMessages.distributor}
                variant="outlined"
                onChange={e => handleValueChange('distributor', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Distributor Part Number"
                error={validationMessages.hasOwnProperty(
                  'distributorPartNumber'
                )}
                value={
                  buildComponent.distributorPartNumber
                    ? buildComponent.distributorPartNumber
                    : ''
                }
                helperText={validationMessages.distributorPartNumber}
                variant="outlined"
                onChange={e =>
                  handleValueChange('distributorPartNumber', e.target.value)
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          {!buildComponent.creationParentGuid && buildComponent.parentGuid && (
            <Button
              className={classes.deleteButton}
              onClick={onDelete}
              variant="contained">
              Delete
            </Button>
          )}
          <Button color="primary" onClick={onSave} variant="contained">
            Save
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CreateProductBuildComponent.defaultProps = {
  open: false
};

export default CreateProductBuildComponent;
