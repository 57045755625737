import * as actionTypes from 'actions';

const initialState = {
  dataSets: [],
  loading: false,
  processing: false,
  creating: false,
  search: '',
  existingDataSet: false,
  newDataSetName: '',
  validationMessages: {},
  createdDataSet: null
};
const sessionEventDataSetAddReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_EVENT_DATA_SETS_PENDING: {
      return {
        ...state,
        loading: true,
        dataSets: initialState.dataSets
      };
    }
    case actionTypes.SESSION_EVENT_DATA_SET_ADD_CLEAR_VALUES: {
      return {
        ...initialState
      };
    }

    case actionTypes.FIND_EVENT_DATA_SETS_FULFILLED: {
      return {
        ...state,
        loading: false,
        dataSets: action.payload.data.result
      };
    }

    case actionTypes.SESSION_EVENT_DATA_SET_ADD_SET_EXISTING_DATA_SET: {
      return {
        ...state,
        existingDataSet: action.payload
      };
    }

    case actionTypes.SESSION_EVENT_DATA_SET_ADD_SET_NEW_DATA_SET_NAME: {
      return {
        ...state,
        newDataSetName: action.payload
      };
    }

    case actionTypes.SESSION_EVENT_DATA_SET_ADD_SET_SEARCH: {
      return {
        ...state,
        search: action.payload
      };
    }

    case actionTypes.SESSION_EVENT_DATA_SET_ADD_CLEAR_CREATED: {
      return {
        ...state,
        createdDataSet: initialState.createdDataSet
      };
    }

    case actionTypes.CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS_PENDING: {
      return {
        ...state,
        creating: true
      };
    }

    case actionTypes.CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS_REJECTED: {
      return {
        ...state,
        creating: false
      };
    }

    case actionTypes.CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS_FULFILLED: {
      return {
        ...state,
        dataSetCreated: action.payload.data.success,
        createdDataSet: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages,
        newDataSetName: initialState.newDataSetName,
        creating: false
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionEventDataSetAddReducer;
