import * as actionTypes from 'actions';

const initialState = {
  masterVersionLoading: false,
  updated: false,
  masterVersion: {
    createdBy: {}
  },
  entityPhotos: [],
  sessionsLoading: false,
  sessions: [],
  testingAnalysisOverview: {
    loading: false,
    analysisOverview: {},
    setupAnalysesCount: 0,
    eventCount: 0,
    noObjectEventCount: 0,
    locationEventCounts: {},
    subModels: []
  },
  analysisBias: null
};

const masterVersionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_MASTER_VERSION_PENDING: {
      return {
        ...initialState,
        masterVersionLoading: true
      };
    }

    case actionTypes.FIND_MASTER_VERSION_FULFILLED: {
      return {
        ...state,
        masterVersionLoading: false,
        updated: false,
        masterVersion: action.payload.data.result
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        masterVersionLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.SET_MASTER_VERSION_STATUS_FULFILLED: {
      return {
        ...state,
        updated: true
      };
    }

    case actionTypes.FIND_MASTER_VERSION_TEST_SESSIONS_PENDING: {
      return {
        ...state,
        sessionsLoading: true,
        sessions: initialState.sessions
      };
    }

    case actionTypes.FIND_MASTER_VERSION_TEST_SESSIONS_FULFILLED: {
      return {
        ...state,
        sessionsLoading: false,
        sessions: action.payload.data.result
      };
    }

    case actionTypes.FIND_MASTER_VERSION_ANALYSIS_OVERVIEW_PENDING: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...initialState.testingAnalysisOverview,
          loading: true
        }
      };
    }

    case actionTypes.FIND_MASTER_VERSION_ANALYSIS_OVERVIEW_FULFILLED: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...action.payload.data.result,
          loading: false
        }
      };
    }

    case actionTypes.SET_MASTER_VERSION_DETAILS_ANALYSIS_BIAS: {
      return {
        ...state,
        analysisBias: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default masterVersionDetailsReducer;
