import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  TextField,
  Button,
  Divider,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import {
  POCKET_LOCATIONS,
  SUBJECT_AREAS,
  NEW_ITEM_TRIGGER,
  OBJECT_TYPE_CATEGORIES,
  POCKET_ITEMS_SUBJECT_AREAS
} from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    paddingBottom: 0
  },
  content: {
    padding: theme.spacing(3)
  },
  objectLocation: {
    marginBottom: theme.spacing(2)
  },
  actions: {
    marginTop: -theme.spacing(3)
  },
  divider: {
    marginBottom: theme.spacing(3)
  },
  subHeader: {
    marginBottom: theme.spacing(3)
  },
  subjectAreaContentButton: {
    marginTop: theme.spacing(1)
  },
  endingCheckbox: {
    marginTop: -theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}));

const CreateSetupCard = props => {
  const {
    setups,
    setup,
    scenario,
    objectTypes,
    objectModels,
    className,
    title,
    onFieldChange,
    onSubjectAreaContentsFieldChange,
    onAddSubjectAreaContents,
    onRemoveSubjectAreaContents,
    onOrderChange,
    onRemoveSetup,
    onToggleCreateObjectType,
    onToggleCreateObjectModel,
    sessionCreate,
    contentOnly,
    hideApplyToExisingSubjectAreas,
    participantEntry,
    ...rest
  } = props;

  const classes = useStyles();

  const entryFootSides = [
    { code: 'BOTH_FEET', name: 'Alternating' },
    { code: 'RIGHT', name: 'Right Foot' },
    { code: 'LEFT', name: 'Left Foot' }
  ];

  const applicableObjectTypes = participantEntry
    ? objectTypes.filter(ot => ot.participantEntryApplicable)
    : objectTypes;

  const objectTypeCategories = {};
  for (let i = 0; i < applicableObjectTypes.length; i++) {
    const objectType = applicableObjectTypes[i];
    let types;
    if (objectTypeCategories.hasOwnProperty(objectType.category)) {
      types = objectTypeCategories[objectType.category];
    } else {
      types = [];
      objectTypeCategories[objectType.category] = types;
    }
    types.push(objectType);
  }

  /* Sort object type categories */
  for (let cat in objectTypeCategories) {
    objectTypeCategories[cat].sort((t1, t2) => {
      if (t1.name.toUpperCase() < t2.name.toUpperCase()) {
        return -1;
      }
      if (t1.name.toUpperCase() > t2.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      {!contentOnly && (
        <CardHeader
          className={classes.header}
          title={title ? title : 'Setup ' + (setup.index + 1)}
        />
      )}

      <CardContent className={classes.content}>
        {!contentOnly && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                className={classes.objectLocation}
                fullWidth
                label="Entry Foot"
                name="entryFoot"
                onChange={event =>
                  onFieldChange(event, setup, 'entryFoot', event.target.value)
                }
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={setup.entryFoot ? setup.entryFoot : ''}
                variant="outlined">
                <option value=""></option>
                {entryFootSides.map(footSide => (
                  <option key={footSide.code} value={footSide.code}>
                    {footSide.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            {setups && (
              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  className={classes.objectLocation}
                  fullWidth
                  label="Setup Order"
                  name="order"
                  onChange={event => {
                    onOrderChange(setup, Number(event.target.value));
                  }}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={setup.index}
                  variant="outlined">
                  {setups.map(setup => (
                    <option key={setup.index} value={setup.index}>
                      {setup.index + 1}
                    </option>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item sm={12} md={12} lg={3}>
              <TextField
                className={classes.objectLocation}
                fullWidth
                label="Custom number of walks"
                name="setupWalkCount"
                onChange={event =>
                  onFieldChange(
                    event,
                    setup,
                    'setupWalkCount',
                    event.target.value
                  )
                }
                value={setup.setupWalkCount ? setup.setupWalkCount : ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        )}

        {!contentOnly && (
          <Typography className={classes.subHeader} gutterBottom variant="h6">
            Subject Contents
          </Typography>
        )}

        {setup.subjectAreaContents &&
          setup.subjectAreaContents.map(
            (subjectAreaContent, subjectAreaContentIndex) => (
              <React.Fragment key={subjectAreaContentIndex}>
                <Grid key={subjectAreaContentIndex} container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      className={classes.objectLocation}
                      fullWidth
                      label="Subject Area"
                      name="subjectArea"
                      onChange={event =>
                        onSubjectAreaContentsFieldChange(
                          event,
                          setup,
                          'subjectArea',
                          event.target.value,
                          subjectAreaContentIndex
                        )
                      }
                      select
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{ native: true }}
                      value={subjectAreaContent.subjectArea}
                      variant="outlined">
                      {Object.keys(SUBJECT_AREAS)
                        .filter(subjectArea => subjectArea !== 'BAG')
                        .map(objectLocation => (
                          <option key={objectLocation} value={objectLocation}>
                            {SUBJECT_AREAS[objectLocation]}
                          </option>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      className={classes.objectLocation}
                      fullWidth
                      disabled={
                        POCKET_ITEMS_SUBJECT_AREAS.indexOf(
                          SUBJECT_AREAS[subjectAreaContent.subjectArea]
                        ) < 0
                      }
                      label="Pocket"
                      name="pocketLocation"
                      onChange={event =>
                        onSubjectAreaContentsFieldChange(
                          event,
                          setup,
                          'pocketLocation',
                          event.target.value,
                          subjectAreaContentIndex
                        )
                      }
                      select
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{ native: true }}
                      value={
                        subjectAreaContent.pocketLocation === null ||
                        POCKET_ITEMS_SUBJECT_AREAS.indexOf(
                          SUBJECT_AREAS[subjectAreaContent.subjectArea]
                        ) < 0
                          ? ''
                          : subjectAreaContent.pocketLocation
                      }
                      variant="outlined">
                      <option key="" value=""></option>

                      {Object.keys(POCKET_LOCATIONS).map(pocketLocation => (
                        <option key={pocketLocation} value={pocketLocation}>
                          {POCKET_LOCATIONS[pocketLocation]}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {applicableObjectTypes.length > 0 && (
                      <TextField
                        className={classes.objectLocation}
                        fullWidth
                        label="Object"
                        name="objectType"
                        onChange={event => {
                          let val;
                          if (event.target.value === NEW_ITEM_TRIGGER) {
                            val = event.target.value;
                          } else {
                            val = applicableObjectTypes.filter(
                              t => t.id === event.target.value
                            )[0];
                          }
                          val = val === undefined ? null : val;
                          onSubjectAreaContentsFieldChange(
                            event,
                            setup,
                            'objectType',
                            val,
                            subjectAreaContentIndex
                          );
                        }}
                        select
                        // eslint-disable-next-line react/jsx-sort-props
                        SelectProps={{ native: true }}
                        value={
                          subjectAreaContent.objectType === null ||
                          !subjectAreaContent.objectType.id
                            ? ''
                            : subjectAreaContent.objectType.id
                        }
                        variant="outlined">
                        <option key="" value="" />

                        {Object.keys(objectTypeCategories).map(category => (
                          <optgroup
                            key={category}
                            label={OBJECT_TYPE_CATEGORIES[category].name}>
                            {objectTypeCategories[category].map(objectType => (
                              <option key={objectType.id} value={objectType.id}>
                                {objectType.name}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <IconButton
                      color="primary"
                      size="small"
                      className={classes.subjectAreaContentButton}
                      onClick={() =>
                        onRemoveSubjectAreaContents(
                          setup,
                          subjectAreaContentIndex
                        )
                      }>
                      <RemoveCircleIcon />
                    </IconButton>
                    {subjectAreaContentIndex ===
                      setup.subjectAreaContents.length - 1 && (
                      <IconButton
                        color="primary"
                        size="small"
                        className={classes.subjectAreaContentButton}
                        onClick={() => onAddSubjectAreaContents(setup)}>
                        <AddCircleIcon />
                      </IconButton>
                    )}
                  </Grid>
                  {contentOnly && !hideApplyToExisingSubjectAreas && (
                    <Grid item sm={12}>
                      <FormControlLabel
                        className={classes.endingCheckbox}
                        control={
                          <Checkbox
                            checked={
                              subjectAreaContent.additionOnlyToExisting
                                ? true
                                : false
                            }
                            color="primary"
                            onChange={(event, value) =>
                              onSubjectAreaContentsFieldChange(
                                event,
                                setup,
                                'additionOnlyToExisting',
                                value,
                                subjectAreaContentIndex
                              )
                            }
                          />
                        }
                        label="Apply only to existing subject areas"
                      />
                    </Grid>
                  )}
                </Grid>
                {subjectAreaContentIndex + 1 <
                  setup.subjectAreaContents.length && (
                  <Divider className={classes.divider} />
                )}
              </React.Fragment>
            )
          )}
        {(!setup.subjectAreaContents ||
          setup.subjectAreaContents.length == 0) && (
          <Button
            onClick={() => onAddSubjectAreaContents(setup)}
            variant="contained">
            Add Content
          </Button>
        )}
      </CardContent>

      {setups && (
        <CardActions>
          <Button onClick={event => onRemoveSetup(setup)} variant="contained">
            Remove Setup
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

CreateSetupCard.propTypes = {
  setup: PropTypes.object.isRequired
};

export default CreateSetupCard;
