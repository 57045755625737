import React, { createRef, useRef, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  EventVideo,
  EventValidationStatus,
  NumberFormat,
  SubjectAreaContents,
  ConfirmAction
} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  findValidateSessionEvent,
  findValidateSessionEvents,
  updateTrainingEventValidationStatus,
  updateSetupValidationStatus,
  findSession,
  setEventPlaybackPaused,
  setEventPlaybackRate,
  setSetupValidationStatusChangeStatus,
  setValidateSessionProcessingEventId
} from 'actions';
import VideoCamOffIcon from '@material-ui/icons/VideocamOff';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  ButtonGroup,
  CircularProgress,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CardHeader,
  Divider,
  Paper,
  colors,
  LinearProgress
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1094,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  header: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default
  },
  files: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  alert: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  createdSessionId: {
    marginTop: theme.spacing(6)
  },
  noVideoMessage: {
    textAlign: 'center',
    paddingTop: 200
  },
  noVideoIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    width: 40,
    height: 40,
    display: 'block',
    color: colors.red[600]
  },
  eventStatus: {
    textAlign: 'center'
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  gutterBottom: {
    marginBottom: theme.spacing(2)
  },
  statusSelection: {
    '& .MuiButton-root': {
      fontSize: '0.8em',
      height: 28
    }
  },
  buttonGroupSeparator: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  selectedButton: {
    backgroundColor: colors.blueGrey[50],
    boxShadow: 'inset 0 0 4px #B0BEC5'
  },
  walksValidation: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2)
  },
  walksValidationEvents: {
    height: '100%',
    flexGrow: 10000,
    display: 'inline-block',
    paddingRight: theme.spacing(2)
  },
  entityPhotos: {
    margin: -theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  setupValidation: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2)
  },
  setupValidationParticipant: {
    height: '100%',
    flexGrow: 1000,
    display: 'inline-block',
    paddingRight: theme.spacing(2)
  },
  setupValidationVideo: {
    height: 525,
    width: 640,
    flexGrow: 0,
    display: 'inline-block'
  },
  setupValidationInfo: {
    marginBottom: theme.spacing(2)
  },
  setupValidationSubjectContents: {
    marginTop: theme.spacing(2)
  },
  setupWalkList: {
    minHeight: 76,
    maxHeight: 76,
    overflowY: 'scroll',
    '& p': {
      textAlign: 'center',
      marginBottom: theme.spacing(1)
    },
    marginBottom: theme.spacing(2)
  },
  selectedEvent: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 1
  },
  walkStatus: {
    display: 'inline-block',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer',
    width: 78,
    height: 60
  },
  validationStatusLabel: {
    marginLeft: -5
  },
  videoContainer: {
    height: 525,
    width: 640,
    position: 'relative'
  },
  videoOverlay: {
    position: 'absolute',
    background: theme.palette.primary.main,
    top: 0,
    height: 60,
    left: 0,
    right: 0,
    zIndex: 1
  },
  videoOverlayMessage: {
    color: 'white',
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
    top: 20,
    textAlign: 'center',
    fontSize: '16px'
  },
  webkitPlayButtonOverlay: {
    position: 'absolute',
    background: 'red',
    top: 408,
    height: 48,
    width: 130,
    left: 0,
    zIndex: 1,
    opacity: 0
  },
  walkCountOverlay: {
    position: 'absolute',
    background: 'white',
    top: 408,
    height: 30,
    borderRadius: 30,
    width: 100,
    left: 270,
    zIndex: 1,
    opacity: 0.7,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.grey[400]
  },
  walkCountOverlayMessage: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
    top: 3,
    textAlign: 'center',
    fontSize: '12px'
  },
  eventProgress: {
    marginLeft: 24
  },
  processing: {
    marginTop: -4
  }
}));

const ValidateTrainingSession = props => {
  const { open, onClose, loading, className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const eventVideoRef = createRef();
  const eventListRef = useRef();

  const {
    session,
    setup,
    initialEventRequested,
    currentEvent,
    eventVideoPlaybackRate,
    eventListLoaded,
    events,
    processing,
    eventPlaybackPaused,
    setupValidationStatusChangeStatus,
    processingEventId
  } = useSelector(state => state.validateTrainingSession);

  const eventStatusRefs = [];
  const setupEvents = events.filter(
    event => setup && event && event.setupIndex === setup.index
  );
  setupEvents.sort((e1, e2) => e1.walktTime - e2.walkTime);
  const eventIndex = setupEvents
    .map(event => event.id)
    .indexOf(currentEvent.id);
  const reviewedWalkCount = setupEvents.filter(
    se => se.validationStatus !== 'PENDING'
  ).length;
  events.sort((e1, e2) => e1.walktTime - e2.walkTime);

  useEffect(() => {
    if (eventIndex < setupEvents.length && eventStatusRefs[eventIndex]) {
      eventListRef.current.scrollTop =
        eventStatusRefs[eventIndex].offsetTop - 8;
    }
  });

  if (!open || !session) {
    return null;
  }

  if (open && !initialEventRequested && setupEvents.length > 0) {
    dispatch(findValidateSessionEvent(setupEvents[0].id)); // TODO CHANGE
  }

  if (open && !eventListLoaded) {
    dispatch(findValidateSessionEvents(session.id));
  }

  const eventValidationStatusCounts = {};
  for (let i = 0; i < setupEvents.length; i++) {
    const event = setupEvents[i];
    if (event.validationStatus) {
      eventValidationStatusCounts[event.validationStatus] =
        (eventValidationStatusCounts[event.validationStatus]
          ? eventValidationStatusCounts[event.validationStatus]
          : 0) + 1;
    }
  }

  const handleUpdateEventValidationStatus = status => {
    dispatch(setValidateSessionProcessingEventId(currentEvent.id));
    dispatch(updateTrainingEventValidationStatus(currentEvent.id, status));
  };

  const handleCloseConfirmUpdateSetupValidationStatus = () => {
    dispatch(setSetupValidationStatusChangeStatus(null));
  };

  const handleUpdateSetupValidationStatus = status => {
    dispatch(setSetupValidationStatusChangeStatus(status));
  };

  const handleUpdateSetupValidationStatusConfirmed = () => {
    dispatch(
      updateSetupValidationStatus(
        session.id,
        setup.index,
        setupValidationStatusChangeStatus
      )
    );
  };

  const handleEventListClick = eventIndex => {
    dispatch(findValidateSessionEvent(setupEvents[eventIndex].id));
  };

  const handleClose = () => {
    dispatch(findSession(session.id));
    onClose();
  };

  const handleEventVideoEnd = () => {
    if (!eventPlaybackPaused) {
      const validationStatus = currentEvent.validationStatus;
      const newValidationStatus =
        validationStatus === 'PENDING' || validationStatus == 'VERIFIED'
          ? 'VERIFIED'
          : 'SUSPECT';

      dispatch(setValidateSessionProcessingEventId(currentEvent.id));
      Promise.resolve(
        dispatch(
          updateTrainingEventValidationStatus(
            currentEvent.id,
            newValidationStatus
          )
        )
      ).then(() => {
        if (eventIndex < setupEvents.length - 1) {
          dispatch(findValidateSessionEvent(setupEvents[eventIndex + 1].id));
        }
      });
    }
  };

  const handleEventVideoLoaded = () => {
    if (eventPlaybackPaused) {
      eventVideoRef.current.pause();
    } else {
      eventVideoRef.current.playbackRate = eventVideoPlaybackRate;
      eventVideoRef.current.play();
    }
  };

  const handleKeyDown = event => {
    if (event.which === 32) {
      event.preventDefault();
      if (eventPlaybackPaused) {
        dispatch(setEventPlaybackPaused(false));
        eventVideoRef.current.playbackRate = eventVideoPlaybackRate;
        eventVideoRef.current && eventVideoRef.current.play();
      }
    }
  };

  const handleKeyUp = event => {
    if (event.which === 32) {
      event.preventDefault();
      if (!eventPlaybackPaused) {
        dispatch(setEventPlaybackPaused(true));
        eventVideoRef.current && eventVideoRef.current.pause();
      }
    }
  };

  const handleAdjustEventPlaybackRate = rate => {
    dispatch(setEventPlaybackRate(rate));
    eventVideoRef.current.playbackRate = rate;
  };

  const setEventStatusRef = ref => {
    eventStatusRefs.push(ref);
  };

  return (
    <>
      <Modal
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onClose={handleClose}
        open={open}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader title="Validate Data Collection Setup" />
          <Divider />
          <CardContent className={classes.content}>
            {processing && <LinearProgress className={classes.processing} />}

            <div className={classes.walksValidation}>
              <div className={classes.walksValidationEvents}>
                <Paper className={classes.setupValidationInfo}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>Session</TableCell>
                        <TableCell>
                          {session.participant.name}{' '}
                          {moment(session.createdTime).format('MMM Do YYYY')}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Reviewed Walks</TableCell>
                        <TableCell>
                          {setupEvents.length > 0 && (
                            <>
                              {reviewedWalkCount} of {setupEvents.length}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>

                <Paper>
                  <div ref={eventListRef} className={classes.setupWalkList}>
                    <PerfectScrollbar>
                      {setupEvents.map((event, i) => (
                        <Card
                          key={i}
                          ref={setEventStatusRef}
                          className={`${classes.walkStatus} ${
                            event.id === currentEvent.id
                              ? classes.selectedEvent
                              : ''
                          }`}
                          onClick={() => handleEventListClick(i)}>
                          <Typography
                            className={classes.field}
                            component="p"
                            variant="overline">
                            <NumberFormat value={i + 1} />
                          </Typography>

                          {event.id === processingEventId ? (
                            <CircularProgress
                              size={12}
                              className={classes.eventProgress}
                            />
                          ) : (
                            <EventValidationStatus
                              className={classes.validationStatusLabel}
                              event={event}
                            />
                          )}
                        </Card>
                      ))}
                    </PerfectScrollbar>
                  </div>
                </Paper>

                <ButtonGroup className={classes.statusSelection} fullWidth>
                  <Button
                    disabled={processing}
                    onClick={() => handleUpdateEventValidationStatus('PENDING')}
                    variant="outlined"
                    className={
                      currentEvent.validationStatus === 'PENDING'
                        ? classes.selectedButton
                        : ''
                    }>
                    Walk Pending
                  </Button>
                  <Button
                    disabled={processing}
                    onClick={() => handleUpdateEventValidationStatus('SUSPECT')}
                    variant="outlined"
                    className={
                      currentEvent.validationStatus === 'SUSPECT'
                        ? classes.selectedButton
                        : ''
                    }>
                    Walk Suspect
                  </Button>
                  <Button
                    disabled={processing}
                    onClick={() =>
                      handleUpdateEventValidationStatus('VERIFIED')
                    }
                    variant="outlined"
                    className={
                      currentEvent.validationStatus === 'VERIFIED'
                        ? classes.selectedButton
                        : ''
                    }>
                    Walk Verified
                  </Button>
                </ButtonGroup>

                <Divider className={classes.buttonGroupSeparator} />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                  <Button
                    size="small"
                    color="primary"
                    disabled={processing}
                    onClick={() => handleUpdateSetupValidationStatus('PENDING')}
                    variant="outlined">
                    Setup Pending
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    disabled={processing}
                    onClick={() => handleUpdateSetupValidationStatus('SUSPECT')}
                    variant="outlined">
                    Setup Suspect
                  </Button>
                  <Button
                    disabled={processing}
                    size="small"
                    color="primary"
                    onClick={() =>
                      handleUpdateSetupValidationStatus('VERIFIED')
                    }
                    variant="outlined">
                    Setup Verified
                  </Button>
                </div>

                <Divider className={classes.buttonGroupSeparator} />

                <div className={classes.setupValidationSubjectContents}>
                  <SubjectAreaContents
                    subjectAreaContents={setup.subjectAreaContents}
                  />
                </div>
              </div>

              <div className={classes.setupValidationVideo}>
                <Paper className={classes.videoContainer}>
                  {currentEvent.id && (
                    <>
                      {eventPlaybackPaused && (
                        <>
                          <Typography
                            className={classes.videoOverlayMessage}
                            variant="h4">
                            Validation paused. Press and hold spacebar to
                            continue
                          </Typography>
                          <div className={classes.videoOverlay} />
                          <div className={classes.webkitPlayButtonOverlay} />
                        </>
                      )}
                      {setupEvents.length > 0 && (
                        <div className={classes.walkCountOverlay}>
                          <Typography
                            className={classes.walkCountOverlayMessage}
                            variant="h4">
                            Walk {eventIndex + 1} of {setupEvents.length}
                          </Typography>
                        </div>
                      )}

                      <EventVideo
                        eventVideoRef={eventVideoRef}
                        playbackRate={eventVideoPlaybackRate}
                        autoPlay={false}
                        loop={false}
                        displayPlaybackRate
                        handleVideoEnd={handleEventVideoEnd}
                        handleVideoLoaded={handleEventVideoLoaded}
                        handleAdjustPlaybackRate={handleAdjustEventPlaybackRate}
                        width={640}
                        height={480}
                        eventId={currentEvent.id}
                      />
                    </>
                  )}
                </Paper>
              </div>
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={handleClose}
              variant="contained"
              disabled={processing}>
              Done
            </Button>
          </CardActions>
        </Card>
      </Modal>
      <ConfirmAction
        message={
          'Please confirm you wish to update the validation status for all walks in this setup'
        }
        onClose={handleCloseConfirmUpdateSetupValidationStatus}
        onConfirm={handleUpdateSetupValidationStatusConfirmed}
        open={setupValidationStatusChangeStatus !== null}
      />
    </>
  );
};

ValidateTrainingSession.displayName = 'ValidateTrainingSession';

ValidateTrainingSession.propTypes = {
  trainerData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

ValidateTrainingSession.defaultProps = {
  open: false
};

export default ValidateTrainingSession;
