import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedCellComponent: {},
  deleted: false,
  deleteMessage: null,
  deletedCellComponent: {},
  cellComponent: {
    creationId: null,
    name: '',
    description: '',
    type: 'SOFTWARE'
  },
  customers: [],
  validationMessages: {}
};

const cellComponentCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CELL_COMPONENT_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedCellComponent: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CELL_COMPONENT_PENDING: {
      return {
        ...state,
        deleted: initialState.deleted,
        deletedCellComponent: initialState.deletedCellComponent
      };
    }

    case actionTypes.DELETE_CELL_COMPONENT_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deleteMessage: action.payload.data.message,
        deletedCellComponent: action.payload.data.result
      };
    }

    case actionTypes.EDIT_CELL_COMPONENT_FULFILLED: {
      return {
        ...state,
        cellComponent: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_CELL_COMPONENT_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_CELL_COMPONENT_VALUE_UPDATE: {
      return {
        ...state,
        cellComponent: {
          ...state.cellComponent,
          ...action.payload
        }
      };
    }

    case actionTypes.CLEAR_CELL_COMPONENT_DELETE_MESSAGE: {
      return {
        ...state,
        deleteMessage: initialState.deleteMessage
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
};

export default cellComponentCreateReducer;
