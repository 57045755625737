import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { EventSettings } from 'components';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: 0
  }
}));

const EventSettingsModal = props => {
  const { open, event, onClose } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader title="Settings" />
        <Divider />
        <CardContent className={classes.content}>
          <EventSettings
            settingsHash={event.eventSettingsHash}
            settingsJson={event.eventSettingsJson}
          />
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

EventSettingsModal.defaultProps = {
  open: false
};

export default EventSettingsModal;
