import ws from '../utils/ws';

/* Actions */

export const findSessionParticipants = scenarioType => dispatch =>
  dispatch({
    type: FIND_SESSION_PARTICIPANTS,
    payload: ws.get(`/sessions/${scenarioType}/participants/`)
  });

export const participantPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: PARTICIPANTS_PAGINATED_LIST,
    payload: ws.get(`/participants/page/${pageNumber}/rows/${pageSize}`)
  });

export const participantValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_PARTICIPANT_VALUE_UPDATE,
    payload: values
  });

export const participantValueReset = () => dispatch =>
  dispatch({
    type: CREATE_PARTICIPANT_VALUE_RESET
  });

export const saveParticipant = participant => dispatch =>
  dispatch({
    type: SAVE_PARTICIPANT,
    payload: ws.post('/participants/', participant)
  });

export const editParticipant = id => dispatch =>
  dispatch({
    type: EDIT_PARTICIPANT,
    payload: ws.get(`/participants/${id}`)
  });

export const deleteParticipant = participant => dispatch =>
  dispatch({
    type: DELETE_PARTICIPANT,
    payload: ws.post(`/participants/${participant.id}/delete`)
  });

export const confirmParticipantDetails = id => dispatch =>
  dispatch({
    type: CONFIRM_PARTICIPANT_DETAILS,
    payload: ws.post(`/participants/${id}/confirm_details`)
  });

export const findParticipants = () => dispatch =>
  dispatch({
    type: FIND_PARTICIPANTS,
    payload: ws.get('/participants/')
  });

export const findParticipant = id => dispatch =>
  dispatch({
    type: FIND_PARTICIPANT,
    payload: ws.get(`/participants/${id}`)
  });

export const findParticipantTrainingObjectTypes = participantId => dispatch =>
  dispatch({
    type: FIND_PARTICIPANT_TRAINING_OBJECT_TYPES,
    payload: ws.get(
      `/participants/${participantId}/training/summary/object_types`
    )
  });

export const findParticipantTrainingObjectOfInterestSubjectAreas = participantId => dispatch =>
  dispatch({
    type: FIND_PARTICIPANT_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS,
    payload: ws.get(
      `/participants/${participantId}/training/summary/object_of_interest_subject_areas`
    )
  });

export const findParticipantTrainingOverview = participantId => dispatch =>
  dispatch({
    type: FIND_PARTICIPANT_TRAINING_OVERVIEW,
    payload: ws.get(`/participants/${participantId}/training/summary/overview`)
  });

export const findParticipantTrainingScenarios = participantId => dispatch =>
  dispatch({
    type: FIND_PARTICIPANT_TRAINING_SCENARIOS,
    payload: ws.get(`/participants/${participantId}/training/summary/scenarios`)
  });

export const toggleCreateParticipantSubjectAreaContent = subjectAreaContent => dispatch =>
  dispatch({
    type: TOGGLE_CREATE_PARTICIPANT_SAC,
    payload: subjectAreaContent
  });

/* Constants */

export const FIND_SESSION_PARTICIPANTS = 'FIND_SESSION_PARTICIPANTS';
export const FIND_SESSION_PARTICIPANTS_PENDING =
  'FIND_SESSION_PARTICIPANTS_PENDING';
export const FIND_SESSION_PARTICIPANTS_FULFILLED =
  'FIND_SESSION_PARTICIPANTS_FULFILLED';

export const PARTICIPANTS_PAGINATED_LIST = 'PARTICIPANTS_PAGINATED_LIST';
export const PARTICIPANTS_PAGINATED_LIST_PENDING =
  'PARTICIPANTS_PAGINATED_LIST_PENDING';
export const PARTICIPANTS_PAGINATED_LIST_FULFILLED =
  'PARTICIPANTS_PAGINATED_LIST_FULFILLED';

export const CREATE_PARTICIPANT_VALUE_UPDATE =
  'CREATE_PARTICIPANTS_VALUE_UPDATE';
export const CREATE_PARTICIPANT_VALUE_RESET = 'CREATE_PARTICIPANT_VALUE_RESET';

export const SAVE_PARTICIPANT = 'SAVE_PARTICIPANT';
export const SAVE_PARTICIPANT_FULFILLED = 'SAVE_PARTICIPANT_FULFILLED';

export const DELETE_PARTICIPANT = 'DELETE_PARTICIPANT';
export const DELETE_PARTICIPANT_FULFILLED = 'DELETE_PARTICIPANT_FULFILLED';

export const EDIT_PARTICIPANT = 'EDIT_PARTICIPANT';
export const EDIT_PARTICIPANT_FULFILLED = 'EDIT_PARTICIPANT_FULFILLED';

export const FIND_PARTICIPANTS = 'FIND_PARTICIPANTS';
export const FIND_PARTICIPANTS_PENDING = 'FIND_PARTICIPANTS_PENDING';
export const FIND_PARTICIPANTS_FULFILLED = 'FIND_PARTICIPANTS_FULFILLED';

export const FIND_PARTICIPANT = 'FIND_PARTICIPANT';
export const FIND_PARTICIPANT_PENDING = 'FIND_PARTICIPANT_PENDING';
export const FIND_PARTICIPANT_FULFILLED = 'FIND_PARTICIPANT_FULFILLED';

export const FIND_PARTICIPANT_TRAINING_OBJECT_TYPES =
  'FIND_PARTICIPANT_TRAINING_OBJECT_TYPES';
export const FIND_PARTICIPANT_TRAINING_OBJECT_TYPES_PENDING =
  'FIND_PARTICIPANT_TRAINING_OBJECT_TYPES_PENDING';
export const FIND_PARTICIPANT_TRAINING_OBJECT_TYPES_FULFILLED =
  'FIND_PARTICIPANT_TRAINING_OBJECT_TYPES_FULFILLED';

export const FIND_PARTICIPANT_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS =
  'FIND_PARTICIPANT_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS';
export const FIND_PARTICIPANT_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_PENDING =
  'FIND_PARTICIPANT_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_PENDING';
export const FIND_PARTICIPANT_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_FULFILLED =
  'FIND_PARTICIPANT_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_FULFILLED';

export const FIND_PARTICIPANT_TRAINING_OVERVIEW =
  'FIND_PARTICIPANT_TRAINING_OVERVIEW';
export const FIND_PARTICIPANT_TRAINING_OVERVIEW_PENDING =
  'FIND_PARTICIPANT_TRAINING_OVERVIEW_PENDING';
export const FIND_PARTICIPANT_TRAINING_OVERVIEW_FULFILLED =
  'FIND_PARTICIPANT_TRAINING_OVERVIEW_FULFILLED';

export const FIND_PARTICIPANT_TRAINING_SCENARIOS =
  'FIND_PARTICIPANT_TRAINING_SCENARIOS';
export const FIND_PARTICIPANT_TRAINING_SCENARIOS_PENDING =
  'FIND_PARTICIPANT_TRAINING_SCENARIOS_PENDING';
export const FIND_PARTICIPANT_TRAINING_SCENARIOS_FULFILLED =
  'FIND_PARTICIPANT_TRAINING_SCENARIOS_FULFILLED';

export const CONFIRM_PARTICIPANT_DETAILS = 'CONFIRM_PARTICIPANT_DETAILS';
export const CONFIRM_PARTICIPANT_DETAILS_PENDING =
  'CONFIRM_PARTICIPANT_DETAILS_PENDING';
export const CONFIRM_PARTICIPANT_DETAILS_FULFILLED =
  'CONFIRM_PARTICIPANT_DETAILS_FULFILLED';

export const TOGGLE_CREATE_PARTICIPANT_SAC = 'TOGGLE_CREATE_PARTICIPANT_SAC';
