import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  cellComponentLoading: false,
  cellComponent: {
    createdBy: {}
  },
  entityPhotos: []
};

const cellComponentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_CELL_COMPONENT_PENDING: {
      return {
        ...initialState,
        cellComponentLoading: true
      };
    }

    case actionTypes.FIND_CELL_COMPONENT_FULFILLED: {
      return {
        ...state,
        cellComponentLoading: false,
        cellComponent: action.payload.data.result
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        cellComponentLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL_COMPONENT &&
        (entityPhoto.entityId === state.cellComponent.id ||
          entityPhoto.entityTempId === state.cellComponent.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL_COMPONENT &&
        (entityPhoto.entityId === state.cellComponent.id ||
          entityPhoto.entityTempId === state.cellComponent.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL_COMPONENT &&
        (entityPhoto.entityId === state.cellComponent.id ||
          entityPhoto.entityTempId === state.cellComponent.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    default: {
      return state;
    }
  }
};

export default cellComponentDetailsReducer;
