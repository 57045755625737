/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { SubjectAreaContent } from 'components';
import { Card, Typography, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  noItems: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

const SubjectAreaContents = props => {
  const { subjectAreaContents, onDelete, overridden } = props;
  const classes = useStyles();

  const subjectAreaContentsCompare = (a, b) => {
    if (a.subjectArea < b.subjectArea) {
      return -1;
    }
    if (a.subjectArea > b.subjectArea) {
      return 1;
    }
    return 0;
  };

  const contentsByArea = {};
  if (subjectAreaContents) {
    subjectAreaContents.sort(subjectAreaContentsCompare);
    for (let i = 0; i < subjectAreaContents.length; i++) {
      const subjectAreaContent = subjectAreaContents[i];
      const area = subjectAreaContent.subjectArea;
      let contents;
      if (contentsByArea.hasOwnProperty(area)) {
        contents = contentsByArea[area];
      } else {
        contents = [];
        contentsByArea[area] = contents;
      }
      contents.push(subjectAreaContent);
    }
  }

  return (
    <div>
      {(!subjectAreaContents || subjectAreaContents.length === 0) && (
        <Card>
          <CardContent className={classes.noItems}>
            <Typography variant="body1">No Items</Typography>
          </CardContent>
        </Card>
      )}
      {Object.keys(contentsByArea).map((area, i) => (
        <SubjectAreaContent
          key={i}
          onDelete={onDelete}
          subjectArea={area}
          subjectAreaContents={contentsByArea[area]}
          overridden={overridden}
          midItem={i + 1 < Object.keys(contentsByArea).length}
        />
      ))}
    </div>
  );
};

SubjectAreaContents.propTypes = {
  subjectAreaContents: PropTypes.array
};

export default SubjectAreaContents;
