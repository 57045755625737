import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { FilterList } from 'components';
import { Search } from 'components/SearchBar/components';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Button,
  Divider,
  CardHeader,
  Tabs,
  Tab,
  LinearProgress
} from '@material-ui/core';
import {
  findSelectEventDataFiltersDataSets,
  setSelectEventDataSetFiltersOpen,
  setSelectEventDataSetFiltersTab,
  setSelectEventDataSetFiltersSelectedDataSet,
  clearSelectEventDataSetFiltersSelectedDataSet,
  setSelectEventDataFiltersSearch,
  setSelectEventDataSetFiltersFilterSelected
} from 'actions';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1020,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    minHeight: 485,
    maxHeight: 485
  },
  searchContainer: {
    padding: theme.spacing(2)
  },
  selectedRow: {
    background: '#F5F6F8'
  },
  dataSetRow: {
    cursor: 'pointer'
  },
  actionColumn: {
    width: 200
  },
  actionButton: {
    marginBottom: theme.spacing(2)
  },
  expandIcon: {
    marginBottom: -9,
    marginRight: theme.spacing(2)
  }
}));

const SelectEventDataSetFilters = props => {
  const { className, actionTitle, onSelectFilters } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    open,
    eventDataSets,
    loading,
    tab,
    selectedDataSet,
    search
  } = useSelector(state => state.selectEventDataSetFilters);

  const tabs = [
    { value: 'walk_analysis', label: 'Walk Analaysis' },
    { value: 'model_data', label: 'Model Data Set' },
    { value: 'other', label: 'Other' }
  ];

  const tabTypes = {
    walk_analysis: 'WALK_ANALYSIS',
    model_data: 'MODEL_DATA',
    other: null
  };

  const filteredDataSets = eventDataSets
    .filter(dataSet => dataSet.type === tabTypes[tab])
    .filter(
      dataSet =>
        !search || dataSet.name.toUpperCase().indexOf(search.toUpperCase()) > -1
    );

  useEffect(() => {
    let mounted = true;

    if (mounted && open) {
      dispatch(findSelectEventDataFiltersDataSets());
    }

    return () => {
      mounted = false;
    };
  }, [open]);

  if (!open) {
    return null;
  }

  const handleTabChange = (_, tab) => {
    dispatch(setSelectEventDataSetFiltersTab(tab));
  };

  const handleToggleFilter = (filterIndex, selected) => {
    dispatch(setSelectEventDataSetFiltersFilterSelected(filterIndex, selected));
  };

  const selectFilters = () => {
    onSelectFilters([
      ...selectedDataSet.filters.filter(filter => filter.selected)
    ]);
    handleClose();
  };

  const handleSelectRow = dataSet => {
    dispatch(
      selectedDataSet && selectedDataSet.id === dataSet.id
        ? clearSelectEventDataSetFiltersSelectedDataSet()
        : setSelectEventDataSetFiltersSelectedDataSet(dataSet)
    );
  };

  const handleClose = () => {
    dispatch(setSelectEventDataSetFiltersOpen(false));
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader title="Select Walk Collection Filters" />
        <Divider />
        {loading && <LinearProgress />}
        <CardContent className={classes.content}>
          <div className={classes.searchContainer}>
            <Search
              placeholder="Walk Collection Name"
              value={search}
              onSearchValueChange={value =>
                dispatch(setSelectEventDataFiltersSearch(value))
              }
              className={classes.search}
              hideSearchButton={true}
            />
          </div>
          <Divider />

          <Tabs
            className={classes.tabs}
            onChange={handleTabChange}
            scrollButtons="auto"
            value={tab}
            variant="scrollable">
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
          <Divider />

          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Filters</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDataSets.map(dataSet => (
                  <>
                    <TableRow
                      hover
                      key={dataSet.id}
                      className={clsx(
                        classes.dataSetRow,
                        selectedDataSet && selectedDataSet.id === dataSet.id
                          ? classes.selectedRow
                          : ''
                      )}
                      onClick={() => handleSelectRow(dataSet)}>
                      <TableCell>
                        {selectedDataSet &&
                        selectedDataSet.id === dataSet.id ? (
                          <ExpandLessIcon className={classes.expandIcon} />
                        ) : (
                          <ExpandMoreIcon className={classes.expandIcon} />
                        )}
                        {dataSet.name}
                      </TableCell>
                      <TableCell>{dataSet.createdBy?.displayName}</TableCell>
                      <TableCell>{dataSet.nonEmptyFilterCount}</TableCell>
                    </TableRow>
                    {selectedDataSet && selectedDataSet.id === dataSet.id && (
                      <TableRow
                        key={'expanded-' + dataSet.id}
                        className={
                          selectedDataSet && selectedDataSet.id === dataSet.id
                            ? classes.selectedRow
                            : ''
                        }>
                        <TableCell colSpan={3}>
                          {selectedDataSet &&
                            selectedDataSet.id === dataSet.id && (
                              <Button
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={selectFilters}
                                className={classes.actionButton}
                                disabled={
                                  selectedDataSet.selectedFilterCount < 1
                                }>
                                {actionTitle}{' '}
                                {selectedDataSet.selectedFilterCount} Filter
                                {selectedDataSet.selectedFilterCount !== 1
                                  ? 's'
                                  : ''}
                              </Button>
                            )}

                          <FilterList
                            hideEmpty={true}
                            filters={selectedDataSet.filters}
                            onToggleFilter={handleToggleFilter}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SelectEventDataSetFilters.displayName = 'SelectEventDataSetFilters';

SelectEventDataSetFilters.propTypes = {
  className: PropTypes.string
};

SelectEventDataSetFilters.defaultProps = {};

export default SelectEventDataSetFilters;
