/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import {
  colors,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  statusContainer: {
    marginTop: theme.spacing(3) * -1,
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3) * -1,
    marginRight: theme.spacing(3) * -1
  },
  status: {
    '& td': {
      padding: 2,
      borderWidth: 1,
      borderColor: colors.grey[200],
      borderStyle: 'solid',
      borderTopWidth: 0
    }
  },
  completedWalk: {
    backgroundColor: colors.green[600]
  },
  unCompletedWalk: {
    backgroundColor: colors.blue[600]
  }
}));

const SessionWalkProgress = props => {
  const { sessionWalk } = props;
  const totalWalkCount = sessionWalk.setup.walkCount;

  const classes = useStyles();

  const walks = [];
  for (let i = 0; i < totalWalkCount; i++) {
    walks.push({ index: i, completed: sessionWalk.currentWalk - 1 > i });
  }

  return (
    <div className={classes.statusContainer}>
      <Table className={classes.status}>
        <TableBody>
          <TableRow>
            {walks.map(walk => (
              <TableCell
                className={
                  walk.completed
                    ? classes.completedWalk
                    : classes.unCompletedWalk
                }
                key={walk.index}>
                &nbsp;
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

SessionWalkProgress.propTypes = {
  sessionWalk: PropTypes.object
};

export default SessionWalkProgress;
