/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { SESSION_STATUSES } from 'common/constants';
import { Label } from 'components';
import { makeStyles } from '@material-ui/styles';
import {
  LinearProgress,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  findTrainingVideoTraining,
  encodeTrainingWebVideos,
  findTrainingWebVideos
} from 'actions';
import ws from 'utils/ws';

const useStyles = makeStyles(theme => ({
  encodingMessage: {
    textAlign: 'center',
    padding: 26,
    '& div': {
      marginTop: 10
    }
  },
  playbackControls: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  rateControl: {
    marginLeft: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2,
    padding: 0
  },
  videoLoadingProgress: {
    marginBottom: -4
  }
}));

const TrainingVideo = props => {
  const { trainingId, displayPlaybackRate, height, width, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    training,
    webEncodingVideos,
    videos,
    webVideosLoading,
    webVideosLoaded
  } = useSelector(state => state.trainingVideo);
  const videoRef = useRef();

  const [playbackRate, setPlaybackRate] = useState(1);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(findTrainingVideoTraining(trainingId));
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (
    training.id &&
    !training.loading &&
    !webEncodingVideos &&
    !webVideosLoading
  ) {
    if (training.webVideosEncoded !== true) {
      dispatch(encodeTrainingWebVideos(trainingId));
    } else if (!webVideosLoaded) {
      dispatch(findTrainingWebVideos(trainingId));
    }
  }

  const setPlayBack = rate => {
    setPlaybackRate(rate);
    videoRef.current.playbackRate = rate;
  };

  const RateControl = props => (
    <Button
      className={classes.rateControl}
      variant={playbackRate === props.rate ? 'outlined' : 'text'}
      size="small"
      onClick={() => setPlayBack(props.rate)}>
      {props.rate === 0.5 ? '1/2' : props.rate}x
    </Button>
  );

  return (
    <div {...rest}>
      {training.loading && (
        <LinearProgress className={classes.videoLoadingProgress} />
      )}
      {webEncodingVideos && (
        <div
          className={classes.encodingMessage}
          style={{ width: width, height: height }}>
          <Typography component="h2" gutterBottom variant="overline">
            Encoding video for display, this process may take several seconds
          </Typography>
          <CircularProgress />
        </div>
      )}

      {training.id && training.webVideosEncoded && videos.length > 0 && (
        <>
          <div>
            {videos.map(video => (
              <video
                ref={videoRef}
                disablePictureInPicture
                controlsList="nodownload"
                controls
                autoPlay
                loop
                muted
                playsInline
                style={{ width: width, height: height }}
                type="video/mp4"
                key={video.key}
                src={ws.url(
                  '/aws/s3/object?bucket=' +
                    video.bucket +
                    '&objectKey=' +
                    video.key
                )}
              />
            ))}
          </div>
          {displayPlaybackRate && (
            <div className={classes.playbackControls}>
              <RateControl rate={0.5} />
              <RateControl rate={1} />
              <RateControl rate={2} />
              <RateControl rate={3} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

TrainingVideo.propTypes = {
  trainingId: PropTypes.string
};

export default TrainingVideo;
