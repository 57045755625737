import ws from '../utils/ws';

/* Actions */

export const cellPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: CELLS_PAGINATED_LIST,
    payload: ws.get(`/cells/page/${pageNumber}/rows/${pageSize}`)
  });

export const findCellVenueStateCounts = () => dispatch =>
  dispatch({
    type: FIND_CELL_VENUE_STATE_COUNTS,
    payload: ws.get(`/cells/venue_state_counts/`)
  });

export const cellValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CELL_VALUE_UPDATE,
    payload: values
  });

export const cellValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CELL_VALUE_RESET
  });

export const setMigrateCellRadarsOpen = open => dispatch =>
  dispatch({
    type: SET_MIGRATE_CELL_RADARS_OPEN,
    payload: open
  });

export const setSelectedRadarMigrationCell = cell => dispatch =>
  dispatch({
    type: SET_SELECTED_RADAR_MIGRATION_CELL,
    payload: cell
  });

export const selectCellDetailsRadar = radar => dispatch =>
  dispatch({
    type: SELECT_CELL_DETAILS_RADAR,
    payload: radar
  });

export const selectAllCellDetailsRadars = () => dispatch =>
  dispatch({
    type: SELECT_ALL_CELL_DETAILS_RADARS
  });

export const deSelectAllCellDetailsRadars = () => dispatch =>
  dispatch({
    type: DE_SELECT_ALL_CELL_DETAILS_RADARS
  });

export const migrateCellRadars = (fromCell, toCell, radarIds) => dispatch =>
  dispatch({
    type: MIGRATE_CELL_RADARS,
    payload: ws.post(
      `/cells/radars/migrate/from/${fromCell.id}/to/${toCell.id}`,
      radarIds
    )
  });

export const addCellMacAddress = () => dispatch =>
  dispatch({
    type: ADD_CELL_MAC_ADDRESS
  });

export const cellMacAddressValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CELL_MAC_ADDRESS_VALUE_UPDATE,
    payload: values
  });

export const cellMacAddressRemove = index => dispatch =>
  dispatch({
    type: CREATE_CELL_MAC_ADDRESS_REMOVE,
    payload: { index }
  });

export const saveCell = cell => dispatch =>
  dispatch({
    type: SAVE_CELL,
    payload: ws.post('/cells/', cell)
  });

export const deleteCell = cell => dispatch =>
  dispatch({
    type: DELETE_CELL,
    payload: ws.post(`/cells/${cell.id}/delete`)
  });

export const editCell = id => dispatch =>
  dispatch({
    type: EDIT_CELL,
    payload: ws.get(`/cells/${id}`)
  });

export const findCells = () => dispatch =>
  dispatch({
    type: FIND_CELLS,
    payload: ws.get('/cells/')
  });

export const findWalkCollectionCells = dataSetId => dispatch =>
  dispatch({
    type: FIND_CELLS,
    payload: ws.get(`/events/data_sets/${dataSetId}/cells/`)
  });

export const findCell = id => dispatch =>
  dispatch({
    type: FIND_CELL,
    payload: ws.get(`/cells/${id}`)
  });

export const findCellComponentVersionReports = id => dispatch =>
  dispatch({
    type: FIND_CELL_COMPONENT_VERSION_REPORTS,
    payload: ws.get(`/cells/${id}/component_version_reports/`)
  });

export const findCellSoftwarePackageReports = id => dispatch =>
  dispatch({
    type: FIND_CELL_SOFTWARE_PACKAGE_REPORTS,
    payload: ws.get(`/cells/${id}/software_package_reports/`)
  });

export const findCellSoftwarePackageReport = (cellId, reportId) => dispatch =>
  dispatch({
    type: FIND_CELL_SOFTWARE_PACKAGE_REPORT,
    payload: ws.get(`/cells/${cellId}/software_package_reports/${reportId}`)
  });

export const setCellDetailsPackageReportSearch = searchTerm => dispatch =>
  dispatch({
    type: SET_CELL_DETAILS_PACKAGE_REPORT_SEARCH,
    payload: searchTerm
  });

export const clearCellDetailsPackageReportSearch = () => dispatch =>
  dispatch({
    type: CLEAR_CELL_DETAILS_PACKAGE_REPORT_SEARCH
  });

export const findCellSoftwarePackageReportPriorDiff = (
  cellId,
  reportId
) => dispatch =>
  dispatch({
    type: FIND_CELL_SOFTWARE_PACKAGE_REPORT_PRIOR_DIFF,
    payload: ws.get(
      `/cells/${cellId}/software_package_reports/${reportId}/prior_diff`
    )
  });

export const findCellBuild = id => dispatch =>
  dispatch({
    type: FIND_CELL_BUILD,
    payload: ws.get(`/cells/${id}/build/`)
  });

export const clearCellDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_CELL_DELETE_MESSAGE
  });

export const findCellOpenTickets = id => dispatch =>
  dispatch({
    type: FIND_CELL_OPEN_TICKETS,
    payload: ws.get(`/cells/${id}/tickets/open/`)
  });

export const findCellClosedTickets = id => dispatch =>
  dispatch({
    type: FIND_CELL_CLOSED_TICKETS,
    payload: ws.get(`/cells/${id}/tickets/closed/`)
  });

export const setCellBuildComponentStatus = (
  cellId,
  cellBuildComponentGuid,
  status
) => dispatch =>
  dispatch({
    type: SET_CELL_BUILD_COMPONENT_STATUS,
    payload: ws.post(
      `/cells/${cellId}/build/component/${cellBuildComponentGuid}/update_status`,
      { status }
    )
  });

export const setCellDetailsActiveCheckList = cellCheckList => dispatch =>
  dispatch({
    type: SET_CELL_DETAILS_ACTIVE_CELL_CHECK_LIST,
    payload: cellCheckList
  });

export const setCreateCellCheckListOpen = open => dispatch =>
  dispatch({
    type: SET_CREATE_CELL_CHECK_LIST_OPEN,
    payload: open
  });

export const setCellDetailsCompleteCheckListItem = checkListItem => dispatch =>
  dispatch({
    type: SET_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM,
    payload: checkListItem
  });

export const saveCellDetailsCompleteCheckListItem = (
  cellId,
  checkListId,
  checkListItemGroupGuid,
  checkListItem
) => dispatch =>
  dispatch({
    type: SAVE_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM,
    payload: ws.post(
      `/cells/${cellId}/check_lists/${checkListId}/groups/${checkListItemGroupGuid}/items/`,
      checkListItem
    )
  });

export const cellCellLogEntryPaginatedList = (
  cellId,
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: CELL_CELL_LOG_PAGINATED_LIST,
    payload: ws.get(
      `/cells/${cellId}/log_entries/page/${pageNumber}/rows/${pageSize}`
    )
  });

export const setCellDetailsCompleteCheckListItemFieldValue = (
  field,
  value
) => dispatch =>
  dispatch({
    type: SET_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM_FIELD_VALUE,
    payload: {
      field,
      value
    }
  });

export const findCellConfiguration = cellId => dispatch =>
  dispatch({
    type: FIND_LATEST_CELL_CONFIGURATION,
    payload: ws.get(`/cells/${cellId}/configuration/latest`)
  });

export const findCellSystemPropertiesFrom = (cellId, startTime) => dispatch =>
  dispatch({
    type: FIND_CELL_SYSTEM_PROPERTIES_FROM,
    payload: ws.get(`/cells/${cellId}/system_properties/from/${startTime}`)
  });

/* Constants */

export const CELLS_PAGINATED_LIST = 'CELLS_PAGINATED_LIST';
export const CELLS_PAGINATED_LIST_PENDING = 'CELLS_PAGINATED_LIST_PENDING';
export const CELLS_PAGINATED_LIST_FULFILLED = 'CELLS_PAGINATED_LIST_FULFILLED';

export const FIND_CELL_VENUE_STATE_COUNTS = 'FIND_CELL_VENUE_STATE_COUNTS';
export const FIND_CELL_VENUE_STATE_COUNTS_PENDING =
  'FIND_CELL_VENUE_STATE_COUNTS_PENDING';
export const FIND_CELL_VENUE_STATE_COUNTS_FULFILLED =
  'FIND_CELL_VENUE_STATE_COUNTS_FULFILLED';

export const CELL_CELL_LOG_PAGINATED_LIST = 'CELL_CELL_LOG_PAGINATED_LIST';
export const CELL_CELL_LOG_PAGINATED_LIST_PENDING =
  'CELL_CELL_LOG_PAGINATED_LIST_PENDING';
export const CELL_CELL_LOG_PAGINATED_LIST_FULFILLED =
  'CELL_CELL_LOG_PAGINATED_LIST_FULFILLED';

export const CREATE_CELL_VALUE_UPDATE = 'CREATE_CELLS_VALUE_UPDATE';
export const CREATE_CELL_VALUE_RESET = 'CREATE_CELL_VALUE_RESET';

export const ADD_CELL_MAC_ADDRESS = 'ADD_CELL_MAC_ADDRESS';
export const CREATE_CELL_MAC_ADDRESS_VALUE_UPDATE =
  'CREATE_CELL_MAC_ADDRESS_VALUE_UPDATE';
export const CREATE_CELL_MAC_ADDRESS_REMOVE = 'CREATE_CELL_MAC_ADDRESS_REMOVE';

export const SAVE_CELL = 'SAVE_CELL';
export const SAVE_CELL_FULFILLED = 'SAVE_CELL_FULFILLED';

export const DELETE_CELL = 'DELETE_CELL';
export const DELETE_CELL_PENDING = 'DELETE_CELL_PENDING';
export const DELETE_CELL_FULFILLED = 'DELETE_CELL_FULFILLED';

export const EDIT_CELL = 'EDIT_CELL';
export const EDIT_CELL_FULFILLED = 'EDIT_CELL_FULFILLED';

export const FIND_CELLS = 'FIND_CELLS';
export const FIND_CELLS_PENDING = 'FIND_CELLS_PENDING';
export const FIND_CELLS_FULFILLED = 'FIND_CELLS_FULFILLED';

export const FIND_CELL = 'FIND_CELL';
export const FIND_CELL_PENDING = 'FIND_CELL_PENDING';
export const FIND_CELL_FULFILLED = 'FIND_CELL_FULFILLED';

export const CLEAR_CELL_DELETE_MESSAGE = 'CLEAR_CELL_DELETE_MESSAGE';

export const FIND_CELL_COMPONENT_VERSION_REPORTS =
  'FIND_CELL_COMPONENT_VERSION_REPORTS';
export const FIND_CELL_COMPONENT_VERSION_REPORTS_PENDING =
  'FIND_CELL_COMPONENT_VERSION_REPORTS_PENDING';
export const FIND_CELL_COMPONENT_VERSION_REPORTS_FULFILLED =
  'FIND_CELL_COMPONENT_VERSION_REPORTS_FULFILLED';

export const FIND_CELL_SOFTWARE_PACKAGE_REPORTS =
  'FIND_CELL_SOFTWARE_PACKAGE_REPORTS';
export const FIND_CELL_SOFTWARE_PACKAGE_REPORTS_PENDING =
  'FIND_CELL_SOFTWARE_PACKAGE_REPORTS_PENDING';
export const FIND_CELL_SOFTWARE_PACKAGE_REPORTS_FULFILLED =
  'FIND_CELL_SOFTWARE_PACKAGE_REPORTS_FULFILLED';

export const FIND_CELL_SOFTWARE_PACKAGE_REPORT =
  'FIND_CELL_SOFTWARE_PACKAGE_REPORT';
export const FIND_CELL_SOFTWARE_PACKAGE_REPORT_PENDING =
  'FIND_CELL_SOFTWARE_PACKAGE_REPORT_PENDING';
export const FIND_CELL_SOFTWARE_PACKAGE_REPORT_FULFILLED =
  'FIND_CELL_SOFTWARE_PACKAGE_REPORT_FULFILLED';

export const FIND_CELL_SOFTWARE_PACKAGE_REPORT_PRIOR_DIFF =
  'FIND_CELL_SOFTWARE_PACKAGE_REPORT_PRIOR_DIFF';
export const FIND_CELL_SOFTWARE_PACKAGE_REPORT_PRIOR_DIFF_PENDING =
  'FIND_CELL_SOFTWARE_PACKAGE_REPORT_PRIOR_DIFF_PENDING';
export const FIND_CELL_SOFTWARE_PACKAGE_REPORT_PRIOR_DIFF_FULFILLED =
  'FIND_CELL_SOFTWARE_PACKAGE_REPORT_PRIOR_DIFF_FULFILLED';

export const FIND_CELL_BUILD = 'FIND_CELL_BUILD';
export const FIND_CELL_BUILD_PENDING = 'FIND_CELL_BUILD_PENDING';
export const FIND_CELL_BUILD_FULFILLED = 'FIND_CELL_BUILD_FULFILLED';

export const FIND_CELL_CLOSED_TICKETS = 'FIND_CELL_CLOSED_TICKETS';
export const FIND_CELL_CLOSED_TICKETS_PENDING =
  'FIND_CELL_CLOSED_TICKETS_PENDING';
export const FIND_CELL_CLOSED_TICKETS_FULFILLED =
  'FIND_CELL_CLOSED_TICKETS_FULFILLED';

export const FIND_CELL_OPEN_TICKETS = 'FIND_CELL_OPEN_TICKETS';
export const FIND_CELL_OPEN_TICKETS_PENDING =
  'FIND_CELL_OPEN_TICKETS_ALERTS_PENDING';
export const FIND_CELL_OPEN_TICKETS_FULFILLED =
  'FIND_CELL_OPEN_TICKETS_FULFILLED';

export const SET_MIGRATE_CELL_RADARS_OPEN = 'SET_MIGRATE_CELL_RADARS_OPEN';

export const MIGRATE_CELL_RADARS = 'MIGRATE_CELL_RADARS';
export const MIGRATE_CELL_RADARS_PENDING = 'MIGRATE_CELL_RADARS_PENDING';
export const MIGRATE_CELL_RADARS_FULFILLED = 'MIGRATE_CELL_RADARS_FULFILLED';

export const SET_CELL_BUILD_COMPONENT_STATUS =
  'SET_CELL_BUILD_COMPONENT_STATUS';
export const SET_CELL_BUILD_COMPONENT_STATUS_PENDING =
  'SET_CELL_BUILD_COMPONENT_STATUS_PENDING';
export const SET_CELL_BUILD_COMPONENT_STATUS_FULFILLED =
  'SET_CELL_BUILD_COMPONENT_STATUS_FULFILLED';

export const SAVE_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM =
  'SAVE_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM';
export const SAVE_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM_PENDING =
  'SAVE_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM_PENDING';
export const SAVE_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM_FULFILLED =
  'SAVE_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM_FULFILLED';

export const SET_SELECTED_RADAR_MIGRATION_CELL =
  'SET_SELECTED_RADAR_MIGRATION_CELL';

export const SELECT_CELL_DETAILS_RADAR = 'SELECT_CELL_DETAILS_RADAR';

export const SELECT_ALL_CELL_DETAILS_RADARS = 'SELECT_ALL_CELL_DETAILS_RADARS';

export const DE_SELECT_ALL_CELL_DETAILS_RADARS =
  'DE_SELECT_ALL_CELL_DETAILS_RADARS';

export const SET_CELL_DETAILS_ACTIVE_CELL_CHECK_LIST =
  'SET_CELL_DETAILS_ACTIVE_CELL_CHECK_LIST';

export const SET_CREATE_CELL_CHECK_LIST_OPEN =
  'SET_CREATE_CELL_CHECK_LIST_OPEN';

export const SET_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM =
  'SET_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM';

export const SET_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM_FIELD_VALUE =
  'SET_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM_FIELD_VALUE';

export const SET_CELL_DETAILS_PACKAGE_REPORT_SEARCH =
  'SET_CELL_DETAILS_PACKAGE_REPORT_SEARCH';

export const CLEAR_CELL_DETAILS_PACKAGE_REPORT_SEARCH =
  'CLEAR_CELL_DETAILS_PACKAGE_REPORT_SEARCH';

export const FIND_LATEST_CELL_CONFIGURATION = 'FIND_LATEST_CELL_CONFIGURATION';
export const FIND_LATEST_CELL_CONFIGURATION_PENDING =
  'FIND_LATEST_CELL_CONFIGURATION_PENDING';
export const FIND_LATEST_CELL_CONFIGURATION_FULFILLED =
  'FIND_LATEST_CELL_CONFIGURATION_FULFILLED';

export const FIND_CELL_SYSTEM_PROPERTIES_FROM =
  'FIND_CELL_SYSTEM_PROPERTIES_FROM';
export const FIND_CELL_SYSTEM_PROPERTIES_FROM_PENDING =
  'FIND_CELL_SYSTEM_PROPERTIES_FROM_PENDING';
export const FIND_CELL_SYSTEM_PROPERTIES_FROM_FULFILLED =
  'FIND_CELL_SYSTEM_PROPERTIES_FROM_FULFILLED';
