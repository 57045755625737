import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  open: false,
  validationMessages: {},
  cellCheckList: {},
  cellCheckListDefinitions: []
};

const PAGE = 'CELL_LIST';

const createCellCheckListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CREATE_CELL_CHECK_LIST_OPEN: {
      return {
        ...state,
        cellCheckList: initialState.cellCheckList,
        validationMessages: initialState.validationMessages,
        open: action.payload,
        saved: initialState.saved
      };
    }

    case actionTypes.FIND_CELL_CHECK_LIST_DEFINITIONS_FULFILLED: {
      return {
        ...state,
        cellCheckListDefinitions: action.payload.data.result
      };
    }

    case actionTypes.SET_CREATE_CELL_CHECK_LIST_FIELD_VALUE: {
      const { field, value } = action.payload;

      return {
        ...state,
        cellCheckList: {
          ...state.cellCheckList,
          [field]: value
        }
      };
    }

    case actionTypes.SAVE_CELL_CHECK_LIST_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        cellCheckList: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    default: {
      return state;
    }
  }
};

export default createCellCheckListReducer;
