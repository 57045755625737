import * as actionTypes from 'actions';

const initialState = {
  searchTerm: '',
  items: [],
  pageNumber: 1,
  rowCount: 0,
  pageSize: 250,
  loading: false,
  filterItemCount: null,
  filter: {
    search: '',
    validationStatus: '',
    walkStatus: '',
    participantName: '',
    model: '',
    cell: '',
    createdBy: '',
    createdTimeFrom: null,
    createdTimeTo: null,
    validationStatus: null,
    objectPresentAccuracy: [0, 0],
    noObjectAccuracy: [0, 0],
    scenario: {},
    subjectAreaContents: []
  },
  commentsSession: {
    comments: []
  },
  defaultFilterSubjectAreaContent: {
    subjectArea: 'RIGHT_SIDE',
    pocketLocation: '',
    objectType: null,
    objectModel: null
  },
  scenarios: [],
  selectedSessionIds: [],
  objectTypes: [],
  objectModels: [],
  cellsLoading: false,
  cells: [],
  participantsLoading: false,
  participants: [],
  participantSessions: [],
  allSessionsSelected: false,
  selectingSessions: false,
  selectedSessionIds: [],
  settingsComparisonSessions: [],
  firstWalkEntryImage: null
};

const PAGE = 'TRAINING_SESSION_LIST';

const testSessionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRAINING_SESSIONS_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.TRAINING_SESSIONS_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        filter: {
          ...initialState.filter,
          ...action.payload.data.result.filter
        },
        loading: false
      };
    }

    case actionTypes.FIND_FIRST_SESSION_WALK_ENTRY_IMAGE_PENDING: {
      return {
        ...state,
        firstWalkEntryImage: initialState.firstWalkEntryImage
      };
    }

    case actionTypes.FIND_FIRST_SESSION_WALK_ENTRY_IMAGE_FULFILLED: {
      return {
        ...state,
        firstWalkEntryImage: action.payload.data.result
      };
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          pageSize: action.payload.pageSize
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...initialState.filter,
            search: state.filter.search
          },
          filterItemCount: initialState.filterItemCount
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === PAGE
      ) {
        result = {
          ...state,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.filter,
            ...action.payload.data.result.filter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SETUP_SCENARIO_SESSION_SEARCH_TERM_UPDATE: {
      return {
        ...state,
        searchTerm: action.payload
      };
    }

    case actionTypes.SET_TRAINING_SESSION_LIST_COMMENTS_SESSION: {
      return {
        ...state,
        commentsSession: {
          ...action.payload,
          comments: !action.payload.comments ? [] : action.payload.comments
        }
      };
    }

    case actionTypes.ADD_COMMENT_FULFILLED:
    case actionTypes.SAVE_COMMENT_FULFILLED:
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      return {
        ...state,
        commentsSession: {
          ...state.commentsSession,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        }
      };
    }

    case actionTypes.SELECT_TEST_SESSION_LIST_SESSION: {
      const sessionId = action.payload;
      const sessionIds = state.selectedSessionIds.slice();
      const sessionIdIndex = sessionIds.indexOf(sessionId);

      if (sessionIdIndex !== -1) {
        sessionIds.splice(sessionIdIndex, 1);
      } else {
        sessionIds.push(sessionId);
      }

      return {
        ...state,
        selectedSessionIds: sessionIds
      };
    }

    case actionTypes.CLEAR_TRAINING_SESSION_LIST_SELECTED_SESSIONS: {
      return {
        ...state,
        selectedSessionIds: []
      };
    }

    case actionTypes.ADD_SESSION_FILTER_SUBJECT_AREA_CONTENT: {
      if (action.payload.page === PAGE) {
        const { filter } = state;

        const subjectAreaContents = filter.subjectAreaContents
          ? filter.subjectAreaContents.slice()
          : [];

        subjectAreaContents.splice(subjectAreaContents.length, 0, {
          ...initialState.defaultFilterSubjectAreaContent
        });
        filter.subjectAreaContents = subjectAreaContents;

        return {
          ...state,
          filter: {
            ...state.filter,
            subjectAreaContents: subjectAreaContents
          }
        };
      } else {
        return state;
      }
    }

    case actionTypes.SESSION_FILTER_SUBJECT_AREA_CONTENT_UPDATE: {
      if (action.payload.page === PAGE) {
        const { filter } = state;
        const subjectAreaContents = filter.subjectAreaContents.slice();

        let subjectAreaContent =
          subjectAreaContents[action.payload.values.subjectAreaContentIndex];

        subjectAreaContents[action.payload.values.subjectAreaContentIndex] = {
          ...subjectAreaContent,
          ...action.payload.values
        };

        return {
          ...state,
          filter: {
            ...state.filter,
            subjectAreaContents: subjectAreaContents
          }
        };
      } else {
        return state;
      }
    }

    case actionTypes.REMOVE_SESSION_FILTER_SUBJECT_AREA_CONTENT: {
      if (action.payload.page === PAGE) {
        const { filter } = state;
        const { subjectAreaContents } = filter;

        subjectAreaContents.splice(action.payload.index, 1);

        return {
          ...state,
          filter: {
            ...state.filter,
            subjectAreaContents: subjectAreaContents
          }
        };
      } else {
        return state;
      }
    }

    case actionTypes.FIND_SESSION_LIST_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data.result
      };
    }

    case actionTypes.FIND_SESSION_LIST_OBJECT_MODELS_FULFILLED: {
      return {
        ...state,
        objectModels: action.payload.data.result
      };
    }

    case actionTypes.FIND_SETUP_SCENARIOS_FULFILLED: {
      return {
        ...state,
        scenarios: action.payload.data.result
      };
    }

    case actionTypes.FIND_CELLS_PENDING: {
      return {
        ...state,
        cells: initialState.cells,
        cellsLoading: true
      };
    }

    case actionTypes.FIND_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result,
        cellsLoading: false
      };
    }

    case actionTypes.FIND_SESSION_PARTICIPANTS_PENDING: {
      return {
        ...state,
        participants: initialState.participants,
        participantsLoading: true
      };
    }

    case actionTypes.FIND_SESSION_PARTICIPANTS_FULFILLED: {
      return {
        ...state,
        participants: action.payload.data.result,
        participantsLoading: false
      };
    }

    case actionTypes.SELECT_TRAINING_SESSION_LIST_SESSION: {
      const sessionId = action.payload;
      const sessionIds = state.selectedSessionIds.slice();
      const sessionIdIndex = sessionIds.indexOf(sessionId);

      if (sessionIdIndex !== -1) {
        sessionIds.splice(sessionIdIndex, 1);
      } else {
        sessionIds.push(sessionId);
      }

      return {
        ...state,
        selectedSessionIds: sessionIds
      };
    }

    case actionTypes.DESELECT_ALL_TRAINING_SESSION_LIST_SESSIONS: {
      return {
        ...state,
        selectedSessionIds: initialState.selectedSessionIds,
        selectingSessions: false,
        allSessionsSelected: false
      };
    }

    case actionTypes.SELECT_ALL_TRAINING_SESSION_LIST_SESSIONS_PENDING: {
      return {
        ...state,
        selectingSessions: true
      };
    }

    case actionTypes.SELECT_ALL_TRAINING_SESSION_LIST_SESSIONS_FULFILLED: {
      return {
        ...state,
        selectedSessionIds: action.payload.data.result,
        selectingSessions: false,
        allSessionsSelected: true
      };
    }

    case actionTypes.CLEAR_SESSION_HASHED_SETTINGS_COMPARISON: {
      return {
        ...state,
        settingsComparisonSessions: initialState.settingsComparisonSessions
      };
    }

    case actionTypes.FIND_SESSION_HASHED_SETTINGS_COMPARISON_PENDING: {
      return {
        ...state,
        settingsComparisonSessions: initialState.settingsComparisonSessions
      };
    }

    case actionTypes.FIND_SESSION_HASHED_SETTINGS_COMPARISON_FULFILLED: {
      return {
        ...state,
        settingsComparisonSessions: action.payload.data.result
      };
    }

    case actionTypes.FIND_PARTICIPANT_SESSIONS: {
      const { participantId, sessions } = action.payload;

      return {
        ...state,
        selectedParticipantId: participantId,
        participantSessions: sessions
      };
    }

    default: {
      return state;
    }
  }
};

export default testSessionListReducer;
