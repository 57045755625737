import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Divider,
  TableContainer
} from '@material-ui/core';
import { NumberFormat } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    minHeight: 300
  },
  container: {
    maxHeight: 275
  }
}));

const EventDataSetSummary = props => {
  const { className, eventDataSetSummary } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader title="Walks" />
            <Divider />
            <CardContent className={classes.content}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Walks</TableCell>
                    <TableCell>%</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Walks</TableCell>
                    <TableCell>
                      <NumberFormat
                        value={eventDataSetSummary.totalEventCount}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell>Object Walks</TableCell>
                    <TableCell>
                      <NumberFormat
                        value={eventDataSetSummary.objectPresentEventCount}
                      />
                    </TableCell>
                    <TableCell>
                      {eventDataSetSummary.totalEventCount > 0 && (
                        <NumberFormat
                          value={
                            eventDataSetSummary.objectPresentEventCount /
                            eventDataSetSummary.totalEventCount
                          }
                          percentage
                          suffix="%"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>No Object Walks</TableCell>
                    <TableCell>
                      <NumberFormat
                        value={eventDataSetSummary.noObjectEventCount}
                      />
                    </TableCell>
                    <TableCell>
                      {eventDataSetSummary.totalEventCount > 0 && (
                        <NumberFormat
                          value={
                            eventDataSetSummary.noObjectEventCount /
                            eventDataSetSummary.totalEventCount
                          }
                          percentage
                          suffix="%"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Male Walks</TableCell>
                    <TableCell>
                      <NumberFormat
                        value={eventDataSetSummary.maleEventCount}
                      />
                    </TableCell>
                    <TableCell>
                      {eventDataSetSummary.totalEventCount > 0 && (
                        <NumberFormat
                          value={
                            eventDataSetSummary.maleEventCount /
                            eventDataSetSummary.totalEventCount
                          }
                          percentage
                          suffix="%"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Female Walks</TableCell>
                    <TableCell>
                      <NumberFormat
                        value={eventDataSetSummary.femaleEventCount}
                      />
                    </TableCell>
                    <TableCell>
                      {eventDataSetSummary.totalEventCount > 0 && (
                        <NumberFormat
                          value={
                            eventDataSetSummary.femaleEventCount /
                            eventDataSetSummary.totalEventCount
                          }
                          percentage
                          suffix="%"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader title="Object Types" />
            <Divider />
            <CardContent className={classes.content}>
              <TableContainer className={classes.container}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Object Type</TableCell>
                      <TableCell>Walks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventDataSetSummary.objectTypeCounts &&
                      eventDataSetSummary.objectTypeCounts.map(
                        objectTypeCount => (
                          <TableRow key={objectTypeCount.objectType.id}>
                            <TableCell>
                              {objectTypeCount.objectType.name}
                            </TableCell>
                            <TableCell>
                              <NumberFormat value={objectTypeCount.count} />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    {(!eventDataSetSummary.objectTypeCounts ||
                      eventDataSetSummary.objectTypeCounts.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={2}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader title="Scenarios" />
            <Divider />
            <CardContent className={classes.content}>
              <TableContainer className={classes.container}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Scenario</TableCell>
                      <TableCell>Walks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventDataSetSummary.scenarioCounts &&
                      eventDataSetSummary.scenarioCounts.map(scenarioCount => (
                        <TableRow key={scenarioCount.scenario.id}>
                          <TableCell>{scenarioCount.scenario.name}</TableCell>
                          <TableCell>
                            <NumberFormat value={scenarioCount.count} />
                          </TableCell>
                        </TableRow>
                      ))}
                    {(!eventDataSetSummary.scenarioCounts ||
                      eventDataSetSummary.scenarioCounts.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={2}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader title="Participants" />
            <Divider />
            <CardContent className={classes.content}>
              <TableContainer className={classes.container}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Participant</TableCell>
                      <TableCell>Walks</TableCell>
                      <TableCell>%</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventDataSetSummary.participantCounts &&
                      eventDataSetSummary.participantCounts.map(
                        participantCount => (
                          <TableRow key={participantCount.participant.id}>
                            <TableCell>
                              {participantCount.participant.name}
                            </TableCell>
                            <TableCell>
                              <NumberFormat value={participantCount.count} />
                            </TableCell>
                            <TableCell>
                              {eventDataSetSummary.totalEventCount > 0 && (
                                <NumberFormat
                                  value={
                                    participantCount.count /
                                    eventDataSetSummary.totalEventCount
                                  }
                                  percentage
                                  suffix="%"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    {(!eventDataSetSummary.participantCounts ||
                      eventDataSetSummary.participantCounts.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={3}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader title="Customer Events" />
            <Divider />
            <CardContent className={classes.content}>
              <TableContainer className={classes.container}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Customer Event</TableCell>
                      <TableCell>Walks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventDataSetSummary.customerEventCounts &&
                      eventDataSetSummary.customerEventCounts.map(
                        customerEventCount => (
                          <TableRow key={customerEventCount.customerEvent.id}>
                            <TableCell>
                              {customerEventCount.customerEvent.name}
                            </TableCell>
                            <TableCell>
                              <NumberFormat value={customerEventCount.count} />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    {(!eventDataSetSummary.customerEventCounts ||
                      eventDataSetSummary.customerEventCounts.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={2}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

EventDataSetSummary.propTypes = {
  className: PropTypes.string
};

export default EventDataSetSummary;
