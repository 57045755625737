import ws from '../utils/ws';

/* Actions */

export const cellLogEntryPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: CELL_LOG_ENTRIES_PAGINATED_LIST,
    payload: ws.get(`/cell_log_entries/page/${pageNumber}/rows/${pageSize}`)
  });

export const findCellLogEntries = cellId => dispatch =>
  dispatch({
    type: FIND_CELL_LOG_ENTRIES,
    payload: ws.get(`/cells/${cellId}/log_entries/`)
  });

/* Constants */

export const CELL_LOG_ENTRIES_PAGINATED_LIST =
  'CELL_LOG_ENTRIES_PAGINATED_LIST';
export const CELL_LOG_ENTRIES_PAGINATED_LIST_PENDING =
  'CELL_LOG_ENTRIES_PAGINATED_LIST_PENDING';
export const CELL_LOG_ENTRIES_PAGINATED_LIST_FULFILLED =
  'CELL_LOG_ENTRIES_PAGINATED_LIST_FULFILLED';

export const FIND_CELL_LOG_ENTRIES = 'FIND_CELL_LOG_ENTRIES';
export const FIND_CELL_LOG_ENTRIES_PENDING = 'FIND_CELL_LOG_ENTRIES_PENDING';
export const FIND_CELL_LOG_ENTRIES_FULFILLED =
  'FIND_CELL_LOG_ENTRIES_FULFILLED';
