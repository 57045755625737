import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  deleted: false,
  savedScenario: {},
  scenario: {
    name: '',
    description: '',
    participant: '',
    setupWalkCount: '',
    category: 'USER',
    setups: [
      {
        index: 0,
        entryFoot: 'BOTH_FEET',
        setupWalkCount: '',
        subjectAreaContents: [
          {
            subjectArea: 'RIGHT_SIDE',
            pocketLocation: '',
            objectType: null,
            objectModel: null
          }
        ]
      }
    ],
    type: null
  },
  defaultSetup: {
    index: 0,
    entryFoot: 'BOTH_FEET',
    setupWalkCount: '',
    subjectAreaContents: [
      {
        subjectArea: 'RIGHT_SIDE',
        pocketLocation: '',
        objectType: null,
        objectModel: null
      }
    ]
  },
  defaultSubjectAreaContent: {
    subjectArea: 'RIGHT_SIDE',
    pocketLocation: '',
    objectType: null,
    objectModel: null
  },
  referenceDataSetupSubectAreaContent: null,
  objectTypes: [],
  objectModels: [],
  validationMessages: {}
};

const setupScenarioCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SETUP_SCENARIO_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedScenario: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.EDIT_SETUP_SCENARIO_FULFILLED: {
      return {
        ...state,
        scenario: action.payload.data.result
      };
    }

    case actionTypes.FIND_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data.result
      };
    }

    case actionTypes.FIND_OBJECT_MODELS_FULFILLED: {
      return {
        ...state,
        objectModels: action.payload.data.result
      };
    }

    case actionTypes.FIND_SETUP_SCENARIO_PENDING: {
      return {
        ...state,
        saved: false
      };
    }

    case actionTypes.DELETE_SETUP_SCENARIO_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deletedSetupScenario: action.payload.data.result
      };
    }

    case actionTypes.SET_CREATE_SCENARIO_TYPE: {
      return {
        ...state,
        scenario: {
          ...state.scenario,
          type: action.payload.scenarioType.code
        }
      };
    }

    case actionTypes.SAVE_OBJECT_TYPE_FULFILLED: {
      let newState;
      if (state.referenceDataSetupSubectAreaContent) {
        const setups = state.scenario.setups;
        const setup =
          setups[state.referenceDataSetupSubectAreaContent.setup.index];
        const subjectAreaContent =
          setup.subjectAreaContents[
            state.referenceDataSetupSubectAreaContent.subjectAreaContentIndex
          ];
        subjectAreaContent.objectType = action.payload.data.result;

        newState = {
          ...state,
          scenario: {
            ...state.scenario,
            setups: setups
          }
        };
      } else {
        newState = state;
      }

      return newState;
    }

    case actionTypes.SAVE_OBJECT_MODEL_FULFILLED: {
      let newState;
      if (state.referenceDataSetupSubectAreaContent) {
        const setups = state.scenario.setups;
        const setup =
          setups[state.referenceDataSetupSubectAreaContent.setup.index];
        const subjectAreaContent =
          setup.subjectAreaContents[
            state.referenceDataSetupSubectAreaContent.subjectAreaContentIndex
          ];
        subjectAreaContent.objectModel = action.payload.data.result;

        newState = {
          ...state,
          scenario: {
            ...state.scenario,
            setups: setups
          }
        };
      } else {
        newState = state;
      }

      return newState;
    }

    case actionTypes.CREATE_SETUP_SCENARIO_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_SETUP_SCENARIO_VALUE_UPDATE: {
      return {
        ...state,
        scenario: {
          ...state.scenario,
          ...action.payload
        }
      };
    }

    case actionTypes.CREATE_SETUP_SCENARIO_SETUP_ADD: {
      const setups = state.scenario.setups.slice();
      setups.splice(setups.length, 0, {
        ...initialState.defaultSetup,
        index: setups.length
      });

      return {
        ...state,
        scenario: {
          ...state.scenario,
          setups: setups
        }
      };
    }

    case actionTypes.CREATE_SETUP_SCENARIO_SETUP_REMOVE: {
      const setups = state.scenario.setups.slice();
      setups.splice(action.payload.index, 1);

      for (let i = 0; i < setups.length; i++) {
        setups[i].index = i;
      }

      return {
        ...state,
        scenario: {
          ...state.scenario,
          setups: setups
        }
      };
    }

    case actionTypes.CREATE_SETUP_SCENARIO_SETUP_SAC_ADD: {
      const setups = state.scenario.setups.slice();
      let setup = { ...setups[action.payload.index] };

      setups.splice(action.payload.index, 1, setup);

      const subjectAreaContents = setup.subjectAreaContents
        ? setup.subjectAreaContents.slice()
        : [];

      subjectAreaContents.splice(subjectAreaContents.length, 0, {
        ...initialState.defaultSubjectAreaContent
      });
      setup.subjectAreaContents = subjectAreaContents;

      return {
        ...state,
        scenario: {
          ...state.scenario,
          setups: setups
        }
      };
    }

    case actionTypes.CREATE_SETUP_SCENARIO_SETUP_SAC_REMOVE: {
      const setups = [...state.scenario.setups];
      let setup = setups[action.payload.setup.index];

      const { subjectAreaContents } = setup;
      subjectAreaContents.splice(action.payload.subjectAreaContent.index, 1);

      setups[action.payload.index] = {
        ...setup,
        ...action.payload
      };

      return {
        ...state,
        scenario: {
          ...state.scenario,
          setups: setups
        }
      };
    }

    case actionTypes.CREATE_SETUP_SCENARIO_SETUP_UPDATE: {
      const setups = [...state.scenario.setups];
      let setup = setups[action.payload.setupIndex];

      setups[action.payload.setupIndex] = {
        ...setup,
        ...action.payload
      };

      return {
        ...state,
        scenario: {
          ...state.scenario,
          setups: setups
        }
      };
    }

    case actionTypes.CREATE_SETUP_SCENARIO_SETUP_SAC_UPDATE: {
      const setups = [...state.scenario.setups];
      let setup = setups[action.payload.setupIndex];

      const subjectAreaContents = [...setup.subjectAreaContents];

      let subjectAreaContent =
        subjectAreaContents[action.payload.subjectAreaContentIndex];

      subjectAreaContents[action.payload.subjectAreaContentIndex] = {
        ...subjectAreaContent,
        ...action.payload
      };

      setups[action.payload.setupIndex] = {
        ...setup,
        subjectAreaContents: subjectAreaContents
      };

      return {
        ...state,
        scenario: {
          ...state.scenario,
          setups: setups
        }
      };
    }

    case actionTypes.CREATE_SETUP_SCENARIO_SETUP_INDEX_UPDATE: {
      const setups = [...state.scenario.setups];
      const { oldIndex, newIndex } = action.payload;

      const rearrangedSetups = arrayMove(setups, oldIndex, newIndex);

      for (let i = 0; i < rearrangedSetups.length; i++) {
        const setup = rearrangedSetups[i];
        setup.index = i;
      }

      return {
        ...state,
        scenario: {
          ...state.scenario,
          setups: rearrangedSetups
        }
      };
    }

    case actionTypes.SET_SCENARIO_REFERENCE_DATA_SETUP_SAC: {
      return {
        ...state,
        referenceDataSetupSubectAreaContent: {
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export default setupScenarioCreateReducer;
