/* Actions */

export const setCameraCaptureFacingMode = facingMode => dispatch =>
  dispatch({
    type: SET_CAMERA_CAPTURE_FACING_MODE,
    payload: facingMode
  });

export const setCameraCaptureMirrored = mirrored => dispatch =>
  dispatch({
    type: SET_CAMERA_CAPTURE_MIRRORED,
    payload: mirrored
  });

export const setCameraCaptureCameraError = error => dispatch =>
  dispatch({
    type: SET_CAMERA_CAPTURE_CAMERA_ERROR,
    payload: error
  });

export const setEntityPhotoCaptureValues = values => dispatch =>
  dispatch({
    type: SET_ENTITY_PHOTO_CAPTURE_VALUES,
    payload: values
  });

export const setEntityPhotoCaptureCameraOpen = open => dispatch =>
  dispatch({
    type: SET_CAMERA_CAPTURE_OPEN,
    payload: open
  });

export const clearEntityPhotoCaptureValues = () => dispatch =>
  dispatch({
    type: CLEAR_ENTITY_PHOTO_CAPTURE_VALUES
  });

export const setEntityPhotoCaptureEntityDetails = values => dispatch =>
  dispatch({
    type: SET_ENTITY_PHOTO_CAPTURE_ENTITY_DETAILS,
    payload: values
  });

/* Constants */

export const SET_CAMERA_CAPTURE_FACING_MODE = 'SET_CAMERA_CAPTURE_FACING_MODE';
export const SET_CAMERA_CAPTURE_MIRRORED = 'SET_CAMERA_CAPTURE_MIRRORED';
export const SET_CAMERA_CAPTURE_CAMERA_ERROR =
  'SET_CAMERA_CAPTURE_CAMERA_ERROR';
export const SET_CAMERA_CAPTURE_DATA_URI = 'SET_CAMERA_CAPTURE_DATA_URI';

export const SET_ENTITY_PHOTO_CAPTURE_VALUES =
  'SET_ENTITY_PHOTO_CAPTURE_VALUES';

export const CLEAR_ENTITY_PHOTO_CAPTURE_VALUES =
  'CLEAR_ENTITY_PHOTO_CAPTURE_VALUES';

export const SET_ENTITY_PHOTO_CAPTURE_ENTITY_DETAILS =
  'SET_ENTITY_PHOTO_CAPTURE_ENTITY_DETAILS';

export const SET_CAMERA_CAPTURE_OPEN = 'SET_CAMERA_CAPTURE_OPEN';
