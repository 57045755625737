import * as actionTypes from 'actions';

const initialState = {
  pageNumber: 1,
  rowCount: 0,
  loading: false,
  items: [],
  lastUpdatedTime: null,
  pageSize: 250,
  filterItemCount: null,
  filter: {
    search: ''
  },
  customers: [],
  customersLoading: false,
  masterVersions: [],
  masterVersionsLoading: false
};

const PAGE = 'CELL_HEALTH';

const cellHealthReportListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CELL_HEALTH_REPORT_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CELL_HEALTH_REPORT_LIST_FULFILLED: {
      return {
        ...state,
        items: action.payload.data.result,
        lastUpdatedTime: new Date(),
        // filter: {
        //   ...initialState.filter,
        //   ...action.payload.data.result.filter
        // },
        loading: false
      };
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          pageSize: action.payload.pageSize
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...initialState.filter,
            search: state.filter.search
          },
          filterItemCount: initialState.filterItemCount
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === PAGE
      ) {
        result = {
          ...state,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.filter,
            ...action.payload.data.result.filter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers,
        customersLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data,
        customersLoading: false
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_PENDING: {
      return {
        ...state,
        masterVersions: initialState.customers,
        masterVersionsLoading: true
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_FULFILLED: {
      return {
        ...state,
        masterVersions: action.payload.data.result,
        masterVersionsLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default cellHealthReportListReducer;
