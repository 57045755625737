import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  saved: false,
  savedCell: {},
  deleted: false,
  deleteMessage: null,
  deletedCell: {},
  cell: {
    creationId: null,
    name: '',
    description: '',
    sharedIdentifier: '',
    customer: null,
    location: '',
    radarPlacement: null,
    runwayWidth: null,
    model: null,
    clientTeamViewerAlias: '',
    serverTeamViewerAlias: '',
    status: 'PRE_BUILD',
    monitId: '',
    macAddresses: [],
    identifiersCreated: false
  },
  entityPhotos: [],
  masterVersions: [],
  cellModelVersions: [],
  validationMessages: {},
  defaultMacAddress: {
    hostType: 'CLIENT',
    address: ''
  }
};

const cellCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CELL_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedCell: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CELL_PENDING: {
      return {
        ...state,
        deleted: initialState.deleted,
        deletedCell: initialState.deletedCell
      };
    }

    case actionTypes.DELETE_CELL_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deleteMessage: action.payload.data.message,
        deletedCell: action.payload.data.result
      };
    }

    case actionTypes.EDIT_CELL_FULFILLED: {
      return {
        ...state,
        cell: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_CELL_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_CELL_VALUE_UPDATE: {
      return {
        ...state,
        cell: {
          ...state.cell,
          ...action.payload
        }
      };
    }

    case actionTypes.ADD_CELL_MAC_ADDRESS: {
      const macAddresses = state.cell.macAddresses
        ? state.cell.macAddresses.slice()
        : [];
      macAddresses.push(initialState.defaultMacAddress);

      return {
        ...state,
        cell: {
          ...state.cell,
          macAddresses: macAddresses
        }
      };
    }

    case actionTypes.CREATE_CELL_MAC_ADDRESS_VALUE_UPDATE: {
      const macAddresses = state.cell.macAddresses
        ? state.cell.macAddresses.slice()
        : [];
      const macAddress = {
        ...macAddresses[action.payload.index],
        ...action.payload
      };
      macAddresses[action.payload.index] = macAddress;

      return {
        ...state,
        cell: {
          ...state.cell,
          macAddresses: macAddresses
        }
      };
    }

    case actionTypes.CREATE_CELL_MAC_ADDRESS_REMOVE: {
      const macAddresses = state.cell.macAddresses.slice();
      macAddresses.splice(action.payload.index, 1);

      return {
        ...state,
        cell: {
          ...state.cell,
          macAddresses: macAddresses
        }
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL &&
        (entityPhoto.entityId === state.cell.id ||
          entityPhoto.entityTempId === state.cell.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL &&
        (entityPhoto.entityId === state.cell.id ||
          entityPhoto.entityTempId === state.cell.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL &&
        (entityPhoto.entityId === state.cell.id ||
          entityPhoto.entityTempId === state.cell.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        cellLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_PENDING: {
      return {
        ...state,
        masterVersions: initialState.masterVersions
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_FULFILLED: {
      return {
        ...state,
        masterVersions: action.payload.data.result
      };
    }

    case actionTypes.FIND_CELL_MODEL_VERSIONS_PENDING: {
      return {
        ...state,
        cellModelVersions: initialState.cellModelVersions
      };
    }

    case actionTypes.FIND_CELL_MODEL_VERSIONS_FULFILLED: {
      return {
        ...state,
        cellModelVersions: action.payload.data.result
      };
    }

    case actionTypes.CLEAR_CELL_DELETE_MESSAGE: {
      return {
        ...state,
        deleteMessage: initialState.deleteMessage
      };
    }

    default: {
      return state;
    }
  }
};

export default cellCreateReducer;
