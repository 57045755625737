import ws from '../utils/ws';

export const setupScenarioValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_VALUE_UPDATE,
    payload: values
  });

export const setupScenarioValueReset = () => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_VALUE_RESET
  });

export const setupScenarioSetupUpdate = values => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_SETUP_UPDATE,
    payload: values
  });

export const setupScenarioSetupSubjectAreaContentUpdate = values => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_SETUP_SAC_UPDATE,
    payload: values
  });

export const setupScenarioSetupIndexUpdate = (oldIndex, newIndex) => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_SETUP_INDEX_UPDATE,
    payload: { oldIndex: oldIndex, newIndex: newIndex }
  });

export const setupScenarioSetupAdd = () => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_SETUP_ADD
  });

export const setupScenarioSetupRemove = setup => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_SETUP_REMOVE,
    payload: setup
  });

export const setupScenarioSetupSubectAreaContentsAdd = setup => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_SETUP_SAC_ADD,
    payload: setup
  });

export const setupScenarioSetupSubectAreaContentsRemove = (
  setup,
  subjectAreaContentIndex
) => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_SETUP_SAC_REMOVE,
    payload: {
      setup: setup,
      subjectAreaContent: { index: subjectAreaContentIndex }
    }
  });

export const setupScenarioSequenceUpdate = values => dispatch =>
  dispatch({
    type: CREATE_SETUP_SCENARIO_SEQUENCE_UPDATE,
    payload: values
  });

export const setupScenarioSearchTermUpdate = searchTerm => dispatch =>
  dispatch({
    type: SETUP_SCENARIO_SEARCH_TERM_UPDATE,
    payload: searchTerm
  });

export const scenarioPaginatedList = (
  scenarioType,
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: SCENARIOS_PAGINATED_LIST,
    payload: ws.get(
      `/setup_scenarios/${scenarioType}/page/${pageNumber}/rows/${pageSize}`
    )
  });

export const findSetupScenario = id => dispatch =>
  dispatch({
    type: FIND_SETUP_SCENARIO,
    payload: ws.get('/setup_scenarios/' + id)
  });

export const editSetupScenario = id => dispatch =>
  dispatch({
    type: EDIT_SETUP_SCENARIO,
    payload: ws.get('/setup_scenarios/' + id)
  });

export const findSetupScenarioEvents = id => dispatch =>
  dispatch({
    type: FIND_SETUP_SCENARIO_EVENTS,
    payload: ws.get('/setup_scenarios/' + id + '/events/')
  });

export const findSetupScenarios = scenarioType => dispatch =>
  dispatch({
    type: FIND_SETUP_SCENARIOS,
    payload: ws.get(`/setup_scenarios/?scenarioType=${scenarioType}`)
  });

export const findSessionsForScenario = id => dispatch =>
  dispatch({
    type: FIND_SESSIONS_FOR_SCENARIO,
    payload: ws.get('/setup_scenarios/' + id + '/sessions/')
  });

export const saveSetupScenario = scenario => dispatch =>
  dispatch({
    type: SAVE_SETUP_SCENARIO,
    payload: ws.post('/setup_scenarios/', scenario)
  });

export const deleteSetupScenario = scenario => dispatch =>
  dispatch({
    type: DELETE_SETUP_SCENARIO,
    payload: ws.post(`/setup_scenarios/${scenario.id}/delete`)
  });

export const findSetupScenarioAnalysisOverview = (
  scenarioId,
  modelConfiguration,
  analysisBias,
  recalculate,
  calculateOptimalAccuracySumBias
) => dispatch =>
  dispatch({
    type: FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW,
    payload: ws.get(
      '/setup_scenarios/' +
        scenarioId +
        '/analysis/overview' +
        '?1=1' +
        (modelConfiguration && modelConfiguration.id
          ? '&modelConfigurationId=' + modelConfiguration.id
          : '') +
        '&bias=' +
        analysisBias +
        (recalculate ? '&recalculate=true' : '') +
        (calculateOptimalAccuracySumBias
          ? '&calculateOptimalAccuracySumBias=true'
          : '')
    )
  });

export const findScenarioSetupMatlabData = (
  scenarioId,
  setupIndex
) => dispatch =>
  dispatch({
    type: FIND_SCENARIO_SETUP_MATLAB_DATA,
    payload: ws.get(
      `/setup_scenarios/${scenarioId}/setups/${setupIndex}/matlab_data`
    )
  });

export const setSetupScenarioAnalysisSessions = sessions => dispatch =>
  dispatch({
    type: SET_SETUP_SCENARIO_ANALYSIS_SESSION,
    payload: sessions
  });

export const setSetupScenarioDetailsAnalysisBias = bias => dispatch =>
  dispatch({
    type: SET_SETUP_SCENARIO_DETAILS_ANALYSIS_BIAS,
    payload: bias
  });

export const setSetupScenarioDetailsCalculateMaxAccuracySumBias = calculateMaxAccuracySumBias => dispatch =>
  dispatch({
    type: SET_SETUP_SCENARIO_DETAILS_CALCULATE_MAX_ACCURACY_SUM_BIAS,
    payload: calculateMaxAccuracySumBias
  });

export const setScenarioAnalysisOverviewExcludeSubjectArea = (
  subjectArea,
  exclude
) => dispatch =>
  dispatch({
    type: SET_SCENARIO_ANALYSIS_OVERVIEW_EXCLUDE_SUBJECT_AREA,
    payload: { subjectArea: subjectArea, exclude: exclude }
  });

export const setScenarioAnalysisOverviewExcludeNoObjectEvents = exclude => dispatch =>
  dispatch({
    type: SET_SCENARIO_ANALYSIS_OVERVIEW_EXCLUDE_NO_OBJECT_EVENTS,
    payload: { exclude: exclude }
  });

export const setSetupScenarioReferenceDataSetupSubectAreaContent = (
  setup,
  subjectAreaContentIndex
) => dispatch =>
  dispatch({
    type: SET_SCENARIO_REFERENCE_DATA_SETUP_SAC,
    payload: { setup: setup, subjectAreaContentIndex: subjectAreaContentIndex }
  });

export const setScenarioListType = scenarioType => dispatch =>
  dispatch({
    type: SET_SCENARIO_LIST_TYPE,
    payload: { scenarioType: scenarioType }
  });

export const setCreateScenarioType = scenarioType => dispatch =>
  dispatch({
    type: SET_CREATE_SCENARIO_TYPE,
    payload: { scenarioType: scenarioType }
  });

export const setScenarioDetailsModelConfiguration = modelConfiguration => dispatch =>
  dispatch({
    type: SET_SCENARIO_DETAILS_MODEL_CONFIGURATION,
    payload: modelConfiguration
  });

export const selectScenarioListScenario = scenario => dispatch =>
  dispatch({
    type: SELECT_SCENARIO_LIST_SCENARIO,
    payload: scenario
  });

export const clearScenarioListSelectedScenarios = () => dispatch =>
  dispatch({
    type: CLEAR_SCENARIO_LIST_SELECTED_SCENARIOS
  });

/* Action Constants */

export const FIND_SETUP_SCENARIO = 'FIND_SETUP_SCENARIO';
export const FIND_SETUP_SCENARIO_PENDING = 'FIND_SETUP_SCENARIO_PENDING';
export const FIND_SETUP_SCENARIO_FULFILLED = 'FIND_SETUP_SCENARIO_FULFILLED';
export const FIND_SETUP_SCENARIO_EVENTS = 'FIND_SETUP_SCENARIO_EVENTS';
export const FIND_SETUP_SCENARIO_EVENTS_FULFILLED =
  'FIND_SETUP_SCENARIO_EVENTS_FULFILLED';
export const FIND_SESSIONS_FOR_SCENARIO = 'FIND_SESSIONS_FOR_SCENARIO';
export const FIND_SESSIONS_FOR_SCENARIO_PENDING =
  'FIND_SESSIONS_FOR_SCENARIO_PENDING';
export const FIND_SESSIONS_FOR_SCENARIO_FULFILLED =
  'FIND_SESSIONS_FOR_SCENARIO_FULFILLED';
export const FIND_SETUP_SCENARIO_SETUP_ANALYSIS =
  'FIND_SETUP_SCENARIO_SETUP_ANALYSIS';
export const FIND_SETUP_SCENARIO_SETUP_ANALYSIS_PENDING =
  'FIND_SETUP_SCENARIO_SETUP_ANALYSIS_PENDING';
export const FIND_SETUP_SCENARIO_SETUP_ANALYSIS_FULFILLED =
  'FIND_SETUP_SCENARIO_SETUP_ANALYSIS_FULFILLED';
export const FIND_SETUP_SCENARIOS = 'FIND_SETUP_SCENARIOS';
export const FIND_SETUP_SCENARIOS_PENDING = 'FIND_SETUP_SCENARIOS_PENDING';
export const FIND_SETUP_SCENARIOS_FULFILLED = 'FIND_SETUP_SCENARIOS_FULFILLED';
export const CREATE_SETUP_SCENARIO_VALUE_UPDATE =
  'CREATE_SETUP_SCENARIO_VALUE_UPDATE';
export const CREATE_SETUP_SCENARIO_VALUE_RESET =
  'CREATE_SETUP_SCENARIO_VALUE_RESET';
export const SETUP_SCENARIO_SEARCH_TERM_UPDATE =
  'SETUP_SCENARIO_SEARCH_TERM_UPDATE';
export const CREATE_SETUP_SCENARIO_SETUP_UPDATE =
  'CREATE_SETUP_SCENARIO_SETUP_UPDATE';
export const CREATE_SETUP_SCENARIO_SETUP_SAC_UPDATE =
  'CREATE_SETUP_SCENARIO_SETUP_SAC_UPDATE';
export const CREATE_SETUP_SCENARIO_SETUP_INDEX_UPDATE =
  'CREATE_SETUP_SCENARIO_SETUP_INDEX_UPDATE';
export const CREATE_SETUP_SCENARIO_SETUP_ADD =
  'CREATE_SETUP_SCENARIO_SETUP_ADD';
export const CREATE_SETUP_SCENARIO_SETUP_REMOVE =
  'CREATE_SETUP_SCENARIO_SETUP_REMOVE';
export const CREATE_SETUP_SCENARIO_SETUP_SAC_ADD =
  'CREATE_SETUP_SCENARIO_SETUP_SAC_ADD';
export const CREATE_SETUP_SCENARIO_SETUP_SAC_REMOVE =
  'CREATE_SETUP_SCENARIO_SETUP_SAC_REMOVE';
export const CREATE_SETUP_SCENARIO_SEQUENCE_UPDATE =
  'CREATE_SETUP_SCENARIO_SEQUENCE_UPDATE';
export const SAVE_SETUP_SCENARIO = 'SAVE_SETUP_SCENARIO';
export const SAVE_SETUP_SCENARIO_FULFILLED = 'SAVE_SETUP_SCENARIO_FULFILLED';
export const DELETE_SETUP_SCENARIO = 'DELETE_SETUP_SCENARIO';
export const DELETE_SETUP_SCENARIO_FULFILLED =
  'DELETE_SETUP_SCENARIO_FULFILLED';
export const EDIT_SETUP_SCENARIO = 'EDIT_SETUP_SCENARIO';
export const EDIT_SETUP_SCENARIO_FULFILLED = 'EDIT_SETUP_SCENARIO_FULFILLED';
export const FIND_SETUP_SCENARIO_ANALYSIS_SUMMARY_OVERVIEW =
  'FIND_SETUP_SCENARIO_ANALYSIS_SUMMARY_OVERVIEW';
export const FIND_SETUP_SCENARIO_ANALYSIS_SUMMARY_OVERVIEW_PENDING =
  'FIND_SETUP_SCENARIO_ANALYSIS_SUMMARY_OVERVIEW_PENDING';
export const FIND_SETUP_SCENARIO_ANALYSIS_SUMMARY_OVERVIEW_FULFILLED =
  'FIND_SETUP_SCENARIO_ANALYSIS_SUMMARY_OVERVIEW_FULFILLED';
export const FIND_SETUP_SCENARIO_ANALYSIS_SUMMARY_OVERVIEW_REJECTED =
  'FIND_SETUP_SCENARIO_ANALYSIS_SUMMARY_OVERVIEW_REJECTED';
export const FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW =
  'FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW';
export const FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW_PENDING =
  'FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW_PENDING';
export const FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW_FULFILLED =
  'FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW_FULFILLED';
export const FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW_REJECTED =
  'FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW_REJECTED';
export const FIND_SETUP_SCENARIO_ANALYSIS_MARGIN_OVERVIEW =
  'FIND_SETUP_SCENARIO_ANALYSIS_MARGIN_OVERVIEW';
export const FIND_SETUP_SCENARIO_ANALYSIS_MARGIN_OVERVIEW_PENDING =
  'FIND_SETUP_SCENARIO_ANALYSIS_MARGIN_OVERVIEW_PENDING';
export const FIND_SETUP_SCENARIO_ANALYSIS_MARGIN_OVERVIEW_FULFILLED =
  'FIND_SETUP_SCENARIO_ANALYSIS_MARGIN_OVERVIEW_FULFILLED';
export const FIND_SETUP_SCENARIO_ANALYSIS_MARGIN_OVERVIEW_REJECTED =
  'FIND_SETUP_SCENARIO_ANALYSIS_MARGIN_OVERVIEW_REJECTED';
export const FIND_SETUP_SCENARIO_ANALYSIS_CROSSOVER_OVERVIEW =
  'FIND_SETUP_SCENARIO_ANALYSIS_CROSSOVER_OVERVIEW';
export const FIND_SETUP_SCENARIO_ANALYSIS_CROSSOVER_OVERVIEW_PENDING =
  'FIND_SETUP_SCENARIO_ANALYSIS_CROSSOVER_OVERVIEW_PENDING';
export const FIND_SETUP_SCENARIO_ANALYSIS_CROSSOVER_OVERVIEW_FULFILLED =
  'FIND_SETUP_SCENARIO_ANALYSIS_CROSSOVER_OVERVIEW_FULFILLED';
export const FIND_SETUP_SCENARIO_ANALYSIS_CROSSOVER_OVERVIEW_REJECTED =
  'FIND_SETUP_SCENARIO_ANALYSIS_CROSSOVER_OVERVIEW_REJECTED';
export const SET_SETUP_SCENARIO_ANALYSIS_SESSION =
  'SET_SETUP_SCENARIO_ANALYSIS_SESSION';
export const SCENARIOS_PAGINATED_LIST = 'SCENARIOS_PAGINATED_LIST';
export const SCENARIOS_PAGINATED_LIST_PENDING =
  'SCENARIOS_PAGINATED_LIST_PENDING';
export const SCENARIOS_PAGINATED_LIST_FULFILLED =
  'SCENARIOS_PAGINATED_LIST_FULFILLED';
export const FIND_SCENARIO_SETUP_MATLAB_DATA =
  'FIND_SCENARIO_SETUP_MATLAB_DATA';
export const FIND_SCENARIO_SETUP_MATLAB_DATA_PENDING =
  'FIND_SCENARIO_SETUP_MATLAB_DATA_PENDING';
export const FIND_SCENARIO_SETUP_MATLAB_DATA_FULFILLED =
  'FIND_SCENARIO_SETUP_MATLAB_DATA_FULFILLED';
export const SET_SCENARIO_ANALYSIS_OVERVIEW_EXCLUDE_SUBJECT_AREA =
  'SET_SCENARIO_ANALYSIS_OVERVIEW_EXCLUDE_SUBJECT_AREA';
export const SET_SCENARIO_ANALYSIS_OVERVIEW_EXCLUDE_NO_OBJECT_EVENTS =
  'SET_SCENARIO_ANALYSIS_OVERVIEW_EXCLUDE_NO_OBJECT_EVENTS';
export const SET_SCENARIO_REFERENCE_DATA_SETUP_SAC =
  'SET_SCENARIO_REFERENCE_DATA_SETUP_SAC';
export const SET_SCENARIO_LIST_TYPE = 'SET_SCENARIO_LIST_TYPE';
export const SET_CREATE_SCENARIO_TYPE = 'SET_CREATE_SCENARIO_TYPE';
export const SET_SCENARIO_DETAILS_MODEL_CONFIGURATION =
  'SET_SCENARIO_DETAILS_MODEL_CONFIGURATION';
export const SET_SETUP_SCENARIO_DETAILS_ANALYSIS_BIAS =
  'SET_SETUP_SCENARIO_DETAILS_ANALYSIS_BIAS';
export const SET_SETUP_SCENARIO_DETAILS_CALCULATE_MAX_ACCURACY_SUM_BIAS =
  'SET_SETUP_SCENARIO_DETAILS_CALCULATE_MAX_ACCURACY_SUM_BIAS';
export const SELECT_SCENARIO_LIST_SCENARIO = 'SELECT_SCENARIO_LIST_SCENARIO';
export const CLEAR_SCENARIO_LIST_SELECTED_SCENARIOS =
  'CLEAR_SCENARIO_LIST_SELECTED_SCENARIOS';
