import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress
} from '@material-ui/core';
import { NumberFormat } from 'components';
import { findFacialRecognitionOverview } from 'actions';
import { useDispatch, useSelector } from 'react-redux';

const FacialRecognitionOverview = props => {
  const { entityType, entityId } = props;

  const { namedFaceWalkCounts, namedFaceWalkCountsLoading } = useSelector(
    state => state.facialRecognitionOverview
  );

  namedFaceWalkCounts.sort((a, b) => b.walks - a.walks);

  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(findFacialRecognitionOverview(entityType, entityId));
    }

    return () => {
      mounted = false;
    };
  }, [entityType, entityId]);

  return (
    <Card>
      <CardHeader title={'Recognized Faces'} />
      <Divider />
      {namedFaceWalkCountsLoading && <LinearProgress />}
      <CardContent style={{ padding: 0, paddingBottom: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Person</TableCell>
              <TableCell>Walks</TableCell>
              <TableCell>%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {namedFaceWalkCounts.map(faceCount => (
              <TableRow
                style={{
                  background: !faceCount.faceName ? '#FFFDE5' : 'inherit'
                }}
                key={faceCount.faceName}>
                <TableCell>
                  {faceCount.faceName ? faceCount.faceName : 'No face detected'}
                </TableCell>
                <TableCell>
                  <NumberFormat
                    value={faceCount.walks}
                    fixedDecimalScale={true}
                    precision={0}
                  />
                </TableCell>
                <TableCell>
                  <NumberFormat
                    value={faceCount.walkPercentage}
                    percentage={true}
                    suffix="%"
                    fixedDecimalScale={true}
                    precision={2}
                  />
                </TableCell>
              </TableRow>
            ))}
            {namedFaceWalkCounts.length === 0 && !namedFaceWalkCountsLoading && (
              <TableRow>
                <TableCell>No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

FacialRecognitionOverview.propTypes = {};

export default FacialRecognitionOverview;
