/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { SESSION_VALIDATION_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const SessionValidationStatus = props => {
  const { status, ...rest } = props;

  return (
    <div>
      <Label
        {...rest}
        color={status === 'VERIFIED' ? colors.green[600] : colors.orange[600]}>
        {SESSION_VALIDATION_STATUSES[status]}
      </Label>
    </div>
  );
};

SessionValidationStatus.propTypes = {
  status: PropTypes.string
};

export default SessionValidationStatus;
