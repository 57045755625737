import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NumberFormat, SubjectAreaContents } from 'components';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Collapse,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead
} from '@material-ui/core';
import { ENTRY_FEET } from 'common/constants';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  subjectAreaContents: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  contentSectionHeader: {
    padding: 15,
    paddingBottom: 8,
    paddingTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  rowLink: {
    cursor: 'pointer'
  }
}));

const SetupAnalysisCard = props => {
  const { setupAnalysis, className, handleEventDetailsOpen, ...rest } = props;
  const [showEvents, setShowEvents] = useState(false);

  const classes = useStyles();

  const handleToggleEvents = () => {
    setShowEvents(showEvents => !showEvents);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={'Setup ' + setupAnalysis.setup.index} />
      <Divider />
      <CardContent className={classes.content}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Setup Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Entry Foot</TableCell>
              <TableCell>{ENTRY_FEET[setupAnalysis.setup.entryFoot]}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Contents</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.subjectAreaContents}>
                <SubjectAreaContents
                  subjectAreaContents={setupAnalysis.setup.subjectAreaContents}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Measure</TableCell>
              <TableCell>Left</TableCell>
              <TableCell>Right</TableCell>
              <TableCell>Max</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Overall Accuracy</TableCell>
              <TableCell />
              <TableCell />
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.overallAccuracy}
                  suffix={'%'}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Local Accuracy</TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.accuracyLeftSide}
                  suffix={'%'}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.accuracyRightSide}
                  suffix={'%'}
                />
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>High</TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.highValueLeftSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.highValueRightSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.highValueMaximum}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Low</TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.lowValueLeftSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.lowValueRightSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.lowValueMaximum}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Std</TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.standardDeviationLeftSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.standardDeviationRightSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.standardDeviationMaximum}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Average Margin</TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.averageMarginLeftSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.averageMarginRightSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.averageMarginMaximum}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Crossover</TableCell>
              <TableCell />
              <TableCell />
              <TableCell>
                <NumberFormat value={setupAnalysis.crossover} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Margin (Correct)</TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.marginCorrectLeftSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.marginCorrectRightSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.marginCorrectMaximum}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Margin (Incorrect)</TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.marginIncorrectLeftSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.marginIncorrectRightSide}
                />
              </TableCell>
              <TableCell>
                <NumberFormat
                  percentage
                  value={setupAnalysis.marginIncorrectMaximum}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div
          className={classes.contentSectionHeader}
          onClick={handleToggleEvents}>
          <Typography variant="h6">Walks</Typography>
          {showEvents ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>

        <Collapse in={showEvents}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Walk</TableCell>
                <TableCell>Left</TableCell>
                <TableCell>Right</TableCell>
                <TableCell>Max</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {setupAnalysis.events.map((analysisEvent, index) => (
                <TableRow
                  hover
                  key={analysisEvent.event.id}
                  className={classes.rowLink}
                  onClick={e => {
                    e.preventDefault();
                    handleEventDetailsOpen(analysisEvent.event);
                  }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <NumberFormat
                      percentage
                      value={analysisEvent.objectPercentLeft}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      percentage
                      value={analysisEvent.objectPercentRight}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      percentage
                      value={analysisEvent.objectPercent}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </CardContent>
    </Card>
  );
};

SetupAnalysisCard.propTypes = {
  setupAnalysis: PropTypes.object.isRequired
};

export default SetupAnalysisCard;
