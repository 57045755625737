import ws from '../utils/ws';

/* Actions */

export const modelInfoRetrievalPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: MODEL_INFO_RETRIEVAL_PAGINATED_LIST,
    payload: ws.get(
      `/model_info_data_retrievals/page/${pageNumber}/rows/${pageSize}`
    )
  });

/* Constants */

export const MODEL_INFO_RETRIEVAL_PAGINATED_LIST =
  'MODEL_INFO_RETRIEVAL_PAGINATED_LIST';
export const MODEL_INFO_RETRIEVAL_PAGINATED_LIST_PENDING =
  'MODEL_INFO_RETRIEVAL_PAGINATED_LIST_PENDING';
export const MODEL_INFO_RETRIEVAL_PAGINATED_LIST_FULFILLED =
  'MODEL_INFO_RETRIEVAL_PAGINATED_LIST_FULFILLED';
