import ws from '../utils/ws';
import { ENTITY_TYPES } from 'common/constants';
import { FIND_PARTICIPANT } from './participantActions';

/* Actions */

export const trainingSessionPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: TRAINING_SESSIONS_PAGINATED_LIST,
    payload: ws.get(`/sessions/training/page/${pageNumber}/rows/${pageSize}`)
  });

export const setTrainingSessionListCommentsSession = session => dispatch =>
  dispatch({
    type: SET_TRAINING_SESSION_LIST_COMMENTS_SESSION,
    payload: session
  });

export const selectAllTrainingSessionListSessions = () => dispatch =>
  dispatch({
    type: SELECT_ALL_TRAINING_SESSION_LIST_SESSIONS,
    payload: ws.get(`/sessions/training/ids`)
  });

export const deselectAllTrainingSessionListSessions = () => dispatch =>
  dispatch({
    type: DESELECT_ALL_TRAINING_SESSION_LIST_SESSIONS
  });

export const clearTrainingSessionListSelectedSessions = () => dispatch =>
  dispatch({
    type: CLEAR_TRAINING_SESSION_LIST_SELECTED_SESSIONS
  });

export const selectTrainingSessionListSession = session => dispatch =>
  dispatch({
    type: SELECT_TRAINING_SESSION_LIST_SESSION,
    payload: session.id
  });

export const findTrainingParticipantSummary = () => dispatch =>
  dispatch({
    type: FIND_TRAINING_PARTICIPANT_SUMMARY,
    payload: ws.get(`/training/sessions/summary/participants`)
  });

export const findTrainingEventCounts = () => dispatch =>
  dispatch({
    type: FIND_TRAINING_EVENT_COUNTS,
    payload: ws.get(`/training/sessions/summary/period_event_counts`)
  });

export const findTrainingObjectTypes = () => dispatch =>
  dispatch({
    type: FIND_TRAINING_OBJECT_TYPES,
    payload: ws.get(`/training/sessions/summary/object_types`)
  });

export const findTrainingObjectOfInterestSubjectAreas = () => dispatch =>
  dispatch({
    type: FIND_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS,
    payload: ws.get(
      `/training/sessions/summary/object_of_interest_subject_areas`
    )
  });

export const findTrainingOverview = () => dispatch =>
  dispatch({
    type: FIND_TRAINING_OVERVIEW,
    payload: ws.get(`/training/sessions/summary/overview`)
  });

export const findEventUserValidationCounts = (
  periodStartTime,
  periodFinishTime
) => dispatch =>
  dispatch({
    type: FIND_EVENT_USER_VALIDATION_COUNTS,
    payload: ws.get(
      `/training/sessions/summary/event_user_validation_counts?periodStartTime=${
        periodStartTime ? periodStartTime : ''
      }&periodFinishTime=${periodFinishTime ? periodFinishTime : ''}`
    )
  });

export const findScenarioEventCounts = () => dispatch =>
  dispatch({
    type: FIND_SCENARIO_EVENT_COUNTS,
    payload: ws.get(`/training/sessions/summary/scenario_event_counts`)
  });

export const setEventUserValidationCountPeriodStartTime = periodStartTime => dispatch =>
  dispatch({
    type: SET_EVENT_USER_VALIDATION_COUNT_PERIOD_START_TIME,
    payload: periodStartTime
  });

export const setEventUserValidationCountPeriodFinishTime = periodFinishTime => dispatch =>
  dispatch({
    type: SET_EVENT_USER_VALIDATION_COUNT_PERIOD_FINISH_TIME,
    payload: periodFinishTime
  });

export const findParticipantPhotos = participantId => dispatch => {
  const entityType = ENTITY_TYPES.PARTICIPANT;
  dispatch({
    type: FIND_TRAINING_SESSION_PARTICIPANT_PHOTOS,
    payload: ws.get(`/entity_photos/type/${entityType}/id/${participantId}/`)
  });
};

export const createTrainingSummary = () => dispatch => {
  dispatch({
    type: CREATE_TRAINING_SESSION_SUMMARY,
    payload: ws.post(`/training/sessions/summary/create`)
  });
};

export const findLatestTrainingSummary = () => dispatch => {
  dispatch({
    type: FIND_LATEST_TRAINING_SESSION_SUMMARY,
    payload: ws.get(`/training/sessions/summary/latest`)
  });
};

export const findTrainingCellsOverviewCells = () => dispatch => {
  dispatch({
    type: FIND_TRAINING_CELLS_OVERVIEW_CELLS,
    payload: ws.get(`/cells/data_collection/`)
  });
};

export const findTrainingCellsOverviewLatestCellWalk = cell_id => dispatch => {
  dispatch({
    type: FIND_TRAINING_CELLS_OVERVIEW_LATEST_CELL_WALK,
    payload: ws.get(`/walks/cell/${cell_id}/latest`)
  });
};

export const findParticipantSessions = (
  participantId,
  sessions
) => dispatch => {
  dispatch({
    type: FIND_PARTICIPANT_SESSIONS,
    payload: { participantId, sessions }
  });
};

/* Action Constants */

export const SET_TRAINING_SESSION_LIST_COMMENTS_SESSION =
  'SET_TRAINING_SESSION_LIST_COMMENTS_SESSION';

export const TRAINING_SESSIONS_PAGINATED_LIST =
  'TRAINING_SESSIONS_PAGINATED_LIST';
export const TRAINING_SESSIONS_PAGINATED_LIST_PENDING =
  'TRAINING_SESSIONS_PAGINATED_LIST_PENDING';
export const TRAINING_SESSIONS_PAGINATED_LIST_FULFILLED =
  'TRAINING_SESSIONS_PAGINATED_LIST_FULFILLED';

export const FIND_TRAINING_PARTICIPANT_SUMMARY =
  'FIND_TRAINING_PARTICIPANT_SUMMARY';
export const FIND_TRAINING_PARTICIPANT_SUMMARY_PENDING =
  'FIND_TRAINING_PARTICIPANT_SUMMARY_PENDING';
export const FIND_TRAINING_PARTICIPANT_SUMMARY_FULFILLED =
  'FIND_TRAINING_PARTICIPANT_SUMMARY_FULFILLED';

export const FIND_TRAINING_EVENT_COUNTS = 'FIND_TRAINING_EVENT_COUNTS';
export const FIND_TRAINING_EVENT_COUNTS_PENDING =
  'FIND_TRAINING_EVENT_COUNTS_PENDING';
export const FIND_TRAINING_EVENT_COUNTS_FULFILLED =
  'FIND_TRAINING_EVENT_COUNTS_FULFILLED';

export const FIND_TRAINING_OBJECT_TYPES = 'FIND_TRAINING_OBJECT_TYPES';
export const FIND_TRAINING_OBJECT_TYPES_PENDING =
  'FIND_TRAINING_OBJECT_TYPES_PENDING';
export const FIND_TRAINING_OBJECT_TYPES_FULFILLED =
  'FIND_TRAINING_OBJECT_TYPES_FULFILLED';

export const FIND_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS =
  'FIND_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS';
export const FIND_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_PENDING =
  'FIND_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_PENDING';
export const FIND_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_FULFILLED =
  'FIND_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_FULFILLED';

export const FIND_TRAINING_OVERVIEW = 'FIND_TRAINING_OVERVIEW';
export const FIND_TRAINING_OVERVIEW_PENDING = 'FIND_TRAINING_OVERVIEW_PENDING';
export const FIND_TRAINING_OVERVIEW_FULFILLED =
  'FIND_TRAINING_OVERVIEW_FULFILLED';

export const FIND_TRAINING_SESSION_PARTICIPANT_PHOTOS =
  'FIND_TRAINING_SESSION_PARTICIPANT_PHOTOS';
export const FIND_TRAINING_SESSION_PARTICIPANT_PHOTOS_PENDING =
  'FIND_TRAINING_SESSION_PARTICIPANT_PHOTOS_PENDING';
export const FIND_TRAINING_SESSION_PARTICIPANT_PHOTOS_FULFILLED =
  'FIND_TRAINING_SESSION_PARTICIPANT_PHOTOS_FULFILLED';

export const FIND_EVENT_USER_VALIDATION_COUNTS =
  'FIND_EVENT_USER_VALIDATION_COUNTS';
export const FIND_EVENT_USER_VALIDATION_COUNTS_PENDING =
  'FIND_EVENT_USER_VALIDATION_COUNTS_PENDING';
export const FIND_EVENT_USER_VALIDATION_COUNTS_FULFILLED =
  'FIND_EVENT_USER_VALIDATION_COUNTS_FULFILLED';

export const FIND_SCENARIO_EVENT_COUNTS = 'FIND_SCENARIO_EVENT_COUNTS';
export const FIND_SCENARIO_EVENT_COUNTS_PENDING =
  'FIND_SCENARIO_EVENT_COUNTS_PENDING';
export const FIND_SCENARIO_EVENT_COUNTS_FULFILLED =
  'FIND_SCENARIO_EVENT_COUNTS_FULFILLED';

export const SET_EVENT_USER_VALIDATION_COUNT_PERIOD_START_TIME =
  'SET_EVENT_USER_VALIDATION_COUNT_PERIOD_START_TIME';
export const SET_EVENT_USER_VALIDATION_COUNT_PERIOD_FINISH_TIME =
  'SET_EVENT_USER_VALIDATION_COUNT_PERIOD_FINISH_TIME';

export const CREATE_TRAINING_SESSION_SUMMARY =
  'CREATE_TRAINING_SESSION_SUMMARY';
export const CREATE_TRAINING_SESSION_SUMMARY_PENDING =
  'CREATE_TRAINING_SESSION_SUMMARY_PENDING';
export const CREATE_TRAINING_SESSION_SUMMARY_FULFILLED =
  'CREATE_TRAINING_SESSION_SUMMARY_FULFILLED';

export const FIND_LATEST_TRAINING_SESSION_SUMMARY =
  'FIND_LATEST_TRAINING_SESSION_SUMMARY';
export const FIND_LATEST_TRAINING_SESSION_SUMMARY_PENDING =
  'FIND_LATEST_TRAINING_SESSION_SUMMARY_PENDING';
export const FIND_LATEST_TRAINING_SESSION_SUMMARY_FULFILLED =
  'FIND_LATEST_TRAINING_SESSION_SUMMARY_FULFILLED';

export const DESELECT_ALL_TRAINING_SESSION_LIST_SESSIONS =
  'DESELECT_ALL_TRAINING_SESSION_LIST_SESSIONS';

export const CLEAR_TRAINING_SESSION_LIST_SELECTED_SESSIONS =
  'CLEAR_TRAINING_SESSION_LIST_SELECTED_SESSIONS';

export const SELECT_ALL_TRAINING_SESSION_LIST_SESSIONS =
  'SELECT_ALL_TRAINING_SESSION_LIST_SESSIONS';
export const SELECT_ALL_TRAINING_SESSION_LIST_SESSIONS_PENDING =
  'SELECT_ALL_TRAINING_SESSION_LIST_SESSIONS_PENDING';
export const SELECT_ALL_TRAINING_SESSION_LIST_SESSIONS_FULFILLED =
  'SELECT_ALL_TRAINING_SESSION_LIST_SESSIONS_FULFILLED';

export const SELECT_TRAINING_SESSION_LIST_SESSION =
  'SELECT_TRAINING_SESSION_LIST_SESSION';

export const FIND_TRAINING_CELLS_OVERVIEW_CELLS =
  'FIND_TRAINING_CELLS_OVERVIEW_CELLS';
export const FIND_TRAINING_CELLS_OVERVIEW_CELLS_PENDING =
  'FIND_TRAINING_CELLS_OVERVIEW_CELLS_PENDING';
export const FIND_TRAINING_CELLS_OVERVIEW_CELLS_FULFILLED =
  'FIND_TRAINING_CELLS_OVERVIEW_CELLS_FULFILLED';

export const FIND_TRAINING_CELLS_OVERVIEW_LATEST_CELL_WALK =
  'FIND_TRAINING_CELLS_OVERVIEW_LATEST_CELL_WALK';
export const FIND_TRAINING_CELLS_OVERVIEW_LATEST_CELL_WALK_PENDING =
  'FIND_TRAINING_CELLS_OVERVIEW_LATEST_CELL_WALK_PENDING';
export const FIND_TRAINING_CELLS_OVERVIEW_LATEST_CELL_WALK_FULFILLED =
  'FIND_TRAINING_CELLS_OVERVIEW_LATEST_CELL_WALK_FULFILLED';

export const FIND_PARTICIPANT_SESSIONS = 'FIND_PARTICIPANT_SESSIONS';
