import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import {
  setCreateCellCheckListDefinitionOpen,
  findCellCheckListDefinitions,
  findCellModelVersions,
  setCreateCellBuildComponentFieldValue,
  createNewCellCheckListDefinition
} from 'actions';
import { CELL_MODELS } from 'common/constants';
import { Alert } from 'components';
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 550,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {},
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  infoAlert: {
    marginBottom: theme.spacing(3)
  }
}));

const CreateCellCheckListDefinition = props => {
  const {} = props;

  const {
    createCellCheckListDefinitionOpen,
    createCellCheckListDefinition,
    savedCellCheckListDefinition,
    cellModelVersions,
    cellCheckListDefinitions,
    validationMessages,
    saved
  } = useSelector(state => state.cellCheckListDefinitionCreate);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted && createCellCheckListDefinitionOpen) {
      dispatch(findCellCheckListDefinitions());
      dispatch(findCellModelVersions());
    }

    return () => {
      mounted = false;
    };
  }, [createCellCheckListDefinitionOpen]);

  if (saved) {
    return (
      <Redirect
        to={`/deployment/cell_check_list_definitions/${savedCellCheckListDefinition.id}/check_list`}
      />
    );
  }

  const handleClose = () => {
    dispatch(setCreateCellCheckListDefinitionOpen(false));
  };

  const handleValueChange = (field, value) => {
    dispatch(setCreateCellBuildComponentFieldValue(field, value));
  };

  const handleSave = () => {
    dispatch(createNewCellCheckListDefinition(createCellCheckListDefinition));
  };

  if (!createCellCheckListDefinitionOpen) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={createCellCheckListDefinitionOpen}>
      <Card className={classes.root}>
        <CardHeader title="Create Cell Check List" />
        <Divider />
        <CardContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={e => handleValueChange('name', e.target.value)}
                value={
                  createCellCheckListDefinition.name
                    ? createCellCheckListDefinition.name
                    : ''
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cell Version"
                name="cellModelVersion"
                onChange={e =>
                  handleValueChange(
                    'cellModelVersion',
                    e.target.value ? { id: e.target.value } : null
                  )
                }
                error={validationMessages.hasOwnProperty('cellModelVersion')}
                helperText={validationMessages.cellModelVersion}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={
                  createCellCheckListDefinition.cellModelVersion
                    ? createCellCheckListDefinition.cellModelVersion.id
                    : ''
                }
                variant="outlined">
                <option />
                {cellModelVersions.map(cellModelVersion => (
                  <option key={cellModelVersion.id} value={cellModelVersion.id}>
                    {CELL_MODELS[cellModelVersion.cellModel]} /{' '}
                    {cellModelVersion.version}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Alert
                className={classes.infoAlert}
                message="Select an existing check list to copy items from (optional)"
              />

              <TextField
                fullWidth
                label="Existing Cell Check List Definition"
                name="templateCellCheckListDefinitionId"
                onChange={e =>
                  handleValueChange(
                    'templateCellCheckListDefinitionId',
                    e.target.value
                  )
                }
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={
                  createCellCheckListDefinition.templateCellCheckListDefinitionId
                    ? createCellCheckListDefinition.templateCellCheckListDefinitionId
                    : ''
                }
                variant="outlined">
                <option />
                {cellCheckListDefinitions.map(cellCheckListDefinition => (
                  <option
                    key={cellCheckListDefinition.id}
                    value={cellCheckListDefinition.id}>
                    {cellCheckListDefinition.name} (
                    {
                      CELL_MODELS[
                        cellCheckListDefinition?.cellModelVersion.cellModel
                      ]
                    }{' '}
                    / {cellCheckListDefinition?.cellModelVersion.version})
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave} variant="contained">
            Create
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CreateCellCheckListDefinition.defaultProps = {
  open: false
};

export default CreateCellCheckListDefinition;
