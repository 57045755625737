import ws from '../utils/ws';

/* Actions */

export const modelConfiruationPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: MODELS_PAGINATED_LIST,
    payload: ws.get(`/model_configurations/page/${pageNumber}/rows/${pageSize}`)
  });

export const findModelConfigurations = () => dispatch =>
  dispatch({
    type: FIND_MODEL_CONFIGURATIONS,
    payload: ws.get(`/model_configurations/`)
  });

export const findModelConfiguration = id => dispatch =>
  dispatch({
    type: FIND_MODEL_CONFIGURATION,
    payload: ws.get(`/model_configurations/${id}`)
  });

export const modelConfigurationValueReset = () => dispatch =>
  dispatch({
    type: CREATE_MODEL_CONFIGURATION_VALUE_RESET
  });

export const modelConfigurationValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_MODEL_CONFIGURATION_VALUE_UPDATE,
    payload: values
  });

export const saveModelConfiguration = modelConfiguration => dispatch =>
  dispatch({
    type: SAVE_MODEL_CONFIGURATION,
    payload: ws.post('/model_configurations/', modelConfiguration)
  });

export const editModelConfiguration = id => dispatch =>
  dispatch({
    type: EDIT_MODEL_CONFIGURATION,
    payload: ws.get(`/model_configurations/${id}`)
  });

export const setModelPredictionResultUploadValues = values => dispatch =>
  dispatch({
    type: SET_MODEL_PREDICTION_RESULT_UPLOAD_VALUES,
    payload: values
  });

export const setModelPredictionResultUploadOpen = open => dispatch =>
  dispatch({
    type: SET__MODEL_PREDICTION_RESULT_UPLOAD_OPEN,
    payload: open
  });

export const clearModelPredictionResultUploadValues = () => dispatch =>
  dispatch({
    type: CLEAR_MODEL_PREDICTION_RESULT_UPLOAD_VALUES
  });

export const setModelPredictionResultUploadModelDetails = values => dispatch =>
  dispatch({
    type: SET_MODEL_PREDICTION_RESULT_UPLOAD_DETAILS,
    payload: values
  });

export const uploadModelFile = (modelConfiguration, file) => dispatch => {
  var formData = new FormData();
  formData.append('file', file);

  dispatch({
    type: UPLOAD_MODEL_FILE,
    payload: ws.postFormData(
      `/model_configurations/${modelConfiguration.id}/model_file/upload`,
      formData
    )
  });
};

export const findModelMaleBmiCategoryPercentages = modelConfiguration => dispatch =>
  dispatch({
    type: FIND_MODEL_MALE_BMI_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/model_configurations/${modelConfiguration.id}/reports/bmi_categories/gender/MALE`
    )
  });

export const findModelFemaleBmiCategoryPercentages = modelConfiguration => dispatch =>
  dispatch({
    type: FIND_MODEL_FEMALE_BMI_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/model_configurations/${modelConfiguration.id}/reports/bmi_categories/gender/FEMALE`
    )
  });

export const findModelMaleHeightCategoryPercentages = modelConfiguration => dispatch =>
  dispatch({
    type: FIND_MODEL_MALE_HEIGHT_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/model_configurations/${modelConfiguration.id}/reports/height_categories/gender/MALE`
    )
  });

export const findModelFemaleHeightCategoryPercentages = modelConfiguration => dispatch =>
  dispatch({
    type: FIND_MODEL_FEMALE_HEIGHT_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/model_configurations/${modelConfiguration.id}/reports/height_categories/gender/FEMALE`
    )
  });

/* Constants */

export const MODELS_PAGINATED_LIST = 'MODELS_PAGINATED_LIST';
export const MODELS_PAGINATED_LIST_PENDING = 'MODELS_PAGINATED_LIST_PENDING';
export const MODELS_PAGINATED_LIST_FULFILLED =
  'MODELS_PAGINATED_LIST_FULFILLED';

export const FIND_MODEL_CONFIGURATIONS = 'FIND_MODEL_CONFIGURATIONS';
export const FIND_MODEL_CONFIGURATIONS_PENDING =
  'FIND_MODEL_CONFIGURATIONS_PENDING';
export const FIND_MODEL_CONFIGURATIONS_FULFILLED =
  'FIND_MODEL_CONFIGURATIONS_FULFILLED';

export const FIND_MODEL_CONFIGURATION = 'FIND_MODEL_CONFIGURATION';
export const FIND_MODEL_CONFIGURATION_PENDING =
  'FIND_MODEL_CONFIGURATION_PENDING';
export const FIND_MODEL_CONFIGURATION_FULFILLED =
  'FIND_MODEL_CONFIGURATION_FULFILLED';

export const SAVE_MODEL_CONFIGURATION = 'SAVE_MODEL_CONFIGURATION';
export const SAVE_MODEL_CONFIGURATION_PENDING =
  'SAVE_MODEL_CONFIGURATION_PENDING';
export const SAVE_MODEL_CONFIGURATION_FULFILLED =
  'SAVE_MODEL_CONFIGURATION_FULFILLED';

export const EDIT_MODEL_CONFIGURATION = 'EDIT_MODEL_CONFIGURATION';
export const EDIT_MODEL_CONFIGURATION_PENDING =
  'EDIT_MODEL_CONFIGURATION_PENDING';
export const EDIT_MODEL_CONFIGURATION_FULFILLED =
  'EDIT_MODEL_CONFIGURATION_FULFILLED';

export const SET_MODEL_LIST_DETAILS_MODEL = 'SET_MODEL_LIST_DETAILS_MODEL';

export const CREATE_MODEL_CONFIGURATION_VALUE_RESET =
  'CREATE_MODEL_CONFIGURATION_VALUE_RESET';

export const CREATE_MODEL_CONFIGURATION_VALUE_UPDATE =
  'CREATE_MODEL_CONFIGURATION_VALUE_UPDATE';

export const SET__MODEL_PREDICTION_RESULT_UPLOAD_OPEN =
  'SET__MODEL_PREDICTION_RESULT_UPLOAD_OPEN';
export const SET_MODEL_PREDICTION_RESULT_UPLOAD_VALUES =
  'SET_MODEL_PREDICTION_RESULT_UPLOAD_VALUES';
export const CLEAR_MODEL_PREDICTION_RESULT_UPLOAD_VALUES =
  'CLEAR_MODEL_PREDICTION_RESULT_UPLOAD_VALUES';
export const SET_MODEL_PREDICTION_RESULT_UPLOAD_DETAILS =
  'SET_MODEL_PREDICTION_RESULT_UPLOAD_DETAILS';

export const PROCESS_MODEL_PREDICTION_RESULT_UPLOAD =
  'PROCESS_MODEL_PREDICTION_RESULT_UPLOAD';
export const PROCESS_MODEL_PREDICTION_RESULT_UPLOAD_PENDING =
  'PROCESS_MODEL_PREDICTION_RESULT_UPLOAD_PENDING';
export const PROCESS_MODEL_PREDICTION_RESULT_UPLOAD_FULFILLED =
  'PROCESS_MODEL_PREDICTION_RESULT_UPLOAD_FULFILLED';

export const UPLOAD_MODEL_FILE = 'UPLOAD_MODEL_FILE';
export const UPLOAD_MODEL_FILE_PENDING = 'UPLOAD_MODEL_FILE_PENDING';
export const UPLOAD_MODEL_FILE_FULFILLED = 'UPLOAD_MODEL_FILE_FULFILLED';

export const FIND_MODEL_MALE_BMI_CATEGORY_PERCENTAGES =
  'FIND_MODEL_MALE_BMI_CATEGORY_PERCENTAGES';
export const FIND_MODEL_MALE_BMI_CATEGORY_PERCENTAGES_PENDING =
  'FIND_MODEL_MALE_BMI_CATEGORY_PERCENTAGES_PENDING';
export const FIND_MODEL_MALE_BMI_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_MODEL_MALE_BMI_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_MODEL_FEMALE_BMI_CATEGORY_PERCENTAGES =
  'FIND_MODEL_FEMALE_BMI_CATEGORY_PERCENTAGES';
export const FIND_MODEL_FEMALE_BMI_CATEGORY_PERCENTAGES_PENDING =
  'FIND_MODEL_FEMALE_BMI_CATEGORY_PERCENTAGES_PENDING';
export const FIND_MODEL_FEMALE_BMI_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_MODEL_FEMALE_BMI_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_MODEL_MALE_HEIGHT_CATEGORY_PERCENTAGES =
  'FIND_MODEL_MALE_HEIGHT_CATEGORY_PERCENTAGES';
export const FIND_MODEL_MALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING =
  'FIND_MODEL_MALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING';
export const FIND_MODEL_MALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_MODEL_MALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_MODEL_FEMALE_HEIGHT_CATEGORY_PERCENTAGES =
  'FIND_MODEL_FEMALE_HEIGHT_CATEGORY_PERCENTAGES';
export const FIND_MODEL_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING =
  'FIND_MODEL_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING';
export const FIND_MODEL_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_MODEL_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED';
