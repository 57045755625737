import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Badge } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Search } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480
  },
  filterButton: {
    marginLeft: 'auto'
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearFilterIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const SearchBar = props => {
  const {
    onSearch,
    onClearSearch,
    className,
    hideSearchButton,
    hideFilter,
    hideSearch,
    searchValue,
    onSearchValueChange,
    placeholder,
    filter,
    handleFilterOpen,
    handleClearFilter,
    filterItemCount,
    disabled,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}>
      <Grid item>
        {!hideSearch && (
          <Search
            disabled={disabled}
            placeholder={placeholder}
            value={searchValue}
            onSearchValueChange={onSearchValueChange}
            className={classes.search}
            hideSearchButton={hideSearchButton}
            onSearch={onSearch}
            onClearSearch={onClearSearch}
          />
        )}
      </Grid>
      <Grid item>
        {!hideFilter && (
          <>
            <Badge badgeContent={filterItemCount} color="secondary">
              <Button
                className={classes.filterButton}
                color="primary"
                onClick={handleFilterOpen}
                size="small"
                variant="outlined">
                <FilterListIcon className={classes.filterIcon} /> Show filters
              </Button>
            </Badge>
            <Button
              className={classes.clearFilterIcon}
              color="primary"
              onClick={handleClearFilter}
              size="small"
              variant="outlined">
              Clear filters
            </Button>
          </>
        )}
      </Grid>
      {filter}
    </Grid>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func
};

export default SearchBar;
