import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: 0
  }
}));

const SelectObjectTypes = props => {
  const {
    open,
    selectedObjectTypes,
    objectTypes,
    loading,
    onSelectObjectType,
    onClose,
    onConfirm
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader title="Select Object Types" />
        <Divider />
        <CardContent className={classes.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '0%' }} />
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {objectTypes.map(objectType => (
                <TableRow
                  style={{ cursor: 'pointer' }}
                  onClick={() => onSelectObjectType(objectType)}
                  key={objectType.id}
                  hover>
                  <TableCell className={classes.buttonCell}>
                    <Checkbox
                      checked={
                        selectedObjectTypes
                          .map(t => t.id)
                          .indexOf(objectType.id) > -1
                      }
                      color="primary"
                      onChange={() => onSelectObjectType(objectType)}
                    />
                  </TableCell>
                  <TableCell>{objectType.name}</TableCell>
                </TableRow>
              ))}
              {!loading && objectTypes.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10}>No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={onConfirm} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SelectObjectTypes.defaultProps = {
  open: false
};

export default SelectObjectTypes;
