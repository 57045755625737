import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  TextareaAutosize,
  Button,
  colors
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { saveComment, deleteComment } from 'actions';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  bubble: {
    flexGrow: 1,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    position: 'relative'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  time: {
    marginLeft: 'auto'
  },
  message: {
    marginTop: theme.spacing(1)
  },
  edit: {
    cursor: 'default!important',
    opacity: '1!important'
  },
  editable: {
    cursor: 'pointer',
    '&:hover': {
      '& svg': {
        display: 'block',
        color: colors.grey[600]
      }
    }
  },
  textArea: {
    ...theme.typography.body1,
    border: 'none',
    outline: 'none',
    resize: 'none',
    width: '100%',
    padding: 6,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  editIcon: {
    height: 20,
    width: 20,
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    display: 'none'
  }
}));

const Comment = props => {
  const { entityType, editable, entityId, comment, className, ...rest } = props;
  const [edit, setEdit] = useState(false);
  const [editComment, setEditComment] = useState({});

  const dispatch = useDispatch();
  const classes = useStyles();

  const { sessionUser } = useSelector(state => state.session);
  const commentEditable =
    editable && sessionUser.userName === comment.createdBy.userName;

  const handleEdit = () => {
    if (commentEditable && !edit) {
      setEditComment({ ...comment });
      setEdit(true);
    }
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleSave = () => {
    dispatch(
      saveComment(entityType, entityId, {
        ...comment,
        comment: editComment.comment
      })
    );
    setEdit(false);
  };

  const handleDelete = () => {
    dispatch(deleteComment(entityType, entityId, comment));
    setEdit(false);
  };

  const handleEditComment = e => {
    setEditComment({ comment: e.target.value });
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div
        className={`${classes.bubble} ${
          commentEditable ? classes.editable : ''
        } ${edit ? classes.edit : ''}`}
        onClick={handleEdit}>
        {!edit && <EditIcon className={classes.editIcon} />}
        <div className={classes.header}>
          <Typography variant="h6">{comment.createdBy.displayName}</Typography>
          <Typography className={classes.time} variant="body2">
            {moment(comment.createdTime).format('MMM Do YYYY, h:mm:ss a')} (
            {moment(comment.createdTime).fromNow()})
          </Typography>
        </div>
        {edit ? (
          <React.Fragment>
            <TextareaAutosize
              className={classes.textArea}
              rowsMax={4}
              aria-label="Message"
              onChange={handleEditComment}
              value={editComment.comment}
            />
            <div className={classes.actions}>
              <Button onClick={handleCancel} size="small">
                Cancel
              </Button>
              <Button onClick={handleDelete} size="small">
                Delete
              </Button>
              <Button
                disabled={editComment.comment.trim() === ''}
                onClick={handleSave}
                size="small">
                Save
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <Typography className={classes.message} variant="body1">
            {comment.comment}
          </Typography>
        )}
      </div>
    </div>
  );
};

Comment.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.object.isRequired
};

export default Comment;
