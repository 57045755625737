import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  saved: false,
  savedCustomer: {},
  deleted: false,
  deletedCustomer: {},
  customer: {
    address: '',
    countryCode: '',
    customerName: '',
    emailAddress: '',
    marketingCustomer: null,
    modelConfiguration: null,
    multiModelDesignated: null,
    phoneNumber: '',
    shortCode: '',
    hubSpotId: '',
    threshold: null
  },
  validationMessages: {}
};

const customerCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CUSTOMER_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedCustomer: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CUSTOMER_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deletedCustomer: action.payload.data.result
      };
    }

    case actionTypes.EDIT_CUSTOMER_FULFILLED: {
      return {
        ...state,
        customer: action.payload.data.result
      };
    }

    case actionTypes.CREATE_CUSTOMER_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_CUSTOMER_VALUE_UPDATE: {
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
};

export default customerCreateReducer;
