import * as actionTypes from 'actions';

const initialState = {
  softwarePackageLoading: false,
  softwarePackage: null,
  installedCells: [],
  installedCellsLoading: false
};

const cellDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_SOFTWARE_PACKAGE_PENDING: {
      return {
        ...state,
        softwarePackage: initialState.softwarePackage,
        softwarePackageLoading: true
      };
    }

    case actionTypes.FIND_SOFTWARE_PACKAGE_FULFILLED:
    case actionTypes.SET_SOFTWARE_PACKAGE_STATUS_FULFILLED: {
      return {
        ...state,
        softwarePackage: action.payload.data.result,
        softwarePackageLoading: false
      };
    }

    case actionTypes.FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_PENDING: {
      return {
        ...state,
        installedCells: initialState.installedCells,
        installedCellsLoading: true
      };
    }

    case actionTypes.FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_FULFILLED: {
      return {
        ...state,
        installedCells: action.payload.data.result,
        installedCellsLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default cellDetailsReducer;
