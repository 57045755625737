import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Paper,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  table: {
    '& th': {
      width: '50%'
    }
  },
  valueCell: {
    color: colors.blue[800],
    fontFamily: 'monospace'
  }
}));

const chunkArray = (arr, n) => {
  var chunkLength = Math.max(arr.length / n, 1);
  var chunks = [];
  for (var i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length)
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }
  return chunks;
};

const CellConfigurationProperties = props => {
  const { cellConfiguration, excludedProperties } = props;

  const classes = useStyles();

  if (!cellConfiguration) return null;

  const cellConfigurationJson = JSON.parse(cellConfiguration.configurationJson);

  const configurationProperties = Object.keys(cellConfigurationJson).filter(
    p => !excludedProperties || excludedProperties.indexOf(p) < 0
  );

  const tables = chunkArray(configurationProperties, 3);

  const PropertyValue = props => {
    const { value } = props;
    const valueString = !(typeof value === 'object')
      ? '' + value
      : JSON.stringify(value);

    return <>{valueString}</>;
  };

  return (
    <Grid container spacing={2}>
      {tables.map((properties, i) => (
        <Grid key={i} item xs={12} md={4}>
          <Paper>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {properties.map(property => (
                  <TableRow key={property}>
                    <TableCell>{property}</TableCell>
                    <TableCell className={classes.valueCell}>
                      <PropertyValue value={cellConfigurationJson[property]} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

CellConfigurationProperties.propTypes = {
  cellConfiguration: PropTypes.object
};

export default CellConfigurationProperties;
