import ws from '../utils/ws';
import { dispatch } from 'rxjs/internal/observable/pairs';

export const USER_PROFILE_VALUE_UPDATE = 'USER_PROFILE_VALUE_UPDATE';
export const USER_PROFILE_VALUE_RESET = 'USER_PROFILE_VALUE_RESET';
export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';
export const SAVE_USER_PROFILE_FULFILLED = 'SAVE_USER_PROFILE_FULFILLED';

export const userProfileValueUpdate = values => dispatch =>
  dispatch({
    type: USER_PROFILE_VALUE_UPDATE,
    payload: values
  });

export const saveUserProfile = user => dispatch =>
  dispatch({
    type: SAVE_USER_PROFILE,
    payload: ws.post('/profile/', user)
  });

export const userProfileValueReset = () => dispatch =>
  dispatch({
    type: USER_PROFILE_VALUE_RESET
  });
