import ws from '../utils/ws';

/* Actions */

export const eventModelRunRequestPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: EVENT_MODEL_RUN_REQUEST_PAGINATED_LIST,
    payload: ws.get(
      `/event_model_run_requests/page/${pageNumber}/rows/${pageSize}`
    )
  });

export const findModelRunRequestEventCount = () => dispatch =>
  dispatch({
    type: EVENT_MODEL_RUN_REQUEST_EVENT_COUNT,
    payload: ws.get(`/jobs/event_model_configuration_runs/request/event_count`)
  });

export const findEventModelConfigurationCreations = () => dispatch =>
  dispatch({
    type: FIND_EVENT_MODEL_CONFIGURATION_CREATIONS,
    payload: ws.get(
      `/event_model_configuration_creations/`
    )
  });

export const findModelRunRequestOverview = () => dispatch =>
  dispatch({
    type: EVENT_MODEL_RUN_REQUEST_OVERVIEW,
    payload: ws.get(
      `/jobs/event_model_configuration_runs/request/model_overview`
    )
  });

export const findModelRunRequestLatestResponse = runRequestId => dispatch =>
  dispatch({
    type: EVENT_MODEL_RUN_REQUEST_LATEST_RESPONSE,
    payload: ws.get(
      `/jobs/event_model_configuration_runs/request/${runRequestId}/latest_response`
    )
  });

export const requeueEventModelRunRequest = runRequest => dispatch =>
  dispatch({
    type: REQUEUE_EVENT_MODEL_RUN_REQUEST,
    payload: ws.post(`/event_model_run_requests/${runRequest.id}/requeue`)
  });

export const deleteEventModelRunRequest = runRequest => dispatch =>
  dispatch({
    type: DELETE_EVENT_MODEL_RUN_REQUEST,
    payload: ws.post(`/event_model_run_requests/${runRequest.id}/delete`)
  });

/* Constants */

export const EVENT_MODEL_RUN_REQUEST_PAGINATED_LIST =
  'EVENT_MODEL_RUN_REQUEST_PAGINATED_LIST';
export const EVENT_MODEL_RUN_REQUEST_PAGINATED_LIST_PENDING =
  'EVENT_MODEL_RUN_REQUEST_PAGINATED_LIST_PENDING';
export const EVENT_MODEL_RUN_REQUEST_PAGINATED_LIST_FULFILLED =
  'EVENT_MODEL_RUN_REQUEST_PAGINATED_LIST_FULFILLED';

export const EVENT_MODEL_RUN_REQUEST_EVENT_COUNT =
  'EVENT_MODEL_RUN_REQUEST_EVENT_COUNT';
export const EVENT_MODEL_RUN_REQUEST_EVENT_COUNT_PENDING =
  'EVENT_MODEL_RUN_REQUEST_EVENT_COUNT_PENDING';
export const EVENT_MODEL_RUN_REQUEST_EVENT_COUNT_FULFILLED =
  'EVENT_MODEL_RUN_REQUEST_EVENT_COUNT_FULFILLED';

export const EVENT_MODEL_RUN_REQUEST_OVERVIEW =
  'EVENT_MODEL_RUN_REQUEST_OVERVIEW';
export const EVENT_MODEL_RUN_REQUEST_OVERVIEW_PENDING =
  'EVENT_MODEL_RUN_REQUEST_OVERVIEW_PENDING';
export const EVENT_MODEL_RUN_REQUEST_OVERVIEW_FULFILLED =
  'EVENT_MODEL_RUN_REQUEST_OVERVIEW_FULFILLED';

export const FIND_EVENT_MODEL_CONFIGURATION_CREATIONS =
  'FIND_EVENT_MODEL_CONFIGURATION_CREATIONS';
export const FIND_EVENT_MODEL_CONFIGURATION_CREATIONS_PENDING =
  'FIND_EVENT_MODEL_CONFIGURATION_CREATIONS_PENDING';
export const FIND_EVENT_MODEL_CONFIGURATION_CREATIONS_FULFILLED =
  'FIND_EVENT_MODEL_CONFIGURATION_CREATIONS_FULFILLED';

export const EVENT_MODEL_RUN_REQUEST_LATEST_RESPONSE =
  'EVENT_MODEL_RUN_REQUEST_LATEST_RESPONSE';
export const EVENT_MODEL_RUN_REQUEST_LATEST_RESPONSE_PENDING =
  'EVENT_MODEL_RUN_REQUEST_LATEST_RESPONSE_PENDING';
export const EVENT_MODEL_RUN_REQUEST_LATEST_RESPONSE_FULFILLED =
  'EVENT_MODEL_RUN_REQUEST_LATEST_RESPONSE_FULFILLED';

export const REQUEUE_EVENT_MODEL_RUN_REQUEST =
  'REQUEUE_EVENT_MODEL_RUN_REQUEST';
export const REQUEUE_EVENT_MODEL_RUN_REQUEST_PENDING =
  'REQUEUE_EVENT_MODEL_RUN_REQUEST_PENDING';
export const REQUEUE_EVENT_MODEL_RUN_REQUEST_FULFILLED =
  'REQUEUE_EVENT_MODEL_RUN_REQUEST_FULFILLED';

export const DELETE_EVENT_MODEL_RUN_REQUEST = 'DELETE_EVENT_MODEL_RUN_REQUEST';
export const DELETE_EVENT_MODEL_RUN_REQUEST_PENDING =
  'DELETE_EVENT_MODEL_RUN_REQUEST_PENDING';
export const DELETE_EVENT_MODEL_RUN_REQUEST_FULFILLED =
  'DELETE_EVENT_MODEL_RUN_REQUEST_FULFILLED';
