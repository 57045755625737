import * as actionTypes from 'actions';

const defaultSubjectAreaContent = {
  subjectArea: 'RIGHT_SIDE',
  pocketLocation: '',
  objectType: null,
  objectModel: null
};

const initialState = {
  filterCell: null,
  initialEventRequested: false,
  eventsLoaded: false,
  currentEvent: {},
  currentEventLoading: false,
  subjectAreaContentSaved: false,
  objectTypes: [],
  objectModels: [],
  objectTypeCategories: [],
  subjectAreaContent: defaultSubjectAreaContent,
  defaultSubjectAreaContent,
  eventsLoading: false,
  processing: false,
  eventPlaybackPaused: true,
  eventVideoPlaybackRate: 2,
  activeStep: 0,
  processingEventId: null,
  events: []
};

const validateCustomerEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_VALIDATE_CUSTOMER_EVENT: {
      return {
        ...initialState
      };
    }

    case actionTypes.FIND_VALIDATE_CUSTOMER_EVENT_EVENT_PENDING: {
      return {
        ...state,
        initialEventRequested: true,
        currentEventLoading: true,
        eventsLoaded: false
      };
    }

    case actionTypes.FIND_VALIDATE_CUSTOMER_EVENT_EVENT_FULFILLED: {
      return {
        ...state,
        currentEvent: action.payload.data.result,
        currentEventLoading: false
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_PENDING: {
      return {
        ...state,
        eventsLoaded: false,
        eventsLoading: false,
        events: []
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_VALIDATION_EVENTS_PENDING: {
      return {
        ...state,
        eventsLoaded: true,
        eventsLoading: true,
        events: []
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_VALIDATION_EVENTS_FULFILLED: {
      const events = action.payload.data.result.filter(
        e => !state.filterCell || e.cellId === state.filterCell.id
      );

      return {
        ...state,
        eventsLoading: false,
        events: events
      };
    }

    case actionTypes.SET_FILTER_CELL: {
      return {
        ...initialState,
        filterCell: action.payload
      };
    }

    case actionTypes.TOGGLE_VALIDATE_CUSTOMER_EVENT_SPECIAL_INTEREST_FULFILLED: {
      const event = action.payload.data.result;

      const events = state.events.slice();
      events[events.findIndex(e => e.id === event.id)].specialInterest =
        event.specialInterest;

      return {
        ...state,
        events: events,
        currentEvent: event
      };
    }

    case actionTypes.SET_VALIDATION_STATUS_PROCESSING_EVENT_ID: {
      return {
        ...state,
        processingEventId: action.payload
      };
    }

    case actionTypes.UPDATE_EVENT_VALIDATION_STATUS_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.UPDATE_EVENT_VALIDATION_STATUS_FULFILLED: {
      const event = action.payload.data.result;

      const events = state.events.slice();
      const index = events.map(e => e.id).indexOf(event.id);

      events[index] = {
        ...event,
        processing: false
      };

      return {
        ...state,
        processing: false,
        events: events,
        processingEventId: null,
        currentEvent: event
      };
    }

    case actionTypes.VALIDATE_CUSTOMER_EVENT_SAC_VALUE_UPDATE: {
      return {
        ...state,
        subjectAreaContent: {
          ...state.subjectAreaContent,
          [action.payload.field]: action.payload.value
        }
      };
    }

    case actionTypes.SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED: {
      return {
        ...state,
        currentEvent: action.payload.data.result,
        subjectAreaContent: {
          ...initialState.subjectAreaContent,
          saved: true
        }
      };
    }

    case actionTypes.DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED: {
      return {
        ...state,
        currentEvent: action.payload.data.result
      };
    }

    case actionTypes.SET_VALIDATE_CUSTOMER_EVENT_SAC_SAVED: {
      return {
        ...state,
        subjectAreaContent: {
          ...state.subjectAreaContent,
          saved: action.payload.saved
        }
      };
    }

    case actionTypes.FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_TYPES_PENDING: {
      return {
        ...state,
        objectTypes: initialState.objectTypes
      };
    }

    case actionTypes.SAVE_OBJECT_TYPE_FULFILLED: {
      const objectTypes = state.objectTypes.slice();

      if (action.payload.data.success) {
        objectTypes.push(action.payload.data.result);
      }

      return {
        ...state,
        objectTypes
      };
    }

    case actionTypes.SAVE_OBJECT_MODEL_FULFILLED: {
      const objectModels = state.objectModels.slice();

      if (action.payload.data.success) {
        objectModels.push(action.payload.data.result);
      }

      return {
        ...state,
        objectModels
      };
    }

    case actionTypes.FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data.result
      };
    }

    case actionTypes.FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_MODELS_PENDING: {
      return {
        ...state,
        objectModels: initialState.objectModels
      };
    }

    case actionTypes.FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_MODELS_FULFILLED: {
      return {
        ...state,
        objectModels: action.payload.data.result
      };
    }

    case actionTypes.SET_EVENT_PLAYBACK_PAUSED: {
      return {
        ...state,
        eventPlaybackPaused: action.payload
      };
    }

    case actionTypes.SET_EVENT_PLAYBACK_RATE: {
      return {
        ...state,
        eventVideoPlaybackRate: action.payload
      };
    }

    case actionTypes.VALIDATE_CUSTOMER_EVENT_SAC_ADD: {
      const additionalItemsSetup = { ...state.additionalItemsSetup };
      const subjectAreaContents = additionalItemsSetup.subjectAreaContents.slice();

      subjectAreaContents.splice(
        additionalItemsSetup.subjectAreaContents.length,
        0,
        { ...initialState.defaultSubjectAreaContent }
      );
      additionalItemsSetup.subjectAreaContents = subjectAreaContents;

      return {
        ...state,
        additionalItemsSetup: additionalItemsSetup
      };
    }

    case actionTypes.VALIDATE_CUSTOMER_EVENT_SAC_REMOVE: {
      const additionalItemsSetup = { ...state.additionalItemsSetup };
      const subjectAreaContents = additionalItemsSetup.subjectAreaContents.slice();

      subjectAreaContents.splice(action.payload.subjectAreaContent.index, 1);
      additionalItemsSetup.subjectAreaContents = subjectAreaContents;

      return {
        ...state,
        additionalItemsSetup: additionalItemsSetup
      };
    }

    default: {
      return state;
    }
  }
};

export default validateCustomerEventReducer;
