import ws from '../utils/ws';

/* Actions */

export const eventModelWorkerPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: EVENT_MODEL_WORKER_PAGINATED_LIST,
    payload: ws.get(`/event_model_workers/page/${pageNumber}/rows/${pageSize}`)
  });

export const eventModelWorkerValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_EVENT_MODEL_WORKER_VALUE_UPDATE,
    payload: values
  });

export const findEventModelWorker = modelWorkerId => dispatch =>
  dispatch({
    type: FIND_EVENT_MODEL_WORKER,
    payload: ws.get(`/event_model_workers/${modelWorkerId}`)
  });

export const eventModelWorkerValueReset = () => dispatch =>
  dispatch({
    type: CREATE_EVENT_MODEL_WORKER_VALUE_RESET
  });

export const deactivateModelWorker = modelWorkerId => dispatch =>
  dispatch({
    type: DEACTIVATE_EVENT_MODEL_WORKER,
    payload: ws.post(`/event_model_workers/${modelWorkerId}/deactivate`)
  });

export const activateModelWorker = modelWorkerId => dispatch =>
  dispatch({
    type: ACTIVATE_EVENT_MODEL_WORKER,
    payload: ws.post(`/event_model_workers/${modelWorkerId}/activate`)
  });

export const editEventModelWorker = id => dispatch =>
  dispatch({
    type: EDIT_EVENT_MODEL_WORKER,
    payload: ws.get(`/event_model_workers/${id}`)
  });

export const saveEventModelWorker = modelWorker => dispatch =>
  dispatch({
    type: SAVE_EVENT_MODEL_WORKER,
    payload: ws.post('/event_model_workers/', modelWorker)
  });

export const deleteEventModelWorker = modelWorker => dispatch =>
  dispatch({
    type: DELETE_EVENT_MODEL_WORKER,
    payload: ws.post(`/event_model_workers/${modelWorker.id}/delete`)
  });

/* Constants */

export const EVENT_MODEL_WORKER_PAGINATED_LIST =
  'EVENT_MODEL_WORKER_PAGINATED_LIST';
export const EVENT_MODEL_WORKER_PAGINATED_LIST_PENDING =
  'EVENT_MODEL_WORKER_PAGINATED_LIST_PENDING';
export const EVENT_MODEL_WORKER_PAGINATED_LIST_FULFILLED =
  'EVENT_MODEL_WORKER_PAGINATED_LIST_FULFILLED';

export const CREATE_EVENT_MODEL_WORKER_VALUE_UPDATE =
  'CREATE_EVENT_MODEL_WORKER_VALUE_UPDATE';
export const CREATE_EVENT_MODEL_WORKER_VALUE_RESET =
  'CREATE_EVENT_MODEL_WORKER_VALUE_RESET';

export const DEACTIVATE_EVENT_MODEL_WORKER = 'DEACTIVATE_EVENT_MODEL_WORKER';
export const DEACTIVATE_EVENT_MODEL_WORKER_PENDING =
  'DEACTIVATE_EVENT_MODEL_WORKER_PENDING';
export const DEACTIVATE_EVENT_MODEL_WORKER_FULFILLED =
  'DEACTIVATE_EVENT_MODEL_WORKER_FULFILLED';

export const ACTIVATE_EVENT_MODEL_WORKER = 'ACTIVATE_EVENT_MODEL_WORKER';
export const ACTIVATE_EVENT_MODEL_WORKER_PENDING =
  'ACTIVATE_EVENT_MODEL_WORKER_PENDING';
export const ACTIVATE_EVENT_MODEL_WORKER_FULFILLED =
  'ACTIVATE_EVENT_MODEL_WORKER_FULFILLED';

export const FIND_EVENT_MODEL_WORKER = 'FIND_EVENT_MODEL_WORKER';
export const FIND_EVENT_MODEL_WORKER_PENDING =
  'FIND_EVENT_MODEL_WORKER_PENDING';
export const FIND_EVENT_MODEL_WORKER_FULFILLED =
  'FIND_EVENT_MODEL_WORKER_FULFILLED';

export const EDIT_EVENT_MODEL_WORKER = 'EDIT_EVENT_MODEL_WORKER';
export const EDIT_EVENT_MODEL_WORKER_PENDING =
  'EDIT_EVENT_MODEL_WORKER_PENDING';
export const EDIT_EVENT_MODEL_WORKER_FULFILLED =
  'EDIT_EVENT_MODEL_WORKER_FULFILLED';

export const SAVE_EVENT_MODEL_WORKER = 'SAVE_EVENT_MODEL_WORKER';
export const SAVE_EVENT_MODEL_WORKER_PENDING =
  'SAVE_EVENT_MODEL_WORKER_PENDING';
export const SAVE_EVENT_MODEL_WORKER_FULFILLED =
  'SAVE_EVENT_MODEL_WORKER_FULFILLED';

export const DELETE_EVENT_MODEL_WORKER = 'DELETE_EVENT_MODEL_WORKER';
export const DELETE_EVENT_MODEL_WORKER_PENDING =
  'DELETE_EVENT_MODEL_WORKER_PENDING';
export const DELETE_EVENT_MODEL_WORKER_FULFILLED =
  'DELETE_EVENT_MODEL_WORKER_FULFILLED';
