import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ANALISYS_OVERVIEW_RESULT_TYPES } from 'common/constants';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 850,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  checkboxCell: {
    width: 30
  }
}));

const ResultTypeSelect = props => {
  const {
    open,
    onClose,
    selectedResultTypes,
    onSelectResultTypes,
    className,
    ...rest
  } = props;

  const classes = useStyles();
  const [resultTypes, setResultTypes] = useState(selectedResultTypes);

  const handleClose = () => {
    onClose();
  };

  const handleToggleResultType = (resultType, selected) => {
    const types = resultTypes.slice();

    if (selected) {
      types.push(resultType);
    } else {
      types.splice(types.indexOf(resultType), 1);
    }

    setResultTypes(types);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Select Result Types" />
        <Divider />

        <CardContent className={classes.content}>
          <Table size="small">
            <TableBody>
              {Object.keys(ANALISYS_OVERVIEW_RESULT_TYPES).map(resultType => (
                <React.Fragment key={resultType}>
                  {!ANALISYS_OVERVIEW_RESULT_TYPES[resultType].chartHidden && (
                    <TableRow hover>
                      <TableCell className={classes.checkboxCell}>
                        <Checkbox
                          size="small"
                          color="primary"
                          checked={
                            resultTypes
                              ? resultTypes.indexOf(resultType) > -1
                              : false
                          }
                          onChange={e =>
                            handleToggleResultType(resultType, e.target.checked)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {ANALISYS_OVERVIEW_RESULT_TYPES[resultType].name}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            onClick={() => onSelectResultTypes(resultTypes)}
            variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ResultTypeSelect.displayName = 'ResultTypeSelect';

ResultTypeSelect.propTypes = {
  className: PropTypes.string
};

export default ResultTypeSelect;
