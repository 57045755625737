import * as actionTypes from 'actions';

const initialState = {
  pageNumber: 1,
  rowCount: 0,
  loading: false,
  items: [],
  pageSize: 250,
  filterItemCount: null,
  filter: {
    search: '',
    showDeprecated: false
  },
  detailsModel: {}
};

const PAGE = 'MODEL_CONFIGURATION_LIST';

const modelListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MODELS_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.MODELS_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        filter: {
          ...initialState.filter,
          ...action.payload.data.result.filter
        },
        loading: false
      };
    }

    case actionTypes.SET_MODEL_LIST_DETAILS_MODEL: {
      return {
        ...state,
        detailsModel: action.payload
      };
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          pageSize: action.payload.pageSize
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...initialState.filter,
            search: state.filter.search
          },
          filterItemCount: initialState.filterItemCount
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === PAGE
      ) {
        result = {
          ...state,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.filter,
            ...action.payload.data.result.filter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    default: {
      return state;
    }
  }
};

export default modelListReducer;
