import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {},
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

const CreateCheckListItem = props => {
  const {
    open,
    checkListItem,
    validationMessages,
    onCancel,
    onDelete,
    onSave,
    onFieldChange
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    onCancel();
  };

  const handleValueChange = (field, value) => {
    onFieldChange(field, value);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader
          title={`${checkListItem.guid ? 'Edit' : 'Add'} Check List Item`}
        />
        <Divider />
        <CardContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Check Description"
                error={validationMessages.hasOwnProperty('checkDescription')}
                value={
                  checkListItem.checkDescription
                    ? checkListItem.checkDescription
                    : ''
                }
                helperText={validationMessages.checkDescription}
                variant="outlined"
                onChange={e =>
                  handleValueChange('checkDescription', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Expected Result"
                error={validationMessages.hasOwnProperty('expectedResult')}
                value={
                  checkListItem.expectedResult
                    ? checkListItem.expectedResult
                    : ''
                }
                helperText={validationMessages.expectedResult}
                variant="outlined"
                onChange={e =>
                  handleValueChange('expectedResult', e.target.value)
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button
            className={classes.deleteButton}
            onClick={onDelete}
            variant="contained">
            Delete
          </Button>
          <Button color="primary" onClick={onSave} variant="contained">
            Save
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CreateCheckListItem.defaultProps = {
  open: false
};

export default CreateCheckListItem;
