import { combineReducers } from 'redux';

import broadcastMessageReducer from './broadcastMessageReducer';
import cellBuildDefinitionCreateReducer from './cellBuildDefinitionCreateReducer';
import cellBuildDefinitionDetailsReducer from './cellBuildDefinitionDetailsReducer';
import cellBuildDefinitionListReducer from './cellBuildDefinitionListReducer';
import cellCheckListDefinitionCreateReducer from './cellCheckListDefinitionCreateReducer';
import cellCheckListDefinitionDetailsReducer from './cellCheckListDefinitionDetailsReducer';
import cellCheckListDefinitionListReducer from './cellCheckListDefinitionListReducer';
import cellComponentCreateReducer from './cellComponentCreateReducer';
import cellComponentDetailsReducer from './cellComponentDetailsReducer';
import cellComponentListReducer from './cellComponentListReducer';
import cellComponentVersionCreateReducer from './cellComponentVersionCreateReducer';
import cellComponentVersionSelectReducer from './cellComponentVersionSelectReducer';
import cellCreateReducer from './cellCreateReducer';
import cellDetailsReducer from './cellDetailsReducer';
import cellHealthReportListReducer from './cellHealthReportListReducer';
import cellListReducer from './cellListReducer';
import cellLogEntryListReducer from './cellLogEntryListReducer';
import cellModelVersionCreateReducer from './cellModelVersionCreateReducer';
import cellModelVersionDetailsReducer from './cellModelVersionDetailsReducer';
import cellModelVersionListReducer from './cellModelVersionListReducer';
import cellRadarCreateReducer from './cellRadarCreateReducer';
import cellRadarDetailsReducer from './cellRadarDetailsReducer';
import cellRadarListReducer from './cellRadarListReducer';
import cellVenueInstallationCreateReducer from './cellVenueInstallationCreateReducer';
import cellsReducer from './cellsReducer';
import createCellCheckListReducer from './createCellCheckListReducer';
import createTicketReducer from './createTicketReducer';
import customerCreateReducer from './customerCreateReducer';
import customerDetailsReducer from './customerDetailsReducer';
import customerEventCreateReducer from './customerEventCreateReducer';
import customerEventDashboardReducer from './customerEventDashboardReducer';
import customerEventDetailsReducer from './customerEventDetailsReducer';
import customerEventListReducer from './customerEventListReducer';
import customerListReducer from './customerListReducer';
import editCellConfigurationReducer from './editCellConfigurationReducer';
import entityPhotoCameraCaptureReducer from './entityPhotoCameraCaptureReducer';
import entityPhotoDetailsReducer from './entityPhotoDetailsReducer';
import entityPhotoUploaderReducer from './entityPhotoUploaderReducer';
import eventDataSetCreateReducer from './eventDataSetCreateReducer';
import eventDataSetDetailsReducer from './eventDataSetDetailsReducer';
import eventDataSetListReducer from './eventDataSetListReducer';
import eventDetailsReducer from './eventDetailsReducer';
import eventFilterReducer from './eventFilterReducer';
import eventImageModalReducer from './eventImageModalReducer';
import eventListReducer from './eventListReducer';
import eventModelRunRequestListReducer from './eventModelRunRequestListReducer';
import eventModelWorkerCreateReducer from './eventModelWorkerCreateReducer';
import eventModelWorkerDetailsReducer from './eventModelWorkerDetailsReducer';
import eventModelWorkerListReducer from './eventModelWorkerListReducer';
import eventVideoReducer from './eventVideoReducer';
import facialRecognitionOverviewReducer from './facialRecognitionOverviewReducer';
import masterVersionCreateReducer from './masterVersionCreateReducer';
import masterVersionDetailsReducer from './masterVersionDetailsReducer';
import masterVersionListReducer from './masterVersionListReducer';
import modelAnalysisComparisonReducer from './modelAnalysisComparisonReducer';
import modelConfigurationCreateReducer from './modelConfigurationCreateReducer';
import modelConfigurationDetailsReducer from './modelConfigurationDetailsReducer';
import modelConfigurationListReducer from './modelConfigurationListReducer';
import modelConfigurationReducer from './modelConfigurationReducer';
import modelInfoRetrievalListReducer from './modelInfoRetrievalListReducer';
import modelSetCreateReducer from './modelSetCreateReducer';
import modelSetDetailsReducer from './modelSetDetailsReducer';
import modelSetListReducer from './modelSetListReducer';
import objectModelCreateReducer from './objectModelCreateReducer';
import objectTypeCreateReducer from './objectTypeCreateReducer';
import participantCreateReducer from './participantCreateReducer';
import participantDetailsReducer from './participantDetailsReducer';
import participantListReducer from './participantListReducer';
import portalUserCreateReducer from './portalUserCreateReducer';
import portalUserDetailsReducer from './portalUserDetailsReducer';
import portalUserListReducer from './portalUserListReducer';
import selectEventDataSetFiltersReducer from './selectEventDataSetFiltersReducer';
import selectEventDataSetReducer from './selectEventDataSetReducer';
import selectModelReducer from './selectModelReducer';
import selectedCellSharedIdentifiersReducer from './selectedCellSharedIdentifiersReducer';
import sessionEventDataSetAddReducer from './sessionEventDataSetAddReducer';
import sessionGroupCreateReducer from './sessionGroupCreateReducer';
import sessionGroupDetailsReducer from './sessionGroupDetailsReducer';
import sessionGroupListReducer from './sessionGroupListReducer';
import sessionReducer from './sessionReducer';
import setupScenarioCreateReducer from './setupScenarioCreateReducer';
import setupScenarioDetailsReducer from './setupScenarioDetailsReducer';
import setupScenarioListReducer from './setupScenarioListReducer';
import setupScenarioSessionCreateReducer from './setupScenarioSessionCreateReducer';
import setupScenarioSessionDetailsReducer from './setupScenarioSessionDetailsReducer';
import setupScenarioSessionWalkReducer from './setupScenarioSessionWalkReducer';
import shortUrlCreateReducer from './shortUrlCreateReducer';
import shortUrlListReducer from './shortUrlListReducer';
import softwarePackageDetailsReducer from './softwarePackageDetailsReducer';
import softwarePackageListReducer from './softwarePackageListReducer';
import testResultFileListReducer from './testResultFileListReducer';
import testSessionListReducer from './testSessionListReducer';
import testSessionUserScheduledListReducer from './testSessionUserScheduledListReducer';
import testingAnalysisReducer from './testingAnalysisReducer';
import testingDashboardReducer from './testingDashboardReducer';
import trainingCellsOverviewReducer from './trainingCellsOverviewReducer';
import trainingCompatibilityGroupCreateReducer from './trainingCompatibilityGroupCreateReducer';
import trainingCompatibilityGroupDetailsReducer from './trainingCompatibilityGroupDetailsReducer';
import trainingCompatibilityGroupListReducer from './trainingCompatibilityGroupListReducer';
import trainingDashboardReducer from './trainingDashboardReducer';
import trainingSessionListReducer from './trainingSessionListReducer';
import trainingVideoReducer from './trainingVideoReducer';
import uploadCellConfigurationReducer from './uploadCellConfigurationReducer';
import userListReducer from './userListReducer';
import userProfileUpdateReducer from './userProfileUpdateReducer';
import validateCustomerEventReducer from './validateCustomerEventReducer';
import validateEventsReducer from './validateEventsReducer';
import validateTrainingSessionReducer from './validateTrainingSessionReducer';
import venueCreateReducer from './venueCreateReducer';
import venueDetailsReducer from './venueDetailsReducer';
import venueListReducer from './venueListReducer';
import walkLocalStorageReducer from './walkLocalStorageReducer';
import walkSessionsReducer from './walkSessionsReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  customerList: customerListReducer,
  customerDetails: customerDetailsReducer,
  userList: userListReducer,
  setupScenarioList: setupScenarioListReducer,
  setupScenarioDetails: setupScenarioDetailsReducer,
  setupScenarioCreate: setupScenarioCreateReducer,
  testSessionList: testSessionListReducer,
  testSessionUserScheduledList: testSessionUserScheduledListReducer,
  trainingSessionList: trainingSessionListReducer,
  setupScenarioSessionDetails: setupScenarioSessionDetailsReducer,
  setupScenarioSessionCreate: setupScenarioSessionCreateReducer,
  setupScenarioSessionWalk: setupScenarioSessionWalkReducer,
  userProfileUpdate: userProfileUpdateReducer,
  eventList: eventListReducer,
  eventVideo: eventVideoReducer,
  trainingVideo: trainingVideoReducer,
  modelConfigurationList: modelConfigurationListReducer,
  modelConfigurationDetails: modelConfigurationDetailsReducer,
  modelConfigurationCreate: modelConfigurationCreateReducer,
  objectTypeCreate: objectTypeCreateReducer,
  objectModelCreate: objectModelCreateReducer,
  testingAnalysis: testingAnalysisReducer,
  sessionGroupList: sessionGroupListReducer,
  sessionGroupCreate: sessionGroupCreateReducer,
  sessionGroupDetails: sessionGroupDetailsReducer,
  participantList: participantListReducer,
  participantCreate: participantCreateReducer,
  participantDetails: participantDetailsReducer,
  entityPhotoCameraCapture: entityPhotoCameraCaptureReducer,
  entityPhotoUploader: entityPhotoUploaderReducer,
  entityPhotoDetails: entityPhotoDetailsReducer,
  validateTrainingSession: validateTrainingSessionReducer,
  trainingDashboard: trainingDashboardReducer,
  modelConfigurations: modelConfigurationReducer,
  cellList: cellListReducer,
  cellCreate: cellCreateReducer,
  cellDetails: cellDetailsReducer,
  masterVersionList: masterVersionListReducer,
  masterVersionCreate: masterVersionCreateReducer,
  masterVersionDetails: masterVersionDetailsReducer,
  cellComponentList: cellComponentListReducer,
  cellComponentCreate: cellComponentCreateReducer,
  cellComponentDetails: cellComponentDetailsReducer,
  cellComponentVersionCreate: cellComponentVersionCreateReducer,
  cellComponentVersionSelect: cellComponentVersionSelectReducer,
  cellVenueInstallationCreate: cellVenueInstallationCreateReducer,
  eventModelRunRequestList: eventModelRunRequestListReducer,
  eventDataSetList: eventDataSetListReducer,
  eventDataSetCreate: eventDataSetCreateReducer,
  eventDataSetDetails: eventDataSetDetailsReducer,
  modelAnalysisComparison: modelAnalysisComparisonReducer,
  venueCreate: venueCreateReducer,
  venueList: venueListReducer,
  venueDetails: venueDetailsReducer,
  customerEventCreate: customerEventCreateReducer,
  customerEventList: customerEventListReducer,
  customerEventDetails: customerEventDetailsReducer,
  validateCustomerEvent: validateCustomerEventReducer,
  eventDetails: eventDetailsReducer,
  customerEventDashboard: customerEventDashboardReducer,
  eventFilter: eventFilterReducer,
  eventModelWorkerList: eventModelWorkerListReducer,
  eventModelWorkerDetails: eventModelWorkerDetailsReducer,
  eventModelWorkerCreate: eventModelWorkerCreateReducer,
  cellRadarList: cellRadarListReducer,
  cellRadarCreate: cellRadarCreateReducer,
  cellRadarDetails: cellRadarDetailsReducer,
  cellModelVersionCreate: cellModelVersionCreateReducer,
  cells: cellsReducer,
  cellModelVersionList: cellModelVersionListReducer,
  cellModelVersionDetails: cellModelVersionDetailsReducer,
  cellBuildDefinitionList: cellBuildDefinitionListReducer,
  cellBuildDefinitionDetails: cellBuildDefinitionDetailsReducer,
  cellBuildDefinitionCreate: cellBuildDefinitionCreateReducer,
  cellCheckListDefinitionList: cellCheckListDefinitionListReducer,
  cellCheckListDefinitionCreate: cellCheckListDefinitionCreateReducer,
  cellCheckListDefinitionDetails: cellCheckListDefinitionDetailsReducer,
  trainingCompatibilityGroupList: trainingCompatibilityGroupListReducer,
  trainingCompatibilityGroupCreate: trainingCompatibilityGroupCreateReducer,
  trainingCompatibilityGroupDetails: trainingCompatibilityGroupDetailsReducer,
  createCellCheckList: createCellCheckListReducer,
  testingDashboard: testingDashboardReducer,
  modelInfoRetrievalList: modelInfoRetrievalListReducer,
  sessionEventDataSetAdd: sessionEventDataSetAddReducer,
  softwarePackageList: softwarePackageListReducer,
  softwarePackageDetails: softwarePackageDetailsReducer,
  validateEvents: validateEventsReducer,
  selectEventDataSetFilters: selectEventDataSetFiltersReducer,
  selectEventDataSet: selectEventDataSetReducer,
  testResultFileList: testResultFileListReducer,
  cellLogEntryList: cellLogEntryListReducer,
  uploadCellConfiguration: uploadCellConfigurationReducer,
  editCellConfiguration: editCellConfigurationReducer,
  eventImageModal: eventImageModalReducer,
  shortUrlList: shortUrlListReducer,
  shortUrlCreate: shortUrlCreateReducer,
  trainingCellsOverview: trainingCellsOverviewReducer,
  walkLocalStorage: walkLocalStorageReducer,
  portalUserCreate: portalUserCreateReducer,
  portalUserList: portalUserListReducer,
  portalUserDetails: portalUserDetailsReducer,
  broadcastMessage: broadcastMessageReducer,
  customerCreate: customerCreateReducer,
  cellHealthReportList: cellHealthReportListReducer,
  createTicket: createTicketReducer,
  facialRecognitionOverview: facialRecognitionOverviewReducer,
  modelSetList: modelSetListReducer,
  modelSetCreate: modelSetCreateReducer,
  modelSetDetails: modelSetDetailsReducer,
  selectModel: selectModelReducer,
  selectedCellSharedIdentifiers: selectedCellSharedIdentifiersReducer,
  walkSessions: walkSessionsReducer
});

export default rootReducer;
