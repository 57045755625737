import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Button,
  CardHeader,
  Divider,
  colors,
  Tabs,
  Tab
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  dataContainer: {
    borderWidth: 1,
    borderColor: colors.grey[200],
    borderStyle: 'solid',
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    marginBottom: theme.spacing(1),
    overflow: 'scroll',
    maxHeight: 400,
    color: colors.blue[800]
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const MatlabDataDetails = props => {
  const {
    open,
    onClose,
    matlabData,
    loading,
    className,
    scenarioType,
    ...rest
  } = props;

  const [tab, setTab] = useState('subjectAreaContents');

  const tabs = [];

  tabs.push({ value: 'subjectAreaContents', label: 'Subject Area Contents' });
  if (scenarioType && scenarioType.code === 'TRAINING') {
    tabs.push({ value: 'openSessionData', label: 'Open Session' });
  } else {
    tabs.push({ value: 'newTestSessionEventData', label: 'New Event' });
  }

  const subjectAreaContentsJsonStr = JSON.stringify(
    matlabData.subjectAreaContents,
    null,
    2
  );
  const newTestSessionEventJsonStr = JSON.stringify(
    matlabData.newTestSessionEventData,
    null,
    2
  );
  const openSessionJsonStr = JSON.stringify(
    matlabData.openSessionData,
    null,
    2
  );

  const classes = useStyles();

  if (!open) {
    return null;
  }

  const handleClose = () => {
    onClose();
  };

  const handleTabsChange = (event, value) => {
    event.persist && event.persist();
    setTab(value);
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          title={
            scenarioType.code === 'TRAINING' ? 'Data Trainer' : 'Data Collector'
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          {loading ? (
            <LinearProgress />
          ) : (
            <div>
              <Tabs
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={tab}
                variant="scrollable">
                {tabs.map(tab => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              <Divider />
              <PerfectScrollbar>
                <div className={classes.dataContainer}>
                  <pre>
                    {tab === 'subjectAreaContents' &&
                      subjectAreaContentsJsonStr}
                    {tab === 'newTestSessionEventData' &&
                      newTestSessionEventJsonStr}
                    {tab === 'openSessionData' && openSessionJsonStr}
                  </pre>
                </div>
              </PerfectScrollbar>
            </div>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

MatlabDataDetails.propTypes = {
  subjectAreaContentData: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

MatlabDataDetails.defaultProps = {
  open: false
};

export default MatlabDataDetails;
