import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedSessionGroup: {},
  deleted: false,
  deletedSessionGroup: {},
  sessionGroup: {
    name: '',
    description: '',
    category: 'USER',
    sessions: [],
    betaModelGroup: false
  },
  validationMessages: {},
  deleting: false
};

const sessionGroupCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SESSION_GROUP_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedSessionGroup: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.EDIT_SESSION_GROUP_FULFILLED: {
      return {
        ...state,
        sessionGroup: action.payload.data.result
      };
    }

    case actionTypes.DELETE_SESSION_GROUP_PENDING: {
      return {
        ...state,
        deleting: true
      };
    }

    case actionTypes.DELETE_SESSION_GROUP_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deletedSessionGroup: action.payload.data.result,
        deleting: false
      };
    }

    case actionTypes.CREATE_SESSION_GROUP_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_SESSION_GROUP_VALUE_UPDATE: {
      return {
        ...state,
        sessionGroup: {
          ...state.sessionGroup,
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionGroupCreateReducer;
