import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import ws from '../utils/ws';

import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from 'reducers';

export default function configureStore(preloadedState = {}) {
  const middlewares = [promise, thunk, logger];
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
