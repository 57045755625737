import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedMasterVersion: {},
  deleted: false,
  deleteMessage: null,
  deletedMasterVersion: {},
  masterVersion: {
    creationId: null,
    versionName: ''
  },
  customers: [],
  cellComponents: [],
  excludedCellComponentIds: [],
  cellComponentVersions: {},
  modelConfigurations: [],
  validationMessages: {}
};

const masterVersionCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_MASTER_VERSION_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedMasterVersion: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_MASTER_VERSION_PENDING: {
      return {
        ...state,
        deleted: initialState.deleted,
        deletedMasterVersion: initialState.deletedMasterVersion
      };
    }

    case actionTypes.DELETE_MASTER_VERSION_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deleteMessage: action.payload.data.message,
        deletedMasterVersion: action.payload.data.result
      };
    }

    case actionTypes.EDIT_MASTER_VERSION_FULFILLED: {
      const masterVersion = {
        ...action.payload.data.result,
        identifiersCreated: true
      };

      const excludedCellComponentIds = masterVersion.excludedCellComponents
        ? masterVersion.excludedCellComponents.map(
            cellComponent => cellComponent.id
          )
        : initialState.excludedCellComponentIds;

      // const cellComponentVersions =

      return {
        ...state,
        masterVersion,
        excludedCellComponentIds
      };
    }

    case actionTypes.CREATE_MASTER_VERSION_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_MASTER_VERSION_VALUE_UPDATE: {
      return {
        ...state,
        masterVersion: {
          ...state.masterVersion,
          ...action.payload
        }
      };
    }

    case actionTypes.CLEAR_MASTER_VERSION_DELETE_MESSAGE: {
      return {
        ...state,
        deleteMessage: initialState.deleteMessage
      };
    }

    case actionTypes.FIND_LATEST_CELL_COMPONENT_VERSIONS_PENDING: {
      return {
        ...state,
        cellComponentVersions: initialState.cellComponentVersions
      };
    }

    case actionTypes.FIND_LATEST_CELL_COMPONENT_VERSIONS_FULFILLED: {
      return {
        ...state,
        cellComponentVersions: action.payload.data.result
      };
    }

    case actionTypes.FIND_MASTER_VERSION_COMPONENT_VERSIONS_PENDING: {
      return {
        ...state,
        cellComponentVersions: initialState.cellComponentVersions
      };
    }

    case actionTypes.FIND_MASTER_VERSION_COMPONENT_VERSIONS_FULFILLED: {
      return {
        ...state,
        cellComponentVersions: action.payload.data.result
      };
    }

    case actionTypes.SET_MASTER_VERSION_CELL_COMPONENT_VERSION: {
      const { cellComponent, version } = action.payload;
      const cellComponentVersions = { ...state.cellComponentVersions };

      cellComponentVersions[cellComponent.id] = version;

      return {
        ...state,
        cellComponentVersions: cellComponentVersions
      };
    }

    case actionTypes.SET_MASTER_VERSION_COMPONENT_EXCLUDED: {
      let excludedCellComponentIds = state.excludedCellComponentIds.slice();
      const { cellComponentId, excluded } = action.payload;

      if (excluded) {
        excludedCellComponentIds.push(cellComponentId);
      } else {
        excludedCellComponentIds.splice(
          excludedCellComponentIds.indexOf(cellComponentId),
          1
        );
      }

      return {
        ...state,
        excludedCellComponentIds
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    case actionTypes.FIND_CELL_COMPONENTS_FULFILLED: {
      return {
        ...state,
        cellComponents: action.payload.data.result
      };
    }

    case actionTypes.FIND_MODEL_CONFIGURATIONS_FULFILLED: {
      return {
        ...state,
        modelConfigurations: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default masterVersionCreateReducer;
