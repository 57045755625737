import ws from '../utils/ws';

/* Actions */

export const cellModelVersionPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: CELL_MODEL_VERSIONS_PAGINATED_LIST,
    payload: ws.get(`/cell_model_versions/page/${pageNumber}/rows/${pageSize}`)
  });

export const cellModelVersionValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CELL_MODEL_VERSION_VALUE_UPDATE,
    payload: values
  });

export const cellModelVersionValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CELL_MODEL_VERSION_VALUE_RESET
  });

export const findLatestCellModelVersionVersions = () => dispatch =>
  dispatch({
    type: FIND_LATEST_CELL_MODEL_VERSION_VERSIONS,
    payload: ws.get('/cell_model_versions/latest_versions/')
  });

export const saveCellModelVersion = cellModelVersion => dispatch =>
  dispatch({
    type: SAVE_CELL_MODEL_VERSION,
    payload: ws.post('/cell_model_versions/', cellModelVersion)
  });

export const deleteCellModelVersion = cellModelVersion => dispatch =>
  dispatch({
    type: DELETE_CELL_MODEL_VERSION,
    payload: ws.post(`/cell_model_versions/${cellModelVersion.id}/delete`)
  });

export const editCellModelVersion = id => dispatch =>
  dispatch({
    type: EDIT_CELL_MODEL_VERSION,
    payload: ws.get(`/cell_model_versions/${id}`)
  });

export const findCellModelVersions = () => dispatch =>
  dispatch({
    type: FIND_CELL_MODEL_VERSIONS,
    payload: ws.get('/cell_model_versions/')
  });

export const findCellModelVersion = id => dispatch =>
  dispatch({
    type: FIND_CELL_MODEL_VERSION,
    payload: ws.get(`/cell_model_versions/${id}`)
  });

export const clearCellModelVersionDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_CELL_MODEL_VERSION_DELETE_MESSAGE
  });

/* Constants */

export const CELL_MODEL_VERSIONS_PAGINATED_LIST =
  'CELL_MODEL_VERSIONS_PAGINATED_LIST';
export const CELL_MODEL_VERSIONS_PAGINATED_LIST_PENDING =
  'CELL_MODEL_VERSIONS_PAGINATED_LIST_PENDING';
export const CELL_MODEL_VERSIONS_PAGINATED_LIST_FULFILLED =
  'CELL_MODEL_VERSIONS_PAGINATED_LIST_FULFILLED';

export const CREATE_CELL_MODEL_VERSION_VALUE_UPDATE =
  'CREATE_CELL_MODEL_VERSIONS_VALUE_UPDATE';
export const CREATE_CELL_MODEL_VERSION_VALUE_RESET =
  'CREATE_CELL_MODEL_VERSION_VALUE_RESET';

export const SAVE_CELL_MODEL_VERSION = 'SAVE_CELL_MODEL_VERSION';
export const SAVE_CELL_MODEL_VERSION_FULFILLED =
  'SAVE_CELL_MODEL_VERSION_FULFILLED';

export const DELETE_CELL_MODEL_VERSION = 'DELETE_CELL_MODEL_VERSION';
export const DELETE_CELL_MODEL_VERSION_PENDING =
  'DELETE_CELL_MODEL_VERSION_PENDING';
export const DELETE_CELL_MODEL_VERSION_FULFILLED =
  'DELETE_CELL_MODEL_VERSION_FULFILLED';

export const EDIT_CELL_MODEL_VERSION = 'EDIT_CELL_MODEL_VERSION';
export const EDIT_CELL_MODEL_VERSION_FULFILLED =
  'EDIT_CELL_MODEL_VERSION_FULFILLED';

export const FIND_CELL_MODEL_VERSIONS = 'FIND_CELL_MODEL_VERSIONS';
export const FIND_CELL_MODEL_VERSIONS_PENDING =
  'FIND_CELL_MODEL_VERSIONS_PENDING';
export const FIND_CELL_MODEL_VERSIONS_FULFILLED =
  'FIND_CELL_MODEL_VERSIONS_FULFILLED';

export const FIND_CELL_MODEL_VERSION = 'FIND_CELL_MODEL_VERSION';
export const FIND_CELL_MODEL_VERSION_PENDING =
  'FIND_CELL_MODEL_VERSION_PENDING';
export const FIND_CELL_MODEL_VERSION_FULFILLED =
  'FIND_CELL_MODEL_VERSION_FULFILLED';

export const FIND_LATEST_CELL_MODEL_VERSION_VERSIONS =
  'FIND_LATEST_CELL_MODEL_VERSION_VERSIONS';
export const FIND_LATEST_CELL_MODEL_VERSION_VERSIONS_PENDING =
  'FIND_LATEST_CELL_MODEL_VERSION_VERSIONS_PENDING';
export const FIND_LATEST_CELL_MODEL_VERSION_VERSIONS_FULFILLED =
  'FIND_LATEST_CELL_MODEL_VERSION_VERSIONS_FULFILLED';

export const CLEAR_CELL_MODEL_VERSION_DELETE_MESSAGE =
  'CLEAR_CELL_MODEL_VERSION_DELETE_MESSAGE';
