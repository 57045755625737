import ws from '../utils/ws';
import { ENTITY_TYPES } from 'common/constants';

/* Actions */

export const setValidateTrainingSessionSetup = (session, setup) => dispatch =>
  dispatch({
    type: SET_VALIDATE_TRAINING_SESSION_SETUP,
    payload: {
      session,
      setup
    }
  });

export const findValidateSessionEvent = eventId => dispatch =>
  dispatch({
    type: FIND_VALIDATE_SESSION_EVENT,
    payload: ws.get(`/events/${eventId}`)
  });

export const findValidateSessionEvents = sessionId => dispatch =>
  dispatch({
    type: FIND_VALIDATE_SESSION_EVENTS,
    payload: ws.get(`/sessions/${sessionId}/events/?torsoThreshold=50`)
  });

export const updateTrainingEventValidationStatus = (
  eventId,
  statusCode
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_VALIDATION_STATUS,
    payload: ws.post(`/events/${eventId}/validation_status`, {
      validationStatus: statusCode
    })
  });

export const setValidateSessionProcessingEventId = eventId => dispatch =>
  dispatch({
    type: SET_VALIDATION_STATUS_PROCESSING_EVENT_ID,
    payload: eventId
  });

export const updateSetupValidationStatus = (
  sessionId,
  setupNumber,
  statusCode
) => dispatch =>
  dispatch({
    type: UPDATE_SETUP_VALIDATION_STATUS,
    payload: ws.post(
      `/sessions/${sessionId}/setups/${setupNumber}/validation_status`,
      { status: statusCode }
    )
  });

export const setEventPlaybackPaused = paused => dispatch =>
  dispatch({
    type: SET_EVENT_PLAYBACK_PAUSED,
    payload: paused
  });

export const setEventPlaybackRate = paused => dispatch =>
  dispatch({
    type: SET_EVENT_PLAYBACK_RATE,
    payload: paused
  });

export const setValidateSetupActiveStep = activeStep => dispatch =>
  dispatch({
    type: SET_VALIDATE_SETUP_ACTIVE_STEP,
    payload: activeStep
  });

export const setSetupValidationStatusChangeStatus = status => dispatch =>
  dispatch({
    type: SET_SETUP_VALIDATION_STATUS_CHANGE_STATUS,
    payload: status
  });

export const findTrainingSessionValidationParticipantPhotos = participantId => dispatch => {
  const entityType = ENTITY_TYPES.PARTICIPANT;
  dispatch({
    type: FIND_TRAINING_SESSION_VALIDATION_PARTICIPANT_PHOTOS,
    payload: ws.get(`/entity_photos/type/${entityType}/id/${participantId}/`)
  });
};

/* Constants */

export const SET_VALIDATE_TRAINING_SESSION_SETUP =
  'SET_VALIDATE_TRAINING_SESSION_SETUP';

export const FIND_VALIDATE_SESSION_EVENT = 'FIND_VALIDATE_SESSION_EVENT';
export const FIND_VALIDATE_SESSION_EVENT_PENDING =
  'FIND_VALIDATE_SESSION_EVENT_PENDING';
export const FIND_VALIDATE_SESSION_EVENT_FULFILLED =
  'FIND_VALIDATE_SESSION_EVENT_FULFILLED';

export const FIND_VALIDATE_SESSION_EVENTS = 'FIND_VALIDATE_SESSION_EVENTS';
export const FIND_VALIDATE_SESSION_EVENTS_PENDING =
  'FIND_VALIDATE_SESSION_EVENTS_PENDING';
export const FIND_VALIDATE_SESSION_EVENTS_FULFILLED =
  'FIND_VALIDATE_SESSION_EVENTS_FULFILLED';

export const UPDATE_EVENT_VALIDATION_STATUS = 'UPDATE_EVENT_VALIDATION_STATUS';
export const UPDATE_EVENT_VALIDATION_STATUS_PENDING =
  'UPDATE_EVENT_VALIDATION_STATUS_PENDING';
export const UPDATE_EVENT_VALIDATION_STATUS_FULFILLED =
  'UPDATE_EVENT_VALIDATION_STATUS_FULFILLED';

export const UPDATE_SETUP_VALIDATION_STATUS = 'UPDATE_SETUP_VALIDATION_STATUS';
export const UPDATE_SETUP_VALIDATION_STATUS_PENDING =
  'UPDATE_SETUP_VALIDATION_STATUS_PENDING';
export const UPDATE_SETUP_VALIDATION_STATUS_FULFILLED =
  'UPDATE_SETUP_VALIDATION_STATUS_FULFILLED';

export const SET_EVENT_PLAYBACK_PAUSED = 'SET_EVENT_PLAYBACK_PAUSED';

export const SET_EVENT_PLAYBACK_RATE = 'SET_EVENT_PLAYBACK_RATE';

export const SET_VALIDATE_SETUP_ACTIVE_STEP = 'SET_VALIDATE_SETUP_ACTIVE_STEP';

export const SET_SETUP_VALIDATION_STATUS_CHANGE_STATUS =
  'SET_SETUP_VALIDATION_STATUS_CHANGE_STATUS';

export const SET_VALIDATION_STATUS_PROCESSING_EVENT_ID =
  'SET_VALIDATION_STATUS_PROCESSING_EVENT_ID';

export const FIND_TRAINING_SESSION_VALIDATION_PARTICIPANT_PHOTOS =
  'FIND_TRAINING_SESSION_VALIDATION_PARTICIPANT_PHOTOS';
export const FIND_TRAINING_SESSION_VALIDATION_PARTICIPANT_PHOTOS_PENDING =
  'FIND_TRAINING_SESSION_VALIDATION_PARTICIPANT_PHOTOS_PENDING';
export const FIND_TRAINING_SESSION_VALIDATION_PARTICIPANT_PHOTOS_FULFILLED =
  'FIND_TRAINING_SESSION_VALIDATION_PARTICIPANT_PHOTOS_FULFILLED';
