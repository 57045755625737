import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const Restricted = props => {
  const { right, children } = props;

  const { sessionUser } = useSelector(state => state.session);

  if (sessionUser.rights.indexOf(right) === -1) {
    return null;
  } else {
    return children;
  }
};

Restricted.propTypes = {
  right: PropTypes.string
};

export default Restricted;
