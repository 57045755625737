import * as actionTypes from 'actions';

const initialState = {
  modelSetLoading: false,
  modelSet: {
    createdBy: {}
  }
};

const modelSetDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_MODEL_SET_PENDING: {
      return {
        ...initialState,
        modelSetLoading: true
      };
    }

    case actionTypes.FIND_MODEL_SET_FULFILLED: {
      return {
        ...state,
        modelSetLoading: false,
        updated: false,
        modelSet: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default modelSetDetailsReducer;
