export default {
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#FFFFFF'
  },
  containedPrimary: {
    backgroundColor: '#676C7D',
    '&:hover': {
      backgroundColor: '#4F5568'
    }
  },
  outlinedPrimary: {
    borderColor: '#676C7D',
    color: '#676C7D',
    '&:hover': {
      borderColor: '#4F5568',
      color: '#4F5568'
    }
  }
};
