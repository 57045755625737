import * as actionTypes from 'actions';

const initialState = {
  message: '',
  unreadMessage: false
};

const broadcastMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BROADCAST_MESSAGE: {
      return {
        ...initialState,
        message: action.payload,
        unreadMessage: true
      };
    }

    case actionTypes.BROADCAST_MESSAGE_READ: {
      return {
        ...initialState,
        unreadMessage: false
      };
    }

    default: {
      return state;
    }
  }
};

export default broadcastMessageReducer;
