import ws from '../utils/ws';

export const CREATE_OBJECT_TYPE_VALUE_UPDATE =
  'CREATE_OBJECT_TYPE_VALUE_UPDATE';
export const CREATE_OBJECT_TYPE_VALUE_RESET = 'CREATE_OBJECT_TYPE_VALUE_RESET';

export const EDIT_OBJECT_TYPE = 'EDIT_OBJECT_TYPE';
export const EDIT_OBJECT_TYPE_PENDING = 'EDIT_OBJECT_TYPE_PENDING';
export const EDIT_OBJECT_TYPE_FULFILLED = 'EDIT_OBJECT_TYPE_FULFILLED';

export const SAVE_OBJECT_TYPE = 'SAVE_OBJECT_TYPE';
export const SAVE_OBJECT_TYPE_FULFILLED = 'SAVE_OBJECT_TYPE_FULFILLED';

export const FIND_OBJECT_TYPES = 'FIND_OBJECT_TYPES';
export const FIND_OBJECT_TYPES_PENDING = 'FIND_OBJECT_TYPES_PENDING';
export const FIND_OBJECT_TYPES_FULFILLED = 'FIND_OBJECT_TYPES_FULFILLED';

/* Actions */

export const objectTypeValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_OBJECT_TYPE_VALUE_UPDATE,
    payload: values
  });

export const objectTypeValueReset = () => dispatch =>
  dispatch({
    type: CREATE_OBJECT_TYPE_VALUE_RESET
  });

export const editObjectType = id => dispatch =>
  dispatch({
    type: EDIT_OBJECT_TYPE,
    payload: ws.get('/object_types/' + id)
  });

export const saveObjectType = values => dispatch =>
  dispatch({
    type: SAVE_OBJECT_TYPE,
    payload: ws.post('/object_types/', {
      ...values,
      category: values.category === '' ? null : values.category
    })
  });

export const findObjectTypes = () => dispatch =>
  dispatch({
    type: FIND_OBJECT_TYPES,
    payload: ws.get('/object_types/')
  });
