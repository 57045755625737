import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  currentPassword: '',
  newPassword: '',
  validationMessages: {}
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.SESSION_LOGIN_FULFILLED: {
      return {
        ...initialState
      };
    }

    case actionTypes.USER_PROFILE_VALUE_UPDATE: {
      return {
        ...state,
        ...action.payload
      };
    }

    case actionTypes.SAVE_USER_PROFILE_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        message: action.payload.data.message,
        validationMessages: action.payload.data.validationMessages
      };
    }

    default: {
      return state;
    }
  }
};

export default userProfileReducer;
