import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  values: {
    category: '',
    name: ''
  },
  validationMessages: {}
};

const objectTypeCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_OBJECT_TYPE_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.EDIT_OBJECT_TYPE_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result
      };
    }

    case actionTypes.CREATE_OBJECT_TYPE_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_OBJECT_TYPE_VALUE_UPDATE: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default objectTypeCreateReducer;
