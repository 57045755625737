import ws from '../utils/ws';

export const findTestingAnalysisOverview = subFilterIndex => dispatch => {
  dispatch({
    type: FIND_TESTING_ANALYSIS_OVERVIEW,
    payload: ws.get('/testing/analysis/overview/sub_filter/' + subFilterIndex)
  });
};

export const findTestingAnalysisTimeSeriesSessions = (
  periodStartTime,
  periodFinishTime,
  subFilterIndex
) => dispatch =>
  dispatch({
    type: FIND_TESTING_ANALYSIS_TIME_SERIES_SESSIONS,
    payload: ws.get(
      `/testing/analysis/overview/sub_filter/${subFilterIndex}/time_series/sessions/?periodStartTime=${periodStartTime}&periodFinishTime=${periodFinishTime}`
    )
  });

export const setTestingAnalysiTimeSeriesSetting = setting => dispatch =>
  dispatch({
    type: SET_TESTING_ANALYSIS_TIME_SERIES_SETTING,
    payload: setting
  });

export const testingAnalysisSetSessionComparisonFilters = sessionIds => dispatch =>
  dispatch({
    type: TESTING_ANALYSIS_SET_SESSION_COMPARISON_FILTERS,
    payload: ws.post(
      `/testing/analysis/overview/set_session_comparison_filters`,
      sessionIds
    )
  });

export const deleteMultipleSessions = sessionIds => dispatch =>
  dispatch({
    type: DELETE_MULTIPLE_SESSIONS,
    payload: ws.post(`/sessions/delete_multiple`, sessionIds)
  });

export const findTestingAnalysis = () => dispatch =>
  dispatch({
    type: FIND_TESTING_ANALYSIS,
    payload: ws.get('/testing/analysis')
  });

export const saveTestingAnalysisTimeSeriesSettings = settings => dispatch =>
  dispatch({
    type: SAVE_TESTING_ANALYSIS_TIME_SERIES_SETTINGS,
    payload: ws.post(
      `/testing/analysis/overview/time_series/settings`,
      settings
    )
  });

export const saveTestingAnalysisSubFilter = subFilter => dispatch =>
  dispatch({
    type: SAVE_TESTING_ANALYSIS_SUB_FILTER_OVERVIEW,
    payload: ws.post(`/testing/analysis/overview/sub_filter/`, subFilter)
  });

export const transposeTestingAnalysisSubFilters = subFilter => dispatch =>
  dispatch({
    type: TRANSPOSE_TESTING_ANALYSIS_SUB_FILTERS,
    payload: ws.post(
      `/testing/analysis/overview/sub_filters/transpose`,
      subFilter
    )
  });

export const setTestingAnalysisBiasType = analysisType => dispatch =>
  dispatch({
    type: SET_TESTING_ANALYSIS_BIAS_TYPE,
    payload: analysisType
  });

export const addTestingAnalysisFilter = () => dispatch =>
  dispatch({
    type: ADD_TESTING_ANALYSIS_FILTER
  });

export const editTestingAnalysisFilter = subFilterIndex => dispatch =>
  dispatch({
    type: EDIT_TESTING_ANALYSIS_FILTER,
    payload: { subFilterIndex: subFilterIndex }
  });

export const removeTestingAnalysisFilter = subFilterIndex => dispatch => {
  dispatch({
    type: REMOVE_TESTING_ANALYSIS_FILTER,
    payload: ws.post(
      `/testing/analysis/overview/sub_filter/${subFilterIndex}/delete`
    )
  });
};

export const setTestingAnalysisFilterPosition = (
  subFilterIndex,
  newIndex
) => dispatch => {
  dispatch({
    type: SET_TESTING_ANALYSIS_FILTER_POSITION,
    payload: ws.post(
      `/testing/analysis/overview/sub_filter/${subFilterIndex}/move_to/${newIndex}`
    )
  });
};

/* Action Constants */

export const FIND_TESTING_ANALYSIS_OVERVIEW = 'FIND_TESTING_ANALYSIS_OVERVIEW';
export const FIND_TESTING_ANALYSIS_OVERVIEW_PENDING =
  'FIND_TESTING_ANALYSIS_OVERVIEW_PENDING';
export const FIND_TESTING_ANALYSIS_OVERVIEW_FULFILLED =
  'FIND_TESTING_ANALYSIS_OVERVIEW_FULFILLED';

export const SET_TESTING_ANALYSIS_TIME_SERIES_SETTING =
  'SET_TESTING_ANALYSIS_TIME_SERIES_SETTING';

export const FIND_TESTING_ANALYSIS_TIME_SERIES_SESSIONS =
  'FIND_TESTING_ANALYSIS_TIME_SERIES_SESSIONS';
export const FIND_TESTING_ANALYSIS_TIME_SERIES_SESSIONS_PENDING =
  'FIND_TESTING_ANALYSIS_TIME_SERIES_SESSIONS_PENDING';
export const FIND_TESTING_ANALYSIS_TIME_SERIES_SESSIONS_FULFILLED =
  'FIND_TESTING_ANALYSIS_TIME_SERIES_SESSIONS_FULFILLED';

export const TESTING_ANALYSIS_SETUP_PAGINATED_LIST =
  'TESTING_ANALYSIS_SETUP_PAGINATED_LIST';
export const TESTING_ANALYSIS_SETUP_PAGINATED_LIST_PENDING =
  'TESTING_ANALYSIS_SETUP_PAGINATED_LIST_PENDING';
export const TESTING_ANALYSIS_SETUP_PAGINATED_LIST_FULFILLED =
  'TESTING_ANALYSIS_SETUP_PAGINATED_LIST_FULFILLED';

export const TESTING_ANALYSIS_SET_SESSION_COMPARISON_FILTERS =
  'TESTING_ANALYSIS_SET_SESSION_COMPARISON_FILTERS';
export const TESTING_ANALYSIS_SET_SESSION_COMPARISON_FILTERS_PENDING =
  'TESTING_ANALYSIS_SET_SESSION_COMPARISON_FILTERS_PENDING';
export const TESTING_ANALYSIS_SET_SESSION_COMPARISON_FILTERS_FULFILLED =
  'TESTING_ANALYSIS_SET_SESSION_COMPARISON_FILTERS_FULFILLED';

export const DELETE_MULTIPLE_SESSIONS = 'DELETE_MULTIPLE_SESSIONS';
export const DELETE_MULTIPLE_SESSIONS_PENDING =
  'DELETE_MULTIPLE_SESSIONS_PENDING';
export const DELETE_MULTIPLE_SESSIONS_FULFILLED =
  'DELETE_MULTIPLE_SESSIONS_FULFILLED';

export const FIND_TESTING_ANALYSIS = 'FIND_TESTING_ANALYSIS';
export const FIND_TESTING_ANALYSIS_PENDING = 'FIND_TESTING_ANALYSIS_PENDING';
export const FIND_TESTING_ANALYSIS_FULFILLED =
  'FIND_TESTING_ANALYSIS_FULFILLED';

export const SAVE_TESTING_ANALYSIS_TIME_SERIES_SETTINGS =
  'SAVE_TESTING_ANALYSIS_TIME_SERIES_SETTINGS';
export const SAVE_TESTING_ANALYSIS_TIME_SERIES_SETTINGS_PENDING =
  'SAVE_TESTING_ANALYSIS_TIME_SERIES_SETTINGS_PENDING';
export const SAVE_TESTING_ANALYSIS_TIME_SERIES_SETTINGS_FULFILLED =
  'SAVE_TESTING_ANALYSIS_TIME_SERIES_SETTINGS_FULFILLED';

export const SAVE_TESTING_ANALYSIS_SUB_FILTER_OVERVIEW =
  'SAVE_TESTING_ANALYSIS_SUB_FILTER_OVERVIEW';
export const SAVE_TESTING_ANALYSIS_SUB_FILTER_OVERVIEW_PENDING =
  'SAVE_TESTING_ANALYSIS_SUB_FILTER_OVERVIEW_PENDING';
export const SAVE_TESTING_ANALYSIS_SUB_FILTER_OVERVIEW_FULFILLED =
  'SAVE_TESTING_ANALYSIS_SUB_FILTER_OVERVIEW_FULFILLED';

export const TRANSPOSE_TESTING_ANALYSIS_SUB_FILTERS =
  'TRANSPOSE_TESTING_ANALYSIS_SUB_FILTERS';
export const TRANSPOSE_TESTING_ANALYSIS_SUB_FILTERS_PENDING =
  'TRANSPOSE_TESTING_ANALYSIS_SUB_FILTERS_PENDING';
export const TRANSPOSE_TESTING_ANALYSIS_SUB_FILTERS_FULFILLED =
  'TRANSPOSE_TESTING_ANALYSIS_SUB_FILTERS_FULFILLED';

export const ADD_TESTING_ANALYSIS_FILTER = 'ADD_TESTING_ANALYSIS_FILTER';
export const EDIT_TESTING_ANALYSIS_FILTER = 'EDIT_TESTING_ANALYSIS_FILTER';

export const REMOVE_TESTING_ANALYSIS_FILTER = 'REMOVE_TESTING_ANALYSIS_FILTER';
export const REMOVE_TESTING_ANALYSIS_FILTER_PENDING =
  'REMOVE_TESTING_ANALYSIS_FILTER_PENDING';
export const REMOVE_TESTING_ANALYSIS_FILTER_FULFILLED =
  'REMOVE_TESTING_ANALYSIS_FILTER_FULFILLED';

export const SET_TESTING_ANALYSIS_FILTER_POSITION =
  'SET_TESTING_ANALYSIS_FILTER_POSITION';
export const SET_TESTING_ANALYSIS_FILTER_POSITION_PENDING =
  'SET_TESTING_ANALYSIS_FILTER_POSITION_PENDING';
export const SET_TESTING_ANALYSIS_FILTER_POSITION_FULFILLED =
  'SET_TESTING_ANALYSIS_FILTER_POSITION_FULFILLED';

export const SET_TESTING_ANALYSIS_BIAS_TYPE = 'SET_TESTING_ANALYSIS_BIAS_TYPE';
