import * as actionTypes from 'actions';

const initialState = {
  objectTypes: [],
  scenarios: [],
  customerEvents: [],
  sessions: [],
  trainingSessions: [],
  trainingSessionsLoading: false,
  participants: [],
  cells: [],
  eventDataSets: [],
  eventDataSetsLoading: false
};

const eventFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data.result
      };
    }

    case actionTypes.FIND_SETUP_SCENARIOS_FULFILLED: {
      return {
        ...state,
        scenarios: action.payload.data.result
      };
    }

    case actionTypes.FIND_PARTICIPANTS_FULFILLED: {
      return {
        ...state,
        participants: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_DATA_SETS_PENDING: {
      return {
        ...state,
        eventDataSets: initialState.eventDataSets,
        eventDataSetsLoading: true
      };
    }

    case actionTypes.FIND_EVENT_DATA_SETS_FULFILLED: {
      return {
        ...state,
        eventDataSets: action.payload.data.result,
        eventDataSetsLoading: false
      };
    }

    case actionTypes.FIND_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENTS_FULFILLED: {
      return {
        ...state,
        customerEvents: action.payload.data.result
      };
    }

    case actionTypes.FIND_SELECTION_TEST_SESSIONS_PENDING: {
      return {
        ...state,
        sessions: initialState.sessions,
        sessionsLoading: true
      };
    }

    case actionTypes.FIND_SELECTION_TEST_SESSIONS_FULFILLED: {
      return {
        ...state,
        sessions: action.payload.data.result,
        sessionsLoading: false
      };
    }

    case actionTypes.FIND_SELECTION_TRAINING_SESSIONS_PENDING: {
      return {
        ...state,
        trainingSessions: initialState.trainingSessions,
        trainingSessionsLoading: true
      };
    }

    case actionTypes.FIND_SELECTION_TRAINING_SESSIONS_FULFILLED: {
      return {
        ...state,
        trainingSessions: action.payload.data.result,
        trainingSessionsLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default eventFilterReducer;
