import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  colors,
  Grid
} from '@material-ui/core';
import ws from '../../utils/ws';
import { useDispatch, useSelector } from 'react-redux';
import { clearEventImageFiles } from 'actions';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    height: '90%',
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: theme.spacing(2)
  },
  image: {
    // borderStyle: 'solid',
    // borderWidth: 10,
    // borderColor: '#fff'
    // boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
    // borderRadius: theme.spacing(1)
  }
}));

const ImageModal = props => {
  const { imageFiles, loading } = useSelector(state => state.eventImageModal);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearEventImageFiles());
  };

  return (
    <Modal onClose={handleClose} open={imageFiles.length > 0}>
      <Card className={classes.root}>
        <CardContent
          style={{height: '90%'}}
          className={classes.content}
        >
          <Grid 
            style={{height: '100%'}}
            container spacing={2}
          >
            {imageFiles.map((img, i) => (
              <Grid 
                style={{height: '100%'}}
                key={i} 
                item 
                xs={6} 
              >
                <img
                  style={{height: '100%'}}
                  src={ws.url(
                    `/aws/s3/object?bucket=${img.bucket}&objectKey=${img.key}`
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ImageModal.defaultProps = {
  open: false
};

export default ImageModal;
