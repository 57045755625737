import * as actionTypes from 'actions';

const initialState = {
  open: false,
  pageNumber: 1,
  pageSize: 6,
  rowCount: 0,
  cell: null,
  validationStatus: null,
  processing: false,
  processingEventId: null,
  loading: false,
  entityType: null,
  entityId: null,
  events: [],
  validationSummary: {},
  objectTypes: [],
  dragEnterSubectArea: null,
  dragObjectType: null,
  addSubjectAreaContentOpen: false,
  newSubjectAreaContent: {
    subjectArea: 'RIGHT_SIDE'
  },
  createObjectTypeOpen: false,
  newObjectType: {},
  currentEvent: null,
  displayVideo: false,
  imageFiles: [],
  selectedEvent: null,
  eventCells: [],
  confirmMarkNoObjectVerifiedOpen: false,
  markNoObjectDetectedVerifiedProcessing: false
};

const validateEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VALIDATE_EVENTS: {
      return {
        ...state,
        open: true,
        displayVideo: initialState.displayVideo,
        ...action.payload
      };
    }

    case actionTypes.CLOSE_VALIDATE_EVENTS: {
      return {
        ...initialState
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_SELECTED_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_SELECTED_CELL: {
      return {
        ...state,
        cell: action.payload
      };
    }

    case actionTypes.SET_CONFIRM_MARK_NO_OBJECT_VERIFIED_OPEN: {
      return {
        ...state,
        confirmMarkNoObjectVerifiedOpen: action.payload
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_SELECTED_VALIDATION_STATUS: {
      return {
        ...state,
        validationStatus: action.payload
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_DRAG_ENTER_SUBJECT_AREA: {
      return {
        ...state,
        dragEnterSubectArea: action.payload
      };
    }
    case actionTypes.VALIDATE_CUSTOMER_EVENT_SAC_VALUE_UPDATE: {
      return {
        ...state,
        subjectAreaContent: {
          ...state.subjectAreaContent,
          [action.payload.field]: action.payload.value
        }
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_DRAG_OBJECT_TYPE: {
      return {
        ...state,
        dragObjectType: action.payload
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_ADD_SUBJECT_AREA_CONTENT_OPEN: {
      return {
        ...state,
        addSubjectAreaContentOpen: action.payload,
        newSubjectAreaContent: initialState.newSubjectAreaContent
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_CREATE_OBJECT_TYPE_OPEN: {
      return {
        ...state,
        createObjectTypeOpen: action.payload,
        newObjectType: initialState.newObjectType
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_SUBJECT_AREA_CONTENT_VALUE: {
      return {
        ...state,
        newSubjectAreaContent: {
          ...state.newSubjectAreaContent,
          [action.payload.field]: action.payload.value
        }
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload
      };
    }

    case actionTypes.VALIDATE_EVENTS_PAGINATED_LIST_FULFILLED: {
      const { items, pageNumber, rowCount } = action.payload.data.result;

      return {
        ...state,
        events: items,
        selectedEvent: items.length > 0 ? items[0] : initialState.selectedEvent,
        pageNumber: pageNumber,
        rowCount: rowCount
      };
    }

    case actionTypes.VALIDATE_EVENTS_CELL_LIST_FULFILLED: {
      const cells = action.payload.data.result;

      return {
        ...state,
        eventCells: cells
      };
    }

    case actionTypes.VALIDATE_EVENTS_PAGINATED_LIST_PREVIOUS_PAGE_FULFILLED: {
      const { items, pageNumber, rowCount } = action.payload.data.result;

      return {
        ...state,
        events: items,
        selectedEvent:
          items.length > 0
            ? items[items.length - 1]
            : initialState.selectedEvent,
        pageNumber: pageNumber,
        rowCount: rowCount
      };
    }

    case actionTypes.VALIDATE_EVENTS_STATUS_SUMMARY_FULFILLED: {
      return {
        ...state,
        validationSummary: action.payload.data.result
      };
    }

    case actionTypes.MARK_NO_OBJECT_DETECTED_VERIFIED_PENDING: {
      return {
        ...state,
        confirmMarkNoObjectVerifiedOpen: false,
        markNoObjectDetectedVerifiedProcessing: true
        // validationSummary: action.payload.data.result
      };
    }

    case actionTypes.MARK_NO_OBJECT_DETECTED_VERIFIED_FULFILLED: {
      return {
        ...state,
        markNoObjectDetectedVerifiedProcessing: false,
        validationSummary: action.payload.data.result
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_PROCESSING_EVENT_ID: {
      return {
        ...state,
        processingEventId: action.payload
      };
    }

    case actionTypes.VALIDATE_EVENTS_UPDATE_EVENT_VALIDATION_STATUS_FULFILLED: {
      const event = action.payload.data.result;
      const index = state.events.map(e => e.id).indexOf(event.id);

      let result;
      if (index > -1) {
        const events = state.events.slice();
        events[index] = event;

        result = {
          ...state,
          events: events,
          selectedEvent: event,
          addSubjectAreaContentOpen: false
        };
      } else {
        result = {
          ...state,
          displayVideo: initialState.displayVideo
        };
      }

      return result;
    }

    case actionTypes.FIND_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data.result
      };
    }

    case actionTypes.SET_VALIDATE_EVENTS_DISPLAY_VIDEO: {
      return {
        ...state,
        displayVideo: action.payload
      };
    }

    case actionTypes.SAVE_OBJECT_TYPE_FULFILLED: {
      const objectTypes = state.objectTypes.slice();

      let result;
      if (action.payload.data.success) {
        objectTypes.push(action.payload.data.result);
        result = {
          ...state,
          objectTypes,
          newSubjectAreaContent: {
            ...state.newSubjectAreaContent,
            objectType: action.payload.data.result
          }
        };
      } else {
        result = state;
      }

      return result;
    }

    case actionTypes.UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER_FULFILLED:
    case actionTypes.UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE_FULFILLED:
    case actionTypes.UPDATE_EVENT_ANNOTATED_CELL_WALK_SIDE_FULFILLED:
    case actionTypes.SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED:
    case actionTypes.DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED:
    case actionTypes.TOGGLE_EVENT_SPECIAL_INTEREST_FULFILLED: {
      const event = action.payload.data.result;
      const index = state.events.map(e => e.id).indexOf(event.id);

      let result;
      if (index > -1) {
        const events = state.events.slice();
        events[index] = event;

        result = {
          ...state,
          events: events,
          selectedEvent: event,
          addSubjectAreaContentOpen: false
        };
      } else {
        result = state;
      }

      return result;
    }

    case actionTypes.FIND_EVENT_VALIDATION_IMAGE_FILES_PENDING: {
      return {
        ...state,
        imageFiles: initialState.imageFiles,
        loading: true
      };
    }

    case actionTypes.FIND_EVENT_VALIDATION_IMAGE_FILES_FULFILLED: {
      return {
        ...state,
        imageFiles: action.payload.data.result,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default validateEventsReducer;
