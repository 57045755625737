import * as actionTypes from 'actions';

const initialState = {
  pageNumber: 1,
  rowCount: 0,
  loading: false,
  items: [],
  pageSize: 250,
  filterItemCount: null,
  filter: {
    search: ''
  },
  walkCount: null,
  modelRunRequestOverview: {
    walkCounts: []
  },
  modelRunRequestOverviewLoading: false,
  detailsResponse: null,
  detailsResponseLoading: false,
  batchInserts: [],
  batchInsertsLoading: false
};

const PAGE = 'EVENT_MODEL_RUN_REQUEST_LIST';

const eventModelRunRequestListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EVENT_MODEL_RUN_REQUEST_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.EVENT_MODEL_RUN_REQUEST_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        filter: {
          ...initialState.filter,
          ...action.payload.data.result.filter
        },
        loading: false
      };
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          pageSize: action.payload.pageSize
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...initialState.filter,
            search: state.filter.search
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === PAGE
      ) {
        result = {
          ...state,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.filter,
            ...action.payload.data.result.filter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.EVENT_MODEL_RUN_REQUEST_EVENT_COUNT_PENDING: {
      return {
        ...state,
        walkCount: null
      };
    }

    case actionTypes.EVENT_MODEL_RUN_REQUEST_EVENT_COUNT_FULFILLED: {
      return {
        ...state,
        walkCount: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_MODEL_CONFIGURATION_CREATIONS_PENDING: {
      return {
        ...state,
        batchInserts: initialState.batchInserts,
        batchInsertsLoading: true
      };
    }

    case actionTypes.FIND_EVENT_MODEL_CONFIGURATION_CREATIONS_FULFILLED: {
      return {
        ...state,
        batchInserts: action.payload.data.result,
        batchInsertsLoading: false
      };
    }

    case actionTypes.EVENT_MODEL_RUN_REQUEST_OVERVIEW_PENDING: {
      return {
        ...state,
        modelRunRequestOverviewLoading: true
      };
    }

    case actionTypes.EVENT_MODEL_RUN_REQUEST_OVERVIEW_FULFILLED: {
      return {
        ...state,
        modelRunRequestOverview: action.payload.data.result,
        modelRunRequestOverviewLoading: false
      };
    }

    case actionTypes.EVENT_MODEL_RUN_REQUEST_LATEST_RESPONSE_PENDING: {
      return {
        ...state,
        detailsResponseLoading: true,
        detailsResponse: null
      };
    }

    case actionTypes.EVENT_MODEL_RUN_REQUEST_LATEST_RESPONSE_FULFILLED: {
      return {
        ...state,
        detailsResponseLoading: false,
        detailsResponse: action.payload.data.result
      };
    }

    case actionTypes.REQUEUE_EVENT_MODEL_RUN_REQUEST_FULFILLED: {
      const items = state.items.slice();

      if (action.payload.data.success) {
        const updatedRunRequest = action.payload.data.result;
        const index = items.map(req => req.id).indexOf(updatedRunRequest.id);
        items[index] = updatedRunRequest;
      }

      return {
        ...state,
        items
      };
    }

    case actionTypes.DELETE_EVENT_MODEL_RUN_REQUEST_FULFILLED: {
      const items = state.items.slice();
      let rowCount = state.rowCount;

      if (action.payload.data.success) {
        const updatedRunRequest = action.payload.data.result;
        const index = items.map(req => req.id).indexOf(updatedRunRequest.id);
        items.splice(index, 1);
        rowCount--;
      }

      return {
        ...state,
        items,
        rowCount
      };
    }

    default: {
      return state;
    }
  }
};

export default eventModelRunRequestListReducer;
