import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Button,
  CardHeader,
  Divider,
  colors,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { CHART_COLORS } from 'common/constants';
import { NumberFormat } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  tabContent: {
    margin: theme.spacing(2)
  },
  chartContainer: {
    padding: theme.spacing(2)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableContainer: {
    maxHeight: 432,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.grey[300]
  }
}));

const PrecisionRecallChart = props => {
  const {
    open,
    onClose,
    loading,
    precisionRecallValues,
    className,
    ...rest
  } = props;

  const classes = useStyles();

  const [tab, setTab] = useState('chart');

  const tabs = [
    { value: 'chart', label: 'Chart' },
    { value: 'table', label: 'Table' }
  ];

  const handleClose = () => {
    onClose();
  };

  const handleTabsChange = (event, value) => {
    event.persist && event.persist();
    setTab(value);
  };

  if (!open) {
    return null;
  }

  const chartSeries = [];

  const series = {
    data: [],
    turboThreshold: precisionRecallValues.length,
    color: CHART_COLORS[0]
  };

  const options = {
    chart: {
      type: 'area',
      zoomType: 'x'
    },
    tooltip: {
      crosshairs: {
        color: colors.grey[400],
        dashStyle: 'solid'
      },
      formatter: function() {
        return (
          '<b>Threshold</b>: ' +
          Highcharts.numberFormat(this.total, 6) +
          ' %<br />' +
          '<b>Recall</b>: ' +
          Highcharts.numberFormat(this.x, 6) +
          ' %<br />' +
          '<b>Precision</b>: ' +
          Highcharts.numberFormat(this.y, 6) +
          ' %'
        );
      }
    },
    title: {
      text: null
    },
    series: [],
    xAxis: {
      max: 100,
      title: {
        text: 'Recall'
      }
    },
    yAxis: {
      max: 100,
      title: {
        text: 'Precision'
      }
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      area: {
        fillOpacity: 0.5
      },
      series: {
        marker: {
          enabled: false
        }
      }
    }
  };

  for (let i = 0; i < precisionRecallValues.length; i++) {
    const precisionRecallValue = precisionRecallValues[i];
    series.data.push({
      total: precisionRecallValue.threshold * 100,
      x: precisionRecallValue.recall * 100.0,
      y: precisionRecallValue.precision * 100.0
    });
  }

  chartSeries.push(series);

  options.series = chartSeries;

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Precision Recall Curve" />
        <Divider />
        <CardContent className={classes.content}>
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={tab}
                variant="scrollable">
                {tabs.map(tab => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              <Divider className={classes.divider} />

              <div className={classes.tabContent}>
                {tab === 'chart' && (
                  <div className={classes.chartContainer}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                  </div>
                )}
                {tab === 'table' && (
                  <>
                    <TableContainer className={classes.tableContainer}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Threshold</TableCell>
                            <TableCell>Precision</TableCell>
                            <TableCell>Recall</TableCell>
                            <TableCell>AUC</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {precisionRecallValues.map(
                            (precisionRecallValue, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <NumberFormat
                                    fixedDecimalScale={true}
                                    precision={3}
                                    value={precisionRecallValue.threshold.toFixed(
                                      8
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <NumberFormat
                                    fixedDecimalScale={true}
                                    precision={8}
                                    value={precisionRecallValue.precision}
                                  />
                                </TableCell>
                                <TableCell>
                                  <NumberFormat
                                    fixedDecimalScale={true}
                                    precision={8}
                                    value={precisionRecallValue.recall}
                                  />
                                </TableCell>
                                <TableCell>
                                  <NumberFormat
                                    fixedDecimalScale={true}
                                    precision={8}
                                    value={precisionRecallValue.areaUnderCurve}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

PrecisionRecallChart.displayName = 'PrecisionRecallChart';

PrecisionRecallChart.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

PrecisionRecallChart.defaultProps = {
  open: false
};

export default PrecisionRecallChart;
