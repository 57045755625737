import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  TextField,
  Table,
  TableRow,
  TableCell
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {},
  checkListItemDetails: {
    marginBottom: theme.spacing(3)
  }
}));

const CompleteCheckListItem = props => {
  const { open, checkListItem, onCancel, onSave, onFieldChange } = props;

  const classes = useStyles();

  const handleClose = () => {
    onCancel();
  };

  const handleValueChange = (field, value) => {
    onFieldChange(field, value);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader title={`Complete Check List Item`} />
        <Divider />
        <CardContent className={classes.content}>
          <Card className={classes.checkListItemDetails}>
            <Table>
              <TableRow>
                <TableCell>Check</TableCell>
                <TableCell>{checkListItem.checkDescription}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Expected Value</TableCell>
                <TableCell>{checkListItem.expectedResult}</TableCell>
              </TableRow>
            </Table>
          </Card>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                label="Actual Result"
                value={
                  checkListItem.actualResult ? checkListItem.actualResult : ''
                }
                variant="outlined"
                onChange={e =>
                  handleValueChange('actualResult', e.target.value)
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button color="primary" onClick={onSave} variant="contained">
            Save
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CompleteCheckListItem.defaultProps = {
  open: false
};

export default CompleteCheckListItem;
