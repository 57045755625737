/* eslint-disable no-undef */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { TextField } from '@material-ui/core';
import { SUBJECT_AREA_GROUPS } from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 100
  }
}));

const SelectSubjectAreaGroup = props => {
  const { onChange, areaGroup, fullWidth, className, margin } = props;

  const classes = useStyles();

  return (
    <TextField
      className={clsx(classes.root, className)}
      margin={margin ? margin : 'dense'}
      fullWidth={fullWidth}
      label="Object Area"
      name="areaGroup"
      onChange={e => onChange(e.target.value ? e.target.value : undefined)}
      select
      SelectProps={{ native: true }}
      value={areaGroup !== null ? areaGroup : ''}
      variant="outlined">
      <option value={null}>All</option>
      {Object.keys(SUBJECT_AREA_GROUPS).map((group, i) => (
        <option key={group} value={group}>
          {SUBJECT_AREA_GROUPS[group]}
        </option>
      ))}
    </TextField>
  );
};

SelectSubjectAreaGroup.propTypes = {};

export default SelectSubjectAreaGroup;
