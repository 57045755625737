import ws from '../utils/ws';

/* Actions */

export const setSelectEventDataSetFiltersOpen = open => dispatch =>
  dispatch({
    type: SET_SELECT_EVENT_DATA_SET_FILTERS_OPEN,
    payload: open
  });

export const setSelectEventDataSetFiltersTab = tab => dispatch =>
  dispatch({
    type: SET_SELECT_EVENT_DATA_SET_FILTERS_TAB,
    payload: tab
  });

export const setSelectEventDataFiltersSearch = search => dispatch =>
  dispatch({
    type: SET_SELECT_EVENT_DATA_SET_FILTERS_SEARCH,
    payload: search
  });

export const setSelectEventDataSetFiltersFilterSelected = (
  filterIndex,
  selected
) => dispatch =>
  dispatch({
    type: SET_SELECT_EVENT_DATA_SET_FILTERS_FILTER_SELECTED,
    payload: { filterIndex, selected }
  });

export const setSelectEventDataSetFiltersSelectedDataSet = dataSet => dispatch =>
  dispatch({
    type: SET_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET,
    payload: ws.get(`/events/data_sets/${dataSet.id}`)
  });

export const clearSelectEventDataSetFiltersSelectedDataSet = () => dispatch =>
  dispatch({
    type: CLEAR_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET
  });

export const findSelectEventDataFiltersDataSets = () => dispatch =>
  dispatch({
    type: FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS,
    payload: ws.get(`/events/data_sets/`)
  });

/* Constants */

export const SET_SELECT_EVENT_DATA_SET_FILTERS_OPEN =
  'SET_SELECT_EVENT_DATA_SET_FILTERS_OPEN';

export const SET_SELECT_EVENT_DATA_SET_FILTERS_TAB =
  'SET_SELECT_EVENT_DATA_SET_FILTERS_TAB';

export const SET_SELECT_EVENT_DATA_SET_FILTERS_SEARCH =
  'SET_SELECT_EVENT_DATA_SET_FILTERS_SEARCH';

export const SET_SELECT_EVENT_DATA_SET_FILTERS_FILTER_SELECTED =
  'SET_SELECT_EVENT_DATA_SET_FILTERS_FILTER_SELECTED';

export const SET_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET =
  'SET_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET';
export const SET_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET_PENDING =
  'SET_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET_PENDING';
export const SET_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET_FULFILLED =
  'SET_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET_FULFILLED';

export const CLEAR_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET =
  'CLEAR_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET';

export const FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS =
  'FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS';
export const FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS_PENDING =
  'FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS_PENDING';
export const FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS_FULFILLED =
  'FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS_FULFILLED';
