import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { OBJECT_TYPE_CATEGORIES } from 'common/constants';
import {
  objectTypeValueUpdate,
  objectTypeValueReset,
  saveObjectType,
  findObjectTypes
} from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const CreateObjectType = props => {
  const { open, onClose, className, participantEntry, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { saved, values, validationMessages, loading } = useSelector(
    state => state.objectTypeCreate
  );

  const handleClose = () => {
    dispatch(objectTypeValueReset());
    onClose();
  };

  const handleFieldChange = event => {
    event && event.persist && event.persist();

    dispatch(
      objectTypeValueUpdate(values => ({
        ...values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      }))
    );
  };

  if (saved) {
    dispatch(findObjectTypes());
    dispatch(objectTypeValueReset());
    onClose();
  }

  const handleCreateObjectType = () => {
    dispatch(
      saveObjectType({
        ...values,
        participantEntryApplicable: participantEntry
      })
    );
  };

  if (!open || loading) {
    return null;
  }

  const create = true;

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography align="center" gutterBottom variant="h3">
            {create ? 'New' : 'Edit'} Object Type
          </Typography>

          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12} md={4}>
              <div className={classes.formGroup}>
                <TextField
                  required={true}
                  error={validationMessages.hasOwnProperty('category')}
                  helperText={validationMessages.category}
                  fullWidth
                  label="Category"
                  name="category"
                  onChange={event =>
                    handleFieldChange(event, 'category', event.target.value)
                  }
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={values.category}
                  variant="outlined">
                  <option key="NONE" value="" />
                  {Object.keys(OBJECT_TYPE_CATEGORIES).map(category => (
                    <option key={category} value={category}>
                      {OBJECT_TYPE_CATEGORIES[category].name}
                    </option>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <div className={classes.formGroup}>
                <TextField
                  required={true}
                  error={validationMessages.hasOwnProperty('name')}
                  helperText={validationMessages.name}
                  fullWidth
                  label="Name (e.g. Cell Phone, Shotgun)"
                  name="name"
                  onChange={event =>
                    handleFieldChange(event, 'name', event.target.value)
                  }
                  value={values.name}
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
          <Button
            className={classes.saveButton}
            onClick={handleCreateObjectType}
            variant="contained">
            Save
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

// CreateObjectType.propTypes = {
//   className: PropTypes.string,
//   customer: PropTypes.any,
//   onClose: PropTypes.func,
//   open: PropTypes.bool,
//   createdSession: PropTypes.object
// };

CreateObjectType.defaultProps = {
  open: false
};

export default CreateObjectType;
