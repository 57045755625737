import * as actionTypes from 'actions';

const initialState = {
  imageFiles: [],
  loading: false
};

const eventImageModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_EVENT_IMAGE_FILES_PENDING: {
      return {
        ...state,
        imageFiles: initialState.imageFiles,
        loading: true
      };
    }

    case actionTypes.FIND_EVENT_IMAGE_FILES_FULFILLED: {
      return {
        ...state,
        imageFiles: action.payload.data.result,
        loading: false
      };
    }

    case actionTypes.CLEAR_EVENT_IMAGE_FILES: {
      return {
        ...state,
        imageFiles: initialState.imageFiles,
        loading: initialState.loading
      };
    }

    default: {
      return state;
    }
  }
};

export default eventImageModalReducer;
