import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  colors,
  Button
} from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/AddCircle';
import CollapseIcon from '@material-ui/icons/RemoveCircle';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import { CHART_COLORS, CELL_BUILD_COMPONENT_STATUSES } from 'common/constants';
import {
  CellBuildProductComponentStatus,
  CellBuildProductComponentStatusCount
} from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  toggleRow: {
    color: colors.blue[600],
    width: 22,
    height: 22,
    float: 'right',
    cursor: 'pointer'
  },
  cardContent: {
    padding: theme.spacing(2),
    paddingBottom: '0!important'
  },
  infoTable: {
    marginBottom: theme.spacing(2)
  },
  labelCell: {
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    verticalAlign: 'top'
  },
  valueCell: {
    whiteSpace: 'break-spaces'
  },
  searchResultMatch: {
    background: colors.yellow[100]
  },
  actions: {
    '& button': {
      marginBottom: -16,
      marginLeft: theme.spacing(1)
    },
    marginLeft: theme.spacing(1)
  },
  workflowButton: {
    padding: '1px 8px',
    fontSize: '0.8125rem',
    marginBottom: theme.spacing(1)
  },
  forwardWorkflowArrow: {
    width: 14,
    marginLeft: theme.spacing(1),
    color: '#757575'
  },
  backWorkflowArrow: {
    width: 14,
    marginRight: theme.spacing(1),
    color: '#757575'
  }
}));

const ProductBuildComponent = props => {
  const {
    cellBuild,
    rootBuildComponent,
    buildComponent,
    expandedComponentIds,
    handleToggle,
    searchResultBuildComponentIds,
    onEdit,
    onCreateChild,
    onSetBuildComponentStatus
  } = props;

  const expanded =
    rootBuildComponent ||
    expandedComponentIds.indexOf(buildComponent.guid) > -1;
  const searchResultMatch =
    searchResultBuildComponentIds.indexOf(buildComponent.guid) > -1;

  const classes = useStyles();

  const ComponentRow = props => {
    const { label, value } = props;

    return (
      <TableRow style={{ display: value ? 'table-row' : 'none' }}>
        <TableCell className={classes.labelCell}>
          <Typography>{label}</Typography>
        </TableCell>
        <TableCell className={classes.valueCell}>
          <Typography>{value}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Card
      className={classes.root}
      style={{
        borderLeft: `4px solid ${
          CHART_COLORS[buildComponent.hierarchyLevel - 1]
        }`
      }}>
      <CardHeader
        className={searchResultMatch ? classes.searchResultMatch : ''}
        title={
          <>
            {!rootBuildComponent && buildComponent.childComponents && (
              <>
                {expanded ? (
                  <CollapseIcon
                    className={classes.toggleRow}
                    onClick={() => handleToggle(buildComponent)}
                  />
                ) : (
                  <ExpandIcon
                    className={classes.toggleRow}
                    onClick={() => handleToggle(buildComponent)}
                  />
                )}
              </>
            )}
            {buildComponent.name}
          </>
        }
        action={
          <div className={classes.actions}>
            {onEdit && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => onEdit(buildComponent)}>
                Edit
              </Button>
            )}
            {onCreateChild && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => onCreateChild(buildComponent)}>
                Add Sub Component
              </Button>
            )}
          </div>
        }
      />
      <Divider />
      {(expanded || !buildComponent.childComponents) && (
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            {cellBuild && (
              <Grid item xs={12} md={6}>
                <Card className={classes.infoTable}>
                  <Table size="small">
                    <TableBody>
                      {!rootBuildComponent && (
                        <ComponentRow
                          label="Workflow"
                          value={
                            <Grid container spacing={1}>
                              <>
                                {buildComponent.previousCellBuildStatus && (
                                  <Grid item xs={12} md={6}>
                                    <Button
                                      className={classes.workflowButton}
                                      fullWidth
                                      color="primary"
                                      variant="outlined"
                                      size="small"
                                      onClick={() =>
                                        onSetBuildComponentStatus(
                                          buildComponent,
                                          buildComponent.previousCellBuildStatus
                                        )
                                      }>
                                      <BackIcon
                                        className={classes.backWorkflowArrow}
                                      />
                                      {
                                        CELL_BUILD_COMPONENT_STATUSES[
                                          buildComponent.previousCellBuildStatus
                                        ]
                                      }
                                    </Button>
                                  </Grid>
                                )}
                                {buildComponent.nextCellBuildStatus && (
                                  <Grid item xs={12} md={6}>
                                    <Button
                                      className={classes.workflowButton}
                                      fullWidth
                                      color="primary"
                                      variant="outlined"
                                      size="small"
                                      onClick={() =>
                                        onSetBuildComponentStatus(
                                          buildComponent,
                                          buildComponent.nextCellBuildStatus
                                        )
                                      }>
                                      {
                                        CELL_BUILD_COMPONENT_STATUSES[
                                          buildComponent.nextCellBuildStatus
                                        ]
                                      }
                                      <ForwardIcon
                                        className={classes.forwardWorkflowArrow}
                                      />
                                    </Button>
                                  </Grid>
                                )}
                              </>
                            </Grid>
                          }
                        />
                      )}
                      {!rootBuildComponent && (
                        <ComponentRow
                          label="Status"
                          value={
                            <CellBuildProductComponentStatus
                              buildComponent={buildComponent}
                            />
                          }
                        />
                      )}
                      {buildComponent.childCount > 0 && (
                        <ComponentRow
                          label="Sub Components"
                          value={
                            <CellBuildProductComponentStatusCount
                              buildComponent={buildComponent}
                            />
                          }
                        />
                      )}
                      {/* {(!rootBuildComponent && buildComponent.childCount) > 0 &&
                        <ComponentRow label="Sub Components" value={buildComponent.childCount} />
                      } */}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            )}
            <Grid item xs={12} md={cellBuild ? 6 : 12}>
              <Card className={classes.infoTable}>
                <Table size="small">
                  <TableBody>
                    <ComponentRow
                      label="Quantity"
                      value={buildComponent.quantity}
                    />
                    {!cellBuild && (
                      <ComponentRow
                        label="Sub Components"
                        value={buildComponent.childCount}
                      />
                    )}
                    <ComponentRow label="Notes" value={buildComponent.notes} />
                    <ComponentRow
                      label="Manufacturer"
                      value={buildComponent.manufacturer}
                    />
                    <ComponentRow
                      label="Manufacturer Part No."
                      value={buildComponent.manufacturerPartNumber}
                    />
                    <ComponentRow
                      label="Distributor"
                      value={buildComponent.distributor}
                    />
                    <ComponentRow
                      label="Distributor Part No."
                      value={buildComponent.distributorPartNumber}
                    />
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              {/* <Button
                variant="outlined"
                size="small"
                color="primary"
              >
                Start Installation
              </Button> */}
            </Grid>
            {buildComponent.childComponents && (
              <Grid item xs={12}>
                {buildComponent.childComponents &&
                  buildComponent.childComponents.map(childComponent => (
                    <ProductBuildComponent
                      cellBuild={cellBuild}
                      rootBuildComponent={false}
                      key={childComponent.guid}
                      onEdit={onEdit}
                      onCreateChild={onCreateChild}
                      onSetBuildComponentStatus={onSetBuildComponentStatus}
                      expandedComponentIds={expandedComponentIds}
                      handleToggle={handleToggle}
                      buildComponent={childComponent}
                      searchResultBuildComponentIds={
                        searchResultBuildComponentIds
                      }
                    />
                  ))}
              </Grid>
            )}
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

export default ProductBuildComponent;
