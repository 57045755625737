import ws from '../utils/ws';

/* Actions */

export const cellComponentPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: CELL_COMPONENTS_PAGINATED_LIST,
    payload: ws.get(`/cell_components/page/${pageNumber}/rows/${pageSize}`)
  });

export const cellComponentValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CELL_COMPONENT_VALUE_UPDATE,
    payload: values
  });

export const cellComponentValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CELL_COMPONENT_VALUE_RESET
  });

export const findLatestCellComponentVersions = () => dispatch =>
  dispatch({
    type: FIND_LATEST_CELL_COMPONENT_VERSIONS,
    payload: ws.get('/cell_components/latest_versions/')
  });

export const saveCellComponent = cellComponent => dispatch =>
  dispatch({
    type: SAVE_CELL_COMPONENT,
    payload: ws.post('/cell_components/', cellComponent)
  });

export const deleteCellComponent = cellComponent => dispatch =>
  dispatch({
    type: DELETE_CELL_COMPONENT,
    payload: ws.post(`/cell_components/${cellComponent.id}/delete`)
  });

export const editCellComponent = id => dispatch =>
  dispatch({
    type: EDIT_CELL_COMPONENT,
    payload: ws.get(`/cell_components/${id}`)
  });

export const findCellComponents = () => dispatch =>
  dispatch({
    type: FIND_CELL_COMPONENTS,
    payload: ws.get('/cell_components/')
  });

export const findCellComponent = id => dispatch =>
  dispatch({
    type: FIND_CELL_COMPONENT,
    payload: ws.get(`/cell_components/${id}`)
  });

export const clearCellComponentDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_CELL_COMPONENT_DELETE_MESSAGE
  });

/* Constants */

export const CELL_COMPONENTS_PAGINATED_LIST = 'CELL_COMPONENTS_PAGINATED_LIST';
export const CELL_COMPONENTS_PAGINATED_LIST_PENDING =
  'CELL_COMPONENTS_PAGINATED_LIST_PENDING';
export const CELL_COMPONENTS_PAGINATED_LIST_FULFILLED =
  'CELL_COMPONENTS_PAGINATED_LIST_FULFILLED';

export const CREATE_CELL_COMPONENT_VALUE_UPDATE =
  'CREATE_CELL_COMPONENTS_VALUE_UPDATE';
export const CREATE_CELL_COMPONENT_VALUE_RESET =
  'CREATE_CELL_COMPONENT_VALUE_RESET';

export const SAVE_CELL_COMPONENT = 'SAVE_CELL_COMPONENT';
export const SAVE_CELL_COMPONENT_FULFILLED = 'SAVE_CELL_COMPONENT_FULFILLED';

export const DELETE_CELL_COMPONENT = 'DELETE_CELL_COMPONENT';
export const DELETE_CELL_COMPONENT_PENDING = 'DELETE_CELL_COMPONENT_PENDING';
export const DELETE_CELL_COMPONENT_FULFILLED =
  'DELETE_CELL_COMPONENT_FULFILLED';

export const EDIT_CELL_COMPONENT = 'EDIT_CELL_COMPONENT';
export const EDIT_CELL_COMPONENT_FULFILLED = 'EDIT_CELL_COMPONENT_FULFILLED';

export const FIND_CELL_COMPONENTS = 'FIND_CELL_COMPONENTS';
export const FIND_CELL_COMPONENTS_PENDING = 'FIND_CELL_COMPONENTS_PENDING';
export const FIND_CELL_COMPONENTS_FULFILLED = 'FIND_CELL_COMPONENTS_FULFILLED';

export const FIND_CELL_COMPONENT = 'FIND_CELL_COMPONENT';
export const FIND_CELL_COMPONENT_PENDING = 'FIND_CELL_COMPONENT_PENDING';
export const FIND_CELL_COMPONENT_FULFILLED = 'FIND_CELL_COMPONENT_FULFILLED';

export const FIND_LATEST_CELL_COMPONENT_VERSIONS =
  'FIND_LATEST_CELL_COMPONENT_VERSIONS';
export const FIND_LATEST_CELL_COMPONENT_VERSIONS_PENDING =
  'FIND_LATEST_CELL_COMPONENT_VERSIONS_PENDING';
export const FIND_LATEST_CELL_COMPONENT_VERSIONS_FULFILLED =
  'FIND_LATEST_CELL_COMPONENT_VERSIONS_FULFILLED';

export const CLEAR_CELL_COMPONENT_DELETE_MESSAGE =
  'CLEAR_CELL_COMPONENT_DELETE_MESSAGE';
