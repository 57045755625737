import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import {
  Button,
  Collapse,
  Divider,
  Modal,
  Card,
  CardHeader,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Radio,
  CardActions,
  LinearProgress
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import {
  Label,
  SelectModelConfiguration,
  SessionSelect,
  ParticipantSelect
} from 'components';
import {
  CELL_MODELS,
  EVENT_VALIDATION_STATUSES,
  GENDERS,
  RADAR_FPS
} from 'common/constants';
import dateUtils from 'utils/dateUtils';
import {
  findObjectTypes,
  findSetupScenarios,
  findCustomerEvents,
  findSelectionTestSessions,
  findSelectionTrainingSessions,
  findParticipants,
  findCells,
  uploadEventDataSetFilterWalksFile,
  findEventDataSets
} from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CancelIcon from '@material-ui/icons/CancelOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1000,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  checkBoxFormGroup: {
    paddingTop: theme.spacing(2)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    justifyContent: 'flex-end'
  },
  subjectAreaSubHeading: {
    marginTop: theme.spacing(2)
  },
  select: {
    margin: 0
  },
  datePicker: {
    marginTop: -9
  },
  sectionDivider: {
    marginBottom: theme.spacing(2)
  },
  sectionLabel: {
    marginBottom: theme.spacing(3)
  },
  fileInput: {
    position: 'relative'
  },
  fileInputButton: {
    position: 'absolute',
    top: 12,
    right: 10
  },
  fileInputProgress: {
    marginLeft: theme.spacing(1)
  },
  activeDisabled: {
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'inherit'
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'inherit'
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#e53935'
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,0.15)'
    },
    '& .MuiOutlinedInput-root.Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e53935'
    }
  },
  textFieldButtonContainer: {
    position: 'relative'
  },
  textFieldButton: {
    position: 'absolute',
    top: 5,
    right: 4,
    padding: '2px 8px',
    minWidth: 10
  },
  textFieldCancelButton: {
    minWidth: 10,
    position: 'absolute',
    top: 6,
    right: 46,
    padding: 4,
    '& svg': {
      fontSize: 18
    }
  }
}));

const Filter = props => {
  const {
    open,
    processing,
    filter,
    onClose,
    onFilter,
    onClear,
    onLoad,
    onFieldChange,
    className,
    eventDataSet,
    filterIndex,
    hiddenFields,
    ...rest
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [expandGeneral, setExpandGeneral] = useState(true);
  const [expandValidation, setExpandValidation] = useState(false);
  const [expandSubjectContents, setExpandSubjectContents] = useState(false);
  const [selectSessionType, setSelectSessionType] = useState(null);
  const [selectParticipantOpen, setSelectParticipantOpen] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted && open && onLoad) {
      onLoad();
    }

    if (mounted && open) {
      dispatch(findObjectTypes());
      dispatch(findSetupScenarios('TRAINING'));
      dispatch(findCustomerEvents());
      dispatch(findSelectionTestSessions());
      dispatch(findSelectionTrainingSessions());
      dispatch(findParticipants());
      dispatch(findEventDataSets());
      dispatch(findCells());
    }

    return () => {
      mounted = false;
    };
  }, [open]);

  const {
    objectTypes,
    scenarios,
    customerEvents,
    sessions,
    sessionsLoading,
    trainingSessions,
    trainingSessionsLoading,
    participants,
    eventDataSets,
    eventDataSetsLoading,
    cells
  } = useSelector(state => state.eventFilter);

  if (!filter) return null;

  const filterObjectPresent = !(
    !filter.objectPresent && !filter.objectNotPresent
  );

  const handleClear = () => {
    onClear();
  };

  const handleNumericFieldChange = (field, value) => {
    const numeric = value.replace(/\D/g, '');
    onFieldChange(field, numeric === '' ? null : numeric);
  };

  const handleFieldChange = (field, value) => {
    onFieldChange(field, value === '' ? null : value);
  };

  const handleCustomerEventFieldChange = value => {
    onFieldChange(
      'customerEvent',
      value === ''
        ? null
        : customerEvents[customerEvents.map(e => e.id).indexOf(value)]
    );
  };

  const handleObjectTypeChange = (field, objectTypeId) => {
    let objectType;
    if (objectTypeId === '') {
      objectType = null;
    } else {
      objectType =
        objectTypes[objectTypes.map(ot => ot.id).indexOf(objectTypeId)];
    }
    onFieldChange(field, objectType);
  };

  const handleObjectPresentFieldChange = (field, value) => {
    /* If turning off active radio button */
    if (!value && filter[field]) {
      onFieldChange(field, value);
    } else {
      if (field === 'objectPresent') {
        onFieldChange('objectPresent', value);
        onFieldChange('objectNotPresent', !value);
      } else if (field === 'objectNotPresent') {
        onFieldChange('objectNotPresent', value);
        onFieldChange('objectPresent', !value);
      }
    }
  };

  const handleToggleGeneral = () => {
    setExpandGeneral(expandGeneral => !expandGeneral);
  };

  const handleToggleValidation = () => {
    setExpandValidation(expandValidation => !expandValidation);
  };

  const handleToggleSubjectContents = () => {
    setExpandSubjectContents(expandSubjectContents => !expandSubjectContents);
  };

  const handleUploadJsonFileChange = event => {
    event.persist && event.persist();

    const file = event.target.files[0];

    if (file) {
      dispatch(uploadEventDataSetFilterWalksFile(filterIndex, file));
    }

    event.target.value = null;
  };

  const handleSubmit = event => {
    event.preventDefault();
    onFilter && onFilter();
  };

  const handleDateChange = (field, date) => {
    onFieldChange(field, date ? dateUtils.getUTCEpoch(date) / 1000 : null);
  };

  const handleScenarioChange = event => {
    event.persist && event.persist();
    const scenarioId = event.target.value;
    const scenario = scenarios[scenarios.map(s => s.id).indexOf(scenarioId)];

    onFieldChange('scenario', scenario);
  };

  const handleCellChange = event => {
    event.persist && event.persist();
    const cellId = event.target.value;
    const cell = cells[cells.map(c => c.id).indexOf(cellId)];

    onFieldChange('cell', cell);
  };

  const handleEventDataSetChange = eventDataSetId => {
    const eventDataSet =
      eventDataSets[eventDataSets.map(d => d.id).indexOf(eventDataSetId)];

    onFieldChange('eventDataSet', eventDataSet);
  };

  const handleSelectSession = type => {
    setSelectSessionType(type);
  };

  const handleCloseSelectSession = () => {
    setSelectSessionType(null);
  };

  const handleSetSession = session => {
    onFieldChange('session', session);
    setSelectSessionType(null);
  };

  const handleSetTrainingSession = session => {
    onFieldChange('trainingSession', session);
    setSelectSessionType(null);
  };

  const handleClearSession = type => {
    if (type === 'training') {
      onFieldChange('trainingSession', null);
    } else if (type === 'testing') {
      onFieldChange('session', null);
    }
    setSelectSessionType(null);
  };

  const handleSelectParticipant = () => {
    setSelectParticipantOpen(true);
  };

  const handleCloseSelectParticipant = () => {
    setSelectParticipantOpen(false);
  };

  const handleSetParticipant = participant => {
    onFieldChange('participant', participant);
    setSelectParticipantOpen(false);
  };

  const handleClearParticipant = () => {
    onFieldChange('participant', null);
    setSelectParticipantOpen(false);
  };

  return (
    <>
      <Modal onClose={onClose} open={open}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={
              'Walks Filter ' +
              (filterIndex !== undefined ? filterIndex + 1 : '')
            }
          />
          <Divider />
          {processing && <LinearProgress />}
          <form onSubmit={handleSubmit}>
            <div className={classes.content}>
              <div className={classes.contentSection}>
                <div
                  className={classes.contentSectionHeader}
                  onClick={handleToggleGeneral}>
                  <Typography variant="h5">General</Typography>
                  {expandGeneral ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                <Divider className={classes.sectionDivider} />
                <Collapse in={expandGeneral}>
                  <div className={classes.contentSectionContent}>
                    <Grid container spacing={3}>
                      {(!hiddenFields ||
                        hiddenFields.indexOf('modelConfiguration') < 0) && (
                        <>
                          <Grid item sm={12} md={6}>
                            <SelectModelConfiguration
                              className={classes.select}
                              fullWidth
                              modelConfiguration={filter.modelConfiguration}
                              onChange={modelConfiguration =>
                                handleFieldChange(
                                  'modelConfiguration',
                                  modelConfiguration
                                )
                              }
                            />
                          </Grid>
                          <Grid item sm={12} md={6} />
                        </>
                      )}
                      <Grid item sm={12} md={3}>
                        <TextField
                          className={classes.select}
                          margin="dense"
                          fullWidth
                          label="Validation Status"
                          name="validationStatus"
                          onChange={event =>
                            handleFieldChange(
                              'validationStatus',
                              event.target.value
                            )
                          }
                          select
                          // eslint-disable-next-line react/jsx-sort-props
                          SelectProps={{ native: true }}
                          value={
                            filter.validationStatus
                              ? filter.validationStatus
                              : ''
                          }
                          variant="outlined">
                          <option></option>
                          {Object.keys(EVENT_VALIDATION_STATUSES).map(
                            validationStatus => (
                              <option
                                key={validationStatus}
                                value={validationStatus}>
                                {EVENT_VALIDATION_STATUSES[validationStatus]}
                              </option>
                            )
                          )}
                        </TextField>
                      </Grid>
                      <Grid item sm={12} md={9}>
                        <Grid container spacing={3}>
                          <Grid item sm={12} md={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    filter.trainingEvent
                                      ? filter.trainingEvent
                                      : false
                                  }
                                  color="primary"
                                  onChange={(_, value) =>
                                    handleFieldChange('trainingEvent', value)
                                  }
                                />
                              }
                              label="Training Walk"
                            />
                          </Grid>
                          <Grid item sm={12} md={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    filter.testSessionEvent
                                      ? filter.testSessionEvent
                                      : false
                                  }
                                  color="primary"
                                  onChange={(_, value) =>
                                    handleFieldChange('testSessionEvent', value)
                                  }
                                />
                              }
                              label="Testing Walk"
                            />
                          </Grid>
                          <Grid item sm={12} md={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    filter.customerEventAssociated
                                      ? filter.customerEventAssociated
                                      : false
                                  }
                                  color="primary"
                                  onChange={(event, value) =>
                                    handleFieldChange(
                                      'customerEventAssociated',
                                      value
                                    )
                                  }
                                />
                              }
                              label="Customer Event"
                            />
                          </Grid>
                          <Grid item sm={12} md={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    filter.specialInterest ? true : false
                                  }
                                  color="primary"
                                  onChange={(event, value) =>
                                    handleFieldChange('specialInterest', value)
                                  }
                                />
                              }
                              label="Special Interest"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <TextField
                          className={classes.field}
                          fullWidth
                          label="Walk Id / GUID"
                          margin="dense"
                          name="walkId"
                          onChange={event =>
                            handleFieldChange('walkId', event.target.value)
                          }
                          value={filter.walkId !== null ? filter.walkId : ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <TextField
                          className={classes.select}
                          fullWidth
                          margin="dense"
                          label="Gender"
                          name="participantGender"
                          onChange={event =>
                            handleFieldChange(
                              'participantGender',
                              event.target.value
                            )
                          }
                          select
                          // eslint-disable-next-line react/jsx-sort-props
                          SelectProps={{ native: true }}
                          value={
                            filter.participantGender
                              ? filter.participantGender
                              : ''
                          }
                          variant="outlined">
                          <option value="" />

                          {Object.keys(GENDERS)
                            .filter(g => g !== 'NON_BINARY')
                            .map(gender => (
                              <option key={gender} value={gender}>
                                {GENDERS[gender]}
                              </option>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        {customerEvents.length > 0 && (
                          <TextField
                            className={classes.select}
                            margin="dense"
                            label="Customer Event"
                            name="customerEvent"
                            fullWidth
                            onChange={event =>
                              handleCustomerEventFieldChange(event.target.value)
                            }
                            select
                            // eslint-disable-next-line react/jsx-sort-props
                            SelectProps={{ native: true }}
                            value={
                              filter.customerEvent
                                ? filter.customerEvent.id
                                : ''
                            }
                            variant="outlined">
                            <option></option>
                            {customerEvents.map(customerEvent => (
                              <option
                                key={customerEvent.id}
                                value={customerEvent.id}>
                                {moment
                                  .utc(customerEvent.eventStartTime)
                                  .format('MMM Do YYYY')}{' '}
                                / {customerEvent.name}
                              </option>
                            ))}
                          </TextField>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.textFieldButtonContainer}>
                          <TextField
                            size="small"
                            className={classes.activeDisabled}
                            fullWidth
                            disabled
                            label="Test Session"
                            value={
                              filter.session && filter.session.id
                                ? filter.session.participant.name +
                                  ' / ' +
                                  moment(filter.session.createdTime).format(
                                    'MMM Do YYYY, h:mm a'
                                  )
                                : ''
                            }
                            variant="outlined"
                          />
                          <Button
                            className={classes.textFieldButton}
                            onClick={() => handleSelectSession('testing')}>
                            <MoreHorizIcon />
                          </Button>
                          <Button
                            className={classes.textFieldCancelButton}
                            onClick={() => handleClearSession('testing')}>
                            <CancelIcon />
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.textFieldButtonContainer}>
                          <TextField
                            size="small"
                            className={classes.activeDisabled}
                            fullWidth
                            disabled
                            label="Training Session"
                            value={
                              filter.trainingSession &&
                              filter.trainingSession.id
                                ? filter.trainingSession.participant.name +
                                  ' / ' +
                                  moment(
                                    filter.trainingSession.createdTime
                                  ).format('MMM Do YYYY, h:mm a')
                                : ''
                            }
                            variant="outlined"
                          />
                          <Button
                            className={classes.textFieldButton}
                            onClick={() => handleSelectSession('training')}>
                            <MoreHorizIcon />
                          </Button>
                          <Button
                            className={classes.textFieldCancelButton}
                            onClick={() => handleClearSession('training')}>
                            <CancelIcon />
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        {!eventDataSetsLoading && (
                          <TextField
                            className={classes.select}
                            fullWidth
                            margin="dense"
                            label="Walk Collection"
                            name="eventDataSet"
                            onChange={event =>
                              handleEventDataSetChange(event.target.value)
                            }
                            select
                            // eslint-disable-next-line react/jsx-sort-props
                            SelectProps={{ native: true }}
                            value={
                              filter.eventDataSet && filter.eventDataSet.id
                                ? filter.eventDataSet.id
                                : ''
                            }
                            variant="outlined">
                            <option key="" value="" />
                            {eventDataSets.map(eventDataSet => (
                              <option
                                key={eventDataSet.id}
                                value={eventDataSet.id}>
                                {eventDataSet.name}
                              </option>
                            ))}
                          </TextField>
                        )}
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className={classes.datePicker}
                            margin="normal"
                            id="filter-start-date-picker-dialog"
                            label="Walk Date (From)"
                            format="MM/dd/yyyy"
                            value={
                              !!filter.eventTimeFrom
                                ? new Date(filter.eventTimeFrom * 1000)
                                : null
                            }
                            onChange={date =>
                              handleDateChange('eventTimeFrom', date)
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className={classes.datePicker}
                            margin="normal"
                            id="filter-finish-date-picker-dialog"
                            label="Walk Date (To)"
                            format="MM/dd/yyyy"
                            value={
                              !!filter.eventTimeTo
                                ? new Date(filter.eventTimeTo * 1000)
                                : null
                            }
                            onChange={date =>
                              handleDateChange('eventTimeTo', date)
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.textFieldButtonContainer}>
                          <TextField
                            size="small"
                            className={classes.activeDisabled}
                            fullWidth
                            disabled
                            label="Participant"
                            value={
                              filter.participant && filter.participant.id
                                ? filter.participant.name
                                : ''
                            }
                            variant="outlined"
                          />
                          <Button
                            className={classes.textFieldButton}
                            onClick={handleSelectParticipant}>
                            <MoreHorizIcon />
                          </Button>
                          <Button
                            className={classes.textFieldCancelButton}
                            onClick={handleClearParticipant}>
                            <CancelIcon />
                          </Button>
                        </div>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        {cells.length > 0 && (
                          <TextField
                            className={classes.select}
                            margin="dense"
                            label="Cell"
                            name="cell"
                            fullWidth
                            onChange={event => handleCellChange(event)}
                            select
                            // eslint-disable-next-line react/jsx-sort-props
                            SelectProps={{ native: true }}
                            value={filter.cell ? filter.cell.id : ''}
                            variant="outlined">
                            <option></option>
                            {cells.map(cell => (
                              <option key={cell.id} value={cell.id}>
                                {cell.name}
                              </option>
                            ))}
                          </TextField>
                        )}
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <TextField
                          className={classes.select}
                          margin="dense"
                          label="Cell Type"
                          name="cellModel"
                          fullWidth
                          onChange={event =>
                            handleFieldChange('cellModel', event.target.value)
                          }
                          select
                          // eslint-disable-next-line react/jsx-sort-props
                          SelectProps={{ native: true }}
                          value={filter.cellModel ? filter.cellModel : ''}
                          variant="outlined">
                          <option></option>
                          {Object.keys(CELL_MODELS).map(cellModel => (
                            <option key={cellModel} value={cellModel}>
                              {CELL_MODELS[cellModel]}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item sm={12} md={6}>
                        <TextField
                          className={classes.field}
                          fullWidth
                          label="Facial Recognition Name"
                          margin="dense"
                          name="facialRecognitionPersonName"
                          onChange={event =>
                            handleFieldChange(
                              'facialRecognitionPersonName',
                              event.target.value
                            )
                          }
                          value={
                            filter.facialRecognitionPersonName !== null
                              ? filter.facialRecognitionPersonName
                              : ''
                          }
                          variant="outlined"
                        />
                      </Grid>

                      {eventDataSet && (
                        <>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                          <Grid item sm={12}>
                            <div className={classes.fileInput}>
                              <TextField
                                className={classes.activeDisabled}
                                // error={uploadValidationMessages.hasOwnProperty('modelFileName') || validationMessages.hasOwnProperty('modelFileName')}
                                // helperText={uploadValidationMessages.modelFileName || validationMessages.modelFileName}
                                fullWidth
                                label="Walk Upload File"
                                name="file"
                                disabled
                                value={
                                  filter.walkUploadFileName
                                    ? filter.walkUploadFileName
                                    : ''
                                }
                                variant="outlined"
                              />
                              <Button
                                className={classes.fileInputButton}
                                size="small"
                                variant="outlined"
                                component="label"
                                // disabled={modelFileUploading}
                              >
                                {/* {modelFileUploading ? 'Uploading' : 'Upload'} */}
                                Upload
                                <input
                                  type="file"
                                  name="file"
                                  onChange={handleUploadJsonFileChange}
                                  accept=".json"
                                  hidden
                                />
                                {/* {modelFileUploading && <CircularProgress className={classes.fileInputProgress} size={10} />} */}
                              </Button>
                            </div>
                          </Grid>
                        </>
                      )}

                      {(!hiddenFields ||
                        hiddenFields.indexOf('selectedEventCount') < 0) && (
                        <>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                          <Grid item sm={12} md={3}>
                            <TextField
                              className={classes.field}
                              fullWidth
                              label="Walks Limit"
                              margin="dense"
                              name="fileName"
                              onChange={event =>
                                handleNumericFieldChange(
                                  'selectedEventCount',
                                  event.target.value
                                )
                              }
                              value={
                                filter.selectedEventCount !== null
                                  ? filter.selectedEventCount
                                  : ''
                              }
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item sm={12} md={9} />
                        </>
                      )}
                    </Grid>
                  </div>
                </Collapse>
              </div>

              {/* <div className={classes.contentSection}>
                <div
                  className={classes.contentSectionHeader}
                  onClick={handleToggleObjectPrediction}
                >
                  <Typography variant="h5">Object Prediction</Typography>
                  {expandObjectPrediction ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                <Divider className={classes.sectionDivider} />
                <Collapse in={expandObjectPrediction}>
                  <div className={classes.contentSectionContent}>
                    <div className={classes.contentSectionContent}>
                    <Grid 
                      container 
                      spacing={3}
                    >
                    <Grid 
                      item 
                      sm={12} 
                      md={3}
                    >
                      <div className={classes.formGroup}>
                        <Typography
                            component="p"
                            gutterBottom
                            variant="overline"
                        >
                            Object Detection
                        </Typography>
                        <div className={classes.fieldGroup}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={filter.predictionCorrect ? true : false}
                                color="primary"
                                onChange={(event, value) =>
                                  handleFieldChange('predictionCorrect', value)
                                }
                              />
                            }
                            label="Score Correct"
                          />                       
                        </div>
                        <div className={classes.fieldGroup}>                                        
                          <FormControlLabel
                            control={
                              <Checkbox
                              checked={filter.predictionFalsePositive ? true : false}
                              color="primary"
                                onChange={(event, value) =>
                                  handleFieldChange('predictionFalsePositive', value)
                                }
                              />
                            }
                            label="False Positive"
                          />                       
                        </div>
                        <div className={classes.fieldGroup}>                                        
                          <FormControlLabel
                            control={
                              <Checkbox
                              checked={filter.predictionFalseNegative ? true : false}
                              color="primary"
                                onChange={(event, value) =>
                                  handleFieldChange('predictionFalseNegative', value)
                                }
                              />
                            }
                            label="False Negative"
                          />                       
                        </div>
                      </div>
                    </Grid>
                    <Grid 
                      item 
                      sm={12} 
                      md={9}
                    >
                    <div className={classes.formGroup}>
                      <Typography
                          component="p"
                          gutterBottom
                          variant="overline"
                      >
                          Object
                      </Typography>
                      <div className={classes.fieldGroup}>
                          <Typography
                          className={classes.minAmount}
                          variant="body1"
                          >
                          {filter.objectPercent[0]}%
                          </Typography>
                          <Slider
                          className={classes.flexGrow}
                          max={100}
                          min={0}
                          onChange={(event, value) =>
                              handleFieldChange('objectPercent', value)
                          }
                          value={filter.objectPercent}
                          valueLabelDisplay="auto"
                          />
                          <Typography
                          className={classes.maxAmount}
                          variant="body1"
                          >
                          {filter.objectPercent[1]}%
                          </Typography>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                        <Typography
                            component="p"
                            gutterBottom
                            variant="overline"
                        >
                            Object Left
                        </Typography>
                        <div className={classes.fieldGroup}>
                            <Typography
                            className={classes.minAmount}
                            variant="body1"
                            >
                            {filter.objectLeftPercent[0]}%
                            </Typography>
                            <Slider
                            className={classes.flexGrow}
                            max={100}
                            min={0}
                            onChange={(event, value) =>
                                handleFieldChange('objectLeftPercent', value)
                            }
                            value={filter.objectLeftPercent}
                            valueLabelDisplay="auto"
                            />
                            <Typography
                            className={classes.maxAmount}
                            variant="body1"
                            >
                            {filter.objectLeftPercent[1]}%
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.formGroup}>
                      <Typography
                          component="p"
                          gutterBottom
                          variant="overline"
                      >
                          Object Right
                      </Typography>
                      <div className={classes.fieldGroup}>
                          <Typography
                          className={classes.minAmount}
                          variant="body1"
                          >
                          {filter.objectRightPercent[0]}%
                          </Typography>
                          <Slider
                          className={classes.flexGrow}
                          max={100}
                          min={0}
                          onChange={(event, value) =>
                              handleFieldChange('objectRightPercent', value)
                          }
                          value={filter.objectRightPercent}
                          valueLabelDisplay="auto"
                          />
                          <Typography
                            className={classes.maxAmount}
                            variant="body1"
                          >
                          {filter.objectRightPercent[1]}%
                          </Typography>
                      </div>
                    </div>
                    </Grid>
                  </Grid>


                    
                    </div>
                  </div>
                </Collapse>
              </div> */}
              <div className={classes.contentSection}>
                <div
                  className={classes.contentSectionHeader}
                  onClick={handleToggleSubjectContents}>
                  <Typography variant="h5">Subject Contents</Typography>
                  {expandSubjectContents ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </div>
                <Divider className={classes.sectionDivider} />
                <Collapse in={expandSubjectContents}>
                  <div className={classes.contentSectionContent}>
                    <div className={classes.contentSectionContent}>
                      <Typography component="p" gutterBottom variant="overline">
                        Object
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item sm={12} md={3}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={filter.objectPresent}
                                color="primary"
                                onClick={(event, value) =>
                                  handleObjectPresentFieldChange(
                                    'objectPresent',
                                    !filter.objectPresent
                                  )
                                }
                              />
                            }
                            label="Object Present"
                          />
                        </Grid>
                        <Grid item sm={12} md={3}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={filter.objectNotPresent}
                                color="primary"
                                onClick={(event, value) =>
                                  handleObjectPresentFieldChange(
                                    'objectNotPresent',
                                    !filter.objectNotPresent
                                  )
                                }
                              />
                            }
                            label="Object Not Present"
                          />
                        </Grid>
                      </Grid>
                      <div className={classes.formGroup}>
                        <Typography
                          component="p"
                          gutterBottom
                          variant="overline">
                          Object Type
                        </Typography>
                        <div className={classes.fieldGroup}>
                          {objectTypes.length > 0 && (
                            <TextField
                              margin="dense"
                              label="Object Type"
                              name="objectType"
                              onChange={event =>
                                handleObjectTypeChange(
                                  'objectType',
                                  event.target.value
                                )
                              }
                              select
                              // eslint-disable-next-line react/jsx-sort-props
                              SelectProps={{ native: true }}
                              value={
                                filter.objectType ? filter.objectType.id : ''
                              }
                              variant="outlined">
                              <option></option>
                              {objectTypes.map(objectType => (
                                <option
                                  key={objectType.id}
                                  value={objectType.id}>
                                  {objectType.name}
                                </option>
                              ))}
                            </TextField>
                          )}
                        </div>
                      </div>

                      <div className={classes.formGroup}>
                        <Typography
                          component="p"
                          gutterBottom
                          variant="overline">
                          Object Location
                        </Typography>
                        <div className={classes.fieldGroup}>
                          <Grid container spacing={0}>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectRightSide}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectRightSide',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Right Side"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectRightChest}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectRightChest',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Right Chest"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectRightFoot}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectRightFoot',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Right Foot"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectLeftSide}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange('objectLeftSide', value)
                                    }
                                  />
                                }
                                label="Left Side"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectLeftChest}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectLeftChest',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Left Chest"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectLeftFoot}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange('objectLeftFoot', value)
                                    }
                                  />
                                }
                                label="Left Foot"
                              />
                            </Grid>

                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectHead}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange('objectHead', value)
                                    }
                                  />
                                }
                                label="Head"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectFront}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange('objectFront', value)
                                    }
                                  />
                                }
                                label="Front"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectBack}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange('objectBack', value)
                                    }
                                  />
                                }
                                label="Back"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectLeftAnkleInside}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectLeftAnkleInside',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Left Ankle - Inside"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectLeftAnkleOutside}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectLeftAnkleOutside',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Left Ankle - Outside"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectRightAnkleInside}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectRightAnkleInside',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Right Ankle - Inside"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectRightAnkleOutside}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectRightAnkleOutside',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Right Ankle - Outside"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectLeftBackWaistband}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectLeftBackWaistband',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Waistband - Left Side, Back"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectLeftFrontWaistband}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectLeftFrontWaistband',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Waistband - Left Side, Front"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectRightBackWaistband}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectRightBackWaistband',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Waistband - Right Side, Back"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectRightFrontWaistband}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectRightFrontWaistband',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Waistband - Right Side, Front"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectRightThighOutside}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectRightThighOutside',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Thigh - Right Side, Outside"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectLeftThighOutside}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectLeftThighOutside',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Thigh - Left Side, Outside"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectUnderBra}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange('objectUnderBra', value)
                                    }
                                  />
                                }
                                label="Under Bra"
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <Typography
                          className={classes.subjectAreaSubHeading}
                          component="p"
                          gutterBottom
                          variant="overline">
                          Object Bag Location
                        </Typography>
                        <div className={classes.fieldGroup}>
                          <Grid container spacing={0}>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectBagLeftHand}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectBagLeftHand',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Left Hand"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectBagRightHand}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectBagRightHand',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Right Hand"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectBagBack}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange('objectBagBack', value)
                                    }
                                  />
                                }
                                label="Back"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectBagFannypack}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectBagFannypack',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Fannypack"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectBagLeftShoulderHigh}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectBagLeftShoulderHigh',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Left Shoulder High"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectBagLeftShoulderLow}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectBagLeftShoulderLow',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Left Shoulder Low"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectBagRightShoulderHigh}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectBagRightShoulderHigh',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Right Shoulder High"
                              />
                            </Grid>
                            <Grid item sm={3}>
                              <FormControlLabel
                                disabled={!filterObjectPresent}
                                control={
                                  <Checkbox
                                    checked={filter.objectBagRightShoulderLow}
                                    color="primary"
                                    onChange={(event, value) =>
                                      handleFieldChange(
                                        'objectBagRightShoulderLow',
                                        value
                                      )
                                    }
                                  />
                                }
                                label="Right Shoulder Low"
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                onClick={onClose}
                variant="contained"
                disabled={processing}>
                Cancel
              </Button>
              <Button
                onClick={handleClear}
                variant="contained"
                disabled={processing}>
                <DeleteIcon className={classes.buttonIcon} />
                Clear
              </Button>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={processing}>
                Apply filters
              </Button>
            </CardActions>
          </form>
        </Card>
      </Modal>

      <SessionSelect
        open={selectSessionType === 'testing'}
        onClose={handleCloseSelectSession}
        sessions={sessions}
        loading={sessionsLoading}
        onSelectSession={handleSetSession}
      />

      <SessionSelect
        open={selectSessionType === 'training'}
        onClose={handleCloseSelectSession}
        sessions={trainingSessions}
        loading={trainingSessionsLoading}
        onSelectSession={handleSetTrainingSession}
      />

      <ParticipantSelect
        open={selectParticipantOpen}
        participants={participants}
        onSelectParticipant={handleSetParticipant}
        onClose={handleCloseSelectParticipant}
      />
    </>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Filter;
