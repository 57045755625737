import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#27518c',
    main: '#1A59B4',
    light: '#6E96D1'
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200],
  calendar: {
    presentEvent: '#5fa778', // Shiny Shamrock Green
    futureEvent: '#eca20c', // Coo-Var Yellow
    pastActiveEvent: '#676767', // French wine
    pastInactiveEvent: '#ac1e44' // Granite gray
  },
  xonar: {
    trueBlue: '#1A55F3',
    motionMagenta: '#D3219B',
    aiOrange: '#D78532',
    navyBase: '#1D273D'
  }
};
