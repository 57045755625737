import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button
} from '@material-ui/core';
import ws from '../../utils/ws';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1080,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: theme.spacing(2)
  }
}));

const ImageModal = props => {
  const { open, onClose, imageUri, className, ...rest } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  const create = true;

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <img className={classes.image} src={ws.url(imageUri)} />
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ImageModal.defaultProps = {
  open: false
};

export default ImageModal;
