/* eslint-disable no-undef */
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

const Page = props => {
  const { title, right, children, ...rest } = props;

  const { sessionUser } = useSelector(state => state.session);

  if (right && sessionUser.rights.indexOf(right) < 0) {
    return <Redirect to="/errors/error-401" />;
  }

  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Page;
