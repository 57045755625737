import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import {
  setCreateCellBuildDefinitionOpen,
  findCellBuildDefinitions,
  findCellModelVersions,
  setCreateCellBuildComponentFieldValue,
  createNewCellBuildDefinition
} from 'actions';
import { CELL_MODELS } from 'common/constants';
import { Alert } from 'components';
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 550,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {},
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  infoAlert: {
    marginBottom: theme.spacing(3)
  }
}));

const CreateProductBuildComponent = props => {
  const {} = props;

  const {
    createCellBuildDefinitionOpen,
    createCellBuildDefinition,
    savedCellBuildDefinition,
    cellModelVersions,
    cellBuildDefinitions,
    validationMessages,
    saved
  } = useSelector(state => state.cellBuildDefinitionCreate);

  const existingBuildModelVersionIds = cellBuildDefinitions.map(
    buildDefinition => buildDefinition.cellModelVersion.id
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted && createCellBuildDefinitionOpen) {
      dispatch(findCellBuildDefinitions());
      dispatch(findCellModelVersions());
    }

    return () => {
      mounted = false;
    };
  }, [createCellBuildDefinitionOpen]);

  if (saved) {
    return (
      <Redirect
        to={`/deployment/cell_build_definitions/${savedCellBuildDefinition.id}/components`}
      />
    );
  }

  const handleClose = () => {
    dispatch(setCreateCellBuildDefinitionOpen(false));
  };

  const handleValueChange = (field, value) => {
    dispatch(setCreateCellBuildComponentFieldValue(field, value));
  };

  const handleSave = () => {
    dispatch(createNewCellBuildDefinition(createCellBuildDefinition));
  };

  if (!createCellBuildDefinitionOpen) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={createCellBuildDefinitionOpen}>
      <Card className={classes.root}>
        <CardHeader title="Create Cell Build Definition" />
        <Divider />
        <CardContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cell Version"
                name="cellModelVersion"
                onChange={e =>
                  handleValueChange(
                    'cellModelVersion',
                    e.target.value ? { id: e.target.value } : null
                  )
                }
                error={validationMessages.hasOwnProperty('cellModelVersion')}
                helperText={validationMessages.cellModelVersion}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={
                  createCellBuildDefinition.cellModelVersion
                    ? createCellBuildDefinition.cellModelVersion.id
                    : ''
                }
                variant="outlined">
                <option />
                {cellModelVersions.map(cellModelVersion => (
                  <option
                    disabled={
                      existingBuildModelVersionIds.indexOf(
                        cellModelVersion.id
                      ) > -1
                    }
                    key={cellModelVersion.id}
                    value={cellModelVersion.id}>
                    {CELL_MODELS[cellModelVersion.cellModel]} /{' '}
                    {cellModelVersion.version}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Alert
                className={classes.infoAlert}
                message="Select an existing definition to copy components from (optional)"
              />

              <TextField
                fullWidth
                label="Existing Cell Build Definition"
                name="templateCellBuildDefinitionId"
                onChange={e =>
                  handleValueChange(
                    'templateCellBuildDefinitionId',
                    e.target.value
                  )
                }
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={
                  createCellBuildDefinition.templateCellBuildDefinitionId
                    ? createCellBuildDefinition.templateCellBuildDefinitionId
                    : ''
                }
                variant="outlined">
                <option />
                {cellBuildDefinitions.map(cellBuildDefinition => (
                  <option
                    key={cellBuildDefinition.id}
                    value={cellBuildDefinition.id}>
                    {
                      CELL_MODELS[
                        cellBuildDefinition?.cellModelVersion.cellModel
                      ]
                    }{' '}
                    / {cellBuildDefinition?.cellModelVersion.version}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave} variant="contained">
            Create
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CreateProductBuildComponent.defaultProps = {
  open: false
};

export default CreateProductBuildComponent;
