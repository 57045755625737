import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat } from 'components';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  LinearProgress
} from '@material-ui/core';
import { ENTRY_FEET, ANALYTICS_OBJECT_LOCATIONS } from 'common/constants';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1150
  },
  methodCell: {
    width: 100
  },
  statusCell: {
    width: 64
  },
  nonSelected: {
    background: 'white'
  },
  entryFoot: {
    width: 200
  }
}));

const CrossoverOverviewAnalysisCard = props => {
  const { className, analysis, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardHeader title="Crossover" />
        <Divider />
        <CardContent className={classes.content}>
          {analysis.loading && <LinearProgress />}
          {analysis.results && (
            <PerfectScrollbar>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell colSpan={20}>Object Placement</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.entryFoot}>Entry</TableCell>
                    {analysis.objectLocations.map(objectLocation => (
                      <TableCell key={objectLocation}>
                        {ANALYTICS_OBJECT_LOCATIONS[objectLocation]}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(analysis.results).map(entryFoot => (
                    <TableRow key={entryFoot} selected>
                      <TableCell>{ENTRY_FEET[entryFoot]}</TableCell>
                      {analysis.objectLocations.map(objectLocation => (
                        <TableCell
                          className={classes.nonSelected}
                          key={objectLocation}>
                          <NumberFormat
                            value={
                              analysis.results[entryFoot].objectLocationResults[
                                objectLocation
                              ]
                            }
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={20}>&nbsp;</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </PerfectScrollbar>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

CrossoverOverviewAnalysisCard.propTypes = {
  className: PropTypes.string,
  analysis: PropTypes.object.isRequired
};

export default CrossoverOverviewAnalysisCard;
