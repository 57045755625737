import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Search } from 'components/SearchBar/components';
import { useSelector } from 'react-redux';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1150,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  progress: {
    marginBottom: theme.spacing(2)
  },
  fileName: {
    marginBottom: theme.spacing(2)
  },
  image: {
    width: '100%'
  },
  details: {
    padding: theme.spacing(2)
  },
  comment: {
    flexGrow: 1,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    position: 'relative',
    marginTop: theme.spacing(1)
  },
  search: {
    minWidth: 480,
    maxWidth: 480,
    flexBasis: 480
  },
  tableContainer: {
    minHeight: 450,
    maxHeight: 450,
    overflowY: 'auto'
  },
  searchContainer: {
    padding: theme.spacing(2)
  },
  selfSearch: {
    marginTop: 3
  },
  scenarioName: {
    maxWidth: 260,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const SessionSelect = props => {
  const { open, onClose, sessions, onSelectSession, className } = props;

  const classes = useStyles();

  const loading = false;
  const [searchName, setSearchName] = useState('');
  const { sessionUser } = useSelector(state => state.session);

  const searchedSessions = sessions.filter(session => {
    return (
      searchName === '' ||
      session.participant.name
        .toUpperCase()
        .indexOf(searchName.toUpperCase()) !== -1
    );
  });

  const handleClose = () => {
    onClose();
  };

  const handleSearchFieldChange = value => {
    setSearchName(value);
  };

  const handleSelfSearch = () => {
    setSearchName(sessionUser.displayName);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader title="Select Session" />
        <Divider />

        <CardContent className={classes.content}>
          <div className={classes.searchContainer}>
            <Grid container spacing={3}>
              <Grid item>
                <Search
                  placeholder="Participant"
                  value={searchName}
                  onSearchValueChange={handleSearchFieldChange}
                  className={classes.search}
                  hideSearchButton={true}
                />
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Participant</TableCell>
                  <TableCell>Cell</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Walked</TableCell>
                  <TableCell>Scenario</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedSessions.map(session => (
                  <TableRow key={session.id} hover>
                    <TableCell>{session.participant.name}</TableCell>
                    <TableCell>{session.cell.name}</TableCell>
                    <TableCell>
                      {moment(session.createdTime).format(
                        'MMM Do YYYY, h:mm a'
                      )}
                    </TableCell>
                    <TableCell>
                      {session.earliestEventTime &&
                        moment(session.earliestEventTime).format(
                          'MMM Do YYYY, h:mm a'
                        )}
                    </TableCell>
                    <TableCell className={classes.scenarioName}>
                      {session.scenarioNames}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => onSelectSession(session)}>
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SessionSelect.displayName = 'SessionSelect';

SessionSelect.propTypes = {
  className: PropTypes.string
};

export default SessionSelect;
