import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat } from 'components';
import HelpIcon from '@material-ui/icons/Help';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  LinearProgress,
  colors,
  Typography,
  Tooltip,
  Button
} from '@material-ui/core';
import {
  ANALISYS_OVERVIEW_RESULT_TYPES,
  ANALISYS_OVERVIEW_RESULT_MEASURES,
  ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS,
  ANALYSIS_BIAS_TYPES,
  CHART_COLORS
} from 'common/constants';
import ws from 'utils/ws';

const useStyles = makeStyles(theme => ({
  root: {
    '& th': {
      verticalAlign: 'bottom'
    }
  },
  content: {
    padding: 0
  },
  tooltip: {
    cursor: 'help'
  },
  noResult: {
    display: 'block',
    height: 21,
    width: 55
  },
  filterLoading: {
    width: '10px!important',
    height: '10px!important',
    marginLeft: theme.spacing(1)
  },
  resultType: {
    background: colors.grey[50],
    '& p': {
      display: 'inline-block',
      fontWeight: 500
    }
  },
  separator: {
    height: theme.spacing(3)
  },
  hover: {
    '&:hover': {
      '& td': {
        background: '#F5F6F8'
      }
    }
  },
  columnSeparator: {
    borderLeftWidth: 1,
    borderLeftColor: colors.grey[400],
    borderLeftStyle: 'dotted'
  },
  subFilterHelp: {
    fontSize: 16,
    marginBottom: -4,
    marginLeft: 2,
    color: colors.blue[600]
  },
  subFilterTooltipContainer: {
    cursor: 'help'
  },
  cardHeaderButton: {
    marginBottom: -3,
    marginTop: 5
  },
  highlightedHeader: {
    backgroundColor: '#F4F6F8'
  },
  highlightedValue: {
    backgroundColor: '#F5F6F8'
  }
}));

const SubFilterAnalysisOverviewCard = props => {
  const {
    className,
    subFilterOverviews,
    excelDownloadUri,
    loading,
    biasType,
    footEntryDisabled,
    highlightedFilterIndex
  } = props;
  const classes = useStyles();

  const biasResultType = ANALYSIS_BIAS_TYPES[biasType].resultType;

  const { resultMeasures } =
    subFilterOverviews.length > 0 ? subFilterOverviews[0].analysisOverview : {};
  const results =
    subFilterOverviews.length > 0
      ? subFilterOverviews[0].analysisOverview[biasResultType]
      : {};

  const subFilters = subFilterOverviews.map(
    subFilterOverview => subFilterOverview.subFilter
  );
  const visibleResultMeasures =
    results && resultMeasures
      ? resultMeasures.filter(
          resultMeasure => ANALISYS_OVERVIEW_RESULT_MEASURES[resultMeasure.code]
        )
      : [];

  const retrieveResult = (overview, resultType, resultMeasure) => {
    const result =
      overview &&
      overview.analysisOverview[biasResultType][resultType].filter(
        result => result.measure.name === resultMeasure.name
      )[0];
    return result ? result.value : null;
  };

  const FilterToolTip = props => {
    const { subFilter } = props;
    return (
      <>
        {(subFilter.filterItemDescriptions &&
          subFilter.filterItemDescriptions.length) > 0 ? (
          <span>
            {subFilter.filterItemDescriptions.map(
              (subFilterDescription, idx) => (
                <div key={idx}>
                  {subFilterDescription.filterItem +
                    ': ' +
                    subFilterDescription.value}
                </div>
              )
            )}
          </span>
        ) : (
          <span>No Filters Set</span>
        )}
      </>
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Card>
        <CardHeader
          title="Analysis Overview"
          action={
            excelDownloadUri && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                className={classes.cardHeaderButton}
                onClick={() => {
                  window.location = ws.url(excelDownloadUri);
                }}>
                Download
              </Button>
            )
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          {loading && <LinearProgress />}
          {!loading && results && (
            <>
              <PerfectScrollbar>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {visibleResultMeasures.map(resultMeasure => (
                        <TableCell
                          className={classes.columnSeparator}
                          colSpan={subFilters.length}
                          selected
                          key={resultMeasure.name}>
                          {ANALISYS_OVERVIEW_RESULT_MEASURES[resultMeasure.code]
                            .split('\n')
                            .map((row, i) => (
                              <div key={i}>{row}</div>
                            ))}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      {visibleResultMeasures.map(resultMeasure => (
                        <React.Fragment key={resultMeasure.name}>
                          {subFilters.map(subFilter => (
                            <TableCell
                              selected
                              key={subFilter.index}
                              style={{
                                borderLeftWidth: 3,
                                borderLeftStyle: 'solid',
                                borderLeftColor: CHART_COLORS[subFilter.index]
                              }}
                              className={
                                highlightedFilterIndex === subFilter.index
                                  ? classes.highlightedHeader
                                  : ''
                              }>
                              {subFilter.title ? (
                                <>{subFilter.title}</>
                              ) : (
                                <Tooltip
                                  title={
                                    <FilterToolTip subFilter={subFilter} />
                                  }
                                  placement="top-start">
                                  <span
                                    className={
                                      classes.subFilterTooltipContainer
                                    }>
                                    Filter {subFilter.index + 1}
                                    <HelpIcon
                                      className={classes.subFilterHelp}
                                    />
                                  </span>
                                </Tooltip>
                              )}
                            </TableCell>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(results).map(resultType => (
                      <React.Fragment key={resultType}>
                        {ANALISYS_OVERVIEW_RESULT_TYPES[resultType] &&
                          !(
                            footEntryDisabled &&
                            ANALISYS_OVERVIEW_RESULT_TYPES[resultType].footEntry
                          ) &&
                          !ANALISYS_OVERVIEW_RESULT_TYPES[resultType]
                            .tableHidden && (
                            <>
                              <TableRow className={classes.hover}>
                                <TableCell className={classes.resultType}>
                                  <Typography variant="body2">
                                    {
                                      ANALISYS_OVERVIEW_RESULT_TYPES[resultType]
                                        .name
                                    }
                                  </Typography>
                                </TableCell>
                                {visibleResultMeasures.map(resultMeasure => (
                                  <React.Fragment
                                    key={resultType + resultMeasure.code}>
                                    {subFilters.map(subFilter => (
                                      <TableCell
                                        className={clsx(
                                          subFilter.index === 0
                                            ? classes.columnSeparator
                                            : '',
                                          highlightedFilterIndex ===
                                            subFilter.index
                                            ? classes.highlightedHeader
                                            : ''
                                        )}
                                        key={subFilter.index}>
                                        <Tooltip
                                          title={
                                            ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS[
                                              resultType
                                            ]
                                              ? ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS[
                                                  resultType
                                                ][resultMeasure.code]
                                              : ''
                                          }
                                          placement="right">
                                          <span
                                            className={`${
                                              ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS[
                                                resultType
                                              ]
                                                ? classes.tooltip
                                                : ''
                                            } ${
                                              retrieveResult(
                                                subFilterOverviews[
                                                  subFilter.index
                                                ],
                                                resultType,
                                                resultMeasure
                                              ) === null
                                                ? classes.noResult
                                                : ''
                                            }`}>
                                            <NumberFormat
                                              percentage={
                                                resultMeasure.percentage
                                              }
                                              fixedDecimalScale={true}
                                              precision={
                                                resultMeasure.precision
                                              }
                                              value={retrieveResult(
                                                subFilterOverviews[
                                                  subFilter.index
                                                ],
                                                resultType,
                                                resultMeasure
                                              )}
                                              suffix={
                                                resultMeasure.displaySymbol
                                                  ? '%'
                                                  : ''
                                              }
                                            />
                                          </span>
                                        </Tooltip>
                                      </TableCell>
                                    ))}
                                  </React.Fragment>
                                ))}
                              </TableRow>
                              {ANALISYS_OVERVIEW_RESULT_TYPES[resultType]
                                .separator && (
                                <TableRow>
                                  <TableCell
                                    className={clsx(
                                      classes.separator,
                                      classes.columnSeparator
                                    )}
                                  />
                                  {visibleResultMeasures.map(resultMeasure => (
                                    <React.Fragment
                                      key={resultType + resultMeasure.code}>
                                      {subFilters.map(subFilter => (
                                        <TableCell
                                          key={subFilter.index}
                                          className={clsx(
                                            subFilter.index === 0
                                              ? classes.columnSeparator
                                              : '',
                                            highlightedFilterIndex ===
                                              subFilter.index
                                              ? classes.highlightedHeader
                                              : ''
                                          )}
                                        />
                                      ))}
                                    </React.Fragment>
                                  ))}
                                </TableRow>
                              )}
                            </>
                          )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

SubFilterAnalysisOverviewCard.propTypes = {
  className: PropTypes.string,
  subFilterOverviews: PropTypes.array.isRequired,
  excelDownloadUri: PropTypes.string,
  filter: PropTypes.object
};

export default SubFilterAnalysisOverviewCard;
