import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  deleted: false,
  eventModelWorker: {},
  validationMessages: {}
};

const eventModelWorkerCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_EVENT_MODEL_WORKER_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        eventModelWorker: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.EDIT_EVENT_MODEL_WORKER_FULFILLED: {
      return {
        ...state,
        eventModelWorker: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.DELETE_EVENT_MODEL_WORKER_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success
      };
    }

    case actionTypes.CREATE_EVENT_MODEL_WORKER_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_EVENT_MODEL_WORKER_VALUE_UPDATE: {
      return {
        ...state,
        eventModelWorker: {
          ...state.eventModelWorker,
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default eventModelWorkerCreateReducer;
