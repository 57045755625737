/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { SESSION_WALK_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const SessionAnalysisExclusionStatus = props => {
  const { analysisExcluded, ...rest } = props;

  return (
    <div>
      <Label
        style={{ width: 80 }}
        {...rest}
        color={analysisExcluded ? colors.red[600] : colors.blueGrey[600]}>
        {analysisExcluded ? 'Excluded' : 'Included'}
      </Label>
    </div>
  );
};

SessionAnalysisExclusionStatus.propTypes = {
  analysisExcluded: PropTypes.bool
};

export default SessionAnalysisExclusionStatus;
