import * as actionTypes from 'actions';

const initialState = {
  event: {
    loading: false
  },
  webEncodingVideos: false,
  webVideosLoading: false,
  webVideosLoaded: false,
  videos: []
};

const eventVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_EVENT_VIDEO_EVENT_PENDING: {
      return {
        ...initialState,
        event: {
          ...state.event,
          loading: true
        }
      };
    }

    case actionTypes.FIND_EVENT_VIDEO_EVENT_FULFILLED: {
      return {
        ...state,
        event: {
          ...action.payload.data.result,
          loading: false
        }
      };
    }

    case actionTypes.ENCODE_EVENT_WEB_VIDEOS_PENDING: {
      return {
        ...state,
        webEncodingVideos: true
      };
    }

    case actionTypes.ENCODE_EVENT_WEB_VIDEOS_FULFILLED: {
      return {
        ...state,
        webEncodingVideos: false,
        event: {
          ...state.event,
          webVideosEncoded: true
        }
      };
    }

    case actionTypes.FIND_EVENT_WEB_VIDEOS_PENDING: {
      return {
        ...state,
        videos: [],
        webVideosLoading: true
      };
    }

    case actionTypes.FIND_EVENT_WEB_VIDEOS_FULFILLED: {
      return {
        ...state,
        videos: action.payload.data.result,
        webVideosLoading: false,
        webVideosLoaded: true
      };
    }

    default: {
      return state;
    }
  }
};

export default eventVideoReducer;
