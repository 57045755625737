import * as actionTypes from 'actions';

const initialState = {
  pageNumber: 1,
  rowCount: 0,
  loading: false,
  items: [],
  pageSize: 250,
  filterItemCount: null,
  filter: {
    search: '',
    dataPointId: '',
    fileName: '',
    trainerId: '',
    eventTimeFrom: null,
    eventTimeTo: null,
    processingTimeFrom: null,
    processingTimeTo: null,
    subjectTemperatureFrom: null,
    subjectTemperatureTo: null,
    validatedTimeFrom: null,
    validatedTimeTo: null,
    validatedByName: null,
    objectPercent: [0, 0],
    objectLeftPercent: [0, 0],
    objectRightPercent: [0, 0],
    objectPresent: false,
    objectNotPresent: false,
    objectLeftSide: false,
    objectLeftChest: false,
    objectLeftFoot: false,
    objectRightSide: false,
    objectRightChest: false,
    objectRightFoot: false,
    objectBag: false,
    objectBagLeftShoulderHigh: false,
    objectBagLeftShoulderLow: false,
    objectBagRightShoulderHigh: false,
    objectBagRightShoulderLow: false,
    objectBagLeftHand: false,
    objectBagRightHand: false,
    objectBagBack: false,
    objectBagFannypack: false,
    objectHead: false,
    objectFront: false,
    objectBack: false
  },
  detailsEventTrainerData: {
    trainer: {
      s3Info: {}
    }
  },
  detailsEventTraining: null,
  detailsSession: {},
  detailsSessionLoading: false,
  detailsEventTestSessionData: {},
  commentsEvent: {
    comments: []
  }
};

const eventListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EVENTS_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.EVENTS_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        filter: {
          ...initialState.filter,
          ...action.payload.data.result.filter
        },
        loading: false
      };
    }

    case actionTypes.SET_EVENT_LIST_DETAILS_EVENT: {
      return {
        ...state,
        detailsEvent: action.payload
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA_PENDING: {
      return {
        ...state,
        detailsEventRawRadarDataLoading: true
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA_FULFILLED: {
      return {
        ...state,
        detailsEventRawRadarData: action.payload.data,
        detailsEventRawRadarDataLoading: false
      };
    }

    case actionTypes.CLEAR_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA: {
      return {
        ...state,
        detailsEventRawRadarData: initialState.detailsEventRawRadarData
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_EVENT_S3_FILES_PENDING: {
      return {
        ...state,
        detailsEventS3Files: initialState.detailsEventS3Files
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_EVENT_S3_FILES_FULFILLED: {
      return {
        ...state,
        detailsEventS3Files: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_EVENT_TRAINER_DATA_PENDING: {
      return {
        ...state,
        detailsEventTrainerData: initialState.detailsEventTrainerData
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_EVENT_TRAINER_DATA_FULFILLED: {
      return {
        ...state,
        detailsEventTrainerData: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_EVENT_TRAINING_PENDING: {
      return {
        ...state,
        detailsEventTraining: initialState.detailsEventTraining
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_EVENT_TRAINING_FULFILLED: {
      return {
        ...state,
        detailsEventTraining: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_SESSION_PENDING: {
      return {
        ...state,
        detailsSession: {},
        detailsSessionLoading: true
      };
    }

    case actionTypes.FIND_EVENT_LIST_DETAILS_SESSION_FULFILLED: {
      return {
        ...state,
        detailsSession: action.payload.data.result,
        detailsSessionLoading: false
      };
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === 'WALK_LIST') {
        result = {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === 'WALK_LIST') {
        result = {
          ...state,
          pageSize: action.payload.pageSize
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === 'WALK_LIST') {
        result = {
          ...state,
          filter: {
            ...initialState.filter,
            search: state.filter.search
          },
          filterItemCount: initialState.filterItemCount
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === 'WALK_LIST'
      ) {
        result = {
          ...state,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.filter,
            ...action.payload.data.result.filter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_EVENT_LIST_COMMENTS_EVENT: {
      return {
        ...state,
        commentsEvent: {
          ...action.payload,
          comments: !action.payload.comments ? [] : action.payload.comments
        }
      };
    }

    case actionTypes.ADD_COMMENT_FULFILLED:
    case actionTypes.SAVE_COMMENT_FULFILLED:
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      const commentEvent = action.payload.data.result;
      const index = state.items.map(e => e.id).indexOf(commentEvent.id);
      const events = state.items.slice();

      if (index > -1) {
        const event = {
          ...events[index]
        };
        event.comments = !action.payload.data.result.comments
          ? []
          : action.payload.data.result.comments;
        events[index] = event;
      }

      return {
        ...state,
        items: events,
        commentsEvent: {
          ...state.commentsEvent,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        },
        detailsEvent: {
          ...state.detailsEvent,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default eventListReducer;
