import ws from '../utils/ws';

/* Actions */

export const findVenueCells = venueId => dispatch =>
  dispatch({
    type: FIND_VENUE_CELLS,
    payload: ws.get(`/venues/${venueId}/cells/`)
  });

export const findVenueCustomerEvents = venueId => dispatch =>
  dispatch({
    type: FIND_VENUE_CUSTOMER_EVENTS,
    payload: ws.get(`/venues/${venueId}/customer_events/`)
  });

export const venuePaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: VENUES_PAGINATED_LIST,
    payload: ws.get(`/venues/page/${pageNumber}/rows/${pageSize}`)
  });

export const venueValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_VENUE_VALUE_UPDATE,
    payload: values
  });

export const venueValueReset = () => dispatch =>
  dispatch({
    type: CREATE_VENUE_VALUE_RESET
  });

export const saveVenue = venue => dispatch =>
  dispatch({
    type: SAVE_VENUE,
    payload: ws.post('/venues/', venue)
  });

export const deleteVenue = venue => dispatch =>
  dispatch({
    type: DELETE_VENUE,
    payload: ws.post(`/venues/${venue.id}/delete`)
  });

export const editVenue = id => dispatch =>
  dispatch({
    type: EDIT_VENUE,
    payload: ws.get(`/venues/${id}`)
  });

export const findVenues = () => dispatch =>
  dispatch({
    type: FIND_VENUES,
    payload: ws.get('/venues/')
  });

export const findVenue = id => dispatch =>
  dispatch({
    type: FIND_VENUE,
    payload: ws.get(`/venues/${id}`)
  });

/* Constants */

export const FIND_VENUE_CELLS = 'FIND_VENUES_CELLS';
export const FIND_VENUE_CELLS_PENDING = 'FIND_VENUES_CELLS_PENDING';
export const FIND_VENUE_CELLS_FULFILLED = 'FIND_VENUES_CELLS_FULFILLED';

export const FIND_VENUE_CUSTOMER_EVENTS = 'FIND_VENUE_CUSTOMER_EVENTS';
export const FIND_VENUE_CUSTOMER_EVENTS_PENDING =
  'FIND_VENUE_CUSTOMER_EVENTS_PENDING';
export const FIND_VENUE_CUSTOMER_EVENTS_FULFILLED =
  'FIND_VENUE_CUSTOMER_EVENTS_FULFILLED';

export const VENUES_PAGINATED_LIST = 'VENUES_PAGINATED_LIST';
export const VENUES_PAGINATED_LIST_PENDING = 'VENUES_PAGINATED_LIST_PENDING';
export const VENUES_PAGINATED_LIST_FULFILLED =
  'VENUES_PAGINATED_LIST_FULFILLED';

export const CREATE_VENUE_VALUE_UPDATE = 'CREATE_VENUES_VALUE_UPDATE';
export const CREATE_VENUE_VALUE_RESET = 'CREATE_VENUE_VALUE_RESET';

export const SAVE_VENUE = 'SAVE_VENUE';
export const SAVE_VENUE_FULFILLED = 'SAVE_VENUE_FULFILLED';

export const DELETE_VENUE = 'DELETE_VENUE';
export const DELETE_VENUE_FULFILLED = 'DELETE_VENUE_FULFILLED';

export const EDIT_VENUE = 'EDIT_VENUE';
export const EDIT_VENUE_FULFILLED = 'EDIT_VENUE_FULFILLED';

export const FIND_VENUES = 'FIND_VENUES';
export const FIND_VENUES_PENDING = 'FIND_VENUES_PENDING';
export const FIND_VENUES_FULFILLED = 'FIND_VENUES_FULFILLED';

export const FIND_VENUE = 'FIND_VENUE';
export const FIND_VENUE_PENDING = 'FIND_VENUE_PENDING';
export const FIND_VENUE_FULFILLED = 'FIND_VENUE_FULFILLED';
