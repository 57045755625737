import ws from '../utils/ws';

/* Actions */

export const setCreateSessionScenario = scenarioId => dispatch =>
  dispatch({
    type: SET_CREATE_SESSION_SCENARIO,
    payload: ws.get('/setup_scenarios/' + scenarioId)
  });

export const createSessionValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_SESSION_VALUE_UPDATE,
    payload: values
  });

export const createSessionSelectCell = cells => dispatch =>
  dispatch({
    type: CREATE_SESSION_SELECT_CELL,
    payload: cells
  });

export const setSessionAdditionalSubjectAreaContents = values => dispatch =>
  dispatch({
    type: SET_SESSION_ADDITIONAL_SUBJECT_AREA_CONTENTS,
    payload: values
  });

export const createSessionValueReset = () => dispatch =>
  dispatch({
    type: CREATE_SESSION_VALUE_RESET
  });

export const editSession = id => dispatch =>
  dispatch({
    type: EDIT_SESSION,
    payload: ws.get('/sessions/' + id)
  });

export const findFirstSessionWalkEntryImage = id => dispatch =>
  dispatch({
    type: FIND_FIRST_SESSION_WALK_ENTRY_IMAGE,
    payload: ws.get('/sessions/' + id + '/first_walk_entry_image')
  });

export const deleteSession = id => dispatch =>
  dispatch({
    type: DELETE_SESSION,
    payload: ws.post('/sessions/delete', { id })
  });

export const saveSession = (
  scenarioSession,
  scenarios,
  additionalItemsSetup
) => dispatch =>
  dispatch({
    type: SAVE_SESSION,
    payload: ws.post('/sessions/', {
      ...scenarioSession,
      scenarioIds: scenarios.map(s => s.id),
      additionalItemsSetup: additionalItemsSetup
    })
  });

export const saveQuickCreateSession = (
  scenarioSession,
  scenarios
) => dispatch =>
  dispatch({
    type: SAVE_QUICK_CREATE_SESSION,
    payload: ws.post('/sessions/quick_create', {
      ...scenarioSession,
      scenarioIds: scenarios.map(s => s.id)
    })
  });

export const findSession = id => dispatch =>
  dispatch({
    type: FIND_SESSION,
    payload: ws.get('/sessions/' + id)
  });

export const findEventsForSession = (
  sessionId,
  modelConfiguration,
  modelSet,
  torsoThreshold
) => dispatch =>
  dispatch({
    type: FIND_EVENTS_FOR_SESSION,
    payload: ws.get(
      `/sessions/${sessionId}/events/` +
        '?modelConfigurationId=' +
        (modelConfiguration && modelConfiguration.id
          ? modelConfiguration.id
          : '') +
        '&modelSetId=' +
        (modelSet && modelSet.id ? modelSet.id : '') +
        '&torsoThreshold=' +
        torsoThreshold
    )
  });

export const findSessionAsWalkedModel = modelName => dispatch =>
  dispatch({
    type: FIND_SESSION_AS_WALKED_MODEL,
    payload: ws.get(`/model_configurations/name/${modelName}`)
  });

export const addSessionListFilterSubjectAreaContent = (
  page,
  subFilterIndex
) => dispatch =>
  dispatch({
    type: ADD_SESSION_FILTER_SUBJECT_AREA_CONTENT,
    payload: { page, subFilterIndex }
  });

export const removeSessionListFilterSubjectAreaContent = (
  page,
  index,
  subFilterIndex
) => dispatch =>
  dispatch({
    type: REMOVE_SESSION_FILTER_SUBJECT_AREA_CONTENT,
    payload: { page, index, subFilterIndex }
  });

export const findSessionListObjectTypes = () => dispatch =>
  dispatch({
    type: FIND_SESSION_LIST_OBJECT_TYPES,
    payload: ws.get('/object_types/')
  });

export const findSessionListObjectModels = () => dispatch =>
  dispatch({
    type: FIND_SESSION_LIST_OBJECT_MODELS,
    payload: ws.get('/object_models/')
  });

export const updateSessionListFilterSubjectAreaContent = (
  page,
  values,
  subFilterIndex
) => dispatch =>
  dispatch({
    type: SESSION_FILTER_SUBJECT_AREA_CONTENT_UPDATE,
    payload: { page, values, subFilterIndex }
  });

export const updateSessionUserWalkStatus = (sessionId, status) => dispatch =>
  dispatch({
    type: UPDATE_SESSION_USER_WALK_STATUS,
    payload: ws.post('/sessions/update_walk_status', {
      sessionId,
      walkStatus: status
    })
  });

export const updateSessionSetupUserWalkStatus = (
  sessionId,
  setupNumber,
  status
) => dispatch =>
  dispatch({
    type: UPDATE_SESSION_SETUP_USER_WALK_STATUS,
    payload: ws.post('/sessions/update_setup_walk_status', {
      sessionId,
      setupNumber,
      walkStatus: status
    })
  });

export const updateSessionSetupAnalyticsStatus = (
  sessionId,
  setupNumber,
  status
) => dispatch =>
  dispatch({
    type: UPDATE_SESSION_SETUP_ANALYTICS_STATUS,
    payload: ws.post(
      `/sessions/${sessionId}/setups/${setupNumber}/analytics_status`,
      {
        status: status
      }
    )
  });

export const updateSessionAnalysisExclusion = (
  sessionIds,
  exclude
) => dispatch =>
  dispatch({
    type: UPDATE_SESSION_ANALYTICS_EXCLUSION,
    payload: ws.post('/sessions/update_analytics_exclusion', {
      sessionIds,
      analysisExcluded: exclude
    })
  });

export const findSessionHashedSettingsComparison = sessionIds => dispatch =>
  dispatch({
    type: FIND_SESSION_HASHED_SETTINGS_COMPARISON,
    payload: ws.post(`/sessions/hashed_settings_comparison`, sessionIds)
  });

export const clearSessionHashedSettingsComparison = () => dispatch =>
  dispatch({
    type: CLEAR_SESSION_HASHED_SETTINGS_COMPARISON
  });

export const setSessionDetailsAreaGroup = areaGroup => dispatch =>
  dispatch({
    type: SET_SESSION_DETAILS_AREA_GROUP,
    payload: areaGroup
  });

export const setSessionDetailsSubModel = subModel => dispatch =>
  dispatch({
    type: SET_SESSION_DETAILS_SUB_MODEL,
    payload: subModel
  });

export const reAssociateSessionEvents = sessionId => dispatch =>
  dispatch({
    type: RE_ASSOCIATE_SESSION_EVENTS,
    payload: ws.post(`/sessions/${sessionId}/events/re_associate`)
  });

/* Action Constants */

export const SET_CREATE_SESSION_SCENARIO = 'SET_CREATE_SESSION_SCENARIO';
export const SET_CREATE_SESSION_SCENARIO_PENDING =
  'SET_CREATE_SESSION_SCENARIO_PENDING';
export const SET_CREATE_SESSION_SCENARIO_FULFILLED =
  'SET_CREATE_SESSION_SCENARIO_FULFILLED';

export const CREATE_SESSION_VALUE_UPDATE = 'CREATE_SESSION_VALUE_UPDATE';

export const CREATE_SESSION_SELECT_CELL = 'CREATE_SESSION_SELECT_CELL';

export const CREATE_SESSION_VALUE_RESET = 'CREATE_SESSION_VALUE_RESET';

export const EDIT_SESSION = 'EDIT_SESSION';
export const EDIT_SESSION_PENDING = 'EDIT_SESSION_PENDING';
export const EDIT_SESSION_FULFILLED = 'EDIT_SESSION_FULFILLED';

export const FIND_FIRST_SESSION_WALK_ENTRY_IMAGE =
  'FIND_FIRST_SESSION_WALK_ENTRY_IMAGE';
export const FIND_FIRST_SESSION_WALK_ENTRY_IMAGE_PENDING =
  'FIND_FIRST_SESSION_WALK_ENTRY_IMAGE_PENDING';
export const FIND_FIRST_SESSION_WALK_ENTRY_IMAGE_FULFILLED =
  'FIND_FIRST_SESSION_WALK_ENTRY_IMAGE_FULFILLED';

export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_PENDING = 'DELETE_SESSION_PENDING';
export const DELETE_SESSION_FULFILLED = 'DELETE_SESSION_FULFILLED';

export const SAVE_SESSION = 'SAVE_SESSION';
export const SAVE_SESSION_PENDING = 'SAVE_SESSION_PENDING';
export const SAVE_SESSION_FULFILLED = 'SAVE_SESSION_FULFILLED';

export const FIND_SESSION = 'FIND_SESSION';
export const FIND_SESSION_PENDING = 'FIND_SESSION_PENDING';
export const FIND_SESSION_FULFILLED = 'FIND_SESSION_FULFILLED';

export const FIND_EVENTS_FOR_SESSION = 'FIND_EVENTS_FOR_SESSION';
export const FIND_EVENTS_FOR_SESSION_PENDING =
  'FIND_EVENTS_FOR_SESSION_PENDING';
export const FIND_EVENTS_FOR_SESSION_FULFILLED =
  'FIND_EVENTS_FOR_SESSION_FULFILLED';

export const FIND_SESSION_AS_WALKED_MODEL = 'FIND_SESSION_AS_WALKED_MODEL';
export const FIND_SESSION_AS_WALKED_MODEL_PENDING =
  'FIND_SESSION_AS_WALKED_MODEL_PENDING';
export const FIND_SESSION_AS_WALKED_MODEL_FULFILLED =
  'FIND_SESSION_AS_WALKED_MODEL_FULFILLED';

export const ADD_SESSION_FILTER_SUBJECT_AREA_CONTENT =
  'ADD_SESSION_FILTER_SUBJECT_AREA_CONTENT';
export const REMOVE_SESSION_FILTER_SUBJECT_AREA_CONTENT =
  'REMOVE_SESSION_FILTER_SUBJECT_AREA_CONTENT';

export const FIND_SESSION_LIST_OBJECT_TYPES = 'FIND_SESSION_LIST_OBJECT_TYPES';
export const FIND_SESSION_LIST_OBJECT_TYPES_FULFILLED =
  'FIND_SESSION_LIST_OBJECT_TYPES_FULFILLED';

export const FIND_SESSION_LIST_OBJECT_MODELS =
  'FIND_SESSION_LIST_OBJECT_MODELS';
export const FIND_SESSION_LIST_OBJECT_MODELS_FULFILLED =
  'FIND_SESSION_LIST_OBJECT_MODELS_FULFILLED';

export const SESSION_FILTER_SUBJECT_AREA_CONTENT_UPDATE =
  'SESSION_FILTER_SUBJECT_AREA_CONTENT_UPDATE';

export const UPDATE_SESSION_USER_WALK_STATUS =
  'UPDATE_SESSION_USER_WALK_STATUS';

export const UPDATE_SESSION_SETUP_USER_WALK_STATUS =
  'UPDATE_SESSION_SETUP_USER_WALK_STATUS';

export const UPDATE_SESSION_SETUP_ANALYTICS_STATUS =
  'UPDATE_SESSION_SETUP_ANALYTICS_STATUS';

export const UPDATE_SESSION_ANALYTICS_EXCLUSION =
  'UPDATE_SESSION_ANALYTICS_EXCLUSION';

export const FIND_SESSION_HASHED_SETTINGS_COMPARISON =
  'FIND_SESSION_HASHED_SETTINGS_COMPARISON';
export const FIND_SESSION_HASHED_SETTINGS_COMPARISON_PENDING =
  'FIND_SESSION_HASHED_SETTINGS_COMPARISON_PENDING';
export const FIND_SESSION_HASHED_SETTINGS_COMPARISON_FULFILLED =
  'FIND_SESSION_HASHED_SETTINGS_COMPARISON_FULFILLED';

export const CLEAR_SESSION_HASHED_SETTINGS_COMPARISON =
  'CLEAR_SESSION_HASHED_SETTINGS_COMPARISON';

export const SAVE_QUICK_CREATE_SESSION = 'SAVE_QUICK_CREATE_SESSION';
export const SAVE_QUICK_CREATE_SESSION_PENDING =
  'SAVE_QUICK_CREATE_SESSION_PENDING';
export const SAVE_QUICK_CREATE_SESSION_FULFILLED =
  'SAVE_QUICK_CREATE_SESSION_FULFILLED';

export const FIND_LATEST_SESSION_WALK = 'FIND_LATEST_SESSION_WALK';
export const FIND_LATEST_SESSION_WALK_PENDING =
  'FIND_LATEST_SESSION_WALK_PENDING';
export const FIND_LATEST_SESSION_WALK_FULFILLED =
  'FIND_LATEST_SESSION_WALK_FULFILLED';
export const FIND_LATEST_SESSION_WALK_REJECTED =
  'FIND_LATEST_SESSION_WALK_REJECTED';

export const SET_SESSION_ADDITIONAL_SUBJECT_AREA_CONTENTS =
  'SET_SESSION_ADDITIONAL_SUBJECT_AREA_CONTENTS';

export const SET_SESSION_DETAILS_AREA_GROUP = 'SET_SESSION_DETAILS_AREA_GROUP';

export const SET_SESSION_DETAILS_SUB_MODEL = 'SET_SESSION_DETAILS_SUB_MODEL';

export const SET_SESSION_DETAILS_ANALYSIS_BIAS =
  'SET_SESSION_DETAILS_ANALYSIS_BIAS';

export const RE_ASSOCIATE_SESSION_EVENTS = 'RE_ASSOCIATE_SESSION_EVENTS';
export const RE_ASSOCIATE_SESSION_EVENTS_PENDING =
  'RE_ASSOCIATE_SESSION_EVENTS_PENDING';
export const RE_ASSOCIATE_SESSION_EVENTS_FULFILLED =
  'RE_ASSOCIATE_SESSION_EVENTS_FULFILLED';
