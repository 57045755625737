import ws from '../utils/ws';
import { ENTITY_TYPES } from 'common/constants';

/* Actions */

export const findValidateCustomerEventEvent = eventId => dispatch =>
  dispatch({
    type: FIND_VALIDATE_CUSTOMER_EVENT_EVENT,
    payload: ws.get(`/events/${eventId}`)
  });

export const findCustomerEventValidationEvents = id => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_VALIDATION_EVENTS,
    payload: ws.get(`/customer_events/${id}/events/validation_events/`)
  });

export const updateCustomerEventEventValidationStatus = (
  customerEventId,
  eventId,
  statusCode
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_VALIDATION_STATUS,
    payload: ws.post(
      `/customer_events/${customerEventId}/events/${eventId}/validation_status`,
      { status: statusCode }
    )
  });

export const updateCustomerEventParticipantGender = (
  eventId,
  gender
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_VALIDATION_STATUS,
    payload: ws.post(`/events/${eventId}/participant_gender`, { gender })
  });

export const updateCustomerEventParticipantClothingType = (
  eventId,
  clothingType
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_VALIDATION_STATUS,
    payload: ws.post(`/events/${eventId}/participant_clothing_type`, {
      clothingType
    })
  });

export const updateCustomerEventParticipantAgeGroup = (
  eventId,
  ageGroup
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_VALIDATION_STATUS,
    payload: ws.post(`/events/${eventId}/participant_age_group`, { ageGroup })
  });

export const validateCustomerEventSubectAreaContentsAdd = setup => dispatch =>
  dispatch({
    type: VALIDATE_CUSTOMER_EVENT_SAC_ADD,
    payload: setup
  });

export const setValidateCustomerEventProcessingEventId = eventId => dispatch =>
  dispatch({
    type: SET_VALIDATION_STATUS_PROCESSING_EVENT_ID,
    payload: eventId
  });

export const setEventPlaybackPaused = paused => dispatch =>
  dispatch({
    type: SET_EVENT_PLAYBACK_PAUSED,
    payload: paused
  });

export const setFilterCell = cell => dispatch =>
  dispatch({
    type: SET_FILTER_CELL,
    payload: cell
  });

export const setEventPlaybackRate = paused => dispatch =>
  dispatch({
    type: SET_EVENT_PLAYBACK_RATE,
    payload: paused
  });

export const setValidateCustomerEventSubjectAreaContentSaved = saved => dispatch =>
  dispatch({
    type: SET_VALIDATE_CUSTOMER_EVENT_SAC_SAVED,
    payload: saved
  });

export const validateCustoemrEventSubjectAreaContentValueUpdate = (
  field,
  value
) => dispatch =>
  dispatch({
    type: VALIDATE_CUSTOMER_EVENT_SAC_VALUE_UPDATE,
    payload: { field, value }
  });

export const findValidateCustomerEventObjectTypes = () => dispatch =>
  dispatch({
    type: FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_TYPES,
    payload: ws.get('/object_types/')
  });

export const findValidateCustomerEventObjectModels = () => dispatch =>
  dispatch({
    type: FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_MODELS,
    payload: ws.get('/object_models/')
  });

/* Constants */

export const SET_VALIDATE_TRAINING_SESSION_SETUP =
  'SET_VALIDATE_TRAINING_SESSION_SETUP';

export const FIND_VALIDATE_CUSTOMER_EVENT_EVENT =
  'FIND_VALIDATE_CUSTOMER_EVENT_EVENT';
export const FIND_VALIDATE_CUSTOMER_EVENT_EVENT_PENDING =
  'FIND_VALIDATE_CUSTOMER_EVENT_EVENT_PENDING';
export const FIND_VALIDATE_CUSTOMER_EVENT_EVENT_FULFILLED =
  'FIND_VALIDATE_CUSTOMER_EVENT_EVENT_FULFILLED';

export const FIND_CUSTOMER_EVENT_VALIDATION_EVENTS =
  'FIND_CUSTOMER_EVENT_VALIDATION_EVENTS';
export const FIND_CUSTOMER_EVENT_VALIDATION_EVENTS_PENDING =
  'FIND_CUSTOMER_EVENT_VALIDATION_EVENTS_PENDING';
export const FIND_CUSTOMER_EVENT_VALIDATION_EVENTS_FULFILLED =
  'FIND_CUSTOMER_EVENT_VALIDATION_EVENTS_FULFILLED';

export const UPDATE_EVENT_VALIDATION_STATUS = 'UPDATE_EVENT_VALIDATION_STATUS';
export const UPDATE_EVENT_VALIDATION_STATUS_PENDING =
  'UPDATE_EVENT_VALIDATION_STATUS_PENDING';
export const UPDATE_EVENT_VALIDATION_STATUS_FULFILLED =
  'UPDATE_EVENT_VALIDATION_STATUS_FULFILLED';

export const UPDATE_SETUP_VALIDATION_STATUS = 'UPDATE_SETUP_VALIDATION_STATUS';
export const UPDATE_SETUP_VALIDATION_STATUS_PENDING =
  'UPDATE_SETUP_VALIDATION_STATUS_PENDING';
export const UPDATE_SETUP_VALIDATION_STATUS_FULFILLED =
  'UPDATE_SETUP_VALIDATION_STATUS_FULFILLED';

export const SET_EVENT_PLAYBACK_PAUSED = 'SET_EVENT_PLAYBACK_PAUSED';

export const SET_FILTER_CELL = 'SET_FILTER_CELL';

export const SET_EVENT_PLAYBACK_RATE = 'SET_EVENT_PLAYBACK_RATE';

export const SET_VALIDATION_STATUS_PROCESSING_EVENT_ID =
  'SET_VALIDATION_STATUS_PROCESSING_EVENT_ID';

export const VALIDATE_CUSTOMER_EVENT_SAC_ADD =
  'VALIDATE_CUSTOMER_EVENT_SAC_ADD';
export const VALIDATE_CUSTOMER_EVENT_SAC_REMOVE =
  'VALIDATE_CUSTOMER_EVENT_SAC_REMOVE';

export const SET_VALIDATE_CUSTOMER_EVENT_SAC_SAVED =
  'SET_VALIDATE_CUSTOMER_EVENT_SAC_SAVED';

export const VALIDATE_CUSTOMER_EVENT_SAC_VALUE_UPDATE =
  'VALIDATE_CUSTOMER_EVENT_SAC_VALUE_UPDATE';

export const FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_TYPES =
  'FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_TYPES';
export const FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_TYPES_PENDING =
  'FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_TYPES_PENDING';
export const FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_TYPES_FULFILLED =
  'FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_TYPES_FULFILLED';

export const FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_MODELS =
  'FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_MODELS';
export const FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_MODELS_PENDING =
  'FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_MODELS_PENDING';
export const FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_MODELS_FULFILLED =
  'FIND_VALIDATE_CUSTOMER_EVENT_OBJECT_MODELS_FULFILLED';
