import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  participantLoading: false,
  participant: {
    createdBy: {},
    defaultSubjectAreaContents: []
  },
  entityPhotos: [],
  sessionsLoading: false,
  sessions: [],
  overview: {},
  overviewLoading: false,
  scenarios: [],
  scenariosLoading: false,
  objectTypes: [],
  objectTypesLoading: false,
  objectOfInterestSubjectAreas: [],
  objectOfInterestSubjectAreasLoading: false
};

const participantDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_PARTICIPANT_PENDING: {
      return {
        ...initialState,
        participantLoading: true
      };
    }

    case actionTypes.FIND_PARTICIPANT_FULFILLED: {
      return {
        ...state,
        participantLoading: false,
        participant: action.payload.data.result
      };
    }

    case actionTypes.CONFIRM_PARTICIPANT_DETAILS_FULFILLED: {
      return {
        ...state,
        participantLoading: false,
        participant: action.payload.data.result
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        participantLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.PARTICIPANT &&
        (entityPhoto.entityId === state.participant.id ||
          entityPhoto.entityTempId === state.participant.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.PARTICIPANT &&
        (entityPhoto.entityId === state.participant.id ||
          entityPhoto.entityTempId === state.participant.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.PARTICIPANT &&
        (entityPhoto.entityId === state.participant.id ||
          entityPhoto.entityTempId === state.participant.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.FIND_PARTICIPANT_TRAINING_OVERVIEW_PENDING: {
      return {
        ...state,
        overview: initialState.overview,
        overviewLoading: true
      };
    }

    case actionTypes.FIND_PARTICIPANT_TRAINING_OVERVIEW_FULFILLED: {
      return {
        ...state,
        overview: action.payload.data,
        overviewLoading: false
      };
    }

    case actionTypes.FIND_PARTICIPANT_TRAINING_SCENARIOS_PENDING: {
      return {
        ...state,
        scenarios: initialState.objectTypes,
        scenariosLoading: true
      };
    }

    case actionTypes.FIND_PARTICIPANT_TRAINING_SCENARIOS_FULFILLED: {
      return {
        ...state,
        scenarios: action.payload.data,
        scenariosLoading: false
      };
    }

    case actionTypes.FIND_PARTICIPANT_TRAINING_OBJECT_TYPES_PENDING: {
      return {
        ...state,
        objectTypes: initialState.objectTypes,
        objectTypesLoading: true
      };
    }

    case actionTypes.FIND_PARTICIPANT_TRAINING_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data,
        objectTypesLoading: false
      };
    }

    case actionTypes.FIND_PARTICIPANT_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_PENDING: {
      return {
        ...state,
        objectOfInterestSubjectAreas: initialState.objectOfInterestSubjectAreas,
        objectOfInterestSubjectAreasLoading: true
      };
    }

    case actionTypes.FIND_PARTICIPANT_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_FULFILLED: {
      return {
        ...state,
        objectOfInterestSubjectAreas: action.payload.data,
        objectOfInterestSubjectAreasLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default participantDetailsReducer;
