import * as actionTypes from 'actions';

const initialState = {
  eventDataSetLoading: false,
  eventsFilterIndex: 0,
  eventDataSet: {
    filters: [],
    createdBy: {}
  },
  eventsPageNumber: 1,
  eventsRowCount: 0,
  eventsLoading: false,
  events: [],
  eventsPageSize: 250,
  eventsDetailsEventS3Files: [],
  eventsDetailsEventTrainerData: {
    trainer: {
      s3Info: {}
    }
  },
  testingAnalysisOverview: {
    loading: false,
    analysisOverview: {},
    setupAnalysesCount: 0,
    eventCount: 0,
    noObjectEventCount: 0,
    locationEventCounts: {},
    subModels: []
  },
  torsoThreshold: 50,
  eventsDetailsEventEvent: null,
  eventsDetailsSession: {},
  eventsDetailsSessionLoading: false,
  commentsEvent: {},
  eventDataSetSummaryLoading: false,
  eventDataSetSummary: null,
  modelConfiguration: null,
  modelSet: null,
  analysisCell: null,
  maleBmiCategoryPercentages: [],
  maleBmiCategoryPercentagesLoading: false,
  femaleBmiCategoryPercentages: [],
  femaleBmiCategoryPercentagesLoading: false,
  maleHeightCategoryPercentages: [],
  maleHeightCategoryPercentagesLoading: false,
  femaleHeightCategoryPercentages: [],
  femaleHeightCategoryPercentagesLoading: false,
  comparisonDataSets: [],
  firstWalkEntryImage: null
};

const eventDataSetDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_EVENT_DATA_SET_PENDING: {
      return {
        ...initialState,
        eventDataSetLoading: true
      };
    }

    case actionTypes.FIND_FIRST_SESSION_WALK_ENTRY_IMAGE_PENDING: {
      return {
        ...state,
        firstWalkEntryImage: initialState.firstWalkEntryImage
      };
    }

    case actionTypes.FIND_FIRST_SESSION_WALK_ENTRY_IMAGE_FULFILLED: {
      return {
        ...state,
        firstWalkEntryImage: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_FULFILLED: {
      return {
        ...state,
        eventDataSetLoading: false,
        eventDataSet: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_SILENT_FULFILLED: {
      return {
        ...state,
        eventDataSetLoading: false,
        eventDataSet: action.payload.data.result
      };
    }

    case actionTypes.CREATE_EVENT_DATA_SET_EVENT_DOWNLOAD_FILE_FULFILLED: {
      return {
        ...state,
        eventDataSetLoading: false,
        eventDataSet: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_SUMMARY_PENDING: {
      return {
        ...state,
        eventDataSetSummary: initialState.eventDataSetSummary,
        eventDataSetSummaryLoading: true
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_SUMMARY_FULFILLED: {
      return {
        ...state,
        eventDataSetSummaryLoading: false,
        eventDataSetSummary: action.payload.data.result
      };
    }

    case actionTypes.EVENT_DATA_SET_EVENTS_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        eventsLoading: true
      };
    }

    case actionTypes.EVENT_DATA_SET_EVENTS_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        events: action.payload.data.result.items,
        eventsRowCount: action.payload.data.result.rowCount,
        eventsPageNumber: action.payload.data.result.pageNumber,
        eventsLoading: false
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_S3_FILES_PENDING: {
      return {
        ...state,
        eventsDetailsEventS3Files: initialState.eventsDetailsEventS3Files
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_S3_FILES_FULFILLED: {
      return {
        ...state,
        eventsDetailsEventS3Files: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_TRAINER_DATA_PENDING: {
      return {
        ...state,
        eventsDetailsEventTrainerData:
          initialState.eventsDetailsEventTrainerData
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_TRAINER_DATA_FULFILLED: {
      return {
        ...state,
        eventsDetailsEventTrainerData: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_EVENT_PENDING: {
      return {
        ...state,
        eventsDetailsEventEvent: initialState.eventsDetailsEventEvent
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_EVENT_FULFILLED: {
      return {
        ...state,
        eventsDetailsEventEvent: action.payload.data.result
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_EVENTS_DETAILS_SESSION_PENDING: {
      return {
        ...state,
        eventsDetailsSession: initialState.eventsDetailsSession,
        eventsDetailsSessionLoading: true
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_EVENTS_DETAILS_SESSION_FULFILLED: {
      return {
        ...state,
        eventsDetailsSession: action.payload.data.result,
        eventsDetailsSessionLoading: false
      };
    }

    case actionTypes.SET_EVENT_DATA_SET_COMMENTS_EVENT: {
      return {
        ...state,
        commentsEvent: {
          ...action.payload,
          comments: !action.payload.comments ? [] : action.payload.comments
        }
      };
    }

    case actionTypes.ADD_COMMENT_FULFILLED:
    case actionTypes.SAVE_COMMENT_FULFILLED:
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      const commentEvent = action.payload.data.result;
      const index = state.events.map(e => e.id).indexOf(commentEvent.id);
      const events = state.events.slice();

      if (index > -1) {
        const event = {
          ...events[index]
        };
        event.comments = !action.payload.data.result.comments
          ? []
          : action.payload.data.result.comments;
        events[index] = event;
      }

      return {
        events: events,
        ...state,
        commentsEvent: {
          ...state.commentsEvent,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        },
        detailsEvent: {
          ...state.detailsEvent,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        }
      };
    }

    case actionTypes.SET_EVENT_DATA_SET_DETAILS_EVENTS_FILTER_INDEX: {
      return {
        ...state,
        eventsFilterIndex: action.payload
      };
    }

    case actionTypes.EVENT_DATA_SET_UPDATED: {
      const dataSet = action.payload.result;

      let result;
      if (dataSet.id === state.eventDataSet.id) {
        result = {
          ...state,
          eventDataSet: {
            ...state.eventDataSet,
            ...action.payload.result
          }
        };
      } else {
        result = state;
      }

      return result;
    }

    case actionTypes.FIND_EVENT_DATA_SET_ANALYSIS_OVERVIEW_PENDING: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...initialState.testingAnalysisOverview,
          loading: true
        }
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_ANALYSIS_OVERVIEW_FULFILLED: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...action.payload.data.result,
          loading: false
        }
      };
    }

    case actionTypes.SET_EVENT_DATA_SET_DETAILS_ANALYSIS_TORSO_THRESHOLD: {
      const { torsoThreshold } = action.payload;

      return {
        ...state,
        torsoThreshold: torsoThreshold
      };
    }

    case actionTypes.SET_EVENT_DATA_SET_DETAILS_MODEL_CONFIGURATION: {
      return {
        ...state,
        modelConfiguration: action.payload,
        modelSet: null
      };
    }

    case actionTypes.SET_EVENT_DATA_SET_DETAILS_MODEL_SET: {
      return {
        ...state,
        modelConfiguration: null,
        modelSet: action.payload
      };
    }

    case actionTypes.SET_EVENT_DATA_SET_DETAILS_ANALYSIS_CELL: {
      return {
        ...state,
        analysisCell: action.payload
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          maleBmiCategoryPercentages: []
        },
        maleBmiCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_FULFILLED: {
      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          maleBmiCategoryPercentages: action.payload.data.result
        },
        maleBmiCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          femaleBmiCategoryPercentages: []
        },
        femaleBmiCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_FULFILLED: {
      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          femaleBmiCategoryPercentages: action.payload.data.result
        },
        femaleBmiCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          maleHeightCategoryPercentages: []
        },
        maleHeightCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED: {
      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          maleHeightCategoryPercentages: action.payload.data.result
        },
        maleHeightCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          femaleHeightCategoryPercentages: []
        },
        femaleHeightCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED: {
      return {
        ...state,
        eventDataSet: {
          ...state.eventDataSet,
          femaleHeightCategoryPercentages: action.payload.data.result
        },
        femaleHeightCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_COMPARISON_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        maleBmiCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_COMPARISON_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_FULFILLED: {
      const comparisonDataSets = state.comparisonDataSets.slice();
      comparisonDataSets[comparisonDataSets.length - 1] = {
        ...state.comparisonDataSets[state.comparisonDataSets.length - 1],
        maleBmiCategoryPercentages: action.payload.data.result
      };

      return {
        ...state,
        comparisonDataSets: comparisonDataSets,
        maleBmiCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_COMPARISON_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        femaleBmiCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_COMPARISON_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_FULFILLED: {
      const comparisonDataSets = state.comparisonDataSets.slice();
      comparisonDataSets[comparisonDataSets.length - 1] = {
        ...state.comparisonDataSets[state.comparisonDataSets.length - 1],
        femaleBmiCategoryPercentages: action.payload.data.result
      };

      return {
        ...state,
        comparisonDataSets: comparisonDataSets,
        femaleBmiCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_COMPARISON_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        maleHeightCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_COMPARISON_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED: {
      const comparisonDataSets = state.comparisonDataSets.slice();
      comparisonDataSets[comparisonDataSets.length - 1] = {
        ...state.comparisonDataSets[state.comparisonDataSets.length - 1],
        maleHeightCategoryPercentages: action.payload.data.result
      };

      return {
        ...state,
        comparisonDataSets: comparisonDataSets,
        maleHeightCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_COMPARISON_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        femaleHeightCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_COMPARISON_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED: {
      const comparisonDataSets = state.comparisonDataSets.slice();
      comparisonDataSets[comparisonDataSets.length - 1] = {
        ...state.comparisonDataSets[state.comparisonDataSets.length - 1],
        femaleHeightCategoryPercentages: action.payload.data.result
      };

      return {
        ...state,
        comparisonDataSets: comparisonDataSets,
        femaleHeightCategoryPercentagesLoading: false
      };
    }

    case actionTypes.ADD_DATA_SET_REPORTS_COMPARISON_DATA_SET: {
      return {
        ...state,
        comparisonDataSets: [...state.comparisonDataSets, action.payload]
      };
    }

    case actionTypes.REMOVE_DATA_SET_REPORTS_COMPARISON_DATA_SET: {
      const dataSet = action.payload;
      const index = state.comparisonDataSets
        .map(dataSet => dataSet.id)
        .indexOf(dataSet.id);

      const comparisonDataSets = state.comparisonDataSets.slice();
      if (index > -1) {
        comparisonDataSets.splice(index, 1);
      }

      return {
        ...state,
        comparisonDataSets: comparisonDataSets
      };
    }

    case actionTypes.UPDATE_EVENT_VALIDATION_STATUS_FULFILLED:
    case actionTypes.UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER_FULFILLED:
    case actionTypes.TOGGLE_EVENT_SPECIAL_INTEREST_FULFILLED:
    case actionTypes.SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED:
    case actionTypes.DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED:
    case actionTypes.UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE_FULFILLED: {
      const event = action.payload.data.result;
      const index = state.events.map(e => e.id).indexOf(event.id);
      const events = state.events.slice();

      if (index > -1) {
        events[index] = event;
      }

      return {
        ...state,
        events: events
      };
    }

    default: {
      return state;
    }
  }
};

export default eventDataSetDetailsReducer;
