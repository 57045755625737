import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { EventSettings } from 'components';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {},
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

const CreateCheckListItemGroup = props => {
  const {
    open,
    checkListItemGroup,
    validationMessages,
    onCancel,
    onDelete,
    onSave,
    onFieldChange
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    onCancel();
  };

  const handleValueChange = (field, value) => {
    onFieldChange(field, value);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader
          title={`${
            checkListItemGroup.guid ? 'Edit' : 'Add'
          } Check List Item Group`}
        />
        <Divider />
        <CardContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Section Number"
                error={validationMessages.hasOwnProperty('sectionNumber')}
                value={
                  checkListItemGroup.sectionNumber
                    ? checkListItemGroup.sectionNumber
                    : ''
                }
                helperText={validationMessages.sectionNumber}
                variant="outlined"
                onChange={e =>
                  handleValueChange('sectionNumber', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                error={validationMessages.hasOwnProperty('name')}
                value={checkListItemGroup.name ? checkListItemGroup.name : ''}
                helperText={validationMessages.name}
                variant="outlined"
                onChange={e => handleValueChange('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notes"
                error={validationMessages.hasOwnProperty('notes')}
                value={checkListItemGroup.notes ? checkListItemGroup.notes : ''}
                helperText={validationMessages.notes}
                variant="outlined"
                onChange={e => handleValueChange('notes', e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          {!checkListItemGroup.creationParentGuid &&
            checkListItemGroup.parentGuid && (
              <Button
                className={classes.deleteButton}
                onClick={onDelete}
                variant="contained">
                Delete
              </Button>
            )}
          <Button color="primary" onClick={onSave} variant="contained">
            Save
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CreateCheckListItemGroup.defaultProps = {
  open: false
};

export default CreateCheckListItemGroup;
