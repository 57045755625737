import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  Tooltip
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  modelName: {
    '& span': {
      maxWidth: 260,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block'
    }
  },
  link: {
    display: 'block',
    maxWidth: 300,
    width: 300,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ModelConfigurationDetails = props => {
  const { onClose, modelConfiguration } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!modelConfiguration) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={modelConfiguration != null}>
      <Card className={classes.root}>
        <CardHeader title="Model Configuration" />
        <Divider />
        <CardContent className={classes.content}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{modelConfiguration.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Alias</TableCell>
                <TableCell>{modelConfiguration.alias}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Documentation</TableCell>
                <TableCell>
                  {modelConfiguration.documentationLink && (
                    <a
                      href={modelConfiguration.documentationLink}
                      className={classes.link}
                      target="_blank">
                      {modelConfiguration.documentationLink}
                    </a>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Preset Description</TableCell>
                <TableCell>{modelConfiguration.presetDescription}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Model File Name</TableCell>
                <TableCell className={classes.modelName}>
                  {modelConfiguration.modelFileName && (
                    <Tooltip
                      title={modelConfiguration.modelFileName}
                      placement="top">
                      <span>{modelConfiguration.modelFileName}</span>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Deprecated Date</TableCell>
                <TableCell>
                  {modelConfiguration.deprecatedTime &&
                    moment(modelConfiguration.deprecatedTime).format(
                      'MMM Do YYYY, h:mm:ss a'
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Model Configuration Id</TableCell>
                <TableCell>{modelConfiguration.id}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ModelConfigurationDetails.displayName = 'ModelConfigurationDetails';

ModelConfigurationDetails.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

ModelConfigurationDetails.defaultProps = {
  open: false
};

export default ModelConfigurationDetails;
