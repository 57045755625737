/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { colors, Typography, Divider, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ObjectOfInterestIcon from '@material-ui/icons/Warning';
import { SUBJECT_AREAS, POCKET_LOCATIONS } from 'common/constants';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16
    // fontSize: 12
  },
  midItem: {
    marginBottom: theme.spacing(2)
  },
  overrideValue: {
    color: colors.deepPurple[600]
  },
  objectOfInterest: {
    color: colors.blue[600],
    fontSize: 16,
    marginLeft: theme.spacing(1)
  },
  subjectAreaContent: {
    borderRadius: 16,
    backgroundColor: colors.blueGrey[50],
    paddingLeft: 10,
    paddingRight: 10,
    height: 32,
    display: 'inline-flex',
    margin: 8,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingLeft: 12,
    paddingRight: 12
  },
  subjectAreaContentsContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  deleteButton: {
    color: colors.grey[600],
    marginLeft: theme.spacing(1),
    width: 15,
    height: 15,
    cursor: 'pointer'
  }
}));

const SubjectAreaContents = props => {
  const {
    subjectArea,
    subjectAreaContents,
    onDelete,
    overridden,
    midItem
  } = props;
  const classes = useStyles();

  const createDescription = subjectAreaContent => {
    let description = '';
    if (subjectAreaContent.objectType) {
      description += subjectAreaContent.objectType.name;
      if (subjectAreaContent.objectModel) {
        description += '/';
      }
    }
    if (subjectAreaContent.sessionSpecificObjectOfInterest) {
      description += 'Session Specific Object of Interest';
    }
    if (subjectAreaContent.objectModel) {
      description += subjectAreaContent.objectModel.name;
    }
    if (subjectAreaContent.pocketLocation) {
      description +=
        ' (' + POCKET_LOCATIONS[subjectAreaContent.pocketLocation] + ' Pocket)';
    }

    return description;
  };

  const hasContent = subjectAreaContent => {
    return (
      subjectAreaContent.objectType ||
      subjectAreaContent.objectModel ||
      subjectAreaContent.pocketLocation ||
      subjectAreaContent.sessionSpecificObjectOfInterest
    );
  };

  return (
    <Card key={subjectArea} className={midItem ? classes.midItem : ''}>
      <div className={classes.title}>
        <Typography variant="h6">{SUBJECT_AREAS[subjectArea]}</Typography>
      </div>
      <Divider />
      <span className={classes.subjectAreaContentsContainer}>
        {subjectAreaContents.map(
          (subjectAreaContent, i) =>
            hasContent(subjectAreaContent) && (
              <span
                key={i}
                className={`${classes.subjectAreaContent} ${
                  overridden ? classes.overrideValue : ''
                }`}>
                <Typography variant="body1">
                  {createDescription(subjectAreaContent)}
                </Typography>
                {(subjectAreaContent.objectOfInterestPresent ||
                  subjectAreaContent?.objectType?.category === 'GUN' ||
                    subjectAreaContent?.objectType?.category === 'KNIFE' ||
                  subjectAreaContent.sessionSpecificObjectOfInterest) && (
                  <ObjectOfInterestIcon className={classes.objectOfInterest} />
                )}
                {onDelete && (
                  <CancelIcon
                    className={classes.deleteButton}
                    onClick={() => onDelete(i)}
                  />
                )}
              </span>
            )
        )}
      </span>
    </Card>
  );
};

SubjectAreaContents.propTypes = {
  subjectAreaContents: PropTypes.array
};

export default SubjectAreaContents;
