import React from 'react';
import {
  Modal,
  Card,
  CardContent,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 400,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    '& p': {
      textAlign: 'center'
    }
  },
  message: {
    marginBottom: theme.spacing(2)
  }
}));

const ProcessingModal = props => {
  const classes = useStyles();

  const { open, message } = props;

  return (
    <Modal open={open}>
      <Card className={classes.root}>
        <CardContent>
          <Typography component="p" className={classes.message}>
            {message}
          </Typography>
          <LinearProgress />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ProcessingModal;
