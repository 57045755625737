export const SELECT_CELL_SHARED_IDENTIFIER = 'SELECT_CELL';
export const DESELECT_CELL_SHARED_IDENTIFIER = 'DESELECT_CELL';
export const SELECT_ALL_CELL_SHARED_IDENTIFIERS = 'SELECT_ALL_CELLS';
export const DESELECT_ALL_CELL_SHARED_IDENTIFIERS = 'DESELECT_ALL_CELLS';

export const selectCellSharedIdentifier = cellSharedId => ({
  type: SELECT_CELL_SHARED_IDENTIFIER,
  payload: cellSharedId
});

export const deselectCellSharedIdentifier = cellSharedId => ({
  type: DESELECT_CELL_SHARED_IDENTIFIER,
  payload: cellSharedId
});

export const selectAllCellSharedIdentifiers = cellSharedIds => ({
  type: SELECT_ALL_CELL_SHARED_IDENTIFIERS,
  payload: cellSharedIds
});

export const deselectAllCellSharedIdentifiers = () => ({
  type: DESELECT_ALL_CELL_SHARED_IDENTIFIERS
});
