/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Typography,
  Divider,
  Button,
  colors,
  Link,
  Checkbox
} from '@material-ui/core';
import { CHART_COLORS, ENTITY_TYPE_DETAILS_URIS } from 'common/constants';
import { NumberFormat } from 'components';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(theme => ({
  container: {},
  filterListItem: {
    borderLeftWidth: 4,
    borderLeftStyle: 'solid'
  },
  filterHeader: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  filter: {
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  filterLabel: {
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: 0.33,
    width: 70
  },
  filterActions: {
    '& button': {
      marginLeft: theme.spacing(1)
    },
    marginLeft: 'auto'
  },
  emptyFilter: {
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.blueGrey[50],
    backgroundColor: 'none',
    paddingLeft: 10,
    paddingRight: 10,
    height: 32,
    display: 'inline-flex',
    marginLeft: 0,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingLeft: 12,
    paddingRight: 12
  },
  filterItemContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  filterItem: {
    borderRadius: 16,
    backgroundColor: colors.blueGrey[50],
    paddingLeft: 10,
    paddingRight: 10,
    height: 32,
    display: 'inline-flex',
    marginLeft: 0,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingLeft: 12,
    paddingRight: 12,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  emptyFilterItem: {
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.blueGrey[50],
    backgroundColor: 'none',
    paddingLeft: 10,
    paddingRight: 10,
    height: 32,
    display: 'inline-flex',
    marginLeft: 0,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingLeft: 12,
    paddingRight: 12
  },
  field: {
    marginRight: theme.spacing(1)
  },
  value: {
    fontSize: 11
  },
  warningLabel: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.grey[300],
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
    marginRight: theme.spacing(1)
  },
  warnings: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  warningInfoIcon: {
    fontSize: 16,
    marginBottom: -3,
    color: colors.blue[600],
    marginRight: theme.spacing(0.5)
  },
  warningTypography: {
    display: 'inline-block'
  },
  entityLink: {
    fontSize: 11
  },
  checkBox: {
    marginRight: theme.spacing(2),
    padding: 0
  }
}));

const FilterList = props => {
  const {
    filters,
    onEditFilter,
    onRemoveFilter,
    readOnly,
    hideEmpty,
    onToggleFilter
  } = props;

  const classes = useStyles();

  return (
    <Card className={classes.container}>
      {filters
        .filter(f => !hideEmpty || f.containsFilters)
        .map((filter, i) => (
          <div
            key={i}
            className={classes.filterListItem}
            style={{ borderLeftColor: CHART_COLORS[i] }}>
            <div className={classes.filterHeader}>
              {onToggleFilter && (
                <Checkbox
                  className={classes.checkBox}
                  checked={filter.selected}
                  color="primary"
                  onChange={(_, value) => onToggleFilter(i, value)}
                />
              )}
              <Typography
                className={classes.filterLabel}
                color="textPrimary"
                variant="body2">
                Filter {i + 1}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {filter.containsFilters && (
                  <>
                    <NumberFormat value={filter.availableEventCount} />{' '}
                    Available Walk{filter.availableEventCount > 1 ? 's' : ''}
                    {filter.selectedEventCount !== null &&
                      filter.selectedEventCount !== undefined && (
                        <>
                          , Limited To{' '}
                          <NumberFormat
                            value={Number(filter.selectedEventCount)}
                          />
                        </>
                      )}
                  </>
                )}
              </Typography>
            </div>
            {filter.eventDataSetWarnings &&
              filter.eventDataSetWarnings.length > 0 && (
                <div className={classes.warnings}>
                  {filter.eventDataSetWarnings.map((warning, i) => (
                    <span key={i} className={classes.warningLabel}>
                      <InfoIcon className={classes.warningInfoIcon} />
                      <Typography
                        className={classes.warningTypography}
                        color="textSecondary"
                        variant="body2">
                        {warning}
                      </Typography>
                    </span>
                  ))}
                </div>
              )}
            <div className={classes.filter}>
              <span className={classes.filterItemContainer}>
                {!filter.containsFilters && (
                  <span className={classes.emptyFilterItem}>
                    <Typography className={classes.value} component="p">
                      No Filters Set
                    </Typography>
                  </span>
                )}
                {filter.filterItemDescriptions && (
                  <>
                    {filter.filterItemDescriptions.map(
                      (filterItemDescription, i) => (
                        <span key={i} className={classes.filterItem}>
                          <Typography
                            className={classes.field}
                            component="p"
                            variant="overline">
                            {filterItemDescription.filterItem}
                          </Typography>
                          {filterItemDescription.entityType && (
                            <Link
                              className={classes.entityLink}
                              target="_blank"
                              color="inherit"
                              href={`/${
                                ENTITY_TYPE_DETAILS_URIS[
                                  filterItemDescription.entityType
                                ]
                              }/${filterItemDescription.entityId}/`}
                              variant="h6">
                              {filterItemDescription.value}
                            </Link>
                          )}
                          {!filterItemDescription.entityType && (
                            <Typography className={classes.value} component="p">
                              {filterItemDescription.value}
                            </Typography>
                          )}
                        </span>
                      )
                    )}
                  </>
                )}
              </span>

              {!readOnly && (
                <div className={classes.filterActions}>
                  {onEditFilter && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => onEditFilter(i)}
                      variant="outlined">
                      Edit Filter
                    </Button>
                  )}
                  {onRemoveFilter && (
                    <Button
                      disabled={filters.length < 2}
                      size="small"
                      color="primary"
                      onClick={() => onRemoveFilter(i)}
                      variant="outlined">
                      Remove Filter
                    </Button>
                  )}
                </div>
              )}
            </div>

            {filters.length > 1 && i < filters.length && <Divider />}
          </div>
        ))}
    </Card>
  );
};

FilterList.propTypes = {
  filter: PropTypes.object
};

export default FilterList;
