/* Testing */
export const TEST_DASHBOARD_VIEW = 'TEST_DASHBOARD_VIEW';

export const TEST_SCENARIOS_VIEW = 'TEST_SCENARIOS_VIEW';
export const TEST_SCENARIOS_CREATE = 'TEST_SCENARIOS_CREATE';
export const TEST_SCENARIOS_MODIFY = 'TEST_SCENARIOS_MODIFY';
export const TEST_SCENARIOS_DELETE = 'TEST_SCENARIOS_DELETE';

export const TEST_SESSIONS_VIEW = 'TEST_SESSIONS_VIEW';
export const TEST_SESSIONS_CREATE = 'TEST_SESSIONS_CREATE';
export const TEST_SESSIONS_MODIFY = 'TEST_SESSIONS_MODIFY';
export const TEST_SESSIONS_DELETE = 'TEST_SESSIONS_DELETE';

export const TEST_SESSION_GROUPS_VIEW = 'TEST_SESSION_GROUPS_VIEW';
export const TEST_SESSION_GROUPS_CREATE = 'TEST_SESSION_GROUPS_CREATE';
export const TEST_SESSION_GROUPS_MODIFY = 'TEST_SESSION_GROUPS_MODIFY';
export const TEST_SESSION_GROUPS_DELETE = 'TEST_SESSION_GROUPS_DELETE';

export const TEST_SESSION_ANALYSIS_VIEW = 'TEST_SESSION_ANALYSIS_VIEW';

/* Training */
export const TRAINING_DASHBOARD_VIEW = 'TRAINING_DASHBOARD_VIEW';

export const TRAINING_SCENARIOS_VIEW = 'TRAINING_SCENARIOS_VIEW';
export const TRAINING_SCENARIOS_CREATE = 'TRAINING_SCENARIOS_CREATE';
export const TRAINING_SCENARIOS_MODIFY = 'TRAINING_SCENARIOS_MODIFY';
export const TRAINING_SCENARIOS_DELETE = 'TRAINING_SCENARIOS_DELETE';

export const TRAINING_SESSIONS_VIEW = 'TRAINING_SESSIONS_VIEW';
export const TRAINING_SESSIONS_CREATE = 'TRAINING_SESSIONS_CREATE';
export const TRAINING_SESSIONS_MODIFY = 'TRAINING_SESSIONS_MODIFY';
export const TRAINING_SESSIONS_DELETE = 'TRAINING_SESSIONS_DELETE';
export const TRAINING_SESSIONS_VALIDATE = 'TRAINING_SESSIONS_VALIDATE';

export const EVENT_DATA_SETS_VIEW = 'EVENT_DATA_SETS_VIEW';
export const EVENT_DATA_SETS_CREATE = 'EVENT_DATA_SETS_CREATE';
export const EVENT_DATA_SETS_MODIFY = 'EVENT_DATA_SETS_MODIFY';
export const EVENT_DATA_SETS_DELETE = 'EVENT_DATA_SETS_DELETE';
export const EVENT_DATA_SETS_VALIDATE = 'EVENT_DATA_SETS_VALIDATE';

export const TRAINING_COMPATIBILITY_GROUPS_VIEW =
  'TRAINING_COMPATIBILITY_GROUPS_VIEW';
export const TRAINING_COMPATIBILITY_GROUPS_CREATE =
  'TRAINING_COMPATIBILITY_GROUPS_CREATE';
export const TRAINING_COMPATIBILITY_GROUPS_MODIFY =
  'TRAINING_COMPATIBILITY_GROUPS_MODIFY';
export const TRAINING_COMPATIBILITY_GROUPS_DELETE =
  'TRAINING_COMPATIBILITY_GROUPS_DELETE';

/* Customer Events */
export const CUSTOMER_EVENTS_VIEW = 'CUSTOMER_EVENTS_VIEW';
export const CUSTOMER_EVENTS_CREATE = 'CUSTOMER_EVENTS_CREATE';
export const CUSTOMER_EVENTS_MODIFY = 'CUSTOMER_EVENTS_MODIFY';
export const CUSTOMER_EVENTS_DELETE = 'CUSTOMER_EVENTS_DELETE';
export const CUSTOMER_EVENTS_VALIDATE = 'CUSTOMER_EVENTS_VALIDATE';

/* Walks */
export const CELL_WALKS_VIEW = 'CELL_WALKS_VIEW';
export const CELL_WALKS_MODIFY = 'CELL_WALKS_MODIFY';

/* Deployment */
export const CELLS_VIEW = 'CELLS_VIEW';
export const CELLS_CREATE = 'CELLS_CREATE';
export const CELLS_MODIFY = 'CELLS_MODIFY';
export const CELLS_DELETE = 'CELLS_DELETE';
export const CELLS_CONFIG_UPDATE = 'CELLS_CONFIG_UPDATE';

export const CELL_LOG_ENTRIES_VIEW = 'CELL_LOG_ENTRIES_VIEW';
export const CELL_LOG_ENTRIES_CREATE = 'CELL_LOG_ENTRIES_CREATE';
export const CELL_LOG_ENTRIES_MODIFY = 'CELL_LOG_ENTRIES_MODIFY';
export const CELL_LOG_ENTRIES_DELETE = 'CELL_LOG_ENTRIES_DELETE';

export const CELL_BUILD_DEFINITIONS_VIEW = 'CELL_BUILD_DEFINITIONS_VIEW';
export const CELL_BUILD_DEFINITIONS_CREATE = 'CELL_BUILD_DEFINITIONS_CREATE';
export const CELL_BUILD_DEFINITIONS_MODIFY = 'CELL_BUILD_DEFINITIONS_MODIFY';
export const CELL_BUILD_DEFINITIONS_DELETE = 'CELL_BUILD_DEFINITIONS_DELETE';

export const CELL_CHECK_LIST_DEFINITIONS_VIEW =
  'CELL_CHECK_LIST_DEFINITIONS_VIEW';
export const CELL_CHECK_LIST_DEFINITIONS_CREATE =
  'CELL_CHECK_LIST_DEFINITIONS_CREATE';
export const CELL_CHECK_LIST_DEFINITIONS_MODIFY =
  'CELL_CHECK_LIST_DEFINITIONS_MODIFY';
export const CELL_CHECK_LIST_DEFINITIONS_DELETE =
  'CELL_CHECK_LIST_DEFINITIONS_DELETE';

export const CELL_BUILDS_VIEW = 'CELLSCELL_BUILDS_VIEW_VIEW';
export const CELL_BUILDS_CREATE = 'CELL_BUILDS_CREATE';
export const CELL_BUILDS_MODIFY = 'CELL_BUILDS_MODIFY';
export const CELL_BUILDS_DELETE = 'CELL_BUILDS_DELETE';

export const CELL_MODEL_VERSIONS_VIEW = 'CELL_MODEL_VERSIONS_VIEW';
export const CELL_MODEL_VERSIONS_CREATE = 'CELL_MODEL_VERSIONS_CREATE';
export const CELL_MODEL_VERSIONS_MODIFY = 'CELL_MODEL_VERSIONS_MODIFY';
export const CELL_MODEL_VERSIONS_DELETE = 'CELL_MODEL_VERSIONS_DELETE';

export const RADARS_VIEW = 'RADARS_VIEW';
export const RADARS_CREATE = 'RADARS_CREATE';
export const RADARS_MODIFY = 'RADARS_MODIFY';
export const RADARS_DELETE = 'RADARS_DELETE';

export const MASTER_VERSIONS_VIEW = 'MASTER_VERSIONS_VIEW';
export const MASTER_VERSIONS_CREATE = 'MASTER_VERSIONS_CREATE';
export const MASTER_VERSIONS_MODIFY = 'MASTER_VERSIONS_MODIFY';
export const MASTER_VERSIONS_DELETE = 'MASTER_VERSIONS_DELETE';

export const CELL_COMPONENTS_VIEW = 'CELL_COMPONENTS_VIEW';
export const CELL_COMPONENTS_CREATE = 'CELL_COMPONENTS_CREATE';
export const CELL_COMPONENTS_MODIFY = 'CELL_COMPONENTS_MODIFY';
export const CELL_COMPONENTS_DELETE = 'CELL_COMPONENTS_DELETE';

export const CELL_ALERTS_ACKNOWLEDGE = 'CELL_ALERTS_ACKNOWLEDGE';

export const SOFTWARE_PACKAGES_VIEW = 'SOFTWARE_PACKAGES_VIEW';
export const SOFTWARE_PACKAGES_CREATE = 'SOFTWARE_PACKAGES_CREATE';
export const SOFTWARE_PACKAGES_MODIFY = 'SOFTWARE_PACKAGES_MODIFY';
export const SOFTWARE_PACKAGES_DELETE = 'SOFTWARE_PACKAGES_DELETE';

export const TEST_RESULT_FILES_VIEW = 'TEST_RESULT_FILES_VIEW';
export const TEST_RESULT_FILES_CREATE = 'TEST_RESULT_FILES_CREATE';
export const TEST_RESULT_FILES_MODIFY = 'TEST_RESULT_FILES_MODIFY';
export const TEST_RESULT_FILES_DELETE = 'TEST_RESULT_FILES_DELETE';

/* Reference Data */
export const PARTICIPANTS_VIEW = 'PARTICIPANTS_VIEW';
export const PARTICIPANTS_CREATE = 'PARTICIPANTS_CREATE';
export const PARTICIPANTS_MODIFY = 'PARTICIPANTS_MODIFY';
export const PARTICIPANTS_DELETE = 'PARTICIPANTS_DELETE';

export const PORTAL_USERS_VIEW = 'PORTAL_USERS_VIEW';
export const PORTAL_USERS_CREATE = 'PORTAL_USERS_CREATE';
export const PORTAL_USERS_MODIFY = 'PORTAL_USERS_MODIFY';
export const PORTAL_USERS_DELETE = 'PORTAL_USERS_DELETE';

export const VENUES_VIEW = 'VENUES_VIEW';
export const VENUES_CREATE = 'VENUES_CREATE';
export const VENUES_MODIFY = 'VENUES_MODIFY';
export const VENUES_DELETE = 'VENUES_DELETE';

export const SHORT_URLS_VIEW = 'SHORT_URLS_VIEW';
export const SHORT_URLS_CREATE = 'SHORT_URLS_CREATE';
export const SHORT_URLS_MODIFY = 'SHORT_URLS_MODIFY';
export const SHORT_URLS_DELETE = 'SHORT_URLS_DELETE';

export const MODEL_CONFIGURATIONS_VIEW = 'MODEL_CONFIGURATIONS_VIEW';
export const MODEL_CONFIGURATIONS_CREATE = 'MODEL_CONFIGURATIONS_CREATE';
export const MODEL_CONFIGURATIONS_MODIFY = 'MODEL_CONFIGURATIONS_MODIFY';
export const MODEL_CONFIGURATIONS_DELETE = 'MODEL_CONFIGURATIONS_DELETE';

export const MODEL_SETS_VIEW = 'MODEL_SETS_VIEW';
export const MODEL_SETS_CREATE = 'MODEL_SETS_CREATE';
export const MODEL_SETS_MODIFY = 'MODEL_SETS_MODIFY';
export const MODEL_SETS_DELETE = 'MODEL_SETS_DELETE';

export const EVENT_MODEL_RUN_REQUESTS_VIEW = 'EVENT_MODEL_RUN_REQUESTS_VIEW';

export const EVENT_MODEL_WORKERS_VIEW = 'EVENT_MODEL_WORKERS_VIEW';
export const EVENT_MODEL_WORKERS_CREATE = 'EVENT_MODEL_WORKERS_CREATE';
export const EVENT_MODEL_WORKERS_MODIFY = 'EVENT_MODEL_WORKERS_MODIFY';
export const EVENT_MODEL_WORKERS_DELETE = 'EVENT_MODEL_WORKERS_DELETE';

export const MODEL_INFO_RETRIEVALS_VIEW = 'MODEL_INFO_RETRIEVALS_VIEW';

export const USER_MANUAL_VIEW = 'USER_MANUAL_VIEW';
export const USER_MANUAL_UPLOAD = 'USER_MANUAL_UPLOAD';
export const USER_MANUAL_DELETE = 'USER_MANUAL_DELETE';

/* Admin */
export const CUSTOMERS_VIEW = 'CUSTOMERS_VIEW';
export const CUSTOMERS_CREATE = 'CUSTOMERS_CREATE';
export const CUSTOMERS_MODIFY = 'CUSTOMERS_MODIFY';
export const CUSTOMERS_DELETE = 'CUSTOMERS_DELETE';

export const USERS_VIEW = 'USERS_VIEW';
export const USERS_CREATE = 'USERS_CREATE';
export const USERS_MODIFY = 'USERS_MODIFY';
export const USERS_DELETE = 'USERS_DELETE';
