import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import {
  cellVenueInstallationValueUpdate,
  cellVenueInstallationValueReset,
  saveCellVenueInstallation,
  deleteCellVenueInstallation,
  setCreateCellVenueInstallationOpen,
  findCell,
  setConfirmDeleteCellVenueInstallationOpen,
  findEntityPhotos
} from 'actions';
import { ConfirmDelete } from 'components';
import { ENTITY_TYPES } from 'common/constants';
import dateUtils from 'utils/dateUtils';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const CreateCellVenueInstallation = props => {
  const { cell, venues, customers, className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    saved,
    deleted,
    values,
    validationMessages,
    deleteMessage,
    openConfirmDelete,
    loading,
    open
  } = useSelector(state => state.cellVenueInstallationCreate);

  const availableVenues = venues.filter(venue => {
    return (
      values.customer &&
      venue.customer &&
      venue.customer.id === values.customer.id
    );
  });

  const handleClose = () => {
    dispatch(cellVenueInstallationValueReset());
    dispatch(setCreateCellVenueInstallationOpen(false));
  };

  const handleFieldChange = (field, value) => {
    dispatch(
      cellVenueInstallationValueUpdate(values => ({
        ...values,
        [field]: value
      }))
    );
  };

  const handleDateChange = (field, date) => {
    dispatch(
      cellVenueInstallationValueUpdate(values => ({
        ...values,
        [field]: dateUtils.getUTCEpoch(date)
      }))
    );
  };

  useEffect(() => {
    if (saved || deleted) {
      dispatch(findCell(cell.id));
      dispatch(findEntityPhotos(ENTITY_TYPES.CELL, cell.id));
      dispatch(cellVenueInstallationValueReset());
      dispatch(setCreateCellVenueInstallationOpen(false));
    }
  }, [saved, deleted]);

  const handleCreateVenueInstallation = () => {
    dispatch(saveCellVenueInstallation(values, cell));
  };

  const handleDeleteVenueInstallation = () => {
    dispatch(deleteCellVenueInstallation(cell, values.guid));
  };

  const handleConfirmDeleteVenueInstallation = () => {
    dispatch(setConfirmDeleteCellVenueInstallationOpen(true));
  };

  const handleConfirmDeleteClose = () => {
    dispatch(setConfirmDeleteCellVenueInstallationOpen(false));
  };

  if (!open || loading) {
    return null;
  }

  const create = !values.guid;

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardContent>
            <Typography align="center" gutterBottom variant="h3">
              {create ? 'New' : 'Edit'} Venue Installation
            </Typography>

            {cell && (
              <Typography align="center" gutterBottom variant="h4">
                {cell.name}
              </Typography>
            )}

            <Grid container className={classes.container} spacing={2}>
              <Grid item xs={12}>
                <div className={classes.formGroup}>
                  <TextField
                    required
                    fullWidth
                    label="Customer"
                    name="customer"
                    error={validationMessages.hasOwnProperty('customer')}
                    helperText={validationMessages.customer}
                    onChange={event =>
                      handleFieldChange('customer', { id: event.target.value })
                    }
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={
                      customers.length > 0 &&
                      values.customer &&
                      values.customer.id
                        ? values.customer.id
                        : ''
                    }
                    variant="outlined">
                    <option value="" />

                    {customers.map(customer => (
                      <option key={customer.id} value={customer.id}>
                        {customer.customerName}
                      </option>
                    ))}
                  </TextField>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.formGroup}>
                  <TextField
                    required
                    fullWidth
                    label="Venue"
                    name="venue"
                    error={validationMessages.hasOwnProperty('venue')}
                    helperText={validationMessages.venue}
                    onChange={event =>
                      handleFieldChange('venue', { id: event.target.value })
                    }
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={
                      venues.length > 0 && values.venue && values.venue.id
                        ? values.venue.id
                        : ''
                    }
                    variant="outlined">
                    <option value="" />

                    {availableVenues.map(venue => (
                      <option key={venue.id} value={venue.id}>
                        {venue.name}
                      </option>
                    ))}
                  </TextField>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.formGroup}>
                  <TextField
                    error={validationMessages.hasOwnProperty('location')}
                    helperText={validationMessages.version}
                    fullWidth
                    label="Location (e.g. Main Entrance, Door 1)"
                    name="location"
                    onChange={event =>
                      handleFieldChange('location', event.target.value)
                    }
                    value={values.location ? values.location : ''}
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item sm={12} md={6}>
                      <KeyboardDatePicker
                        error={validationMessages.hasOwnProperty(
                          'installationStartTime'
                        )}
                        helperText={validationMessages.installationStartTime}
                        margin="dense"
                        className={classes.datePicker}
                        id="filter-start-date-picker-dialog"
                        label="Installation (From)"
                        format="MM/dd/yyyy"
                        value={
                          values.installationStartTime !== null
                            ? new Date(values.installationStartTime)
                            : null
                        }
                        onChange={date =>
                          handleDateChange('installationStartTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <KeyboardDatePicker
                        error={validationMessages.hasOwnProperty(
                          'installationFinishTime'
                        )}
                        helperText={validationMessages.installationFinishTime}
                        margin="dense"
                        className={classes.datePicker}
                        id="filter-finish-date-picker-dialog"
                        label="Installation (To)"
                        format="MM/dd/yyyy"
                        value={
                          values.installationFinishTime !== null
                            ? new Date(values.installationFinishTime)
                            : null
                        }
                        onChange={date =>
                          handleDateChange('installationFinishTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            {!create && (
              <Button
                className={classes.saveButton}
                onClick={handleConfirmDeleteVenueInstallation}
                variant="contained">
                Delete
              </Button>
            )}
            <Button
              className={classes.saveButton}
              onClick={handleCreateVenueInstallation}
              variant="contained">
              Save
            </Button>
          </CardActions>
        </Card>
      </Modal>

      <ConfirmDelete
        open={openConfirmDelete}
        message={deleteMessage}
        disabled={deleteMessage !== null}
        entityType="version"
        onClose={handleConfirmDeleteClose}
        onConfirm={handleDeleteVenueInstallation}
      />
    </>
  );
};

CreateCellVenueInstallation.defaultProps = {
  open: false
};

export default CreateCellVenueInstallation;
