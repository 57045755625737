/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { SETUP_VALIDATION_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const SetupValidationStatus = props => {
  const { status, ...rest } = props;

  const statusColors = {
    UNLINKED: colors.blue[600],
    VERIFIED: colors.green[600],
    SUSPECT: colors.red[600],
    PENDING: colors.orange[600]
  };

  return (
    <div>
      <Label {...rest} color={statusColors[status]}>
        {SETUP_VALIDATION_STATUSES[status]}
      </Label>
    </div>
  );
};

SetupValidationStatus.propTypes = {
  status: PropTypes.string
};

export default SetupValidationStatus;
