import * as actionTypes from 'actions';
import { CAMERA_FACING_MODES } from 'common/constants';

const initialState = {
  processing: false,
  facingMode: CAMERA_FACING_MODES.ENVIRONMENT,
  mirrored: false,
  cameraError: null,
  entityDetailsSet: false,
  entityPhoto: {
    comment: '',
    dataUri: null
  },
  cameraCaptureOpen: false
};

const entityPhotoCameraCaptureReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ENTITY_PHOTO_CAPTURE_VALUES: {
      return {
        ...state,
        entityPhoto: {
          ...state.entityPhoto,
          ...action.payload
        }
      };
    }

    case actionTypes.CLEAR_ENTITY_PHOTO_CAPTURE_VALUES: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      return {
        ...state,
        entityPhoto: {
          ...initialState.entityPhoto
        },
        entityDetailsSet: false,
        cameraCaptureOpen: false,
        processing: false
      };
    }

    case actionTypes.SET_CAMERA_CAPTURE_OPEN: {
      return {
        ...state,
        cameraCaptureOpen: action.payload,
        entityDetailsSet: !action.payload ? false : state.entityDetailsSet
      };
    }

    case actionTypes.SET_CAMERA_CAPTURE_FACING_MODE: {
      return {
        ...state,
        facingMode: action.payload
      };
    }

    case actionTypes.SET_CAMERA_CAPTURE_MIRRORED: {
      return {
        ...state,
        mirrored: action.payload
      };
    }

    case actionTypes.SET_CAMERA_CAPTURE_CAMERA_ERROR: {
      return {
        ...state,
        cameraError: action.payload
      };
    }

    case actionTypes.SET_ENTITY_PHOTO_CAPTURE_ENTITY_DETAILS: {
      return {
        ...state,
        entityPhoto: {
          ...state.entityPhoto,
          ...action.payload
        },
        entityDetailsSet: true
      };
    }

    default: {
      return state;
    }
  }
};

export default entityPhotoCameraCaptureReducer;
