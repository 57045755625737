import * as actionTypes from 'actions';

const initialState = {
  session: null,
  setup: null,
  initialEventRequested: false,
  eventListLoaded: false,
  currentEvent: {},
  currentEventLoading: false,
  events: [],
  eventsLoading: false,
  processing: false,
  eventPlaybackPaused: true,
  eventVideoPlaybackRate: 2,
  activeStep: 0,
  processingEventId: null,
  setupValidationStatusChangeStatus: null,
  participantPhotosLoading: false,
  participantPhotos: []
};

const validateTrainingSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VALIDATE_TRAINING_SESSION_SETUP: {
      return {
        ...state,
        initialEventRequested: false,
        eventListLoaded: false,
        currentEvent: initialState.currentEvent,
        currentEventLoading: initialState.currentEventLoading,
        events: initialState.events,
        eventsLoading: initialState.eventsLoading,
        processing: initialState.processing,
        eventPlaybackPaused: initialState.eventPlaybackPaused,
        eventVideoPlaybackRate: initialState.eventVideoPlaybackRate,
        activeStep: initialState.activeStep,
        processingEventId: initialState.processingEventId,
        setupValidationStatusChangeStatus:
          initialState.setupValidationStatusChangeStatus,
        participantPhotosLoading: initialState.participantPhotosLoading,
        participantPhotos: initialState.participantPhotos,
        ...action.payload
      };
    }

    case actionTypes.FIND_VALIDATE_SESSION_EVENT_PENDING: {
      return {
        ...state,
        initialEventRequested: true,
        currentEventLoading: true,
        eventListLoaded: false
      };
    }

    case actionTypes.FIND_VALIDATE_SESSION_EVENT_FULFILLED: {
      return {
        ...state,
        currentEvent: action.payload.data.result,
        currentEventLoading: false
      };
    }

    case actionTypes.FIND_VALIDATE_SESSION_EVENTS_PENDING: {
      return {
        ...state,
        eventListLoaded: true,
        eventsLoading: true,
        events: []
      };
    }

    case actionTypes.FIND_VALIDATE_SESSION_EVENTS_FULFILLED: {
      return {
        ...state,
        eventsLoading: true,
        events: action.payload.data.result
      };
    }

    case actionTypes.SET_VALIDATION_STATUS_PROCESSING_EVENT_ID: {
      return {
        ...state,
        processingEventId: action.payload
      };
    }

    case actionTypes.UPDATE_EVENT_VALIDATION_STATUS_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.UPDATE_EVENT_VALIDATION_STATUS_FULFILLED: {
      const event = action.payload.data.result;

      const events = state.events.slice();
      const index = events.map(e => e.id).indexOf(event.id);

      events[index] = {
        ...event,
        processing: false
      };

      return {
        ...state,
        processing: false,
        events: events,
        processingEventId: null,
        currentEvent: {
          ...action.payload.data.result
        }
      };
    }

    case actionTypes.UPDATE_SETUP_VALIDATION_STATUS_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.UPDATE_SETUP_VALIDATION_STATUS_FULFILLED: {
      return {
        ...state,
        sesssion: action.payload.data.result,
        eventListLoaded: false,
        currentEvent: {
          ...state.currentEvent,
          validationStatus: state.setupValidationStatusChangeStatus
        },
        setupValidationStatusChangeStatus:
          initialState.setupValidationStatusChangeStatus,
        processing: false
      };
    }

    case actionTypes.SET_EVENT_PLAYBACK_PAUSED: {
      return {
        ...state,
        eventPlaybackPaused: action.payload
      };
    }

    case actionTypes.SET_EVENT_PLAYBACK_RATE: {
      return {
        ...state,
        eventVideoPlaybackRate: action.payload
      };
    }

    case actionTypes.SET_VALIDATE_SETUP_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: action.payload
      };
    }

    case actionTypes.SET_SETUP_VALIDATION_STATUS_CHANGE_STATUS: {
      return {
        ...state,
        setupValidationStatusChangeStatus: action.payload
      };
    }

    case actionTypes.FIND_TRAINING_SESSION_VALIDATION_PARTICIPANT_PHOTOS_PENDING: {
      return {
        ...state,
        participantPhotosLoading: initialState.participantPhotosLoading,
        participantPhotos: initialState.participantPhotos
      };
    }

    case actionTypes.FIND_TRAINING_SESSION_VALIDATION_PARTICIPANT_PHOTOS_FULFILLED: {
      return {
        ...state,
        participantPhotosLoading: false,
        participantPhotos: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default validateTrainingSessionReducer;
