import ws from '../utils/ws';

/* Actions */

export const cellComponentVersionValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CELL_COMPONENT_VERSION_VALUE_UPDATE,
    payload: values
  });

export const cellComponentVersionValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CELL_COMPONENT_VERSION_VALUE_RESET
  });

export const setConfirmDeleteCellComponentVersionOpen = open => dispatch =>
  dispatch({
    type: SET_CONFIRM_DELETE_CELL_COMPONENT_VERSION_OPEN,
    payload: open
  });

export const editCellComponentVersion = (
  cellComponent,
  versionGuid
) => dispatch =>
  dispatch({
    type: EDIT_CELL_COMPONENT_VERSION,
    payload: ws.get(
      `/cell_components/${cellComponent.id}/versions/${versionGuid}`
    )
  });

export const saveCellComponentVersion = (values, cellComponent) => dispatch =>
  dispatch({
    type: SAVE_CELL_COMPONENT_VERSION,
    payload: ws.post(`/cell_components/${cellComponent.id}/versions/`, {
      ...values
    })
  });

export const deleteCellComponentVersion = (
  cellComponent,
  versionGuid
) => dispatch =>
  dispatch({
    type: DELETE_CELL_COMPONENT_VERSION,
    payload: ws.post(
      `/cell_components/${cellComponent.id}/versions/${versionGuid}/delete`
    )
  });

export const setCreateCellComponentVersionOpen = open => dispatch =>
  dispatch({
    type: SET_CREATE_CELL_COMPONENT_VERSION_OPEN,
    payload: open
  });

/* Constants */

export const SET_CREATE_CELL_COMPONENT_VERSION_OPEN =
  'SET_CREATE_CELL_COMPONENT_VERSION_OPEN';

export const CREATE_CELL_COMPONENT_VERSION_VALUE_UPDATE =
  'CREATE_CELL_COMPONENT_VERSION_VALUE_UPDATE';
export const CREATE_CELL_COMPONENT_VERSION_VALUE_RESET =
  'CREATE_CELL_COMPONENT_VERSION_VALUE_RESET';

export const SET_CONFIRM_DELETE_CELL_COMPONENT_VERSION_OPEN =
  'SET_CONFIRM_DELETE_CELL_COMPONENT_VERSION_OPEN';

export const EDIT_CELL_COMPONENT_VERSION = 'EDIT_CELL_COMPONENT_VERSION';
export const EDIT_CELL_COMPONENT_VERSION_PENDING =
  'EDIT_CELL_COMPONENT_VERSION_PENDING';
export const EDIT_CELL_COMPONENT_VERSION_FULFILLED =
  'EDIT_CELL_COMPONENT_VERSION_FULFILLED';

export const SAVE_CELL_COMPONENT_VERSION = 'SAVE_CELL_COMPONENT_VERSION';
export const SAVE_CELL_COMPONENT_VERSION_FULFILLED =
  'SAVE_CELL_COMPONENT_VERSION_FULFILLED';

export const DELETE_CELL_COMPONENT_VERSION = 'DELETE_CELL_COMPONENT_VERSION';
export const DELETE_CELL_COMPONENT_VERSION_FULFILLED =
  'DELETE_CELL_COMPONENT_VERSION_FULFILLED';
