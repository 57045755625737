import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  colors,
  TableHead
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  labelCell: {
    width: 120
  },
  valueCell: {
    color: colors.blue[800],
    fontFamily: 'monospace'
  }
}));

const CellConfigurationRadars = props => {
  const { cellConfiguration } = props;

  const classes = useStyles();

  if (!cellConfiguration) return null;

  const cellConfigurationJson = JSON.parse(cellConfiguration.configurationJson);
  const cellConfigurationRadars = cellConfigurationJson['Radars'];

  if (!cellConfigurationRadars || cellConfigurationRadars.length === 0)
    return null;

  const radarProperties = [
    ...new Set(cellConfigurationRadars.flatMap(radar => Object.keys(radar)))
  ];

  const PropertyValue = props => {
    const { value } = props;
    const valueString = !(typeof value === 'object')
      ? '' + value
      : JSON.stringify(value);

    return <>{valueString}</>;
  };

  return (
    <PerfectScrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            {radarProperties.map(property => (
              <TableCell key={property}>{property}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {cellConfigurationRadars.map((radar, i) => (
            <TableRow key={i}>
              {radarProperties.map(property => (
                <TableCell key={property}>
                  <PropertyValue value={radar[property]} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PerfectScrollbar>
  );
};

CellConfigurationRadars.propTypes = {
  cellConfiguration: PropTypes.object
};

export default CellConfigurationRadars;
