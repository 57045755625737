/* Actions */

export const setSelectCellComponentVersionOpen = open => dispatch =>
  dispatch({
    type: SET_SELECT_CELL_COMPONENT_VERSION_OPEN,
    payload: open
  });

export const setSelectCellComponentVersionComponent = cellComponent => dispatch =>
  dispatch({
    type: SET_SELECT_CELL_COMPONENT_VERSION_COMPONENT,
    payload: cellComponent
  });

export const setSelectCellComponentVersionSearch = search => dispatch =>
  dispatch({
    type: SET_SELECT_CELL_COMPONENT_VERSION_SEARCH,
    payload: search
  });

/* Constants */

export const SET_SELECT_CELL_COMPONENT_VERSION_OPEN =
  'SET_SELECT_CELL_COMPONENT_VERSION_OPEN';

export const SET_SELECT_CELL_COMPONENT_VERSION_COMPONENT =
  'SET_SELECT_CELL_COMPONENT_VERSION_COMPONENT';

export const SET_SELECT_CELL_COMPONENT_VERSION_SEARCH =
  'SET_SELECT_CELL_COMPONENT_VERSION_SEARCH';
