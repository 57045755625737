import ws from '../utils/ws';

/* Actions */

export const cellCheckListDefinitionPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: CELL_CHECK_LIST_DEFINITIONS_PAGINATED_LIST,
    payload: ws.get(
      `/cell_check_list_definitions/page/${pageNumber}/rows/${pageSize}`
    )
  });

export const cellCheckListDefinitionValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CELL_CHECK_LIST_DEFINITION_VALUE_UPDATE,
    payload: values
  });

export const cellCheckListDefinitionValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CELL_CHECK_LIST_DEFINITION_VALUE_RESET
  });

export const toggleCellCheckListComponentExpanded = buildComponentGuid => dispatch =>
  dispatch({
    type: TOGGLE_CELL_CHECK_LIST_COMPONENT_EXPANDED,
    payload: buildComponentGuid
  });

export const toggleCellCheckListComponentsExpanded = buildComponentGuids => dispatch =>
  dispatch({
    type: TOGGLE_CELL_CHECK_LIST_COMPONENTS_EXPANDED,
    payload: buildComponentGuids
  });

export const setCellCheckListComponentSearch = search => dispatch =>
  dispatch({
    type: SET_CELL_CHECK_LIST_COMPONENT_SEARCH,
    payload: search
  });

export const setSearchResultCellCheckListComponents = buildComponentGuids => dispatch =>
  dispatch({
    type: SET_SEARCH_RESULT_CELL_CHECK_LIST_COMPONENTS,
    payload: buildComponentGuids
  });

export const setCreateBuildDefinitionEditComponent = buildComponent => dispatch =>
  dispatch({
    type: SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT,
    payload: buildComponent
  });

export const setCreateCheckListItemGroup = checkListItemGroup => dispatch =>
  dispatch({
    type: SET_CREATE_CHECK_LIST_ITEM_GROUP,
    payload: checkListItemGroup
  });

export const setCreateCheckListItem = checkListItem => dispatch =>
  dispatch({
    type: SET_CREATE_CHECK_LIST_ITEM,
    payload: checkListItem
  });

export const setConfirmDeleteCheckListItemGroup = checkListItemGroup => dispatch =>
  dispatch({
    type: SET_CONFIRM_DELETE_CHECK_LIST_ITEM_GROUP,
    payload: checkListItemGroup
  });

export const setConfirmDeleteCheckListItem = checkListItem => dispatch =>
  dispatch({
    type: SET_CONFIRM_DELETE_CHECK_LIST_ITEM,
    payload: checkListItem
  });

export const setCreateCellCheckListDefinitionOpen = open => dispatch =>
  dispatch({
    type: SET_CREATE_CELL_CHECK_LIST_DEFINITION_OPEN,
    payload: open
  });

export const setConfirmDeleteCellCheckListDefinition = cellCheckListDefinition => dispatch =>
  dispatch({
    type: SET_CONFIRM_DELETE_CELL_CHECK_LIST_DEFINITION,
    payload: cellCheckListDefinition
  });

export const setCreateBuildDefinitionEditComponentFieldValue = (
  field,
  value
) => dispatch =>
  dispatch({
    type: SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT_FIELD_VALUE,
    payload: {
      field,
      value
    }
  });

export const setCreateCellCheckListComponentFieldValue = (
  field,
  value
) => dispatch =>
  dispatch({
    type: SET_CREATE_BUILD_DEFINITION_FIELD_VALUE,
    payload: {
      field,
      value
    }
  });

export const findLatestCellCheckListDefinitionVersions = () => dispatch =>
  dispatch({
    type: FIND_LATEST_CELL_CHECK_LIST_DEFINITION_VERSIONS,
    payload: ws.get('/cell_check_list_definitions/latest_versions/')
  });

export const createNewCellCheckListDefinition = cellCheckListDefinition => dispatch =>
  dispatch({
    type: CREATE_CELL_CHECK_LIST_DEFINITION,
    payload: ws.post(`/cell_check_list_definitions/`, cellCheckListDefinition)
  });

export const saveCheckListItemGroup = (
  cellCheckListDefinitionId,
  checkListItemGroup
) => dispatch =>
  dispatch({
    type: SAVE_CELL_CHECK_LIST_ITEM_GROUP,
    payload: ws.post(
      `/cell_check_list_definitions/${cellCheckListDefinitionId}/groups/`,
      checkListItemGroup
    )
  });

export const saveCheckListItem = (
  cellCheckListDefinitionId,
  checkListItemGroupGuid,
  checkListItem
) => dispatch =>
  dispatch({
    type: SAVE_CELL_CHECK_LIST_ITEM,
    payload: ws.post(
      `/cell_check_list_definitions/${cellCheckListDefinitionId}/groups/${checkListItemGroupGuid}/check_list_item`,
      checkListItem
    )
  });

export const deleteCheckListItemGroup = (
  cellCheckListDefinitionId,
  checkListItemGroup
) => dispatch =>
  dispatch({
    type: DELETE_CELL_CHECK_LIST_ITEM_GROUP,
    payload: ws.post(
      `/cell_check_list_definitions/${cellCheckListDefinitionId}/groups/delete`,
      checkListItemGroup
    )
  });

export const deleteCheckListItem = (
  cellCheckListDefinitionId,
  checkListItem
) => dispatch =>
  dispatch({
    type: DELETE_CELL_CHECK_LIST_ITEM,
    payload: ws.post(
      `/cell_check_list_definitions/${cellCheckListDefinitionId}/groups/${checkListItem.checkListItemGroupGuid}/check_list_item/delete`,
      checkListItem
    )
  });

export const saveCellCheckListDefinition = cellCheckListDefinition => dispatch =>
  dispatch({
    type: SAVE_CELL_CHECK_LIST_DEFINITION,
    payload: ws.post('/cell_check_list_definitions/', cellCheckListDefinition)
  });

export const deleteCellCheckListDefinition = cellCheckListDefinition => dispatch =>
  dispatch({
    type: DELETE_CELL_CHECK_LIST_DEFINITION,
    payload: ws.post(
      `/cell_check_list_definitions/${cellCheckListDefinition.id}/delete`
    )
  });

export const editCellCheckListDefinition = id => dispatch =>
  dispatch({
    type: EDIT_CELL_CHECK_LIST_DEFINITION,
    payload: ws.get(`/cell_check_list_definitions/${id}`)
  });

export const findCellCheckListDefinitions = () => dispatch =>
  dispatch({
    type: FIND_CELL_CHECK_LIST_DEFINITIONS,
    payload: ws.get('/cell_check_list_definitions/')
  });

export const findCellCheckListDefinition = id => dispatch =>
  dispatch({
    type: FIND_CELL_CHECK_LIST_DEFINITION,
    payload: ws.get(`/cell_check_list_definitions/${id}`)
  });

export const clearCellCheckListDefinitionDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_CELL_CHECK_LIST_DEFINITION_DELETE_MESSAGE
  });

export const setCreateCellCheckListFieldValue = (field, value) => dispatch =>
  dispatch({
    type: SET_CREATE_CELL_CHECK_LIST_FIELD_VALUE,
    payload: {
      field,
      value
    }
  });

export const saveCellCheckList = (cell, cellCheckList) => dispatch =>
  dispatch({
    type: SAVE_CELL_CHECK_LIST,
    payload: ws.post(`/cells/${cell.id}/check_lists/`, cellCheckList)
  });

/* Constants */

export const CELL_CHECK_LIST_DEFINITIONS_PAGINATED_LIST =
  'CELL_CHECK_LIST_DEFINITIONS_PAGINATED_LIST';
export const CELL_CHECK_LIST_DEFINITIONS_PAGINATED_LIST_PENDING =
  'CELL_CHECK_LIST_DEFINITIONS_PAGINATED_LIST_PENDING';
export const CELL_CHECK_LIST_DEFINITIONS_PAGINATED_LIST_FULFILLED =
  'CELL_CHECK_LIST_DEFINITIONS_PAGINATED_LIST_FULFILLED';

export const CREATE_CELL_CHECK_LIST_DEFINITION_VALUE_UPDATE =
  'CREATE_CELL_CHECK_LIST_DEFINITIONS_VALUE_UPDATE';
export const CREATE_CELL_CHECK_LIST_DEFINITION_VALUE_RESET =
  'CREATE_CELL_CHECK_LIST_DEFINITION_VALUE_RESET';

export const SAVE_CELL_CHECK_LIST_DEFINITION =
  'SAVE_CELL_CHECK_LIST_DEFINITION';
export const SAVE_CELL_CHECK_LIST_DEFINITION_FULFILLED =
  'SAVE_CELL_CHECK_LIST_DEFINITION_FULFILLED';

export const DELETE_CELL_CHECK_LIST_DEFINITION =
  'DELETE_CELL_CHECK_LIST_DEFINITION';
export const DELETE_CELL_CHECK_LIST_DEFINITION_PENDING =
  'DELETE_CELL_CHECK_LIST_DEFINITION_PENDING';
export const DELETE_CELL_CHECK_LIST_DEFINITION_FULFILLED =
  'DELETE_CELL_CHECK_LIST_DEFINITION_FULFILLED';

export const EDIT_CELL_CHECK_LIST_DEFINITION =
  'EDIT_CELL_CHECK_LIST_DEFINITION';
export const EDIT_CELL_CHECK_LIST_DEFINITION_FULFILLED =
  'EDIT_CELL_CHECK_LIST_DEFINITION_FULFILLED';

export const FIND_CELL_CHECK_LIST_DEFINITIONS =
  'FIND_CELL_CHECK_LIST_DEFINITIONS';
export const FIND_CELL_CHECK_LIST_DEFINITIONS_PENDING =
  'FIND_CELL_CHECK_LIST_DEFINITIONS_PENDING';
export const FIND_CELL_CHECK_LIST_DEFINITIONS_FULFILLED =
  'FIND_CELL_CHECK_LIST_DEFINITIONS_FULFILLED';

export const FIND_CELL_CHECK_LIST_DEFINITION =
  'FIND_CELL_CHECK_LIST_DEFINITION';
export const FIND_CELL_CHECK_LIST_DEFINITION_PENDING =
  'FIND_CELL_CHECK_LIST_DEFINITION_PENDING';
export const FIND_CELL_CHECK_LIST_DEFINITION_FULFILLED =
  'FIND_CELL_CHECK_LIST_DEFINITION_FULFILLED';

export const FIND_LATEST_CELL_CHECK_LIST_DEFINITION_VERSIONS =
  'FIND_LATEST_CELL_CHECK_LIST_DEFINITION_VERSIONS';
export const FIND_LATEST_CELL_CHECK_LIST_DEFINITION_VERSIONS_PENDING =
  'FIND_LATEST_CELL_CHECK_LIST_DEFINITION_VERSIONS_PENDING';
export const FIND_LATEST_CELL_CHECK_LIST_DEFINITION_VERSIONS_FULFILLED =
  'FIND_LATEST_CELL_CHECK_LIST_DEFINITION_VERSIONS_FULFILLED';

export const CLEAR_CELL_CHECK_LIST_DEFINITION_DELETE_MESSAGE =
  'CLEAR_CELL_CHECK_LIST_DEFINITION_DELETE_MESSAGE';

export const TOGGLE_CELL_CHECK_LIST_COMPONENT_EXPANDED =
  'TOGGLE_CELL_CHECK_LIST_COMPONENT_EXPANDED';

export const TOGGLE_CELL_CHECK_LIST_COMPONENTS_EXPANDED =
  'TOGGLE_CELL_CHECK_LIST_COMPONENTS_EXPANDED';

export const SET_CELL_CHECK_LIST_COMPONENT_SEARCH =
  'SET_CELL_CHECK_LIST_COMPONENT_SEARCH';

export const SET_SEARCH_RESULT_CELL_CHECK_LIST_COMPONENTS =
  'SET_SEARCH_RESULT_CELL_CHECK_LIST_COMPONENTS';

export const SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT =
  'SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT';

export const SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT_FIELD_VALUE =
  'SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT_FIELD_VALUE';

export const SAVE_CELL_CHECK_LIST_ITEM_GROUP =
  'SAVE_CELL_CHECK_LIST_ITEM_GROUP';
export const SAVE_CELL_CHECK_LIST_ITEM_GROUP_PENDING =
  'SAVE_CELL_CHECK_LIST_ITEM_GROUP_PENDING';
export const SAVE_CELL_CHECK_LIST_ITEM_GROUP_FULFILLED =
  'SAVE_CELL_CHECK_LIST_ITEM_GROUP_FULFILLED';

export const SAVE_CELL_CHECK_LIST_ITEM = 'SAVE_CELL_CHECK_LIST_ITEM';
export const SAVE_CELL_CHECK_LIST_ITEM_PENDING =
  'SAVE_CELL_CHECK_LIST_ITEM_PENDING';
export const SAVE_CELL_CHECK_LIST_ITEM_FULFILLED =
  'SAVE_CELL_CHECK_LIST_ITEM_FULFILLED';

export const SET_CREATE_CHILD_BUILD_DEFINITION_COMPONENT =
  'SET_CREATE_CHILD_BUILD_DEFINITION_COMPONENT';

export const SET_CONFIRM_DELETE_CHECK_LIST_ITEM_GROUP =
  'SET_CONFIRM_DELETE_CHECK_LIST_ITEM_GROUP';

export const SET_CONFIRM_DELETE_CHECK_LIST_ITEM =
  'SET_CONFIRM_DELETE_CHECK_LIST_ITEM';

export const DELETE_CELL_CHECK_LIST_ITEM_GROUP =
  'DELETE_CELL_CHECK_LIST_ITEM_GROUP';
export const DELETE_CELL_CHECK_LIST_ITEM_GROUP_PENDING =
  'DELETE_CELL_CHECK_LIST_ITEM_GROUP_PENDING';
export const DELETE_CELL_CHECK_LIST_ITEM_GROUP_FULFILLED =
  'DELETE_CELL_CHECK_LIST_ITEM_GROUP_FULFILLED';

export const DELETE_CELL_CHECK_LIST_ITEM = 'DELETE_CELL_CHECK_LIST_ITEM';
export const DELETE_CELL_CHECK_LIST_ITEM_PENDING =
  'DELETE_CELL_CHECK_LIST_ITEM_PENDING';
export const DELETE_CELL_CHECK_LIST_ITEM_FULFILLED =
  'DELETE_CELL_CHECK_LIST_ITEM_FULFILLED';

export const SET_CREATE_CELL_CHECK_LIST_DEFINITION_OPEN =
  'SET_CREATE_CELL_CHECK_LIST_DEFINITION_OPEN';

export const SET_CREATE_BUILD_DEFINITION_FIELD_VALUE =
  'SET_CREATE_BUILD_DEFINITION_FIELD_VALUE';

export const CREATE_CELL_CHECK_LIST_DEFINITION =
  'CREATE_CELL_CHECK_LIST_DEFINITION';
export const CREATE_CELL_CHECK_LIST_DEFINITION_PENDING =
  'CREATE_CELL_CHECK_LIST_DEFINITION_PENDING';
export const CREATE_CELL_CHECK_LIST_DEFINITION_FULFILLED =
  'CREATE_CELL_CHECK_LIST_DEFINITION_FULFILLED';

export const SAVE_CELL_CHECK_LIST = 'SAVE_CELL_CHECK_LIST';
export const SAVE_CELL_CHECK_LIST_PENDING = 'SAVE_CELL_CHECK_LIST_PENDING';
export const SAVE_CELL_CHECK_LIST_FULFILLED = 'SAVE_CELL_CHECK_LIST_FULFILLED';

export const SET_CONFIRM_DELETE_CELL_CHECK_LIST_DEFINITION =
  'SET_CONFIRM_DELETE_CELL_CHECK_LIST_DEFINITION';

export const SET_CREATE_CHECK_LIST_ITEM_GROUP =
  'SET_CREATE_CHECK_LIST_ITEM_GROUP';

export const SET_CREATE_CHECK_LIST_ITEM = 'SET_CREATE_CHECK_LIST_ITEM';

export const SET_CREATE_CELL_CHECK_LIST_FIELD_VALUE =
  'SET_CREATE_CELL_CHECK_LIST_FIELD_VALUE';
