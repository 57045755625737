import ws from '../utils/ws';

/* Actions */

export const sessionGroupPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: SESSION_GROUPS_PAGINATED_LIST,
    payload: ws.get(`/session_groups/page/${pageNumber}/rows/${pageSize}`)
  });

export const sessionGroupEventPaginatedList = (
  sessionGroupId,
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: SESSION_GROUPS_EVENT_PAGINATED_LIST,
    payload: ws.get(
      `/session_groups/${sessionGroupId}/events/page/${pageNumber}/rows/${pageSize}`
    )
  });

export const setSessionGroupDetailsEventListCommentsEvent = event => dispatch =>
  dispatch({
    type: SET_SESSION_GROUP_DETAILS_EVENT_LIST_COMMENTS_EVENT,
    payload: event
  });

export const sessionGroupValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_SESSION_GROUP_VALUE_UPDATE,
    payload: values
  });

export const sessionGroupValueReset = () => dispatch =>
  dispatch({
    type: CREATE_SESSION_GROUP_VALUE_RESET
  });

export const saveSessionGroup = sesionGroup => dispatch =>
  dispatch({
    type: SAVE_SESSION_GROUP,
    payload: ws.post('/session_groups/', sesionGroup)
  });

export const removeAllFromSessionGroup = sesionGroup => dispatch =>
  dispatch({
    type: REMOVE_ALL_FROM_SESSION_GROUP,
    payload: ws.post(`/session_groups/${sesionGroup.id}/remove/all`)
  });

export const deleteSessionGroup = sesionGroup => dispatch =>
  dispatch({
    type: DELETE_SESSION_GROUP,
    payload: ws.post(`/session_groups/${sesionGroup.id}/delete`)
  });

export const findSessionGroupCreatedByUsers = () => dispatch =>
  dispatch({
    type: FIND_SESSION_GROUP_CREATED_BY_USERS,
    payload: ws.get(`/session_groups/reference/created_by_users`)
  });

export const editSessionGroup = id => dispatch =>
  dispatch({
    type: EDIT_SESSION_GROUP,
    payload: ws.get(`/session_groups/${id}`)
  });

export const findSessionGroups = () => dispatch =>
  dispatch({
    type: FIND_SESSION_GROUPS,
    payload: ws.get('/session_groups/')
  });

export const addSessionsToGroup = (
  groupId,
  sessionIds,
  entityType,
  associationType
) => dispatch =>
  dispatch({
    type: ADD_SESSIONS_TO_GROUP,
    payload: ws.post(
      `/session_groups/${groupId}/add?entityType=${entityType}&associationType=${associationType}`,
      sessionIds
    )
  });

export const removeSessionsFromGroup = (
  groupId,
  sessionIds,
  entityType
) => dispatch =>
  dispatch({
    type: REMOVE_SESSIONS_FROM_GROUP,
    payload: ws.post(
      `/session_groups/${groupId}/remove?entityType=${entityType}`,
      sessionIds
    )
  });

export const findSessionGroup = id => dispatch =>
  dispatch({
    type: FIND_SESSION_GROUP,
    payload: ws.get(`/session_groups/${id}`)
  });

export const findSessionsForGroup = sessionGroupId => dispatch =>
  dispatch({
    type: FIND_GROUP_SESSIONS,
    payload: ws.get(`/session_groups/${sessionGroupId}/sessions/`)
  });

export const findCustomerEventsForGroup = sessionGroupId => dispatch =>
  dispatch({
    type: FIND_GROUP_CUSTOMER_EVENTS,
    payload: ws.get(`/session_groups/${sessionGroupId}/customer_events/`)
  });

export const findSessionGroupAnalysisOverview = (
  id,
  modelConfiguration,
  analysisBias,
  recalculate,
  calculateOptimalAccuracySumBias,
  cell,
  subModel,
  areaGroup
) => dispatch =>
  dispatch({
    type: FIND_SESSION_GROUP_ANALYSIS_OVERVIEW,
    payload: ws.get(
      '/session_groups/' +
        id +
        '/analysis/overview' +
        '?modelConfigurationId=' +
        (modelConfiguration && modelConfiguration.id
          ? modelConfiguration.id
          : '') +
        '&bias=' +
        analysisBias +
        (recalculate ? '&recalculate=' + recalculate : '') +
        (cell ? '&cell=' + cell.id : '') +
        (calculateOptimalAccuracySumBias
          ? '&calculateOptimalAccuracySumBias=true'
          : '') +
        (subModel !== undefined ? '&subModel=' + subModel : '') +
        (areaGroup !== undefined ? '&areaGroup=' + areaGroup : '')
    )
  });

export const setSessionGroupDetailsAnalysisBias = (
  bias,
  subModel
) => dispatch =>
  dispatch({
    type: SET_SESSION_GROUP_DETAILS_ANALYSIS_BIAS,
    payload: {
      bias,
      subModel
    }
  });

export const setSessionGroupDetailsModelConfiguration = modelConfiguration => dispatch =>
  dispatch({
    type: SET_SESSION_GROUP_DETAILS_MODEL_CONFIGURATION,
    payload: modelConfiguration
  });

export const setSessionGroupDetailsCell = cell => dispatch =>
  dispatch({
    type: SET_SESSION_GROUP_DETAILS_CELL,
    payload: cell
  });

export const setSessionGroupDetailsCalculateMaxAccuracySumBias = calculateMaxAccuracySumBias => dispatch =>
  dispatch({
    type: SET_SESSION_GROUP_DETAILS_CALCULATE_MAX_ACCURACY_SUM_BIAS,
    payload: calculateMaxAccuracySumBias
  });

/* Constants */

export const SESSION_GROUPS_PAGINATED_LIST = 'SESSION_GROUPS_PAGINATED_LIST';
export const SESSION_GROUPS_PAGINATED_LIST_PENDING =
  'SESSION_GROUPS_PAGINATED_LIST_PENDING';
export const SESSION_GROUPS_PAGINATED_LIST_FULFILLED =
  'SESSION_GROUPS_PAGINATED_LIST_FULFILLED';

export const SESSION_GROUPS_EVENT_PAGINATED_LIST =
  'SESSION_GROUPS_EVENT_PAGINATED_LIST';
export const SESSION_GROUPS_EVENT_PAGINATED_LIST_PENDING =
  'SESSION_GROUPS_EVENT_PAGINATED_LIST_PENDING';
export const SESSION_GROUPS_EVENT_PAGINATED_LIST_FULFILLED =
  'SESSION_GROUPS_EVENT_PAGINATED_LIST_FULFILLED';

export const SET_SESSION_GROUP_DETAILS_EVENT_LIST_COMMENTS_EVENT =
  'SET_SESSION_GROUP_DETAILS_EVENT_LIST_COMMENTS_EVENT';

export const CREATE_SESSION_GROUP_VALUE_UPDATE =
  'CREATE_SESSION_GROUP_VALUE_UPDATE';
export const CREATE_SESSION_GROUP_VALUE_RESET =
  'CREATE_SESSION_GROUP_VALUE_RESET';

export const SAVE_SESSION_GROUP = 'SAVE_SESSION_GROUP';
export const SAVE_SESSION_GROUP_FULFILLED = 'SAVE_SESSION_GROUP_FULFILLED';

export const REMOVE_ALL_FROM_SESSION_GROUP = 'REMOVE_ALL_FROM_SESSION_GROUP';

export const DELETE_SESSION_GROUP = 'DELETE_SESSION_GROUP';
export const DELETE_SESSION_GROUP_PENDING = 'DELETE_SESSION_GROUP_PENDING';
export const DELETE_SESSION_GROUP_FULFILLED = 'DELETE_SESSION_GROUP_FULFILLED';

export const FIND_SESSION_GROUP_CREATED_BY_USERS =
  'FIND_SESSION_GROUP_CREATED_BY_USERS';
export const FIND_SESSION_GROUP_CREATED_BY_USERS_PENDING =
  'FIND_SESSION_GROUP_CREATED_BY_USERS_PENDING';
export const FIND_SESSION_GROUP_CREATED_BY_USERS_FULFILLED =
  'FIND_SESSION_GROUP_CREATED_BY_USERS_FULFILLED';

export const EDIT_SESSION_GROUP = 'EDIT_SESSION_GROUP';
export const EDIT_SESSION_GROUP_FULFILLED = 'EDIT_SESSION_GROUP_FULFILLED';

export const FIND_SESSION_GROUPS = 'FIND_SESSION_GROUPS';
export const FIND_SESSION_GROUPS_PENDING = 'FIND_SESSION_GROUPS_PENDING';
export const FIND_SESSION_GROUPS_FULFILLED = 'FIND_SESSION_GROUPS_FULFILLED';

export const ADD_SESSIONS_TO_GROUP = 'ADD_SESSIONS_TO_GROUP';
export const ADD_SESSIONS_TO_GROUP_PENDING = 'ADD_SESSIONS_TO_GROUP_PENDING';
export const ADD_SESSIONS_TO_GROUP_FULFILLED =
  'ADD_SESSIONS_TO_GROUP_FULFILLED';

export const REMOVE_SESSIONS_FROM_GROUP = 'REMOVE_SESSION_FROM_GROUP';
export const REMOVE_SESSIONS_FROM_GROUP_PENDING =
  'REMOVE_SESSION_FROM_GROUP_PENDING';
export const REMOVE_SESSIONS_FROM_GROUP_FULFILLED =
  'REMOVE_SESSION_FROM_GROUP_FULFILLED';

export const FIND_SESSION_GROUP = 'FIND_SESSION_GROUP';
export const FIND_SESSION_GROUP_PENDING = 'FIND_SESSION_GROUP_PENDING';
export const FIND_SESSION_GROUP_FULFILLED = 'FIND_SESSION_GROUP_FULFILLED';

export const FIND_GROUP_SESSIONS = 'FIND_GROUP_SESSIONS';
export const FIND_GROUP_SESSIONS_PENDING = 'FIND_GROUP_SESSIONS_PENDING';
export const FIND_GROUP_SESSIONS_FULFILLED = 'FIND_GROUP_SESSIONS_FULFILLED';

export const FIND_GROUP_CUSTOMER_EVENTS = 'FIND_GROUP_CUSTOMER_EVENTS';
export const FIND_GROUP_CUSTOMER_EVENTS_PENDING =
  'FIND_GROUP_CUSTOMER_EVENTS_PENDING';
export const FIND_GROUP_CUSTOMER_EVENTS_FULFILLED =
  'FIND_GROUP_CUSTOMER_EVENTS_FULFILLED';

export const FIND_SESSION_GROUP_ANALYSIS_OVERVIEW =
  'FIND_SESSION_GROUP_ANALYSIS_OVERVIEW';
export const FIND_SESSION_GROUP_ANALYSIS_OVERVIEW_PENDING =
  'FIND_SESSION_GROUP_ANALYSIS_OVERVIEW_PENDING';
export const FIND_SESSION_GROUP_ANALYSIS_OVERVIEW_FULFILLED =
  'FIND_SESSION_GROUP_ANALYSIS_OVERVIEW_FULFILLED';

export const SET_SESSION_GROUP_DETAILS_ANALYSIS_BIAS =
  'SET_SESSION_GROUP_DETAILS_ANALYSIS_BIAS';

export const SET_SESSION_GROUP_DETAILS_MODEL_CONFIGURATION =
  'SET_SESSION_GROUP_DETAILS_MODEL_CONFIGURATION';

export const SET_SESSION_GROUP_DETAILS_CELL = 'SET_SESSION_GROUP_DETAILS_CELL';

export const SET_SESSION_GROUP_DETAILS_CALCULATE_MAX_ACCURACY_SUM_BIAS =
  'SET_SESSION_GROUP_DETAILS_CALCULATE_MAX_ACCURACY_SUM_BIAS';
