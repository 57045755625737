import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { GENDERS } from 'common/constants';
import { Search } from 'components/SearchBar/components';
import { useSelector } from 'react-redux';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 850,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  progress: {
    marginBottom: theme.spacing(2)
  },
  fileName: {
    marginBottom: theme.spacing(2)
  },
  image: {
    width: '100%'
  },
  details: {
    padding: theme.spacing(2)
  },
  comment: {
    flexGrow: 1,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    position: 'relative',
    marginTop: theme.spacing(1)
  },
  search: {
    minWidth: 480,
    maxWidth: 480,
    flexBasis: 480
  },
  tableContainer: {
    minHeight: 450,
    maxHeight: 450,
    overflowY: 'auto'
  },
  searchContainer: {
    padding: theme.spacing(2)
  },
  selfSearch: {
    marginTop: 3
  },
  buttonCell: {
    paddingTop: 0,
    paddingBottom: 0,
    width: 100
  },
  buttonExtraSmall: {
    padding: '1px 8px',
    fontSize: '0.8125rem'
  }
}));

const ParticipantSelect = props => {
  const {
    open,
    onClose,
    participants,
    onSelectParticipant,
    className,
    ...rest
  } = props;

  const classes = useStyles();

  const loading = false;
  const [searchName, setSearchName] = useState('');
  const { sessionUser } = useSelector(state => state.session);

  const findParticipantsByName = searchName => {
    return participants.filter(participant => {
      return (
        searchName === '' ||
        (!isNaN(searchName)
          ? participant.participantNumber === Number(searchName)
          : participant.name.toUpperCase().indexOf(searchName.toUpperCase()) !==
            -1)
      );
    });
  };

  const findFirstParticipantByName = searchName => {
    const participants = findParticipantsByName(searchName);
    return participants.length > 0 ? participants[0] : null;
  };

  const searchedParticipants = findParticipantsByName(searchName);
  const userParticipant = findFirstParticipantByName(sessionUser.displayName);

  const handleClose = () => {
    onClose();
  };

  const handleSearchFieldChange = value => {
    setSearchName(value);
  };

  const handleSelfSelection = () => {
    if (userParticipant) {
      onSelectParticipant(userParticipant);
    }
  };

  const handleSelectFirstParticipant = () => {
    onSelectParticipant(
      searchedParticipants.length === 0 ? null : searchedParticipants[0]
    );
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Select Participant" />
        <Divider />

        <CardContent className={classes.content}>
          <div className={classes.searchContainer}>
            <Grid container spacing={3}>
              <Grid item>
                <Search
                  autoFocus={true}
                  placeholder="Name or Number"
                  value={searchName}
                  onSearchValueChange={handleSearchFieldChange}
                  className={classes.search}
                  hideSearchButton={true}
                  onSearch={handleSelectFirstParticipant}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={!userParticipant}
                  className={classes.selfSearch}
                  onClick={handleSelfSelection}
                  variant="contained">
                  Myself
                </Button>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Actions</TableCell>
                  <TableCell>Number</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Height</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>Birth Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedParticipants.map(participant => (
                  <TableRow key={participant.id} hover>
                    <TableCell className={classes.buttonCell}>
                      <Button
                        className={classes.buttonExtraSmall}
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => onSelectParticipant(participant)}>
                        Select
                      </Button>
                    </TableCell>
                    <TableCell>{participant.participantNumber}</TableCell>
                    <TableCell>{participant.name}</TableCell>
                    <TableCell>{GENDERS[participant.gender]}</TableCell>
                    <TableCell>{participant.formattedHeightFeet}</TableCell>
                    <TableCell>{participant.weightPounds}</TableCell>
                    <TableCell>
                      {participant.timeOfBirth &&
                        moment(participant.timeOfBirth).format('MMM Do YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
                {!loading && searchedParticipants.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={10}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ParticipantSelect.displayName = 'ParticipantSelect';

ParticipantSelect.propTypes = {
  className: PropTypes.string
};

export default ParticipantSelect;
