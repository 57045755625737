import * as actionTypes from 'actions';

const initialState = {
  open: true,
  setupIndex: null,
  sessionIds: [],
  firstSession: null,
  cellWalkCounts: {},
  cells: []
};

const walkSessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WALK_SESSIONS_OPEN: {
      return {
        ...state,
        open: action.payload
      };
    }

    case actionTypes.SET_WALK_SESSION_IDS: {
      return {
        ...initialState,
        sessionIds: action.payload
      };
    }

    case actionTypes.SET_WALK_SESSION_SETUP_INDEX: {
      return {
        ...state,
        setupIndex: action.payload
      };
    }

    case actionTypes.FIND_WALK_SESSIONS_FIRST_SESSION_PENDING: {
      return {
        ...state,
        firstSession: initialState.firstSession
      };
    }

    case actionTypes.FIND_WALK_SESSIONS_FIRST_SESSION_FULFILLED: {
      return {
        ...state,
        firstSession: action.payload.data.result
      };
    }

    case actionTypes.FIND_WALK_SESSIONS_CELL_WALK_COUNTS_FULFILLED: {
      return {
        ...state,
        cellWalkCounts: action.payload.data.result
      };
    }

    case actionTypes.FIND_WALK_SESSIONS_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default walkSessionsReducer;
