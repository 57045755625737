import ws from '../utils/ws';

/* Actions */

export const addComment = (entityType, entityId, comment) => dispatch =>
  dispatch({
    type: ADD_COMMENT,
    payload: ws.post(`/comments/entity/${entityType}/${entityId}/`, comment)
  });

export const saveComment = (entityType, entityId, comment) => dispatch =>
  dispatch({
    type: SAVE_COMMENT,
    payload: ws.post(`/comments/entity/${entityType}/${entityId}/save`, comment)
  });

export const deleteComment = (entityType, entityId, comment) => dispatch =>
  dispatch({
    type: DELETE_COMMENT,
    payload: ws.post(
      `/comments/entity/${entityType}/${entityId}/delete`,
      comment
    )
  });

/* Action Constants */

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_PENDING = 'ADD_COMMENT_PENDING';
export const ADD_COMMENT_FULFILLED = 'ADD_COMMENT_FULFILLED';

export const SAVE_COMMENT = 'SAVE_COMMENT';
export const SAVE_COMMENT_PENDING = 'SAVE_COMMENT_PENDING';
export const SAVE_COMMENT_FULFILLED = 'SAVE_COMMENT_FULFILLED';

export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_PENDING = 'DELETE_COMMENT_PENDING';
export const DELETE_COMMENT_FULFILLED = 'DELETE_COMMENT_FULFILLED';
