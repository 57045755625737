import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat } from 'components';
import HelpIcon from '@material-ui/icons/Help';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  LinearProgress,
  colors,
  Typography,
  Tooltip,
  TableSortLabel
} from '@material-ui/core';
import {
  PARTICIPANT_ANALISYS_OVERVIEW_RESULT_TYPES,
  PARTICIPANT_ANALISYS_OVERVIEW_RESULT_MEASURES,
  ANALYSIS_BIAS_TYPES,
  CHART_COLORS
} from 'common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({
  root: {
    '& th': {
      verticalAlign: 'bottom'
    }
  },
  content: {
    padding: 0
  },
  tooltip: {
    cursor: 'help'
  },
  noResult: {
    display: 'block',
    height: 21,
    width: 55
  },
  filterLoading: {
    width: '10px!important',
    height: '10px!important',
    marginLeft: theme.spacing(1)
  },
  resultType: {
    background: colors.grey[50],
    '& p': {
      display: 'inline-block',
      fontWeight: 500
    }
  },
  separator: {
    height: theme.spacing(3)
  },
  hover: {
    '&:hover': {
      '& td': {
        background: '#F5F6F8'
      }
    }
  },
  columnSeparator: {
    borderLeftWidth: 1,
    borderLeftColor: colors.grey[400],
    borderLeftStyle: 'dotted'
  },
  subFilterHelp: {
    fontSize: 16,
    marginBottom: -4,
    marginLeft: 2,
    color: colors.blue[600]
  },
  subFilterTooltipContainer: {
    cursor: 'help'
  },
  sortable: {
    fontSize: 14,
    opacity: 0.6,
    marginLeft: theme.spacing(1)
  }
}));

const SubFilterParticipantAnalysisOverviewCard = props => {
  const { className, subFilterOverviews, loading, biasType } = props;
  const classes = useStyles();

  const [sort, setSort] = useState(undefined);

  const biasResultType = ANALYSIS_BIAS_TYPES[biasType].resultType;

  const participants = [];

  for (let i = 0; i < subFilterOverviews.length; i++) {
    const subFilterParticipantNames = subFilterOverviews[
      i
    ].participantAnalysisOverview[biasResultType].map(
      result => result.participantName
    );

    for (let j = 0; j < subFilterParticipantNames.length; j++) {
      const participantName = subFilterParticipantNames[j];
      if (participants.indexOf(participantName) < 0) {
        participants.push(participantName);
      }
    }
  }

  const { resultMeasures } =
    subFilterOverviews.length > 0 ? subFilterOverviews[0].analysisOverview : {};
  const results =
    subFilterOverviews.length > 0
      ? subFilterOverviews[0].analysisOverview[biasResultType]
      : {};

  const subFilters = subFilterOverviews.map(
    subFilterOverview => subFilterOverview.subFilter
  );
  const visibleResultMeasures =
    results && resultMeasures
      ? resultMeasures.filter(
          resultMeasure =>
            PARTICIPANT_ANALISYS_OVERVIEW_RESULT_MEASURES[resultMeasure.code]
        )
      : [];

  const retrieveResult = (participant, overview, resultType, resultMeasure) => {
    const participantOverview =
      overview &&
      overview.participantAnalysisOverview[biasResultType] &&
      overview.participantAnalysisOverview[biasResultType].filter(
        participantResult => participantResult.participantName === participant
      );

    const result =
      participantOverview.length > 0 &&
      participantOverview[0].results[resultType].filter(
        result => result.measure.code === resultMeasure
      )[0];
    return result ? result.value : null;
  };

  const FilterToolTip = props => {
    const { subFilter } = props;
    return (
      <>
        {(subFilter.filterItemDescriptions &&
          subFilter.filterItemDescriptions.length) > 0 ? (
          <span>
            {subFilter.filterItemDescriptions.map(subFilterDescription => (
              <div>
                {subFilterDescription.filterItem +
                  ': ' +
                  subFilterDescription.value}
              </div>
            ))}
          </span>
        ) : (
          <span>No Filters Set</span>
        )}
      </>
    );
  };

  const handleSort = (filter, resultType) => {
    setSort({
      filter,
      resultType,
      order: sort?.order === 'asc' ? 'desc' : 'asc'
    });
  };

  if (sort) {
    participants.sort((participant1, participant2) => {
      const result1 = retrieveResult(
        participant1,
        subFilterOverviews[sort.filter],
        sort.resultType,
        'ACCURACY'
      );
      const result2 = retrieveResult(
        participant2,
        subFilterOverviews[sort.filter],
        sort.resultType,
        'ACCURACY'
      );
      return sort.order === 'asc' ? result1 - result2 : result2 - result1;
    });
  } else {
    participants.sort();
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Card>
        <CardHeader title="Accuracy Overview" />
        <Divider />
        <CardContent className={classes.content}>
          {loading && <LinearProgress />}
          {!loading && results && (
            <>
              <PerfectScrollbar>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {visibleResultMeasures.map(resultMeasure => (
                        <TableCell
                          className={classes.columnSeparator}
                          colSpan={
                            subFilters.length *
                            Object.keys(
                              PARTICIPANT_ANALISYS_OVERVIEW_RESULT_TYPES
                            ).length
                          }
                          selected
                          key={resultMeasure.name}>
                          {PARTICIPANT_ANALISYS_OVERVIEW_RESULT_MEASURES[
                            resultMeasure.code
                          ]
                            .split('\n')
                            .map((row, i) => (
                              <div key={i}>{row}</div>
                            ))}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      {visibleResultMeasures.map(resultMeasure => (
                        <React.Fragment key={resultMeasure.name}>
                          {subFilters.map(subFilter => (
                            <TableCell
                              colSpan={
                                Object.keys(
                                  PARTICIPANT_ANALISYS_OVERVIEW_RESULT_TYPES
                                ).length
                              }
                              selected
                              key={subFilter.index}
                              style={{
                                borderLeftWidth: 3,
                                borderLeftStyle: 'solid',
                                borderLeftColor: CHART_COLORS[subFilter.index]
                              }}>
                              {subFilter.title ? (
                                <>{subFilter.title}</>
                              ) : (
                                <Tooltip
                                  title={
                                    <FilterToolTip subFilter={subFilter} />
                                  }
                                  placement="top-start">
                                  <span
                                    className={
                                      classes.subFilterTooltipContainer
                                    }>
                                    Filter {subFilter.index + 1}
                                    <HelpIcon
                                      className={classes.subFilterHelp}
                                    />
                                  </span>
                                </Tooltip>
                              )}
                            </TableCell>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      {subFilters.map(subFilter => (
                        <React.Fragment key={subFilter.index}>
                          {Object.keys(
                            PARTICIPANT_ANALISYS_OVERVIEW_RESULT_TYPES
                          ).map((resultType, i) => (
                            <TableCell
                              key={resultType}
                              style={
                                i == 0
                                  ? {
                                      borderLeftWidth: 3,
                                      borderLeftStyle: 'solid',
                                      borderLeftColor:
                                        CHART_COLORS[subFilter.index]
                                    }
                                  : {}
                              }>
                              <TableSortLabel
                                active={
                                  sort?.resultType === resultType &&
                                  sort?.filter === subFilter.index
                                }
                                direction={sort?.order}
                                onClick={() =>
                                  handleSort(subFilter.index, resultType)
                                }
                                hideSortIcon={true}>
                                {
                                  PARTICIPANT_ANALISYS_OVERVIEW_RESULT_TYPES[
                                    resultType
                                  ].name
                                }{' '}
                                {!(
                                  sort?.resultType === resultType &&
                                  sort?.filter === subFilter.index
                                ) && (
                                  <FontAwesomeIcon
                                    className={classes.sortable}
                                    icon={faSort}
                                  />
                                )}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {participants.map(participant => (
                      <TableRow key={participant}>
                        <TableCell className={classes.resultType}>
                          <Typography variant="body2">{participant}</Typography>
                        </TableCell>
                        {subFilters.map(subFilter => (
                          <React.Fragment key={subFilter.index}>
                            {Object.keys(
                              PARTICIPANT_ANALISYS_OVERVIEW_RESULT_TYPES
                            ).map(resultType => (
                              <TableCell key={resultType}>
                                <NumberFormat
                                  percentage={true}
                                  fixedDecimalScale={true}
                                  precision={2}
                                  value={retrieveResult(
                                    participant,
                                    subFilterOverviews[subFilter.index],
                                    resultType,
                                    'ACCURACY'
                                  )}
                                  suffix={'%'}
                                />
                              </TableCell>
                            ))}
                          </React.Fragment>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

SubFilterParticipantAnalysisOverviewCard.propTypes = {
  className: PropTypes.string,
  subFilterOverviews: PropTypes.array.isRequired,
  excelDownloadUri: PropTypes.string,
  filter: PropTypes.object
};

export default SubFilterParticipantAnalysisOverviewCard;
