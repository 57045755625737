import ws from '../utils/ws';

export const FIND_EVENT_VIDEO_EVENT = 'FIND_EVENT_VIDEO_EVENT';
export const FIND_EVENT_VIDEO_EVENT_PENDING = 'FIND_EVENT_VIDEO_EVENT_PENDING';
export const FIND_EVENT_VIDEO_EVENT_FULFILLED =
  'FIND_EVENT_VIDEO_EVENT_FULFILLED';

export const ENCODE_EVENT_WEB_VIDEOS = 'ENCODE_EVENT_WEB_VIDEOS';
export const ENCODE_EVENT_WEB_VIDEOS_PENDING =
  'ENCODE_EVENT_WEB_VIDEOS_PENDING';
export const ENCODE_EVENT_WEB_VIDEOS_FULFILLED =
  'ENCODE_EVENT_WEB_VIDEOS_FULFILLED';

export const FIND_EVENT_WEB_VIDEOS = 'FIND_EVENT_WEB_VIDEOS';
export const FIND_EVENT_WEB_VIDEOS_PENDING = 'FIND_EVENT_WEB_VIDEOS_PENDING';
export const FIND_EVENT_WEB_VIDEOS_FULFILLED =
  'FIND_EVENT_WEB_VIDEOS_FULFILLED';

export const findEventVideoEvent = eventId => dispatch =>
  dispatch({
    type: FIND_EVENT_VIDEO_EVENT,
    payload: ws.get('/events/' + eventId)
  });

export const encodeEventWebVideos = eventId => dispatch =>
  dispatch({
    type: ENCODE_EVENT_WEB_VIDEOS,
    payload: ws.get('/events/' + eventId + '/web_encode_videos')
  });

export const findEventWebVideos = eventId => dispatch =>
  dispatch({
    type: FIND_EVENT_WEB_VIDEOS,
    payload: ws.get('/events/' + eventId + '/web_videos/')
  });
