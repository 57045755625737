import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  LinearProgress
} from '@material-ui/core';
import { Alert } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

const ConfirmDelete = props => {
  const {
    open,
    onClose,
    onConfirm,
    deleting,
    additionalMessage,
    entityType,
    message,
    disabled,
    ...rest
  } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root)}>
        {deleting && <LinearProgress />}
        <CardContent>
          {message && <Alert variant="error" message={message} />}
          {!message && (
            <Typography className={classes.field} component="p">
              Please confirm that you wish to delete this {entityType}.{' '}
              {additionalMessage}
            </Typography>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained" disabled={deleting}>
            Cancel
          </Button>
          <Button
            disabled={disabled || deleting}
            onClick={onConfirm}
            className={classes.deleteButton}
            variant="contained">
            Delete
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ConfirmDelete.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  entityType: PropTypes.string
};

ConfirmDelete.defaultProps = {
  open: false
};

export default ConfirmDelete;
