import ws from '../utils/ws';

export const APPLY_FILTER = 'APPLY_FILTER';
export const APPLY_FILTER_PENDING = 'APPLY_FILTER_PENDING';
export const APPLY_FILTER_FULFILLED = 'APPLY_FILTER_FULFILLED';

export const APPLY_FILTER_COLUMN_SORT = 'APPLY_FILTER_COLUMN_SORT';
export const APPLY_FILTER_COLUMN_SORT_PENDING =
  'APPLY_FILTER_COLUMN_SORT_PENDING';
export const APPLY_FILTER_COLUMN_SORT_FULFILLED =
  'APPLY_FILTER_COLUMN_SORT_FULFILLED';

export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';

export const SET_FILTER_VALUE = 'SET_FILTER_VALUE';
export const CLEAR_FILTER_VALUES = 'CLEAR_FILTER_VALUES';
export const GET_FILTER_VALUES = 'GET_FILTER_VALUES';
export const GET_FILTER_VALUES_PENDING = 'GET_FILTER_VALUES_PENDING';
export const GET_FILTER_VALUES_FULFILLED = 'GET_FILTER_VALUES_FULFILLED';

export const applyFilter = (pageName, filterValues) => dispatch =>
  dispatch({
    type: APPLY_FILTER,
    payload: ws.post(`/profile/filter/page/${pageName}/set`, filterValues)
  });

export const applyFilterColumnSort = (pageName, column, sort) => dispatch =>
  dispatch({
    type: APPLY_FILTER_COLUMN_SORT,
    payload: ws.post(`/profile/filter/page/${pageName}/set_sort_order`, {
      column,
      sort
    })
  });

export const setPageSize = (pageName, pageSize) => dispatch =>
  dispatch({
    type: SET_PAGE_SIZE,
    payload: { pageName: pageName, pageSize: pageSize }
  });

export const setFilterValue = (
  pageName,
  filterValue,
  subFilterIndex
) => dispatch =>
  dispatch({
    type: SET_FILTER_VALUE,
    payload: {
      pageName: pageName,
      value: filterValue,
      subFilterIndex: subFilterIndex
    }
  });

export const clearFilterValues = (pageName, subFilterIndex) => dispatch =>
  dispatch({
    type: CLEAR_FILTER_VALUES,
    payload: { pageName: pageName, subFilterIndex: subFilterIndex }
  });

export const getFilterValues = pageName => dispatch =>
  dispatch({
    type: GET_FILTER_VALUES,
    payload: ws.get(`/profile/filter/page/${pageName}/`)
  });
