import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Alert } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetEditCellConfiguration,
  editCellConfiguration,
  verifyEditedConfigurationFile,
  setEditedCellConfiguration,
  saveEditedCellConfiguration
} from 'actions';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  TextareaAutosize,
  colors
} from '@material-ui/core';
import JSONPrettyMon from 'react-json-pretty/dist/monikai';
import JSONPretty from 'react-json-pretty';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1200,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: { padding: theme.spacing(2) },
  jsonContent: { padding: 0, paddingBottom: '0!important' },
  actions: {
    justifyContent: 'flex-end'
  },
  radars: {
    marginTop: theme.spacing(2)
  },
  radarContent: {
    padding: 0,
    paddingBottom: '0!important'
  },
  textArea: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.grey[400],
    color: colors.blue[900],
    padding: theme.spacing(2),
    width: '100%',
    fontFamily: 'monospace',
    fontSize: 13
  },
  alert: {
    marginBottom: theme.spacing(2)
  }
}));

const EditCellConfiguration = props => {
  const { cell, className } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    configurationSaved,
    configurationSaving,
    validationMessages,
    cellConfiguration,
    verificationConfiguration
  } = useSelector(state => state.editCellConfiguration);

  const handleClose = () => {
    dispatch(resetEditCellConfiguration());
  };

  const handleSave = () => {
    dispatch(saveEditedCellConfiguration(cell.id, cellConfiguration));
  };

  const handleVerify = () => {
    dispatch(verifyEditedConfigurationFile(verificationConfiguration.id));
  };

  return (
    <>
      <Button
        className={className}
        color="primary"
        size="small"
        variant="outlined"
        disabled={configurationSaved}
        onClick={() => dispatch(editCellConfiguration(cell.id))}>
        Edit
      </Button>
      <>
        {((cellConfiguration !== null && !configurationSaved) ||
          validationMessages.configurationFileContents) && (
          <Modal onClose={handleClose} open={true}>
            <Card className={classes.root}>
              <CardHeader title="Edit Cell Config" />
              <Divider />
              <CardContent className={classes.content}>
                {validationMessages.configurationFileContents && (
                  <Alert
                    className={classes.alert}
                    message="Unable to parse JSON"
                  />
                )}

                <TextareaAutosize
                  className={classes.textArea}
                  rowsMin={30}
                  rowsMax={30}
                  spellCheck={false}
                  onChange={e =>
                    dispatch(setEditedCellConfiguration(e.target.value))
                  }
                  value={cellConfiguration}
                />
              </CardContent>
              <CardActions className={classes.actions}>
                <Button onClick={handleClose} variant="contained">
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  disabled={configurationSaving || !cellConfiguration}>
                  Save
                </Button>
              </CardActions>
            </Card>
          </Modal>
        )}

        {configurationSaved && !validationMessages.configurationFileContents && (
          <Modal onClose={handleClose} open={true}>
            <Card className={classes.root}>
              <CardHeader title="Edit Cell Config" />
              <Divider />
              <CardContent className={classes.jsonContent}>
                <JSONPretty
                  mainStyle="padding:1em"
                  data={verificationConfiguration.configurationJson}
                  theme={JSONPrettyMon}
                />
              </CardContent>
              <CardActions className={classes.actions}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  // disabled={processing}
                >
                  Cancel
                </Button>
                {verificationConfiguration && (
                  <Button
                    onClick={handleVerify}
                    variant="contained"
                    color="primary"
                    // disabled={processing}
                  >
                    Confirm
                  </Button>
                )}
              </CardActions>
            </Card>
          </Modal>
        )}
      </>
    </>
  );
};

EditCellConfiguration.displayName = 'UploadCellConfig';

EditCellConfiguration.propTypes = {
  cell: PropTypes.object
};

EditCellConfiguration.defaultProps = {
  open: false
};

export default EditCellConfiguration;
