import ws from '../utils/ws';

/* Actions */

export const testSessionPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: TEST_SESSIONS_PAGINATED_LIST,
    payload: ws.get(`/sessions/testing/page/${pageNumber}/rows/${pageSize}`)
  });

export const testSessionUserScheduledPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: USER_SCHEDULED_TEST_SESSIONS_PAGINATED_LIST,
    payload: ws.get(
      `/sessions/testing/scheduled/page/${pageNumber}/rows/${pageSize}`
    )
  });

export const findSessionAnalysisOverview = (
  session,
  modelConfiguration,
  modelSet,
  torsoThreshold
) => dispatch =>
  dispatch({
    type: FIND_SESSION_ANALYSIS_OVERVIEW,
    payload: ws.get(
      '/sessions/' +
        session.id +
        '/analysis/overview' +
        '?modelConfigurationId=' +
        (modelConfiguration && modelConfiguration.id
          ? modelConfiguration.id
          : '') +
        '&modelSetId=' +
        (modelSet && modelSet.id ? modelSet.id : '') +
        (session ? '&sessionIds=' + session.id : '') +
        '&sessionSpecific=true' +
        '&torsoThreshold=' +
        torsoThreshold
    )
  });

export const setSessionDetailsTorsoThreshold = torsoThreshold => dispatch =>
  dispatch({
    type: SET_SESSION_DETAILS_TORSO_SENSITIVITY,
    payload: {
      torsoThreshold
    }
  });

export const findSessionSetupMatlabData = (sessionId, setupIndex) => dispatch =>
  dispatch({
    type: FIND_SESSION_SETUP_MATLAB_DATA,
    payload: ws.get(`/sessions/${sessionId}/setups/${setupIndex}/matlab_data`)
  });

export const findGroupsForTestSession = sessionId => dispatch =>
  dispatch({
    type: FIND_GROUPS_FOR_TEST_SESSION,
    payload: ws.get('/sessions/' + sessionId + '/groups/')
  });

export const findSessionEventListEvent = eventId => dispatch =>
  dispatch({
    type: FIND_SESSION_EVENT_LIST_DETAILS_EVENT,
    payload: ws.get('/events/' + eventId)
  });

export const excludeTestSessionEvent = (sessionId, eventId) => dispatch =>
  dispatch({
    type: EXCLUDE_TEST_SESSION_EVENT,
    payload: ws.post(
      '/sessions/' + sessionId + '/events/' + eventId + '/exclude'
    )
  });

export const includeTestSessionEvent = (sessionId, eventId) => dispatch =>
  dispatch({
    type: INCLUDE_TEST_SESSION_EVENT,
    payload: ws.post(
      '/sessions/' + sessionId + '/events/' + eventId + '/include'
    )
  });

export const closeTestSession = sessionId => dispatch =>
  dispatch({
    type: CLOSE_TEST_SESSION,
    payload: ws.post('/sessions/' + sessionId + '/close')
  });

export const testSessionSetupUpdate = values => dispatch =>
  dispatch({
    type: CREATE_TEST_SESSION_SETUP_UPDATE,
    payload: values
  });

export const testSessionSingleSetupUpdate = values => dispatch =>
  dispatch({
    type: CREATE_TEST_SESSION_SINGLE_SETUP_UPDATE,
    payload: values
  });

export const testSessionSetupSubectAreaContentsAdd = setup => dispatch =>
  dispatch({
    type: CREATE_TEST_SESSION_SETUP_SAC_ADD,
    payload: setup
  });

export const testSessionSetupSubectAreaContentsRemove = (
  setup,
  subjectAreaContentIndex
) => dispatch =>
  dispatch({
    type: CREATE_TEST_SESSION_SETUP_SAC_REMOVE,
    payload: {
      setup: setup,
      subjectAreaContent: { index: subjectAreaContentIndex }
    }
  });

export const testSessionSetupAdditionalItemsSubectAreaContentsAdd = setup => dispatch =>
  dispatch({
    type: CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_ADD,
    payload: setup
  });

export const testSessionSetupAdditionalItemsSubectAreaContentsRemove = (
  setup,
  subjectAreaContentIndex
) => dispatch =>
  dispatch({
    type: CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_REMOVE,
    payload: {
      setup: setup,
      subjectAreaContent: { index: subjectAreaContentIndex }
    }
  });

export const testSessionReferenceDataSetupSubectAreaContent = (
  setup,
  subjectAreaContentIndex
) => dispatch =>
  dispatch({
    type: SET_TEST_SESSION_REFERENCE_DATA_SETUP_SAC,
    payload: { setup: setup, subjectAreaContentIndex: subjectAreaContentIndex }
  });

export const testSessionSetupSubjectAreaContentUpdate = values => dispatch =>
  dispatch({
    type: CREATE_TEST_SESSION_SETUP_SAC_UPDATE,
    payload: values
  });

export const testSessionSetupAdditionalItemsSubjectAreaContentUpdate = values => dispatch =>
  dispatch({
    type: CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_UPDATE,
    payload: values
  });

export const setTestSessionListCommentsSession = session => dispatch =>
  dispatch({
    type: SET_TEST_SESSION_LIST_COMMENTS_SESSION,
    payload: session
  });

export const setUserScheduledTestSessionListCommentsSession = session => dispatch =>
  dispatch({
    type: SET_USER_SCHEDULED_TEST_SESSION_LIST_COMMENTS_SESSION,
    payload: session
  });

export const setTestSessionDetailsCommentsEvent = event => dispatch =>
  dispatch({
    type: SET_TEST_SESSION_DETAILS_COMMENTS_EVENT,
    payload: event
  });

export const selectTestSessionListSession = session => dispatch =>
  dispatch({
    type: SELECT_TEST_SESSION_LIST_SESSION,
    payload: session.id
  });

export const selectAllTestSessionListSessions = () => dispatch =>
  dispatch({
    type: SELECT_ALL_TEST_SESSION_LIST_SESSIONS,
    payload: ws.get(`/sessions/testing/ids`)
  });

export const deselectAllTestSessionListSessions = () => dispatch =>
  dispatch({
    type: DESELECT_ALL_TEST_SESSION_LIST_SESSIONS
  });

export const clearTestSessionListSelectedSessions = () => dispatch =>
  dispatch({
    type: CLEAR_TEST_SESSION_LIST_SELECTED_SESSIONS
  });

export const setSessionDetailsAnalysisPredictionType = predictionType => dispatch =>
  dispatch({
    type: SET_SESSION_DETAILS_ANALYSIS_PREDICTION_TYPE,
    payload: predictionType
  });

export const setSessionDetailsModelConfiguration = modelConfiguration => dispatch =>
  dispatch({
    type: SET_SESSION_DETAILS_MODEL_CONFIGURATION,
    payload: modelConfiguration
  });

export const setSessionDetailsModelSet = modelSet => dispatch =>
  dispatch({
    type: SET_SESSION_DETAILS_MODEL_SET,
    payload: modelSet
  });

export const findSelectionTestSessions = () => dispatch =>
  dispatch({
    type: FIND_SELECTION_TEST_SESSIONS,
    payload: ws.get(`/sessions/selection_test_sessions/`)
  });

export const findSelectionTrainingSessions = () => dispatch =>
  dispatch({
    type: FIND_SELECTION_TRAINING_SESSIONS,
    payload: ws.get(`/sessions/selection_training_sessions/`)
  });

export const findSessionSubModels = sessionId => dispatch =>
  dispatch({
    type: FIND_SESSION_SUB_MODELS,
    payload: ws.get(`/sessions/${sessionId}/events/sub_models`)
  });

export const createTestingSummary = () => dispatch => {
  dispatch({
    type: CREATE_TESTING_SESSION_SUMMARY,
    payload: ws.post(`/testing/sessions/summary/create`)
  });
};

export const findLatestTestingSummary = () => dispatch => {
  dispatch({
    type: FIND_LATEST_TESTING_SESSION_SUMMARY,
    payload: ws.get(`/testing/sessions/summary/latest`)
  });
};

/* Action Constants */

export const SET_TEST_SESSION_LIST_COMMENTS_SESSION =
  'SET_TEST_SESSION_LIST_COMMENTS_SESSION';

export const SET_USER_SCHEDULED_TEST_SESSION_LIST_COMMENTS_SESSION =
  'SET_USER_SCHEDULED_TEST_SESSION_LIST_COMMENTS_SESSION';

export const SET_TEST_SESSION_DETAILS_COMMENTS_EVENT =
  'SET_TEST_SESSION_DETAILS_COMMENTS_EVENT';

export const CREATE_TEST_SESSION_SINGLE_SETUP_UPDATE =
  'CREATE_TEST_SESSION_SINGLE_SETUP_UPDATE';
export const CREATE_TEST_SESSION_SETUP_UPDATE =
  'CREATE_TEST_SESSION_SETUP_UPDATE';
export const CREATE_TEST_SESSION_SETUP_SAC_ADD =
  'CREATE_TEST_SESSION_SETUP_SAC_ADD';
export const CREATE_TEST_SESSION_SETUP_SAC_REMOVE =
  'CREATE_TEST_SESSION_SETUP_SAC_REMOVE';
export const SET_TEST_SESSION_REFERENCE_DATA_SETUP_SAC =
  'SET_TEST_SESSION_REFERENCE_DATA_SETUP_SAC';
export const CREATE_TEST_SESSION_SETUP_SAC_UPDATE =
  'CREATE_TEST_SESSION_SETUP_SAC_UPDATE';

export const CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_ADD =
  'CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_ADD';
export const CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_REMOVE =
  'CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_REMOVE';
export const CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_UPDATE =
  'CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_UPDATE';

export const CREATE_TEST_SESSION_SETUP_OVERRIDE_VALUE_UPDATE =
  'CREATE_TEST_SESSION_SETUP_OVERRIDE_VALUE_UPDATE';
export const CREATE_TEST_SESSION_SETUP_VALUE_UPDATE =
  'CREATE_TEST_SESSION_SETUP_VALUE_UPDATE';
export const FIND_SESSIONS = 'FIND_SESSIONS';
export const FIND_SESSIONS_PENDING = 'FIND_SESSIONS_PENDING';
export const FIND_SESSIONS_FULFILLED = 'FIND_SESSIONS_FULFILLED';
export const SETUP_SCENARIO_SESSION_SEARCH_TERM_UPDATE =
  'SETUP_SCENARIO_SESSION_SEARCH_TERM_UPDATE';

export const FIND_SESSION_ANALYSIS_OVERVIEW = 'FIND_SESSION_ANALYSIS_OVERVIEW';
export const FIND_SESSION_ANALYSIS_OVERVIEW_PENDING =
  'FIND_SESSION_ANALYSIS_OVERVIEW_PENDING';
export const FIND_SESSION_ANALYSIS_OVERVIEW_FULFILLED =
  'FIND_SESSION_ANALYSIS_OVERVIEW_FULFILLED';

export const FIND_SESSION_SCENARIO_ANALYSIS_OVERVIEW =
  'FIND_SESSION_SCENARIO_ANALYSIS_OVERVIEW';
export const FIND_SESSION_SCENARIO_ANALYSIS_OVERVIEW_PENDING =
  'FIND_SESSION_SCENARIO_ANALYSIS_OVERVIEW_PENDING';
export const FIND_SESSION_SCENARIO_ANALYSIS_OVERVIEW_FULFILLED =
  'FIND_SESSION_SCENARIO_ANALYSIS_OVERVIEW_FULFILLED';

export const SET_SESSION_DETAILS_TORSO_SENSITIVITY =
  'SET_SESSION_DETAILS_TORSO_SENSITIVITY';

export const DESELECT_ALL_TEST_SESSION_LIST_SESSIONS =
  'DESELECT_ALL_TEST_SESSION_LIST_SESSIONS';

export const SELECT_ALL_TEST_SESSION_LIST_SESSIONS =
  'SELECT_ALL_TEST_SESSION_LIST_SESSIONS';
export const SELECT_ALL_TEST_SESSION_LIST_SESSIONS_PENDING =
  'SELECT_ALL_TEST_SESSION_LIST_SESSIONS_PENDING';
export const SELECT_ALL_TEST_SESSION_LIST_SESSIONS_FULFILLED =
  'SELECT_ALL_TEST_SESSION_LIST_SESSIONS_FULFILLED';

export const FIND_GROUPS_FOR_TEST_SESSION = 'FIND_GROUPS_FOR_TEST_SESSION';
export const FIND_GROUPS_FOR_TEST_SESSION_PENDING =
  'FIND_GROUPS_FOR_TEST_SESSION_PENDING';
export const FIND_GROUPS_FOR_TEST_SESSION_FULFILLED =
  'FIND_GROUPS_FOR_TEST_SESSION_FULFILLED';

export const FIND_SESSION_EVENT_LIST_DETAILS_EVENT =
  'FIND_SESSION_EVENT_LIST_DETAILS_EVENT';
export const FIND_SESSION_EVENT_LIST_DETAILS_EVENT_PENDING =
  'FIND_SESSION_EVENT_LIST_DETAILS_EVENT_PENDING';
export const FIND_SESSION_EVENT_LIST_DETAILS_EVENT_FULFILLED =
  'FIND_SESSION_EVENT_LIST_DETAILS_EVENT_FULFILLED';

export const EXCLUDE_TEST_SESSION_EVENT = 'EXCLUDE_TEST_SESSION_EVENT';
export const EXCLUDE_TEST_SESSION_EVENT_PENDING =
  'EXCLUDE_TEST_SESSION_EVENT_PENDING';
export const EXCLUDE_TEST_SESSION_EVENT_FULFILLED =
  'EXCLUDE_TEST_SESSION_EVENT_FULFILLED';

export const INCLUDE_TEST_SESSION_EVENT = 'INCLUDE_TEST_SESSION_EVENT';
export const INCLUDE_TEST_SESSION_EVENT_PENDING =
  'INCLUDE_TEST_SESSION_EVENT_PENDING';
export const INCLUDE_TEST_SESSION_EVENT_FULFILLED =
  'INCLUDE_TEST_SESSION_EVENT_FULFILLED';

export const CLOSE_TEST_SESSION = 'CLOSE_TEST_SESSION';

export const TEST_SESSIONS_PAGINATED_LIST = 'TEST_SESSIONS_PAGINATED_LIST';
export const TEST_SESSIONS_PAGINATED_LIST_PENDING =
  'TEST_SESSIONS_PAGINATED_LIST_PENDING';
export const TEST_SESSIONS_PAGINATED_LIST_FULFILLED =
  'TEST_SESSIONS_PAGINATED_LIST_FULFILLED';

export const USER_SCHEDULED_TEST_SESSIONS_PAGINATED_LIST =
  'USER_SCHEDULED_TEST_SESSIONS_PAGINATED_LIST';
export const USER_SCHEDULED_TEST_SESSIONS_PAGINATED_LIST_PENDING =
  'USER_SCHEDULED_TEST_SESSIONS_PAGINATED_LIST_PENDING';
export const USER_SCHEDULED_TEST_SESSIONS_PAGINATED_LIST_FULFILLED =
  'USER_SCHEDULED_TEST_SESSIONS_PAGINATED_LIST_FULFILLED';

export const FIND_SESSION_SETUP_MATLAB_DATA = 'FIND_SESSION_SETUP_MATLAB_DATA';
export const FIND_SESSION_SETUP_MATLAB_DATA_PENDING =
  'FIND_SESSION_SETUP_MATLAB_DATA_PENDING';
export const FIND_SESSION_SETUP_MATLAB_DATA_FULFILLED =
  'FIND_SESSION_SETUP_MATLAB_DATA_FULFILLED';

export const SELECT_TEST_SESSION_LIST_SESSION =
  'SELECT_TEST_SESSION_LIST_SESSION';
export const SELECT_TEST_SESSION_LIST_SESSIONS =
  'SELECT_TEST_SESSION_LIST_SESSIONS';

export const CLEAR_TEST_SESSION_LIST_SELECTED_SESSIONS =
  'CLEAR_TEST_SESSION_LIST_SELECTED_SESSIONS';

export const SET_SESSION_DETAILS_ANALYSIS_PREDICTION_TYPE =
  'SET_SESSION_DETAILS_ANALYSIS_PREDICTION_TYPE';

export const SET_SESSION_DETAILS_MODEL_CONFIGURATION =
  'SET_SESSION_DETAILS_MODEL_CONFIGURATION';

export const SET_SESSION_DETAILS_MODEL_SET = 'SET_SESSION_DETAILS_MODEL_SET';

export const FIND_SELECTION_TEST_SESSIONS = 'FIND_SELECTION_TEST_SESSIONS';
export const FIND_SELECTION_TEST_SESSIONS_PENDING =
  'FIND_SELECTION_TEST_SESSIONS_PENDING';
export const FIND_SELECTION_TEST_SESSIONS_FULFILLED =
  'FIND_SELECTION_TEST_SESSIONS_FULFILLED';

export const FIND_SELECTION_TRAINING_SESSIONS =
  'FIND_SELECTION_TRAINING_SESSIONS';
export const FIND_SELECTION_TRAINING_SESSIONS_PENDING =
  'FIND_SELECTION_TRAINING_SESSIONS_PENDING';
export const FIND_SELECTION_TRAINING_SESSIONS_FULFILLED =
  'FIND_SELECTION_TRAINING_SESSIONS_FULFILLED';

export const FIND_SESSION_SUB_MODELS = 'FIND_SESSION_SUB_MODELS';
export const FIND_SESSION_SUB_MODELS_PENDING =
  'FIND_SESSION_SUB_MODELS_PENDING';
export const FIND_SESSION_SUB_MODELS_FULFILLED =
  'FIND_SESSION_SUB_MODELS_FULFILLED';

export const CREATE_TESTING_SESSION_SUMMARY = 'CREATE_TESTING_SESSION_SUMMARY';
export const CREATE_TESTING_SESSION_SUMMARY_PENDING =
  'CREATE_TESTING_SESSION_SUMMARY_PENDING';
export const CREATE_TESTING_SESSION_SUMMARY_FULFILLED =
  'CREATE_TESTING_SESSION_SUMMARY_FULFILLED';

export const FIND_LATEST_TESTING_SESSION_SUMMARY =
  'FIND_LATEST_TESTING_SESSION_SUMMARY';
export const FIND_LATEST_TESTING_SESSION_SUMMARY_PENDING =
  'FIND_LATEST_TESTING_SESSION_SUMMARY_PENDING';
export const FIND_LATEST_TESTING_SESSION_SUMMARY_FULFILLED =
  'FIND_LATEST_TESTING_SESSION_SUMMARY_FULFILLED';
