import * as actionTypes from 'actions';

const initialState = {
  cellRadarLoading: false,
  cellRadar: {
    createdBy: {}
  }
};

const cellRadarDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_CELL_RADAR_PENDING: {
      return {
        ...initialState,
        customers: state.customers,
        venues: state.venues,
        cellLoading: true
      };
    }

    case actionTypes.FIND_CELL_RADAR_FULFILLED: {
      return {
        ...state,
        cellRadarLoading: false,
        cellRadar: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default cellRadarDetailsReducer;
