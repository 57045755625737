import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from 'components/SearchBar/components';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Button,
  Divider,
  CardHeader,
  Tabs,
  Tab,
  LinearProgress
} from '@material-ui/core';
import {
  findSelectEventDataSetDataSets,
  setSelectEventDataSetOpen,
  setSelectEventDataSetTab,
  setSelectEventDataSetSearch
} from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1020,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    minHeight: 485,
    maxHeight: 485
  },
  searchContainer: {
    padding: theme.spacing(2)
  },
  actionColumn: {
    width: 100
  },
  buttonExtraSmall: {
    padding: '1px 8px',
    fontSize: '0.8125rem'
  },
  buttonCell: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const SelectEventDataSet = props => {
  const { className, onSelectDataSet } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const { open, eventDataSets, loading, tab, search } = useSelector(
    state => state.selectEventDataSet
  );

  const tabs = [
    { value: 'walk_analysis', label: 'Walk Analaysis' },
    { value: 'model_data', label: 'Model Data Set' },
    { value: 'other', label: 'Other' }
  ];

  const tabTypes = {
    walk_analysis: 'WALK_ANALYSIS',
    model_data: 'MODEL_DATA',
    other: null
  };

  const filteredDataSets = eventDataSets
    .filter(dataSet => dataSet.type === tabTypes[tab])
    .filter(
      dataSet =>
        !search || dataSet.name.toUpperCase().indexOf(search.toUpperCase()) > -1
    );

  useEffect(() => {
    let mounted = true;

    if (mounted && open) {
      dispatch(findSelectEventDataSetDataSets());
    }

    return () => {
      mounted = false;
    };
  }, [open]);

  if (!open) {
    return null;
  }

  const handleTabChange = (_, tab) => {
    dispatch(setSelectEventDataSetTab(tab));
  };

  const handleClose = () => {
    dispatch(setSelectEventDataSetOpen(false));
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader title="Select Walk Collection" />
        <Divider />
        {loading && <LinearProgress />}
        <CardContent className={classes.content}>
          <div className={classes.searchContainer}>
            <Search
              placeholder="Walk Collection Name"
              value={search}
              onSearchValueChange={value =>
                dispatch(setSelectEventDataSetSearch(value))
              }
              className={classes.search}
              hideSearchButton={true}
            />
          </div>
          <Divider />

          <Tabs
            className={classes.tabs}
            onChange={handleTabChange}
            scrollButtons="auto"
            value={tab}
            variant="scrollable">
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
          <Divider />

          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.actionColumn} />
                  <TableCell>Name</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Filters</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDataSets.map(dataSet => (
                  <TableRow hover key={dataSet.id}>
                    <TableCell className={classes.buttonCell}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          dispatch(setSelectEventDataSetOpen(false));
                          onSelectDataSet(dataSet);
                        }}
                        className={classes.actionButton}>
                        Select
                      </Button>
                    </TableCell>
                    <TableCell>{dataSet.name}</TableCell>
                    <TableCell>{dataSet.createdBy?.displayName}</TableCell>
                    <TableCell>{dataSet.nonEmptyFilterCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SelectEventDataSet.displayName = 'SelectEventDataSet';

SelectEventDataSet.propTypes = {
  className: PropTypes.string
};

SelectEventDataSet.defaultProps = {};

export default SelectEventDataSet;
