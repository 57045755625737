import ws from '../utils/ws';

/* Actions */

export const cellVenueInstallationValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CELL_VENUE_INSTALLATION_VALUE_UPDATE,
    payload: values
  });

export const cellVenueInstallationValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CELL_VENUE_INSTALLATION_VALUE_RESET
  });

export const setConfirmDeleteCellVenueInstallationOpen = open => dispatch =>
  dispatch({
    type: SET_CONFIRM_DELETE_CELL_VENUE_INSTALLATION_OPEN,
    payload: open
  });

export const editCellVenueInstallation = (
  cell,
  venueInstallationGuid
) => dispatch =>
  dispatch({
    type: EDIT_CELL_VENUE_INSTALLATION,
    payload: ws.get(
      `/cells/${cell.id}/venue_installations/${venueInstallationGuid}`
    )
  });

export const saveCellVenueInstallation = (values, cell) => dispatch =>
  dispatch({
    type: SAVE_CELL_VENUE_INSTALLATION,
    payload: ws.post(`/cells/${cell.id}/venue_installations/`, {
      ...values
    })
  });

export const deleteCellVenueInstallation = (
  cell,
  venueInstallationGuid
) => dispatch =>
  dispatch({
    type: DELETE_CELL_VENUE_INSTALLATION,
    payload: ws.post(
      `/cells/${cell.id}/venue_installations/${venueInstallationGuid}/delete`
    )
  });

export const setCreateCellVenueInstallationOpen = open => dispatch =>
  dispatch({
    type: SET_CREATE_CELL_VENUE_INSTALLATION_OPEN,
    payload: open
  });

/* Constants */

export const SET_CREATE_CELL_VENUE_INSTALLATION_OPEN =
  'SET_CREATE_CELL_VENUE_INSTALLATION_OPEN';

export const CREATE_CELL_VENUE_INSTALLATION_VALUE_UPDATE =
  'CREATE_CELL_VENUE_INSTALLATION_VALUE_UPDATE';
export const CREATE_CELL_VENUE_INSTALLATION_VALUE_RESET =
  'CREATE_CELL_VENUE_INSTALLATION_VALUE_RESET';

export const SET_CONFIRM_DELETE_CELL_VENUE_INSTALLATION_OPEN =
  'SET_CONFIRM_DELETE_CELL_VENUE_INSTALLATION_OPEN';

export const EDIT_CELL_VENUE_INSTALLATION = 'EDIT_CELL_VENUE_INSTALLATION';
export const EDIT_CELL_VENUE_INSTALLATION_PENDING =
  'EDIT_CELL_VENUE_INSTALLATION_PENDING';
export const EDIT_CELL_VENUE_INSTALLATION_FULFILLED =
  'EDIT_CELL_VENUE_INSTALLATION_FULFILLED';

export const SAVE_CELL_VENUE_INSTALLATION = 'SAVE_CELL_VENUE_INSTALLATION';
export const SAVE_CELL_VENUE_INSTALLATION_FULFILLED =
  'SAVE_CELL_VENUE_INSTALLATION_FULFILLED';

export const DELETE_CELL_VENUE_INSTALLATION = 'DELETE_CELL_VENUE_INSTALLATION';
export const DELETE_CELL_VENUE_INSTALLATION_FULFILLED =
  'DELETE_CELL_VENUE_INSTALLATION_FULFILLED';
