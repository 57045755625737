import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Label } from 'components';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  Grid,
  colors,
  Typography
} from '@material-ui/core';
import {
  POCKET_ITEMS_SUBJECT_AREAS,
  POCKET_LOCATIONS,
  SUBJECT_AREAS
} from 'common/constants';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 850,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  selectedItem: {
    backgroundColor: colors.green[200],
    '&:hover': {
      backgroundColor: colors.green[300]
    }
  },
  itemCountLabel: {
    marginLeft: theme.spacing(2)
  },
  divider: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.grey[300]
  },
  subjectArea: {
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase'
  },
  createNewIconButton: {
    // borderColor: colors.green[600]
  },
  createNewIcon: {
    color: colors.green[600],
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20
  }
}));

const SelectSubjectAreaContents = props => {
  const {
    open,
    onClose,
    objectTypes,
    subjectAreaContents,
    onSelectParticipant,
    onSelectContent,
    onCreateObjectType,
    className,
    ...rest
  } = props;

  const classes = useStyles();
  const [pocketSubjectArea, setPocketSubjectArea] = useState(null);

  const pocketSubjectAreas = [];
  for (let i = 0; i < Object.keys(SUBJECT_AREAS).length; i++) {
    const subjectArea = Object.keys(SUBJECT_AREAS)[i];

    if (POCKET_ITEMS_SUBJECT_AREAS.indexOf(SUBJECT_AREAS[subjectArea]) < 0) {
      pocketSubjectAreas.push({
        subjectArea: subjectArea,
        pocketLocation: null
      });
    } else {
      for (let j = 0; j < Object.keys(POCKET_LOCATIONS).length; j++) {
        pocketSubjectAreas.push({
          subjectArea: subjectArea,
          pocketLocation: Object.keys(POCKET_LOCATIONS)[j]
        });
      }
    }
  }

  const handleClose = () => {
    setPocketSubjectArea(null);
    onClose();
  };

  if (!open) {
    return null;
  }

  const handleSelectPocketSubjectArea = pocketSubjectArea => {
    setPocketSubjectArea(pocketSubjectArea);
  };

  const handleClearPocketSubjectArea = () => {
    setPocketSubjectArea(null);
  };

  const handleSelectObjectType = objectType => {
    onSelectContent({
      subjectArea: pocketSubjectArea.subjectArea,
      pocketLocation: pocketSubjectArea.pocketLocation,
      objectType
    });
  };

  const itemSelected = (pocketSubjectArea, objectType) => {
    return (
      subjectAreaContents
        .map(
          sac =>
            sac.subjectArea + '_' + sac.pocketLocation + '_' + sac.objectType.id
        )
        .indexOf(
          pocketSubjectArea.subjectArea +
            '_' +
            pocketSubjectArea.pocketLocation +
            '_' +
            objectType.id
        ) > -1
    );
  };

  const areaItemCount = pocketSubjectArea => {
    return subjectAreaContents.filter(
      sac =>
        sac.subjectArea === pocketSubjectArea.subjectArea &&
        sac.pocketLocation === pocketSubjectArea.pocketLocation
    ).length;
  };

  const SubjectArea = props => {
    const { pocketSubjectArea } = props;
    const { subjectArea, pocketLocation } = pocketSubjectArea;
    const count = areaItemCount(pocketSubjectArea);

    return (
      <Button
        variant="outlined"
        fullWidth
        onClick={() => handleSelectPocketSubjectArea(pocketSubjectArea)}>
        {SUBJECT_AREAS[subjectArea]}{' '}
        {POCKET_LOCATIONS[pocketLocation] &&
          ' - ' + POCKET_LOCATIONS[pocketLocation] + ' Pocket'}
        {count > 0 && (
          <Label color={colors.blue[600]} className={classes.itemCountLabel}>
            {count}
          </Label>
        )}
      </Button>
    );
  };

  const ObjectType = props => {
    const { pocketSubjectArea, objectType } = props;
    const selected = itemSelected(pocketSubjectArea, objectType);

    return (
      <Button
        className={selected ? classes.selectedItem : ''}
        variant="outlined"
        fullWidth
        onClick={() => handleSelectObjectType(objectType)}>
        {objectType.name}
      </Button>
    );
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Select Items" />
        <Divider />

        <CardContent>
          {pocketSubjectArea && (
            <>
              <Typography
                className={classes.subjectArea}
                color="inherit"
                align="center"
                gutterBottom
                variant="h4">
                {SUBJECT_AREAS[pocketSubjectArea.subjectArea]}
                {POCKET_LOCATIONS[pocketSubjectArea.pocketLocation] &&
                  ' - ' +
                    POCKET_LOCATIONS[pocketSubjectArea.pocketLocation] +
                    ' Pocket'}
              </Typography>
              <Divider className={classes.divider} />
            </>
          )}
          {!pocketSubjectArea && (
            <Grid container spacing={1}>
              {pocketSubjectAreas.map(pocketSubjectArea => (
                <Grid
                  key={
                    pocketSubjectArea.subjectArea +
                    pocketSubjectArea.pocketLocation
                  }
                  item
                  xs={12}
                  sm={6}
                  lg={4}>
                  <SubjectArea pocketSubjectArea={pocketSubjectArea} />
                </Grid>
              ))}
            </Grid>
          )}
          {pocketSubjectArea && (
            <Grid container spacing={1}>
              {objectTypes.map(objectType => (
                <Grid key={objectType.id} item xs={12} sm={6} lg={4}>
                  <ObjectType
                    pocketSubjectArea={pocketSubjectArea}
                    objectType={objectType}
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={6} lg={4}>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  onClick={onCreateObjectType}>
                  Create New <AddCircleIcon className={classes.createNewIcon} />
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          {pocketSubjectArea && (
            <Button variant="contained" onClick={handleClearPocketSubjectArea}>
              <ArrowLeftIcon />
              Add More
            </Button>
          )}
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SelectSubjectAreaContents.displayName = 'ParticipantSelect';

SelectSubjectAreaContents.propTypes = {
  className: PropTypes.string
};

export default SelectSubjectAreaContents;
