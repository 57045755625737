import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLOTHING_TYPES,
  POCKET_LOCATIONS,
  SUBJECT_AREAS,
  SERVICE_POLLING_FREQ
} from 'common/constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Alert, SubjectAreaContents } from 'components';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  colors,
  Typography
} from '@material-ui/core';
import {
  testSessionPaginatedList,
  findSession,
  walkSession,
  setWalkSessionSetup,
  resetSessionWalk,
  clearSessionWalk,
  closeTestSession,
  setWalkSession,
  setWalkSessionChecklistItemsComplete,
  findWalkSessionPeriodicUpdate
} from 'actions';
import SessionWalkStatus from 'components/SessionWalkStatus';
import Label from '../Label';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1000,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  inverseContent: {
    margin: theme.spacing(3) * -1
  },
  files: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  createdSessionId: {
    marginTop: theme.spacing(6)
  },
  headlineData: {
    fontSize: 30
  },
  pocketItems: {
    '& td': {
      textAlign: 'center',
      width: '50%',
      border: `1px solid ${theme.palette.divider}`,
      fontSize: '1.4em',
      lineHeight: 'normal'
    }
  },
  pocketItemsHeader: {
    '& td': {
      fontSize: '1.4em'
    }
  },
  pocketItemsHeaderLabel: {
    backgroundColor: '#F4F6F8',
    textAlign: 'left'
  },
  walkCount: {
    '& td': {
      textAlign: 'center',
      width: '33%',
      border: `1px solid ${theme.palette.divider}`,
      fontSize: '4em',
      lineHeight: 'normal'
    },
    marginBottom: theme.spacing(3)
  },
  walkCountHeader: {
    '& td': {
      fontSize: '1.4em'
    }
  },
  subjectInfo: {
    '& td': {
      textAlign: 'center',
      width: '50%',
      border: `1px solid ${theme.palette.divider}`,
      fontSize: '1.4em',
      lineHeight: 'normal'
    },
    marginBottom: theme.spacing(3)
  },
  socketConnectionStatus: {
    marginTop: 8
  },
  disconnected: {
    opacity: 0.4
  },
  setups: {
    '& td': {
      verticalAlign: 'top'
    }
  },
  subjectAreaContents: {
    '& li': {
      listStyleType: 'none'
    }
  },
  midSubjectAreaContentsItem: {
    marginBottom: theme.spacing(2)
  },
  checklistItemsContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  checklistItems: {
    display: 'inline-block'
  },
  checklistItem: {
    fontWeight: 'normal',
    marginBottom: theme.spacing(2)
  },
  warningCell: {
    color: colors.red[600],
    padding: theme.spacing(4)
  }
}));

const WalkSession = props => {
  const { open, onClose, className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { loading, loaded, setup, session, checklistComplete } = useSelector(
    state => state.setupScenarioSessionWalk
  );

  const { pageNumber, pageSize } = useSelector(state => state.testSessionList);

  useEffect(() => {
    let intervalId;
    if (open && setup && session && checklistComplete) {
      intervalId = setInterval(() => {
        dispatch(findWalkSessionPeriodicUpdate(session.id));
      }, SERVICE_POLLING_FREQ);
    }

    return () => clearInterval(intervalId);
  }, [open, setup, session, checklistComplete]);

  if (session && setup && !loading && !loaded && checklistComplete) {
    dispatch(walkSession(session.id, setup.index));
  }

  const handleSelectSetup = setup => {
    dispatch(setWalkSessionSetup(setup));
  };

  const handleClose = () => {
    dispatch(resetSessionWalk());
    dispatch(testSessionPaginatedList(pageNumber, pageSize));
    dispatch(findSession(session.id));
    dispatch(closeTestSession(session.id));
    onClose();
  };

  const handleWalkDifferentSetup = () => {
    Promise.resolve(dispatch(findSession(session.id))).then(response => {
      dispatch(setWalkSession(response.value.data.result));
    });
    dispatch(clearSessionWalk());
  };

  const handleChecklistComplete = () => {
    dispatch(setWalkSessionChecklistItemsComplete(true));
  };

  if (!open || !session) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={!setup ? 'Select Setup' : 'Walk Setup'} />
        <Divider />
        {loading && <LinearProgress />}
        <CardContent className={classes.content}>
          {!loading && (
            <div>
              {!setup ? (
                <div className={classes.inverseContent}>
                  <PerfectScrollbar>
                    <Table className={classes.setups}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Setup</TableCell>
                          <TableCell>Action</TableCell>
                          {session.durationType === 'WALK_COUNT' && (
                            <>
                              <TableCell>Walks</TableCell>
                              <TableCell>Walk Status</TableCell>
                            </>
                          )}
                          <TableCell>Contents</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {session.setups.map(setup => (
                          <TableRow key={setup.index} hover>
                            <TableCell>{setup.index + 1}</TableCell>
                            <TableCell>
                              <Button
                                disabled={
                                  session.durationType === 'WALK_COUNT' &&
                                  setup.walkStatus !== 'COMPLETE'
                                }
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => handleSelectSetup(setup)}>
                                Select
                              </Button>
                            </TableCell>
                            {session.durationType === 'WALK_COUNT' && (
                              <>
                                <TableCell>
                                  {setup.associatedWalkCount} of{' '}
                                  {setup.walkCount}
                                </TableCell>
                                <TableCell>
                                  <SessionWalkStatus
                                    status={setup.walkStatus}
                                  />
                                </TableCell>
                              </>
                            )}
                            <TableCell>
                              <SubjectAreaContents
                                subjectAreaContents={setup.subjectAreaContents}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </PerfectScrollbar>
                </div>
              ) : (
                <>
                  {!checklistComplete ? (
                    <div>
                      <Typography align="center" gutterBottom variant="h3">
                        Session Checklist
                      </Typography>
                      <div className={classes.checklistItemsContainer}>
                        <div className={classes.checklistItems}>
                          {session.setupChecklistItems[setup.index].map(
                            (checklistItem, i) => (
                              <div key={checklistItem}>
                                <Typography
                                  className={classes.checklistItem}
                                  variant="h5">
                                  {checklistItem}
                                </Typography>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {session.walkStatus == 'COMPLETE' ? (
                        <div>
                          <Alert
                            message={
                              'You have walked all ' +
                              setup.walkCount +
                              ' walks for this setup.'
                            }
                          />
                          <audio autoPlay>
                            <source src="/sounds/alert.mp3" type="audio/mpeg" />
                          </audio>
                        </div>
                      ) : (
                        <div>
                          {/* <SessionWalkProgress sessionWalk={sessionWalk} /> */}
                          <Table className={classes.subjectInfo}>
                            <TableBody>
                              <TableRow>
                                <TableCell className={classes.headlineData}>
                                  {session.cell && session.cell.name}
                                </TableCell>
                                <TableCell className={classes.headlineData}>
                                  Session {session.shortId} / Setup{' '}
                                  {setup.index + 1}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.headlineData}>
                                  {session.participant.name}
                                </TableCell>
                                <TableCell className={classes.headlineData}>
                                  {session.clothingType
                                    ? CLOTHING_TYPES[session.clothingType] +
                                      ' Clothing'
                                    : 'No Clothing Type'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Table className={classes.walkCount}>
                            <TableBody>
                              <TableRow
                                selected
                                className={classes.walkCountHeader}>
                                <TableCell>Walks Uploaded</TableCell>
                                {session.durationType === 'WALK_COUNT' && (
                                  <TableCell>Remaining</TableCell>
                                )}
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  {setup.associatedWalkCount}
                                </TableCell>
                                {session.durationType === 'WALK_COUNT' && (
                                  <TableCell>
                                    {setup.walkCount -
                                      setup.associatedWalkCount}
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Table className={classes.pocketItems}>
                            <TableBody>
                              {Object.keys(setup.subjectAreas).map(
                                subjectArea => (
                                  <TableRow
                                    key={subjectArea}
                                    className={classes.pocketItemsHeader}>
                                    <TableCell
                                      className={
                                        classes.pocketItemsHeaderLabel
                                      }>
                                      {SUBJECT_AREAS[subjectArea]}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                      <ul
                                        className={classes.subjectAreaContents}>
                                        {setup.subjectAreas[subjectArea].map(
                                          (content, i) => (
                                            <li
                                              className={
                                                i <
                                                setup.subjectAreas[subjectArea]
                                                  .length -
                                                  1
                                                  ? classes.midSubjectAreaContentsItem
                                                  : ''
                                              }
                                              key={i}>
                                              {content.objectType
                                                ? content.objectType.name
                                                : ''}{' '}
                                              {content.objectModel &&
                                                '/ ' +
                                                  content.objectModel.name}{' '}
                                              {content.pocketLocation &&
                                                '(' +
                                                  POCKET_LOCATIONS[
                                                    content.pocketLocation
                                                  ] +
                                                  ' Pocket)'}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              {Object.keys(setup.subjectAreas).length === 0 ? (
                                <TableRow className={classes.pocketItemsHeader}>
                                  <TableCell
                                    className={classes.warningCell}
                                    colSpan={2}>
                                    No Weapons, No Additional Items In Pockets
                                    and No Accessories
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <>
                                  {Object.keys(
                                    setup.nonObjectOfInterestSubjectAreas
                                  ).length === 0 && (
                                    <TableRow
                                      className={classes.pocketItemsHeader}>
                                      <TableCell
                                        className={classes.warningCell}
                                        colSpan={2}>
                                        No Additional Items In Pockets and No
                                        Accessories
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            {!setup || !checklistComplete ? 'Close' : 'Done'}
          </Button>
          {setup && !checklistComplete && (
            <Button
              disabled={!setup}
              onClick={handleChecklistComplete}
              variant="contained"
              color="primary">
              Continue
            </Button>
          )}
          {(setup && setup.walkStatus) === 'COMPLETE' && (
            <Button onClick={handleWalkDifferentSetup} variant="contained">
              Walk Another Setup
            </Button>
          )}
        </CardActions>
      </Card>
    </Modal>
  );
};

WalkSession.displayName = 'EventDetails';

WalkSession.propTypes = {
  event: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

WalkSession.defaultProps = {
  open: false
};

export default WalkSession;
