import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Search } from 'components/SearchBar/components';
import { useSelector } from 'react-redux';
import { CellStatus } from 'components';
import { CELL_STATUSES } from 'common/constants';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Checkbox,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 850,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  progress: {
    marginBottom: theme.spacing(2)
  },
  fileName: {
    marginBottom: theme.spacing(2)
  },
  image: {
    width: '100%'
  },
  details: {
    padding: theme.spacing(2)
  },
  comment: {
    flexGrow: 1,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    position: 'relative',
    marginTop: theme.spacing(1)
  },
  search: {
    minWidth: 480,
    maxWidth: 480,
    flexBasis: 480
  },
  tableContainer: {
    minHeight: 431,
    maxHeight: 431,
    overflowY: 'auto'
  },
  searchContainer: {
    padding: theme.spacing(2)
  },
  selfSearch: {
    marginTop: 3
  },
  buttonCell: {
    paddingTop: 0,
    paddingBottom: 0,
    width: 100
  },
  buttonExtraSmall: {
    padding: '1px 8px',
    fontSize: '0.8125rem'
  },
  labelCell: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const SelectCells = props => {
  const {
    open,
    onClose,
    cells,
    selectedCells,
    onSelectCell,
    cellStatus,
    onChageCellStatus,
    className,
    ...rest
  } = props;

  const classes = useStyles();

  const loading = false;
  const [searchName, setSearchName] = useState('');
  const { sessionUser } = useSelector(state => state.session);

  const findCellsByName = searchName => {
    return cells.filter(cell => {
      return (
        searchName === '' ||
        cell.name.toUpperCase().indexOf(searchName.toUpperCase()) !== -1
      );
    });
  };

  const findFirstCellByName = searchName => {
    const cells = findCellsByName(searchName);
    return cells.length > 0 ? cells[0] : null;
  };

  const searchedCells = findCellsByName(searchName);
  const userCell = findFirstCellByName(sessionUser.displayName);

  const handleClose = () => {
    onClose();
  };

  const handleSearchFieldChange = value => {
    setSearchName(value);
  };

  const handleSelfSelection = () => {
    if (userCell) {
      onSelectCell(userCell);
    }
  };

  const handleSelectFirstCell = () => {
    onSelectCell(searchedCells.length === 0 ? null : searchedCells[0]);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Select Cells" />
        <Divider />

        <CardContent className={classes.content}>
          <div className={classes.searchContainer}>
            <Grid container spacing={3}>
              <Grid item>
                <Search
                  autoFocus={true}
                  placeholder="Name"
                  value={searchName}
                  onSearchValueChange={handleSearchFieldChange}
                  className={classes.search}
                  hideSearchButton={true}
                  onSearch={handleSelectFirstCell}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ marginTop: 0 }}
                  fullWidth
                  label="Status"
                  name="status"
                  margin="dense"
                  onChange={event =>
                    onChageCellStatus(
                      event.target.value ? event.target.value : null
                    )
                  }
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={cellStatus ? cellStatus : ''}
                  variant="outlined">
                  <option value="" />

                  {Object.keys(CELL_STATUSES).map(status => (
                    <option key={status} value={status}>
                      {CELL_STATUSES[status]}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '0%' }} />
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Identifier</TableCell>
                  <TableCell>Venue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedCells.map(cell => (
                  <TableRow key={cell.id} hover>
                    <TableCell className={classes.buttonCell}>
                      <Checkbox
                        checked={
                          selectedCells.map(c => c.id).indexOf(cell.id) > -1
                        }
                        color="primary"
                        onChange={(_, value) => onSelectCell(cell, value)}
                      />
                    </TableCell>
                    <TableCell>{cell.name}</TableCell>
                    <TableCell className={classes.labelCell}>
                      <CellStatus cell={cell} />
                    </TableCell>
                    <TableCell>{cell.sharedIdentifier}</TableCell>
                    <TableCell>{cell.currentVenue?.name}</TableCell>
                  </TableRow>
                ))}
                {!loading && searchedCells.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={10}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SelectCells.displayName = 'SelectCells';

SelectCells.propTypes = {
  className: PropTypes.string
};

export default SelectCells;
