import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedCell: {},
  deleted: false,
  deleteMessage: null,
  deletedCell: {},
  cellRadar: {},
  validationMessages: {},
  cells: []
};

const cellRadarCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CELL_RADAR_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedCell: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CELL_RADAR_PENDING: {
      return {
        ...state,
        deleted: initialState.deleted,
        deletedCell: initialState.deletedCell
      };
    }

    case actionTypes.DELETE_CELL_RADAR_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deleteMessage: action.payload.data.message,
        deletedCell: action.payload.data.result
      };
    }

    case actionTypes.EDIT_CELL_RADAR_FULFILLED: {
      return {
        ...state,
        cellRadar: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_CELL_RADAR_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_CELL_RADAR_VALUE_UPDATE: {
      return {
        ...state,
        cellRadar: {
          ...state.cellRadar,
          ...action.payload
        }
      };
    }

    case actionTypes.CLEAR_CELL_RADAR_DELETE_MESSAGE: {
      return {
        ...state,
        deleteMessage: initialState.deleteMessage
      };
    }

    case actionTypes.FIND_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default cellRadarCreateReducer;
