import ws from '../utils/ws';

/* Actions */

export const trainingCompatibilityGroupPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: TRAINING_COMPATIBILITY_GROUP_PAGINATED_LIST,
    payload: ws.get(
      `/training_compatibility_groups/page/${pageNumber}/rows/${pageSize}`
    )
  });

export const trainingCompatibilityGroupValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_TRAINING_COMPATIBILITY_GROUP_VALUE_UPDATE,
    payload: values
  });

export const findTrainingCompatibilityGroup = compatibilityGroupId => dispatch =>
  dispatch({
    type: FIND_TRAINING_COMPATIBILITY_GROUP,
    payload: ws.get(`/training_compatibility_groups/${compatibilityGroupId}`)
  });

export const findTrainingCompatibilityGroups = () => dispatch =>
  dispatch({
    type: FIND_TRAINING_COMPATIBILITY_GROUPS,
    payload: ws.get(`/training_compatibility_groups/`)
  });

export const trainingCompatibilityGroupValueReset = () => dispatch =>
  dispatch({
    type: CREATE_TRAINING_COMPATIBILITY_GROUP_VALUE_RESET
  });

export const editTrainingCompatibilityGroup = id => dispatch =>
  dispatch({
    type: EDIT_TRAINING_COMPATIBILITY_GROUP,
    payload: ws.get(`/training_compatibility_groups/${id}`)
  });

export const saveTrainingCompatibilityGroup = compatibilityGroup => dispatch =>
  dispatch({
    type: SAVE_TRAINING_COMPATIBILITY_GROUP,
    payload: ws.post('/training_compatibility_groups/', compatibilityGroup)
  });

export const deleteTrainingCompatibilityGroup = compatibilityGroup => dispatch =>
  dispatch({
    type: DELETE_TRAINING_COMPATIBILITY_GROUP,
    payload: ws.post(
      `/training_compatibility_groups/${compatibilityGroup.id}/delete`
    )
  });

/* Constants */

export const TRAINING_COMPATIBILITY_GROUP_PAGINATED_LIST =
  'TRAINING_COMPATIBILITY_GROUP_PAGINATED_LIST';
export const TRAINING_COMPATIBILITY_GROUP_PAGINATED_LIST_PENDING =
  'TRAINING_COMPATIBILITY_GROUP_PAGINATED_LIST_PENDING';
export const TRAINING_COMPATIBILITY_GROUP_PAGINATED_LIST_FULFILLED =
  'TRAINING_COMPATIBILITY_GROUP_PAGINATED_LIST_FULFILLED';

export const CREATE_TRAINING_COMPATIBILITY_GROUP_VALUE_UPDATE =
  'CREATE_TRAINING_COMPATIBILITY_GROUP_VALUE_UPDATE';
export const CREATE_TRAINING_COMPATIBILITY_GROUP_VALUE_RESET =
  'CREATE_TRAINING_COMPATIBILITY_GROUP_VALUE_RESET';

export const FIND_TRAINING_COMPATIBILITY_GROUP =
  'FIND_TRAINING_COMPATIBILITY_GROUP';
export const FIND_TRAINING_COMPATIBILITY_GROUP_PENDING =
  'FIND_TRAINING_COMPATIBILITY_GROUP_PENDING';
export const FIND_TRAINING_COMPATIBILITY_GROUP_FULFILLED =
  'FIND_TRAINING_COMPATIBILITY_GROUP_FULFILLED';

export const FIND_TRAINING_COMPATIBILITY_GROUPS =
  'FIND_TRAINING_COMPATIBILITY_GROUPS';
export const FIND_TRAINING_COMPATIBILITY_GROUPS_PENDING =
  'FIND_TRAINING_COMPATIBILITY_GROUPS_PENDING';
export const FIND_TRAINING_COMPATIBILITY_GROUPS_FULFILLED =
  'FIND_TRAINING_COMPATIBILITY_GROUPS_FULFILLED';

export const EDIT_TRAINING_COMPATIBILITY_GROUP =
  'EDIT_TRAINING_COMPATIBILITY_GROUP';
export const EDIT_TRAINING_COMPATIBILITY_GROUP_PENDING =
  'EDIT_TRAINING_COMPATIBILITY_GROUP_PENDING';
export const EDIT_TRAINING_COMPATIBILITY_GROUP_FULFILLED =
  'EDIT_TRAINING_COMPATIBILITY_GROUP_FULFILLED';

export const SAVE_TRAINING_COMPATIBILITY_GROUP =
  'SAVE_TRAINING_COMPATIBILITY_GROUP';
export const SAVE_TRAINING_COMPATIBILITY_GROUP_PENDING =
  'SAVE_TRAINING_COMPATIBILITY_GROUP_PENDING';
export const SAVE_TRAINING_COMPATIBILITY_GROUP_FULFILLED =
  'SAVE_TRAINING_COMPATIBILITY_GROUP_FULFILLED';

export const DELETE_TRAINING_COMPATIBILITY_GROUP =
  'DELETE_TRAINING_COMPATIBILITY_GROUP';
export const DELETE_TRAINING_COMPATIBILITY_GROUP_PENDING =
  'DELETE_TRAINING_COMPATIBILITY_GROUP_PENDING';
export const DELETE_TRAINING_COMPATIBILITY_GROUP_FULFILLED =
  'DELETE_TRAINING_COMPATIBILITY_GROUP_FULFILLED';
