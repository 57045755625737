import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Card,
  Button,
  CardContent,
  CardActions,
  Typography
} from '@material-ui/core';
import { broadcastMessageRead } from 'actions';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 420,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    '& p': {
      textAlign: 'center'
    }
  },
  actions: {
    justifyContent: 'center'
  }
}));

const BroadcastMessage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { unreadMessage, message } = useSelector(
    state => state.broadcastMessage
  );

  const handleClose = () => {
    dispatch(broadcastMessageRead());
  };

  const handleRefresh = () => {
    handleClose();
    window.location.reload();
  };

  return (
    <Modal onClose={handleClose} open={unreadMessage}>
      <Card className={classes.root}>
        <CardContent>
          <Typography component="p">{message}</Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button variant="contained" onClick={handleClose}>
            Dismiss
          </Button>
          <Button variant="contained" color="primary" onClick={handleRefresh}>
            Refresh
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default BroadcastMessage;
