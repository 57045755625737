import React from 'react';
import PropTypes from 'prop-types';
import ws from 'utils/ws';
import DownloadIcon from '@material-ui/icons/CloudDownload';

const S3File = props => {
  const { s3File, className, disabled } = props;

  const url = ws.url(
    '/aws/s3/object?bucket=' + s3File.bucket + '&objectKey=' + s3File.key
  );

  return (
    <DownloadIcon
      style={{
        width: 18,
        height: 18,
        cursor: 'pointer'
      }}
      disabled={disabled}
      className={className}
      color="primary"
      size="small"
      variant="outlined"
      onClick={() => {
        window.location = url;
      }}
    />
  );
};

S3File.propTypes = {
  s3File: PropTypes.object
};

export default S3File;
