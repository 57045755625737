import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  saving: false,
  savedModelSet: {},
  modelSet: {
    id: null,
    name: '',
    models: [
      {
        model: null,
        weight: null
      }
      // {
      //   model: null,
      //   weight: null
      // },
      // {
      //   model: null,
      //   weight: null
      // },
      // {
      //   model: null,
      //   weight: null
      // },
      // {
      //   model: null,
      //   weight: null
      // }
    ]
  },
  modelSelectionIndex: null,
  validationMessages: {},
  deleted: false
};

const modelSetCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_MODEL_SET_PENDING: {
      return {
        ...state,
        saving: true
      };
    }

    case actionTypes.SAVE_MODEL_SET_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedModelSet: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages,
        saving: false
      };
    }

    case actionTypes.EDIT_MODEL_SET_FULFILLED: {
      return {
        ...state,
        modelSet: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.SET_CREATE_MODEL_SET_MODEL_SELECTION_INDEX: {
      return {
        ...state,
        modelSelectionIndex: action.payload
      };
    }

    case actionTypes.DELETE_MODEL_SET_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success
      };
    }

    case actionTypes.CREATE_MODEL_SET_ADD_NEW_MODEL: {
      const models = state.modelSet.models.slice();
      models.push({
        model: null,
        weight: null
      });

      return {
        ...state,
        modelSet: {
          ...state.modelSet,
          models: models
        }
      };
    }

    case actionTypes.REMOVE_CREATE_MODEL_SET_MODEL: {
      const models = state.modelSet.models.slice();
      models.splice(action.payload, 1);
      return {
        ...state,
        modelSet: {
          ...state.modelSet,
          models: models
        }
      };
    }

    case actionTypes.CREATE_MODEL_SET_MODEL_VALUE_UPDATE: {
      const { index, values } = action.payload;

      const models = state.modelSet.models.slice();
      models[index] = {
        ...models[index],
        ...values
      };

      return {
        ...state,
        modelSet: {
          ...state.modelSet,
          models: models
        }
      };
    }

    case actionTypes.CREATE_MODEL_SET_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_MODEL_SET_VALUE_UPDATE: {
      return {
        ...state,
        modelSet: {
          ...state.modelSet,
          ...action.payload
        }
      };
    }

    // case actionTypes.FIND_EVENT_DATA_SETS_PENDING: {
    //   return {
    //     ...state,
    //     eventDataSets: initialState.eventDataSets
    //   };
    // }

    // case actionTypes.FIND_EVENT_DATA_SETS_FULFILLED: {
    //   return {
    //     ...state,
    //     eventDataSets: action.payload.data.result
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default modelSetCreateReducer;
