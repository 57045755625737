import * as actionTypes from 'actions';

const initialState = {
  entityPhoto: {},
  editedEntityPhoto: {},
  openConfirmDelete: false,
  edit: false,
  open: false,
  readOnly: false
};

const entityPhotoDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DETAILS_ENTITY_PHOTO: {
      return {
        ...state,
        entityPhoto: {
          ...action.payload.entityPhoto
        },
        openConfirmDelete: false,
        edit: false,
        open: true,
        readOnly:
          action.payload.readOnly !== undefined
            ? action.payload.readOnly
            : false
      };
    }

    case actionTypes.CLEAR_DETAILS_ENTITY_PHOTO: {
      return {
        ...initialState
      };
    }

    case actionTypes.SET_DETAILS_ENTITY_PHOTO_EDIT: {
      return {
        ...state,
        editedEntityPhoto: {
          ...state.entityPhoto
        },
        edit: action.payload
      };
    }

    case actionTypes.SET_DETAILS_ENTITY_PHOTO_CONFIRM_DELETE_OPEN: {
      return {
        ...state,
        openConfirmDelete: action.payload
      };
    }

    case actionTypes.SET_EDITED_ENTITY_PHOTO_VALUES: {
      return {
        ...state,
        editedEntityPhoto: {
          ...state.editedEntityPhoto,
          ...action.payload
        }
      };
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      return {
        ...state,
        open: false
      };
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      return {
        ...state,
        open: false
      };
    }

    default: {
      return state;
    }
  }
};

export default entityPhotoDetailsReducer;
