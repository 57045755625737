import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat } from 'components';
import { TIME_POINT_DESCRIPTIONS } from 'common/constants';

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Card
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    '& td': {
      paddingTop: 2,
      paddingBottom: 2
    }
  },
  label: {
    paddingLeft: theme.spacing(1),
    fontSize: '0.8em'
  },
  time: {
    paddingRight: theme.spacing(1),
    fontSize: '0.8em'
  },
  indicator: {
    display: 'inline-block',
    backgroundColor: colors.blue[600],
    height: 10,
    borderRadius: 6
  },
  indicatorPadding: {
    display: 'inline-block'
  },
  timeDelta: {
    textAlign: 'right'
  },
  total: {
    '& td': {
      fontWeight: 600,
      backgroundColor: colors.grey[100],
      fontSize: '0.8em'
    }
  },
  excluded: {
    color: colors.grey[600],
    textDecoration: 'line-through'
  },
  excludedLabel: {
    color: colors.grey[600]
  }
}));

const EventProcessingTime = props => {
  const { event } = props;

  const classes = useStyles();

  const indicatorWidths = [];
  const totalElapsedTime =
    event.processingTimePoints[event.processingTimePoints.length - 1]
      .elapsedTime;
  const totalWidth = 220;
  let paddingWidth = 0.0;

  for (let i = 0; i < event.processingTimePoints.length; i++) {
    const priorTimePoint = i > 0 ? event.processingTimePoints[i - 1] : null;
    const timePoint = event.processingTimePoints[i];

    const width = totalWidth * (timePoint.timeDelta / totalElapsedTime);

    if (priorTimePoint) {
      paddingWidth +=
        totalWidth * (priorTimePoint.timeDelta / totalElapsedTime);
    }

    indicatorWidths.push({
      width,
      paddingWidth
    });
  }

  return (
    <Card className={classes.root}>
      <Table>
        <TableBody>
          {event.processingTimePoints.map((timePoint, i) => (
            <React.Fragment key={timePoint.label}>
              {i > 0 && (
                <TableRow>
                  <TableCell
                    className={clsx(
                      TIME_POINT_DESCRIPTIONS[timePoint.label] &&
                        TIME_POINT_DESCRIPTIONS[timePoint.label].excluded
                        ? classes.excludedLabel
                        : '',
                      classes.label
                    )}>
                    {TIME_POINT_DESCRIPTIONS[timePoint.label]
                      ? TIME_POINT_DESCRIPTIONS[timePoint.label].description
                      : timePoint.label}
                  </TableCell>
                  <TableCell>
                    <div
                      className={classes.indicatorPadding}
                      style={{ width: indicatorWidths[i].paddingWidth }}></div>
                    <div
                      className={classes.indicator}
                      style={{ width: indicatorWidths[i].width + 'px' }}></div>
                  </TableCell>
                  <TableCell
                    className={clsx(
                      TIME_POINT_DESCRIPTIONS[timePoint.label] &&
                        TIME_POINT_DESCRIPTIONS[timePoint.label].excluded
                        ? classes.excluded
                        : '',
                      classes.timeDelta
                    )}>
                    <NumberFormat
                      value={timePoint.timeDelta * 1000}
                      suffix=" ms"
                      precision={2}
                      fixedDecimalScale={2}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}

          <TableRow className={classes.total}>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell className={classes.timeDelta}>
              <NumberFormat
                value={event.totalProcessingElapsedTime}
                suffix=" s"
                precision={2}
                fixedDecimalScale={2}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

EventProcessingTime.propTypes = {
  event: PropTypes.object
};

export default EventProcessingTime;
