export default class dateUtils {
  static parseUTCEpoch(time) {
    return time !== null
      ? new Date(time + new Date(time).getTimezoneOffset() * 60 * 1000)
      : null;
  }

  static getUTCEpoch(time) {
    return time
      ? time.valueOf() - new Date(time).getTimezoneOffset() * 60 * 1000
      : null;
  }
}
