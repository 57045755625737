import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  CHART_COLORS,
  GIT_COMMIT_URL,
  EVENT_SETTING_COMMIT_HASH
} from 'common/constants';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  colors,
  Tooltip,
  Link
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    '& td': {
      paddingTop: 2,
      paddingBottom: 2
    }
  },
  label: {
    paddingLeft: theme.spacing(1),
    fontSize: '0.8em'
  },
  valueLabel: {
    paddingLeft: theme.spacing(1),
    fontSize: '0.8em',
    maxWidth: 220,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },

  hashRow: {
    backgroundColor: colors.grey[50]
  },
  hashLabel: {
    fontWeight: 600
  },
  settingLabel: {
    borderLeftWidth: 6,
    borderLeftStyle: 'solid',
    fontSize: '0.8em'
  }
}));

const EventSettings = props => {
  const { settingsHash, settingsJson } = props;

  const classes = useStyles();

  if (!settingsHash || !settingsJson) return null;

  const eventSettings = JSON.parse(settingsJson);

  const settingPrefixes = [];
  Object.keys(eventSettings).forEach(setting => {
    const prefix = setting.substring(0, setting.indexOf('.'));
    if (settingPrefixes.indexOf(prefix) < 0) {
      settingPrefixes.push(prefix);
    }
  });

  const settingPrefixColors = {};
  settingPrefixes.forEach((prefix, i) => {
    settingPrefixColors[prefix] = CHART_COLORS[i];
  });

  return (
    <Card className={classes.root}>
      <Table>
        <TableBody>
          <TableRow className={classes.hashRow}>
            <TableCell className={clsx(classes.label, classes.hashLabel)}>
              Setting
            </TableCell>
            <TableCell className={clsx(classes.label, classes.hashLabel)}>
              {settingsHash}
            </TableCell>
          </TableRow>

          {Object.keys(eventSettings).map(setting => (
            <TableRow key={setting}>
              <TableCell
                className={classes.settingLabel}
                style={{
                  borderLeftColor:
                    settingPrefixColors[
                      setting.substring(0, setting.indexOf('.'))
                    ]
                }}>
                {setting}
              </TableCell>
              <TableCell className={classes.valueLabel}>
                {setting === EVENT_SETTING_COMMIT_HASH ? (
                  <Link
                    color="inherit"
                    rel="noopener"
                    target="_blank"
                    href={`${GIT_COMMIT_URL}${eventSettings[setting]}`}
                    variant="h6">
                    {eventSettings[setting]}
                  </Link>
                ) : (
                  <Tooltip placement="top-start" title={eventSettings[setting]}>
                    <span>{eventSettings[setting]}</span>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

EventSettings.propTypes = {
  event: PropTypes.object
};

export default EventSettings;
