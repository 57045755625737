import * as actionTypes from 'actions';

const initialState = {
  cells: [],
  latestCellWalks: {}
};

const trainingCellsOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_TRAINING_CELLS_OVERVIEW_CELLS_PENDING: {
      return {
        ...state,
        cellsLoading: true
      };
    }

    case actionTypes.FIND_TRAINING_CELLS_OVERVIEW_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result,
        cellsLoading: false
      };
    }

    case actionTypes.FIND_TRAINING_CELLS_OVERVIEW_LATEST_CELL_WALK_FULFILLED: {
      const walk =
        action.payload && action.payload.data && action.payload.data.result;

      let result;
      if (walk) {
        result = {
          ...state,
          latestCellWalks: {
            ...state.latestCellWalks,
            [walk.cell.id]: walk
          }
        };
      } else {
        result = state;
      }
      return result;
    }

    default: {
      return state;
    }
  }
};

export default trainingCellsOverviewReducer;
