import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Button,
  Grid,
  TextField,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import { Alert } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleCreateTicketOpen,
  createTicketValueChange,
  saveCellTicket
} from 'actions';
import { TICKET_PIPELINES, TICKET_PRIORITIES } from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: '60%',
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const CreateTicket = props => {
  const { className, onDone } = props;

  const {
    open,
    saved,
    validationMessages,
    ticket,
    cell,
    processing
  } = useSelector(state => state.createTicket);

  const classes = useStyles();
  const dispatch = useDispatch();

  if (!open) {
    return null;
  }

  const statusPipelines = Object.keys(TICKET_PIPELINES).filter(pipeline =>
    cell.status === 'FIELD_PRODUCTION' || cell.status === 'MARKETING'
      ? pipeline.indexOf('PRODUCTION') > -1
      : pipeline.indexOf('TRAINING') > -1
  );

  const handleValueChange = (field, value) => {
    dispatch(createTicketValueChange(field, value));
  };

  return (
    <>
      <Modal onClose={() => dispatch(toggleCreateTicketOpen())} open={open}>
        <Card className={clsx(classes.root, className)}>
          <CardHeader title="Create Ticket" />
          <Divider />
          {processing && <LinearProgress />}
          <CardContent className={classes.content}>
            {saved ? (
              <>
                <Alert
                  variant="success"
                  message="Your ticket has been created and will appear in Insight shortly"
                />
              </>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <TextField
                    error={validationMessages.hasOwnProperty('pipeline')}
                    helperText={validationMessages.pipeline}
                    fullWidth={true}
                    label="Pipeline"
                    name="pipleine"
                    onChange={e =>
                      handleValueChange(
                        'pipeline',
                        e.target.value ? e.target.value : null
                      )
                    }
                    select
                    SelectProps={{ native: true }}
                    value={ticket.pipeline ? ticket.pipeline : ''}
                    variant="outlined">
                    <option />
                    {statusPipelines.map(pipeline => (
                      <option key={pipeline} value={pipeline}>
                        {TICKET_PIPELINES[pipeline]}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={validationMessages.hasOwnProperty('priority')}
                    helperText={validationMessages.priority}
                    fullWidth={true}
                    label="Priority"
                    name="priority"
                    onChange={e =>
                      handleValueChange(
                        'priority',
                        e.target.value ? e.target.value : null
                      )
                    }
                    select
                    SelectProps={{ native: true }}
                    value={ticket.priority ? ticket.priority : ''}
                    variant="outlined">
                    <option />
                    {Object.keys(TICKET_PRIORITIES).map(priority => (
                      <option key={priority} value={priority}>
                        {TICKET_PRIORITIES[priority]}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Subject"
                    name="subject"
                    error={validationMessages.hasOwnProperty('subject')}
                    helperText={validationMessages.subject}
                    onChange={e => handleValueChange('subject', e.target.value)}
                    value={ticket.subject}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Details"
                    name="content"
                    error={validationMessages.hasOwnProperty('content')}
                    helperText={validationMessages.content}
                    onChange={e => handleValueChange('content', e.target.value)}
                    value={ticket.content}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            {saved ? (
              <>
                <Button
                  onClick={() => {
                    dispatch(toggleCreateTicketOpen());
                    onDone();
                  }}
                  variant="contained">
                  Done
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => dispatch(toggleCreateTicketOpen())}
                  variant="contained">
                  Cancel
                </Button>
                <Button
                  onClick={() => dispatch(saveCellTicket(cell.id, ticket))}
                  color="primary"
                  variant="contained">
                  Save
                </Button>
              </>
            )}
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default CreateTicket;
