import * as actionTypes from 'actions';

const initialState = {
  loggedIn: false,
  loggedOut: false,
  sessionUser: null,
  sessionUserLoading: false,
  user: {},
  version: null
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN_PENDING: {
      return {
        ...state,
        loggedIn: false,
        loggingIn: true,
        loggedOut: false
      };
    }

    case actionTypes.EXPIRE_SESSIONS: {
      window.location = '/';
    }

    case actionTypes.SESSION_LOGIN_FULFILLED: {
      const { success, message, result } = action.payload.data;
      const sessionUser = result ? result.sessionUser : undefined;

      return {
        ...state,
        loggedIn: success,
        message: message,
        loggingIn: false,
        loggedOut: false,
        user: sessionUser
      };
    }

    case actionTypes.SESSION_LOGIN_REJECTED: {
      const { message } = action.payload;

      return {
        ...state,
        loggedIn: false,
        message: message,
        loggingIn: false,
        loggedOut: false
      };
    }

    case actionTypes.FIND_SESSION_USER_PENDING: {
      return {
        ...state,
        sessionUser: initialState.sessionUser,
        sessionUserLoading: true
      };
    }

    case actionTypes.FIND_SESSION_USER_FULFILLED: {
      return {
        ...state,
        sessionUser:
          action.payload.status === 200
            ? action.payload.data
            : initialState.sessionUser,
        sessionUserLoading: false
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loggedOut: true,
        sessionUser: initialState.sessionUser,
        message: 'You have been logged out',
        user: {}
      };
    }

    case actionTypes.LOGIN_SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loggedOut: true,
        sessionUser: initialState.sessionUser,
        user: {}
      };
    }

    case actionTypes.FIND_SYSTEM_PROFILE_PENDING: {
      return {
        ...state,
        version: initialState.version
      };
    }

    case actionTypes.FIND_SYSTEM_PROFILE_FULFILLED: {
      return {
        ...state,
        version: action.payload.data.version
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
