import ws from '../utils/ws';

export const createCameraCaptureEntityPhoto = entityPhoto => dispatch =>
  dispatch({
    type: CREATE_ENTITY_PHOTO,
    payload: ws.post(`/entity_photos/create`, entityPhoto)
  });

export const createFileUploadEntityPhoto = entityPhoto => dispatch => {
  const file = entityPhoto.file;

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  toBase64(file).then(encodedFile => {
    dispatch({
      type: CREATE_ENTITY_PHOTO,
      payload: ws.post(`/entity_photos/create`, {
        ...entityPhoto,
        dataUri: encodedFile
      })
    });
  });
};

export const saveEntityPhoto = entityPhoto => dispatch =>
  dispatch({
    type: SAVE_ENTITY_PHOTO,
    payload: ws.post(`/entity_photos/save`, entityPhoto)
  });

export const deleteEntityPhoto = entityPhoto => dispatch =>
  dispatch({
    type: DELETE_ENTITY_PHOTO,
    payload: ws.post(`/entity_photos/delete`, entityPhoto)
  });

export const findEntityPhotos = (entityType, entityId) => dispatch =>
  dispatch({
    type: FIND_ENTITY_PHOTOS,
    payload: ws.get(`/entity_photos/type/${entityType}/id/${entityId}/`)
  });

export const findEntityTempPhotos = (entityType, entityTempId) => dispatch =>
  dispatch({
    type: FIND_ENTITY_TEMP_PHOTOS,
    payload: ws.get(
      `/entity_photos/type/${entityType}/temp_id/${entityTempId}/`
    )
  });

export const setDetailsEntityPhoto = (entityPhoto, readOnly) => dispatch =>
  dispatch({
    type: SET_DETAILS_ENTITY_PHOTO,
    payload: { entityPhoto, readOnly }
  });

export const clearDetailsEntityPhoto = () => dispatch =>
  dispatch({
    type: CLEAR_DETAILS_ENTITY_PHOTO
  });

export const setDetailsEntityPhotoEdit = edit => dispatch =>
  dispatch({
    type: SET_DETAILS_ENTITY_PHOTO_EDIT,
    payload: edit
  });

export const setEntityPhotoOpenConfirmDelete = open => dispatch =>
  dispatch({
    type: SET_DETAILS_ENTITY_PHOTO_CONFIRM_DELETE_OPEN,
    payload: open
  });

export const setEditedEntityPhotoValues = values => dispatch =>
  dispatch({
    type: SET_EDITED_ENTITY_PHOTO_VALUES,
    payload: values
  });

/* Action Constants */

export const CREATE_ENTITY_PHOTO = 'CREATE_ENTITY_PHOTO';
export const CREATE_ENTITY_PHOTO_PENDING = 'CREATE_ENTITY_PHOTO_PENDING';
export const CREATE_ENTITY_PHOTO_FULFILLED = 'CREATE_ENTITY_PHOTO_FULFILLED';

export const SAVE_ENTITY_PHOTO = 'SAVE_ENTITY_PHOTO';
export const SAVE_ENTITY_PHOTO_PENDING = 'SAVE_ENTITY_PHOTO_PENDING';
export const SAVE_ENTITY_PHOTO_FULFILLED = 'SAVE_ENTITY_PHOTO_FULFILLED';

export const DELETE_ENTITY_PHOTO = 'DELETE_ENTITY_PHOTO';
export const DELETE_ENTITY_PHOTO_PENDING = 'DELETE_ENTITY_PHOTO_PENDING';
export const DELETE_ENTITY_PHOTO_FULFILLED = 'DELETE_ENTITY_PHOTO_FULFILLED';

export const FIND_ENTITY_PHOTOS = 'FIND_ENTITY_PHOTOS';
export const FIND_ENTITY_PHOTOS_PENDING = 'FIND_ENTITY_PHOTOS_PENDING';
export const FIND_ENTITY_PHOTOS_FULFILLED = 'FIND_ENTITY_PHOTOS_FULFILLED';

export const FIND_ENTITY_TEMP_PHOTOS = 'FIND_ENTITY_TEMP_PHOTOS';
export const FIND_ENTITY_TEMP_PHOTOS_PENDING =
  'FIND_ENTITY_TEMP_PHOTOS_PENDING';
export const FIND_ENTITY_TEMP_PHOTOS_FULFILLED =
  'FIND_ENTITY_TEMP_PHOTOS_FULFILLED';

export const SET_DETAILS_ENTITY_PHOTO = 'SET_DETAILS_ENTITY_PHOTO';
export const CLEAR_DETAILS_ENTITY_PHOTO = 'CLEAR_DETAILS_ENTITY_PHOTO';

export const SET_DETAILS_ENTITY_PHOTO_EDIT = 'SET_DETAILS_ENTITY_PHOTO_EDIT';

export const SET_DETAILS_ENTITY_PHOTO_CONFIRM_DELETE_OPEN =
  'SET_DETAILS_ENTITY_PHOTO_CONFIRM_DELETE_OPEN';

export const SET_EDITED_ENTITY_PHOTO_VALUES = 'SET_EDITED_ENTITY_PHOTO_VALUES';
