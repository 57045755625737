/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { TRAINING_VALIDATION_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const TrainingValidationStatus = props => {
  const { training, type, ...rest } = props;

  const statusColors = {
    VERIFIED: colors.green[600],
    SUSPECT: colors.red[600],
    PENDING: colors.orange[600]
  };

  const status = training
    ? type === 'setup'
      ? training.setupContentsValidationStatus
      : type === 'event'
      ? training.eventValidationStatus
      : undefined
    : undefined;

  return (
    <div>
      {training && status && (
        <Label {...rest} color={statusColors[status]}>
          {TRAINING_VALIDATION_STATUSES[status]}
        </Label>
      )}
    </div>
  );
};

TrainingValidationStatus.propTypes = {
  training: PropTypes.object
};

export default TrainingValidationStatus;
