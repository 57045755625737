import ws from '../utils/ws';

/* Actions */

export const cellHealthReportList = (startTime, finishTime) => dispatch =>
  dispatch({
    type: CELL_HEALTH_REPORT_LIST,
    payload: ws.get(
      `/cells/health_reports/?startTime=${startTime}&finishTime=${finishTime}`
    )
  });

/* Constants */

export const CELL_HEALTH_REPORT_LIST = 'CELL_HEALTH_REPORT_LIST';
export const CELL_HEALTH_REPORT_LIST_PENDING =
  'CELL_HEALTH_REPORT_LIST_PENDING';
export const CELL_HEALTH_REPORT_LIST_FULFILLED =
  'CELL_HEALTH_REPORT_LIST_FULFILLED';
