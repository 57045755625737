import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { NumberFormat, S3File } from 'components';
import { S3_FILE_TYPES } from 'common/constants';
// import Plot from 'react-plotly.js';

import {
  Modal,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Button,
  Grid,
  CardHeader,
  Divider,
  colors,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1800,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: theme.spacing(3)
  },
  files: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  createdSessionId: {
    marginTop: theme.spacing(6)
  }
}));

const EventRadarData = props => {
  const { open, onClose, rawRadarData, loading, className, ...rest } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Radar Data" />
        <Divider />
        {/* <CardContent className={classes.content}>
        {loading && <LinearProgress />}

        <Grid container
              spacing={0}
        >
          {rawRadarData.radars.map((radarDetails, index) => (
            <Grid key={index}
              item
              sm={12}
              md={6}
              lg={4}>
              <Typography
                component="h2"
                gutterBottom
                variant="overline"
              >
                {radarDetails.radarInfo.standNumber} / {radarDetails.radarInfo.position} / {radarDetails.radarInfo.serialNumber}
              </Typography>

              <Plot
                data={[
                  {
                    z: radarDetails.data,
                    type: 'heatmap'
                  }
                ]}
                layout={
                   {
                    autosize: false,
                    width: 550,
                    height: 330,
                    margin: {
                      l: 50,
                      r: 10,
                      b: 50,
                      t: 30,
                      pad: 4
                    }
                  }
                }
              />
            </Grid>
          ))}
        </Grid>

        </CardContent> */}
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

EventRadarData.displayName = 'EventDetails';

EventRadarData.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

EventRadarData.defaultProps = {
  open: false
};

export default EventRadarData;
