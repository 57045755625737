import * as actionTypes from 'actions';

const initialState = {
  validationMessages: {},
  fileUploading: false,
  fileUploaded: false,
  verificationConfiguration: null
};

const uploadCellConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_UPLOAD_CELL_CONFIGURATION: {
      return {
        ...initialState
      };
    }

    case actionTypes.UPLOAD_CELL_CONFIGURATION_FILE_PENDING: {
      return {
        ...state,
        verificationConfiguration: initialState.verificationConfiguration,
        fileUploading: false,
        fileUploaded: true
      };
    }

    case actionTypes.UPLOAD_CELL_CONFIGURATION_FILE_FULFILLED: {
      return {
        ...state,
        verificationConfiguration: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages,
        fileUploading: false,
        fileUploaded: true
      };
    }

    case actionTypes.VERIFY_UPLOADED_CELL_CONFIGURATION_FILE_FULFILLED: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};

export default uploadCellConfigurationReducer;
