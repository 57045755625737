import * as actionTypes from 'actions';

const initialState = {
  modelAnalysisComparison: {
    modelConfigurationAnalyses: []
  },
  loading: false,
  creationRequired: false,
  creating: false,
  weightsAdjusted: false,
  weights: {
    objectPresentAccuracyWeighting: 100,
    noObjectAccuracyWeighting: 100,
    numStandardDeviationsWeighting: 100,
    marginSpreadWeighting: 100
  },
  modelConfigurationRocValuesLoading: false,
  modelConfigurationRocValues: [],
  modelConfigurationPrecisionRecallValuesLoading: false,
  modelConfigurationPrecisionRecallValues: []
};

const modelAnalysisComparisonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_LATEST_MODEL_ANALYSIS_COMPARISON_PENDING: {
      return {
        ...state,
        loading: true,
        creationRequired: false,
        modelAnalysisComparison: initialState.modelAnalysisComparison
      };
    }

    case actionTypes.FIND_LATEST_MODEL_ANALYSIS_COMPARISON_FULFILLED: {
      const modelAnalysisComparison = action.payload.data.result;

      const weights = modelAnalysisComparison
        ? {
            objectPresentAccuracyWeighting: Math.round(
              modelAnalysisComparison.objectPresentAccuracyWeighting * 100
            ),
            noObjectAccuracyWeighting: Math.round(
              modelAnalysisComparison.noObjectAccuracyWeighting * 100
            ),
            numStandardDeviationsWeighting: Math.round(
              modelAnalysisComparison.numStandardDeviationsWeighting * 100
            ),
            marginSpreadWeighting: Math.round(
              modelAnalysisComparison.marginSpreadWeighting * 100
            )
          }
        : initialState.weights;

      return {
        ...state,
        loading: false,
        creationRequired: modelAnalysisComparison == null,
        modelAnalysisComparison:
          modelAnalysisComparison == null
            ? initialState.modelAnalysisComparison
            : modelAnalysisComparison,
        weights
      };
    }

    case actionTypes.FIND_LATEST_MODEL_ANALYSIS_COMPARISON_SILENTLY_FULFILLED: {
      const modelAnalysisComparison = action.payload.data.result;

      return {
        ...state,
        creationRequired: modelAnalysisComparison == null,
        modelAnalysisComparison:
          modelAnalysisComparison == null
            ? initialState.modelAnalysisComparison
            : modelAnalysisComparison
      };
    }

    case actionTypes.CREATE_MODEL_ANALYSIS_COMPARISON_PENDING: {
      return {
        ...state,
        creating: true,
        creationRequired: false,
        modelAnalysisComparison: initialState.modelAnalysisComparison
      };
    }

    case actionTypes.CREATE_MODEL_ANALYSIS_COMPARISON_FULFILLED: {
      const modelAnalysisComparison = action.payload.data.result;

      const weights = {
        objectPresentAccuracyWeighting: Math.round(
          modelAnalysisComparison.objectPresentAccuracyWeighting * 100
        ),
        noObjectAccuracyWeighting: Math.round(
          modelAnalysisComparison.noObjectAccuracyWeighting * 100
        ),
        numStandardDeviationsWeighting: Math.round(
          modelAnalysisComparison.numStandardDeviationsWeighting * 100
        ),
        marginSpreadWeighting: Math.round(
          modelAnalysisComparison.marginSpreadWeighting * 100
        )
      };

      return {
        ...state,
        creating: false,
        modelAnalysisComparison,
        weights
      };
    }

    case actionTypes.MODEL_ANALYSIS_COMPARISON_MODEL_CALCULATED: {
      const modelAnalysisComparison = action.payload.result;

      let result;
      if (modelAnalysisComparison.id === state.modelAnalysisComparison.id) {
        result = {
          ...state,
          modelAnalysisComparison: modelAnalysisComparison
        };
      } else {
        result = state;
      }

      return result;
    }

    case actionTypes.UPDATE_MODEL_ANALYSIS_COMPARISON_WEIGHTS: {
      return {
        ...state,
        weightsAdjusted: true,
        weights: action.payload
      };
    }

    case actionTypes.APPLY_MODEL_ANALYSIS_COMPARISON_WEIGHTS_FULFILLED: {
      return {
        ...state,
        weightsAdjusted: false,
        modelAnalysisComparison: action.payload.data.result
      };
    }

    case actionTypes.FIND_MODEL_ANALYSIS_COMPARISON_ROC_VALUES_PENDING: {
      return {
        ...state,
        modelConfigurationRocValuesLoading: true,
        modelConfigurationRocValues: initialState.modelConfigurationRocValues
      };
    }

    case actionTypes.FIND_MODEL_ANALYSIS_COMPARISON_ROC_VALUES_FULFILLED: {
      return {
        ...state,
        modelConfigurationRocValuesLoading: false,
        modelConfigurationRocValues: action.payload.data.result
      };
    }

    case actionTypes.FIND_MODEL_ANALYSIS_COMPARISON_PRECISION_RECALL_VALUES_PENDING: {
      return {
        ...state,
        modelConfigurationPrecisionRecallValuesLoading: true,
        modelConfigurationPrecisionRecallValues:
          initialState.modelConfigurationPrecisionRecallValues
      };
    }

    case actionTypes.FIND_MODEL_ANALYSIS_COMPARISON_PRECISION_RECALL_VALUES_FULFILLED: {
      return {
        ...state,
        modelConfigurationPrecisionRecallValuesLoading: false,
        modelConfigurationPrecisionRecallValues: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default modelAnalysisComparisonReducer;
