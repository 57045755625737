/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { CELL_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const CellRadarStatus = props => {
  const { cellRadar, ...rest } = props;

  const statusColors = {
    ACTIVE: colors.green[600],
    DECOMMISSIONED: colors.grey[500]
  };

  return (
    <div>
      {cellRadar && cellRadar.status && (
        <Label {...rest} color={statusColors[cellRadar.status]}>
          {CELL_STATUSES[cellRadar.status]}
        </Label>
      )}
    </div>
  );
};

CellRadarStatus.propTypes = {
  cellRadar: PropTypes.object
};

export default CellRadarStatus;
