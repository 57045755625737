/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { CELL_CHECK_LIST_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const CellCheckListStatus = props => {
  const { cellCheckList, ...rest } = props;

  const statusColors = {
    INCOMPLETE: colors.orange[600],
    COMPLETED: colors.green[600]
  };

  return (
    <div>
      {cellCheckList && (
        <Label {...rest} color={statusColors[cellCheckList.status]}>
          {CELL_CHECK_LIST_STATUSES[cellCheckList.status]}
        </Label>
      )}
    </div>
  );
};

CellCheckListStatus.propTypes = {
  cellCheckList: PropTypes.object
};

export default CellCheckListStatus;
