/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { CELL_BUILD_COMPONENT_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat } from 'components';

const useStyles = makeStyles(theme => ({
  label: {
    // marginRight: theme.spacing(1)
    width: 180
  },
  value: {
    marginLeft: theme.spacing(1),
    width: 40
  }
}));

const CellBuildProductComponentStatus = props => {
  const { buildComponent, status, ...rest } = props;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.labelContainer}>
        <Label variant="outlined" className={classes.label}>
          Total
        </Label>
        <Label className={classes.value}>{buildComponent.childCount}</Label>
      </div>
      <div className={classes.labelContainer}>
        <Label
          variant="outlined"
          className={classes.label}
          color={colors.blueGrey[300]}>
          {CELL_BUILD_COMPONENT_STATUSES.PENDING_INSTALLATION}
        </Label>
        <Label className={classes.value} color={colors.blueGrey[300]}>
          {buildComponent.pendingInstallationChildCount}
        </Label>
        <Label className={classes.value} color={colors.blueGrey[300]}>
          <NumberFormat
            value={
              buildComponent.pendingInstallationChildCount /
              buildComponent.childCount
            }
            percentage
            suffix="%"
          />
        </Label>
      </div>
      <div className={classes.labelContainer}>
        <Label
          variant="outlined"
          className={classes.label}
          color={colors.orange[600]}>
          {CELL_BUILD_COMPONENT_STATUSES.INSTALLATION_STARTED}
        </Label>
        <Label className={classes.value} color={colors.orange[600]}>
          {buildComponent.installationStartedChildCount}
        </Label>
        <Label className={classes.value} color={colors.orange[600]}>
          <NumberFormat
            value={
              buildComponent.installationStartedChildCount /
              buildComponent.childCount
            }
            percentage
            suffix="%"
          />
        </Label>
      </div>
      <div className={classes.labelContainer}>
        <Label
          variant="outlined"
          className={classes.label}
          color={colors.purple[600]}>
          {CELL_BUILD_COMPONENT_STATUSES.INSTALLATION_COMPLETED}
        </Label>
        <Label className={classes.value} color={colors.purple[600]}>
          {buildComponent.installationCompletedChildCount}
        </Label>
        <Label className={classes.value} color={colors.purple[600]}>
          <NumberFormat
            value={
              buildComponent.installationCompletedChildCount /
              buildComponent.childCount
            }
            percentage
            suffix="%"
          />
        </Label>
      </div>
      <div className={classes.labelContainer}>
        <Label
          variant="outlined"
          className={classes.label}
          color={colors.green[600]}>
          {CELL_BUILD_COMPONENT_STATUSES.INSTALLATION_APPROVED}
        </Label>
        <Label className={classes.value} color={colors.green[600]}>
          {buildComponent.installationApprovedChildCount}
        </Label>
        <Label className={classes.value} color={colors.green[600]}>
          <NumberFormat
            value={
              buildComponent.installationApprovedChildCount /
              buildComponent.childCount
            }
            percentage
            suffix="%"
          />
        </Label>
      </div>
      <div className={classes.labelContainer}>
        <Label
          variant="outlined"
          className={classes.label}
          color={colors.green[600]}>
          {CELL_BUILD_COMPONENT_STATUSES.INSTALLATION_TESTED}
        </Label>
        <Label className={classes.value} color={colors.green[600]}>
          {buildComponent.installationTestedChildCount}
        </Label>
        <Label className={classes.value} color={colors.green[600]}>
          <NumberFormat
            value={
              buildComponent.installationTestedChildCount /
              buildComponent.childCount
            }
            percentage
            suffix="%"
          />
        </Label>
      </div>
    </div>
  );
};

CellBuildProductComponentStatus.propTypes = {
  status: PropTypes.string
};

export default CellBuildProductComponentStatus;
