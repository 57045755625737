import ws from '../utils/ws';

/* Actions */

export const findEventDataSets = () => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SETS,
    payload: ws.get(`/events/data_sets/`)
  });

export const eventDataSetPaginatedList = (
  pageNumber,
  pageSize,
  dataSetType
) => dispatch =>
  dispatch({
    type: EVENT_DATA_SETS_PAGINATED_LIST,
    payload: ws.get(
      `/events/data_sets/page/${pageNumber}/rows/${pageSize}?dataSetType=${dataSetType}`
    )
  });

export const eventDataSetEventPaginatedList = (
  eventDataSetId,
  pageNumber,
  pageSize,
  modelConfiguration,
  modelSet,
  torsoThreshold,
  cell
) => dispatch =>
  dispatch({
    type: EVENT_DATA_SET_EVENTS_PAGINATED_LIST,
    payload: ws.get(
      `/events/data_sets/${eventDataSetId}/events/page/${pageNumber}/rows/${pageSize}` +
        '?modelConfigurationId=' +
        (modelConfiguration && modelConfiguration.id
          ? modelConfiguration.id
          : '') +
        '&modelSetId=' +
        (modelSet && modelSet.id ? modelSet.id : '') +
        '&torsoThreshold=' +
        torsoThreshold +
        (cell ? '&cell=' + cell.id : '')
    )
  });

export const eventDataSetValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_EVENT_DATA_SET_VALUE_UPDATE,
    payload: values
  });

export const eventDataSetValueReset = () => dispatch =>
  dispatch({
    type: CREATE_EVENT_DATA_SET_VALUE_RESET
  });

export const uploadEventDataSetFilterWalksFile = (
  filterIndex,
  file
) => dispatch => {
  const formData = new FormData();
  formData.append('file', file);

  dispatch({
    type: UPLOAD_EVENT_DATA_SET_FILTER_WALKS_FILE,
    payload: ws.postFormData(
      `/events/data_set_filter/${filterIndex}/upload_walks_file`,
      formData
    )
  });
};

export const selectDataSetListDataSet = dataSet => dispatch =>
  dispatch({
    type: SELECT_DATA_SET_LIST_DATA_SET,
    payload: dataSet.id
  });

export const clearEventDataSetListSelectedEventDataSets = () => dispatch =>
  dispatch({
    type: CLEAR_EVENT_DATA_SET_LIST_SELECTED_EVENT_DATA_SETS
  });

export const saveEventDataSet = eventDataSet => dispatch =>
  dispatch({
    type: SAVE_EVENT_DATA_SET,
    payload: ws.post('/events/data_sets/', eventDataSet)
  });

export const createEventDownloadFile = eventDataSetId => dispatch =>
  dispatch({
    type: CREATE_EVENT_DATA_SET_EVENT_DOWNLOAD_FILE,
    payload: ws.post(`/events/data_sets/${eventDataSetId}/data_download/create`)
  });

export const deleteEventDataSet = eventDataSet => dispatch =>
  dispatch({
    type: DELETE_EVENT_DATA_SET,
    payload: ws.post(`/events/data_sets/${eventDataSet.id}/delete`)
  });

export const editEventDataSet = id => dispatch =>
  dispatch({
    type: EDIT_EVENT_DATA_SET,
    payload: ws.get(`/events/data_sets/${id}`)
  });

export const clearEventDataSetDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_EVENT_DATA_SET_DELETE_MESSAGE
  });

export const findEventDataSet = id => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET,
    payload: ws.get(`/events/data_sets/${id}`)
  });

export const findEventDataSetSilent = id => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_SILENT,
    payload: ws.get(`/events/data_sets/${id}`)
  });

export const findEventDataSetSummary = id => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_SUMMARY,
    payload: ws.get(`/events/data_sets/${id}/summary`)
  });

export const setEventDataSetCommentsEvent = event => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_COMMENTS_EVENT,
    payload: event
  });

export const findEventDataSetEventsDetailsEventS3Files = eventId => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_S3_FILES,
    payload: ws.get('/events/' + eventId + '/s3_files/')
  });

export const findEventDataSetEventsDetailsEventTrainerData = trainerId => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_TRAINER_DATA,
    payload: ws.get(`/xonar_api/v1/trainer-data/${trainerId}`)
  });

export const findEventDataSetEventsDetailsEventEvent = trainerId => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_EVENT,
    payload: ws.get(`/events/${trainerId}`)
  });

export const findEventDataSetEventsDetailsSession = sessionId => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_EVENTS_DETAILS_SESSION,
    payload: ws.get(`/sessions/${sessionId}`)
  });

export const saveEventDataSetFilter = (
  eventDataSet,
  filter,
  filterIndex
) => dispatch =>
  dispatch({
    type: SAVE_EVENT_DATA_SET_FILTER,
    payload: ws.post(`/events/data_set_filter/save`, {
      eventDataSet,
      filter,
      filterIndex
    })
  });

export const importEventDataSetFilters = (eventDataSet, filters) => dispatch =>
  dispatch({
    type: SAVE_EVENT_DATA_SET_FILTER,
    payload: ws.post(`/events/data_set_filters/import`, {
      eventDataSet,
      filters
    })
  });

export const setEventDataSetCreateFilterIndex = filterIndex => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_CREATE_FILTER_INDEX,
    payload: filterIndex
  });

export const setEventDataSetCreateFilterValue = (
  field,
  value,
  filterIndex
) => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_CREATE_FILTER_VALUE,
    payload: {
      field,
      value,
      filterIndex
    }
  });

export const addEventDataSetFilter = () => dispatch =>
  dispatch({
    type: ADD_EVENT_DATA_SET_FILTER
  });

export const removeEventDataSetFilter = filterIndex => dispatch =>
  dispatch({
    type: REMOVE_EVENT_DATA_SET_FILTER,
    payload: filterIndex
  });

export const clearEventDataSetFilter = filterIndex => dispatch =>
  dispatch({
    type: CLEAR_EVENT_DATA_SET_FILTER,
    payload: filterIndex
  });

export const setEventDataSetFilterOpen = open => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_FILTER_OPEN,
    payload: open
  });

export const setEventDataSetDetailsEventsFilterIndex = filterIndex => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_DETAILS_EVENTS_FILTER_INDEX,
    payload: filterIndex
  });

export const findEventDataSetAnalysisOverview = (
  id,
  modelConfiguration,
  modelSet,
  torsoThreshold,
  cell
) => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_ANALYSIS_OVERVIEW,
    payload: ws.get(
      '/events/data_sets/' +
        id +
        '/analysis/overview' +
        '?modelConfigurationId=' +
        (modelConfiguration && modelConfiguration.id
          ? modelConfiguration.id
          : '') +
        '&modelSetId=' +
        (modelSet && modelSet.id ? modelSet.id : '') +
        '&torsoThreshold=' +
        torsoThreshold +
        (cell ? '&cell=' + cell.id : '')
    )
  });

export const setEventDataSetDetailsAnalysisTorsoThreshold = torsoThreshold => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_DETAILS_ANALYSIS_TORSO_THRESHOLD,
    payload: {
      torsoThreshold
    }
  });

export const setEventDataSetDetailsModelConfiguration = modelConfiguration => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_DETAILS_MODEL_CONFIGURATION,
    payload: modelConfiguration
  });

export const setEventDataSetDetailsModelSet = modelSet => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_DETAILS_MODEL_SET,
    payload: modelSet
  });

export const setEventDataSetDetailsAnalysisCell = cell => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_DETAILS_ANALYSIS_CELL,
    payload: cell
  });

export const setEventDataSetListTab = tab => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_LIST_TAB,
    payload: tab
  });

export const setEventDataSetListType = dataSetType => dispatch =>
  dispatch({
    type: SET_EVENT_DATA_SET_LIST_TYPE,
    payload: dataSetType
  });

export const findMaleBmiCategoryPercentages = dataSetId => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/events/data_sets/${dataSetId}/reports/bmi_categories/gender/MALE`
    )
  });

export const findFemaleBmiCategoryPercentages = dataSetId => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/events/data_sets/${dataSetId}/reports/bmi_categories/gender/FEMALE`
    )
  });

export const findMaleHeightCategoryPercentages = dataSetId => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/events/data_sets/${dataSetId}/reports/height_categories/gender/MALE`
    )
  });

export const findFemaleHeightCategoryPercentages = dataSetId => dispatch =>
  dispatch({
    type: FIND_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/events/data_sets/${dataSetId}/reports/height_categories/gender/FEMALE`
    )
  });

export const findComparisonMaleBmiCategoryPercentages = dataSetId => dispatch =>
  dispatch({
    type: FIND_COMPARISON_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/events/data_sets/${dataSetId}/reports/bmi_categories/gender/MALE`
    )
  });

export const findComparisonFemaleBmiCategoryPercentages = dataSetId => dispatch =>
  dispatch({
    type: FIND_COMPARISON_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/events/data_sets/${dataSetId}/reports/bmi_categories/gender/FEMALE`
    )
  });

export const findComparisonMaleHeightCategoryPercentages = dataSetId => dispatch =>
  dispatch({
    type: FIND_COMPARISON_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/events/data_sets/${dataSetId}/reports/height_categories/gender/MALE`
    )
  });

export const findComparisonFemaleHeightCategoryPercentages = dataSetId => dispatch =>
  dispatch({
    type: FIND_COMPARISON_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES,
    payload: ws.get(
      `/events/data_sets/${dataSetId}/reports/height_categories/gender/FEMALE`
    )
  });

export const addDataSetReportsComparisonDataSet = dataSet => dispatch =>
  dispatch({
    type: ADD_DATA_SET_REPORTS_COMPARISON_DATA_SET,
    payload: dataSet
  });

export const removeDataSetReportsComparisonDataSet = dataSet => dispatch =>
  dispatch({
    type: REMOVE_DATA_SET_REPORTS_COMPARISON_DATA_SET,
    payload: dataSet
  });

export const createDataSetAnalysisSetComparisonFilters = dataSetIds => dispatch =>
  dispatch({
    type: CREATE_DATA_SET_ANALYSIS_COMPARISON_FILTERS,
    payload: ws.post(
      `/testing/analysis/overview/set_data_set_comparison_filters`,
      dataSetIds
    )
  });

export const deleteWalkCollections = walkCollectionIds => dispatch =>
  dispatch({
    type: DELETE_WALK_COLLECTIONS,
    payload: ws.post(`/events/data_sets/delete`, walkCollectionIds)
  });

/* Constants */

export const FIND_EVENT_DATA_SETS = 'FIND_EVENT_DATA_SETS';
export const FIND_EVENT_DATA_SETS_PENDING = 'FIND_EVENT_DATA_SETS_PENDING';
export const FIND_EVENT_DATA_SETS_FULFILLED = 'FIND_EVENT_DATA_SETS_FULFILLED';

export const EVENT_DATA_SETS_PAGINATED_LIST = 'EVENT_DATA_SETS_PAGINATED_LIST';
export const EVENT_DATA_SETS_PAGINATED_LIST_PENDING =
  'EVENT_DATA_SETS_PAGINATED_LIST_PENDING';
export const EVENT_DATA_SETS_PAGINATED_LIST_FULFILLED =
  'EVENT_DATA_SETS_PAGINATED_LIST_FULFILLED';

export const EVENT_DATA_SET_EVENTS_PAGINATED_LIST =
  'EVENT_DATA_SET_EVENTS_PAGINATED_LIST';
export const EVENT_DATA_SET_EVENTS_PAGINATED_LIST_PENDING =
  'EVENT_DATA_SET_EVENTS_PAGINATED_LIST_PENDING';
export const EVENT_DATA_SET_EVENTS_PAGINATED_LIST_FULFILLED =
  'EVENT_DATA_SET_EVENTS_PAGINATED_LIST_FULFILLED';

export const CREATE_EVENT_DATA_SET_VALUE_UPDATE =
  'CREATE_EVENT_DATA_SETS_VALUE_UPDATE';
export const CREATE_EVENT_DATA_SET_VALUE_RESET =
  'CREATE_EVENT_DATA_SET_VALUE_RESET';

export const SET_EVENT_DATA_SET_COMPONENT_EXCLUDED =
  'SET_EVENT_DATA_SET_COMPONENT_EXCLUDED';

export const UPLOAD_EVENT_DATA_SET_FILTER_WALKS_FILE =
  'UPLOAD_EVENT_DATA_SET_FILTER_WALKS_FILE';
export const UPLOAD_EVENT_DATA_SET_FILTER_WALKS_FILE_PENDING =
  'UPLOAD_EVENT_DATA_SET_FILTER_WALKS_FILE_PENDING';
export const UPLOAD_EVENT_DATA_SET_FILTER_WALKS_FILE_FULFILLED =
  'UPLOAD_EVENT_DATA_SET_FILTER_WALKS_FILE_FULFILLED';

export const SELECT_DATA_SET_LIST_DATA_SET = 'SELECT_DATA_SET_LIST_DATA_SET';

export const CLEAR_EVENT_DATA_SET_LIST_SELECTED_EVENT_DATA_SETS =
  'CLEAR_EVENT_DATA_SET_LIST_SELECTED_EVENT_DATA_SETS';

export const SAVE_EVENT_DATA_SET = 'SAVE_EVENT_DATA_SET';
export const SAVE_EVENT_DATA_SET_PENDING = 'SAVE_EVENT_DATA_SET_PENDING';
export const SAVE_EVENT_DATA_SET_FULFILLED = 'SAVE_EVENT_DATA_SET_FULFILLED';

export const DELETE_EVENT_DATA_SET = 'DELETE_EVENT_DATA_SET';
export const DELETE_EVENT_DATA_SET_PENDING = 'DELETE_EVENT_DATA_SET_PENDING';
export const DELETE_EVENT_DATA_SET_FULFILLED =
  'DELETE_EVENT_DATA_SET_FULFILLED';

export const EDIT_EVENT_DATA_SET = 'EDIT_EVENT_DATA_SET';
export const EDIT_EVENT_DATA_SET_FULFILLED = 'EDIT_EVENT_DATA_SET_FULFILLED';

export const FIND_EVENT_DATA_SET = 'FIND_EVENT_DATA_SET';
export const FIND_EVENT_DATA_SET_PENDING = 'FIND_EVENT_DATA_SET_PENDING';
export const FIND_EVENT_DATA_SET_FULFILLED = 'FIND_EVENT_DATA_SET_FULFILLED';

export const FIND_EVENT_DATA_SET_SILENT = 'FIND_EVENT_DATA_SET_SILENT';
export const FIND_EVENT_DATA_SET_SILENT_PENDING =
  'FIND_EVENT_DATA_SET_SILENT_PENDING';
export const FIND_EVENT_DATA_SET_SILENT_FULFILLED =
  'FIND_EVENT_DATA_SET_SILENT_FULFILLED';

export const FIND_EVENT_DATA_SET_SUMMARY = 'FIND_EVENT_DATA_SET_SUMMARY';
export const FIND_EVENT_DATA_SET_SUMMARY_PENDING =
  'FIND_EVENT_DATA_SET_SUMMARY_PENDING';
export const FIND_EVENT_DATA_SET_SUMMARY_FULFILLED =
  'FIND_EVENT_DATA_SET_SUMMARY_FULFILLED';

export const CLEAR_EVENT_DATA_SET_DELETE_MESSAGE =
  'CLEAR_EVENT_DATA_SET_DELETE_MESSAGE';

export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_S3_FILES =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_S3_FILES';
export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_S3_FILES_PENDING =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_S3_FILES_PENDING';
export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_S3_FILES_FULFILLED =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_S3_FILES_FULFILLED';

export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_TRAINER_DATA =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_TRAINER_DATA';
export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_TRAINER_DATA_PENDING =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_TRAINER_DATA_PENDING';
export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_TRAINER_DATA_FULFILLED =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_TRAINER_DATA_FULFILLED';

export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_EVENT =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_EVENT';
export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_EVENT_PENDING =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_EVENT_PENDING';
export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_EVENT_FULFILLED =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_EVENT_EVENT_FULFILLED';

export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_SESSION =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_SESSION';
export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_SESSION_PENDING =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_SESSION_PENDING';
export const FIND_EVENT_DATA_SET_EVENTS_DETAILS_SESSION_FULFILLED =
  'FIND_EVENT_DATA_SET_EVENTS_DETAILS_SESSION_FULFILLED';

export const SET_EVENT_DATA_SET_COMMENTS_EVENT =
  'SET_EVENT_DATA_SET_COMMENTS_EVENT';

export const SET_EVENT_DATA_SET_CREATE_FILTER_INDEX =
  'SET_EVENT_DATA_SET_CREATE_FILTER_INDEX';

export const SET_EVENT_DATA_SET_CREATE_FILTER_VALUE =
  'SET_EVENT_DATA_SET_CREATE_FILTER_VALUE';

export const SAVE_EVENT_DATA_SET_FILTER = 'SAVE_EVENT_DATA_SET_FILTER';
export const SAVE_EVENT_DATA_SET_FILTER_PENDING =
  'SAVE_EVENT_DATA_SET_FILTER_PENDING';
export const SAVE_EVENT_DATA_SET_FILTER_FULFILLED =
  'SAVE_EVENT_DATA_SET_FILTER_FULFILLED';

export const CREATE_EVENT_DATA_SET_EVENT_DOWNLOAD_FILE =
  'CREATE_EVENT_DATA_SET_EVENT_DOWNLOAD_FILE';
export const CREATE_EVENT_DATA_SET_EVENT_DOWNLOAD_FILE_PENDING =
  'CREATE_EVENT_DATA_SET_EVENT_DOWNLOAD_FILE_PENDING';
export const CREATE_EVENT_DATA_SET_EVENT_DOWNLOAD_FILE_FULFILLED =
  'CREATE_EVENT_DATA_SET_EVENT_DOWNLOAD_FILE_FULFILLED';

export const ADD_EVENT_DATA_SET_FILTER = 'ADD_EVENT_DATA_SET_FILTER';
export const CLEAR_EVENT_DATA_SET_FILTER = 'CLEAR_EVENT_DATA_SET_FILTER';
export const REMOVE_EVENT_DATA_SET_FILTER = 'REMOVE_EVENT_DATA_SET_FILTER';

export const SET_EVENT_DATA_SET_FILTER_OPEN = 'SET_EVENT_DATA_SET_FILTER_OPEN';

export const SET_EVENT_DATA_SET_DETAILS_EVENTS_FILTER_INDEX =
  'SET_EVENT_DATA_SET_DETAILS_EVENTS_FILTER_INDEX';

export const EVENT_DATA_SET_UPDATED = 'EVENT_DATA_SET_UPDATED';

export const FIND_EVENT_DATA_SET_ANALYSIS_OVERVIEW =
  'FIND_EVENT_DATA_SET_ANALYSIS_OVERVIEW';
export const FIND_EVENT_DATA_SET_ANALYSIS_OVERVIEW_PENDING =
  'FIND_EVENT_DATA_SET_ANALYSIS_OVERVIEW_PENDING';
export const FIND_EVENT_DATA_SET_ANALYSIS_OVERVIEW_FULFILLED =
  'FIND_EVENT_DATA_SET_ANALYSIS_OVERVIEW_FULFILLED';

export const SET_EVENT_DATA_SET_DETAILS_ANALYSIS_TORSO_THRESHOLD =
  'SET_EVENT_DATA_SET_DETAILS_ANALYSIS_TORSO_THRESHOLD';

export const SET_EVENT_DATA_SET_DETAILS_MODEL_CONFIGURATION =
  'SET_EVENT_DATA_SET_DETAILS_MODEL_CONFIGURATION';

export const SET_EVENT_DATA_SET_DETAILS_MODEL_SET =
  'SET_EVENT_DATA_SET_DETAILS_MODEL_SET';

export const SET_EVENT_DATA_SET_DETAILS_ANALYSIS_CELL =
  'SET_EVENT_DATA_SET_DETAILS_ANALYSIS_CELL';

export const SET_EVENT_DATA_SET_LIST_TAB = 'SET_EVENT_DATA_SET_LIST_TAB';

export const SET_EVENT_DATA_SET_LIST_TYPE = 'SET_EVENT_DATA_SET_LIST_TYPE';

export const FIND_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES =
  'FIND_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES';
export const FIND_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_PENDING =
  'FIND_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_PENDING';
export const FIND_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES =
  'FIND_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES';
export const FIND_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_PENDING =
  'FIND_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_PENDING';
export const FIND_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES =
  'FIND_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES';
export const FIND_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING =
  'FIND_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING';
export const FIND_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES =
  'FIND_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES';
export const FIND_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING =
  'FIND_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING';
export const FIND_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_COMPARISON_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES =
  'FIND_COMPARISON_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES';
export const FIND_COMPARISON_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_PENDING =
  'FIND_COMPARISON_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_PENDING';
export const FIND_COMPARISON_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_COMPARISON_EVENT_DATA_SET_MALE_BMI_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_COMPARISON_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES =
  'FIND_COMPARISON_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES';
export const FIND_COMPARISON_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_PENDING =
  'FIND_COMPARISON_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_PENDING';
export const FIND_COMPARISON_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_COMPARISON_EVENT_DATA_SET_FEMALE_BMI_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_COMPARISON_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES =
  'FIND_COMPARISON_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES';
export const FIND_COMPARISON_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING =
  'FIND_COMPARISON_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING';
export const FIND_COMPARISON_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_COMPARISON_EVENT_DATA_SET_MALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED';

export const FIND_COMPARISON_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES =
  'FIND_COMPARISON_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES';
export const FIND_COMPARISON_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING =
  'FIND_COMPARISON_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING';
export const FIND_COMPARISON_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED =
  'FIND_COMPARISON_EVENT_DATA_SET_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED';

export const ADD_DATA_SET_REPORTS_COMPARISON_DATA_SET =
  'ADD_DATA_SET_REPORTS_COMPARISON_DATA_SET';

export const REMOVE_DATA_SET_REPORTS_COMPARISON_DATA_SET =
  'REMOVE_DATA_SET_REPORTS_COMPARISON_DATA_SET';

export const CREATE_DATA_SET_ANALYSIS_COMPARISON_FILTERS =
  'CREATE_DATA_SET_ANALYSIS_COMPARISON_FILTERS';
export const CREATE_DATA_SET_ANALYSIS_COMPARISON_FILTERS_PENDING =
  'CREATE_DATA_SET_ANALYSIS_COMPARISON_FILTERS_PENDING';
export const CREATE_DATA_SET_ANALYSIS_COMPARISON_FILTERS_FULFILLED =
  'CREATE_DATA_SET_ANALYSIS_COMPARISON_FILTERS_FULFILLED';

export const DELETE_WALK_COLLECTIONS = 'DELETE_WALK_COLLECTIONS';
export const DELETE_WALK_COLLECTIONS_PENDING =
  'DELETE_WALK_COLLECTIONS_PENDING';
export const DELETE_WALK_COLLECTIONS_FULFILLED =
  'DELETE_WALK_COLLECTIONS_FULFILLED';
