import ws from '../utils/ws';
import { ENTITY_TYPES } from 'common/constants';

/* Actions */

export const validateEvents = (entityType, entityId) => dispatch =>
  dispatch({
    type: VALIDATE_EVENTS,
    payload: {
      entityType,
      entityId
    }
  });

export const closeValidateEvents = () => dispatch =>
  dispatch({
    type: CLOSE_VALIDATE_EVENTS
  });

export const setValidateEventsProcessingEventId = eventId => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_PROCESSING_EVENT_ID,
    payload: eventId
  });

export const setValidateEventsDragEnterSubjectArea = subjectArea => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_DRAG_ENTER_SUBJECT_AREA,
    payload: subjectArea
  });

export const setValidateEventsDragObjectType = objectType => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_DRAG_OBJECT_TYPE,
    payload: objectType
  });

export const setValidateEventsAddSubjectAreaContentOpen = open => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_ADD_SUBJECT_AREA_CONTENT_OPEN,
    payload: open
  });

export const setValidateEventsCreateObjectTypeOpen = open => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_CREATE_OBJECT_TYPE_OPEN,
    payload: open
  });

export const validateEventsSubjectAreaContentValueUpdate = (
  field,
  value
) => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_SUBJECT_AREA_CONTENT_VALUE,
    payload: {
      field,
      value
    }
  });

export const setValidateEventsDisplayVideo = displayVideo => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_DISPLAY_VIDEO,
    payload: displayVideo
  });

export const setValidateEventsPageNumber = pageNumber => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_PAGE_NUMBER,
    payload: pageNumber
  });

export const eventValidationCellList = (entityType, entityId) => dispatch =>
  dispatch({
    type: VALIDATE_EVENTS_CELL_LIST,
    payload: ws.get(`/customer_events/${entityId}/cells/`)
  });

export const eventValidationPaginatedList = (
  entityType,
  entityId,
  pageNumber,
  pageSize,
  cell,
  validationStatus,
  previousPage
) => dispatch =>
  dispatch({
    type: previousPage
      ? VALIDATE_EVENTS_PAGINATED_LIST_PREVIOUS_PAGE
      : VALIDATE_EVENTS_PAGINATED_LIST,
    payload: ws.get(
      `/customer_events/${entityId}/events/validation/page/${pageNumber}/rows/${pageSize}?cell=${
        cell?.id ? cell.id : ''
      }&validationStatus=${
        validationStatus && validationStatus !== 'all' ? validationStatus : ''
      }`
    )
  });

export const eventValidationStatusSummary = (
  entityType,
  entityId
) => dispatch =>
  dispatch({
    type: VALIDATE_EVENTS_STATUS_SUMMARY,
    payload: ws.get(`/customer_events/${entityId}/events/validation_summary`)
  });

export const validateEventsMarkNoObjectVerified = (
  entityType,
  entityId
) => dispatch =>
  dispatch({
    type: MARK_NO_OBJECT_DETECTED_VERIFIED,
    payload: ws.post(
      `/customer_events/${entityId}/mark_no_object_detected_verified`
    )
  });

export const setValidateEventsSelectedEvent = event => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_SELECTED_EVENT,
    payload: event
  });

export const setValidateEventsSelectedCell = cell => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_SELECTED_CELL,
    payload: cell
  });

export const setConfirmMarkNoObjectVerifiedOpen = open => dispatch =>
  dispatch({
    type: SET_CONFIRM_MARK_NO_OBJECT_VERIFIED_OPEN,
    payload: open
  });

export const setValidateEventsSelectedValidationStatus = status => dispatch =>
  dispatch({
    type: SET_VALIDATE_EVENTS_SELECTED_VALIDATION_STATUS,
    payload: status
  });

export const validateEventsUpdateEventValidationStatus = (
  eventId,
  validationStatus
) => dispatch =>
  dispatch({
    type: VALIDATE_EVENTS_UPDATE_EVENT_VALIDATION_STATUS,
    payload: ws.post(`/events/${eventId}/validation_status`, {
      validationStatus
    })
  });

export const findEventValidationImageFiles = event => dispatch =>
  dispatch({
    type: FIND_EVENT_VALIDATION_IMAGE_FILES,
    payload: ws.get('/events/' + event.id + '/image_files/')
  });

/* Constants */

export const VALIDATE_EVENTS = 'VALIDATE_EVENTS';
export const CLOSE_VALIDATE_EVENTS = 'CLOSE_VALIDATE_EVENTS';
export const SET_VALIDATE_EVENTS_PROCESSING_EVENT_ID =
  'SET_EVENT_VALIDATION_PROCESSING_EVENT_ID';
export const SET_VALIDATE_EVENTS_DRAG_ENTER_SUBJECT_AREA =
  'SET_VALIDATE_EVENTS_DRAG_ENTER_SUBJECT_AREA';
export const SET_VALIDATE_EVENTS_DRAG_OBJECT_TYPE =
  'SET_VALIDATE_EVENTS_DRAG_OBJECT_TYPE';
export const SET_VALIDATE_EVENTS_ADD_SUBJECT_AREA_CONTENT_OPEN =
  'SET_VALIDATE_EVENTS_ADD_SUBJECT_AREA_CONTENT_OPEN';
export const SET_VALIDATE_EVENTS_CREATE_OBJECT_TYPE_OPEN =
  'SET_VALIDATE_EVENTS_CREATE_OBJECT_TYPE_OPEN';
export const SET_VALIDATE_EVENTS_SUBJECT_AREA_CONTENT_VALUE =
  'SET_VALIDATE_EVENTS_SUBJECT_AREA_CONTENT_VALUE';
export const SET_VALIDATE_EVENTS_DISPLAY_VIDEO =
  'SET_VALIDATE_EVENTS_DISPLAY_VIDEO';

export const SET_VALIDATE_EVENTS_PAGE_NUMBER =
  'SET_VALIDATE_EVENTS_PAGE_NUMBER';

export const VALIDATE_EVENTS_CELL_LIST = 'VALIDATE_EVENTS_CELL_LIST';
export const VALIDATE_EVENTS_CELL_LIST_PENDING =
  'VALIDATE_EVENTS_CELL_LIST_PENDING';
export const VALIDATE_EVENTS_CELL_LIST_FULFILLED =
  'VALIDATE_EVENTS_CELL_LIST_FULFILLED';

export const VALIDATE_EVENTS_PAGINATED_LIST = 'VALIDATE_EVENTS_PAGINATED_LIST';
export const VALIDATE_EVENTS_PAGINATED_LIST_PENDING =
  'VALIDATE_EVENTS_PAGINATED_LIST_PENDING';
export const VALIDATE_EVENTS_PAGINATED_LIST_FULFILLED =
  'VALIDATE_EVENTS_PAGINATED_LIST_FULFILLED';

export const VALIDATE_EVENTS_PAGINATED_LIST_PREVIOUS_PAGE =
  'VALIDATE_EVENTS_PAGINATED_LIST_PREVIOUS_PAGE';
export const VALIDATE_EVENTS_PAGINATED_LIST_PREVIOUS_PAGE_PENDING =
  'VALIDATE_EVENTS_PAGINATED_LIST_PREVIOUS_PAGE_PENDING';
export const VALIDATE_EVENTS_PAGINATED_LIST_PREVIOUS_PAGE_FULFILLED =
  'VALIDATE_EVENTS_PAGINATED_LIST_PREVIOUS_PAGE_FULFILLED';

export const VALIDATE_EVENTS_STATUS_SUMMARY = 'VALIDATE_EVENTS_STATUS_SUMMARY';
export const VALIDATE_EVENTS_STATUS_SUMMARY_PENDING =
  'VALIDATE_EVENTS_STATUS_SUMMARY_PENDING';
export const VALIDATE_EVENTS_STATUS_SUMMARY_FULFILLED =
  'VALIDATE_EVENTS_STATUS_SUMMARY_FULFILLED';

export const MARK_NO_OBJECT_DETECTED_VERIFIED =
  'MARK_NO_OBJECT_DETECTED_VERIFIED';
export const MARK_NO_OBJECT_DETECTED_VERIFIED_PENDING =
  'MARK_NO_OBJECT_DETECTED_VERIFIED_PENDING';
export const MARK_NO_OBJECT_DETECTED_VERIFIED_FULFILLED =
  'MARK_NO_OBJECT_DETECTED_VERIFIED_FULFILLED';

export const VALIDATE_EVENTS_UPDATE_EVENT_VALIDATION_STATUS =
  'VALIDATE_EVENTS_UPDATE_EVENT_VALIDATION_STATUS';
export const VALIDATE_EVENTS_UPDATE_EVENT_VALIDATION_STATUS_PENDING =
  'VALIDATE_EVENTS_UPDATE_EVENT_VALIDATION_STATUS_PENDING';
export const VALIDATE_EVENTS_UPDATE_EVENT_VALIDATION_STATUS_FULFILLED =
  'VALIDATE_EVENTS_UPDATE_EVENT_VALIDATION_STATUS_FULFILLED';

export const FIND_EVENT_VALIDATION_IMAGE_FILES =
  'FIND_EVENT_VALIDATION_IMAGE_FILES';
export const FIND_EVENT_VALIDATION_IMAGE_FILES_PENDING =
  'FIND_EVENT_VALIDATION_IMAGE_FILES_PENDING';
export const FIND_EVENT_VALIDATION_IMAGE_FILES_FULFILLED =
  'FIND_EVENT_VALIDATION_IMAGE_FILES_FULFILLED';

export const SET_VALIDATE_EVENTS_SELECTED_EVENT =
  'SET_VALIDATE_EVENTS_SELECTED_EVENT';

export const SET_VALIDATE_EVENTS_SELECTED_CELL =
  'SET_VALIDATE_EVENTS_SELECTED_CELL';

export const SET_CONFIRM_MARK_NO_OBJECT_VERIFIED_OPEN =
  'SET_CONFIRM_MARK_NO_OBJECT_VERIFIED_OPEN';

export const SET_VALIDATE_EVENTS_SELECTED_VALIDATION_STATUS =
  'SET_VALIDATE_EVENTS_SELECTED_VALIDATION_STATUS';
