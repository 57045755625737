import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat, Label, OptimalAccuracySumBias } from 'components';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  LinearProgress,
  colors,
  Typography,
  Tooltip
} from '@material-ui/core';
import {
  ANALISYS_OVERVIEW_RESULT_TYPES,
  ANALISYS_OVERVIEW_RESULT_MEASURES,
  ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS
} from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    '& th': {
      verticalAlign: 'bottom'
    }
  },
  content: {
    padding: 0
  },
  result: {
    width: 160
  },
  tooltip: {
    cursor: 'help'
  },
  noResult: {
    display: 'block',
    height: 21
  },
  resultType: {
    background: colors.grey[50],
    '& p': {
      display: 'inline-block',
      fontWeight: 500
    }
  },
  summaryLabel: {
    background: colors.grey[50],
    '& p': {
      display: 'inline-block',
      fontWeight: 500
    }
  },
  separator: {
    height: theme.spacing(3)
  },
  hover: {
    '&:hover': {
      '& td': {
        background: '#F5F6F8'
      }
    }
  },
  scenarioRowHover: {
    '&:hover': {
      '& td': {
        background: '#f6f7ea'
      }
    }
  },
  columnSeparator: {
    borderLeftWidth: 1,
    borderLeftColor: colors.grey[400],
    borderLeftStyle: 'dotted'
  },
  headerControl: {
    backgroundColor: 'white'
  },
  flexGrow: {
    flexGrow: 1
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  fieldGroupInline: {},
  breakevenBiasLabel: {
    marginTop: -1
  },
  biasLabel: {
    marginLeft: theme.spacing(2)
  },
  biasInput: {
    width: 100,
    marginRight: theme.spacing(1),
    '& input': {
      paddingTop: 7,
      paddingBottom: 7
    },
    '& label': {
      marginTop: -4
    }
  },
  activeBiasInput: {
    '& fieldset': {
      borderColor: colors.blue[600]
    },
    '& .MuiInputBase-root:hover': {
      '& fieldset': {
        borderColor: colors.blue[800]
      }
    }
  },
  actionButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  toggleScenarioRows: {
    color: colors.blue[600],
    width: 16,
    height: 16,
    float: 'right',
    cursor: 'pointer'
  },
  scenarioRow: {
    backgroundColor: '#fdfff1'
  },
  scenarioRowResultType: {
    paddingLeft: theme.spacing(4)
  },
  scenarioRowResultTypeLabel: {
    fontWeight: 600
  },
  calculateOptimalAccuracySumBias: {
    marginTop: theme.spacing(2)
  }
}));

const AnalysisOverviewCard = props => {
  const {
    className,
    sessionCount,
    customerEventCount,
    eventCount,
    objectEventCount,
    noObjectEventCount,
    torsoObjectEventCount,
    ankleObjectEventCount,
    analysisOverview,
    customerEvent,
    displayCustomerEventCount,
    loading,
    optimalAccuracySumBias,
    accuraciesDownloadUri
  } = props;

  const classes = useStyles();

  const {
    conventionalBiasResults,
    breakevenBiasResults,
    resultMeasures
  } = analysisOverview;

  const conventionalBiasResultMeasures = conventionalBiasResults
    ? resultMeasures.filter(
        resultMeasure => ANALISYS_OVERVIEW_RESULT_MEASURES[resultMeasure.code]
      )
    : [];
  const breakevenBiasResultMeasures = breakevenBiasResults
    ? resultMeasures.filter(
        resultMeasure => ANALISYS_OVERVIEW_RESULT_MEASURES[resultMeasure.code]
      )
    : [];

  const retrieveResult = (
    biasType,
    resultType,
    resultMeasure,
    scenarioOverview
  ) => {
    let result;
    if (scenarioOverview) {
      result =
        biasType == 'CONVENTIONAL'
          ? scenarioOverview.overview.analysisOverview.conventionalBiasResults[
              resultType
            ].filter(result => result.measure.code === resultMeasure.code)[0]
          : scenarioOverview.overview.analysisOverview.breakevenBiasResults[
              resultType
            ].filter(result => result.measure.code === resultMeasure.code)[0];
    } else {
      result =
        biasType == 'CONVENTIONAL'
          ? conventionalBiasResults[resultType].filter(
              result => result.measure.code === resultMeasure.code
            )[0]
          : breakevenBiasResults[resultType].filter(
              result => result.measure.code === resultMeasure.code
            )[0];
    }

    return result ? result.value : null;
  };

  const Subheader = () => {
    let subHeader;

    if (customerEvent) {
      subHeader = (
        <>
          {!loading &&
            eventCount +
              ' Walk' +
              (eventCount !== 1 ? 's' : '') +
              ' ' +
              '(' +
              objectEventCount +
              ' Object, ' +
              noObjectEventCount +
              ' No Object)'}
          <div>
            <OptimalAccuracySumBias
              optimalAccuracySumBias={optimalAccuracySumBias}
              accuraciesDownloadUri={accuraciesDownloadUri}
            />
          </div>
        </>
      );
    } else {
      subHeader = (
        <>
          {!loading &&
            sessionCount +
              ' Test Session' +
              (sessionCount !== 1 ? 's' : '') +
              ', ' +
              (displayCustomerEventCount
                ? customerEventCount +
                  ' Customer Event' +
                  (customerEventCount !== 1 ? 's' : '') +
                  ', '
                : '') +
              eventCount +
              ' Walk' +
              (eventCount !== 1 ? 's' : '') +
              ' ' +
              '(' +
              objectEventCount +
              ' Object, ' +
              noObjectEventCount +
              ' No Object, ' +
              torsoObjectEventCount +
              ' Torso Object, ' +
              ankleObjectEventCount +
              ' Ankle Object)'}
          <div>
            <OptimalAccuracySumBias
              optimalAccuracySumBias={optimalAccuracySumBias}
              accuraciesDownloadUri={accuraciesDownloadUri}
            />
          </div>
        </>
      );
    }

    return subHeader;
  };

  const AnalyticsCell = props => {
    const {
      colNumber,
      resultMeasure,
      resultType,
      biasType,
      scenarioOverview
    } = props;

    const result = retrieveResult(
      biasType,
      resultType,
      resultMeasure,
      scenarioOverview
    );

    return (
      <TableCell
        key={resultType + resultMeasure.code}
        className={
          colNumber == 0 ? clsx(classes.columnSeparator, classes.result) : ''
        }>
        <Tooltip
          title={
            ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS[resultType]
              ? ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS[resultType][
                  resultMeasure.code
                ]
              : ''
          }
          placement="right">
          <span
            className={`${
              ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS[resultType]
                ? classes.tooltip
                : ''
            } ${result === null ? classes.noResult : ''}`}>
            <NumberFormat
              percentage={resultMeasure.percentage}
              fixedDecimalScale={true}
              precision={resultMeasure.precision}
              value={result}
              suffix={resultMeasure.displaySymbol ? '%' : ''}
            />
          </span>
        </Tooltip>
      </TableCell>
    );
  };

  const AnalyticsRow = props => {
    const {
      breakevenBiasResultMeasures,
      conventionalBiasResultMeasures,
      resultType,
      scenarioOverview
    } = props;

    return (
      <TableRow
        className={clsx(
          scenarioOverview ? classes.scenarioRowHover : classes.hover,
          scenarioOverview ? classes.scenarioRow : ''
        )}>
        <TableCell
          className={
            scenarioOverview
              ? classes.scenarioRowResultType
              : classes.resultType
          }>
          <Typography
            className={
              scenarioOverview ? classes.scenarioRowResultTypeLabel : ''
            }
            variant="body2">
            {scenarioOverview
              ? scenarioOverview.scenario.name
              : ANALISYS_OVERVIEW_RESULT_TYPES[resultType].name}
          </Typography>
        </TableCell>
        {breakevenBiasResultMeasures.map((resultMeasure, i) => (
          <AnalyticsCell
            key={i}
            colNumber={i}
            resultMeasure={resultMeasure}
            resultType={resultType}
            scenarioOverview={scenarioOverview}
            biasType={'BREAKEVEN'}
          />
        ))}
        {conventionalBiasResultMeasures.map((resultMeasure, i) => (
          <AnalyticsCell
            key={i}
            colNumber={i}
            resultMeasure={resultMeasure}
            resultType={resultType}
            scenarioOverview={scenarioOverview}
            biasType={'CONVENTIONAL'}
          />
        ))}
      </TableRow>
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Card>
        <CardHeader title="Analysis Overview" subheader={<Subheader />} />
        <Divider />

        <CardContent className={classes.content}>
          {loading && <LinearProgress />}
          {conventionalBiasResults && (
            <PerfectScrollbar>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell
                      className={classes.columnSeparator}
                      colSpan={
                        Object.keys(ANALISYS_OVERVIEW_RESULT_MEASURES).length
                      }>
                      Breakeven Torso Threshold
                      <Label
                        className={classes.biasLabel}
                        color={colors.blue[600]}>
                        {retrieveResult('CONVENTIONAL', 'BREAKEVEN_BIAS', {
                          code: 'STANDARD_DEVIATION'
                        }) !== null ? (
                          <NumberFormat
                            percentage
                            fixedDecimalScale={true}
                            precision={2}
                            value={retrieveResult(
                              'CONVENTIONAL',
                              'BREAKEVEN_BIAS',
                              { code: 'STANDARD_DEVIATION' }
                            )}
                          />
                        ) : (
                          <>N/A</>
                        )}
                      </Label>
                    </TableCell>
                    <TableCell
                      className={classes.columnSeparator}
                      colSpan={
                        Object.keys(ANALISYS_OVERVIEW_RESULT_MEASURES).length
                      }>
                      Standard/Custom Torso Threshold
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell />
                    {Object.keys(ANALISYS_OVERVIEW_RESULT_MEASURES).map(
                      (resultMeasure, i) => (
                        <TableCell
                          className={
                            i == 0
                              ? clsx(classes.columnSeparator, classes.result)
                              : ''
                          }
                          // className={classes.result}
                          key={resultMeasure}>
                          {ANALISYS_OVERVIEW_RESULT_MEASURES[resultMeasure]
                            .split('\n')
                            .map((row, i) => (
                              <div key={i}>{row}</div>
                            ))}
                        </TableCell>
                      )
                    )}
                    {Object.keys(ANALISYS_OVERVIEW_RESULT_MEASURES).map(
                      (resultMeasure, i) => (
                        <TableCell
                          className={
                            i == 0
                              ? clsx(classes.columnSeparator, classes.result)
                              : ''
                          }
                          key={resultMeasure}>
                          {ANALISYS_OVERVIEW_RESULT_MEASURES[resultMeasure]
                            .split('\n')
                            .map((row, i) => (
                              <div key={i}>{row}</div>
                            ))}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(conventionalBiasResults).map(resultType => (
                    <React.Fragment key={resultType}>
                      {ANALISYS_OVERVIEW_RESULT_TYPES[resultType] &&
                        (!ANALISYS_OVERVIEW_RESULT_TYPES[resultType]
                          .hideEmpty ||
                          conventionalBiasResults[resultType].length > 0) &&
                        !(
                          customerEvent &&
                          ANALISYS_OVERVIEW_RESULT_TYPES[resultType].footEntry
                        ) &&
                        !ANALISYS_OVERVIEW_RESULT_TYPES[resultType]
                          .tableHidden && (
                          <>
                            <AnalyticsRow
                              breakevenBiasResultMeasures={
                                breakevenBiasResultMeasures
                              }
                              conventionalBiasResultMeasures={
                                conventionalBiasResultMeasures
                              }
                              resultType={resultType}
                            />

                            {ANALISYS_OVERVIEW_RESULT_TYPES[resultType]
                              .separator && (
                              <TableRow>
                                <TableCell />
                                <TableCell
                                  className={clsx(
                                    classes.columnSeparator,
                                    classes.separator
                                  )}
                                  colSpan={breakevenBiasResultMeasures.length}
                                />
                                <TableCell
                                  className={clsx(
                                    classes.columnSeparator,
                                    classes.separator
                                  )}
                                  colSpan={
                                    conventionalBiasResultMeasures.length
                                  }
                                />
                              </TableRow>
                            )}
                          </>
                        )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

AnalysisOverviewCard.propTypes = {
  className: PropTypes.string,
  analysisOverview: PropTypes.object.isRequired,
  excelDownloadUri: PropTypes.string
};

export default AnalysisOverviewCard;
