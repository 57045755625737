import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Slider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateModelAnalysisComparisonWeights,
  applyModelAnalysisComparisonWeights
} from 'actions';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  maxAmount: {
    marginLeft: theme.spacing(2)
  },
  flexGrow: {
    flexGrow: 1
  },
  actions: {
    justifyContent: 'flex-end'
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  actionButton: {
    marginTop: 4,
    float: 'right'
  },
  label: {
    marginBottom: 4
  }
}));

const ModelAnalysisComparisonWeights = props => {
  const { weights, modelAnalysisComparison } = useSelector(
    state => state.modelAnalysisComparison
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    dispatch(
      updateModelAnalysisComparisonWeights({
        ...weights,
        [field]: value
      })
    );
  };

  const handleApplyWeights = () => {
    dispatch(
      applyModelAnalysisComparisonWeights(modelAnalysisComparison, weights)
    );
  };

  return (
    <div className={classes.content}>
      <Grid container spacing={8}>
        <Grid item xs={12} lg={3} xl={2}>
          <Typography
            className={classes.label}
            component="p"
            gutterBottom
            variant="overline">
            Object Present Accuracy
          </Typography>
          <div className={classes.fieldGroup}>
            <Slider
              className={classes.flexGrow}
              max={100}
              min={0}
              onChange={(event, value) =>
                handleFieldChange(
                  event,
                  'objectPresentAccuracyWeighting',
                  value
                )
              }
              onChangeCommitted={handleApplyWeights}
              value={weights.objectPresentAccuracyWeighting}
              valueLabelDisplay="auto"
            />
            <Typography className={classes.maxAmount} variant="body1">
              {weights.objectPresentAccuracyWeighting}%
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={3} xl={2}>
          <Typography
            component="p"
            gutterBottom
            variant="overline"
            className={classes.label}>
            No Object Accuracy
          </Typography>
          <div className={classes.fieldGroup}>
            <Slider
              className={classes.flexGrow}
              max={100}
              min={0}
              onChange={(event, value) =>
                handleFieldChange(event, 'noObjectAccuracyWeighting', value)
              }
              onChangeCommitted={handleApplyWeights}
              value={weights.noObjectAccuracyWeighting}
              valueLabelDisplay="auto"
            />
            <Typography className={classes.maxAmount} variant="body1">
              {weights.noObjectAccuracyWeighting}%
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={3} xl={2}>
          <Typography
            component="p"
            gutterBottom
            variant="overline"
            className={classes.label}>
            Num Std Dev
          </Typography>
          <div className={classes.fieldGroup}>
            <Slider
              className={classes.flexGrow}
              max={100}
              min={0}
              onChange={(event, value) =>
                handleFieldChange(
                  event,
                  'numStandardDeviationsWeighting',
                  value
                )
              }
              onChangeCommitted={handleApplyWeights}
              value={weights.numStandardDeviationsWeighting}
              valueLabelDisplay="auto"
            />
            <Typography className={classes.maxAmount} variant="body1">
              {weights.numStandardDeviationsWeighting}%
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={3} xl={2}>
          <Typography
            component="p"
            gutterBottom
            variant="overline"
            className={classes.label}>
            Margin Spread
          </Typography>
          <div className={classes.fieldGroup}>
            <Slider
              className={classes.flexGrow}
              max={100}
              min={0}
              onChange={(event, value) =>
                handleFieldChange(event, 'marginSpreadWeighting', value)
              }
              onChangeCommitted={handleApplyWeights}
              value={weights.marginSpreadWeighting}
              valueLabelDisplay="auto"
            />
            <Typography className={classes.maxAmount} variant="body1">
              {weights.marginSpreadWeighting}%
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ModelAnalysisComparisonWeights.displayName = 'ModelAnalysisComparisonWeights';

ModelAnalysisComparisonWeights.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

ModelAnalysisComparisonWeights.defaultProps = {
  open: false
};

export default ModelAnalysisComparisonWeights;
