import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import {
  Typography,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  colors,
  Button
} from '@material-ui/core';
import { CHART_COLORS } from 'common/constants';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  cardContent: {
    padding: theme.spacing(2),
    paddingBottom: '0!important'
  },
  checkDescriptionCell: {
    width: '50%'
  },
  actions: {
    '& button': {
      marginBottom: -16,
      marginLeft: theme.spacing(1)
    },
    marginLeft: theme.spacing(1)
  },
  groupNotesContent: {
    padding: theme.spacing(1),
    paddingBottom: '8px!important'
  },
  groupNotes: {
    marginBottom: theme.spacing(2)
  },
  buttonExtraSmall: {
    padding: '1px 8px',
    fontSize: '0.812,5rem'
  },
  checkBox: {
    cursor: 'pointer',
    color: colors.green[600]
  },
  checkBoxIncomplete: {
    cursor: 'pointer',
    color: colors.blue[600]
  },
  checkBoxColumn: {
    width: '0%'
  },
  completedByLabel: {
    color: colors.blueGrey[500],
    fontSize: 12
  },
  parentGroupCheckList: {
    marginBottom: theme.spacing(2)
  },
  checkListItemTable: {
    '& td': {
      whiteSpace: 'normal',
      verticalAlign: 'top'
    }
  }
}));

const CheckListItemGroup = props => {
  const {
    cellCheckList,
    checkListItemGroup,
    onEdit,
    onEditCheckListItem,
    onCreateCheckListItemGroup,
    onCreateCheckListItem,
    onCompleteCheckListItem,
    onUnCompleteCheckListItem
  } = props;

  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      style={{
        borderLeft: `4px solid ${
          CHART_COLORS[checkListItemGroup.hierarchyLevel]
        }`
      }}>
      <CardHeader
        title={`${checkListItemGroup.sectionNumber} ${
          checkListItemGroup.name ? checkListItemGroup.name : ''
        }`}
        action={
          <div className={classes.actions}>
            {onEdit && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => onEdit(checkListItemGroup)}>
                Edit
              </Button>
            )}
            {onCreateCheckListItem && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => onCreateCheckListItem(checkListItemGroup)}>
                Add Check List Item
              </Button>
            )}
            {onCreateCheckListItemGroup && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => onCreateCheckListItemGroup(checkListItemGroup)}>
                Add Sub Group
              </Button>
            )}
          </div>
        }
      />
      <Divider />
      <CardContent>
        <Grid container>
          {checkListItemGroup.notes && (
            <Grid item xs={12}>
              <Card className={classes.groupNotes}>
                <CardContent className={classes.groupNotesContent}>
                  <Typography>{checkListItemGroup.notes}</Typography>
                </CardContent>
              </Card>
            </Grid>
          )}

          {checkListItemGroup.checkListItems &&
            checkListItemGroup.checkListItems.length > 0 && (
              <Grid item xs={12}>
                <Card
                  className={
                    checkListItemGroup.childGroups
                      ? classes.parentGroupCheckList
                      : ''
                  }>
                  <PerfectScrollbar>
                    <Table className={classes.checkListItemTable} size="small">
                      <TableHead>
                        <TableRow>
                          {cellCheckList && (
                            <TableCell className={classes.checkBoxColumn} />
                          )}
                          <TableCell>Check</TableCell>
                          <TableCell>Expected Value</TableCell>
                          {cellCheckList && <TableCell>Actual Value</TableCell>}
                          {onEditCheckListItem && (
                            <TableCell>Actions</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {checkListItemGroup.checkListItems.map(
                          checkListItem => (
                            <TableRow key={checkListItem.guid}>
                              {cellCheckList && (
                                <TableCell>
                                  {checkListItem.completed ? (
                                    <CheckBoxIcon
                                      className={classes.checkBox}
                                      onClick={() =>
                                        onUnCompleteCheckListItem(
                                          checkListItemGroup,
                                          checkListItem
                                        )
                                      }
                                    />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon
                                      className={clsx(
                                        classes.checkBox,
                                        classes.checkBoxIncomplete
                                      )}
                                      onClick={() =>
                                        onCompleteCheckListItem(
                                          checkListItemGroup,
                                          checkListItem
                                        )
                                      }
                                    />
                                  )}
                                </TableCell>
                              )}
                              <TableCell
                                className={classes.checkDescriptionCell}>
                                {checkListItem.checkDescription}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: cellCheckList ? '25%' : '50%'
                                }}>
                                {checkListItem.expectedResult}
                              </TableCell>
                              {cellCheckList && (
                                <TableCell>
                                  <div>{checkListItem.actualResult}</div>
                                  {checkListItem.completed && (
                                    <div className={classes.completedByLabel}>
                                      {checkListItem.completedBy?.displayName},{' '}
                                      {moment(
                                        checkListItem.completedTime
                                      ).format('MMM Do YYYY, h:mm:ss a')}
                                    </div>
                                  )}
                                </TableCell>
                              )}
                              {onEditCheckListItem && (
                                <TableCell>
                                  {onEditCheckListItem && (
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      size="small"
                                      className={classes.buttonExtraSmall}
                                      onClick={() =>
                                        onEditCheckListItem(
                                          checkListItemGroup,
                                          checkListItem
                                        )
                                      }>
                                      Edit
                                    </Button>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </PerfectScrollbar>
                </Card>
              </Grid>
            )}
          {checkListItemGroup.childGroups && (
            <Grid item xs={12}>
              {checkListItemGroup.childGroups.map(childGroup => (
                <CheckListItemGroup
                  key={childGroup.guid}
                  cellCheckList={cellCheckList}
                  onEdit={onEdit}
                  onEditCheckListItem={onEditCheckListItem}
                  onCreateCheckListItemGroup={onCreateCheckListItemGroup}
                  onCreateCheckListItem={onCreateCheckListItem}
                  onCompleteCheckListItem={onCompleteCheckListItem}
                  onUnCompleteCheckListItem={onUnCompleteCheckListItem}
                  checkListItemGroup={childGroup}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CheckListItemGroup;
