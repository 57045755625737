import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  customerLoading: false,
  updated: false,
  customer: {
    createdBy: {}
  },
  entityPhotos: [],
  venues: [],
  cells: [],
  customerEvents: [],
  sessionsLoading: false,
  sessions: [],
  analysisOverview: {
    loading: false,
    analysisOverview: {},
    setupAnalysesCount: 0,
    eventCount: 0,
    noObjectEventCount: 0,
    locationEventCounts: {}
  },
  newVenue: null
};

const customerDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMER_DETAILS_NEW_VENUE: {
      return {
        ...state,
        newVenue: action.payload
      };
    }

    case actionTypes.FIND_CUSTOMER_PENDING: {
      return {
        ...initialState,
        customerLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_FULFILLED: {
      return {
        ...state,
        customerLoading: false,
        updated: false,
        customer: action.payload.data.result
      };
    }

    case actionTypes.FIND_CUSTOMER_CELLS_PENDING: {
      return {
        ...state,
        cells: initialState.cells
      };
    }

    case actionTypes.FIND_CUSTOMER_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result
      };
    }

    case actionTypes.FIND_CUSTOMER_CUSTOMER_EVENTS_PENDING: {
      return {
        ...state,
        customerEvents: initialState.customerEvents
      };
    }

    case actionTypes.FIND_CUSTOMER_CUSTOMER_EVENTS_FULFILLED: {
      return {
        ...state,
        customerEvents: action.payload.data.result
      };
    }

    case actionTypes.SAVE_CUSTOMER_PENDING: {
      return {
        ...state,
        customerLoading: true
      };
    }

    case actionTypes.SAVE_CUSTOMER_FULFILLED: {
      return {
        ...state,
        customerLoading: false,
        customer: action.payload.data.result
      };
    }

    case actionTypes.FIND_CUSTOMER_VENUES_PENDING: {
      return {
        ...state,
        venues: initialState.venues
      };
    }

    case actionTypes.FIND_CUSTOMER_VENUES_FULFILLED: {
      return {
        ...state,
        venues: action.payload.data.result
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        customerLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        customerLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CUSTOMER &&
        (entityPhoto.entityId === state.customer.id ||
          entityPhoto.entityTempId === state.customer.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CUSTOMER &&
        (entityPhoto.entityId === state.customer.id ||
          entityPhoto.entityTempId === state.customer.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CUSTOMER &&
        (entityPhoto.entityId === state.customer.id ||
          entityPhoto.entityTempId === state.customer.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    default: {
      return state;
    }
  }
};

export default customerDetailsReducer;
