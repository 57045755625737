import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import ClearIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: 18,
    height: 18,
    color: colors.blueGrey[600]
  }
}));

const MagnetometerResult = props => {
  const { score, className } = props;

  const classes = useStyles();

  if (score !== 1 && score !== 0) return null;

  return (
    <>
      {score === 1 && <WarningIcon className={clsx(classes.root, className)} />}
      {score === 0 && <ClearIcon className={clsx(classes.root, className)} />}
    </>
  );
};

export default MagnetometerResult;
