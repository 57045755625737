import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Modal,
  Card,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  CardContent,
  Tooltip,
  Link
} from '@material-ui/core';
import {
  CHART_COLORS,
  EVENT_SETTING_COMMIT_HASH,
  GIT_COMMIT_URL
} from 'common/constants';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1200,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    '& td': {
      paddingTop: 2,
      paddingBottom: 2
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: theme.spacing(2)
  },
  hashSelect: {
    margin: theme.spacing(2),
    marginBottom: 0
  },
  label: {
    paddingLeft: theme.spacing(1),
    fontSize: '0.8em'
  },
  hashRow: {
    backgroundColor: colors.grey[50]
  },
  hashLabel: {
    fontWeight: 600
  },
  differenceRow: {
    background: colors.orange[50]
  },
  separator: {
    borderLeft: 1,
    borderLeftColor: colors.grey[200],
    borderLeftStyle: 'solid'
  },
  settingLabel: {
    borderLeftWidth: 6,
    borderLeftStyle: 'solid'
  },
  value: {
    maxWidth: 100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '0.8em'
  },
  heading: {
    fontWeight: 600,
    fontSize: '0.8em'
  },
  settingLink: {
    fontWeight: 600
  }
}));

const SessionEventSettingsComparisonModal = props => {
  const { open, sessions, onClose } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const settingsSessions = sessions.filter(
    session => session.hashedEventSettings
  );

  if (!open || !settingsSessions || !settingsSessions.length > 0) {
    return null;
  }

  const uniqueSettings = new Set();
  const sessionHashedEventSettings = {};

  settingsSessions.forEach(session => {
    const hashes = Object.keys(session.hashedEventSettings);
    const hashedEventSettings = {};

    for (let i = 0; i < hashes.length; i++) {
      const hash = hashes[i];
      hashedEventSettings[hash] = JSON.parse(session.hashedEventSettings[hash]);
      const settings = Object.keys(hashedEventSettings[hash]);
      settings.forEach(s => uniqueSettings.add(s));
    }

    sessionHashedEventSettings[session.sessionId] = hashedEventSettings;
  });

  const orderedUniqueSettings = Array.from(uniqueSettings);

  const valueDifferenceSettings = [];

  orderedUniqueSettings.forEach(setting => {
    const settingValues = new Set();
    settingsSessions.forEach(session => {
      Object.keys(sessionHashedEventSettings[session.sessionId]).forEach(
        hash => {
          const settingValue = sessionHashedEventSettings[session.sessionId][
            hash
          ][setting]
            ? sessionHashedEventSettings[session.sessionId][hash][setting]
            : 'undefined';

          if (settingValue) {
            settingValues.add(
              sessionHashedEventSettings[session.sessionId][hash][setting]
            );
          }
        }
      );
    });

    if (settingValues.size > 1) {
      valueDifferenceSettings.push(setting);
    }
  });

  orderedUniqueSettings.sort((a, b) => {
    let result =
      valueDifferenceSettings.indexOf(b) - valueDifferenceSettings.indexOf(a); // Sort value difference settings to top of list
    if (result === 0) {
      result = a.toLowerCase().localeCompare(b.toLowerCase());
    }
    return result;
  });

  const settingPrefixes = [];
  orderedUniqueSettings.forEach(setting => {
    const prefix = setting.substring(0, setting.indexOf('.'));
    if (settingPrefixes.indexOf(prefix) < 0) {
      settingPrefixes.push(prefix);
    }
  });

  const settingPrefixColors = {};
  settingPrefixes.forEach((prefix, i) => {
    settingPrefixColors[prefix] = CHART_COLORS[i];
  });

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader title="Walk Settings" />
        <Divider />
        <CardContent className={classes.content}>
          <Card>
            <PerfectScrollbar>
              <Table>
                <TableBody>
                  <TableRow className={classes.hashRow}>
                    <TableCell className={classes.heading}>Session</TableCell>
                    {settingsSessions.map(session => (
                      <TableCell
                        key={session.sessionId}
                        colSpan={
                          Object.keys(
                            sessionHashedEventSettings[session.sessionId]
                          ).length
                        }
                        className={clsx(classes.separator, classes.heading)}>
                        {session.participantName}
                        {session.createdTime
                          ? ', ' +
                            moment(
                              session.walkedTime
                                ? session.walkedTime
                                : session.createdTime
                            ).format('MMM Do YYYY, h:mm a')
                          : ''}
                      </TableCell>
                    ))}
                  </TableRow>

                  <TableRow className={classes.hashRow}>
                    <TableCell className={classes.heading}>Setting</TableCell>
                    {settingsSessions.map(session => (
                      <React.Fragment key={session.sessionId}>
                        {Object.keys(
                          sessionHashedEventSettings[session.sessionId]
                        ).map((hash, i) => (
                          <TableCell
                            key={session.sessionId + hash}
                            className={clsx(
                              classes.heading,
                              i === 0 ? classes.separator : ''
                            )}>
                            {hash}
                          </TableCell>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableRow>

                  {orderedUniqueSettings.map(setting => (
                    <TableRow
                      key={setting}
                      className={
                        valueDifferenceSettings.indexOf(setting) > -1
                          ? classes.differenceRow
                          : ''
                      }>
                      <TableCell
                        className={clsx(classes.label, classes.settingLabel)}
                        style={{
                          borderLeftColor:
                            valueDifferenceSettings.indexOf(setting) !== -1
                              ? colors.orange[50]
                              : settingPrefixColors[
                                  setting.substring(0, setting.indexOf('.'))
                                ]
                        }}>
                        {setting}
                      </TableCell>
                      {settingsSessions.map(session => (
                        <React.Fragment key={session.sessionId}>
                          {Object.keys(
                            sessionHashedEventSettings[session.sessionId]
                          ).map((hash, i) => (
                            <TableCell
                              key={session.sessionId + hash}
                              className={clsx(
                                i === 0 ? classes.separator : '',
                                classes.value
                              )}>
                              {sessionHashedEventSettings[session.sessionId][
                                hash
                              ][setting] && (
                                <>
                                  {setting === EVENT_SETTING_COMMIT_HASH ? (
                                    <Link
                                      className={classes.settingLink}
                                      color="inherit"
                                      rel="noopener"
                                      target="_blank"
                                      href={`${GIT_COMMIT_URL}${
                                        sessionHashedEventSettings[
                                          session.sessionId
                                        ][hash][setting]
                                      }`}>
                                      {
                                        sessionHashedEventSettings[
                                          session.sessionId
                                        ][hash][setting]
                                      }
                                    </Link>
                                  ) : (
                                    <Tooltip
                                      placement="top-start"
                                      title={
                                        sessionHashedEventSettings[
                                          session.sessionId
                                        ][hash][setting]
                                      }>
                                      <span>
                                        {
                                          sessionHashedEventSettings[
                                            session.sessionId
                                          ][hash][setting]
                                        }
                                      </span>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </TableCell>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Card>
        </CardContent>

        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SessionEventSettingsComparisonModal.defaultProps = {
  open: false
};

export default SessionEventSettingsComparisonModal;
