import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  createFileUploadEntityPhoto,
  clearEntityPhotoUploadValues,
  setEntityPhotoUploadValues,
  setEntityPhotoUploadEntityDetails,
  setEntityPhotoUploadOpen
} from 'actions';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  LinearProgress,
  TextField,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 650,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  progress: {
    marginBottom: theme.spacing(2)
  },
  fileName: {
    marginBottom: theme.spacing(2)
  }
}));

const EntityPhotoFileUploader = props => {
  const {
    entityType,
    entityId,
    entityTempId,
    identifiersCreated,
    className,
    ...rest
  } = props;

  const { processing, entityPhoto, entityDetailsSet, open } = useSelector(
    state => state.entityPhotoUploader
  );

  const dispatch = useDispatch();
  const classes = useStyles();

  if (!entityDetailsSet && identifiersCreated && open) {
    dispatch(
      setEntityPhotoUploadEntityDetails({
        entityType: entityType,
        entityId: entityId,
        entityTempId: entityTempId
      })
    );
  }

  const handleClose = () => {
    dispatch(clearEntityPhotoUploadValues());
    dispatch(setEntityPhotoUploadOpen(false));
  };

  if (!open) {
    return null;
  }

  const handleSave = () => {
    dispatch(createFileUploadEntityPhoto(entityPhoto));
  };

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    dispatch(
      setEntityPhotoUploadValues(entityPhoto => ({
        ...entityPhoto,
        [field]: value
      }))
    );
  };

  const handleFileChange = event => {
    event.persist && event.persist();
    const file = event.target.files[0];

    dispatch(
      setEntityPhotoUploadValues({
        file: file
      })
    );
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="File Upload" />
        <Divider />
        <CardContent className={classes.content}>
          {processing ? (
            <LinearProgress />
          ) : (
            <>
              {entityPhoto.file ? (
                <>
                  <Typography className={classes.fileName} component="p">
                    {entityPhoto.file.name}
                  </Typography>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="Comment"
                    name="comment"
                    onChange={e =>
                      handleFieldChange(e, 'comment', e.target.value)
                    }
                    value={entityPhoto.comment}
                    variant="outlined"
                  />
                </>
              ) : (
                <input
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                  accept=".jpg,.jpeg"
                />
              )}
            </>
          )}
        </CardContent>
        {!processing && (
          <CardActions className={classes.actions}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button
              disabled={!entityPhoto.file}
              onClick={handleSave}
              variant="contained">
              Save
            </Button>
          </CardActions>
        )}
      </Card>
    </Modal>
  );
};

EntityPhotoFileUploader.displayName = 'UploadFile';

EntityPhotoFileUploader.propTypes = {
  entityType: PropTypes.string,
  entityId: PropTypes.string,
  entityTempId: PropTypes.string,
  identifiersCreated: PropTypes.bool,
  className: PropTypes.string
};

export default EntityPhotoFileUploader;
