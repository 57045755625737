import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import { CAMERA_FACING_MODES } from 'common/constants';
import {
  createCameraCaptureEntityPhoto,
  clearEntityPhotoCaptureValues,
  setCameraCaptureFacingMode,
  setCameraCaptureCameraError,
  setCameraCaptureMirrored,
  setEntityPhotoCaptureValues,
  setEntityPhotoCaptureEntityDetails,
  setEntityPhotoCaptureCameraOpen
} from 'actions';
import CameraIcon from '@material-ui/icons/CameraAlt';
import FlipCameraIcon from '@material-ui/icons/FlipCameraIos';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import { Alert } from 'components';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  LinearProgress,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 650,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    '& #white-flash': {
      display: 'none!important'
    },
    '& video': {
      width: '100%',
      height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
      marginBottom: theme.spacing(3)
    },
    '& img[alt="camera"]': {
      display: 'none!important'
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cameraActions: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 240,
    '& svg': {
      height: '1.7em',
      width: '1.7em'
    },
    '& button': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  },
  progress: {
    marginBottom: theme.spacing(2)
  },
  processing: {},
  cameraContainer: {},
  preview: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(2)
  }
}));

const CameraCapture = props => {
  const {
    entityType,
    entityId,
    entityTempId,
    identifiersCreated,
    className,
    ...rest
  } = props;

  const {
    processing,
    entityPhoto,
    facingMode,
    mirrored,
    cameraError,
    entityDetailsSet,
    cameraCaptureOpen
  } = useSelector(state => state.entityPhotoCameraCapture);

  const dispatch = useDispatch();
  const classes = useStyles();
  const webcamRef = React.useRef(null);

  if (!entityDetailsSet && identifiersCreated && cameraCaptureOpen) {
    dispatch(
      setEntityPhotoCaptureEntityDetails({
        entityType: entityType,
        entityId: entityId,
        entityTempId: entityTempId
      })
    );
  }

  const capture = React.useCallback(() => {
    dispatch(
      setEntityPhotoCaptureValues({
        dataUri: webcamRef.current.getScreenshot()
      })
    );
  }, [webcamRef]);

  const handleClose = () => {
    dispatch(clearEntityPhotoCaptureValues());
    dispatch(setEntityPhotoCaptureCameraOpen(false));
  };

  if (!cameraCaptureOpen) {
    return null;
  }

  const handleSave = () => {
    dispatch(createCameraCaptureEntityPhoto(entityPhoto));
  };

  const handleToggleFacingMode = () => {
    const mode =
      facingMode === CAMERA_FACING_MODES.ENVIRONMENT
        ? CAMERA_FACING_MODES.USER
        : CAMERA_FACING_MODES.ENVIRONMENT;

    dispatch(setCameraCaptureFacingMode(mode));
  };

  const handleToggleMirrored = () => {
    dispatch(setCameraCaptureMirrored(!mirrored));
  };

  const handleCameraError = () => {
    dispatch(setCameraCaptureCameraError('Unable to access camera'));
  };

  const handleFieldChange = (event, field, value) => {
    event && event.persist && event.persist();
    dispatch(
      setEntityPhotoCaptureValues(entityPhoto => ({
        ...entityPhoto,
        [field]: value
      }))
    );
  };

  const videoConstraints = {
    width: 1280,
    height: 960,
    facingMode: facingMode
  };

  return (
    <Modal onClose={handleClose} open={cameraCaptureOpen}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={'Camera Capture'} />
        <Divider />
        <CardContent className={classes.content}>
          {cameraError ? (
            <Alert message={cameraError} />
          ) : (
            <>
              {processing ? (
                <LinearProgress />
              ) : (
                <>
                  {entityPhoto.dataUri ? (
                    <>
                      <img
                        className={classes.preview}
                        src={entityPhoto.dataUri}
                      />
                      <TextField
                        className={classes.field}
                        fullWidth
                        label="Comment"
                        name="comment"
                        onChange={e =>
                          handleFieldChange(e, 'comment', e.target.value)
                        }
                        value={entityPhoto.comment}
                        variant="outlined"
                      />
                    </>
                  ) : (
                    <>
                      <Webcam
                        audio={false}
                        mirrored={mirrored}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        onUserMediaError={handleCameraError}
                      />
                      <div className={classes.cameraActions}>
                        <Button
                          disabled={processing}
                          color="primary"
                          onClick={capture}>
                          <CameraIcon />
                        </Button>
                        <Button
                          disabled={processing}
                          color="primary"
                          onClick={handleToggleFacingMode}>
                          <FlipCameraIcon />
                        </Button>
                        <Button
                          disabled={processing}
                          color="primary"
                          onClick={handleToggleMirrored}>
                          <SwitchCameraIcon />
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </CardContent>
        {!processing && (
          <CardActions className={classes.actions}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            {!cameraError && (
              <Button
                disabled={!entityPhoto.dataUri}
                onClick={handleSave}
                variant="contained">
                Save
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </Modal>
  );
};

CameraCapture.displayName = 'CameraCapture';

CameraCapture.propTypes = {
  entityType: PropTypes.string,
  entityId: PropTypes.string,
  entityTempId: PropTypes.string,
  identifiersCreated: PropTypes.bool,
  className: PropTypes.string
};

export default CameraCapture;
