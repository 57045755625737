/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { GIT_COMMIT_URL } from 'common/constants';
import { Link } from '@material-ui/core';

const GitHashVersion = props => {
  const { version } = props;

  if (!version) return null;

  const versionComponents = version.split('.');

  const hashComponents = versionComponents.filter(
    component => component.length === 7
  );

  const gitHash =
    hashComponents.length > 0
      ? hashComponents[hashComponents.length - 1]
      : null;
  const versionNumber =
    versionComponents.length > 0
      ? versionComponents
          .slice(0, versionComponents.length - (gitHash ? 1 : 0))
          .join('.')
      : version;

  return (
    <div>
      {versionNumber}
      {gitHash && (
        <>
          .
          <Link
            // color="inherit"
            target="_blank"
            href={`${GIT_COMMIT_URL}${gitHash}`}
            variant="h6">
            {gitHash}
          </Link>
        </>
      )}
    </div>
  );
};

GitHashVersion.propTypes = {
  version: PropTypes.string
};

export default GitHashVersion;
