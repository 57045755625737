/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_VALIDATION_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const EventValidationStatus = props => {
  const { event, ...rest } = props;

  const statusColors = {
    VERIFIED: colors.green[600],
    SUSPECT: colors.red[600],
    TESTING: colors.blueGrey[600],
    PENDING: colors.blue[600]
  };

  return (
    <div>
      {!event && (
        <Label {...rest} style={{ width: 65 }} color={colors.blue[600]}>
          Unlinked
        </Label>
      )}

      {event && event.validationStatus && (
        <Label
          {...rest}
          style={{ width: 65 }}
          color={statusColors[event.validationStatus]}>
          {EVENT_VALIDATION_STATUSES[event.validationStatus]}
        </Label>
      )}
    </div>
  );
};

EventValidationStatus.propTypes = {
  status: PropTypes.string
};

export default EventValidationStatus;
