import ws from '../utils/ws';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const LOGIN_SESSION_LOGOUT = 'LOGIN_SESSION_LOGOUT';
export const SESSION_LOGIN_PENDING = 'SESSION_LOGIN_PENDING';
export const SESSION_LOGIN_FULFILLED = 'SESSION_LOGIN_FULFILLED';
export const SESSION_LOGIN_REJECTED = 'SESSION_LOGIN_REJECTED';
export const EXPIRE_SESSIONS = 'EXPIRE_SESSIONS';

export const login = credentials => dispatch =>
  dispatch({
    type: SESSION_LOGIN,
    payload: ws.post('/login', credentials)
  });

export const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT
  });

export const loginLogout = () => dispatch =>
  dispatch({
    type: LOGIN_SESSION_LOGOUT
  });
