/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { MODEL_CONFIGURATION_EVENT_MODEL_WORKER_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const ModelConfigurationProcessingStatus = props => {
  const { modelConfiguration, ...rest } = props;

  const statusColors = {
    ACTIVE: colors.green[600],
    INACTIVE: colors.grey[600],
    PAUSED: colors.red[600],
    EXCLUDED: colors.blue[600]
  };

  return (
    <div>
      {modelConfiguration &&
        modelConfiguration.eventModelWorkerStatus &&
        modelConfiguration.type !== 'EXPERIMENTAL' && (
          <Label
            {...rest}
            color={statusColors[modelConfiguration.eventModelWorkerStatus]}>
            {
              MODEL_CONFIGURATION_EVENT_MODEL_WORKER_STATUSES[
                modelConfiguration.eventModelWorkerStatus
              ]
            }
          </Label>
        )}
    </div>
  );
};

ModelConfigurationProcessingStatus.propTypes = {
  modelConfiguration: PropTypes.object
};

export default ModelConfigurationProcessingStatus;
