import * as actionTypes from 'actions';

const initialState = {
  configs: [],
  loading: false
};

const modelConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_MODEL_CONFIGURATIONS_PENDING: {
      return {
        ...state,
        configs: initialState.configs,
        loading: true
      };
    }

    case actionTypes.FIND_MODEL_CONFIGURATIONS_FULFILLED: {
      return {
        ...state,
        configs: action.payload.data.result,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default modelConfigurationReducer;
