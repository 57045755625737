import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { S3File, SetupValidationStatus } from 'components';
import { S3_FILE_TYPES } from 'common/constants';
import { TrainingVideo } from 'components';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  colors,
  Collapse,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 640,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  files: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  createdSessionId: {
    marginTop: theme.spacing(6)
  },
  contentSectionHeader: {
    padding: 15,
    paddingBottom: 8,
    paddingTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  cellLabel: {
    width: 100
  }
}));

const EventTrainerDetails = props => {
  const {
    open,
    onClose,
    training,
    trainerData,
    loading,
    className,
    ...rest
  } = props;
  const classes = useStyles();

  const [showFiles, setShowFiles] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleToggleFiles = () => {
    setShowFiles(showFiles => !showFiles);
  };

  if (!open || loading) {
    return null;
  }

  const s3Files = Object.keys(trainerData.trainer.s3Info).map(fileName => {
    return {
      fileName: fileName,
      fileType: fileName.slice(-3),
      ...trainerData.trainer.s3Info[fileName]
    };
  });

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Trainer Details" />
        <Divider />
        <CardContent className={classes.content}>
          {loading ? (
            <LinearProgress />
          ) : (
            <PerfectScrollbar>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellLabel}>
                      Trainer Id
                    </TableCell>
                    <TableCell>{training && training.id}</TableCell>
                  </TableRow>
                  {training && (
                    <TableRow>
                      <TableCell>Validation Status</TableCell>
                      <TableCell>
                        {training.setupContentsValidationStatus && (
                          <SetupValidationStatus
                            status={training.setupContentsValidationStatus}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {trainerData.trainer._id && (
                <TrainingVideo
                  width={640}
                  height={480}
                  trainingId={trainerData.trainer._id}
                />
              )}
            </PerfectScrollbar>
          )}

          <div
            className={classes.contentSectionHeader}
            onClick={handleToggleFiles}>
            <Typography variant="h6">Files</Typography>
            {showFiles ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>

          <Collapse in={showFiles}>
            <div className={classes.files}>
              <PerfectScrollbar options={{ suppressScrollY: true }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>File</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {s3Files.map(s3File => (
                      <TableRow key={s3File.fileName} hover>
                        <TableCell>{s3File.fileName}</TableCell>
                        <TableCell>{S3_FILE_TYPES[s3File.fileType]}</TableCell>
                        <TableCell>
                          <S3File s3File={s3File} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </div>
          </Collapse>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

EventTrainerDetails.displayName = 'TrainerDetails';

EventTrainerDetails.propTypes = {
  trainerData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

EventTrainerDetails.defaultProps = {
  open: false
};

export default EventTrainerDetails;
