/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { findCells, findWalkCollectionCells } from 'actions';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2)
  }
}));

const SelectCell = props => {
  const {
    style,
    onChange,
    cell,
    walkCollectionId,
    fullWidth,
    filteredCells,
    className,
    margin
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, cells } = useSelector(state => state.cells);

  const filteredCellIds = filteredCells
    ? filteredCells.map(cell => cell.id)
    : null;

  const activeCells =
    filteredCellIds !== null
      ? cells.filter(cell => filteredCellIds.indexOf(cell.id) > -1)
      : cells.filter(
          cell =>
            walkCollectionId ||
            cell.status === 'DEVELOPMENT' ||
            cell.status === 'ACTIVE' ||
            cell.status === 'LAB_PRODUCTION' ||
            cell.status === 'FIELD_PRODUCTION'
        );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (walkCollectionId) {
        dispatch(findWalkCollectionCells(walkCollectionId));
      } else {
        dispatch(findCells());
      }
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (loading) {
    return null;
  }

  return (
    <TextField
      style={style}
      className={clsx(classes.root, className)}
      margin={margin ? margin : 'normal'}
      fullWidth={fullWidth}
      label="Cell"
      name="cell"
      onChange={e => onChange(cells.filter(c => c.id === e.target.value)[0])}
      select
      SelectProps={{ native: true }}
      value={cell ? cell.id : 'all'}
      variant="outlined">
      <optgroup>
        <option value="all">All</option>
      </optgroup>
      <optgroup>
        {activeCells &&
          activeCells.map &&
          activeCells.map(cell => (
            <option key={cell.id} value={cell.id}>
              {cell.name}
            </option>
          ))}
      </optgroup>
    </TextField>
  );
};

SelectCell.propTypes = {};

export default SelectCell;
