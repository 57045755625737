/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { DEFAULT_SUB_MODEL_AREA_DESCRIPTIONS } from 'common/constants';

import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 100
  }
}));

const SelectSubModel = props => {
  const {
    subModel,
    subModels,
    onChange,
    fullWidth,
    className,
    disabled,
    margin
  } = props;

  const classes = useStyles();

  return (
    <TextField
      disabled={disabled}
      className={clsx(classes.root, className)}
      margin={margin ? margin : 'dense'}
      fullWidth={fullWidth}
      label="Score"
      name="subModel"
      onChange={e =>
        onChange(e.target.value >= 0 ? Number(e.target.value) : undefined)
      }
      select
      SelectProps={{ native: true }}
      value={subModel !== null && subModel !== undefined ? subModel : -1}
      variant="outlined">
      <option value={-1}>Combined</option>
      {subModels &&
        subModels.length > 1 &&
        subModels.map((subModel, i) => (
          <option key={i} value={i}>
            {DEFAULT_SUB_MODEL_AREA_DESCRIPTIONS[subModel]
              ? DEFAULT_SUB_MODEL_AREA_DESCRIPTIONS[subModel]
              : `Score ${subModel + 1}`}
          </option>
        ))}
    </TextField>
  );
};

SelectSubModel.propTypes = {
  subModels: PropTypes.array
};

export default SelectSubModel;
