import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { NumberFormat } from 'components';
import { GENDERS } from 'common/constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Grid,
  Modal,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  colors,
  Paper
} from '@material-ui/core';
import ReactImageMagnify from 'react-image-magnify';
import ws from 'utils/ws';
import { findFirstSessionWalkEntryImage } from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 850,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    // padding: 0
  },
  files: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  createdSessionId: {
    marginTop: theme.spacing(6)
  },
  contentSectionHeader: {
    padding: 15,
    paddingBottom: 8,
    paddingTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  tableContainer: {
    padding: 0,
    '& td': {
      borderWidth: 0
    }
  },
  setupInfo: {
    '& td': {
      width: '25%',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: colors.grey[200]
    }
  },
  cellLabel: {
    backgroundColor: colors.grey[50]
  },
  modelName: {
    display: 'block',
    maxWidth: 450,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'help'
  },
  commentsContainer: {
    padding: theme.spacing(3)
  }
}));

const ParticipantDetails = props => {
  const {
    open,
    onClose,
    participant,
    firstWalkEntryImage,
    sessionId,
    loading,
    className,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    let mounted = true;

    if (mounted && sessionId) {
      dispatch(findFirstSessionWalkEntryImage(sessionId));
    }

    return () => {
      mounted = false;
    };
  }, [sessionId]);

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Participant Info" />
        <Divider />
        <CardContent className={classes.content}>
          {loading ? (
            <LinearProgress />
          ) : (
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <Paper>
                  <PerfectScrollbar>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>{participant.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Participant Number</TableCell>
                          <TableCell>{participant.participantNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Phone</TableCell>
                          <TableCell>{participant.phoneNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>{participant.emailAddress}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Birth Date</TableCell>
                          <TableCell>
                            {participant.timeOfBirth &&
                              moment(participant.timeOfBirth).format(
                                'MMM Do YYYY'
                              )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Height</TableCell>
                          <TableCell>
                            {participant.formattedHeightFeet}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Weight (Lbs)</TableCell>
                          <TableCell>{participant.weightPounds}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>BMI</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={participant.bmi}
                              fixedDecimalScale={2}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Gender</TableCell>
                          <TableCell>{GENDERS[participant.gender]}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Participant Id</TableCell>
                          <TableCell>{participant.id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Created By</TableCell>
                          <TableCell>
                            {participant.createdBy?.displayName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Created Date</TableCell>
                          <TableCell>
                            {participant.createdTime &&
                              moment(participant.createdTime).format(
                                'MMM Do YYYY, h:mm:ss a'
                              )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </PerfectScrollbar>
                </Paper>
              </Grid>
              <Grid item lg={6}>
                {firstWalkEntryImage && (
                  <Box style={{ width: 400 }}>
                    <ReactImageMagnify
                      {...{
                        enlargedImagePosition: 'over',
                        smallImage: {
                          isFluidWidth: true,
                          src: ws.url(
                            `/aws/s3/object?bucket=${firstWalkEntryImage.bucket}&objectKey=${firstWalkEntryImage.key}`
                          ),
                          height: 500
                        },
                        largeImage: {
                          src: ws.url(
                            `/aws/s3/object?bucket=${firstWalkEntryImage.bucket}&objectKey=${firstWalkEntryImage.key}`
                          ),
                          width: 1400,
                          height: 2500
                        }
                      }}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ParticipantDetails.displayName = 'ParticipantDetails';

ParticipantDetails.propTypes = {
  participant: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

ParticipantDetails.defaultProps = {
  open: false
};

export default ParticipantDetails;
