import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import {
  cellComponentVersionValueUpdate,
  cellComponentVersionValueReset,
  saveCellComponentVersion,
  deleteCellComponentVersion,
  setCreateCellComponentVersionOpen,
  findCellComponent,
  setConfirmDeleteCellComponentVersionOpen,
  findEntityPhotos
} from 'actions';
import { ConfirmDelete } from 'components';
import { ENTITY_TYPES } from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const CreateCellComponentVersion = props => {
  const { cellComponent, className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    saved,
    deleted,
    values,
    validationMessages,
    deleteMessage,
    openConfirmDelete,
    loading,
    open
  } = useSelector(state => state.cellComponentVersionCreate);

  const handleClose = () => {
    dispatch(cellComponentVersionValueReset());
    dispatch(setCreateCellComponentVersionOpen(false));
  };

  const handleFieldChange = event => {
    event && event.persist && event.persist();

    dispatch(
      cellComponentVersionValueUpdate(values => ({
        ...values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      }))
    );
  };

  if (saved || deleted) {
    dispatch(findCellComponent(cellComponent.id));
    dispatch(findEntityPhotos(ENTITY_TYPES.CELL_COMPONENT, cellComponent.id));
    dispatch(cellComponentVersionValueReset());
    dispatch(setCreateCellComponentVersionOpen(false));
  }

  const handleCreateComponentVersion = () => {
    dispatch(saveCellComponentVersion(values, cellComponent));
  };

  const handleDeleteComponentVersion = () => {
    dispatch(deleteCellComponentVersion(cellComponent, values.versionGuid));
  };

  const handleConfirmDeleteComponentVersion = () => {
    dispatch(setConfirmDeleteCellComponentVersionOpen(true));
  };

  const handleConfirmDeleteClose = () => {
    dispatch(setConfirmDeleteCellComponentVersionOpen(false));
  };

  if (!open || loading) {
    return null;
  }

  const create = !values.versionGuid;

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardContent>
            <Typography align="center" gutterBottom variant="h3">
              {create ? 'New' : 'Edit'} Component Version
            </Typography>

            {cellComponent && (
              <Typography align="center" gutterBottom variant="h4">
                {cellComponent.name}
              </Typography>
            )}

            <Grid container className={classes.container} spacing={2}>
              <Grid item xs={12}>
                <div className={classes.formGroup}>
                  <TextField
                    required={true}
                    error={validationMessages.hasOwnProperty('version')}
                    helperText={validationMessages.version}
                    fullWidth
                    label="Version (e.g. 1.2.5 or XonarServer_09-04-2020)"
                    name="version"
                    onChange={event =>
                      handleFieldChange(event, 'version', event.target.value)
                    }
                    value={values.version}
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.formGroup}>
                  <TextField
                    required={true}
                    error={validationMessages.hasOwnProperty('description')}
                    helperText={validationMessages.description}
                    fullWidth
                    label="Description or version log"
                    name="description"
                    onChange={event =>
                      handleFieldChange(
                        event,
                        'description',
                        event.target.value
                      )
                    }
                    value={values.description}
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.formGroup}>
                  <TextField
                    error={validationMessages.hasOwnProperty('jiraLink')}
                    helperText={validationMessages.jiraLink}
                    fullWidth
                    label="Jira Link"
                    name="jiraLink"
                    onChange={event =>
                      handleFieldChange(event, 'jiraLink', event.target.value)
                    }
                    value={values.jiraLink}
                    variant="outlined"
                  />
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            {!create && (
              <Button
                className={classes.saveButton}
                onClick={handleConfirmDeleteComponentVersion}
                variant="contained">
                Delete
              </Button>
            )}
            <Button
              className={classes.saveButton}
              onClick={handleCreateComponentVersion}
              variant="contained">
              Save
            </Button>
          </CardActions>
        </Card>
      </Modal>

      <ConfirmDelete
        open={openConfirmDelete}
        message={deleteMessage}
        disabled={deleteMessage !== null}
        entityType="version"
        onClose={handleConfirmDeleteClose}
        onConfirm={handleDeleteComponentVersion}
      />
    </>
  );
};

CreateCellComponentVersion.defaultProps = {
  open: false
};

export default CreateCellComponentVersion;
