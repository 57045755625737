import ws from '../utils/ws';

/* Actions */

export const resetEditCellConfiguration = () => dispatch =>
  dispatch({
    type: RESET_EDIT_CELL_CONFIGURATION
  });

export const setEditedCellConfiguration = editedCellConfiguration => dispatch => {
  dispatch({
    type: SET_EDITED_CELL_CONFIGURATION,
    payload: editedCellConfiguration
  });
};

export const saveEditedCellConfiguration = (
  cellId,
  editedCellConfiguration
) => dispatch =>
  dispatch({
    type: SAVE_EDITED_CELL_CONFIGURATION,
    payload: ws.postText(
      `/cells/${cellId}/configuration/`,
      editedCellConfiguration
    )
  });

export const editCellConfiguration = cellId => dispatch => {
  dispatch({
    type: EDIT_CELL_CONFIGURATION,
    payload: ws.get(`/cells/${cellId}/configuration/latest`)
  });
};

export const verifyEditedConfigurationFile = cellConfigurationId => dispatch => {
  dispatch({
    type: VERIFY_EDITED_CELL_CONFIGURATION,
    payload: ws.post(`/cell_configurations/${cellConfigurationId}/verify`)
  });
};

/* Constants */

export const RESET_EDIT_CELL_CONFIGURATION = 'RESET_EDIT_CELL_CONFIGURATION';

export const SET_EDITED_CELL_CONFIGURATION = 'SET_EDITED_CELL_CONFIGURATION';

export const EDIT_CELL_CONFIGURATION = 'EDIT_CELL_CONFIGURATION';
export const EDIT_CELL_CONFIGURATION_PENDING =
  'EDIT_CELL_CONFIGURATION_PENDING';
export const EDIT_CELL_CONFIGURATION_FULFILLED =
  'EDIT_CELL_CONFIGURATION_FULFILLED';

export const SAVE_EDITED_CELL_CONFIGURATION = 'SAVE_EDITED_CELL_CONFIGURATION';
export const SAVE_EDITED_CELL_CONFIGURATION_PENDING =
  'SAVE_EDITED_CELL_CONFIGURATION_PENDING';
export const SAVE_EDITED_CELL_CONFIGURATION_FULFILLED =
  'SAVE_EDITED_CELL_CONFIGURATION_FULFILLED';

export const VERIFY_EDITED_CELL_CONFIGURATION =
  'VERIFY_EDITED_CELL_CONFIGURATION';
export const VERIFY_EDITED_CELL_CONFIGURATION_PENDING =
  'VERIFY_EDITED_CELL_CONFIGURATION_PENDING';
export const VERIFY_EDITED_CELL_CONFIGURATION_FULFILLED =
  'VERIFY_EDITED_CELL_CONFIGURATION_FULFILLED';
