import * as actionTypes from 'actions';

const initialState = {
  pageNumber: 1,
  rowCount: 0,
  requestsLoading: false,
  items: [],
  pageSize: 250,
  filterItemCount: null,
  filter: {
    search: ''
  },
  overview: {},
  overviewLoading: false
};

const PAGE = 'LOCAL_STORAGE_DOWNLOAD_REQUEST_LIST';

const walkLocalStorageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCAL_STORAGE_DOWNLOAD_REQUEST_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        requestsLoading: true
      };
    }

    case actionTypes.LOCAL_STORAGE_DOWNLOAD_REQUEST_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        filter: {
          ...initialState.filter,
          ...action.payload.data.result.filter
        },
        requestsLoading: false
      };
    }

    case actionTypes.FIND_LOCAL_STORAGE_OVERVIEW_PENDING: {
      return {
        ...state,
        overviewLoading: true
      };
    }

    case actionTypes.FIND_LOCAL_STORAGE_OVERVIEW_FULFILLED: {
      return {
        ...state,
        overview: action.payload.data.result,
        overviewLoading: false
      };
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          pageSize: action.payload.pageSize
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    default: {
      return state;
    }
  }
};

export default walkLocalStorageReducer;
