import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Button } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles(theme => ({
  root: {},
  activeDisabled: {
    '& input': {
      maxWidth: 190,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'inherit'
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'inherit'
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#e53935'
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,0.15)'
    },
    '& .MuiOutlinedInput-root.Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e53935'
    }
  },
  textFieldButtonContainer: {
    minWidth: 300,
    position: 'relative'
  },
  textFieldButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 9
  }
}));

const ModelSelector = props => {
  const { modelName, onShowSelectModel } = props;

  const classes = useStyles();

  return (
    <div className={classes.textFieldButtonContainer}>
      <TextField
        className={classes.activeDisabled}
        fullWidth
        disabled
        label="Model/Model Set"
        value={modelName}
        variant="outlined"
      />
      <Button
        variant="outlined"
        className={classes.textFieldButton}
        onClick={() => onShowSelectModel()}>
        <MoreHorizIcon />
      </Button>
    </div>
  );
};

export default ModelSelector;
