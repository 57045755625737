import * as actionTypes from 'actions';

const initialState = {
  testingAnalysisLoading: false,
  subFilterAnalysisSaving: false,
  timeSerieSettingsApplying: false,
  timeSeriesSettings: {
    timeSeriesResultMeasure: 'ACCURACY',
    timeSeriesFrequency: 'DAILY',
    timeSeriesResultTypes: ['OBJECT_PRESENT']
  },
  testingAnalysis: {
    subFilterOverviews: [],
    subFilterTimeSeriesOverviews: []
  },
  setupPaginatedList: {
    pageNumber: 1,
    rowCount: 0,
    loading: false,
    items: [],
    pageSize: 250
  },
  creationSubFilter: null,
  defaultSubFilter: {
    torsoThreshold: 50,
    filterItemCount: 0,
    analysisOverviewLoaded: true,
    analysisOverviewLoading: false,
    sessionCreatedTimeFrom: null,
    sessionCreatedTimeTo: null,
    participantName: '',
    modelName: '',
    weightFrom: '',
    weightTo: '',
    heightFrom: '',
    heightTo: '',
    clothingType: '',
    cell: null,
    entryFootLeftSide: false,
    entryFootRightSide: false,
    objectPresent: false,
    objectNotPresent: false,
    excludedSubjectAreas: [],
    sessionGroup: null,
    subjectAreaContents: []
  },
  defaultFilterSubjectAreaContent: {
    subjectArea: 'RIGHT_SIDE',
    pocketLocation: '',
    objectType: null,
    objectModel: null
  },
  filterItemCount: 0,
  applyFilterRequired: false,
  objectTypes: [],
  objectModels: [],
  cellsLoading: false,
  cells: [],
  sessionsLoading: false,
  sessions: [],
  participantsLoading: false,
  participants: [],
  masterVersionsLoading: false,
  masterVersions: [],
  scenariosLoading: false,
  scenarios: [],
  eventDataSetsLoading: false,
  eventDataSets: [],
  timeSeriesSessionsLoading: false,
  timeSeriesSessions: {
    sessions: [],
    customerEvents: []
  },
  biasType: 'BREAKEVEN'
};

const PAGE = 'TESTING_ANALYSIS';

const testingAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_TESTING_ANALYSIS_TIME_SERIES_SETTINGS_PENDING: {
      return {
        ...state,
        timeSerieSettingsApplying: true
      };
    }

    case actionTypes.SAVE_TESTING_ANALYSIS_TIME_SERIES_SETTINGS_FULFILLED: {
      const testingAnalysis =
        action.payload.data.result === null
          ? initialState.testingAnalysis
          : action.payload.data.result;

      return {
        ...state,
        testingAnalysis: testingAnalysis,
        timeSeriesSettings: {
          timeSeriesResultMeasure: testingAnalysis.timeSeriesResultMeasure,
          timeSeriesFrequency: testingAnalysis.timeSeriesFrequency,
          timeSeriesResultTypes: testingAnalysis.timeSeriesResultTypes
        },
        timeSerieSettingsApplying: false
      };
    }

    case actionTypes.SET_TESTING_ANALYSIS_TIME_SERIES_SETTING: {
      return {
        ...state,
        timeSeriesSettings: {
          ...state.timeSeriesSettings,
          ...action.payload
        }
      };
    }

    case actionTypes.FIND_TESTING_ANALYSIS_PENDING: {
      return {
        ...state,
        testingAnalysisLoading: true
      };
    }

    case actionTypes.FIND_TESTING_ANALYSIS_FULFILLED: {
      const testingAnalysis =
        action.payload.data.result === null
          ? initialState.testingAnalysis
          : action.payload.data.result;

      return {
        ...state,
        testingAnalysis: testingAnalysis,
        timeSeriesSettings: {
          timeSeriesResultMeasure: testingAnalysis.timeSeriesResultMeasure,
          timeSeriesFrequency: testingAnalysis.timeSeriesFrequency,
          timeSeriesResultTypes: testingAnalysis.timeSeriesResultTypes
        },
        testingAnalysisLoading: false
      };
    }

    case actionTypes.FIND_TESTING_ANALYSIS_OVERVIEW: {
      const subFilterIndex =
        action.payload.subFilterIndex !== undefined
          ? action.payload.subFilterIndex
          : 0;

      const subFilters = state.filter.subFilters.slice();

      subFilters[subFilterIndex] = {
        ...subFilters[subFilterIndex],
        ...action.payload.value,
        analysisOverviewLoaded: false,
        analysisOverviewLoading: true
      };

      return {
        ...state,
        filter: {
          ...state.filter,
          subFilters: subFilters
        }
      };
    }

    case actionTypes.FIND_TESTING_ANALYSIS_OVERVIEW_FULFILLED: {
      const subFilterIndex =
        action.payload.data.result.subFilter &&
        action.payload.data.result.subFilter.index !== null
          ? action.payload.data.result.subFilter.index
          : 0;

      const subFilters = state.filter.subFilters.slice();

      subFilters[subFilterIndex] = {
        ...subFilters[subFilterIndex],
        ...action.payload.value,
        analysisOverviewLoaded: true,
        analysisOverviewLoading: false
      };

      return {
        ...state,
        filter: {
          ...state.filter,
          subFilters: subFilters
        },
        overviews: {
          ...state.overviews,
          [subFilterIndex]: action.payload.data.result
        }
      };
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === PAGE
      ) {
        result = {
          ...state,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.filter,
            ...action.payload.data.result.filter,
            loaded: true
          }
        };
      } else {
        result = {
          ...state,
          filter: {
            ...state.filter,
            loaded: true
          }
        };
      }

      return result;
    }

    case actionTypes.FIND_TESTING_ANALYSIS_TIME_SERIES_SESSIONS_PENDING: {
      return {
        ...state,
        timeSeriesSessions: initialState.timeSeriesSessions,
        timeSeriesSessionsLoading: true
      };
    }

    case actionTypes.FIND_TESTING_ANALYSIS_TIME_SERIES_SESSIONS_FULFILLED: {
      return {
        ...state,
        timeSeriesSessions: action.payload.data.result,
        timeSeriesSessionsLoading: false
      };
    }

    case actionTypes.TESTING_ANALYSIS_SETUP_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        setupPaginatedList: {
          ...state.setupPaginatedList,
          loading: true
        }
      };
    }

    case actionTypes.TESTING_ANALYSIS_SETUP_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        setupPaginatedList: {
          ...state.setupPaginatedList,
          ...action.payload.data.result,
          loading: false
        }
      };
    }

    case actionTypes.SET_TESTING_ANALYSIS_BIAS_TYPE: {
      return {
        ...state,
        biasType: action.payload
      };
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          creationSubFilter: {
            ...state.creationSubFilter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          creationSubFilter: {
            ...state.defaultSubFilter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.ADD_TESTING_ANALYSIS_FILTER: {
      return {
        ...state,
        creationSubFilter: initialState.defaultSubFilter
      };
    }

    case actionTypes.REMOVE_TESTING_ANALYSIS_FILTER_PENDING: {
      return {
        ...state,
        testingAnalysisLoading: true
      };
    }

    case actionTypes.REMOVE_TESTING_ANALYSIS_FILTER_FULFILLED: {
      return {
        ...state,
        testingAnalysis:
          action.payload.data.result === null
            ? initialState.testingAnalysis
            : action.payload.data.result,
        testingAnalysisLoading: false
      };
    }

    case actionTypes.SET_TESTING_ANALYSIS_FILTER_POSITION_PENDING: {
      return {
        ...state
        // testingAnalysisLoading: true
      };
    }

    case actionTypes.SET_TESTING_ANALYSIS_FILTER_POSITION_FULFILLED: {
      return {
        ...state,
        testingAnalysis:
          action.payload.data.result === null
            ? initialState.testingAnalysis
            : action.payload.data.result,
        testingAnalysisLoading: false
      };
    }

    case actionTypes.SAVE_TESTING_ANALYSIS_SUB_FILTER_OVERVIEW_PENDING: {
      return {
        ...state,
        subFilterAnalysisSaving: true
      };
    }

    case actionTypes.SAVE_TESTING_ANALYSIS_SUB_FILTER_OVERVIEW_FULFILLED: {
      return {
        ...state,
        testingAnalysis:
          action.payload.data.result === null
            ? initialState.testingAnalysis
            : action.payload.data.result,
        subFilterAnalysisSaving: false
      };
    }

    case actionTypes.TRANSPOSE_TESTING_ANALYSIS_SUB_FILTERS_PENDING: {
      return {
        ...state,
        subFilterAnalysisSaving: true
      };
    }

    case actionTypes.TRANSPOSE_TESTING_ANALYSIS_SUB_FILTERS_FULFILLED: {
      return {
        ...state,
        testingAnalysis:
          action.payload.data.result === null
            ? initialState.testingAnalysis
            : action.payload.data.result,
        subFilterAnalysisSaving: false
      };
    }

    case actionTypes.APPLY_FILTER_FULFILLED: {
      return {
        ...state,
        applyFilterRequired: false
      };
    }

    case actionTypes.EDIT_TESTING_ANALYSIS_FILTER: {
      return {
        ...state,
        creationSubFilter: {
          ...initialState.defaultSubFilter,
          ...state.testingAnalysis.subFilterOverviews[
            action.payload.subFilterIndex
          ].subFilter
        }
      };
    }

    case actionTypes.ADD_SESSION_FILTER_SUBJECT_AREA_CONTENT: {
      if (action.payload.page === PAGE) {
        const subjectAreaContents = state.creationSubFilter.subjectAreaContents
          ? state.creationSubFilter.subjectAreaContents.slice()
          : [];
        subjectAreaContents.splice(subjectAreaContents.length, 0, {
          ...initialState.defaultFilterSubjectAreaContent
        });

        return {
          ...state,
          creationSubFilter: {
            ...state.creationSubFilter,
            subjectAreaContents
          }
        };
      } else {
        return state;
      }
    }

    case actionTypes.SESSION_FILTER_SUBJECT_AREA_CONTENT_UPDATE: {
      if (action.payload.page === PAGE) {
        const subjectAreaContents = state.creationSubFilter.subjectAreaContents.slice();

        let subjectAreaContent =
          subjectAreaContents[action.payload.values.subjectAreaContentIndex];

        subjectAreaContents[action.payload.values.subjectAreaContentIndex] = {
          ...subjectAreaContent,
          ...action.payload.values
        };

        return {
          ...state,
          creationSubFilter: {
            ...state.creationSubFilter,
            subjectAreaContents
          }
        };
      } else {
        return state;
      }
    }

    case actionTypes.REMOVE_SESSION_FILTER_SUBJECT_AREA_CONTENT: {
      if (action.payload.page === PAGE) {
        const { subjectAreaContents } = state.creationSubFilter;

        subjectAreaContents.splice(action.payload.index, 1);

        return {
          ...state,
          creationSubFilter: {
            ...state.creationSubFilter,
            subjectAreaContents
          }
        };
      } else {
        return state;
      }
    }

    case actionTypes.FIND_SESSION_LIST_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data.result
      };
    }

    case actionTypes.FIND_SESSION_LIST_OBJECT_MODELS_FULFILLED: {
      return {
        ...state,
        objectModels: action.payload.data.result
      };
    }

    case actionTypes.FIND_CELLS_PENDING: {
      return {
        ...state,
        cells: initialState.cells,
        cellsLoading: true
      };
    }

    case actionTypes.FIND_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result,
        cellsLoading: false
      };
    }

    case actionTypes.FIND_SELECTION_TEST_SESSIONS_PENDING: {
      return {
        ...state,
        sessions: initialState.sessions,
        sessionsLoading: true
      };
    }

    case actionTypes.FIND_SELECTION_TEST_SESSIONS_FULFILLED: {
      return {
        ...state,
        sessions: action.payload.data.result,
        sessionsLoading: false
      };
    }

    case actionTypes.FIND_SESSION_PARTICIPANTS_PENDING: {
      return {
        ...state,
        participants: initialState.participants,
        participantsLoading: true
      };
    }

    case actionTypes.FIND_SESSION_PARTICIPANTS_FULFILLED: {
      return {
        ...state,
        participants: action.payload.data.result,
        participantsLoading: false
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_PENDING: {
      return {
        ...state,
        masterVersions: initialState.masterVersions,
        masterVersionsLoading: true
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_FULFILLED: {
      return {
        ...state,
        masterVersions: action.payload.data.result,
        masterVersionsLoading: false
      };
    }

    case actionTypes.FIND_SETUP_SCENARIOS_PENDING: {
      return {
        ...state,
        scenarios: initialState.scenarios,
        scenariosLoading: true
      };
    }

    case actionTypes.FIND_SETUP_SCENARIOS_FULFILLED: {
      return {
        ...state,
        scenarios: action.payload.data.result,
        scenariosLoading: false
      };
    }

    case actionTypes.FIND_EVENT_DATA_SETS_PENDING: {
      return {
        ...state,
        eventDataSets: initialState.scenarios,
        eventDataSetsLoading: true
      };
    }

    case actionTypes.FIND_EVENT_DATA_SETS_FULFILLED: {
      return {
        ...state,
        eventDataSets: action.payload.data.result,
        eventDataSetsLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default testingAnalysisReducer;
