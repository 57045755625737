import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import useColorChange from 'use-color-change';
import {
  Card,
  Grid,
  CardContent,
  Typography,
  Divider,
  CardHeader,
  colors
} from '@material-ui/core';
import { NumberFormat, Label } from 'components';
import { CHART_COLORS } from 'common/constants';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  settingsContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  filterListItem: {
    borderLeftWidth: 4,
    borderLeftStyle: 'solid'
  },
  subFilterHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  divider: {
    height: 4
  },
  chartContainer: {
    // border: '2px solid red'
  },
  cellLocation: {
    marginTop: 12
  },
  thresholdBreached: {
    color: colors.red[600]
  }
}));

const CellMonitor = props => {
  const { monitor, index } = props;

  const classes = useStyles();

  const colorStyle = useColorChange(monitor.eventCount, {
    higher: colors.blue[600],
    duration: 1800
  });

  const chartSeries = [];

  const series = {
    name: 'Accuracy',
    data: [],
    color: CHART_COLORS[0]
  };

  const options = {
    chart: {
      height: 130,
      type: 'area'
    },
    tooltip: {
      crosshairs: {
        color: colors.grey[400],
        dashStyle: 'solid'
      },
      valueDecimals: 2,
      formatter: function() {
        return '<b>Accuracy</b>: ' + Highcharts.numberFormat(this.y, 2) + ' %';
      }
    },
    title: {
      text: null
    },
    series: [],
    xAxis: {
      visible: false
    },
    yAxis: {
      max: 100,
      title: {
        text: 'Accuracy'
      },
      labels: {
        format: '{value}%'
      }
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      area: {
        fillOpacity: 0.5
      },
      series: {
        connectNulls: true,
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 2
        }
      }
    }
  };

  if (monitor.accuracies) {
    for (let i = 0; i < monitor.accuracies.length; i++) {
      const accuracy = monitor.accuracies[i];
      series.data.push({
        x: i,
        y: accuracy != null ? accuracy * 100 : null
      });
    }
  }

  chartSeries.push(series);

  options.series = chartSeries;

  return (
    <Card>
      <CardHeader
        title={monitor.cellName}
        subheader={
          monitor.latestEventTime
            ? 'Latest Walk ' +
              moment(monitor.latestEventTime).format('MMM Do YYYY, h:mm:ss a') +
              ' (' +
              moment(monitor.latestEventTime).fromNow() +
              ')'
            : 'No Walks Available'
        }
        action={
          monitor.cellLocation && (
            <Label color={CHART_COLORS[index]} className={classes.cellLocation}>
              {monitor.cellLocation}
            </Label>
          )
        }
      />
      <Divider
        style={{ backgroundColor: CHART_COLORS[index] }}
        className={classes.divider}
      />
      <CardContent>
        <Grid spacing={2} container>
          <Grid item xs={12} md={6}>
            <Grid spacing={2} container>
              <Grid item xs={3} md={6}>
                <Typography
                  className={classes.categoryLabel}
                  component="h2"
                  variant="overline">
                  Walks
                </Typography>
                <Typography variant="h1" color="textPrimary" style={colorStyle}>
                  <NumberFormat value={monitor.eventCount} precision={0} />
                </Typography>
              </Grid>
              <Grid item xs={3} md={6}>
                <Typography
                  className={classes.categoryLabel}
                  component="h2"
                  variant="overline">
                  Accuracy
                </Typography>
                <Typography
                  variant="h1"
                  color="textPrimary"
                  className={
                    monitor.accuracyLow ? classes.thresholdBreached : ''
                  }>
                  {monitor.accuracy !== null ? (
                    <NumberFormat
                      value={monitor.accuracy}
                      percentage
                      precision={0}
                      suffix="%"
                    />
                  ) : (
                    'N/A'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3} md={6}>
                <Typography
                  className={classes.categoryLabel}
                  component="h2"
                  variant="overline">
                  Proc. Time
                </Typography>
                <Typography
                  variant="h1"
                  color="textPrimary"
                  className={
                    monitor.averageProcessingTimeHigh
                      ? classes.thresholdBreached
                      : ''
                  }>
                  {monitor.averageProcessingTime !== null ? (
                    <NumberFormat
                      value={monitor.averageProcessingTime}
                      precision={1}
                      suffix="s"
                    />
                  ) : (
                    'N/A'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3} md={6}>
                <Typography
                  className={classes.categoryLabel}
                  component="h2"
                  variant="overline">
                  CPU Temp
                </Typography>
                <Typography
                  variant="h1"
                  color="textPrimary"
                  className={
                    monitor.averageCpuTemperatureHigh
                      ? classes.thresholdBreached
                      : ''
                  }>
                  {monitor.averageCpuTemperature !== null ? (
                    <NumberFormat
                      value={monitor.averageCpuTemperature}
                      precision={0}
                      suffix="C"
                    />
                  ) : (
                    'N/A'
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.chartContainer}>
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CellMonitor.propTypes = {
  className: PropTypes.string,
  monitor: PropTypes.object.isRequired
};

export default CellMonitor;
