import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { IconButton, Paper, Input, Tooltip } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addComment } from 'actions';
import { Comment } from 'components';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  container: {
    maxHeight: 500
  },
  actions: {
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing(0.5, 2)
  },
  input: {
    width: '100%'
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: 'none'
  }
}));

const CommentsCard = props => {
  const {
    entityType,
    editableRight,
    entityId,
    comments,
    className,
    loading,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { sessionUser } = useSelector(state => state.session);
  const [newComment, setNewComment] = useState('');

  const editable =
    !editableRight || sessionUser.rights.indexOf(editableRight) > -1;

  comments.sort((comment1, comment2) => {
    return comment2.createdTime - comment1.createdTime;
  });

  const handleAddComment = () => {
    if (newComment.trim() !== '') {
      dispatch(addComment(entityType, entityId, { comment: newComment }));
      setNewComment('');
    }
  };

  const handleCommentChange = e => {
    e.persist && e.persist();
    setNewComment(e.target.value);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleAddComment();
    }
  };

  return (
    <div>
      <PerfectScrollbar className={classes.container}>
        {comments.map(comment => (
          <Comment
            entityType={entityType}
            entityId={entityId}
            key={comment.identifier}
            comment={comment}
            editable={editable}
          />
        ))}
      </PerfectScrollbar>

      {editable && (
        <div className={classes.actions}>
          <Paper className={classes.paper} elevation={1}>
            <Input
              className={classes.input}
              disableUnderline
              onChange={handleCommentChange}
              value={newComment}
              onKeyDown={handleKeyDown}
              placeholder="Add a comment"
            />
          </Paper>
          <Tooltip title="Add">
            <IconButton
              onClick={handleAddComment}
              color={newComment.length > 0 ? 'primary' : 'default'}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default CommentsCard;
