/* Actions */

export const setEntityPhotoUploadValues = values => dispatch =>
  dispatch({
    type: SET_ENTITY_PHOTO_UPLOAD_VALUES,
    payload: values
  });

export const setEntityPhotoUploadOpen = open => dispatch =>
  dispatch({
    type: SET_ENTITY_PHOTO_UPLOADER_OPEN,
    payload: open
  });

export const clearEntityPhotoUploadValues = () => dispatch =>
  dispatch({
    type: CLEAR_ENTITY_PHOTO_UPLOAD_VALUES
  });

export const setEntityPhotoUploadEntityDetails = values => dispatch =>
  dispatch({
    type: SET_ENTITY_PHOTO_UPLOAD_ENTITY_DETAILS,
    payload: values
  });

/* Constants */

export const SET_CAMERA_CAPTURE_FACING_MODE = 'SET_CAMERA_CAPTURE_FACING_MODE';
export const SET_CAMERA_CAPTURE_MIRRORED = 'SET_CAMERA_CAPTURE_MIRRORED';
export const SET_CAMERA_CAPTURE_CAMERA_ERROR =
  'SET_CAMERA_CAPTURE_CAMERA_ERROR';
export const SET_CAMERA_CAPTURE_DATA_URI = 'SET_CAMERA_CAPTURE_DATA_URI';

export const SET_ENTITY_PHOTO_UPLOAD_VALUES = 'SET_ENTITY_PHOTO_UPLOAD_VALUES';

export const CLEAR_ENTITY_PHOTO_UPLOAD_VALUES =
  'CLEAR_ENTITY_PHOTO_UPLOAD_VALUES';

export const SET_ENTITY_PHOTO_UPLOAD_ENTITY_DETAILS =
  'SET_ENTITY_PHOTO_UPLOAD_ENTITY_DETAILS';

export const SET_ENTITY_PHOTO_UPLOADER_OPEN = 'SET_ENTITY_PHOTO_UPLOADER_OPEN';
