/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_DATA_SET_STATUSES } from 'common/constants';
import { Label } from 'components';
import { CircularProgress, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  progress: {
    marginLeft: theme.spacing(1),
    marginBottom: -4
  }
}));

const EventDataSetStatus = props => {
  const { status, fileCount, processedFileCount, archiving, ...rest } = props;

  const classes = useStyles();

  const statusColors = {
    READY: colors.green[600],
    PROCESSING: colors.purple[600],
    FAILED: colors.red[600]
  };

  return (
    <div>
      {status && (
        <>
          <Label {...rest} color={statusColors[status]}>
            {EVENT_DATA_SET_STATUSES[status] +
              (!archiving && fileCount && processedFileCount
                ? ' (' + processedFileCount + ' of ' + fileCount + ')'
                : '') +
              (archiving ? ' (Archiving)' : '')}
          </Label>
          {status === 'PROCESSING' && (
            <CircularProgress className={classes.progress} size={14} />
          )}
        </>
      )}
    </div>
  );
};

EventDataSetStatus.propTypes = {
  eventDataSet: PropTypes.object
};

export default EventDataSetStatus;
