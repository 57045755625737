import ws from '../utils/ws';

/* Actions */

export const testResultFilePaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: TEST_RESULT_FILE_PAGINATED_LIST,
    payload: ws.get(`/test_result_files/page/${pageNumber}/rows/${pageSize}`)
  });

export const testResultFilelValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_TEST_RESULT_FILE_VALUE_UPDATE,
    payload: values
  });

export const testResultFileValueReset = () => dispatch =>
  dispatch({
    type: CREATE_TEST_RESULT_FILE_VALUE_RESET
  });

/* Constants */

export const TEST_RESULT_FILE_PAGINATED_LIST =
  'TEST_RESULT_FILE_PAGINATED_LIST';
export const TEST_RESULT_FILE_PAGINATED_LIST_PENDING =
  'TEST_RESULT_FILE_PAGINATED_LIST_PENDING';
export const TEST_RESULT_FILE_PAGINATED_LIST_FULFILLED =
  'TEST_RESULT_FILE_PAGINATED_LIST_FULFILLED';

export const CREATE_TEST_RESULT_FILE_VALUE_UPDATE =
  'CREATE_TEST_RESULT_FILE_VALUE_UPDATE';
export const CREATE_TEST_RESULT_FILE_VALUE_RESET =
  'CREATE_TEST_RESULT_FILE_VALUE_RESET';
