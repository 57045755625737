import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  saved: false,
  savedParticipant: {},
  deleted: false,
  deletedParticipant: {},
  participant: {
    creationId: null,
    name: '',
    phoneNumber: '',
    emailAddress: '',
    timeOfBirth: null,
    heightFeet: '',
    heightInches: '',
    weightPounds: '',
    gender: null,
    identifiersCreated: false,
    defaultSubjectAreaContents: [],
    metalDetectorItems: ''
  },
  entityPhotos: [],
  validationMessages: {},
  objectTypes: []
};

const participantCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PARTICIPANT_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedParticipant: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_PARTICIPANT_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deletedParticipant: action.payload.data.result
      };
    }

    case actionTypes.EDIT_PARTICIPANT_FULFILLED: {
      return {
        ...state,
        participant: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_PARTICIPANT_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_PARTICIPANT_VALUE_UPDATE: {
      return {
        ...state,
        participant: {
          ...state.participant,
          ...action.payload
        }
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.PARTICIPANT &&
        (entityPhoto.entityId === state.participant.id ||
          entityPhoto.entityTempId === state.participant.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.PARTICIPANT &&
        (entityPhoto.entityId === state.participant.id ||
          entityPhoto.entityTempId === state.participant.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.PARTICIPANT &&
        (entityPhoto.entityId === state.participant.id ||
          entityPhoto.entityTempId === state.participant.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        participantLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.FIND_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data.result
      };
    }

    case actionTypes.FIND_OBJECT_MODELS_FULFILLED: {
      return {
        ...state,
        objectModels: action.payload.data.result
      };
    }

    case actionTypes.TOGGLE_CREATE_PARTICIPANT_SAC: {
      const subjectAreaContents = state.participant.defaultSubjectAreaContents
        ? state.participant.defaultSubjectAreaContents.slice()
        : [];
      const subjectAreaContent = action.payload;
      const subjectAreaContentIndex = subjectAreaContents
        .map(sac => sac.subjectArea + '_' + sac.objectType.id)
        .indexOf(
          subjectAreaContent.subjectArea +
            '_' +
            subjectAreaContent.objectType.id
        );

      if (subjectAreaContentIndex > -1) {
        subjectAreaContents.splice(subjectAreaContentIndex, 1);
      } else {
        subjectAreaContents.push(subjectAreaContent);
      }

      return {
        ...state,
        participant: {
          ...state.participant,
          defaultSubjectAreaContents: subjectAreaContents
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default participantCreateReducer;
