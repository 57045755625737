import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  NumberFormat,
  Comments,
  S3File,
  EventSessionStatus,
  EventVideo,
  MagnetometerResult,
  EventPredictionResult,
  EventProcessingTime,
  EventSettings
} from 'components';
import {
  S3_FILE_TYPES,
  ENTRY_FEET,
  POCKET_LOCATIONS,
  SUBJECT_AREAS,
  ENTITY_TYPES,
  SCENARIO_TYPES
} from 'common/constants';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  colors,
  Typography,
  Collapse,
  Tooltip
} from '@material-ui/core';
import {
  createSessionValueReset,
  excludeTestSessionEvent,
  includeTestSessionEvent
} from 'actions';
import { TRAINING_SESSIONS_MODIFY, TEST_SESSIONS_MODIFY } from 'common/Right';
import ws from 'utils/ws';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  files: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  createdSessionId: {
    marginTop: theme.spacing(6)
  },
  contentSectionHeader: {
    padding: 15,
    paddingBottom: 8,
    paddingTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  tableContainer: {
    padding: 0,
    '& td': {
      borderWidth: 0
    }
  },
  setupInfo: {
    '& td': {
      width: '25%',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: colors.grey[200]
    }
  },
  cellLabel: {
    backgroundColor: colors.grey[50]
  },
  modelName: {
    display: 'block',
    maxWidth: 450,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'help'
  },
  commentsContainer: {
    padding: theme.spacing(3)
  },
  cardHeaderButton: {
    marginBottom: -3,
    marginTop: 5,
    marginLeft: theme.spacing(1)
  }
}));

const SessionEventDetails = props => {
  const {
    open,
    onClose,
    event,
    sessionEvent,
    session,
    s3Files,
    s3FilesLoading,
    testSessionData,
    loading,
    className,
    editableRight,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [showFiles, setShowFiles] = useState(false);
  const [showSetup, setShowSetup] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showProcessingTime, setShowProcessingTime] = useState(false);
  const [showEventSettings, setShowEventSettings] = useState(false);

  const { sessionUser } = useSelector(state => state.session);

  const scenarioType = SCENARIO_TYPES.filter(
    t => t.code === session.scenarioType
  )[0];

  const editable =
    !editableRight || sessionUser.rights.indexOf(editableRight) > -1;

  const handleClose = () => {
    dispatch(createSessionValueReset());
    onClose();
  };

  const handleToggleFiles = () => {
    setShowFiles(showFiles => !showFiles);
  };

  const handleToggleSetup = () => {
    setShowSetup(showSetup => !showSetup);
  };

  const handleToggleComments = () => {
    setShowComments(showComments => !showComments);
  };

  const handleToggleProcessingTime = () => {
    setShowProcessingTime(showProcessingTime => !showProcessingTime);
  };

  const handleToggleEventSettings = () => {
    setShowEventSettings(showEventSettings => !showEventSettings);
  };

  const handleIncludeEvent = () => {
    const eventId =
      session.scenarioType === 'TRAINING'
        ? sessionEvent.event.id
        : sessionEvent.fileName;
    Promise.resolve(
      dispatch(includeTestSessionEvent(event.scenarioSessionId, eventId))
    ).then(() => {
      onClose();
    });
  };

  const handleExcludeEvent = () => {
    const eventId =
      session.scenarioType === 'TRAINING'
        ? sessionEvent.event.id
        : sessionEvent.fileName;
    Promise.resolve(
      dispatch(excludeTestSessionEvent(event.scenarioSessionId, eventId))
    ).then(() => {
      onClose();
    });
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          title="Walk Info"
          action={
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={classes.cardHeaderButton}
              onClick={() => {
                window.location = ws.url(`/events/${event.id}/download_json`);
              }}>
              Download JSON
            </Button>
          }
        />
        <Divider />
        <CardContent className={classes.content}>
          {loading ? (
            <LinearProgress />
          ) : (
            <div>
              <PerfectScrollbar>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Walk Time</TableCell>
                      <TableCell>
                        {event.eventTime &&
                          moment(event.eventTime).format(
                            'MMM Do YYYY, h:mm:ss a'
                          )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>
                        <EventSessionStatus sessionEvent={sessionEvent} />
                      </TableCell>
                    </TableRow>
                    {event.modelName && (
                      <TableRow>
                        <TableCell>Walked Model</TableCell>
                        <TableCell>
                          {event.modelName && (
                            <Tooltip title={event.modelName}>
                              <span className={classes.modelName}>
                                {event.modelName}
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>Torso Threshold</TableCell>
                      <TableCell>
                        <NumberFormat
                          value={event.torsoThreshold}
                          percentage
                          suffix={'%'}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Object Location</TableCell>
                      <TableCell>
                        {event.objectOfInterestLocationDescription}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} className={classes.tableContainer}>
                        <Table>
                          <TableHead>
                            <TableCell
                              className={clsx(
                                classes.columnSeparator,
                                classes.scoreCell
                              )}>
                              Torso Left
                            </TableCell>
                            <TableCell className={classes.scoreCell}>
                              Torso Right
                            </TableCell>
                            <TableCell
                              className={clsx(
                                classes.columnSeparator,
                                classes.scoreCell
                              )}>
                              Ankle Left
                            </TableCell>
                            <TableCell className={classes.scoreCell}>
                              Ankle Right
                            </TableCell>
                            <TableCell>Prediction</TableCell>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                className={clsx(
                                  classes.columnSeparator,
                                  classes.scoreCell
                                )}>
                                <NumberFormat
                                  value={event.torsoScoreLeft}
                                  percentage
                                  suffix={'%'}
                                />
                              </TableCell>
                              <TableCell className={classes.scoreCell}>
                                <NumberFormat
                                  value={event.torsoScoreRight}
                                  percentage
                                  suffix={'%'}
                                />
                              </TableCell>
                              <TableCell
                                className={clsx(
                                  classes.labelCell,
                                  classes.columnSeparator,
                                  classes.scoreCell
                                )}>
                                <MagnetometerResult
                                  score={event.ankleScoreLeft}
                                />
                              </TableCell>
                              <TableCell
                                className={clsx(
                                  classes.labelCell,
                                  classes.scoreCell
                                )}>
                                <MagnetometerResult
                                  score={event.ankleScoreRight}
                                />
                              </TableCell>
                              <TableCell className={classes.labelCell}>
                                <EventPredictionResult
                                  predictionCorrect={event.predictionCorrect}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </PerfectScrollbar>

              <div
                className={classes.contentSectionHeader}
                onClick={handleToggleSetup}>
                <Typography variant="h6">Setup</Typography>
                {showSetup ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>

              <Collapse in={showSetup}>
                <div className={classes.files}>
                  {testSessionData && (
                    <PerfectScrollbar options={{ suppressScrollY: true }}>
                      {testSessionData.setup &&
                      testSessionData.setup.subjectAreaContents ? (
                        <Table className={classes.setupInfo}>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.cellLabel}>
                                Setup Number
                              </TableCell>
                              <TableCell>
                                {testSessionData.setup.index}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellLabel}>
                                Entry Foot
                              </TableCell>
                              <TableCell>
                                {ENTRY_FEET[testSessionData.setup.entryFoot]}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellLabel}>
                                Contents
                              </TableCell>
                              <TableCell>
                                {Object.keys(
                                  testSessionData.setup.subjectAreaContents
                                ).map(subjectArea => (
                                  <span key={subjectArea}>
                                    <span>{subjectArea}</span>
                                    <span>
                                      {testSessionData.setup
                                        .subjectAreaContents[subjectArea].gun &&
                                      testSessionData.setup.subjectAreaContents[
                                        subjectArea
                                      ].gun.hasGun
                                        ? ' Gun: ' +
                                          testSessionData.setup
                                            .subjectAreaContents[subjectArea]
                                            .gun.gunType
                                        : ''}
                                    </span>
                                    <span>
                                      {testSessionData.setup
                                        .subjectAreaContents[subjectArea].other
                                        ? ' Other: ' +
                                          testSessionData.setup
                                            .subjectAreaContents[subjectArea]
                                            .other
                                        : ''}
                                    </span>
                                  </span>
                                ))}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      ) : (
                        <Table className={classes.setupInfo}>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.cellLabel}>
                                Object Location
                              </TableCell>
                              <TableCell>
                                {testSessionData.setup &&
                                testSessionData.setup.objectLocation
                                  ? SUBJECT_AREAS[
                                      testSessionData.setup.objectLocation
                                    ]
                                  : 'None'}
                              </TableCell>
                              <TableCell className={classes.cellLabel}>
                                Wallet
                              </TableCell>
                              <TableCell>
                                {testSessionData.setup &&
                                testSessionData.setup.walletLocation
                                  ? POCKET_LOCATIONS[
                                      testSessionData.setup.walletLocation
                                    ]
                                  : 'None'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellLabel}>
                                Money Clip
                              </TableCell>
                              <TableCell>
                                {testSessionData.setup &&
                                testSessionData.setup.moneyClipLocation
                                  ? POCKET_LOCATIONS[
                                      testSessionData.setup.moneyClipLocation
                                    ]
                                  : 'None'}
                              </TableCell>
                              <TableCell className={classes.cellLabel}>
                                Cellphone
                              </TableCell>
                              <TableCell>
                                {testSessionData.setup &&
                                testSessionData.setup.cellPhoneLocation
                                  ? POCKET_LOCATIONS[
                                      testSessionData.setup.cellPhoneLocation
                                    ]
                                  : 'None'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.cellLabel}>
                                Keys
                              </TableCell>
                              <TableCell>
                                {testSessionData.setup &&
                                testSessionData.setup.keysLocation
                                  ? POCKET_LOCATIONS[
                                      testSessionData.setup.keysLocation
                                    ]
                                  : 'None'}
                              </TableCell>
                              <TableCell className={classes.cellLabel}>
                                Entry Foot
                              </TableCell>
                              <TableCell>
                                {testSessionData.setup &&
                                testSessionData.setup.entryFoot
                                  ? ENTRY_FEET[testSessionData.setup.entryFoot]
                                  : ''}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </PerfectScrollbar>
                  )}
                </div>
              </Collapse>

              {event.videoTimestampPresent && (
                <EventVideo width={700} height={263} eventId={event.id} />
              )}
            </div>
          )}

          {sessionEvent.event && (
            <React.Fragment>
              <div
                className={classes.contentSectionHeader}
                onClick={handleToggleComments}>
                <Typography variant="h6">
                  Comments (
                  {sessionEvent.event.comments
                    ? sessionEvent.event.comments.length
                    : 0}
                  )
                </Typography>
                {showComments ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>

              <Collapse in={showComments}>
                <div className={classes.commentsContainer}>
                  <Comments
                    editableRight={
                      scenarioType.code == 'TRAINING'
                        ? TRAINING_SESSIONS_MODIFY
                        : TEST_SESSIONS_MODIFY
                    }
                    entityType={ENTITY_TYPES.EVENT}
                    entityId={sessionEvent.event.id}
                    comments={
                      sessionEvent.event.comments
                        ? sessionEvent.event.comments
                        : []
                    }
                  />
                </div>
              </Collapse>
            </React.Fragment>
          )}

          {sessionEvent.event && sessionEvent.event.processingTimePoints && (
            <>
              <div
                className={classes.contentSectionHeader}
                onClick={handleToggleProcessingTime}>
                <Typography variant="h6">
                  Processing Time
                  {sessionEvent &&
                    sessionEvent.event.totalProcessingElapsedTime && (
                      <>
                        {' '}
                        (
                        <NumberFormat
                          value={sessionEvent.event.totalProcessingElapsedTime}
                          suffix=" s"
                          precision={2}
                          fixedDecimalScale={2}
                        />
                        )
                      </>
                    )}
                </Typography>
                {showProcessingTime ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>

              <Collapse in={showProcessingTime}>
                <EventProcessingTime event={sessionEvent.event} />
              </Collapse>
            </>
          )}

          {event && event.eventSettingsJson && (
            <>
              <div
                className={classes.contentSectionHeader}
                onClick={handleToggleEventSettings}>
                <Typography variant="h6">Settings</Typography>
                {showEventSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>

              <Collapse in={showEventSettings}>
                <EventSettings
                  settingsHash={event.eventSettingsHash}
                  settingsJson={event.eventSettingsJson}
                />
              </Collapse>
            </>
          )}

          <div
            className={classes.contentSectionHeader}
            onClick={handleToggleFiles}>
            <Typography variant="h6">Files</Typography>
            {showFiles ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>

          <Collapse in={showFiles}>
            <div className={classes.files}>
              <PerfectScrollbar options={{ suppressScrollY: true }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>File</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {s3Files.map(s3File => (
                      <TableRow key={s3File.fileName} hover>
                        <TableCell>{s3File.fileName}</TableCell>
                        <TableCell>{S3_FILE_TYPES[s3File.fileType]}</TableCell>
                        <TableCell>
                          <S3File s3File={s3File} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </div>
          </Collapse>
        </CardContent>
        <CardActions className={classes.actions}>
          {editable &&
            session.scenarioType === 'TESTING' &&
            sessionEvent.status === 'EXCLUDED' && (
              <Button onClick={handleIncludeEvent} variant="contained">
                Include
              </Button>
            )}
          {editable &&
            session.scenarioType === 'TESTING' &&
            sessionEvent.status === 'INCLUDED' && (
              <Button onClick={handleExcludeEvent} variant="contained">
                Exclude
              </Button>
            )}
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SessionEventDetails.displayName = 'EventDetails';

SessionEventDetails.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

SessionEventDetails.defaultProps = {
  open: false
};

export default SessionEventDetails;
