import ws from '../utils/ws';

/* Actions */

export const shortUrlPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: SHORT_URLS_PAGINATED_LIST,
    payload: ws.get(`/short_urls/page/${pageNumber}/rows/${pageSize}`)
  });

export const shortUrlValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_SHORT_URL_VALUE_UPDATE,
    payload: values
  });

export const shortUrlValueReset = () => dispatch =>
  dispatch({
    type: CREATE_SHORT_URL_VALUE_RESET
  });

export const saveShortUrl = shortUrl => dispatch =>
  dispatch({
    type: SAVE_SHORT_URL,
    payload: ws.post('/short_urls/', shortUrl)
  });

export const deleteShortUrl = shortUrl => dispatch =>
  dispatch({
    type: DELETE_SHORT_URL,
    payload: ws.post(`/short_urls/${shortUrl.id}/delete`)
  });

export const editShortUrl = id => dispatch =>
  dispatch({
    type: EDIT_SHORT_URL,
    payload: ws.get(`/short_urls/${id}`)
  });

export const setShortUrlListQrCodeShortUrl = shortUrl => dispatch =>
  dispatch({
    type: SET_SHORT_URL_LIST_QR_CODE_SHORT_URL,
    payload: shortUrl
  });

/* Constants */

export const SHORT_URLS_PAGINATED_LIST = 'SHORT_URLS_PAGINATED_LIST';
export const SHORT_URLS_PAGINATED_LIST_PENDING =
  'SHORT_URLS_PAGINATED_LIST_PENDING';
export const SHORT_URLS_PAGINATED_LIST_FULFILLED =
  'SHORT_URLS_PAGINATED_LIST_FULFILLED';

export const CREATE_SHORT_URL_VALUE_UPDATE = 'CREATE_SHORT_URL_VALUE_UPDATE';
export const CREATE_SHORT_URL_VALUE_RESET = 'CREATE_SHORT_URL_VALUE_RESET';

export const SAVE_SHORT_URL = 'SAVE_SHORT_URL';
export const SAVE_SHORT_URL_FULFILLED = 'SAVE_SHORT_URL_FULFILLED';

export const DELETE_SHORT_URL = 'DELETE_SHORT_URL';
export const DELETE_SHORT_URL_FULFILLED = 'DELETE_SHORT_URL_FULFILLED';

export const EDIT_SHORT_URL = 'EDIT_SHORT_URL';
export const EDIT_SHORT_URL_FULFILLED = 'EDIT_SHORT_URL_FULFILLED';

export const SET_SHORT_URL_LIST_QR_CODE_SHORT_URL =
  'SET_SHORT_URL_LIST_QR_CODE_SHORT_URL';

// export const FIND_SHORT_URLS = 'FIND_SHORT_URLS';
// export const FIND_SHORT_URLS_PENDING = 'FIND_SHORT_URLS_PENDING';
// export const FIND_SHORT_URLS_FULFILLED = 'FIND_SHORT_URLS_FULFILLED';

// export const FIND_SHORT_URL = 'FIND_SHORT_URL';
// export const FIND_SHORT_URL_PENDING = 'FIND_SHORT_URL_PENDING';
// export const FIND_SHORT_URL_FULFILLED = 'FIND_SHORT_URL_FULFILLED';

// export const FIND_SHORT_URL_TRAINING_OBJECT_TYPES = 'FIND_SHORT_URL_TRAINING_OBJECT_TYPES';
// export const FIND_SHORT_URL_TRAINING_OBJECT_TYPES_PENDING = 'FIND_SHORT_URL_TRAINING_OBJECT_TYPES_PENDING';
// export const FIND_SHORT_URL_TRAINING_OBJECT_TYPES_FULFILLED = 'FIND_SHORT_URL_TRAINING_OBJECT_TYPES_FULFILLED';

// export const FIND_SHORT_URL_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS = 'FIND_SHORT_URL_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS';
// export const FIND_SHORT_URL_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_PENDING = 'FIND_SHORT_URL_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_PENDING';
// export const FIND_SHORT_URL_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_FULFILLED = 'FIND_SHORT_URL_TRAINING_OBJECT_OF_INTEREST_SUBJECT_AREAS_FULFILLED';

// export const FIND_SHORT_URL_TRAINING_OVERVIEW = 'FIND_SHORT_URL_TRAINING_OVERVIEW';
// export const FIND_SHORT_URL_TRAINING_OVERVIEW_PENDING = 'FIND_SHORT_URL_TRAINING_OVERVIEW_PENDING';
// export const FIND_SHORT_URL_TRAINING_OVERVIEW_FULFILLED = 'FIND_SHORT_URL_TRAINING_OVERVIEW_FULFILLED';

// export const FIND_SHORT_URL_TRAINING_SCENARIOS = 'FIND_SHORT_URL_TRAINING_SCENARIOS';
// export const FIND_SHORT_URL_TRAINING_SCENARIOS_PENDING = 'FIND_SHORT_URL_TRAINING_SCENARIOS_PENDING';
// export const FIND_SHORT_URL_TRAINING_SCENARIOS_FULFILLED = 'FIND_SHORT_URL_TRAINING_SCENARIOS_FULFILLED';

// export const CONFIRM_SHORT_URL_DETAILS = 'CONFIRM_SHORT_URL_DETAILS';
// export const CONFIRM_SHORT_URL_DETAILS_PENDING = 'CONFIRM_SHORT_URL_DETAILS_PENDING';
// export const CONFIRM_SHORT_URL_DETAILS_FULFILLED = 'CONFIRM_SHORT_URL_DETAILS_FULFILLED';

// export const TOGGLE_CREATE_SHORT_URL_SAC = 'TOGGLE_CREATE_SHORT_URL_SAC';
