import * as actionTypes from 'actions';

const initialState = {
  participantSummary: {},
  eventCounts: [],
  objectTypes: [],
  objectOfInterestSubjectAreas: [],
  overview: {},
  eventUserValidationCounts: [],
  scenarioEventCounts: [],
  loading: false,
  createdTime: null,
  periodEventCounts: []
};

const trainingDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_LATEST_CUSTOMER_EVENT_SUMMARY_PENDING: {
      return {
        ...initialState,
        loading: true
      };
    }

    case actionTypes.FIND_LATEST_CUSTOMER_EVENT_SUMMARY_FULFILLED: {
      const summary = action.payload.data.result;

      return {
        ...state,
        ...action.payload.data.result,
        loading: false
      };
    }

    case actionTypes.CREATE_CUSTOMER_EVENT_SUMMARY_PENDING: {
      return {
        ...state,
        loading: true,
        creating: true
      };
    }

    case actionTypes.CREATE_CUSTOMER_EVENT_SUMMARY_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        loading: false,
        creating: false
      };
    }

    default: {
      return state;
    }
  }
};

export default trainingDashboardReducer;
