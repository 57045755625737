import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedCustomerEvent: {},
  deleted: false,
  deleteMessage: null,
  deletedCustomerEvent: {},
  customerEvent: {
    creationId: null,
    name: '',
    eventStartTime: null,
    eventFinishTime: null,
    eventReportingStartTime: null,
    eventReportingFinishTime: null
  },
  customers: [],
  venues: [],
  cells: [],
  validationMessages: {},
  saving: false
};

const customerEventCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CUSTOMER_EVENT_PENDING: {
      return {
        ...state,
        saving: true
      };
    }

    case actionTypes.SAVE_CUSTOMER_EVENT_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        saving: false,
        savedCustomerEvent: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CUSTOMER_EVENT_PENDING: {
      return {
        ...state,
        deleted: initialState.deleted,
        deletedCustomerEvent: initialState.deletedCustomerEvent
      };
    }

    case actionTypes.DELETE_CUSTOMER_EVENT_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deleteMessage: action.payload.data.message,
        deletedCustomerEvent: action.payload.data.result
      };
    }

    case actionTypes.EDIT_CUSTOMER_EVENT_FULFILLED: {
      const customerEvent = {
        ...action.payload.data.result,
        identifiersCreated: true
      };

      return {
        ...state,
        customerEvent
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    case actionTypes.FIND_VENUES_PENDING: {
      return {
        ...state,
        venues: initialState.venues
      };
    }

    case actionTypes.FIND_VENUES_FULFILLED: {
      return {
        ...state,
        venues: action.payload.data.result
      };
    }

    case actionTypes.FIND_CELLS_PENDING: {
      return {
        ...state,
        cells: initialState.cells
      };
    }

    case actionTypes.FIND_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result
      };
    }

    case actionTypes.CREATE_CUSTOMER_EVENT_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_CUSTOMER_EVENT_VALUE_UPDATE: {
      return {
        ...state,
        customerEvent: {
          ...state.customerEvent,
          ...action.payload
        }
      };
    }

    case actionTypes.CLEAR_CUSTOMER_EVENT_DELETE_MESSAGE: {
      return {
        ...state,
        deleteMessage: initialState.deleteMessage
      };
    }

    default: {
      return state;
    }
  }
};

export default customerEventCreateReducer;
