import * as actionTypes from 'actions';

const initialState = {
  sessionWalk: {
    session: {},
    currentSetup: {}
  },
  latestSessionWalk: {
    session: {},
    currentSetup: {}
  },
  checkedListItems: [],
  checklistComplete: false,
  loading: false,
  loaded: false
};

const setupScenarioSessionWalkReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WALK_SESSION_PENDING: {
      return {
        ...state,
        sessionWalk: initialState.sessionWalk,
        loading: true
      };
    }

    case actionTypes.CLEAR_WALK_SESSION_SESSION_WALK: {
      return {
        ...state,
        sessionWalk: initialState.sessionWalk,
        setup: undefined,
        checklistComplete: false,
        checkedListItems: [],
        loaded: false
      };
    }

    case actionTypes.WALK_SESSION_FULFILLED: {
      return {
        ...state,
        sessionWalk: action.payload.data.result,
        loading: false,
        loaded: true
      };
    }

    case actionTypes.SET_WALK_SESSION_SESSION: {
      return {
        ...state,
        session: action.payload
      };
    }

    case actionTypes.FIND_WALK_SESSION_PENDING: {
      return {
        ...state,
        session: initialState.session
      };
    }

    case actionTypes.FIND_WALK_SESSION_FULFILLED: {
      return {
        ...state,
        session: action.payload.data.result
      };
    }

    case actionTypes.FIND_WALK_SESSION_PERIODIC_UPDATE_FULFILLED: {
      const setup = action.payload.data.result.setups[state.setup.index];

      return {
        ...state,
        session: action.payload.data.result,
        setup: {
          ...state.setup,
          associatedWalkCount: setup.associatedWalkCount,
          walkStatus: setup.walkStatus
        }
      };
    }

    case actionTypes.SET_WALK_SESSION_SESSION_SETUP: {
      return {
        ...state,
        setup: action.payload
      };
    }

    case actionTypes.RESET_SESSION_WALK: {
      return {
        ...initialState
      };
    }

    case actionTypes.WALK_SESSION_OPENED: {
      return {
        ...state,
        latestSessionWalk: action.payload.result
      };
    }

    case actionTypes.SET_WALK_SESSION_CHECKLIST_ITEMS_COMPLETE: {
      return {
        ...state,
        checklistComplete: action.payload.complete
      };
    }

    case actionTypes.FIND_LATEST_SESSION_WALK_REJECTED: {
      return state;
    }

    case actionTypes.FIND_LATEST_SESSION_WALK_FULFILLED: {
      if (!action.payload) {
        return {
          ...state,
          latestSessionWalkError: 'Connection Error'
        };
      }

      const latestSessionWalk = action.payload.data.result;

      const sessionIdMatch =
        state.sessionWalk &&
        state.sessionWalk.session &&
        latestSessionWalk &&
        latestSessionWalk.session &&
        latestSessionWalk.session.id === state.sessionWalk.session.id;

      return {
        ...state,
        sessionWalk: sessionIdMatch ? latestSessionWalk : state.sessionWalk,
        latestSessionWalkError: null,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default setupScenarioSessionWalkReducer;
