import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  cellLoading: false,
  cell: {
    createdBy: {},
    latestSystemProperties: {}
  },
  cellSystemProperties: [],
  cellSystemPropertiesLoading: false,
  entityPhotos: [],
  customers: [],
  venues: [],
  latestSystemProperties: { properties: {} },
  latestSystemPropertiesLoading: false,
  versionReports: [],
  versionReportsLoading: false,
  softwarePackageReports: [],
  softwarePackageReportsLoading: false,
  softwarePackageReport: null,
  softwarePackageReportLoading: false,
  softwarePackageReportPriorDiff: {
    diffPackages: [],
    missingPackages: [],
    newPackages: []
  },
  softwarePackageReportPriorDiffLoading: false,
  openTickets: [],
  closedTickets: [],
  cellRadars: [],
  cellRadarsLoading: false,
  cellLogEntries: [],
  cellLogEntriesLoading: false,
  cellBuild: null,
  cellBuildLoading: false,
  migrateCellRadarsOpen: false,
  cells: [],
  selectedRadarMigrationCell: null,
  cellRadarsMigrating: false,
  selectedRadarIds: [],
  expandedBuildComponentIds: [],
  searchResultBuildComponentIds: [],
  componentSearch: '',
  createCellCheckListOpen: false,
  createCellCheckList: {},
  cellCheckListDefinitions: [],
  createCellCheckListValidationMessages: {},
  cellCheckLists: [],
  cellCheckListsLoading: false,
  activeCellCheckList: null,
  completeCheckListItem: null,
  confirmDeleteCellCheckList: null,
  packageReportSearch: '',
  logs: {
    pageNumber: 1,
    rowCount: 0,
    loading: false,
    items: [],
    pageSize: 250,
    filterItemCount: null,
    filter: {
      level: null
    }
  }
};

const cellDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_CELL_PENDING: {
      return {
        ...initialState,
        customers: state.customers,
        venues: state.venues,
        cellLoading: true
      };
    }

    case actionTypes.TOGGLE_CELL_BUILD_COMPONENT_EXPANDED: {
      const componentId = action.payload;
      const expandedBuildComponentIds = state.expandedBuildComponentIds.slice();

      if (expandedBuildComponentIds.indexOf(componentId) > -1) {
        expandedBuildComponentIds.splice(
          expandedBuildComponentIds.indexOf(componentId),
          1
        );
      } else {
        expandedBuildComponentIds.push(componentId);
      }

      return {
        ...state,
        expandedBuildComponentIds: expandedBuildComponentIds
      };
    }

    case actionTypes.TOGGLE_CELL_BUILD_COMPONENTS_EXPANDED: {
      return {
        ...state,
        expandedBuildComponentIds: action.payload
      };
    }

    case actionTypes.SET_SEARCH_RESULT_CELL_BUILD_COMPONENTS: {
      return {
        ...state,
        searchResultBuildComponentIds: action.payload
      };
    }

    case actionTypes.SET_CELL_BUILD_COMPONENT_SEARCH: {
      return {
        ...state,
        componentSearch: action.payload
      };
    }

    case actionTypes.SET_MIGRATE_CELL_RADARS_OPEN: {
      return {
        ...state,
        migrateCellRadarsOpen: action.payload,
        selectedRadarMigrationCell: initialState.selectedRadarMigrationCell
      };
    }

    case actionTypes.CLEAR_CELL_DETAILS_PACKAGE_REPORT_SEARCH: {
      return {
        ...state,
        packageReportSearch: initialState.packageReportSearch
      };
    }

    case actionTypes.SET_CELL_DETAILS_PACKAGE_REPORT_SEARCH: {
      return {
        ...state,
        packageReportSearch: action.payload
      };
    }

    case actionTypes.SET_CONFIRM_DELETE_CELL_CHECK_LIST: {
      return {
        ...state,
        confirmDeleteCellCheckList: action.payload
      };
    }

    case actionTypes.FIND_CELL_FULFILLED: {
      return {
        ...state,
        cellLoading: false,
        cell: action.payload.data.result
      };
    }

    case actionTypes.FIND_VENUES_PENDING: {
      return {
        ...state,
        venues: initialState.venues
      };
    }

    case actionTypes.FIND_VENUES_FULFILLED: {
      return {
        ...state,
        venues: action.payload.data.result
      };
    }

    case actionTypes.FIND_CELLS_PENDING: {
      return {
        ...state,
        cells: initialState.cells
      };
    }

    case actionTypes.FIND_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result
      };
    }

    case actionTypes.SET_SELECTED_RADAR_MIGRATION_CELL: {
      return {
        ...state,
        selectedRadarMigrationCell: action.payload
      };
    }

    case actionTypes.FIND_CELL_RADARS_PENDING: {
      return {
        ...state,
        cellRadars: initialState.cellRadars,
        cellRadarsLoading: true
      };
    }

    case actionTypes.FIND_CELL_RADARS_FULFILLED: {
      return {
        ...state,
        cellRadars: action.payload.data.result,
        cellRadarsLoading: false
      };
    }

    case actionTypes.FIND_CELL_LOG_ENTRIES_PENDING: {
      return {
        ...state,
        cellLogEntries: initialState.cellLogEntries,
        cellLogEntriesLoading: true
      };
    }

    case actionTypes.FIND_CELL_LOG_ENTRIES_FULFILLED: {
      return {
        ...state,
        cellLogEntries: action.payload.data.result,
        cellLogEntriesLoading: false
      };
    }

    case actionTypes.FIND_CELL_SYSTEM_PROPERTIES_FROM_PENDING: {
      return {
        ...state,
        cellSystemProperties: initialState.cellSystemProperties,
        cellSystemPropertiesLoading: true
      };
    }

    case actionTypes.FIND_CELL_SYSTEM_PROPERTIES_FROM_FULFILLED: {
      return {
        ...state,
        cellSystemProperties: action.payload.data.result,
        cellSystemPropertiesLoading: false
      };
    }

    case actionTypes.FIND_LATEST_CELL_CONFIGURATION_FULFILLED:
    case actionTypes.VERIFY_EDITED_CELL_CONFIGURATION_FULFILLED:
    case actionTypes.VERIFY_UPLOADED_CELL_CONFIGURATION_FILE_FULFILLED: {
      return {
        ...state,
        cellConfiguration: action.payload.data.result,
        cellConfigurationLoading: false
      };
    }

    case actionTypes.FIND_CELL_CHECK_LISTS_PENDING: {
      return {
        ...state,
        cellCheckLists: initialState.cellRadars,
        cellCheckListsLoading: true,
        activeCellCheckList: initialState.activeCellCheckList
      };
    }

    case actionTypes.FIND_CELL_CHECK_LISTS_FULFILLED: {
      return {
        ...state,
        cellCheckLists: action.payload.data.result,
        cellCheckListsLoading: false,
        activeCellCheckList:
          action.payload.data.result.length > 0
            ? action.payload.data.result[0]
            : initialState.activeCellCheckList
      };
    }

    case actionTypes.SET_CELL_DETAILS_ACTIVE_CELL_CHECK_LIST: {
      return {
        ...state,
        activeCellCheckList: action.payload
      };
    }

    case actionTypes.SET_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM: {
      return {
        ...state,
        completeCheckListItem: action.payload
      };
    }

    case actionTypes.SAVE_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM_FULFILLED: {
      const checkList = action.payload.data.result;
      const index = state.cellCheckLists.map(c => c.id).indexOf(checkList.id);
      const cellCheckLists = state.cellCheckLists.slice();
      cellCheckLists[index] = checkList;

      return {
        ...state,
        activeCellCheckList: checkList,
        cellCheckLists: cellCheckLists,
        completeCheckListItem: action.payload.data.success
          ? initialState.completeCheckListItem
          : state.completeCheckListItem
      };
    }

    case actionTypes.SAVE_CELL_CHECK_LIST_FULFILLED: {
      let result;
      if (action.payload.data.success) {
        const checkList = action.payload.data.result;
        const cellCheckLists = state.cellCheckLists.slice();
        cellCheckLists.push(checkList);

        result = {
          ...state,
          activeCellCheckList: checkList,
          cellCheckLists: cellCheckLists
        };
      } else {
        result = state;
      }

      return result;
    }

    case actionTypes.DELETE_CELL_CHECK_LIST_FULFILLED: {
      let result;
      if (action.payload.data.success) {
        const checkList = action.payload.data.result;
        const index = state.cellCheckLists.map(c => c.id).indexOf(checkList.id);
        const cellCheckLists = state.cellCheckLists.slice();
        cellCheckLists.splice(index, 1);

        result = {
          ...state,
          confirmDeleteCellCheckList: null,
          cellCheckLists: cellCheckLists,
          activeCellCheckList:
            cellCheckLists.length > 0
              ? cellCheckLists[index > 0 ? index - 1 : 0]
              : null
        };
      } else {
        result = state;
      }

      return result;
    }

    case actionTypes.SET_CELL_DETAILS_COMPLETE_CHECK_LIST_ITEM_FIELD_VALUE: {
      const { field, value } = action.payload;

      return {
        ...state,
        completeCheckListItem: {
          ...state.completeCheckListItem,
          [field]: value
        }
      };
    }

    case actionTypes.FIND_CELL_BUILD_PENDING: {
      return {
        ...state,
        cellBuild: initialState.cellBuilds,
        cellBuildLoading: true
      };
    }

    case actionTypes.FIND_CELL_BUILD_FULFILLED: {
      return {
        ...state,
        cellBuild: action.payload.data.result,
        cellBuildLoading: false
      };
    }

    case actionTypes.SET_CELL_BUILD_COMPONENT_STATUS_PENDING: {
      return {
        ...state,
        cellBuildLoading: true
      };
    }

    case actionTypes.SET_CELL_BUILD_COMPONENT_STATUS_FULFILLED: {
      return {
        ...state,
        cellBuild: action.payload.data.result,
        cellBuildLoading: false
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    case actionTypes.MIGRATE_CELL_RADARS_PENDING: {
      return {
        ...state,
        cellRadarsMigrating: true
      };
    }

    case actionTypes.MIGRATE_CELL_RADARS_FULFILLED: {
      return {
        ...state,
        cellRadarsMigrating: false
      };
    }

    case actionTypes.FIND_CELL_COMPONENT_VERSION_REPORTS_PENDING: {
      return {
        ...state,
        versionReports: initialState.versionReports,
        versionReportsLoading: true
      };
    }

    case actionTypes.FIND_CELL_COMPONENT_VERSION_REPORTS_FULFILLED: {
      return {
        ...state,
        versionReports: action.payload.data.result,
        versionReportsLoading: false
      };
    }

    case actionTypes.FIND_CELL_SOFTWARE_PACKAGE_REPORTS_PENDING: {
      return {
        ...state,
        softwarePackageReports: initialState.softwarePackageReports,
        softwarePackageReportsLoading: true
      };
    }

    case actionTypes.FIND_CELL_SOFTWARE_PACKAGE_REPORTS_FULFILLED: {
      return {
        ...state,
        softwarePackageReports: action.payload.data.result,
        softwarePackageReportsLoading: false
      };
    }

    case actionTypes.FIND_CELL_SOFTWARE_PACKAGE_REPORT_PENDING: {
      return {
        ...state,
        softwarePackageReport: initialState.softwarePackageReport,
        softwarePackageReportLoading: true
      };
    }

    case actionTypes.FIND_CELL_SOFTWARE_PACKAGE_REPORT_FULFILLED: {
      return {
        ...state,
        softwarePackageReport: action.payload.data.result,
        softwarePackageReportLoading: false
      };
    }

    case actionTypes.FIND_CELL_SOFTWARE_PACKAGE_REPORT_PRIOR_DIFF_PENDING: {
      return {
        ...state,
        softwarePackageReportPriorDiff:
          initialState.softwarePackageReportPriorDiff,
        softwarePackageReportPriorDiffLoading: true
      };
    }

    case actionTypes.FIND_CELL_SOFTWARE_PACKAGE_REPORT_PRIOR_DIFF_FULFILLED: {
      return {
        ...state,
        softwarePackageReportPriorDiff: action.payload.data.result,
        softwarePackageReportPriorDiffLoading: false
      };
    }

    case actionTypes.FIND_CELL_OPEN_TICKETS_PENDING: {
      return {
        ...state,
        openTickets: initialState.openTickets,
        openTicketsLoading: true
      };
    }

    case actionTypes.FIND_CELL_OPEN_TICKETS_FULFILLED: {
      return {
        ...state,
        openTickets: action.payload.data.result,
        openTicketsLoading: false
      };
    }

    case actionTypes.FIND_CELL_CLOSED_TICKETS_PENDING: {
      return {
        ...state,
        closedTickets: initialState.closedTickets,
        closedTicketsLoading: true
      };
    }

    case actionTypes.FIND_CELL_CLOSED_TICKETS_FULFILLED: {
      return {
        ...state,
        closedTickets: action.payload.data.result,
        closedTicketsLoading: false
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        cellLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.SELECT_CELL_DETAILS_RADAR: {
      const radarId = action.payload.id;

      const selectedRadarIds = state.selectedRadarIds.slice();

      const selectedRadarIndex = selectedRadarIds.indexOf(radarId);

      if (selectedRadarIndex > -1) {
        selectedRadarIds.splice(selectedRadarIndex, 1);
      } else {
        selectedRadarIds.push(radarId);
      }

      return {
        ...state,
        selectedRadarIds: selectedRadarIds
      };
    }

    case actionTypes.SELECT_ALL_CELL_DETAILS_RADARS: {
      return {
        ...state,
        selectedRadarIds: state.cellRadars.map(radar => radar.id)
      };
    }

    case actionTypes.DE_SELECT_ALL_CELL_DETAILS_RADARS: {
      return {
        ...state,
        selectedRadarIds: initialState.selectedRadarIds
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL &&
        (entityPhoto.entityId === state.cell.id ||
          entityPhoto.entityTempId === state.cell.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL &&
        (entityPhoto.entityId === state.cell.id ||
          entityPhoto.entityTempId === state.cell.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.CELL &&
        (entityPhoto.entityId === state.cell.id ||
          entityPhoto.entityTempId === state.cell.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.CELL_CELL_LOG_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        logs: {
          ...state.logs,
          loading: true
        }
      };
    }

    case actionTypes.CELL_CELL_LOG_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        logs: {
          ...action.payload.data.result,
          pageSize: state.logs.pageSize,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.logs.filter,
            ...action.payload.data.result.filter
          },
          loading: false
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default cellDetailsReducer;
