import ws from '../utils/ws';

/* Actions */

export const objectModelValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_OBJECT_MODEL_VALUE_UPDATE,
    payload: values
  });

export const objectModelValueReset = () => dispatch =>
  dispatch({
    type: CREATE_OBJECT_MODEL_VALUE_RESET
  });

export const editObjectModel = id => dispatch =>
  dispatch({
    type: EDIT_OBJECT_MODEL,
    payload: ws.get('/object_models/' + id)
  });

export const saveObjectModel = (values, objectType) => dispatch =>
  dispatch({
    type: SAVE_OBJECT_MODEL,
    payload: ws.post('/object_models/', {
      ...values,
      objectType: objectType
    })
  });

export const findObjectModels = () => dispatch =>
  dispatch({
    type: FIND_OBJECT_MODELS,
    payload: ws.get('/object_models/')
  });

/* Constants */

export const CREATE_OBJECT_MODEL_VALUE_UPDATE =
  'CREATE_OBJECT_MODEL_VALUE_UPDATE';
export const CREATE_OBJECT_MODEL_VALUE_RESET =
  'CREATE_OBJECT_MODEL_VALUE_RESET';

export const EDIT_OBJECT_MODEL = 'EDIT_OBJECT_MODEL';
export const EDIT_OBJECT_MODEL_PENDING = 'EDIT_OBJECT_MODEL_PENDING';
export const EDIT_OBJECT_MODEL_FULFILLED = 'EDIT_OBJECT_MODEL_FULFILLED';

export const SAVE_OBJECT_MODEL = 'SAVE_OBJECT_MODEL';
export const SAVE_OBJECT_MODEL_FULFILLED = 'SAVE_OBJECT_MODEL_FULFILLED';

export const FIND_OBJECT_MODELS = 'FIND_OBJECT_MODELS';
export const FIND_OBJECT_MODELS_PENDING = 'FIND_OBJECT_MODELS_PENDING';
export const FIND_OBJECT_MODELS_FULFILLED = 'FIND_OBJECT_MODELS_FULFILLED';
