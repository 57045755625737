import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedCellModelVersion: {},
  deleted: false,
  deleteMessage: null,
  deletedCellModelVersion: {},
  cellModelVersion: {
    creationId: null,
    name: '',
    description: '',
    type: 'SOFTWARE'
  },
  customers: [],
  validationMessages: {}
};

const cellModelVersionCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CELL_MODEL_VERSION_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedCellModelVersion: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CELL_MODEL_VERSION_PENDING: {
      return {
        ...state,
        deleted: initialState.deleted,
        deletedCellModelVersion: initialState.deletedCellModelVersion
      };
    }

    case actionTypes.DELETE_CELL_MODEL_VERSION_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deleteMessage: action.payload.data.message,
        deletedCellModelVersion: action.payload.data.result
      };
    }

    case actionTypes.EDIT_CELL_MODEL_VERSION_FULFILLED: {
      return {
        ...state,
        cellModelVersion: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_CELL_MODEL_VERSION_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_CELL_MODEL_VERSION_VALUE_UPDATE: {
      return {
        ...state,
        cellModelVersion: {
          ...state.cellModelVersion,
          ...action.payload
        }
      };
    }

    case actionTypes.CLEAR_CELL_MODEL_VERSION_DELETE_MESSAGE: {
      return {
        ...state,
        deleteMessage: initialState.deleteMessage
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
};

export default cellModelVersionCreateReducer;
