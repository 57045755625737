import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { Label } from 'components';
import { Search } from 'components/SearchBar/components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  ENTITY_TYPES,
  SESSION_GROUP_CATEGORIES,
  SESSION_GROUP_ASSOCIATION_TYPES
} from 'common/constants';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  colors,
  Typography,
  Menu,
  MenuItem,
  LinearProgress
} from '@material-ui/core';
import {
  addSessionsToGroup,
  removeSessionsFromGroup,
  findSessionGroups
} from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 900,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  subHeader: {
    padding: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableContainer: {
    minHeight: 450,
    maxHeight: 450,
    overflowY: 'auto'
  },
  field: {
    marginRight: theme.spacing(2)
  },
  fieldGroup: {
    padding: theme.spacing(2)
  },
  actionButton: {
    marginRight: theme.spacing(2)
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480,
    margin: theme.spacing(2)
  },
  progress: {
    marginLeft: theme.spacing(1)
  }
}));

const SessionGroups = props => {
  const {
    open,
    onClose,
    sessionIds,
    entityType,
    groups,
    loading,
    className,
    processing,
    ...rest
  } = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpenSessionGoupId, setMenuOpenSessionGoupId] = useState(null);
  const [searchName, setSearchName] = useState('');
  const [searchCreatedBy, setSearchCreatedBy] = useState('');

  const classes = useStyles();

  const searchedGroups = groups.filter(group => {
    return (
      (searchName === '' ||
        group.name.toUpperCase().indexOf(searchName.toUpperCase()) !== -1) &&
      (searchCreatedBy === '' ||
        group.createdBy.displayName.indexOf(searchCreatedBy) !== -1)
    );
  });

  const handleClose = () => {
    onClose();
  };

  const handleAddToGroup = (group, associationType) => {
    setMenuOpenSessionGoupId(null);
    Promise.resolve(
      dispatch(
        addSessionsToGroup(group.id, sessionIds, entityType, associationType)
      )
    ).then(() => {
      dispatch(findSessionGroups());
    });
  };

  const handleRemoveFromGroup = group => {
    setMenuOpenSessionGoupId(null);
    Promise.resolve(
      dispatch(removeSessionsFromGroup(group.id, sessionIds, entityType))
    ).then(() => {
      dispatch(findSessionGroups());
    });
  };

  const handleSearchFieldChange = (field, value) => {
    if (field === 'name') {
      setSearchName(value);
    } else if (field === 'createdBy') {
      setSearchCreatedBy(value);
    }
  };

  const handleActionsClick = (event, group) => {
    setAnchorEl(event.currentTarget);
    setMenuOpenSessionGoupId(group.id);
  };

  const handleCloseActions = () => {
    setMenuOpenSessionGoupId(null);
  };

  if (!open) {
    return null;
  }

  const groupSessionCounts = {};
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    for (let j = 0; j < sessionIds.length; j++) {
      const sessionId = sessionIds[j];
      let count;
      if (entityType === ENTITY_TYPES.SETUP_SCENARIO_SESSION) {
        count =
          group.sessions &&
          group.sessions.filter(session => session.sessionId === sessionId)
            .length;
        if (groupSessionCounts.hasOwnProperty(group.id)) {
          groupSessionCounts[group.id] = groupSessionCounts[group.id] + count;
        } else {
          groupSessionCounts[group.id] = count;
        }
      } else if (entityType === ENTITY_TYPES.CUSTOMER_EVENT) {
        count =
          group.customerEvents &&
          group.customerEvents.filter(
            customerEvent => customerEvent.customerEventId === sessionId
          ).length;
        if (groupSessionCounts.hasOwnProperty(group.id)) {
          groupSessionCounts[group.id] = groupSessionCounts[group.id] + count;
        } else {
          groupSessionCounts[group.id] = count;
        }
      }
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Session Groups" />
        <Divider />
        {sessionIds.length > 1 && (
          <React.Fragment>
            <div className={classes.subHeader}>
              <Typography
                // className={classes.field}
                variant="h5">
                {`${sessionIds.length} Selected Sessions`}
              </Typography>
            </div>
            <Divider />
          </React.Fragment>
        )}
        <Search
          placeholder="Name"
          value={searchName}
          onSearchValueChange={search =>
            handleSearchFieldChange('name', search)
          }
          className={classes.search}
          hideSearchButton={true}
        />
        <Divider />
        {processing && <LinearProgress />}
        <CardContent className={classes.content}>
          <div className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedGroups.map(sessionGroup => (
                  <TableRow key={sessionGroup.id}>
                    <TableCell>{sessionGroup.name}</TableCell>
                    <TableCell>
                      {SESSION_GROUP_CATEGORIES[sessionGroup.category]}
                    </TableCell>
                    <TableCell>{sessionGroup.createdBy.displayName}</TableCell>
                    <TableCell>
                      {sessionIds.length === 1 ? (
                        <React.Fragment>
                          {groupSessionCounts[sessionGroup.id] > 0 ? (
                            <Label color={colors.green[600]}>Included</Label>
                          ) : (
                            <Label color={colors.orange[600]}>
                              Not Included
                            </Label>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Label
                            color={
                              groupSessionCounts[sessionGroup.id] ===
                              sessionIds.length
                                ? colors.green[600]
                                : colors.orange[600]
                            }>
                            {groupSessionCounts[sessionGroup.id] === undefined
                              ? 0
                              : groupSessionCounts[sessionGroup.id]}{' '}
                            of {sessionIds.length} Included
                          </Label>
                        </React.Fragment>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        disabled={
                          processing ||
                          groupSessionCounts[sessionGroup.id] ===
                            sessionIds.length
                        }
                        size="small"
                        variant="outlined"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className={classes.actionButton}
                        onClick={event =>
                          handleActionsClick(event, sessionGroup)
                        }>
                        Add{' '}
                        <ArrowDropDownIcon className={classes.dropdownIcon} />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={menuOpenSessionGoupId === sessionGroup.id}
                        onClose={handleCloseActions}>
                        <MenuItem
                          disabled={processing}
                          onClick={() => {
                            handleAddToGroup(sessionGroup, 'COMBINED');
                          }}>
                          {SESSION_GROUP_ASSOCIATION_TYPES['COMBINED']}
                        </MenuItem>
                        <MenuItem
                          disabled={processing}
                          onClick={() => {
                            handleAddToGroup(sessionGroup, 'OBJECT');
                          }}>
                          {SESSION_GROUP_ASSOCIATION_TYPES['OBJECT']}
                        </MenuItem>
                        <MenuItem
                          disabled={processing}
                          onClick={() => {
                            handleAddToGroup(sessionGroup, 'NO_OBJECT');
                          }}>
                          {SESSION_GROUP_ASSOCIATION_TYPES['NO_OBJECT']}
                        </MenuItem>
                        <MenuItem
                          disabled={processing}
                          onClick={() => {
                            handleAddToGroup(sessionGroup, 'FEMALE_COMBINED');
                          }}>
                          {SESSION_GROUP_ASSOCIATION_TYPES['FEMALE_COMBINED']}
                        </MenuItem>
                        <MenuItem
                          disabled={processing}
                          onClick={() => {
                            handleAddToGroup(sessionGroup, 'FEMALE_OBJECT');
                          }}>
                          {SESSION_GROUP_ASSOCIATION_TYPES['FEMALE_OBJECT']}
                        </MenuItem>
                        <MenuItem
                          disabled={processing}
                          onClick={() => {
                            handleAddToGroup(sessionGroup, 'FEMALE_NO_OBJECT');
                          }}>
                          {SESSION_GROUP_ASSOCIATION_TYPES['FEMALE_NO_OBJECT']}
                        </MenuItem>
                        <MenuItem
                          disabled={processing}
                          onClick={() => {
                            handleAddToGroup(sessionGroup, 'MALE_COMBINED');
                          }}>
                          {SESSION_GROUP_ASSOCIATION_TYPES['MALE_COMBINED']}
                        </MenuItem>
                        <MenuItem
                          disabled={processing}
                          onClick={() => {
                            handleAddToGroup(sessionGroup, 'MALE_OBJECT');
                          }}>
                          {SESSION_GROUP_ASSOCIATION_TYPES['MALE_OBJECT']}
                        </MenuItem>
                        <MenuItem
                          disabled={processing}
                          onClick={() => {
                            handleAddToGroup(sessionGroup, 'MALE_NO_OBJECT');
                          }}>
                          {SESSION_GROUP_ASSOCIATION_TYPES['MALE_NO_OBJECT']}
                        </MenuItem>
                      </Menu>
                      <Button
                        disabled={
                          processing || groupSessionCounts[sessionGroup.id] <= 0
                        }
                        className={classes.actionButton}
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => handleRemoveFromGroup(sessionGroup)}>
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {searchedGroups.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SessionGroups.displayName = 'SessionGroups';

SessionGroups.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

SessionGroups.defaultProps = {
  open: false
};

export default SessionGroups;
