/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { findModelConfigurations } from 'actions';
import { TextField } from '@material-ui/core';
import { CELL_MODELS } from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const SelectModelConfiguration = props => {
  const {
    cell,
    onChange,
    asRecordedModelName,
    modelConfiguration,
    fullWidth,
    className,
    margin,
    disableAsRecordedOption
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, configs } = useSelector(state => state.modelConfigurations);

  const cellConfigs = cell
    ? configs.filter(
        config =>
          config.supportedCellModels &&
          config.supportedCellModels.indexOf(cell.model) > -1
      )
    : configs;

  cellConfigs.sort((a, b) => {
    if (a.displayName.toUpperCase() < b.displayName.toUpperCase()) {
      return -1;
    }
    if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(findModelConfigurations());
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (loading) {
    return null;
  }

  return (
    <TextField
      className={clsx(classes.root, className)}
      margin={margin ? margin : 'dense'}
      fullWidth={fullWidth}
      label="Model Configuration"
      name="modelConfiguration"
      onChange={e => onChange(configs.filter(c => c.id === e.target.value)[0])}
      select
      SelectProps={{ native: true }}
      value={!modelConfiguration ? 'as-recorded' : modelConfiguration.id}
      variant="outlined">
      {!disableAsRecordedOption && (
        <>
          <optgroup>
            <option value="as-recorded">
              As Recorded{' '}
              {asRecordedModelName && '(' + asRecordedModelName + ')'}
            </option>
          </optgroup>
          <optgroup />
        </>
      )}
      {/* <optgroup> */}
      {disableAsRecordedOption && <option value="" />}
      {cellConfigs &&
        cellConfigs.map &&
        cellConfigs.map(config => (
          <option key={config.id} value={config.id}>
            {config.displayName}{' '}
            {config.supportedCellModels &&
              '(' +
                config.supportedCellModels
                  .map(cm => CELL_MODELS[cm])
                  .join(', ') +
                ')'}
          </option>
        ))}
      {/* </optgroup> */}
    </TextField>
  );
};

SelectModelConfiguration.propTypes = {
  analysisExcluded: PropTypes.bool
};

export default SelectModelConfiguration;
