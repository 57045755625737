/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { TableSortLabel } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { applyFilterColumnSort } from 'actions';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({
  root: {},
  sortable: {
    fontSize: 14,
    opacity: 0.6,
    marginLeft: theme.spacing(1)
  }
}));

const ColumnSort = props => {
  const { filter, label, column, page, onChange } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const columnSort = filter.columnSorts.filter(cs => cs.column === column)[0];
  const currentSortOrder = columnSort?.sortOrder;

  const handleSortHeader = column => {
    let columnSortOrder;
    if (currentSortOrder === 'ASC') {
      columnSortOrder = 'DESC';
    } else {
      columnSortOrder = 'ASC';
    }

    Promise.resolve(
      dispatch(applyFilterColumnSort(page, column, columnSortOrder))
    ).then(() => {
      onChange();
    });
  };

  return (
    <TableSortLabel
      active={columnSort !== undefined}
      direction={currentSortOrder?.toLowerCase()}
      onClick={() => handleSortHeader(column)}
      hideSortIcon={columnSort === undefined}>
      {label}{' '}
      {!columnSort && (
        <FontAwesomeIcon className={classes.sortable} icon={faSort} />
      )}
    </TableSortLabel>
  );
};

ColumnSort.propTypes = {
  filter: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired
};

export default ColumnSort;
