/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { CELL_BUILD_COMPONENT_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  statusUpdateDetails: {
    marginLeft: theme.spacing(1)
  }
}));

const CellBuildProductComponentStatus = props => {
  const { buildComponent, ...rest } = props;
  const classes = useStyles();

  let color;
  if (buildComponent.cellBuildStatus === 'PENDING_INSTALLATION') {
    color = colors.blueGrey[300];
  } else if (buildComponent.cellBuildStatus === 'INSTALLATION_STARTED') {
    color = colors.orange[600];
  } else if (buildComponent.cellBuildStatus === 'INSTALLATION_COMPLETED') {
    color = colors.purple[600];
  } else if (buildComponent.cellBuildStatus === 'INSTALLATION_APPROVED') {
    color = colors.green[600];
  } else if (buildComponent.cellBuildStatus === 'INSTALLATION_TESTED') {
    color = colors.green[600];
  }

  return (
    <div>
      <Label {...rest} color={color}>
        {CELL_BUILD_COMPONENT_STATUSES[buildComponent.cellBuildStatus]}
      </Label>
      {buildComponent.statusLastUpdatedBy && (
        <Label variant="outlined" className={classes.statusUpdateDetails}>
          {buildComponent.statusLastUpdatedBy.displayName}{' '}
          {moment(buildComponent.statusLastUpdatedTime).format(
            'MMM Do YYYY, h:mm a'
          )}
        </Label>
      )}
    </div>
  );
};

CellBuildProductComponentStatus.propTypes = {
  buildComponent: PropTypes.object
};

export default CellBuildProductComponentStatus;
