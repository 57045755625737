import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ConfirmDelete } from 'components';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDetailsEntityPhoto,
  setDetailsEntityPhotoEdit,
  deleteEntityPhoto,
  setEditedEntityPhotoValues,
  saveEntityPhoto,
  setEntityPhotoOpenConfirmDelete
} from 'actions';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import ws from 'utils/ws';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  progress: {
    marginBottom: theme.spacing(2)
  },
  fileName: {
    marginBottom: theme.spacing(2)
  },
  image: {
    width: '100%'
  },
  details: {
    padding: theme.spacing(2)
  },
  comment: {
    flexGrow: 1,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    position: 'relative',
    marginTop: theme.spacing(1)
  },
  field: {
    marginTop: theme.spacing(3)
  }
}));

const EntityPhotoDetails = props => {
  const { className, editable, ...rest } = props;

  const {
    entityPhoto,
    editedEntityPhoto,
    openConfirmDelete,
    readOnly,
    open,
    edit
  } = useSelector(state => state.entityPhotoDetails);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = () => {
    dispatch(clearDetailsEntityPhoto());
  };

  const handleEdit = () => {
    dispatch(setDetailsEntityPhotoEdit(true));
  };

  const handleCancel = () => {
    dispatch(setDetailsEntityPhotoEdit(false));
  };

  const handleConfirmDelete = () => {
    dispatch(setEntityPhotoOpenConfirmDelete(true));
  };

  const handleConfirmDeleteClose = () => {
    dispatch(setEntityPhotoOpenConfirmDelete(false));
  };

  const handleDelete = () => {
    dispatch(deleteEntityPhoto(entityPhoto));
  };

  const handleFieldChange = (event, field, value) => {
    event && event.persist && event.persist();
    dispatch(
      setEditedEntityPhotoValues({
        [field]: value
      })
    );
  };

  const handleSave = () => {
    dispatch(saveEntityPhoto(editedEntityPhoto));
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader title="Image Details" />
          <Divider />
          <CardContent className={classes.content}>
            <img
              className={classes.image}
              src={ws.url(
                '/aws/s3/object?bucket=' +
                  entityPhoto.bucket +
                  '&objectKey=' +
                  entityPhoto.objectKey
              )}
            />
            <div className={classes.details}>
              <Typography
                className={classes.value}
                component="p"
                variant="overline">
                {moment(entityPhoto.createdTime).format('MMM Do YYYY, h:mm a')}/
                {entityPhoto.createdBy.displayName}
              </Typography>
              {edit ? (
                <>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="Comment"
                    name="comment"
                    onChange={e =>
                      handleFieldChange(e, 'comment', e.target.value)
                    }
                    value={
                      editedEntityPhoto.comment ? editedEntityPhoto.comment : ''
                    }
                    variant="outlined"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          editedEntityPhoto.deviceLocationPlan ? true : false
                        }
                        color="primary"
                        onChange={(e, value) =>
                          handleFieldChange(e, 'deviceLocationPlan', value)
                        }
                      />
                    }
                    label="Venue Device Location Plan"
                  />
                </>
              ) : (
                <>
                  {entityPhoto.comment && (
                    <div className={classes.comment}>
                      <Typography className={classes.value} component="p">
                        {entityPhoto.comment}
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            {edit ? (
              <>
                <Button onClick={handleCancel} variant="contained">
                  Cancel
                </Button>
                <Button onClick={handleConfirmDelete} variant="contained">
                  Delete
                </Button>
                <Button onClick={handleSave} variant="contained">
                  Save
                </Button>
              </>
            ) : (
              <>
                {!readOnly && editable && (
                  <Button onClick={handleEdit} variant="contained">
                    Edit
                  </Button>
                )}
                <Button onClick={handleClose} variant="contained">
                  Done
                </Button>
              </>
            )}
          </CardActions>
        </Card>
      </Modal>

      <ConfirmDelete
        open={openConfirmDelete}
        entityType="photo"
        onClose={handleConfirmDeleteClose}
        onConfirm={handleDelete}
      />
    </>
  );
};

EntityPhotoDetails.displayName = 'ImageDetails';

EntityPhotoDetails.propTypes = {
  className: PropTypes.string
};

export default EntityPhotoDetails;
