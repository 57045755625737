import React, { useState, createRef, useRef, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  EventVideo,
  EventValidationStatus,
  NumberFormat,
  AddSubjectAreaContent,
  SubjectAreaContents,
  EventObjectPercentStatus,
  ConfirmAction,
  CreateObjectType,
  CreateObjectModel
} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  findValidateCustomerEventEvent,
  findCustomerEventValidationEvents,
  updateCustomerEventEventValidationStatus,
  updateCustomerEventParticipantGender,
  updateCustomerEventParticipantClothingType,
  findCustomerEvent,
  setEventPlaybackPaused,
  setEventPlaybackRate,
  setValidateSetupActiveStep,
  setValidateCustomerEventProcessingEventId,
  validateCustoemrEventSubjectAreaContentValueUpdate,
  findValidateCustomerEventObjectTypes,
  findValidateCustomerEventObjectModels,
  saveEventAnnotatedSubjectAreaContent,
  deleteEventAnnotatedSubjectAreaContent,
  setValidateCustomerEventSubjectAreaContentSaved,
  findCustomerEventCellEventTimes,
  toggleValidateCustomerEventEventSpecialInterest,
  setFilterCell,
  resetValidateCustomerEvent
} from 'actions';
import VideoCamOffIcon from '@material-ui/icons/VideocamOff';
import ErrorIcon from '@material-ui/icons/Error';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  ButtonGroup,
  CircularProgress,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CardHeader,
  Divider,
  Paper,
  colors,
  LinearProgress,
  TextField
} from '@material-ui/core';
import {
  NEW_ITEM_TRIGGER,
  EVENT_VALIDATION_STATUS_CODES
} from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1094,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  header: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default
  },
  files: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  alert: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  createdSessionId: {
    marginTop: theme.spacing(6)
  },
  noVideoMessage: {
    textAlign: 'center',
    paddingTop: 200
  },
  noVideoIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    width: 40,
    height: 40,
    display: 'block',
    color: colors.red[600]
  },
  eventStatus: {
    textAlign: 'center'
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  gutterBottom: {
    marginBottom: theme.spacing(2)
  },
  attributeSelection: {
    '& .MuiSvgIcon-root': {
      width: '0.8em',
      height: '0.8em',
      marginLeft: theme.spacing(1)
    },
    '& .MuiButton-root': {
      fontSize: '0.8em',
      height: 28
    },
    marginBottom: theme.spacing(1)
  },
  selectedButton: {
    backgroundColor: colors.blueGrey[50],
    boxShadow: 'inset 0 0 4px #B0BEC5'
  },
  walksValidation: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2)
  },
  walksValidationEvents: {
    height: '100%',
    flexGrow: 10000,
    display: 'inline-block',
    paddingRight: theme.spacing(2)
  },
  entityPhotos: {
    margin: -theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  setupValidation: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2)
  },
  setupValidationParticipant: {
    height: '100%',
    flexGrow: 1000,
    display: 'inline-block',
    paddingRight: theme.spacing(2)
  },
  setupValidationVideo: {
    height: 525,
    width: 640,
    flexGrow: 0,
    display: 'inline-block'
  },
  setupValidationInfo: {
    marginBottom: theme.spacing(2)
  },
  setupValidationSubjectContents: {
    marginTop: theme.spacing(1)
  },
  stepper: {
    padding: theme.spacing(2)
  },
  setupWalkList: {
    minHeight: 76,
    maxHeight: 76,
    overflowY: 'scroll',
    '& p': {
      textAlign: 'center',
      marginBottom: theme.spacing(1)
    },
    marginBottom: theme.spacing(2)
  },
  selectedEvent: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 1
  },
  walkStatus: {
    display: 'inline-block',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer',
    width: 78,
    height: 60
  },
  videoContainer: {
    height: 525,
    width: 640,
    position: 'relative'
  },
  videoOverlay: {
    position: 'absolute',
    background: theme.palette.primary.main,
    top: 0,
    height: 60,
    left: 0,
    right: 0,
    zIndex: 1
  },
  videoOverlayMessage: {
    color: 'white',
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
    top: 20,
    textAlign: 'center',
    fontSize: '16px'
  },
  webkitPlayButtonOverlay: {
    position: 'absolute',
    background: 'red',
    top: 408,
    height: 48,
    width: 130,
    left: 0,
    zIndex: 1,
    opacity: 0
  },
  walkCountOverlay: {
    position: 'absolute',
    background: 'white',
    top: 408,
    height: 30,
    borderRadius: 30,
    width: 100,
    left: 270,
    zIndex: 1,
    opacity: 0.7,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.grey[400]
  },
  walkCountOverlayMessage: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
    top: 3,
    textAlign: 'center',
    fontSize: '12px'
  },
  eventProgress: {
    marginLeft: 24
  },
  processing: {
    marginTop: -4
  },
  addContentButton: {
    marginTop: theme.spacing(2)
  },
  detailsTable: {
    '& td': {
      padding: '6px 6px 6px 16px'
    }
  },
  labelCell: {
    width: 125
  },
  specialInterest: {
    fontSize: 16,
    marginLeft: 6,
    marginBottom: -6,
    color: colors.orange[400]
  },
  clothingTypeButton: {
    fontSize: '11px!important',
    padding: 0
  },
  cellSelect: {
    marginTop: 0,
    marginBottom: theme.spacing(2)
  }
}));

const ValidateCustomerEvent = props => {
  const { customerEvent, open, onClose, className } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const eventVideoRef = createRef();
  const eventListRef = useRef();

  const {
    initialEventRequested,
    currentEvent,
    eventVideoPlaybackRate,
    eventsLoaded,
    events,
    processing,
    eventPlaybackPaused,
    processingEventId,
    objectTypes,
    objectModels,
    subjectAreaContent,
    filterCell
  } = useSelector(state => state.validateCustomerEvent);

  const [addSubjectAreaContentOpen, setAddSubjectAreaContentOpen] = useState(
    false
  );
  const [
    confirmDeleteSubjectAreaContentOpen,
    setConfirmDeleteSubjectAreaContentOpen
  ] = useState(false);
  const [deleteSubjectAreaContent, setDeleteSubjectAreaContent] = useState(
    false
  );
  const [openCreateObjectType, setOpenCreateObjectType] = useState(false);
  const [openCreateObjectModel, setOpenCreateObjectModel] = useState(false);

  const cells = customerEvent.cells;

  const eventStatusRefs = [];
  const eventIndex = events.map(event => event.id).indexOf(currentEvent.id);
  const reviewedWalkCount = events.filter(
    se => se.validationStatus !== 'pending'
  ).length;

  useEffect(() => {
    if (eventIndex < events.length && eventStatusRefs[eventIndex]) {
      eventListRef.current.scrollTop =
        eventStatusRefs[eventIndex].offsetTop - 8;
    }
  });

  if (!open || !customerEvent) {
    return null;
  }

  if (open && !initialEventRequested && events.length > 0) {
    dispatch(findValidateCustomerEventEvent(events[0].id));
    dispatch(setValidateSetupActiveStep(0));
    dispatch(findValidateCustomerEventObjectTypes());
    dispatch(findValidateCustomerEventObjectModels());
  }

  if (open && !eventsLoaded) {
    dispatch(findCustomerEventValidationEvents(customerEvent.id));
  }

  const eventValidationStatusCounts = {};
  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    if (event.validationStatus) {
      eventValidationStatusCounts[event.validationStatus] =
        (eventValidationStatusCounts[event.validationStatus]
          ? eventValidationStatusCounts[event.validationStatus]
          : 0) + 1;
    }
  }

  const handleUpdateEventValidationStatus = status => {
    dispatch(setValidateCustomerEventProcessingEventId(currentEvent.id));
    dispatch(
      updateCustomerEventEventValidationStatus(
        customerEvent.id,
        currentEvent.id,
        status
      )
    );
  };

  const handleUpdateEventParticipantGender = gender => {
    dispatch(setValidateCustomerEventProcessingEventId(currentEvent.id));
    dispatch(
      updateCustomerEventParticipantGender(
        currentEvent.id,
        currentEvent.annotatedParticipantGender === gender ? null : gender
      )
    );
  };

  const handleUpdateEventParticipantClothingType = clothingType => {
    dispatch(setValidateCustomerEventProcessingEventId(currentEvent.id));
    dispatch(
      updateCustomerEventParticipantClothingType(
        currentEvent.id,
        currentEvent.annotatedParticipantClothingType === clothingType
          ? null
          : clothingType
      )
    );
  };

  const handleEventListClick = eventIndex => {
    dispatch(findValidateCustomerEventEvent(events[eventIndex].id));
  };

  const handleClose = () => {
    dispatch(findCustomerEvent(customerEvent.id));
    dispatch(findCustomerEventCellEventTimes(customerEvent.id));
    onClose();
  };

  const handleEventVideoEnd = () => {
    if (!eventPlaybackPaused) {
      const validationStatus = currentEvent.validationStatus;
      const newValidationStatus =
        validationStatus === 'pending'
          ? 'VERIFIED'
          : EVENT_VALIDATION_STATUS_CODES[validationStatus];

      dispatch(setValidateCustomerEventProcessingEventId(currentEvent.id));
      Promise.resolve(
        dispatch(
          updateCustomerEventEventValidationStatus(
            customerEvent.id,
            currentEvent.id,
            newValidationStatus
          )
        )
      ).then(() => {
        if (eventIndex < events.length - 1) {
          dispatch(findValidateCustomerEventEvent(events[eventIndex + 1].id));
        }
      });
    }
  };

  const handleEventVideoLoaded = () => {
    if (eventPlaybackPaused) {
      eventVideoRef.current.pause();
    } else {
      eventVideoRef.current.playbackRate = eventVideoPlaybackRate;
      eventVideoRef.current.play();
    }
  };

  const handleKeyDown = event => {
    if (event.which === 32) {
      event.preventDefault();
      if (eventPlaybackPaused) {
        dispatch(setEventPlaybackPaused(false));
        eventVideoRef.current.playbackRate = eventVideoPlaybackRate;
        eventVideoRef.current && eventVideoRef.current.play();
      }
    }
  };

  const handleKeyUp = event => {
    if (event.which === 32) {
      event.preventDefault();
      if (!eventPlaybackPaused) {
        dispatch(setEventPlaybackPaused(true));
        eventVideoRef.current && eventVideoRef.current.pause();
      }
    }
  };

  const handleAdjustEventPlaybackRate = rate => {
    dispatch(setEventPlaybackRate(rate));
    eventVideoRef.current.playbackRate = rate;
  };

  const setEventStatusRef = ref => {
    eventStatusRefs.push(ref);
  };

  const handleOpenAddSubjectAreaContent = () => {
    dispatch(setValidateCustomerEventSubjectAreaContentSaved(false));
    setAddSubjectAreaContentOpen(true);
  };

  const handleCloseAddSubjectAreaContent = () => {
    setAddSubjectAreaContentOpen(false);
  };

  const handleSubjectAreaContentFieldChange = (field, value) => {
    if (value === NEW_ITEM_TRIGGER) {
      if (field === 'objectType') {
        handleToggleCreateObjectType();
      } else if (field === 'objectModel') {
        handleToggleCreateObjectModel();
      }
    } else {
      dispatch(
        validateCustoemrEventSubjectAreaContentValueUpdate(field, value)
      );
    }
  };

  const handleSaveSubjectAreaContent = () => {
    dispatch(
      saveEventAnnotatedSubjectAreaContent(currentEvent.id, subjectAreaContent)
    );
  };

  const handleDeleteSubjectAreaContent = subjectAreaContent => {
    setDeleteSubjectAreaContent(subjectAreaContent);
    setConfirmDeleteSubjectAreaContentOpen(true);
  };

  const handleConfirmDeleteSubjectAreaContent = () => {
    dispatch(
      deleteEventAnnotatedSubjectAreaContent(
        currentEvent.id,
        deleteSubjectAreaContent.guid
      )
    );
    handleCloseConfirmDeleteSubjectAreaContent();
  };

  const handleCloseConfirmDeleteSubjectAreaContent = () => {
    setConfirmDeleteSubjectAreaContentOpen(false);
    setDeleteSubjectAreaContent(null);
  };

  const handleToggleCreateObjectType = () => {
    setOpenCreateObjectType(!openCreateObjectType);
  };

  const handleToggleCreateObjectModel = () => {
    setOpenCreateObjectModel(!openCreateObjectModel);
  };

  const handleToggleEventSpecialInterest = event => {
    dispatch(toggleValidateCustomerEventEventSpecialInterest(event.id));
  };

  const handleFilterCellChange = event => {
    const cell =
      customerEvent?.cells[
        customerEvent?.cells.map(c => c.id).indexOf(event.target.value)
      ];

    dispatch(setFilterCell(cell));
  };

  return (
    <>
      <Modal
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onClose={handleClose}
        open={open}>
        <Card className={clsx(classes.root, className)}>
          <CardHeader title="Validate Customer Event" />
          <Divider />
          <CardContent className={classes.content}>
            {processing && <LinearProgress className={classes.processing} />}

            <div className={classes.walksValidation}>
              <div className={classes.walksValidationEvents}>
                <TextField
                  className={classes.cellSelect}
                  margin="dense"
                  // label="Cell"
                  name="cell"
                  fullWidth
                  onChange={event => handleFilterCellChange(event)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={filterCell ? filterCell.id : ''}
                  variant="outlined">
                  <option value="">All Cells</option>
                  <optgroup>
                    {cells?.map(cell => (
                      <option key={cell.id} value={cell.id}>
                        {cell.name}
                      </option>
                    ))}
                  </optgroup>
                </TextField>

                <Paper className={classes.setupValidationInfo}>
                  <Table size="small" className={classes.detailsTable}>
                    <TableBody>
                      <TableRow>
                        <TableCell>Reviewed Walks</TableCell>
                        <TableCell>
                          {events.length > 0 && (
                            <>
                              {reviewedWalkCount} of {events.length}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.labelCell}>
                          Walk Time
                        </TableCell>
                        <TableCell>
                          {currentEvent.eventTime &&
                            moment(currentEvent.eventTime).format(
                              'MMM Do YYYY, h:mm:ss a'
                            )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Walk Result</TableCell>
                        <TableCell>
                          <EventObjectPercentStatus event={currentEvent} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>

                <Paper>
                  <div ref={eventListRef} className={classes.setupWalkList}>
                    <PerfectScrollbar>
                      {events.map((event, i) => (
                        <Card
                          key={i}
                          ref={setEventStatusRef}
                          className={`${classes.walkStatus} ${
                            event.id === currentEvent.id
                              ? classes.selectedEvent
                              : ''
                          }`}
                          onClick={() => handleEventListClick(i)}>
                          <Typography
                            className={classes.field}
                            component="p"
                            variant="overline">
                            <NumberFormat value={i + 1} />
                            {event.specialInterest && (
                              <ErrorIcon className={classes.specialInterest} />
                            )}
                          </Typography>

                          {event.id === processingEventId ? (
                            <CircularProgress
                              size={12}
                              className={classes.eventProgress}
                            />
                          ) : (
                            <EventValidationStatus event={event} />
                          )}
                        </Card>
                      ))}
                    </PerfectScrollbar>
                  </div>
                </Paper>

                <ButtonGroup className={classes.attributeSelection} fullWidth>
                  <Button
                    disabled={processing}
                    onClick={() => handleUpdateEventValidationStatus('PENDING')}
                    variant="outlined"
                    className={
                      currentEvent.validationStatus === 'pending'
                        ? classes.selectedButton
                        : ''
                    }>
                    Pending
                  </Button>
                  <Button
                    disabled={processing}
                    onClick={() => handleUpdateEventValidationStatus('SUSPECT')}
                    variant="outlined"
                    className={
                      currentEvent.validationStatus === 'suspect'
                        ? classes.selectedButton
                        : ''
                    }>
                    Suspect
                  </Button>
                  <Button
                    disabled={processing}
                    onClick={() =>
                      handleUpdateEventValidationStatus('VERIFIED')
                    }
                    variant="outlined"
                    className={
                      currentEvent.validationStatus === 'verified'
                        ? classes.selectedButton
                        : ''
                    }>
                    Verified
                  </Button>
                  <Button
                    disabled={processing}
                    onClick={() => handleUpdateEventValidationStatus('TESTING')}
                    variant="outlined"
                    className={
                      currentEvent.validationStatus === 'testing'
                        ? classes.selectedButton
                        : ''
                    }>
                    Testing
                  </Button>
                </ButtonGroup>

                <ButtonGroup className={classes.attributeSelection} fullWidth>
                  <Button
                    disabled={processing}
                    onClick={() => handleUpdateEventParticipantGender('MALE')}
                    variant="outlined"
                    className={
                      currentEvent.annotatedParticipantGender === 'MALE'
                        ? classes.selectedButton
                        : ''
                    }>
                    Male
                  </Button>
                  <Button
                    disabled={processing}
                    onClick={() => handleUpdateEventParticipantGender('FEMALE')}
                    variant="outlined"
                    className={
                      currentEvent.annotatedParticipantGender === 'FEMALE'
                        ? classes.selectedButton
                        : ''
                    }>
                    Female
                  </Button>
                </ButtonGroup>

                <ButtonGroup className={classes.attributeSelection} fullWidth>
                  <Button
                    disabled={processing}
                    onClick={() =>
                      handleUpdateEventParticipantClothingType('LIGHT')
                    }
                    variant="outlined"
                    className={clsx(
                      classes.clothingTypeButton,
                      currentEvent.annotatedParticipantClothingType === 'LIGHT'
                        ? classes.selectedButton
                        : ''
                    )}>
                    Light Clothing
                  </Button>
                  <Button
                    disabled={processing}
                    onClick={() =>
                      handleUpdateEventParticipantClothingType('MEDIUM')
                    }
                    variant="outlined"
                    className={clsx(
                      classes.clothingTypeButton,
                      currentEvent.annotatedParticipantClothingType === 'MEDIUM'
                        ? classes.selectedButton
                        : ''
                    )}>
                    Medium Clothing
                  </Button>
                  <Button
                    disabled={processing}
                    onClick={() =>
                      handleUpdateEventParticipantClothingType('HEAVY')
                    }
                    variant="outlined"
                    className={clsx(
                      classes.clothingTypeButton,
                      currentEvent.annotatedParticipantClothingType === 'HEAVY'
                        ? classes.selectedButton
                        : ''
                    )}>
                    Heavy Clothing
                  </Button>
                </ButtonGroup>

                <ButtonGroup className={classes.attributeSelection} fullWidth>
                  <Button
                    disabled={processing}
                    onClick={() =>
                      handleToggleEventSpecialInterest(currentEvent)
                    }
                    variant="outlined"
                    className={
                      currentEvent.specialInterest ? classes.selectedButton : ''
                    }>
                    Special Interest
                  </Button>
                </ButtonGroup>

                <div className={classes.setupValidationSubjectContents}>
                  <SubjectAreaContents
                    subjectAreaContents={
                      currentEvent.annotatedSubjectAreaContents
                    }
                    onDelete={handleDeleteSubjectAreaContent}
                  />
                  <Button
                    className={classes.addContentButton}
                    variant="contained"
                    size="small"
                    onClick={handleOpenAddSubjectAreaContent}>
                    Add Content
                  </Button>
                </div>
              </div>

              <div className={classes.setupValidationVideo}>
                <Paper className={classes.videoContainer}>
                  {currentEvent.id && (
                    <>
                      {currentEvent.videoTimestampPresent ? (
                        <>
                          {eventPlaybackPaused && (
                            <>
                              <Typography
                                className={classes.videoOverlayMessage}
                                variant="h4">
                                Validation paused. Press and hold spacebar to
                                continue
                              </Typography>
                              <div className={classes.videoOverlay} />
                              <div
                                className={classes.webkitPlayButtonOverlay}
                              />
                            </>
                          )}
                          {events.length > 0 && (
                            <div className={classes.walkCountOverlay}>
                              <Typography
                                className={classes.walkCountOverlayMessage}
                                variant="h4">
                                Walk {eventIndex + 1} of {events.length}
                              </Typography>
                            </div>
                          )}

                          <EventVideo
                            eventVideoRef={eventVideoRef}
                            playbackRate={eventVideoPlaybackRate}
                            autoPlay={false}
                            loop={false}
                            displayPlaybackRate
                            handleVideoEnd={handleEventVideoEnd}
                            handleVideoLoaded={handleEventVideoLoaded}
                            handleAdjustPlaybackRate={
                              handleAdjustEventPlaybackRate
                            }
                            width={640}
                            height={480}
                            eventId={currentEvent.id}
                          />
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.noVideoMessage}
                            variant="h5">
                            No video data was captured for this event, please
                            mark as suspect
                          </Typography>
                          <VideoCamOffIcon className={classes.noVideoIcon} />
                        </>
                      )}
                    </>
                  )}
                </Paper>
              </div>
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={handleClose}
              variant="contained"
              disabled={processing}>
              Done
            </Button>
          </CardActions>
        </Card>
      </Modal>

      <ConfirmAction
        message={
          'Please confirm that you wish to remove this item from the walk.'
        }
        onClose={handleCloseConfirmDeleteSubjectAreaContent}
        onConfirm={handleConfirmDeleteSubjectAreaContent}
        open={confirmDeleteSubjectAreaContentOpen}
      />

      <AddSubjectAreaContent
        open={addSubjectAreaContentOpen}
        onClose={handleCloseAddSubjectAreaContent}
        subjectAreaContent={subjectAreaContent}
        objectTypes={objectTypes}
        objectModels={objectModels}
        onFieldChange={handleSubjectAreaContentFieldChange}
        onSave={handleSaveSubjectAreaContent}
      />

      <CreateObjectType
        open={openCreateObjectType}
        onClose={handleToggleCreateObjectType}
      />

      <CreateObjectModel
        open={openCreateObjectModel}
        objectType={subjectAreaContent.objectType}
        onClose={handleToggleCreateObjectModel}
      />
    </>
  );
};

ValidateCustomerEvent.displayName = 'ValidateCustomerEvent';

ValidateCustomerEvent.propTypes = {
  trainerData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

ValidateCustomerEvent.defaultProps = {
  open: false
};

export default ValidateCustomerEvent;
