import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat } from 'components';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  LinearProgress
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1150
  },
  methodCell: {
    width: 100
  },
  statusCell: {
    width: 64
  },
  options: {
    float: 'right'
  },
  scoreType: {
    width: 200
  },
  nonSelected: {
    background: 'white'
  }
}));

const SummaryOverviewAnalysisCard = props => {
  const { className, analysis, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardHeader title="Summary" />
        <Divider />
        <CardContent className={classes.content}>
          {analysis.loading && <LinearProgress />}

          <PerfectScrollbar>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Accuracy</TableCell>
                  <TableCell>Margin</TableCell>
                  <TableCell>Margin If Correct</TableCell>
                </TableRow>
              </TableHead>
              {analysis.results && (
                <TableBody>
                  <TableRow selected>
                    <TableCell className={classes.scoreType}>No Gun</TableCell>
                    <TableCell className={classes.nonSelected}>
                      {analysis.results.NO_OBJECT && (
                        <NumberFormat
                          value={analysis.results.NO_OBJECT.accuracy}
                          percentage
                          suffix={'%'}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.nonSelected}>
                      {analysis.results.NO_OBJECT && (
                        <NumberFormat
                          value={analysis.results.NO_OBJECT.margin}
                          percentage
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.nonSelected}>
                      {analysis.results.NO_OBJECT && (
                        <NumberFormat
                          value={analysis.results.NO_OBJECT.marginIfCorrect}
                          percentage
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>Gun</TableCell>
                    <TableCell className={classes.nonSelected}>
                      {analysis.results.OBJECT && (
                        <NumberFormat
                          value={analysis.results.OBJECT.accuracy}
                          percentage
                          suffix={'%'}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.nonSelected}>
                      {analysis.results.OBJECT && (
                        <NumberFormat
                          value={analysis.results.OBJECT.margin}
                          percentage
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.nonSelected}>
                      {analysis.results.OBJECT && (
                        <NumberFormat
                          value={analysis.results.OBJECT.marginIfCorrect}
                          percentage
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow selected>
                    <TableCell>Right Foot</TableCell>
                    <TableCell className={classes.nonSelected}>
                      {analysis.results.RIGHT_FOOT && (
                        <NumberFormat
                          value={analysis.results.RIGHT_FOOT.accuracy}
                          percentage
                          suffix={'%'}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.nonSelected} />
                    <TableCell className={classes.nonSelected} />
                  </TableRow>
                  <TableRow selected>
                    <TableCell>Left Foot</TableCell>
                    <TableCell className={classes.nonSelected}>
                      {analysis.results.LEFT_FOOT && (
                        <NumberFormat
                          value={analysis.results.LEFT_FOOT.accuracy}
                          percentage
                          suffix={'%'}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.nonSelected} />
                    <TableCell className={classes.nonSelected} />
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  );
};

SummaryOverviewAnalysisCard.propTypes = {
  className: PropTypes.string,
  analysis: PropTypes.object.isRequired
};

export default SummaryOverviewAnalysisCard;
