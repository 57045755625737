/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import AssignmentIcon from '@material-ui/icons/Assignment';
import SoftwareIcon from '@material-ui/icons/DesktopMacOutlined';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DnsIcon from '@material-ui/icons/Dns';
import EventIcon from '@material-ui/icons/Event';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MemoryIcon from '@material-ui/icons/Memory';
import {
  CELL_LOG_ENTRIES_VIEW,
  CELL_WALKS_VIEW,
  CELLS_CONFIG_UPDATE,
  CELLS_VIEW,
  CUSTOMER_EVENTS_VIEW,
  CUSTOMERS_VIEW,
  EVENT_DATA_SETS_VIEW,
  EVENT_MODEL_RUN_REQUESTS_VIEW,
  EVENT_MODEL_WORKERS_VIEW,
  MODEL_CONFIGURATIONS_VIEW,
  MODEL_SETS_VIEW,
  PARTICIPANTS_VIEW,
  PORTAL_USERS_VIEW,
  SHORT_URLS_VIEW,
  SOFTWARE_PACKAGES_VIEW,
  TEST_RESULT_FILES_VIEW,
  TEST_SCENARIOS_VIEW,
  TEST_SESSION_ANALYSIS_VIEW,
  TEST_SESSION_GROUPS_VIEW,
  TEST_SESSIONS_VIEW,
  TRAINING_SCENARIOS_VIEW,
  TRAINING_SESSIONS_VIEW,
  VENUES_VIEW
} from 'common/Right';

export default [
  {
    title: null,
    pages: [
      {
        title: 'Walks',
        href: '/walks',
        rights: [CELL_WALKS_VIEW],
        icon: DirectionsWalkIcon,
        children: [
          {
            title: 'Walks',
            href: '/walks/cell_walks/',
            right: CELL_WALKS_VIEW
          },
          {
            title: 'Walk Collections',
            href: '/walks/data_sets/',
            right: EVENT_DATA_SETS_VIEW
          },
          {
            title: 'Analysis',
            href: '/walks/analysis/',
            right: TEST_SESSION_ANALYSIS_VIEW
          },
          {
            title: 'Local Storage',
            href: '/walks/local_storage/',
            right: TEST_SESSION_ANALYSIS_VIEW
          }
        ]
      },
      {
        title: 'Testing',
        href: '/testing',
        rights: [
          TEST_SCENARIOS_VIEW,
          TEST_SESSIONS_VIEW,
          TEST_SESSION_GROUPS_VIEW,
          TEST_SESSION_ANALYSIS_VIEW
        ],
        icon: AssignmentIcon,
        children: [
          // {
          //   title: 'Dashboard',
          //   href: '/testing/dashboard/',
          //   right: TEST_DASHBOARD_VIEW
          // },
          // {
          //   title: 'Scheduled For Me',
          //   href: '/testing/scheduled/',
          //   right: TEST_SCENARIOS_VIEW
          // },
          {
            title: 'Sessions',
            href: '/testing/sessions/',
            right: TEST_SESSIONS_VIEW
          },
          {
            title: 'Scenarios',
            href: '/testing/scenarios/',
            right: TEST_SCENARIOS_VIEW
          }
        ]
      },
      {
        title: 'Data Collection',
        href: '/training',
        rights: [TRAINING_SCENARIOS_VIEW, TRAINING_SESSIONS_VIEW],
        icon: HowToRegIcon,
        children: [
          {
            title: 'Sessions',
            href: '/training/sessions/',
            right: TRAINING_SESSIONS_VIEW
          },
          {
            title: 'Scenarios',
            href: '/training/scenarios/',
            right: TRAINING_SCENARIOS_VIEW
          },
          {
            title: 'Cells',
            href: '/training/cells_overview/',
            right: TRAINING_SESSIONS_VIEW
          }
        ]
      },
      {
        title: 'Events',
        href: '/events',
        rights: [CUSTOMER_EVENTS_VIEW],
        icon: EventIcon,
        children: [
          {
            title: 'Calendar',
            href: '/events/calendar/',
            right: CUSTOMER_EVENTS_VIEW
          },
          {
            title: 'Customer Events',
            href: '/events/customer_events/',
            right: CUSTOMER_EVENTS_VIEW
          }
        ]
      },
      {
        title: 'Cells',
        href: '/deployment',
        rights: [CELLS_VIEW],
        icon: DnsIcon,
        children: [
          {
            title: 'Cells',
            href: '/deployment/cells/',
            right: CELLS_VIEW
          },
          {
            title: 'Health',
            href: '/deployment/cell_health_reports/',
            right: CELL_LOG_ENTRIES_VIEW
          },
          {
            title: 'Logs',
            href: '/deployment/cell_log_entries/',
            right: CELL_LOG_ENTRIES_VIEW
          },
          {
            title: 'Map',
            href: '/deployment/cells_map',
            right: CELLS_VIEW
          },
          {
            title: 'Configuration',
            href: '/deployment/cell_config/',
            right: CELLS_CONFIG_UPDATE
          }
        ]
      },
      {
        title: 'Modelling',
        href: '/models',
        rights: [MODEL_CONFIGURATIONS_VIEW],
        icon: MemoryIcon,
        children: [
          {
            title: 'Models',
            href: '/models/model_configurations/',
            right: MODEL_CONFIGURATIONS_VIEW
          },
          {
            title: 'Model Sets',
            href: '/models/model_sets/',
            right: MODEL_SETS_VIEW
          },
          {
            title: 'Model Processing',
            href: '/models/event_model_run_requests/',
            right: EVENT_MODEL_RUN_REQUESTS_VIEW
          },
          {
            title: 'Cluster Workers',
            href: '/models/event_model_workers/',
            right: EVENT_MODEL_WORKERS_VIEW
          }
        ]
      },
      {
        title: 'Software',
        href: '/software',
        rights: [SOFTWARE_PACKAGES_VIEW, TEST_RESULT_FILES_VIEW],
        icon: SoftwareIcon,
        children: [
          {
            title: 'Packages',
            href: '/software/packages/',
            right: SOFTWARE_PACKAGES_VIEW
          },
          {
            title: 'Test Result Files',
            href: '/software/test_result_files/',
            right: TEST_RESULT_FILES_VIEW
          }
        ]
      },
      {
        title: 'Reference',
        href: '/reference',
        rights: [PARTICIPANTS_VIEW],
        icon: LibraryBooksIcon,
        children: [
          {
            title: 'Participants',
            href: '/reference/participants/',
            right: PARTICIPANTS_VIEW
          },
          {
            title: 'Customers',
            href: '/reference/customers/',
            right: CUSTOMERS_VIEW
          },
          {
            title: 'Venues',
            href: '/reference/venues/',
            right: VENUES_VIEW
          },
          {
            title: 'Portal Users',
            href: '/reference/portal_users/',
            right: PORTAL_USERS_VIEW
          },
          {
            title: 'Short URLs',
            href: '/reference/short_urls/',
            right: SHORT_URLS_VIEW
          },
          {
            title: 'User Manuals',
            href: '/reference/user_manuals/',
            right: SHORT_URLS_VIEW
          }
        ]
      }
    ]
  }
];
