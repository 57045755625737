/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(1),
    display: 'flex'
  },
  filterItem: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 16,
    backgroundColor: colors.blueGrey[50],
    marginRight: theme.spacing(1),
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap'
  },
  label: {
    marginRight: 6
  },
  value: {
    fontSize: 11
  }
}));

const FilterDescription = props => {
  const { filter } = props;

  const classes = useStyles();

  return (
    <>
      {filter &&
        filter.filterItemDescriptions &&
        filter.filterItemDescriptions.length > 0 && (
          <div className={classes.container}>
            {filter &&
              filter.filterItemDescriptions &&
              filter.filterItemDescriptions.map((filterItemDescription, i) => (
                <div key={i} className={classes.filterItem}>
                  <Typography
                    className={classes.label}
                    component="p"
                    variant="overline">
                    {filterItemDescription.filterItem}
                  </Typography>
                  <Typography className={classes.value} component="p">
                    {filterItemDescription.value}
                  </Typography>
                </div>
              ))}
          </div>
        )}
    </>
  );
};

FilterDescription.propTypes = {
  filter: PropTypes.object
};

export default FilterDescription;
