import * as actionTypes from 'actions';

const initialState = {
  cells: [],
  loading: false
};

const cellsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_CELLS_PENDING: {
      return {
        ...state,
        cells: initialState.cells,
        loading: true
      };
    }

    case actionTypes.FIND_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default cellsReducer;
