import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  eventModelWorker: {
    createdBy: {}
  }
};

const eventModelWorkerDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_EVENT_MODEL_WORKER_PENDING: {
      return {
        ...initialState,
        loading: true
      };
    }

    case actionTypes.FIND_EVENT_MODEL_WORKER_FULFILLED: {
      return {
        ...state,
        loading: false,
        eventModelWorker: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default eventModelWorkerDetailsReducer;
