import ws from '../utils/ws';

export const WALK_SESSION = 'WALK_SESSION';
export const WALK_SESSION_PENDING = 'WALK_SESSION_PENDING';
export const WALK_SESSION_FULFILLED = 'WALK_SESSION_FULFILLED';

export const RESET_SESSION_WALK = 'RESET_SESSION_WALK';

export const WALK_SESSION_OPENED = 'WALK_SESSION_OPENED';

export const SET_WALK_SESSION_SESSION = 'SET_WALK_SESSION_SESSION';
export const SET_WALK_SESSION_SESSION_SETUP = 'SET_WALK_SESSION_SESSION_SETUP';

export const FIND_WALK_SESSION = 'FIND_WALK_SESSION';
export const FIND_WALK_SESSION_PENDING = 'FIND_WALK_SESSION_PENDING';
export const FIND_WALK_SESSION_FULFILLED = 'FIND_WALK_SESSION_FULFILLED';

export const FIND_WALK_SESSION_PERIODIC_UPDATE =
  'FIND_WALK_SESSION_PERIODIC_UPDATE';
export const FIND_WALK_SESSION_PERIODIC_UPDATE_PENDING =
  'FIND_WALK_SESSION_PERIODIC_UPDATE_PENDING';
export const FIND_WALK_SESSION_PERIODIC_UPDATE_FULFILLED =
  'FIND_WALK_SESSION_PERIODIC_UPDATE_FULFILLED';

export const CLEAR_WALK_SESSION_SESSION_WALK =
  'CLEAR_WALK_SESSION_SESSION_WALK';

export const SET_WALK_SESSION_SOCKET_CONNECTED =
  'SET_WALK_SESSION_SOCKET_CONNECTED';
export const SET_WALK_SESSION_SOCKET_DISCONNECTED =
  'SET_WALK_SESSION_SOCKET_DISCONNECTED';

export const SET_WALK_SESSION_CHECKLIST_ITEMS_COMPLETE =
  'SET_WALK_SESSION_CHECKLIST_ITEMS_COMPLETE';

export const walkSession = (sessionId, setupIndex) => dispatch =>
  dispatch({
    type: WALK_SESSION,
    payload: ws.post(
      '/sessions/' + sessionId + '/setup/' + setupIndex + '/walk'
    )
  });

export const setWalkSession = session => dispatch =>
  dispatch({
    type: SET_WALK_SESSION_SESSION,
    payload: session
  });

export const findWalkSession = id => dispatch =>
  dispatch({
    type: FIND_WALK_SESSION,
    payload: ws.get('/sessions/' + id)
  });

export const findWalkSessionPeriodicUpdate = id => dispatch =>
  dispatch({
    type: FIND_WALK_SESSION_PERIODIC_UPDATE,
    payload: ws.get('/sessions/' + id)
  });

export const setWalkSessionSetup = setup => dispatch =>
  dispatch({
    type: SET_WALK_SESSION_SESSION_SETUP,
    payload: setup
  });

export const clearSessionWalk = () => dispatch =>
  dispatch({
    type: CLEAR_WALK_SESSION_SESSION_WALK
  });

export const resetSessionWalk = () => dispatch =>
  dispatch({
    type: RESET_SESSION_WALK
  });

export const setSessionWalkSocketDisconnected = () => dispatch =>
  dispatch({
    type: SET_WALK_SESSION_SOCKET_DISCONNECTED
  });

export const setWalkSessionChecklistItemsComplete = complete => dispatch =>
  dispatch({
    type: SET_WALK_SESSION_CHECKLIST_ITEMS_COMPLETE,
    payload: { complete }
  });
