import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Snackbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { NavBar, TopBar } from './components';
import { BroadcastMessage, Alert } from 'components';
import {
  findSessionUser,
  clearSessionEventDataSetAddCreatedDataSet
} from 'actions';
import { KIOSK_PATHS } from 'common/constants';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  }
}));

const Dashboard = props => {
  const { route } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const { sessionUser } = useSelector(state => state.session);
  const { createdDataSet } = useSelector(state => state.sessionEventDataSetAdd);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(findSessionUser());
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (!sessionUser) {
    return null;
  }

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const path = window.location.pathname + window.location.search;

  const hideNav = KIOSK_PATHS.indexOf(path) > -1;

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        {!hideNav && (
          <NavBar
            className={classes.navBar}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
          />
        )}
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      <BroadcastMessage />

      <Snackbar
        open={createdDataSet !== null && createdDataSet !== undefined}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        onClose={() => dispatch(clearSessionEventDataSetAddCreatedDataSet())}>
        <Alert
          variant="success"
          message={`Collection ${createdDataSet?.name} created succesfully`}
        />
      </Snackbar>
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
