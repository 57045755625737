import ws from '../utils/ws';

/* Actions */

export const findCellCheckLists = cellId => dispatch =>
  dispatch({
    type: FIND_CELL_CHECK_LISTS,
    payload: ws.get(`/cells/${cellId}/check_lists/`)
  });

export const deleteDeleteCellCheckList = (cellId, cellCheckList) => dispatch =>
  dispatch({
    type: DELETE_CELL_CHECK_LIST,
    payload: ws.post(`/cells/${cellId}/check_lists/${cellCheckList.id}/delete`)
  });

export const setConfirmDeleteCellCheckList = cellCheckList => dispatch =>
  dispatch({
    type: SET_CONFIRM_DELETE_CELL_CHECK_LIST,
    payload: cellCheckList
  });

/* Constants */

export const FIND_CELL_CHECK_LISTS = 'FIND_CELL_CHECK_LISTS';
export const FIND_CELL_CHECK_LISTS_PENDING = 'FIND_CELL_CHECK_LISTS_PENDING';
export const FIND_CELL_CHECK_LISTS_FULFILLED =
  'FIND_CELL_CHECK_LISTS_FULFILLED';

export const DELETE_CELL_CHECK_LIST = 'DELETE_CELL_CHECK_LIST';
export const DELETE_CELL_CHECK_LIST_PENDING = 'DELETE_CELL_CHECK_LIST_PENDING';
export const DELETE_CELL_CHECK_LIST_FULFILLED =
  'DELETE_CELL_CHECK_LIST_FULFILLED';

export const SET_CONFIRM_DELETE_CELL_CHECK_LIST =
  'SET_CONFIRM_DELETE_CELL_CHECK_LIST';
