import * as actionTypes from 'actions';
import { SCENARIO_TYPES } from 'common/constants';

const initialState = {
  listType: null,
  searchTerm: '',
  items: [],
  pageNumber: 1,
  rowCount: 0,

  pageSizes: {
    TEST_SCENARIO_LIST: 250,
    TRAINING_SCENARIO_LIST: 250
  },
  loading: false,
  filterItemCounts: {
    TEST_SCENARIO_LIST: null,
    TRAINING_SCENARIO_LIST: null
  },
  filter: {
    search: '',
    createdBy: '',
    category: '',
    createdTimeFrom: null,
    createdTimeTo: null
  },
  createSessionScenario: {},
  selectedScenarios: []
};

const PAGES = SCENARIO_TYPES.map(t => t.listPage);

const setupScenarioListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SCENARIOS_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.SCENARIOS_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        filter: {
          ...initialState.filter,
          ...action.payload.data.result.filter
        },
        loading: false
      };
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (PAGES.indexOf(action.payload.pageName) > -1) {
        result = {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (PAGES.indexOf(action.payload.pageName) > -1) {
        result = {
          ...state,
          pageSizes: {
            ...state.pageSizes,
            [action.payload.pageName]: action.payload.pageSize
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (PAGES.indexOf(action.payload.pageName) > -1) {
        result = {
          ...state,
          filter: {
            ...initialState.filter,
            search: state.filter.search
          },
          filterItemCount: initialState.filterItemCount
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        PAGES.indexOf(action.payload.data.result.page) > -1
      ) {
        result = {
          ...state,
          filterItemCounts: {
            ...state.filterItemCounts,
            [action.payload.data.result.page]:
              action.payload.data.result.filter.filterItemCount
          },
          filter: {
            ...initialState.filter,
            ...action.payload.data.result.filter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SETUP_SCENARIO_SEARCH_TERM_UPDATE: {
      return {
        ...state,
        searchTerm: action.payload
      };
    }

    case actionTypes.SET_SCENARIO_LIST_TYPE: {
      return {
        ...state,
        listType: action.payload
      };
    }

    case actionTypes.SELECT_SCENARIO_LIST_SCENARIO: {
      const scenario = action.payload;
      const scenarios = state.selectedScenarios.slice();
      const scenarioIndex = scenarios.map(s => s.id).indexOf(scenario.id);

      if (scenarioIndex !== -1) {
        scenarios.splice(scenarioIndex, 1);
      } else {
        scenarios.push(scenario);
      }

      return {
        ...state,
        selectedScenarios: scenarios
      };
    }

    case actionTypes.CLEAR_SCENARIO_LIST_SELECTED_SCENARIOS: {
      return {
        ...state,
        selectedScenarios: initialState.selectedScenarios
      };
    }

    default: {
      return state;
    }
  }
};

export default setupScenarioListReducer;
