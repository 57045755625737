import * as actionTypes from 'actions';

const initialState = {
  searchTerm: '',
  items: [],
  pageNumber: 1,
  rowCount: 0,
  pageSize: 250,
  loading: false,
  commentsSession: {
    comments: []
  }
};

const PAGE = 'TEST_SESSION_LIST';

const testSessionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_SCHEDULED_TEST_SESSIONS_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.USER_SCHEDULED_TEST_SESSIONS_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        filter: {
          ...initialState.filter,
          ...action.payload.data.result.filter
        },
        loading: false
      };
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          pageSize: action.payload.pageSize
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_USER_SCHEDULED_TEST_SESSION_LIST_COMMENTS_SESSION: {
      return {
        ...state,
        commentsSession: {
          ...action.payload,
          comments: !action.payload.comments ? [] : action.payload.comments
        }
      };
    }

    case actionTypes.ADD_COMMENT_FULFILLED:
    case actionTypes.SAVE_COMMENT_FULFILLED:
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      return {
        ...state,
        commentsSession: {
          ...state.commentsSession,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default testSessionListReducer;
