import * as actionTypes from 'actions';

const initialState = {
  open: false,
  eventDataSets: [],
  tab: 'walk_analysis',
  search: ''
};

const selectEventDataSetFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECT_EVENT_DATA_SET_OPEN: {
      return {
        ...state,
        open: action.payload,
        search: initialState.search
      };
    }

    case actionTypes.SET_SELECT_EVENT_DATA_SET_TAB: {
      return {
        ...state,
        tab: action.payload
      };
    }

    case actionTypes.SET_SELECT_EVENT_DATA_SET_SEARCH: {
      return {
        ...state,
        search: action.payload
      };
    }

    case actionTypes.FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS_PENDING: {
      return {
        ...state,
        eventDataSets: initialState.eventDataSets,
        loading: true
      };
    }

    case actionTypes.FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS_FULFILLED: {
      return {
        ...state,
        eventDataSets: action.payload.data.result,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default selectEventDataSetFiltersReducer;
