import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat } from 'components';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  LinearProgress
} from '@material-ui/core';
import {
  ENTRY_FEET,
  SCORE_SIDES,
  ANALYTICS_OBJECT_LOCATIONS
} from 'common/constants';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1150
  },
  methodCell: {
    width: 100
  },
  statusCell: {
    width: 64
  },
  entryFoot: {
    width: 200
  },
  scoreSide: {
    width: 200
  }
}));

const MarginOverviewAnalysisCard = props => {
  const { className, analysis, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardHeader title="Margin" />
        <Divider />
        <CardContent className={classes.content}>
          {analysis.loading && <LinearProgress />}
          {analysis.results && (
            <PerfectScrollbar>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} />
                    <TableCell colSpan={8}>Object Placement</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Entry</TableCell>
                    {analysis.objectLocations.map(objectLocation => (
                      <TableCell key={objectLocation}>
                        {ANALYTICS_OBJECT_LOCATIONS[objectLocation]}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(analysis.results).map(entryFoot => (
                    <React.Fragment key={entryFoot}>
                      {Object.keys(analysis.results[entryFoot]).map(
                        (scoreSide, iScoreSide) => (
                          <TableRow
                            key={scoreSide + iScoreSide}
                            selected={iScoreSide === 0}>
                            {iScoreSide === 0 && (
                              <TableCell
                                className={classes.entryFoot}
                                rowSpan={
                                  Object.keys(analysis.results[entryFoot])
                                    .length
                                }>
                                {ENTRY_FEET[entryFoot]}
                              </TableCell>
                            )}
                            <TableCell className={classes.scoreSide}>
                              {SCORE_SIDES[scoreSide]}
                            </TableCell>
                            {analysis.objectLocations.map(objectLocation => (
                              <TableCell key={objectLocation}>
                                <NumberFormat
                                  percentage
                                  value={
                                    analysis.results[entryFoot][scoreSide]
                                      .objectLocationResults[objectLocation]
                                  }
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        )
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

MarginOverviewAnalysisCard.propTypes = {
  className: PropTypes.string,
  analysis: PropTypes.object.isRequired
};

export default MarginOverviewAnalysisCard;
