import ws from '../utils/ws';

/* Actions */

export const setSessionEventDataSetAddSearch = search => dispatch =>
  dispatch({
    type: SESSION_EVENT_DATA_SET_ADD_SET_SEARCH,
    payload: search
  });

export const setSessionEventDataSetAddClearValues = search => dispatch =>
  dispatch({
    type: SESSION_EVENT_DATA_SET_ADD_CLEAR_VALUES,
    payload: search
  });

export const setSessionEventDataSetAddExistingDataSet = exisingDataSet => dispatch =>
  dispatch({
    type: SESSION_EVENT_DATA_SET_ADD_SET_EXISTING_DATA_SET,
    payload: exisingDataSet
  });

export const setSessionEventDataSetAddNewDataSetName = name => dispatch =>
  dispatch({
    type: SESSION_EVENT_DATA_SET_ADD_SET_NEW_DATA_SET_NAME,
    payload: name
  });

export const clearSessionEventDataSetAddCreatedDataSet = () => dispatch =>
  dispatch({
    type: SESSION_EVENT_DATA_SET_ADD_CLEAR_VALUES
  });

export const addSessionsToEventDataSet = (
  dataSetId,
  entityIds,
  entityType
) => dispatch =>
  dispatch({
    type: ADD_SESSIONS_TO_DATA_SET,
    payload: ws.post(
      `/events/data_sets/${dataSetId}/add?entityType=${entityType}`,
      entityIds
    )
  });

export const createFromEntityAssociations = (
  dataSetName,
  entityIds,
  entityType
) => dispatch =>
  dispatch({
    type: CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS,
    payload: ws.post(
      `/events/data_sets/create_from_entity_associations?entityType=${entityType}`,
      {
        entityIds,
        dataSetName
      }
    )
  });

/* Constants */

export const SESSION_EVENT_DATA_SET_ADD_SET_SEARCH =
  'SESSION_EVENT_DATA_SET_ADD_SET_SEARCH';

export const SESSION_EVENT_DATA_SET_ADD_CLEAR_CREATED =
  'SESSION_EVENT_DATA_SET_ADD_CLEAR_CREATED';

export const SESSION_EVENT_DATA_SET_ADD_CLEAR_VALUES =
  'SESSION_EVENT_DATA_SET_ADD_CLEAR_VALUES';

export const SESSION_EVENT_DATA_SET_ADD_SET_EXISTING_DATA_SET =
  'SESSION_EVENT_DATA_SET_ADD_SET_EXISTING_DATA_SET';

export const SESSION_EVENT_DATA_SET_ADD_SET_NEW_DATA_SET_NAME =
  'SESSION_EVENT_DATA_SET_ADD_SET_NEW_DATA_SET_NAME';

export const ADD_SESSIONS_TO_DATA_SET = 'ADD_SESSIONS_TO_DATA_SET';
export const ADD_SESSIONS_TO_DATA_SET_PENDING =
  'ADD_SESSIONS_TO_DATA_SET_PENDING';
export const ADD_SESSIONS_TO_DATA_SET_FULFILLED =
  'ADD_SESSIONS_TO_DATA_SET_FULFILLED';

export const CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS =
  'CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS';
export const CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS_PENDING =
  'CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS_PENDING';
export const CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS_FULFILLED =
  'CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS_FULFILLED';
export const CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS_REJECTED =
  'CREATE_EVENT_DATA_SET_FROM_ENTITY_ASSOCIATIONS_REJECTED';
