import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  setupScenarioSession: {
    scenarios: [],
    participant: {},
    setups: [],
    events: [],
    comments: []
  },
  events: [],
  eventsLoading: false,
  eventsFilter: {
    modelConfiguration: null,
    bias: null
  },
  eventsFilterItemCount: 0,
  sessionGroups: [],
  sessionGroupsLoading: false,
  entityPhotos: [],
  entityPhotosLoading: false,
  participantPhotos: [],
  participantPhotosLoading: false,
  setupAnalysesLoading: false,
  setupAnalyses: [],
  detailsEvent: {},
  detailsEventS3Files: [],
  testingAnalysisOverview: {
    loading: false,
    analysisOverview: {},
    setupAnalysesCount: 0,
    eventCount: 0,
    noObjectEventCount: 0,
    locationEventCounts: {},
    subModels: []
  },
  torsoThreshold: 50,
  matlabData: {},
  matlabDataLoading: false,
  detailsEventTestSessionData: {},
  commentsEvent: {
    comments: []
  },
  processingAction: false,
  modelConfiguration: null,
  modelSet: null,
  subModels: [],
  subModelsLoading: false,
  subModel: null,
  asWalkedModel: null
};

const EVENT_LIST_PAGE = 'SESSION_DETAILS_EVENT_LIST';

const setupScenarioSessionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_SESSION_PENDING: {
      return {
        ...initialState,
        sessionLoading: true
      };
    }

    case actionTypes.FIND_SESSION_FULFILLED: {
      return {
        ...state,
        sessionLoading: false,
        setupScenarioSession: {
          ...action.payload.data.result,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        }
      };
    }

    case actionTypes.FIND_SESSION_SUB_MODELS_PENDING: {
      return {
        ...state,
        subModels: initialState.subModels,
        subModelsLoading: true
      };
    }

    case actionTypes.FIND_SESSION_SUB_MODELS_FULFILLED: {
      return {
        ...state,
        subModels: action.payload.data.result,
        subModelsLoading: false
      };
    }

    case actionTypes.FIND_SESSION_SETUP_MATLAB_DATA_PENDING: {
      return {
        ...state,
        matlabData: '',
        matlabDataLoading: true
      };
    }

    case actionTypes.FIND_SESSION_SETUP_MATLAB_DATA_FULFILLED: {
      return {
        ...state,
        matlabDataLoading: false,
        matlabData: action.payload.data
      };
    }

    case actionTypes.FIND_SESSION_ANALYSIS_OVERVIEW_PENDING: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...initialState.testingAnalysisOverview,
          loading: true
        }
      };
    }

    case actionTypes.FIND_SESSION_ANALYSIS_OVERVIEW_FULFILLED: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...action.payload.data.result,
          loading: false
        }
      };
    }

    case actionTypes.FIND_EVENTS_FOR_SESSION_PENDING: {
      return {
        ...state,
        eventsLoading: true
      };
    }

    case actionTypes.FIND_EVENTS_FOR_SESSION_FULFILLED: {
      return {
        ...state,
        eventsLoading: false,
        events: action.payload.data.result
      };
    }

    case actionTypes.FIND_SESSION_AS_WALKED_MODEL_PENDING: {
      return {
        ...state,
        asWalkedModel: initialState.asWalkedModel
      };
    }

    case actionTypes.FIND_SESSION_AS_WALKED_MODEL_FULFILLED: {
      return {
        ...state,
        eventsLoading: false,
        asWalkedModel: action.payload.data.result
      };
    }

    case actionTypes.SET_SESSION_DETAILS_TORSO_SENSITIVITY: {
      const { torsoThreshold } = action.payload;

      return {
        ...state,
        torsoThreshold: torsoThreshold
      };
    }

    case actionTypes.FIND_GROUPS_FOR_TEST_SESSION_PENDING: {
      return {
        ...state,
        sessionGroupsLoading: true
      };
    }

    case actionTypes.FIND_GROUPS_FOR_TEST_SESSION_FULFILLED: {
      return {
        ...state,
        sessionGroupsLoading: false,
        sessionGroups: action.payload.data.result
      };
    }

    case actionTypes.FIND_SESSION_EVENT_LIST_DETAILS_EVENT_PENDING: {
      return {
        ...state,
        detailsEventLoading: true
      };
    }

    case actionTypes.FIND_SESSION_EVENT_LIST_DETAILS_EVENT_FULFILLED: {
      return {
        ...state,
        detailsEvent: action.payload.data.result,
        detailsEventLoading: false
      };
    }

    case actionTypes.SAVE_SESSION_PENDING: {
      return {
        ...state,
        processingAction: true
      };
    }

    case actionTypes.SAVE_SESSION_FULFILLED: {
      return {
        ...state,
        processingAction: false
      };
    }

    case actionTypes.EXCLUDE_TEST_SESSION_EVENT_FULFILLED: {
      return {
        ...state,
        eventsLoading: false,
        events: action.payload.data.result
      };
    }

    case actionTypes.INCLUDE_TEST_SESSION_EVENT_FULFILLED: {
      return {
        ...state,
        eventsLoading: false,
        events: action.payload.data.result
      };
    }

    case actionTypes.SET_TEST_SESSION_DETAILS_COMMENTS_EVENT: {
      return {
        ...state,
        commentsEvent: {
          ...action.payload,
          comments: !action.payload.comments ? [] : action.payload.comments
        }
      };
    }

    case actionTypes.ADD_COMMENT_FULFILLED:
    case actionTypes.SAVE_COMMENT_FULFILLED:
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      const commentEvent = action.payload.data.result;
      const index = state.events
        .map(e => (e.event ? e.event.id : undefined))
        .indexOf(commentEvent.id);
      const events = state.events.slice();

      if (index > -1) {
        const sessionEvent = {
          ...events[index]
        };
        if (sessionEvent.event) {
          sessionEvent.event.comments = !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments;
        }
        events[index] = sessionEvent;
      }

      return {
        ...state,
        events: events,
        setupScenarioSession: {
          ...state.setupScenarioSession,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        },
        commentsEvent: {
          ...state.commentsEvent,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        },
        detailsSessionEvent: {
          ...state.detailsSessionEvent,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        }
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_PENDING: {
      return {
        ...state,
        entityPhotosLoading: initialState.entityPhotosLoading,
        entityPhotos: initialState.entityPhotos
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        entityPhotosLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.FIND_TRAINING_SESSION_PARTICIPANT_PHOTOS_PENDING: {
      return {
        ...state,
        participantPhotosLoading: initialState.participantPhotosLoading,
        participantPhotos: initialState.participantPhotos
      };
    }

    case actionTypes.FIND_TRAINING_SESSION_PARTICIPANT_PHOTOS_FULFILLED: {
      return {
        ...state,
        participantPhotosLoading: false,
        participantPhotos: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.SETUP_SCENARIO_SESSION &&
        entityPhoto.entityId === state.setupScenarioSession.id
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.SETUP_SCENARIO_SESSION &&
        entityPhoto.entityId === state.setupScenarioSession.id
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.SETUP_SCENARIO_SESSION &&
        entityPhoto.entityId === state.setupScenarioSession.id
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SET_SESSION_DETAILS_MODEL_CONFIGURATION: {
      return {
        ...state,
        modelConfiguration: action.payload,
        modelSet: null
      };
    }

    case actionTypes.SET_SESSION_DETAILS_MODEL_SET: {
      return {
        ...state,
        modelSet: action.payload,
        modelConfiguration: null
      };
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === EVENT_LIST_PAGE) {
        result = {
          ...state,
          eventsFilter: {
            ...initialState.eventsFilter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === EVENT_LIST_PAGE
      ) {
        result = {
          ...state,
          eventsFilter: {
            ...initialState.eventsFilter,
            ...action.payload.data.result.filter
          },
          eventsFilterItemCount:
            action.payload.data.result.filter.filterItemCount
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === EVENT_LIST_PAGE) {
        result = {
          ...state,
          eventsFilter: {
            ...state.eventsFilter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_SESSION_DETAILS_AREA_GROUP: {
      return {
        ...state,
        areaGroup: action.payload
      };
    }

    case actionTypes.SET_SESSION_DETAILS_SUB_MODEL: {
      return {
        ...state,
        subModel: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default setupScenarioSessionDetailsReducer;
