import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedPortalUser: {},
  deleted: false,
  deletedPortalUser: {},
  portalUser: {
    creationId: null,
    name: '',
    emailAddress: '',
    customers: []
  },
  customers: [],
  validationMessages: {}
};

const portalUserCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PORTAL_USER_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedParticipant: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_PORTAL_USER_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deletedParticipant: action.payload.data.result
      };
    }

    case actionTypes.EDIT_PORTAL_USER_FULFILLED: {
      return {
        ...state,
        portalUser: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_PORTAL_USER_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_PORTAL_USER_VALUE_UPDATE: {
      return {
        ...state,
        portalUser: {
          ...state.portalUser,
          ...action.payload
        }
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
};

export default portalUserCreateReducer;
