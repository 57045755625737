import React, { useEffect } from 'react';
import {
  findWalkSessionsFirstSession,
  setWalkSessionIds,
  setWalkSessionSetupIndex,
  findSessionCellWalkCounts,
  findWalkSessionsCells,
  openSessions,
  closeSessions
} from 'actions';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { NumberFormat, SubjectAreaContents } from 'components';
import theme from 'theme';
import { CLOTHING_TYPES } from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1600,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  inverseContent: {
    margin: theme.spacing(3) * -1
  },
  walkCount: {
    '& td': {
      fontSize: 24,
      padding: theme.spacing(2)
    },
    '& th': {
      fontSize: '24px!important',
      padding: theme.spacing(2),
      fontWeight: 'normal!important'
    }
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const WalkSessions = () => {
  const {
    sessionIds,
    setupIndex,
    firstSession,
    cellWalkCounts,
    cells
  } = useSelector(state => state.walkSessions);

  const classes = useStyles();
  const dispatch = useDispatch();
  const POLLING_FREQUENCY = 1000;

  const cellNames = cells.reduce(function(map, obj) {
    map[obj.sharedIdentifier] = obj.name;
    return map;
  }, {});

  const cellWalkCountTotal = Object.values(cellWalkCounts).reduce(
    (a, b) => a + b,
    0
  );

  const setupWalkCountTotal =
    setupIndex !== null && firstSession.durationType === 'WALK_COUNT'
      ? firstSession.setups[setupIndex].walkCount * sessionIds.length
      : null;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (sessionIds.length > 0 && setupIndex !== null) {
        dispatch(findSessionCellWalkCounts(sessionIds, setupIndex));
      }
    }, POLLING_FREQUENCY);

    return () => clearInterval(intervalId);
  }, [sessionIds, setupIndex]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (sessionIds.length > 0) {
        dispatch(findWalkSessionsFirstSession(sessionIds[0]));
        dispatch(findWalkSessionsCells(sessionIds));
      }
    }

    return () => {
      mounted = false;
    };
  }, [sessionIds]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (sessionIds.length > 0 && setupIndex !== null) {
        dispatch(findSessionCellWalkCounts(sessionIds, setupIndex));
        dispatch(openSessions(sessionIds, setupIndex));
      }
    }

    return () => {
      mounted = false;
    };
  }, [sessionIds, setupIndex]);

  const handleSelectSetup = setup => {
    dispatch(setWalkSessionSetupIndex(setup.index));
  };

  const handleClose = () => {
    dispatch(setWalkSessionIds([]));
    dispatch(closeSessions(sessionIds));
  };

  const handleChangeSetup = () => {
    dispatch(findWalkSessionsFirstSession(sessionIds[0]));
    dispatch(setWalkSessionSetupIndex(null));
  };

  return (
    <>
      <Modal onClose={handleClose} open={sessionIds.length > 0}>
        <Card className={classes.root}>
          <CardHeader
            title="Walk Sessions"
            subheader={
              sessionIds.length +
              ' cell' +
              (sessionIds.length > 1 ? 's' : '') +
              (setupIndex !== null ? ', setup ' + (setupIndex + 1) : '')
            }
          />
          <Divider />
          <CardContent className={classes.content}>
            {setupIndex === null && firstSession && (
              <Box className={classes.inverseContent}>
                <PerfectScrollbar>
                  <Table className={classes.setups}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Setup</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Walks</TableCell>
                        <TableCell>Contents</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {firstSession.setups.map(setup => (
                        <TableRow key={setup.index} hover>
                          <TableCell>{setup.index + 1}</TableCell>
                          <TableCell>
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={() => handleSelectSetup(setup)}>
                              Select
                            </Button>
                          </TableCell>
                          <TableCell>{setup.associatedWalkCount}</TableCell>
                          <TableCell>
                            <SubjectAreaContents
                              subjectAreaContents={setup.subjectAreaContents}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
              </Box>
            )}
            {setupIndex !== null && firstSession && (
              <>
                <Grid container spacing={3}>
                  <Grid item lg={6}>
                    <Paper style={{ padding: theme.spacing(2) }}>
                      <Typography style={{ textAlign: 'center' }} variant="h1">
                        {firstSession.participant.name}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item lg={6}>
                    <Paper style={{ padding: theme.spacing(2) }}>
                      <Typography style={{ textAlign: 'center' }} variant="h1">
                        {firstSession.clothingType
                          ? CLOTHING_TYPES[firstSession.clothingType] +
                            ' Clothing'
                          : 'No Clothing Type'}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item lg={6}>
                    <SubjectAreaContents
                      subjectAreaContents={
                        firstSession.setups[setupIndex].subjectAreaContents
                      }
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <Paper>
                      <Table className={classes.walkCount}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Walks</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                              <NumberFormat value={cellWalkCountTotal} />
                              {firstSession.durationType === 'WALK_COUNT' && (
                                <>
                                  <span
                                    style={{ marginLeft: 22, marginRight: 22 }}>
                                    of
                                  </span>
                                  <NumberFormat value={setupWalkCountTotal} />
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(cellNames).map(cell => (
                            <TableRow key={cell}>
                              <TableCell>
                                {cellNames[cell] ? cellNames[cell] : cell}
                              </TableCell>
                              <TableCell style={{ textAlign: 'right' }}>
                                <NumberFormat
                                  value={
                                    cellWalkCounts[cell]
                                      ? cellWalkCounts[cell]
                                      : 0
                                  }
                                />
                                {firstSession.durationType === 'WALK_COUNT' && (
                                  <>
                                    <span
                                      style={{
                                        marginLeft: 22,
                                        marginRight: 22
                                      }}>
                                      of
                                    </span>
                                    <NumberFormat
                                      value={
                                        firstSession.setups[setupIndex]
                                          .walkCount
                                      }
                                    />
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                </Grid>
              </>
            )}
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            {setupIndex !== null && (
              <Button onClick={handleChangeSetup} variant="contained">
                Change Setup
              </Button>
            )}
            <Button onClick={handleClose} variant="contained">
              Done
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default WalkSessions;
