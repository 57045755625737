import * as actionTypes from 'actions';

const initialState = {
  customers: []
};

const customerListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...initialState,
        customers: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
};

export default customerListReducer;
