import ws from '../utils/ws';

/* Actions */

export const softwarePackagePaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: SOFTWARE_PACKAGE_PAGINATED_LIST,
    payload: ws.get(`/software/packages/page/${pageNumber}/rows/${pageSize}`)
  });

export const softwarePackagelValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_SOFTWARE_PACKAGE_VALUE_UPDATE,
    payload: values
  });

export const softwarePackageValueReset = () => dispatch =>
  dispatch({
    type: CREATE_SOFTWARE_PACKAGE_VALUE_RESET
  });

export const findSoftwarePackage = id => dispatch =>
  dispatch({
    type: FIND_SOFTWARE_PACKAGE,
    payload: ws.get(`/software/packages/${id}`)
  });

export const findSoftwarePackageInstalledCells = id => dispatch =>
  dispatch({
    type: FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS,
    payload: ws.get(`/software/packages/${id}/installed_cells/`)
  });

export const setSoftwarePackageStatus = (id, status) => dispatch =>
  dispatch({
    type: SET_SOFTWARE_PACKAGE_STATUS,
    payload: ws.post(`/software/packages/${id}/status`, { status })
  });

/* Constants */

export const SOFTWARE_PACKAGE_PAGINATED_LIST =
  'SOFTWARE_PACKAGE_PAGINATED_LIST';
export const SOFTWARE_PACKAGE_PAGINATED_LIST_PENDING =
  'SOFTWARE_PACKAGE_PAGINATED_LIST_PENDING';
export const SOFTWARE_PACKAGE_PAGINATED_LIST_FULFILLED =
  'SOFTWARE_PACKAGE_PAGINATED_LIST_FULFILLED';

export const CREATE_SOFTWARE_PACKAGE_VALUE_UPDATE =
  'CREATE_SOFTWARE_PACKAGE_VALUE_UPDATE';
export const CREATE_SOFTWARE_PACKAGE_VALUE_RESET =
  'CREATE_SOFTWARE_PACKAGE_VALUE_RESET';

export const FIND_SOFTWARE_PACKAGE = 'FIND_SOFTWARE_PACKAGE';
export const FIND_SOFTWARE_PACKAGE_PENDING = 'FIND_SOFTWARE_PACKAGE_PENDING';
export const FIND_SOFTWARE_PACKAGE_FULFILLED =
  'FIND_SOFTWARE_PACKAGE_FULFILLED';

export const FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS =
  'FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS';
export const FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_PENDING =
  'FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_PENDING';
export const FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_FULFILLED =
  'FIND_SOFTWARE_PACKAGE_INSTALLED_CELLS_FULFILLED';

export const SET_SOFTWARE_PACKAGE_STATUS = 'SET_SOFTWARE_PACKAGE_STATUS';
export const SET_SOFTWARE_PACKAGE_STATUS_PENDING =
  'SET_SOFTWARE_PACKAGE_STATUS_PENDING';
export const SET_SOFTWARE_PACKAGE_STATUS_FULFILLED =
  'SET_SOFTWARE_PACKAGE_STATUS_FULFILLED';
