import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  TableContainer,
  TableHead,
  LinearProgress,
  Tabs,
  Tab,
  TextField
} from '@material-ui/core';
import { Label, NumberFormat } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1200,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    '& td': {
      paddingTop: 2,
      paddingBottom: 2
    }
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: 0
  },
  container: {
    minHeight: 595,
    maxHeight: 595
  },
  tab: {
    '& .MuiTab-wrapper': {
      display: 'flex' /* or inline-flex */,
      flexDirection: 'row'
    }
  },
  tabLabel: {
    marginTop: 3,
    marginRight: theme.spacing(1)
  },
  countLabel: {
    height: 16,
    paddingLeft: 4,
    paddingRight: 4
  },
  searchContainer: {
    padding: theme.spacing(2)
  }
}));

const SoftwarePackageReportDetailsModal = props => {
  const {
    open,
    sofwarePackageReport,
    softwarePackageReportPriorDiff,
    onSearchChange,
    searchValue,
    loading,
    onClose
  } = props;

  const classes = useStyles();

  const [tab, setTab] = useState('all');

  const tabs = [{ value: 'all', label: 'All' }];

  if (sofwarePackageReport && sofwarePackageReport.status !== 'INITIAL') {
    tabs.push(
      { value: 'new', label: 'Added' },
      { value: 'removed', label: 'Removed' },
      { value: 'diff', label: 'Updated' }
    );
  }

  const tabCounts = {
    all:
      sofwarePackageReport && sofwarePackageReport.systemSoftwarePackages
        ? sofwarePackageReport.systemSoftwarePackages.length
        : 0,
    new: softwarePackageReportPriorDiff.newPackages.length,
    removed: softwarePackageReportPriorDiff.missingPackages.length,
    diff: softwarePackageReportPriorDiff.diffPackages.length
  };

  const tabColors = {
    all: colors.blue[600],
    new: colors.green[600],
    removed: colors.orange[600],
    diff: colors.purple[600]
  };

  const systemSoftwarePackages = sofwarePackageReport
    ? sofwarePackageReport.systemSoftwarePackages.filter(
        softwarePackage =>
          searchValue === '' ||
          softwarePackage.name
            .toUpperCase()
            .indexOf(searchValue.toUpperCase()) > -1
      )
    : [];

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader
          title="Packages"
          subheader={
            sofwarePackageReport &&
            `As of ${moment(sofwarePackageReport.reportTime).format(
              'MMM Do YYYY, h:mm:ss a'
            )}`
          }
        />
        <Divider />
        {loading && <LinearProgress />}
        <CardContent className={classes.content}>
          <Tabs
            className={classes.tabs}
            onChange={(event, value) => setTab(value)}
            scrollButtons="auto"
            value={tab}
            variant="scrollable">
            {tabs.map(tab => (
              <Tab
                className={classes.tab}
                key={tab.value}
                label={
                  <>
                    <div className={classes.tabLabel}>{tab.label}</div>
                    <Label
                      color={tabColors[tab.value]}
                      className={classes.countLabel}>
                      <NumberFormat value={tabCounts[tab.value]} />
                    </Label>
                  </>
                }
                value={tab.value}
              />
            ))}
          </Tabs>
          <Divider className={classes.divider} />
          {tab === 'all' && (
            <div className={classes.searchContainer}>
              <TextField
                className={classes.field}
                fullWidth
                label="Search"
                margin="dense"
                name="packageName"
                onChange={event => onSearchChange(event.target.value)}
                value={searchValue}
                variant="outlined"
              />
            </div>
          )}
          <Divider className={classes.divider} />
          <PerfectScrollbar>
            <TableContainer className={classes.container}>
              {tab === 'all' && (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Version</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {systemSoftwarePackages.map(softwarePackage => (
                      <TableRow key={softwarePackage.name}>
                        <TableCell>{softwarePackage.name}</TableCell>
                        <TableCell>{softwarePackage.version}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
              {tab === 'new' && (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Version</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {softwarePackageReportPriorDiff.newPackages.map(
                      softwarePackage => (
                        <TableRow key={softwarePackage.name}>
                          <TableCell>{softwarePackage.name}</TableCell>
                          <TableCell>{softwarePackage.version}</TableCell>
                        </TableRow>
                      )
                    )}
                    {softwarePackageReportPriorDiff.newPackages.length ===
                      0 && (
                      <TableRow>
                        <TableCell colSpan={2}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
              {tab === 'removed' && (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Version</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {softwarePackageReportPriorDiff.missingPackages.map(
                      softwarePackage => (
                        <TableRow key={softwarePackage.name}>
                          <TableCell>{softwarePackage.name}</TableCell>
                          <TableCell>{softwarePackage.version}</TableCell>
                        </TableRow>
                      )
                    )}
                    {softwarePackageReportPriorDiff.missingPackages.length ===
                      0 && (
                      <TableRow>
                        <TableCell colSpan={2}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
              {tab === 'diff' && (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Current Version</TableCell>
                      <TableCell>Prior Version</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {softwarePackageReportPriorDiff.diffPackages.map(
                      softwarePackage => (
                        <TableRow key={softwarePackage.name}>
                          <TableCell>{softwarePackage.name}</TableCell>
                          <TableCell>{softwarePackage.version}</TableCell>
                          <TableCell>{softwarePackage.versionDiff}</TableCell>
                        </TableRow>
                      )
                    )}
                    {softwarePackageReportPriorDiff.diffPackages.length ===
                      0 && (
                      <TableRow>
                        <TableCell colSpan={3}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </PerfectScrollbar>
        </CardContent>
        <Divider className={classes.divider} />
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SoftwarePackageReportDetailsModal.defaultProps = {
  open: false
};

export default SoftwarePackageReportDetailsModal;
