import * as actionTypes from 'actions';

const initialState = {
  setupScenario: {
    setups: [],
    createdBy: {}
  },
  events: [],
  sessions: [],
  sessionsLoaded: false,
  setupAnalysis: {
    setups: []
  },
  testingAnalysisOverview: {
    loading: false,
    analysisOverview: {},
    setupAnalysesCount: 0,
    eventCount: 0,
    noObjectEventCount: 0,
    locationEventCounts: {},
    subModels: []
  },
  excludeNoObjectEvents: false,
  analysisOverviewExcludedSubjectAreas: [],
  analysisBias: null,
  matlabData: {},
  matlabDataLoading: false,
  analysisSessions: [],
  modelConfiguration: null
};

const setupScenarioDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_SETUP_SCENARIO_PENDING: {
      return {
        ...state,
        setupScenario: {
          setups: []
        },
        scenarioLoading: true,
        sessionsLoaded: false,
        sessions: []
      };
    }

    case actionTypes.FIND_SCENARIO_SETUP_MATLAB_DATA_PENDING: {
      return {
        ...state,
        matlabData: '',
        matlabDataLoading: true
      };
    }

    case actionTypes.FIND_SCENARIO_SETUP_MATLAB_DATA_FULFILLED: {
      return {
        ...state,
        matlabDataLoading: false,
        matlabData: action.payload.data
      };
    }

    case actionTypes.FIND_SETUP_SCENARIO_FULFILLED: {
      return {
        ...state,
        scenarioLoading: false,
        setupScenario: action.payload.data.result
      };
    }

    case actionTypes.FIND_SETUP_SCENARIO_EVENTS_FULFILLED: {
      return {
        ...state,
        events: action.payload.data.result
      };
    }

    case actionTypes.FIND_SESSIONS_FOR_SCENARIO_PENDING: {
      return {
        ...state,
        sessionsLoading: true,
        sessionsLoaded: false,
        sessions: []
      };
    }

    case actionTypes.FIND_SESSIONS_FOR_SCENARIO_FULFILLED: {
      return {
        ...state,
        sessionsLoading: false,
        sessionsLoaded: true,
        sessions: action.payload.data.result
      };
    }

    case actionTypes.FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW_PENDING: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...initialState.testingAnalysisOverview,
          loading: true
        }
      };
    }

    case actionTypes.FIND_SETUP_SCENARIO_ANALYSIS_OVERVIEW_FULFILLED: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...action.payload.data.result,
          loading: false
        }
      };
    }

    case actionTypes.SET_SETUP_SCENARIO_ANALYSIS_SESSION: {
      return {
        ...state,
        analysisSessions: action.payload
      };
    }

    case actionTypes.SET_SCENARIO_ANALYSIS_OVERVIEW_EXCLUDE_SUBJECT_AREA: {
      const { subjectArea, exclude } = action.payload;
      const subjectAreas = state.analysisOverviewExcludedSubjectAreas.slice();

      if (exclude && subjectAreas.indexOf(subjectArea) < 0) {
        subjectAreas.push(subjectArea);
      } else if (!exclude && subjectAreas.indexOf(subjectArea) >= 0) {
        subjectAreas.splice(subjectAreas.indexOf(subjectArea), 1);
      }

      return {
        ...state,
        analysisOverviewExcludedSubjectAreas: subjectAreas
      };
    }

    case actionTypes.SET_SCENARIO_ANALYSIS_OVERVIEW_EXCLUDE_NO_OBJECT_EVENTS: {
      return {
        ...state,
        excludeNoObjectEvents: action.payload.exclude
      };
    }

    case actionTypes.SET_SCENARIO_DETAILS_MODEL_CONFIGURATION: {
      return {
        ...state,
        modelConfiguration: action.payload
      };
    }

    case actionTypes.SET_SETUP_SCENARIO_DETAILS_ANALYSIS_BIAS: {
      return {
        ...state,
        analysisBias: action.payload
      };
    }

    case actionTypes.SET_SETUP_SCENARIO_DETAILS_CALCULATE_MAX_ACCURACY_SUM_BIAS: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...state.testingAnalysisOverview,
          calculateOptimalAccuracySumBias: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default setupScenarioDetailsReducer;
