import ws from '../utils/ws';

export const FIND_SYSTEM_PROFILE = 'FIND_SYSTEM_PROFILE';
export const FIND_SYSTEM_PROFILE_PENDING = 'FIND_SYSTEM_PROFILE_PENDING';
export const FIND_SYSTEM_PROFILE_FULFILLED = 'FIND_SYSTEM_PROFILE_FULFILLED';
export const FIND_SYSTEM_PROFILE_REJECTED = 'FIND_SYSTEM_PROFILE_REJECTED';

export const findSystemProfile = () => dispatch =>
  dispatch({
    type: FIND_SYSTEM_PROFILE,
    payload: ws.get('/system/profile')
  });
