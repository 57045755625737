import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'components';
import { Search } from 'components/SearchBar/components';
import { ENTITY_TYPES } from 'common/constants';
import { Link as RouterLink } from 'react-router-dom';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Link,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  colors,
  Typography,
  LinearProgress,
  FormControlLabel,
  Radio,
  TextField
} from '@material-ui/core';
import {
  addSessionsToEventDataSet,
  setSessionEventDataSetAddSearch,
  setSessionEventDataSetAddExistingDataSet,
  findEventDataSets,
  setSessionEventDataSetAddNewDataSetName,
  createFromEntityAssociations,
  setSessionEventDataSetAddClearValues
} from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 900,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  subHeader: {
    padding: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableContainer: {
    minHeight: 450,
    maxHeight: 450,
    overflowY: 'auto'
  },
  field: {
    marginRight: theme.spacing(2)
  },
  fieldGroup: {
    padding: theme.spacing(2)
  },
  buttonCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  actionButton: {
    marginRight: theme.spacing(2),
    padding: '1px 8px',
    fontSize: '0.8125rem'
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480,
    margin: theme.spacing(2)
  },
  progress: {
    marginLeft: theme.spacing(1)
  },
  actionColumn: {
    width: 112
  },
  statusColumn: {
    width: 121
  }
}));

const SessionEventDataSetAdd = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    dataSets,
    loading,
    processing,
    existingDataSet,
    newDataSetName,
    search,
    validationMessages,
    creating,
    createdDataSet
  } = useSelector(state => state.sessionEventDataSetAdd);

  const { open, onClose, sessionIds, entityType } = props;

  useEffect(() => {
    let mounted = true;

    if (mounted && open) {
      dispatch(findEventDataSets());
      dispatch(setSessionEventDataSetAddClearValues());
    }

    return () => {
      mounted = false;
    };
  }, [open]);

  if (createdDataSet) {
    onClose();
  }

  const searchedDataSets = dataSets.filter(dataSet => {
    return (
      search === '' ||
      dataSet.name.toUpperCase().indexOf(search.toUpperCase()) !== -1
    );
  });

  const handleClose = () => {
    onClose();
  };

  const handleAddToDataSet = (dataSet, newDataSetName) => {
    Promise.resolve(
      dispatch(
        addSessionsToEventDataSet(
          dataSet.id,
          sessionIds,
          entityType,
          newDataSetName
        )
      )
    ).then(() => {
      dispatch(findEventDataSets());
    });
  };

  if (!open) {
    return null;
  }

  const dataSetSessionCounts = {};
  for (let i = 0; i < dataSets.length; i++) {
    const dataSet = dataSets[i];
    for (let j = 0; j < sessionIds.length; j++) {
      const sessionId = sessionIds[j];
      let count;
      if (entityType === ENTITY_TYPES.SETUP_SCENARIO_SESSION) {
        count =
          dataSet.sessions &&
          dataSet.sessions.filter(session => session.id === sessionId).length;
        if (dataSetSessionCounts.hasOwnProperty(dataSet.id)) {
          dataSetSessionCounts[dataSet.id] =
            dataSetSessionCounts[dataSet.id] + count;
        } else {
          dataSetSessionCounts[dataSet.id] = count;
        }
      } else if (entityType === ENTITY_TYPES.CUSTOMER_EVENT) {
        count =
          dataSet.customerEvents &&
          dataSet.customerEvents.filter(
            customerEvent => customerEvent.id === sessionId
          ).length;
        if (dataSetSessionCounts.hasOwnProperty(dataSet.id)) {
          dataSetSessionCounts[dataSet.id] =
            dataSetSessionCounts[dataSet.id] + count;
        } else {
          dataSetSessionCounts[dataSet.id] = count;
        }
      } else if (entityType === ENTITY_TYPES.EVENT_DATA_SET) {
        count =
          dataSet.eventDataSets &&
          dataSet.eventDataSets.filter(
            eventDataSet => eventDataSet.id === sessionId
          ).length;
        if (dataSetSessionCounts.hasOwnProperty(dataSet.id)) {
          dataSetSessionCounts[dataSet.id] =
            dataSetSessionCounts[dataSet.id] + count;
        } else {
          dataSetSessionCounts[dataSet.id] = count;
        }
      }
    }
  }

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <Card className={classes.root}>
          <CardHeader title="Add to Walk Collections" />
          <Divider />

          {sessionIds.length > 1 && (
            <>
              <div className={classes.subHeader}>
                <Typography variant="h5">
                  {`${sessionIds.length} Selected Items`}
                </Typography>
              </div>
              <Divider />
            </>
          )}

          <div className={classes.subHeader}>
            <FormControlLabel
              disabled={creating}
              control={
                <Radio
                  checked={!existingDataSet}
                  color="primary"
                  onClick={() =>
                    dispatch(setSessionEventDataSetAddExistingDataSet(false))
                  }
                />
              }
              label="New Walk Collection"
            />
            <FormControlLabel
              disabled={creating}
              control={
                <Radio
                  checked={existingDataSet}
                  color="primary"
                  onClick={() =>
                    dispatch(setSessionEventDataSetAddExistingDataSet(true))
                  }
                />
              }
              label="Existing Walk Collection"
            />
          </div>
          <Divider />

          {!existingDataSet && (
            <>
              {creating && <LinearProgress />}
              <div className={classes.subHeader}>
                <TextField
                  disabled={creating}
                  error={validationMessages.hasOwnProperty('name')}
                  helperText={validationMessages.name}
                  required
                  className={classes.field}
                  fullWidth
                  label="Walk Collection Name"
                  margin="dense"
                  name="fileName"
                  onChange={event =>
                    dispatch(
                      setSessionEventDataSetAddNewDataSetName(
                        event.target.value
                      )
                    )
                  }
                  value={newDataSetName}
                  variant="outlined"
                />
              </div>
            </>
          )}

          {existingDataSet && (
            <>
              <Search
                placeholder="Name"
                value={search}
                onSearchValueChange={search => {
                  dispatch(setSessionEventDataSetAddSearch(search));
                }}
                className={classes.search}
                hideSearchButton={true}
              />
              <Divider />
              {(loading || processing) && <LinearProgress />}
              <CardContent className={classes.content}>
                <div className={classes.tableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.actionColumn}>
                          Actions
                        </TableCell>
                        <TableCell className={classes.statusColumn}>
                          Status
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Created By</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchedDataSets.map(eventDataSet => (
                        <TableRow key={eventDataSet.id}>
                          <TableCell className={classes.buttonCell}>
                            <Button
                              color="primary"
                              disabled={
                                processing ||
                                dataSetSessionCounts[eventDataSet.id] ===
                                  sessionIds.length
                              }
                              size="small"
                              variant="outlined"
                              className={classes.actionButton}
                              onClick={() => handleAddToDataSet(eventDataSet)}>
                              Add
                            </Button>
                          </TableCell>
                          <TableCell>
                            {sessionIds.length === 1 ? (
                              <>
                                {dataSetSessionCounts[eventDataSet.id] > 0 ? (
                                  <Label color={colors.green[600]}>
                                    Included
                                  </Label>
                                ) : (
                                  <Label color={colors.orange[600]}>
                                    Not Included
                                  </Label>
                                )}
                              </>
                            ) : (
                              <>
                                <Label
                                  color={
                                    dataSetSessionCounts[eventDataSet.id] ===
                                    sessionIds.length
                                      ? colors.green[600]
                                      : colors.orange[600]
                                  }>
                                  {dataSetSessionCounts[eventDataSet.id] ===
                                  undefined
                                    ? 0
                                    : dataSetSessionCounts[
                                        eventDataSet.id
                                      ]}{' '}
                                  of {sessionIds.length} Included
                                </Label>
                              </>
                            )}
                          </TableCell>
                          <TableCell>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={'/walks/data_sets/' + eventDataSet.id}
                              variant="h6"
                              target="_blank">
                              {eventDataSet.name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {eventDataSet.createdBy.displayName}
                          </TableCell>
                        </TableRow>
                      ))}
                      {!loading && searchedDataSets.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={4}>No data available</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </CardContent>
            </>
          )}
          <Divider />
          <CardActions className={classes.actions}>
            {existingDataSet && (
              <Button onClick={handleClose} variant="contained">
                Done
              </Button>
            )}
            {!existingDataSet && (
              <>
                <Button
                  disabled={creating}
                  onClick={handleClose}
                  variant="contained">
                  Cancel
                </Button>
                <Button
                  disabled={creating}
                  onClick={() =>
                    dispatch(
                      createFromEntityAssociations(
                        newDataSetName,
                        sessionIds,
                        entityType
                      )
                    )
                  }
                  color="primary"
                  variant="contained">
                  Create Walk Collection
                </Button>
              </>
            )}
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

SessionEventDataSetAdd.displayName = 'SessionGroups';

SessionEventDataSetAdd.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

SessionEventDataSetAdd.defaultProps = {
  open: false
};

export default SessionEventDataSetAdd;
