/* eslint-disable no-undef */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {},
  activeDisabled: {
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'inherit'
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'inherit'
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#e53935'
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,0.15)'
    },
    '& .MuiOutlinedInput-root.Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e53935'
    }
  },
  textFieldButtonContainer: {
    position: 'relative'
  },
  textFieldButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: 9
  }
}));

const isNumber = str => {
  // if (typeof str != "string") return false // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
};

const ThresholdSelection = props => {
  const {
    className,
    disabled,
    handleTorsoThresholdChange,
    handleTorsoThresholdChangeCommitted,
    torsoThreshold
  } = props;

  const classes = useStyles();

  return (
    <div className={clsx(className, classes.textFieldButtonContainer)}>
      <TextField
        className={classes.activeDisabled}
        fullWidth
        label="Torso Threshold"
        value={torsoThreshold ? torsoThreshold : ''}
        variant="outlined"
        onChange={event => handleTorsoThresholdChange(event.target.value)}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            handleTorsoThresholdChangeCommitted();
          }
        }}
      />
      <Button
        variant="outlined"
        disabled={disabled || !isNumber(torsoThreshold)}
        className={classes.textFieldButton}
        onClick={event =>
          handleTorsoThresholdChangeCommitted(event, torsoThreshold)
        }>
        Apply
      </Button>
    </div>
  );
};

ThresholdSelection.propTypes = {};

export default ThresholdSelection;
