import ws from '../utils/ws';

/* Actions */

export const findCustomers = () => dispatch =>
  dispatch({
    type: FIND_CUSTOMERS,
    payload: ws.get('/customers/')
  });

export const customerPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: CUSTOMERS_PAGINATED_LIST,
    payload: ws.get(`/customers/page/${pageNumber}/rows/${pageSize}`)
  });

export const customerValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CUSTOMER_VALUE_UPDATE,
    payload: values
  });

export const customerValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CUSTOMER_VALUE_RESET
  });

export const saveCustomer = customer => dispatch =>
  dispatch({
    type: SAVE_CUSTOMER,
    payload: ws.post('/customers/', customer)
  });

export const deleteCustomer = customer => dispatch =>
  dispatch({
    type: DELETE_CUSTOMER,
    payload: ws.post(`/customers/${customer.id}/delete`)
  });

export const editCustomer = id => dispatch =>
  dispatch({
    type: EDIT_CUSTOMER,
    payload: ws.get(`/customers/${id}`)
  });

export const findCustomer = id => dispatch =>
  dispatch({
    type: FIND_CUSTOMER,
    payload: ws.get(`/customers/${id}`)
  });

export const findCustomerCells = customerId => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_CELLS,
    payload: ws.get(`/customers/${customerId}/cells/`)
  });

export const findCustomerVenues = customerId => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_VENUES,
    payload: ws.get(`/customers/${customerId}/venues/`)
  });

export const findCustomerCustomerEvents = customerId => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_CUSTOMER_EVENTS,
    payload: ws.get(`/customers/${customerId}/customer_events/`)
  });

export const clearMasterVersionDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_CUSTOMER_DELETE_MESSAGE
  });

export const setCustomerDetailsNewVenue = venue => dispatch =>
  dispatch({
    type: SET_CUSTOMER_DETAILS_NEW_VENUE,
    payload: venue
  });

/* Constants */

export const FIND_CUSTOMER_CELLS = 'FIND_CUSTOMER_CELLS';
export const FIND_CUSTOMER_CELLS_PENDING = 'FIND_CUSTOMER_CELLS_PENDING';
export const FIND_CUSTOMER_CELLS_FULFILLED = 'FIND_CUSTOMER_CELLS_FULFILLED';

export const FIND_CUSTOMER_VENUES = 'FIND_CUSTOMER_VENUES';
export const FIND_CUSTOMER_VENUES_PENDING = 'FIND_CUSTOMER_VENUES_PENDING';
export const FIND_CUSTOMER_VENUES_FULFILLED = 'FIND_CUSTOMER_VENUES_FULFILLED';

export const FIND_CUSTOMER_CUSTOMER_EVENTS = 'FIND_CUSTOMER_CUSTOMER_EVENTS';
export const FIND_CUSTOMER_CUSTOMER_EVENTS_PENDING =
  'FIND_CUSTOMER_CUSTOMER_EVENTS_PENDING';
export const FIND_CUSTOMER_CUSTOMER_EVENTS_FULFILLED =
  'FIND_CUSTOMER_CUSTOMER_EVENTS_FULFILLED';

export const FIND_CUSTOMERS = 'FIND_CUSTOMERS';
export const FIND_CUSTOMERS_PENDING = 'FIND_CUSTOMERS_PENDING';
export const FIND_CUSTOMERS_FULFILLED = 'FIND_CUSTOMERS_FULFILLED';

export const CUSTOMERS_PAGINATED_LIST = 'CUSTOMERS_PAGINATED_LIST';
export const CUSTOMERS_PAGINATED_LIST_PENDING =
  'CUSTOMERS_PAGINATED_LIST_PENDING';
export const CUSTOMERS_PAGINATED_LIST_FULFILLED =
  'CUSTOMERS_PAGINATED_LIST_FULFILLED';

export const CREATE_CUSTOMER_VALUE_UPDATE = 'CREATE_CUSTOMERS_VALUE_UPDATE';
export const CREATE_CUSTOMER_VALUE_RESET = 'CREATE_CUSTOMER_VALUE_RESET';

export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const SAVE_CUSTOMER_PENDING = 'SAVE_CUSTOMER_PENDING';
export const SAVE_CUSTOMER_FULFILLED = 'SAVE_CUSTOMER_FULFILLED';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_PENDING = 'DELETE_CUSTOMER_PENDING';
export const DELETE_CUSTOMER_FULFILLED = 'DELETE_CUSTOMER_FULFILLED';

export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const EDIT_CUSTOMER_FULFILLED = 'EDIT_CUSTOMER_FULFILLED';

export const FIND_CUSTOMER = 'FIND_CUSTOMER';
export const FIND_CUSTOMER_PENDING = 'FIND_CUSTOMER_PENDING';
export const FIND_CUSTOMER_FULFILLED = 'FIND_CUSTOMER_FULFILLED';

export const CLEAR_CUSTOMER_DELETE_MESSAGE = 'CLEAR_CUSTOMER_DELETE_MESSAGE';

export const SET_CUSTOMER_DETAILS_NEW_VENUE = 'SET_CUSTOMER_DETAILS_NEW_VENUE';
