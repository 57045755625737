import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import { Comments } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  }
}));

const CommentsCard = props => {
  const {
    entityType,
    editableRight,
    entityId,
    comments,
    className,
    loading,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Comments" />
      <Divider />
      <CardContent className={classes.content}>
        <Comments {...props} />
      </CardContent>
    </Card>
  );
};

export default CommentsCard;
