import ws from '../utils/ws';

/* Actions */

export const setWalkSessionsOpen = open => dispatch =>
  dispatch({
    type: SET_WALK_SESSIONS_OPEN,
    payload: open
  });

export const setWalkSessionIds = sessionIds => dispatch =>
  dispatch({
    type: SET_WALK_SESSION_IDS,
    payload: sessionIds
  });

export const setWalkSessionSetupIndex = setupIndex => dispatch =>
  dispatch({
    type: SET_WALK_SESSION_SETUP_INDEX,
    payload: setupIndex
  });

export const findWalkSessionsFirstSession = sessionId => dispatch =>
  dispatch({
    type: FIND_WALK_SESSIONS_FIRST_SESSION,
    payload: ws.get('/sessions/' + sessionId)
  });

export const findSessionCellWalkCounts = (sessionIds, setupIndex) => dispatch =>
  dispatch({
    type: FIND_WALK_SESSIONS_CELL_WALK_COUNTS,
    payload: ws.get(
      `/sessions/session_cell_walk_counts/?sessionIds=${sessionIds.join(
        ','
      )}&setupIndex=${setupIndex}`
    )
  });

export const findWalkSessionsCells = sessionIds => dispatch =>
  dispatch({
    type: FIND_WALK_SESSIONS_CELLS,
    payload: ws.get(
      `/sessions/session_cells/?sessionIds=${sessionIds.join(',')}`
    )
  });

export const openSessions = (sessionIds, setupIndex) => dispatch =>
  dispatch({
    type: OPEN_SESSIONS,
    payload: ws.get(
      `/sessions/open_sessions/?sessionIds=${sessionIds.join(
        ','
      )}&setupIndex=${setupIndex}`
    )
  });

export const closeSessions = sessionIds => dispatch =>
  dispatch({
    type: CLOSE_SESSIONS,
    payload: ws.get(
      `/sessions/close_sessions/?sessionIds=${sessionIds.join(',')}`
    )
  });

/* Constants */

export const SET_WALK_SESSIONS_OPEN = 'SET_WALK_SESSIONS_OPEN';
export const SET_WALK_SESSION_IDS = 'SET_WALK_SESSION_IDS';
export const SET_WALK_SESSION_SETUP_INDEX = 'SET_WALK_SESSION_SETUP_INDEX';

export const FIND_WALK_SESSIONS_FIRST_SESSION =
  'FIND_WALK_SESSIONS_FIRST_SESSION';
export const FIND_WALK_SESSIONS_FIRST_SESSION_PENDING =
  'FIND_WALK_SESSIONS_FIRST_SESSION_PENDING';
export const FIND_WALK_SESSIONS_FIRST_SESSION_FULFILLED =
  'FIND_WALK_SESSIONS_FIRST_SESSION_FULFILLED';

export const FIND_WALK_SESSIONS_CELL_WALK_COUNTS =
  'FIND_WALK_SESSIONS_CELL_WALK_COUNTS';
export const FIND_WALK_SESSIONS_CELL_WALK_COUNTS_PENDING =
  'FIND_WALK_SESSIONS_CELL_WALK_COUNTS_PENDING';
export const FIND_WALK_SESSIONS_CELL_WALK_COUNTS_FULFILLED =
  'FIND_WALK_SESSIONS_CELL_WALK_COUNTS_FULFILLED';

export const FIND_WALK_SESSIONS_CELLS = 'FIND_WALK_SESSIONS_CELLS';
export const FIND_WALK_SESSIONS_CELLS_PENDING =
  'FIND_WALK_SESSIONS_CELLS_PENDING';
export const FIND_WALK_SESSIONS_CELLS_FULFILLED =
  'FIND_WALK_SESSIONS_CELLS_FULFILLED';

export const OPEN_SESSIONS = 'OPEN_SESSIONS';
export const OPEN_SESSIONS_PENDING = 'OPEN_SESSIONS_PENDING';
export const OPEN_SESSIONS_FULFILLED = 'OPEN_SESSIONS_FULFILLED';

export const CLOSE_SESSIONS = 'CLOSE_SESSIONS';
export const CLOSE_SESSIONS_PENDING = 'CLOSE_SESSIONS_PENDING';
export const CLOSE_SESSIONS_FULFILLED = 'CLOSE_SESSIONS_FULFILLED';
