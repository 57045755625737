import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 600,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  participantName: {
    marginBottom: theme.spacing(3)
  },
  participantNumber: {
    fontSize: '13em',
    lineHeight: '1em',
    marginBottom: -theme.spacing(3)
  }
}));

const ConfirmParticipantDetails = props => {
  const { open, onConfirm, participant, ...rest } = props;
  const classes = useStyles();

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onConfirm} open={open}>
      <Card {...rest} className={clsx(classes.root)}>
        <CardContent>
          <Typography
            align="center"
            gutterBottom
            variant="h3"
            className={classes.participantName}>
            {participant.name}
          </Typography>
          <Typography
            align="center"
            variant="h3"
            className={classes.participantNumber}>
            {participant.participantNumber}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            onClick={onConfirm}
            // className={classes.deleteButton}
            variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ConfirmParticipantDetails.propTypes = {
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  participant: PropTypes.object
};

ConfirmParticipantDetails.defaultProps = {
  open: false
};

export default ConfirmParticipantDetails;
