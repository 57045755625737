/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { TextField, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const SensitivityAdjustments = props => {
  const { adjustments, onChange } = props;

  const classes = useStyles();

  const handleFieldChange = (index, value) => {
    const edited = adjustments ? adjustments.slice() : [];
    edited[index] = value;
    onChange(edited);
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={6} item>
        <TextField
          className={classes.field}
          fullWidth
          label="Sensitivity 1"
          margin="dense"
          name="bias"
          onChange={event => handleFieldChange(0, event.target.value)}
          value={adjustments && adjustments[0] ? adjustments[0] : ''}
          variant="outlined"
        />
      </Grid>
      <Grid xs={6} item>
        <TextField
          className={classes.field}
          fullWidth
          label="Sensitivity 2"
          margin="dense"
          name="bias"
          onChange={event => handleFieldChange(1, event.target.value)}
          value={adjustments && adjustments[1] ? adjustments[1] : ''}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

SensitivityAdjustments.propTypes = {};

export default SensitivityAdjustments;
