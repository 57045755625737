import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { MODEL_SET_CALUCLATION_METHODS } from 'common/constants';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 550,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ModelSetModal = props => {
  const { onClose, modelSet } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!modelSet) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={modelSet !== null}>
      <Card className={classes.root}>
        <CardHeader title="Model Set" />
        <Divider />

        <CardContent className={classes.content}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: 160 }}>Name</TableCell>
                <TableCell>{modelSet.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Calculation Method</TableCell>
                <TableCell>
                  {MODEL_SET_CALUCLATION_METHODS[modelSet.calculationMethod]}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Model</TableCell>
                <TableCell>Weight</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modelSet.models.map(modelAssociation => (
                <TableRow key={modelAssociation.model.id}>
                  <TableCell>{modelAssociation.model.displayName}</TableCell>
                  <TableCell>{modelAssociation.weight * 100}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ModelSetModal.displayName = 'ModelSetModal';

ModelSetModal.propTypes = {
  className: PropTypes.string
};

export default ModelSetModal;
