import * as actionTypes from 'actions';

const initialState = {
  namedFaceWalkCounts: [],
  namedFaceWalkCountsLoading: false
};

const facialRecognitionOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_FACIAL_RECOGNITION_OVERVIEW_PENDING: {
      return {
        namedFaceWalkCounts: initialState.namedFaceWalkCounts,
        namedFaceWalkCountsLoading: true
      };
    }

    case actionTypes.FIND_FACIAL_RECOGNITION_OVERVIEW_FULFILLED: {
      return {
        namedFaceWalkCounts: action.payload.data.result,
        namedFaceWalkCountsLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default facialRecognitionOverviewReducer;
