import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  venueLoading: false,
  venue: {
    createdBy: {}
  },
  entityPhotos: [],
  cells: [],
  customerEvents: []
};

const venueDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_VENUE_PENDING: {
      return {
        ...initialState,
        venueLoading: true
      };
    }

    case actionTypes.FIND_VENUE_FULFILLED: {
      return {
        ...state,
        venueLoading: false,
        venue: action.payload.data.result
      };
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        venueLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    case actionTypes.FIND_VENUE_CELLS_PENDING: {
      return {
        ...state,
        cells: initialState.cells
      };
    }

    case actionTypes.FIND_VENUE_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result
      };
    }

    case actionTypes.FIND_VENUE_CUSTOMER_EVENTS_PENDING: {
      return {
        ...state,
        customerEvents: initialState.customerEvents
      };
    }

    case actionTypes.FIND_VENUE_CUSTOMER_EVENTS_FULFILLED: {
      return {
        ...state,
        customerEvents: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.VENUE &&
        (entityPhoto.entityId === state.venue.id ||
          entityPhoto.entityTempId === state.venue.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.VENUE &&
        (entityPhoto.entityId === state.venue.id ||
          entityPhoto.entityTempId === state.venue.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.VENUE &&
        (entityPhoto.entityId === state.venue.id ||
          entityPhoto.entityTempId === state.venue.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    default: {
      return state;
    }
  }
};

export default venueDetailsReducer;
