import * as actionTypes from 'actions';

const initialState = {
  processing: false,
  entityDetailsSet: false,
  entityPhoto: {
    comment: '',
    file: null
  },
  open: false
};

const entityPhotoUploaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ENTITY_PHOTO_UPLOAD_VALUES: {
      return {
        ...state,
        entityPhoto: {
          ...state.entityPhoto,
          ...action.payload
        }
      };
    }

    case actionTypes.CLEAR_ENTITY_PHOTO_UPLOAD_VALUES: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      return {
        ...state,
        entityPhoto: {
          ...initialState.entityPhoto
        },
        entityDetailsSet: false,
        open: false,
        processing: false
      };
    }

    case actionTypes.SET_ENTITY_PHOTO_UPLOADER_OPEN: {
      return {
        ...state,
        open: action.payload
      };
    }

    case actionTypes.SET_ENTITY_PHOTO_UPLOAD_ENTITY_DETAILS: {
      return {
        ...state,
        entityPhoto: {
          ...state.entityPhoto,
          ...action.payload
        },
        entityDetailsSet: true
      };
    }

    default: {
      return state;
    }
  }
};

export default entityPhotoUploaderReducer;
