import * as actionTypes from 'actions';

const initialState = {
  processing: false,
  saved: false,
  open: false,
  ticket: {
    subject: '',
    content: ''
  },
  validationMessages: {}
};

const createTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_CREATE_TICKET_OPEN: {
      return {
        ...initialState,
        open: !state.open,
        cell: action.payload.cell
      };
    }

    case actionTypes.CREATE_TICKET_VALUE_CHANGE: {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          ...action.payload
        }
      };
    }

    case actionTypes.SAVE_CELL_TICKET_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.SAVE_CELL_TICKET_FULFILLED: {
      return {
        ...state,
        processing: false,
        saved: action.payload.data.success,
        validationMessages: action.payload.data.validationMessages
      };
    }

    default: {
      return state;
    }
  }
};

export default createTicketReducer;
