import * as actionTypes from 'actions';

const initialState = {
  sessionGroupLoading: false,
  sessionGroup: {
    createdBy: {}
  },
  sessionsLoading: false,
  sessions: [],
  customerEventsLoading: false,
  customerEvents: [],
  testingAnalysisOverview: {
    loading: false,
    analysisOverview: {},
    setupAnalysesCount: 0,
    eventCount: 0,
    noObjectEventCount: 0,
    locationEventCounts: {},
    subModels: []
  },
  commentsEvent: {
    comments: []
  },
  events: {
    pageNumber: 1,
    rowCount: 0,
    loading: false,
    items: [],
    pageSize: 250,
    filterItemCount: null,
    filter: {
      objectPresent: false,
      objectNotPresent: false,
      columnSorts: [],
      specialInterest: null,
      predictionCorrect: null,
      predictionFalsePositive: null,
      predictionFalseNegative: null,
      dataPointId: '',
      fileName: '',
      objectPercent: [0, 0],
      objectLeftPercent: [0, 0],
      objectRightPercent: [0, 0]
    }
  },
  analysisBiases: [],
  modelConfiguration: null,
  cell: null
};

const EVENT_LIST_PAGE = 'SESSION_GROUP_DETAILS_EVENT_LIST';

const sessionGroupDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_SESSION_GROUP_PENDING: {
      return {
        ...initialState,
        sessionGroupLoading: true
      };
    }

    case actionTypes.FIND_SESSION_GROUP_FULFILLED: {
      return {
        ...state,
        sessionGroupLoading: false,
        sessionGroup: action.payload.data.result
      };
    }

    case actionTypes.FIND_GROUP_SESSIONS_PENDING: {
      return {
        ...state,
        sessionsLoading: true,
        sessions: []
      };
    }

    case actionTypes.FIND_GROUP_SESSIONS_FULFILLED: {
      return {
        ...state,
        sessionsLoading: false,
        sessions: action.payload.data.result
      };
    }

    case actionTypes.FIND_GROUP_CUSTOMER_EVENTS_PENDING: {
      return {
        ...state,
        customerEventsLoading: true,
        customerEvents: []
      };
    }

    case actionTypes.FIND_GROUP_CUSTOMER_EVENTS_FULFILLED: {
      return {
        ...state,
        customerEventsLoading: false,
        customerEvents: action.payload.data.result
      };
    }

    case actionTypes.FIND_SESSION_GROUP_ANALYSIS_OVERVIEW_PENDING: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...initialState.testingAnalysisOverview,
          loading: true
        }
      };
    }

    case actionTypes.FIND_SESSION_GROUP_ANALYSIS_OVERVIEW_FULFILLED: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...action.payload.data.result,
          loading: false
        }
      };
    }

    case actionTypes.SET_SESSION_GROUP_DETAILS_ANALYSIS_BIAS: {
      const { bias, subModel } = action.payload;

      const analysisBiases = state.analysisBiases.slice();
      analysisBiases[subModel] = bias;

      return {
        ...state,
        analysisBiases: analysisBiases
      };
    }

    case actionTypes.SET_SESSION_GROUP_DETAILS_MODEL_CONFIGURATION: {
      return {
        ...state,
        modelConfiguration: action.payload
      };
    }

    case actionTypes.SET_SESSION_GROUP_DETAILS_CELL: {
      return {
        ...state,
        cell: action.payload
      };
    }

    case actionTypes.SET_SESSION_GROUP_DETAILS_CALCULATE_MAX_ACCURACY_SUM_BIAS: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...state.testingAnalysisOverview,
          calculateOptimalAccuracySumBias: action.payload
        }
      };
    }

    case actionTypes.SESSION_GROUPS_EVENT_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        events: {
          ...state.events,
          loading: true
        }
      };
    }

    case actionTypes.SESSION_GROUPS_EVENT_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        events: {
          ...action.payload.data.result,
          pageSize: state.events.pageSize,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.events.filter,
            ...action.payload.data.result.filter
          },
          loading: false
        }
      };
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === EVENT_LIST_PAGE) {
        result = {
          ...state,
          events: {
            ...state.events,
            filter: {
              ...initialState.events.filter,
              columnSorts: state.events.filter.columnSorts.slice(),
              search: state.events.filter.search
            }
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === EVENT_LIST_PAGE
      ) {
        result = {
          ...state,
          events: {
            ...state.events,
            filterItemCount: action.payload.data.result.filter.filterItemCount,
            filter: {
              ...initialState.events.filter,
              ...action.payload.data.result.filter
            }
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === EVENT_LIST_PAGE) {
        result = {
          ...state,
          events: {
            ...state.events,
            filter: {
              ...state.events.filter,
              ...action.payload.value
            }
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === EVENT_LIST_PAGE) {
        result = {
          ...state,
          events: {
            ...state.events,
            pageSize: action.payload.pageSize
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    default: {
      return state;
    }
  }
};

export default sessionGroupDetailsReducer;
