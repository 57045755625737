import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedCellBuildDefinition: {},
  deleted: false,
  deleteMessage: null,
  deletedCellBuildDefinition: {},
  cellBuildDefinition: {
    creationId: null,
    name: '',
    description: '',
    type: 'SOFTWARE'
  },
  customers: [],
  validationMessages: {},
  expandedBuildComponentIds: [],
  searchResultBuildComponentIds: [],
  buildComponentValidationMessages: {},
  confirmDeleteBuildComponent: null,
  componentSearch: '',
  editBuildComponent: null,
  createCellBuildDefinitionOpen: false,
  createCellBuildDefinition: {},
  cellModelVersions: [],
  cellBuildDefinitions: [],
  confirmDeleteCellBuildDefinition: null
};

const cellBuildDefinitionCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CREATE_CELL_BUILD_DEFINITION_OPEN: {
      return {
        ...state,
        createCellBuildDefinitionOpen: action.payload,
        createCellBuildDefinition: initialState.createCellBuildDefinition
      };
    }

    case actionTypes.FIND_CELL_MODEL_VERSIONS_FULFILLED: {
      return {
        ...state,
        cellModelVersions: action.payload.data.result
      };
    }

    case actionTypes.FIND_CELL_BUILD_DEFINITIONS_FULFILLED: {
      return {
        ...state,
        cellBuildDefinitions: action.payload.data.result
      };
    }

    case actionTypes.TOGGLE_CELL_BUILD_COMPONENT_EXPANDED: {
      const componentId = action.payload;
      const expandedBuildComponentIds = state.expandedBuildComponentIds.slice();

      if (expandedBuildComponentIds.indexOf(componentId) > -1) {
        expandedBuildComponentIds.splice(
          expandedBuildComponentIds.indexOf(componentId),
          1
        );
      } else {
        expandedBuildComponentIds.push(componentId);
      }

      return {
        ...state,
        expandedBuildComponentIds: expandedBuildComponentIds
      };
    }

    case actionTypes.SET_CONFIRM_DELETE_BUILD_DEFINITION_COMPONENT: {
      return {
        ...state,
        confirmDeleteBuildComponent: action.payload
      };
    }

    case actionTypes.SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT: {
      let editBuildComponent;
      if (action.payload) {
        const { childComponents, ...rest } = action.payload;
        editBuildComponent = {
          ...rest
        };
      } else {
        editBuildComponent = null;
      }

      return {
        ...state,
        editBuildComponent: editBuildComponent,
        buildComponentValidationMessages:
          initialState.buildComponentValidationMessages
      };
    }

    case actionTypes.SET_CREATE_CHILD_BUILD_DEFINITION_COMPONENT: {
      const parentBuildComponent = action.payload;
      const expandedBuildComponentIds = state.expandedBuildComponentIds.slice();

      if (
        state.expandedBuildComponentIds.indexOf(parentBuildComponent.guid) < 0
      ) {
        expandedBuildComponentIds.push(parentBuildComponent.guid);
      }

      return {
        ...state,
        editBuildComponent: {
          creationParentGuid: parentBuildComponent.guid
        },
        expandedBuildComponentIds: expandedBuildComponentIds
      };
    }

    case actionTypes.SAVE_CELL_BUILD_DEFINITION_COMPONENT_FULFILLED: {
      return {
        ...state,
        cellBuildDefinition: action.payload.data.result,
        editBuildComponent: action.payload.data.success
          ? initialState.editBuildComponent
          : state.editBuildComponent,
        buildComponentValidationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CELL_BUILD_DEFINITION_COMPONENT_FULFILLED: {
      return {
        ...state,
        cellBuildDefinition: action.payload.data.result,
        editBuildComponent: action.payload.data.success
          ? initialState.editBuildComponent
          : state.editBuildComponent,
        buildComponentValidationMessages:
          action.payload.data.validationMessages,
        confirmDeleteBuildComponent: action.payload.data.success
          ? initialState.confirmDeleteBuildComponent
          : state.confirmDeleteBuildComponent
      };
    }

    case actionTypes.SET_CREATE_BUILD_DEFINITION_EDIT_COMPONENT_FIELD_VALUE: {
      const { field, value } = action.payload;

      return {
        ...state,
        editBuildComponent: {
          ...state.editBuildComponent,
          [field]: value
        }
      };
    }

    case actionTypes.SET_CONFIRM_DELETE_CELL_BUILD_DEFINITION: {
      return {
        ...state,
        confirmDeleteCellBuildDefinition: action.payload
      };
    }

    case actionTypes.DELETE_CELL_BUILD_DEFINITION_FULFILLED: {
      return {
        ...state,
        confirmDeleteCellBuildDefinition:
          initialState.confirmDeleteCellBuildDefinition,
        deleted: true
      };
    }

    case actionTypes.SET_CREATE_BUILD_DEFINITION_FIELD_VALUE: {
      const { field, value } = action.payload;

      return {
        ...state,
        createCellBuildDefinition: {
          ...state.createCellBuildDefinition,
          [field]: value
        }
      };
    }

    case actionTypes.TOGGLE_CELL_BUILD_COMPONENTS_EXPANDED: {
      return {
        ...state,
        expandedBuildComponentIds: action.payload
      };
    }

    case actionTypes.SET_SEARCH_RESULT_CELL_BUILD_COMPONENTS: {
      return {
        ...state,
        searchResultBuildComponentIds: action.payload
      };
    }

    case actionTypes.SET_CELL_BUILD_COMPONENT_SEARCH: {
      return {
        ...state,
        componentSearch: action.payload
      };
    }

    case actionTypes.CREATE_CELL_BUILD_DEFINITION_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedCellBuildDefinition: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CELL_BUILD_DEFINITION_PENDING: {
      return {
        ...state,
        deleted: initialState.deleted,
        deletedCellBuildDefinition: initialState.deletedCellBuildDefinition
      };
    }

    case actionTypes.DELETE_CELL_BUILD_DEFINITION_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deleteMessage: action.payload.data.message,
        deletedCellBuildDefinition: action.payload.data.result
      };
    }

    case actionTypes.EDIT_CELL_BUILD_DEFINITION_FULFILLED: {
      return {
        ...state,
        cellBuildDefinition: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_CELL_BUILD_DEFINITION_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_CELL_BUILD_DEFINITION_VALUE_UPDATE: {
      return {
        ...state,
        cellBuildDefinition: {
          ...state.cellBuildDefinition,
          ...action.payload
        }
      };
    }

    case actionTypes.CLEAR_CELL_BUILD_DEFINITION_DELETE_MESSAGE: {
      return {
        ...state,
        deleteMessage: initialState.deleteMessage
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
};

export default cellBuildDefinitionCreateReducer;
