import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import {
  objectModelValueUpdate,
  objectModelValueReset,
  saveObjectModel,
  findObjectModels
} from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const CreateObjectModel = props => {
  const { open, onClose, objectType, className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { saved, values, validationMessages, loading } = useSelector(
    state => state.objectModelCreate
  );

  const handleClose = () => {
    dispatch(objectModelValueReset());
    onClose();
  };

  const handleFieldChange = event => {
    event && event.persist && event.persist();

    dispatch(
      objectModelValueUpdate(values => ({
        ...values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      }))
    );
  };

  if (saved) {
    dispatch(findObjectModels());
    dispatch(objectModelValueReset());
    onClose();
  }

  const handleCreateObjectModel = () => {
    dispatch(saveObjectModel(values, objectType));
  };

  if (!open || loading) {
    return null;
  }

  const create = true;

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography align="center" gutterBottom variant="h3">
            {create ? 'New' : 'Edit'} Object Model
          </Typography>

          {objectType && (
            <Typography align="center" gutterBottom variant="h4">
              {objectType.name}
            </Typography>
          )}

          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  required={true}
                  error={validationMessages.hasOwnProperty('name')}
                  helperText={validationMessages.name}
                  fullWidth
                  label="Name (e.g. MP9 SHIELD EZ, iPhone 11 Pro Max)"
                  name="name"
                  onChange={event =>
                    handleFieldChange(event, 'name', event.target.value)
                  }
                  value={values.name}
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
          <Button
            className={classes.saveButton}
            onClick={handleCreateObjectModel}
            variant="contained">
            Save
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

// CreateObjectType.propTypes = {
//   className: PropTypes.string,
//   customer: PropTypes.any,
//   onClose: PropTypes.func,
//   open: PropTypes.bool,
//   createdSession: PropTypes.object
// };

CreateObjectModel.defaultProps = {
  open: false
};

export default CreateObjectModel;
