/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { SESSION_WALK_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';
import CheckedIcon from '@material-ui/icons/CheckBox';

const SessionWalkStatus = props => {
  const { status, className, ...rest } = props;

  return (
    <div className={className}>
      {status === 'COMPLETE' && (
        <CheckedIcon
          style={{ color: colors.green[600], width: 20, height: 20 }}
        />
      )}
    </div>
  );
};

SessionWalkStatus.propTypes = {
  status: PropTypes.string
};

export default SessionWalkStatus;
