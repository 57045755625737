import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();

// import React from 'react';
// import ReactDOM from 'react-dom';

// import App from './App';
// import * as serviceWorker from './serviceWorker';
// import { GoogleOAuthProvider } from "@react-oauth/google"

// ReactDOM.render(
//   <GoogleOAuthProvider clientId="22289539476-s5tuv7qa7i2dpfkb5mnnrahumd58ec7r.apps.googleusercontent.com">
//     <App />
//   </GoogleOAuthProvider>,
//   document.getElementById('root')
// );

// serviceWorker.unregister();
