import ws from '../utils/ws';

/* Actions */

export const toggleCreateTicketOpen = cell => dispatch =>
  dispatch({
    type: TOGGLE_CREATE_TICKET_OPEN,
    payload: {
      cell: cell ? cell : null
    }
  });

export const createTicketValueChange = (field, value) => dispatch =>
  dispatch({
    type: CREATE_TICKET_VALUE_CHANGE,
    payload: {
      [field]: value
    }
  });

export const saveCellTicket = (cellId, ticket) => dispatch =>
  dispatch({
    type: SAVE_CELL_TICKET,
    payload: ws.post(`/cells/${cellId}/tickets/`, ticket)
  });

/* Action Constants */

export const TOGGLE_CREATE_TICKET_OPEN = 'TOGGLE_CREATE_TICKET_OPEN';
export const CREATE_TICKET_VALUE_CHANGE = 'CREATE_TICKET_VALUE_CHANGE';

export const SAVE_CELL_TICKET = 'SAVE_CELL_TICKET';
export const SAVE_CELL_TICKET_PENDING = 'SAVE_CELL_TICKET_PENDING';
export const SAVE_CELL_TICKET_FULFILLED = 'SAVE_CELL_TICKET_FULFILLED';
