import React from 'react';
import PropTypes from 'prop-types';

import { NumericFormat } from 'react-number-format';

const CustomNumberFormat = props => {
  const {
    value,
    suffix,
    prefix,
    percentage,
    precision,
    fixedDecimalScale,
    zeroNulls
  } = props;
  return !zeroNulls && (value === null || value === undefined) ? (
    ''
  ) : (
    <NumericFormat
      value={
        percentage
          ? value * 100.0
          : value === null || value === undefined
          ? 0
          : value
      }
      displayType={'text'}
      decimalScale={precision !== undefined ? precision : 2}
      fixedDecimalScale={fixedDecimalScale}
      thousandSeparator={true}
      prefix={prefix}
      suffix={suffix}
    />
  );
};

CustomNumberFormat.propTypes = {
  value: PropTypes.number,
  suffix: PropTypes.string,
  prefix: PropTypes.string
};

export default CustomNumberFormat;
