import * as actionTypes from 'actions';

const initialState = {
  pageNumber: 1,
  rowCount: 0,
  loading: false,
  items: [],
  pageSize: 250,
  filterItemCount: null,
  filter: {
    search: ''
  },
  allCustomerEventsSelected: false,
  selectingCustomerEvents: false,
  selectedCustomerEventIds: [],
  customers: [],
  customersLoading: false,
  calendarFilter: {},
  calendarEvents: [],
  calendarEventsLoading: false
};

const PAGE = 'CUSTOMER_EVENT_LIST';

const customerEventListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMER_EVENTS_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CUSTOMER_EVENTS_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        filter: {
          ...initialState.filter,
          ...action.payload.data.result.filter
        },
        loading: false
      };
    }
    case actionTypes.CUSTOMER_EVENT_CALENDAR_LIST_PENDING: {
      return {
        ...state,
        calendarEventsLoading: true
      };
    }

    case actionTypes.CUSTOMER_EVENT_CALENDAR_LIST_FULFILLED: {
      return {
        ...state,
        calendarEvents: action.payload.data.result.items,
        calendarFilter: {
          ...initialState.calendarFilter,
          ...action.payload.data.result.filter
        },
        calendarEventsLoading: false
      };
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          pageSize: action.payload.pageSize
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers,
        customersLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data,
        customersLoading: false
      };
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === PAGE) {
        result = {
          ...state,
          filter: {
            ...initialState.filter,
            search: state.filter.search
          },
          filterItemCount: initialState.filterItemCount
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === PAGE
      ) {
        result = {
          ...state,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.filter,
            ...action.payload.data.result.filter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SELECT_CUSTOMER_EVENT_LIST_EVENT: {
      const customerEventId = action.payload;
      const customerEventIds = state.selectedCustomerEventIds.slice();
      const customerEventIdIndex = customerEventIds.indexOf(customerEventId);

      if (customerEventIdIndex !== -1) {
        customerEventIds.splice(customerEventIdIndex, 1);
      } else {
        customerEventIds.push(customerEventId);
      }

      return {
        ...state,
        selectedCustomerEventIds: customerEventIds
      };
    }

    case actionTypes.SELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS_PENDING: {
      return {
        ...state,
        selectingCustomerEvents: true
      };
    }

    case actionTypes.SELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS_FULFILLED: {
      return {
        ...state,
        selectedCustomerEventIds: action.payload.data.result,
        selectingCustomerEvents: false,
        allCustomerEventsSelected: true
      };
    }

    case actionTypes.DESELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS: {
      return {
        ...state,
        selectedCustomerEventIds: initialState.selectedCustomerEventIds,
        selectingCustomerEvents: false,
        allCustomerEventsSelected: false
      };
    }

    case actionTypes.CLEAR_CUSTOMER_EVENT_LIST_SELECTED_EVENTS: {
      return {
        ...state,
        selectedCustomerEventIds: initialState.selectedCustomerEventIds,
        allCustomerEventsSelected: false
      };
    }

    default: {
      return state;
    }
  }
};

export default customerEventListReducer;
