import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  },
  clearButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Search = props => {
  const {
    disabled,
    onSearch,
    onClearSearch,
    className,
    hideSearchButton,
    value,
    onSearchValueChange,
    placeholder,
    autoFocus,
    ...rest
  } = props;

  const classes = useStyles();

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Paper
        className={classes.search}
        elevation={1}
        style={{ backgroundColor: disabled ? '#F4F6F8' : 'white' }}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          disabled={disabled}
          autoFocus={autoFocus}
          value={value}
          onChange={e => onSearchValueChange(e.target.value)}
          onKeyUp={handleKeyUp}
          className={classes.searchInput}
          disableUnderline
          placeholder={placeholder ? placeholder : 'Search'}
        />
      </Paper>
      {!hideSearchButton && (
        <>
          <Button
            className={classes.searchButton}
            onClick={onSearch}
            size="large"
            variant="contained">
            Search
          </Button>
          <Button
            className={classes.clearButton}
            onClick={onClearSearch}
            size="large"
            variant="contained">
            Clear
          </Button>
        </>
      )}
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func
};

export default Search;
