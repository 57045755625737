import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  savedCellCheckListDefinition: {},
  deleted: false,
  deleteMessage: null,
  deletedCellCheckListDefinition: {},
  cellCheckListDefinition: {
    creationId: null,
    name: '',
    description: '',
    type: 'SOFTWARE'
  },
  customers: [],
  validationMessages: {},
  expandedBuildComponentIds: [],
  searchResultBuildComponentIds: [],
  checkListItemGroupValidationMessages: {},
  checkListItemValidationMessages: {},
  confirmDeleteCheckListItemGroup: null,
  confirmDeleteCheckListItem: null,
  componentSearch: '',
  editCheckListItemGroup: null,
  editCheckListItem: null,
  createCellCheckListDefinitionOpen: false,
  createCellCheckListDefinition: {},
  cellModelVersions: [],
  cellCheckListDefinitions: [],
  confirmDeleteCellCheckListDefinition: null
};

const cellCheckListDefinitionCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CREATE_CELL_CHECK_LIST_DEFINITION_OPEN: {
      return {
        ...state,
        createCellCheckListDefinitionOpen: action.payload,
        createCellCheckListDefinition:
          initialState.createCellCheckListDefinition
      };
    }

    case actionTypes.FIND_CELL_MODEL_VERSIONS_FULFILLED: {
      return {
        ...state,
        cellModelVersions: action.payload.data.result
      };
    }

    case actionTypes.FIND_CELL_CHECK_LIST_DEFINITIONS_FULFILLED: {
      return {
        ...state,
        cellCheckListDefinitions: action.payload.data.result
      };
    }

    case actionTypes.TOGGLE_CELL_BUILD_COMPONENT_EXPANDED: {
      const componentId = action.payload;
      const expandedBuildComponentIds = state.expandedBuildComponentIds.slice();

      if (expandedBuildComponentIds.indexOf(componentId) > -1) {
        expandedBuildComponentIds.splice(
          expandedBuildComponentIds.indexOf(componentId),
          1
        );
      } else {
        expandedBuildComponentIds.push(componentId);
      }

      return {
        ...state,
        expandedBuildComponentIds: expandedBuildComponentIds
      };
    }

    case actionTypes.SET_CONFIRM_DELETE_CHECK_LIST_ITEM_GROUP: {
      return {
        ...state,
        confirmDeleteCheckListItemGroup: action.payload
      };
    }

    case actionTypes.SET_CONFIRM_DELETE_CHECK_LIST_ITEM: {
      return {
        ...state,
        confirmDeleteCheckListItem: action.payload
      };
    }

    case actionTypes.SET_CREATE_CHECK_LIST_ITEM_GROUP: {
      return {
        ...state,
        editCheckListItemGroup: action.payload
      };
    }

    case actionTypes.SET_CREATE_CHECK_LIST_ITEM: {
      return {
        ...state,
        editCheckListItem: action.payload
      };
    }

    case actionTypes.SAVE_CELL_CHECK_LIST_ITEM_GROUP_FULFILLED: {
      return {
        ...state,
        cellCheckListDefinition: action.payload.data.result,
        editCheckListItemGroup: action.payload.data.success
          ? initialState.editCheckListItemGroup
          : state.editCheckListItemGroup,
        checkListItemGroupValidationMessages:
          action.payload.data.validationMessages
      };
    }

    case actionTypes.SAVE_CELL_CHECK_LIST_ITEM_FULFILLED: {
      return {
        ...state,
        cellCheckListDefinition: action.payload.data.result,
        editCheckListItem: action.payload.data.success
          ? initialState.editCheckListItem
          : state.editCheckListItem,
        checkListItemValidationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CELL_CHECK_LIST_ITEM_GROUP_FULFILLED: {
      return {
        ...state,
        cellCheckListDefinition: action.payload.data.result,
        editCheckListItemGroup: action.payload.data.success
          ? initialState.editCheckListItemGroup
          : state.editCheckListItemGroup,
        checkListItemGroupValidationMessages:
          action.payload.data.validationMessages,
        confirmDeleteCheckListItemGroup: action.payload.data.success
          ? initialState.confirmDeleteCheckListItemGroup
          : state.confirmDeleteCheckListItemGroup
      };
    }

    case actionTypes.DELETE_CELL_CHECK_LIST_ITEM_FULFILLED: {
      return {
        ...state,
        cellCheckListDefinition: action.payload.data.result,
        editCheckListItem: action.payload.data.success
          ? initialState.editCheckListItem
          : state.editCheckListItem,
        checkListItemValidationMessages: action.payload.data.validationMessages,
        confirmDeleteCheckListItem: action.payload.data.success
          ? initialState.confirmDeleteCheckListItem
          : state.confirmDeleteCheckListItem
      };
    }

    case actionTypes.SET_CREATE_CHECK_LIST_DEFINITION_EDIT_CHECK_LIST_ITEM_GROUP_FIELD_VALUE: {
      const { field, value } = action.payload;

      return {
        ...state,
        editCheckListItemGroup: {
          ...state.editCheckListItemGroup,
          [field]: value
        }
      };
    }

    case actionTypes.SET_CREATE_CHECK_LIST_DEFINITION_EDIT_CHECK_LIST_ITEM_FIELD_VALUE: {
      const { field, value } = action.payload;

      return {
        ...state,
        editCheckListItem: {
          ...state.editCheckListItem,
          [field]: value
        }
      };
    }

    case actionTypes.SET_CONFIRM_DELETE_CELL_CHECK_LIST_DEFINITION: {
      return {
        ...state,
        confirmDeleteCellCheckListDefinition: action.payload
      };
    }

    case actionTypes.DELETE_CELL_CHECK_LIST_DEFINITION_FULFILLED: {
      return {
        ...state,
        confirmDeleteCellCheckListDefinition:
          initialState.confirmDeleteCellCheckListDefinition,
        deleted: true
      };
    }

    case actionTypes.SET_CREATE_BUILD_DEFINITION_FIELD_VALUE: {
      const { field, value } = action.payload;

      return {
        ...state,
        createCellCheckListDefinition: {
          ...state.createCellCheckListDefinition,
          [field]: value
        }
      };
    }

    case actionTypes.TOGGLE_CELL_BUILD_COMPONENTS_EXPANDED: {
      return {
        ...state,
        expandedBuildComponentIds: action.payload
      };
    }

    case actionTypes.SET_SEARCH_RESULT_CELL_BUILD_COMPONENTS: {
      return {
        ...state,
        searchResultBuildComponentIds: action.payload
      };
    }

    case actionTypes.SET_CELL_BUILD_COMPONENT_SEARCH: {
      return {
        ...state,
        componentSearch: action.payload
      };
    }

    case actionTypes.SAVE_CELL_CHECK_LIST_DEFINITION_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedCellCheckListDefinition: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.CREATE_CELL_CHECK_LIST_DEFINITION_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedCellCheckListDefinition: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_CELL_CHECK_LIST_DEFINITION_PENDING: {
      return {
        ...state,
        deleted: initialState.deleted,
        deletedCellCheckListDefinition:
          initialState.deletedCellCheckListDefinition
      };
    }

    case actionTypes.DELETE_CELL_CHECK_LIST_DEFINITION_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deleteMessage: action.payload.data.message,
        deletedCellCheckListDefinition: action.payload.data.result
      };
    }

    case actionTypes.EDIT_CELL_CHECK_LIST_DEFINITION_FULFILLED: {
      return {
        ...state,
        cellCheckListDefinition: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_CELL_CHECK_LIST_DEFINITION_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_CELL_CHECK_LIST_DEFINITION_VALUE_UPDATE: {
      return {
        ...state,
        cellCheckListDefinition: {
          ...state.cellCheckListDefinition,
          ...action.payload
        }
      };
    }

    case actionTypes.CLEAR_CELL_CHECK_LIST_DEFINITION_DELETE_MESSAGE: {
      return {
        ...state,
        deleteMessage: initialState.deleteMessage
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
};

export default cellCheckListDefinitionCreateReducer;
