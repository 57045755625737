import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { GENDERS } from 'common/constants';
import { Search } from 'components/SearchBar/components';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid
} from '@material-ui/core';
import {
  setSelectCellComponentVersionOpen,
  setSelectCellComponentVersionSearch
} from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 850,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  wrapCell: {
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  link: {
    display: 'block',
    maxWidth: 120,
    width: 120,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  buttonCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  buttonExtraSmall: {
    padding: '1px 8px',
    fontSize: '0.8125rem',
    marginTop: 15
  },
  table: {
    tableLayout: 'fixed',
    '& td': {
      verticalAlign: 'top'
    }
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  search: {
    minWidth: 480,
    maxWidth: 480,
    flexBasis: 480
  },
  tableContainer: {
    minHeight: 450,
    maxHeight: 450,
    overflowY: 'auto'
  },
  searchContainer: {
    padding: theme.spacing(2)
  },
  buttonColumn: {
    width: 100
  }
}));

const CellComponentVersionSelect = props => {
  const { onSelectVersion, className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, cellComponent, search } = useSelector(
    state => state.cellComponentVersionSelect
  );

  const versions =
    cellComponent && cellComponent.versions ? cellComponent.versions : [];

  const searchedVersions = versions.filter(version => {
    return (
      search === '' ||
      version.version.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
      version.description.toUpperCase().indexOf(search.toUpperCase()) !== -1
    );
  });

  const handleClose = () => {
    dispatch(setSelectCellComponentVersionOpen(false));
  };

  const handleSearchFieldChange = value => {
    dispatch(setSelectCellComponentVersionSearch(value));
  };

  const handleSelectVersion = (cellComponent, version) => {
    onSelectVersion(cellComponent, version);
    handleClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Select Version" />
        <Divider />

        <CardContent className={classes.content}>
          <div className={classes.searchContainer}>
            <Grid container spacing={3}>
              <Grid item>
                <Search
                  placeholder="Version"
                  value={search}
                  onSearchValueChange={handleSearchFieldChange}
                  className={classes.search}
                  hideSearchButton={true}
                />
              </Grid>
            </Grid>
          </div>
          <Divider />

          <div className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Version</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>JIRA Link</TableCell>
                  <TableCell className={classes.buttonColumn} />
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedVersions.map(version => (
                  <TableRow key={version.version}>
                    <TableCell className={classes.wrapCell}>
                      {version.version}
                    </TableCell>
                    <TableCell className={classes.wrapCell}>
                      {version.description}
                    </TableCell>
                    <TableCell>
                      {moment(version.createdTime).format(
                        'MMM Do YYYY, h:mm:ss a'
                      )}
                    </TableCell>
                    <TableCell>
                      {version.jiraLink && (
                        <a
                          className={classes.link}
                          target="_blank"
                          href={version.jiraLink}>
                          {version.jiraLink}
                        </a>
                      )}
                    </TableCell>
                    <TableCell className={classes.buttonCell}>
                      <Button
                        className={classes.buttonExtraSmall}
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleSelectVersion(cellComponent, version)
                        }>
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {searchedVersions.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={10}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CellComponentVersionSelect.displayName = 'VersionSelect';

CellComponentVersionSelect.propTypes = {
  className: PropTypes.string
};

export default CellComponentVersionSelect;
