/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const TrainingEventValidationStatusCount = props => {
  const { status, count, ...rest } = props;

  const statusColors = {
    VERIFIED: colors.green[600],
    SUSPECT: colors.red[600],
    TESTING: colors.blueGrey[600],
    PENDING: colors.blue[600]
  };

  return (
    <div>
      {status && (
        <Label
          {...rest}
          // variant="outlined"
          color={statusColors[status]}>
          {count === null ? 0 : count}
        </Label>
      )}
    </div>
  );
};

TrainingEventValidationStatusCount.propTypes = {
  status: PropTypes.string,
  count: PropTypes.number
};

export default TrainingEventValidationStatusCount;
