import { colors } from '@material-ui/core';

export default {
  root: {
    borderRadius: 0,
    overflow: 'hidden'
  },
  colorPrimary: {
    backgroundColor: colors.blueGrey[50]
  },
  barColorPrimary: {
    backgroundColor: '#1A59B4'
  }
};
