import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { NumberFormat } from 'components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  colors,
  TableHead
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableContainer: {
    padding: 0,
    '& td': {
      borderWidth: 0
    }
  },
  setupInfo: {
    '& td': {
      width: '25%',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: colors.grey[200]
    }
  },
  cellLabel: {
    backgroundColor: colors.grey[50]
  },
  modelName: {
    display: 'block',
    maxWidth: 450,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'help'
  },
  commentsContainer: {
    padding: theme.spacing(3)
  }
}));

const OptimalAccuracies = props => {
  const { open, onClose, optimalAccuracies } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader title="Optimal Accuracies" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Accuracy Sum</TableCell>
                  <TableCell>Object Present</TableCell>
                  <TableCell>No Object</TableCell>
                  {optimalAccuracies[0].biasAdjustments.map(
                    (biasAdjustment, i) => (
                      <TableCell key={i}>Model {i + 1} Sensitivity</TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {optimalAccuracies &&
                  optimalAccuracies.map((accuracy, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <NumberFormat
                          value={accuracy.sumAccuracy}
                          percentage={true}
                          suffix="%"
                          fixedDecimalScale={true}
                          precision={2}
                        />
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          value={accuracy.objectPresentAccuracy}
                          percentage={true}
                          suffix="%"
                          fixedDecimalScale={true}
                          precision={2}
                        />
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          value={accuracy.noObjectAccuracy}
                          percentage={true}
                          suffix="%"
                          fixedDecimalScale={true}
                          precision={2}
                        />
                      </TableCell>
                      {accuracy.biasAdjustments &&
                        accuracy.biasAdjustments.map((biasAdjustment, i) => (
                          <TableCell key={i}>
                            <NumberFormat
                              value={biasAdjustment}
                              percentage={true}
                              suffix="%"
                              fixedDecimalScale={true}
                              precision={2}
                            />
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

OptimalAccuracies.displayName = 'OptimalAccuracies';

OptimalAccuracies.propTypes = {
  participant: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

OptimalAccuracies.defaultProps = {
  open: false
};

export default OptimalAccuracies;
