import * as actionTypes from 'actions';

const defaultSubjectAreaContent = {
  subjectArea: 'RIGHT_SIDE',
  pocketLocation: '',
  objectType: null,
  objectModel: null
};

const initialState = {
  eventId: null,
  event: null,
  s3Files: [],
  s3FilesRequested: false,
  objectTypes: [],
  objectModels: [],
  objectTypeCategories: [],
  subjectAreaContent: defaultSubjectAreaContent,
  defaultSubjectAreaContent,
  tab: 'summary',
  modelSearch: '',
  walkCollectionSearch: '',
  objectPredictions: [],
  walkCollections: [],
  loading: false,
  radarDataImagesGenerating: false
};

const eventDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EVENT_DETAILS_EVENT_ID: {
      return {
        ...state,
        eventId: action.payload,
        loading: false
      };
    }

    case actionTypes.SET_EVENT_DETAILS_EVENT: {
      return {
        ...state,
        event: action.payload,
        loading: false
      };
    }

    case actionTypes.SET_EVENT_DETAILS_EVENT_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case actionTypes.SET_EVENT_DETAILS_WALK_COLLECTIONS: {
      return {
        ...state,
        walkCollections: action.payload
      };
    }

    case actionTypes.SET_EVENT_DETAILS_OBJECT_PREDICTIONS: {
      return {
        ...state,
        objectPredictions: action.payload
      };
    }

    case actionTypes.SET_EVENT_DETAILS_TAB: {
      return {
        ...state,
        tab: action.payload
      };
    }

    case actionTypes.SET_EVENT_DETAILS_MODEL_SEARCH: {
      return {
        ...state,
        modelSearch: action.payload
      };
    }

    case actionTypes.SET_EVENT_DETAILS_WALK_COLLECTION_SEARCH: {
      return {
        ...state,
        walkCollectionSearch: action.payload
      };
    }

    case actionTypes.FIND_SESSION_EVENT_LIST_DETAILS_EVENT_FULFILLED: {
      return {
        ...state,
        event: action.payload.data.result
      };
    }

    case actionTypes.CLEAR_EVENT_DETAILS_EVENT: {
      return {
        ...state,
        eventId: initialState.eventId,
        event: initialState.event,
        s3FilesRequested: initialState.s3FilesRequested
      };
    }

    case actionTypes.EVENT_DETAILS_SAC_VALUE_UPDATE: {
      return {
        ...state,
        subjectAreaContent: {
          ...state.subjectAreaContent,
          [action.payload.field]: action.payload.value
        }
      };
    }

    case actionTypes.SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED: {
      return {
        ...state,
        event: action.payload.data.result,
        subjectAreaContent: {
          ...initialState.subjectAreaContent,
          saved: true
        }
      };
    }

    case actionTypes.DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED: {
      return {
        ...state,
        event: action.payload.data.result
      };
    }

    case actionTypes.UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER_FULFILLED: {
      return {
        ...state,
        event: action.payload.data.result
      };
    }

    case actionTypes.UPDATE_EVENT_VALIDATION_STATUS_FULFILLED: {
      return {
        ...state,
        event: action.payload.data.result
      };
    }

    case actionTypes.UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE_FULFILLED: {
      return {
        ...state,
        event: action.payload.data.result
      };
    }

    case actionTypes.UPDATE_EVENT_ANNOTATED_PARTICIPANT_AGE_GROUP_FULFILLED: {
      return {
        ...state,
        event: action.payload.data.result
      };
    }

    case actionTypes.TOGGLE_EVENT_SPECIAL_INTEREST_FULFILLED: {
      return {
        ...state,
        event: action.payload.data.result
      };
    }

    case actionTypes.SET_EVENT_DETAILS_SAC_SAVED: {
      return {
        ...state,
        subjectAreaContent: {
          ...state.subjectAreaContent,
          saved: action.payload.saved
        }
      };
    }

    case actionTypes.FIND_OBJECT_TYPES_PENDING: {
      return {
        ...state,
        objectTypes: initialState.objectTypes
      };
    }

    case actionTypes.FIND_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data.result
      };
    }

    case actionTypes.FIND_OBJECT_MODELS_PENDING: {
      return {
        ...state,
        objectModels: initialState.objectModels
      };
    }

    case actionTypes.FIND_OBJECT_MODELS_FULFILLED: {
      return {
        ...state,
        objectModels: action.payload.data.result
      };
    }

    case actionTypes.EVENT_DETAILS_SAC_ADD: {
      const additionalItemsSetup = { ...state.additionalItemsSetup };
      const subjectAreaContents = additionalItemsSetup.subjectAreaContents.slice();

      subjectAreaContents.splice(
        additionalItemsSetup.subjectAreaContents.length,
        0,
        { ...initialState.defaultSubjectAreaContent }
      );
      additionalItemsSetup.subjectAreaContents = subjectAreaContents;

      return {
        ...state,
        additionalItemsSetup: additionalItemsSetup
      };
    }

    case actionTypes.SAVE_OBJECT_TYPE_FULFILLED: {
      const objectTypes = state.objectTypes.slice();

      if (action.payload.data.success) {
        objectTypes.push(action.payload.data.result);
      }

      return {
        ...state,
        objectTypes
      };
    }

    case actionTypes.SAVE_OBJECT_MODEL_FULFILLED: {
      const objectModels = state.objectModels.slice();

      if (action.payload.data.success) {
        objectModels.push(action.payload.data.result);
      }

      return {
        ...state,
        objectModels
      };
    }

    case actionTypes.ADD_COMMENT_FULFILLED:
    case actionTypes.SAVE_COMMENT_FULFILLED:
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      return {
        ...state,
        event: {
          ...state.event,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        }
      };
    }

    case actionTypes.GENERATE_WALK_RADAR_DATA_IMAGES_PENDING: {
      return {
        ...state,
        radarDataImagesGenerating: true
      };
    }

    case actionTypes.GENERATE_WALK_RADAR_DATA_IMAGES_FULFILLED: {
      return {
        ...state,
        radarDataImagesGenerating: false,
        event: action.payload.data.success
          ? action.payload.data.result
          : state.event
      };
    }

    default: {
      return state;
    }
  }
};

export default eventDetailsReducer;
