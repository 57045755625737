import ws from '../utils/ws';

export const FIND_TRAINING_VIDEO_TRAINING = 'FIND_TRAINING_VIDEO_TRAINING';
export const FIND_TRAINING_VIDEO_TRAINING_PENDING =
  'FIND_TRAINING_VIDEO_TRAINING_PENDING';
export const FIND_TRAINING_VIDEO_TRAINING_FULFILLED =
  'FIND_TRAINING_VIDEO_TRAINING_FULFILLED';

export const ENCODE_TRAINING_WEB_VIDEOS = 'ENCODE_TRAINING_WEB_VIDEOS';
export const ENCODE_TRAINING_WEB_VIDEOS_PENDING =
  'ENCODE_TRAINING_WEB_VIDEOS_PENDING';
export const ENCODE_TRAINING_WEB_VIDEOS_FULFILLED =
  'ENCODE_TRAINING_WEB_VIDEOS_FULFILLED';

export const FIND_TRAINING_WEB_VIDEOS = 'FIND_TRAINING_WEB_VIDEOS';
export const FIND_TRAINING_WEB_VIDEOS_PENDING =
  'FIND_TRAINING_WEB_VIDEOS_PENDING';
export const FIND_TRAINING_WEB_VIDEOS_FULFILLED =
  'FIND_TRAINING_WEB_VIDEOS_FULFILLED';

export const findTrainingVideoTraining = trainingId => dispatch =>
  dispatch({
    type: FIND_TRAINING_VIDEO_TRAINING,
    payload: ws.get('/trainings/' + trainingId)
  });

export const encodeTrainingWebVideos = trainingId => dispatch =>
  dispatch({
    type: ENCODE_TRAINING_WEB_VIDEOS,
    payload: ws.get('/trainings/' + trainingId + '/web_encode_videos')
  });

export const findTrainingWebVideos = trainingId => dispatch =>
  dispatch({
    type: FIND_TRAINING_WEB_VIDEOS,
    payload: ws.get('/trainings/' + trainingId + '/web_videos/')
  });
