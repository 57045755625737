import ws from '../utils/ws';

/* Actions */

export const cellRadarPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: CELL_RADARS_PAGINATED_LIST,
    payload: ws.get(`/cells/radars/page/${pageNumber}/rows/${pageSize}`)
  });

export const cellRadarValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CELL_RADAR_VALUE_UPDATE,
    payload: values
  });

export const cellRadarValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CELL_RADAR_VALUE_RESET
  });

export const saveCellRadar = cellRadar => dispatch =>
  dispatch({
    type: SAVE_CELL_RADAR,
    payload: ws.post('/cells/radars/', cellRadar)
  });

export const deleteCellRadar = cellRadar => dispatch =>
  dispatch({
    type: DELETE_CELL_RADAR,
    payload: ws.post(`/cells/radars/${cellRadar.id}/delete`)
  });

export const editCellRadar = id => dispatch =>
  dispatch({
    type: EDIT_CELL_RADAR,
    payload: ws.get(`/cells/radars/${id}`)
  });

export const findCellRadars = cellId => dispatch =>
  dispatch({
    type: FIND_CELL_RADARS,
    payload: ws.get(`/cells/${cellId}/radars/`)
  });

export const findCellRadar = id => dispatch =>
  dispatch({
    type: FIND_CELL_RADAR,
    payload: ws.get(`/cells/radars/${id}`)
  });

export const clearCellRadarDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_CELL_RADAR_DELETE_MESSAGE
  });

/* Constants */

export const CELL_RADARS_PAGINATED_LIST = 'CELL_RADARS_PAGINATED_LIST';
export const CELL_RADARS_PAGINATED_LIST_PENDING =
  'CELL_RADARS_PAGINATED_LIST_PENDING';
export const CELL_RADARS_PAGINATED_LIST_FULFILLED =
  'CELL_RADARS_PAGINATED_LIST_FULFILLED';

export const CREATE_CELL_RADAR_VALUE_UPDATE = 'CREATE_CELL_RADAR_VALUE_UPDATE';
export const CREATE_CELL_RADAR_VALUE_RESET = 'CREATE_CELL_RADAR_VALUE_RESET';

export const SAVE_CELL_RADAR = 'SAVE_CELL_RADAR';
export const SAVE_CELL_RADAR_FULFILLED = 'SAVE_CELL_RADAR_FULFILLED';

export const DELETE_CELL_RADAR = 'DELETE_CELL_RADAR';
export const DELETE_CELL_RADAR_PENDING = 'DELETE_CELL_RADAR_PENDING';
export const DELETE_CELL_RADAR_FULFILLED = 'DELETE_CELL_RADAR_FULFILLED';

export const EDIT_CELL_RADAR = 'EDIT_CELL_RADAR';
export const EDIT_CELL_RADAR_FULFILLED = 'EDIT_CELL_RADAR_FULFILLED';

export const FIND_CELL_RADARS = 'FIND_CELL_RADARS';
export const FIND_CELL_RADARS_PENDING = 'FIND_CELL_RADARS_PENDING';
export const FIND_CELL_RADARS_FULFILLED = 'FIND_CELL_RADARS_FULFILLED';

export const FIND_CELL_RADAR = 'FIND_CELL_RADAR';
export const FIND_CELL_RADAR_PENDING = 'FIND_CELL_RADAR_PENDING';
export const FIND_CELL_RADAR_FULFILLED = 'FIND_CELL_RADAR_FULFILLED';

export const CLEAR_CELL_RADAR_DELETE_MESSAGE =
  'CLEAR_CELL_RADAR_DELETE_MESSAGE';
