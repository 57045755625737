import * as actionTypes from 'actions';

const initialState = {
  saved: false,
  deleted: false,
  trainingCompatibilityGroup: {},
  validationMessages: {}
};

const trainingCompatibilityGroupCreateReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.SAVE_TRAINING_COMPATIBILITY_GROUP_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        trainingCompatibilityGroup: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.EDIT_TRAINING_COMPATIBILITY_GROUP_FULFILLED: {
      return {
        ...state,
        trainingCompatibilityGroup: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.DELETE_TRAINING_COMPATIBILITY_GROUP_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success
      };
    }

    case actionTypes.CREATE_TRAINING_COMPATIBILITY_GROUP_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_TRAINING_COMPATIBILITY_GROUP_VALUE_UPDATE: {
      return {
        ...state,
        trainingCompatibilityGroup: {
          ...state.trainingCompatibilityGroup,
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default trainingCompatibilityGroupCreateReducer;
