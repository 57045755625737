import * as actionTypes from 'actions';

const initialState = {
  open: false,
  eventDataSets: [],
  tab: 'walk_analysis',
  search: '',
  selectedDataSet: null
};

const selectEventDataSetFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECT_EVENT_DATA_SET_FILTERS_OPEN: {
      return {
        ...state,
        open: action.payload,
        search: initialState.search,
        selectedDataSet: initialState.selectedDataSet
      };
    }

    case actionTypes.SET_SELECT_EVENT_DATA_SET_FILTERS_TAB: {
      return {
        ...state,
        tab: action.payload
      };
    }

    case actionTypes.SET_SELECT_EVENT_DATA_SET_FILTERS_SEARCH: {
      return {
        ...state,
        search: action.payload
      };
    }

    case actionTypes.CLEAR_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET:
    case actionTypes.SET_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET_PENDING: {
      return {
        ...state,
        selectedDataSet: initialState.selectedDataSet
      };
    }

    case actionTypes.SET_SELECT_EVENT_DATA_SET_FILTERS_SELECTED_DATA_SET_FULFILLED: {
      const dataSet = action.payload.data.result;

      if (dataSet.filters) {
        dataSet.filters
          .filter(filter => filter.containsFilters)
          .forEach(filter => (filter.selected = true));
        dataSet.selectedFilterCount = dataSet.filters.filter(
          filter => filter.selected
        ).length;
      } else {
        dataSet.selectedFilterCount = 0;
      }

      return {
        ...state,
        selectedDataSet: dataSet
      };
    }

    case actionTypes.SET_SELECT_EVENT_DATA_SET_FILTERS_FILTER_SELECTED: {
      const { filterIndex, selected } = action.payload;
      const filters = state.selectedDataSet.filters.slice();

      filters[filterIndex] = {
        ...filters[filterIndex],
        selected: selected
      };

      return {
        ...state,
        selectedDataSet: {
          ...state.selectedDataSet,
          selectedFilterCount: filters.filter(filter => filter.selected).length,
          filters: filters
        }
      };
    }

    case actionTypes.FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS_PENDING: {
      return {
        ...state,
        eventDataSets: initialState.eventDataSets,
        loading: true
      };
    }

    case actionTypes.FIND_SELECT_EVENTS_DATA_SET_FILTERS_DATA_SETS_FULFILLED: {
      return {
        ...state,
        eventDataSets: action.payload.data.result,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default selectEventDataSetFiltersReducer;
