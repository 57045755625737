/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const CellMasterVersionStatus = props => {
  const { masterVersion, ...rest } = props;

  const statusColors = {
    IN_DEVELOPMENT: colors.blue[600],
    PENDING_TESTING: colors.teal[600],
    TESTING_COMPLETE: colors.purple[600],
    PENDING_APPROVAL: colors.orange[600],
    APPROVED: colors.green[600]
  };

  return null;
};

CellMasterVersionStatus.propTypes = {
  cell: PropTypes.object
};

export default CellMasterVersionStatus;
