import React from 'react';
import {
  Modal,
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  CardActions,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { POCKET_LOCATIONS, SUBJECT_AREAS } from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SubjectAreaContentsListModal = props => {
  const classes = useStyles();

  const { open, onClose, subjectAreaContents } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Card className={classes.root}>
        <CardHeader title="Subject Area Contents" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Area</TableCell>
                  <TableCell>Pocket</TableCell>
                  <TableCell>Object</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subjectAreaContents &&
                  subjectAreaContents.map((subjectAreaContent, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {SUBJECT_AREAS[subjectAreaContent.subjectArea]}
                      </TableCell>
                      <TableCell>
                        {POCKET_LOCATIONS[subjectAreaContent.pocketLocation]}
                      </TableCell>
                      <TableCell>
                        {subjectAreaContent.objectType?.name}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={onClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default SubjectAreaContentsListModal;
