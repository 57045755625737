import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components';
import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer'
  }
}));

const CommentCount = props => {
  const { comments, onClick } = props;
  const classes = useStyles();

  return (
    <div>
      <Label
        className={onClick ? classes.link : ''}
        onClick={onClick}
        color={colors.blue[600]}>
        {comments ? comments.length : 0}
      </Label>
    </div>
  );
};

CommentCount.propTypes = {};

export default CommentCount;
