import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Comments } from 'components';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const CommentsModal = props => {
  const {
    open,
    onClose,
    entityType,
    entityId,
    comments,
    className,
    loading,
    editableRight,
    ...rest
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!open || loading) {
    return null;
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title="Comments" />
        <Divider />
        <CardContent>
          <Comments
            editableRight={editableRight}
            entityType={entityType}
            entityId={entityId}
            comments={comments}
          />
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CommentsModal.defaultProps = {
  open: false
};

export default CommentsModal;
