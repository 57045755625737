import * as actionTypes from 'actions';

const initialState = {
  open: false,
  cellComponent: null,
  search: ''
};

const cellComponentVersionSelectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECT_CELL_COMPONENT_VERSION_OPEN: {
      return {
        ...state,
        open: action.payload,
        search: initialState.search
      };
    }

    case actionTypes.SET_SELECT_CELL_COMPONENT_VERSION_COMPONENT: {
      return {
        ...state,
        cellComponent: action.payload
      };
    }

    case actionTypes.SET_SELECT_CELL_COMPONENT_VERSION_SEARCH: {
      return {
        ...state,
        search: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default cellComponentVersionSelectReducer;
