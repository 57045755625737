import * as actionTypes from 'actions';
import { DEFAULT_MODEL_CONFIG_SETTINGS_JSON } from 'common/constants';

const initialState = {
  saved: false,
  saving: false,
  savedModelConfiguration: {},
  modelConfiguration: {
    id: null,
    alias: '',
    documentationLink: '',
    eventModelWorkerStatus: 'ACTIVE',
    settingsJson: DEFAULT_MODEL_CONFIG_SETTINGS_JSON
  },
  validationMessages: {},
  uploadValidationMessages: {},
  modelFileUploading: false,
  eventDataSets: []
};

const modelConfigurationCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_MODEL_CONFIGURATION_PENDING: {
      return {
        ...state,
        saving: true
      };
    }

    case actionTypes.SAVE_MODEL_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedModelConfiguration: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages,
        saving: false
      };
    }

    case actionTypes.EDIT_MODEL_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        modelConfiguration: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_MODEL_CONFIGURATION_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_MODEL_CONFIGURATION_VALUE_UPDATE: {
      return {
        ...state,
        modelConfiguration: {
          ...state.modelConfiguration,
          ...action.payload
        }
      };
    }

    case actionTypes.UPLOAD_MODEL_FILE_PENDING: {
      return {
        ...state,
        modelFileUploading: true
      };
    }

    case actionTypes.UPLOAD_MODEL_FILE_FULFILLED: {
      let result = {
        modelFileUploading: false,
        uploadValidationMessages: action.payload.data.validationMessages
      };
      if (action.payload.data.success) {
        result = {
          ...state,
          ...result,
          modelConfiguration: {
            ...state.modelConfiguration,

            id: action.payload.data.result.id,
            modelFileSize: action.payload.data.result.modelFileSize,
            modelFileName: action.payload.data.result.modelFileName,
            modelFileBucket: action.payload.data.result.modelFileBucket,
            modelFileObjectKey: action.payload.data.result.modelFileObjectKey,
            modelFileMD5Checksum:
              action.payload.data.result.modelFileMD5Checksum
          }
        };
      } else {
        result = {
          ...state,
          ...result
        };
      }

      return result;
    }

    case actionTypes.FIND_EVENT_DATA_SETS_PENDING: {
      return {
        ...state,
        eventDataSets: initialState.eventDataSets
      };
    }

    case actionTypes.FIND_EVENT_DATA_SETS_FULFILLED: {
      return {
        ...state,
        eventDataSets: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default modelConfigurationCreateReducer;
