import * as actionTypes from 'actions';

const initialState = {
  portalUserLoading: false,
  portalUser: {
    customers: [],
    createdBy: {},
    lastUpdatedBy: {}
  }
};

const participantDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_PORTAL_USER_PENDING: {
      return {
        ...initialState,
        portalUserLoading: true
      };
    }

    case actionTypes.FIND_PORTAL_USER_FULFILLED: {
      return {
        ...state,
        portalUserLoading: false,
        portalUser: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default participantDetailsReducer;
