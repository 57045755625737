import React from 'react';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  Divider,
  CardActions,
  Button,
  colors
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textContent: {
    whiteSpace: 'pre',
    fontFamily: 'monospace',
    background: colors.blueGrey[50],
    padding: theme.spacing(3),
    height: 500,
    maxHeight: 500,
    overflow: 'scroll'
  }
}));

const TextContentModal = props => {
  const classes = useStyles();

  const { open, onClose, text, title } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Card className={classes.root}>
        <CardHeader title={title} />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.textContent}>{text}</div>
          </PerfectScrollbar>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={onClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default TextContentModal;
