import * as actionTypes from 'actions';

const initialState = {
  created: false,
  actionType: null,
  values: {
    participant: {},
    weight: '',
    height: '',
    modelName: '',
    cellName: '',
    clothingType: '',
    setupWalkCount: '',
    radarCoversInstalled: false,
    setups: [],
    creationComment: '',
    heightFeetOverridden: false,
    heightInchesOverridden: false,
    weightOverridden: false,
    analysisExcluded: false,
    scheduledTime: null,
    scheduledDurationMinutes: 15,
    cells: []
  },
  overrideSetup: {
    index: 1,
    entryFoot: '',
    setupWalkCount: '',
    subjectAreaContents: []
  },
  additionalItemsSetup: {
    subjectAreaContents: []
  },
  scenario: {
    setups: []
  },
  defaultSubjectAreaContent: {
    subjectArea: 'RIGHT_SIDE',
    pocketLocation: '',
    objectType: null,
    objectModel: null
  },
  participants: [],
  cellsLoading: false,
  cells: [],
  masterVersionsLoading: false,
  masterVersions: [],
  createdSessions: [],
  validationMessages: {},
  referenceDataSetupSubectAreaContent: null,
  loading: false,
  sessionSaving: false
};

const setupScenarioSessionCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SESSION_VALUE_UPDATE: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    }

    case actionTypes.SET_SESSION_ADDITIONAL_SUBJECT_AREA_CONTENTS: {
      return {
        ...state,
        additionalItemsSetup: {
          subjectAreaContents: action.payload
        }
      };
    }

    case actionTypes.CREATE_TEST_SESSION_SETUP_OVERRIDE_VALUE_UPDATE: {
      return {
        ...state,
        values: {
          ...state.values,
          overrideSetup: {
            ...state.values.overrideSetup,
            ...action.payload
          }
        }
      };
    }

    case actionTypes.CREATE_TEST_SESSION_SETUP_VALUE_UPDATE: {
      const { setupIndex, field, value } = action.payload;

      state.values.setups[setupIndex - 1] = {
        ...state.values.setups[setupIndex - 1],
        [field]: value
      };

      return {
        ...state,
        values: {
          ...state.values,
          setups: state.values.setups
        }
      };
    }

    case actionTypes.CREATE_SESSION_SELECT_CELL: {
      const cells = state.values.cells.slice();
      const cell = action.payload;
      const index = cells.map(c => c.id).indexOf(cell.id);

      if (index > -1) {
        cells.splice(index, 1);
      } else {
        cells.push(cell);
      }

      return {
        ...state,
        values: {
          ...state.values,
          cells: cells
        }
      };
    }

    case actionTypes.CREATE_SESSION_VALUE_RESET: {
      return {
        ...initialState,
        values: initialState.values,
        overrideSetup: initialState.overrideSetup,
        additionalItemsSetup: initialState.additionalItemsSetup
      };
    }

    case actionTypes.SET_CREATE_SESSION_SCENARIO_PENDING: {
      return {
        ...state,
        scenario: {
          setups: []
        }
      };
    }

    case actionTypes.SAVE_OBJECT_TYPE_FULFILLED: {
      let newState;
      if (state.referenceDataSetupSubectAreaContent) {
        const additionalItemsSetup = { ...state.additionalItemsSetup };

        const subjectAreaContent =
          additionalItemsSetup.subjectAreaContents[
            state.referenceDataSetupSubectAreaContent.subjectAreaContentIndex
          ];
        subjectAreaContent.objectType = action.payload.data.result;

        newState = {
          ...state,
          additionalItemsSetup: additionalItemsSetup
        };
      } else {
        newState = state;
      }

      return newState;
    }

    case actionTypes.SAVE_OBJECT_MODEL_FULFILLED: {
      let newState;
      if (state.referenceDataSetupSubectAreaContent) {
        const additionalItemsSetup = { ...state.additionalItemsSetup };

        const subjectAreaContent =
          additionalItemsSetup.subjectAreaContents[
            state.referenceDataSetupSubectAreaContent.subjectAreaContentIndex
          ];
        subjectAreaContent.objectModel = action.payload.data.result;

        newState = {
          ...state,
          additionalItemsSetup: additionalItemsSetup
        };
      } else {
        newState = state;
      }

      return newState;
    }

    case actionTypes.FIND_PARTICIPANTS_FULFILLED: {
      return {
        ...state,
        participants: action.payload.data.result
      };
    }

    case actionTypes.SET_CREATE_SESSION_SCENARIO_FULFILLED: {
      return {
        ...state,
        scenario: action.payload.data.result
      };
    }

    case actionTypes.SAVE_SESSION_PENDING: {
      return {
        ...state,
        sessionSaving: true
      };
    }

    case actionTypes.SAVE_SESSION_FULFILLED: {
      const success = action.payload.data.success;

      return {
        ...state,
        created: success,
        actionType: action.payload.data.actionType,
        createdSessions: success ? action.payload.data.result : [],
        validationMessages: action.payload.data.validationMessages,
        sessionSaving: false
      };
    }

    case actionTypes.EDIT_SESSION_FULFILLED: {
      return {
        ...state,
        loading: false,
        values: {
          ...state.values,
          ...action.payload.data.result,
          cells: [action.payload.data.result.cell]
        },
        additionalItemsSetup: action.payload.data.result.additionalItemsSetup
          ? action.payload.data.result.additionalItemsSetup
          : initialState.additionalItemsSetup
      };
    }

    case actionTypes.EDIT_SESSION_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CREATE_TEST_SESSION_SETUP_UPDATE: {
      return {
        ...state,
        overrideSetup: {
          ...state.overrideSetup,
          ...action.payload
        }
      };
    }

    case actionTypes.CREATE_TEST_SESSION_SINGLE_SETUP_UPDATE: {
      const { setupIndex, field, value } = action.payload;

      const setups = state.values.setups.slice();

      setups[setupIndex - 1] = {
        ...setups[setupIndex - 1],
        [field]: value
      };

      return {
        ...state,
        values: {
          ...state.values,
          setups: setups
        }
      };
    }

    case actionTypes.CREATE_TEST_SESSION_SETUP_SAC_ADD: {
      const overrideSetup = { ...state.overrideSetup };
      const subjectAreaContents = overrideSetup.subjectAreaContents.slice();

      subjectAreaContents.splice(overrideSetup.subjectAreaContents.length, 0, {
        ...initialState.defaultSubjectAreaContent
      });
      overrideSetup.subjectAreaContents = subjectAreaContents;

      return {
        ...state,
        overrideSetup: overrideSetup
      };
    }

    case actionTypes.CREATE_TEST_SESSION_SETUP_SAC_REMOVE: {
      const overrideSetup = { ...state.overrideSetup };
      const subjectAreaContents = overrideSetup.subjectAreaContents.slice();

      subjectAreaContents.splice(action.payload.subjectAreaContent.index, 1);
      overrideSetup.subjectAreaContents = subjectAreaContents;

      return {
        ...state,
        overrideSetup: overrideSetup
      };
    }

    case actionTypes.CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_ADD: {
      const additionalItemsSetup = { ...state.additionalItemsSetup };
      const subjectAreaContents = additionalItemsSetup.subjectAreaContents.slice();

      subjectAreaContents.splice(
        additionalItemsSetup.subjectAreaContents.length,
        0,
        { ...initialState.defaultSubjectAreaContent }
      );
      additionalItemsSetup.subjectAreaContents = subjectAreaContents;

      return {
        ...state,
        additionalItemsSetup: additionalItemsSetup
      };
    }

    case actionTypes.CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_REMOVE: {
      const additionalItemsSetup = { ...state.additionalItemsSetup };
      const subjectAreaContents = additionalItemsSetup.subjectAreaContents.slice();

      subjectAreaContents.splice(action.payload.subjectAreaContent.index, 1);
      additionalItemsSetup.subjectAreaContents = subjectAreaContents;

      return {
        ...state,
        additionalItemsSetup: additionalItemsSetup
      };
    }

    case actionTypes.SET_TEST_SESSION_REFERENCE_DATA_SETUP_SAC: {
      return {
        ...state,
        referenceDataSetupSubectAreaContent: {
          ...action.payload
        }
      };
    }

    case actionTypes.CREATE_TEST_SESSION_SETUP_SAC_UPDATE: {
      const overrideSetup = state.overrideSetup;
      const subjectAreaContents = overrideSetup.subjectAreaContents.slice();

      let subjectAreaContent =
        subjectAreaContents[action.payload.subjectAreaContentIndex];

      subjectAreaContents[action.payload.subjectAreaContentIndex] = {
        ...subjectAreaContent,
        ...action.payload
      };

      return {
        ...state,
        overrideSetup: {
          ...overrideSetup,
          subjectAreaContents: subjectAreaContents
        }
      };
    }

    case actionTypes.CREATE_TEST_SESSION_ADDITIONAL_ITEMS_SETUP_SAC_UPDATE: {
      const additionalItemsSetup = state.additionalItemsSetup;
      const subjectAreaContents = additionalItemsSetup.subjectAreaContents.slice();

      let subjectAreaContent =
        subjectAreaContents[action.payload.subjectAreaContentIndex];

      subjectAreaContents[action.payload.subjectAreaContentIndex] = {
        ...subjectAreaContent,
        ...action.payload
      };

      return {
        ...state,
        additionalItemsSetup: {
          ...additionalItemsSetup,
          subjectAreaContents: subjectAreaContents
        }
      };
    }

    case actionTypes.FIND_CELLS_PENDING: {
      return {
        ...state,
        cellsLoading: true
      };
    }

    case actionTypes.FIND_CELLS_FULFILLED: {
      return {
        ...state,
        cells: action.payload.data.result,
        cellsLoading: false
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_PENDING: {
      return {
        ...state,
        masterVersionsLoading: true
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_FULFILLED: {
      return {
        ...state,
        masterVersions: action.payload.data.result,
        masterVersionsLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default setupScenarioSessionCreateReducer;
