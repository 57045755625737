import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Tooltip,
  Link
} from '@material-ui/core';
import {
  CHART_COLORS,
  EVENT_SETTING_COMMIT_HASH,
  GIT_COMMIT_URL
} from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1200,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    '& td': {
      paddingTop: 2,
      paddingBottom: 2
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: theme.spacing(2)
  },
  hashSelect: {
    margin: theme.spacing(2),
    marginBottom: 0
  },
  label: {
    paddingLeft: theme.spacing(1),
    fontSize: '0.8em'
  },
  hashRow: {
    backgroundColor: colors.grey[50]
  },
  hashLabel: {
    fontWeight: 600
  },
  differenceRow: {
    background: colors.orange[50]
  },
  settingLabel: {
    borderLeftWidth: 6,
    borderLeftStyle: 'solid'
  },
  value: {
    maxWidth: 180,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '0.8em'
  },
  settingLink: {
    fontWeight: 600
  }
}));

const SessionEventSettingsModal = props => {
  const { open, session, onClose } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  if (!open || !session.hashedEventSettings) {
    return null;
  }

  const hashes = Object.keys(session.hashedEventSettings);
  const hashedEventSettings = {};
  const uniqueSettings = new Set();

  for (let i = 0; i < hashes.length; i++) {
    const hash = hashes[i];
    hashedEventSettings[hash] = JSON.parse(session.hashedEventSettings[hash]);
    const settings = Object.keys(hashedEventSettings[hash]);
    settings.forEach(s => uniqueSettings.add(s));
  }

  const orderedUniqueSettings = Array.from(uniqueSettings);

  const valueDifferenceSettings = [];

  orderedUniqueSettings.forEach(setting => {
    const settingValues = new Set();
    hashes.forEach(hash => {
      if (hashedEventSettings[hash][setting]) {
        settingValues.add(hashedEventSettings[hash][setting]);
      }
    });

    if (settingValues.size > 1) {
      valueDifferenceSettings.push(setting);
    }
  });

  orderedUniqueSettings.sort((a, b) => {
    let result =
      valueDifferenceSettings.indexOf(b) - valueDifferenceSettings.indexOf(a); // Sort value difference settings to top of list
    if (result === 0) {
      result = a.toLowerCase().localeCompare(b.toLowerCase());
    }
    return result;
  });

  const settingPrefixes = [];
  orderedUniqueSettings.forEach(setting => {
    const prefix = setting.substring(0, setting.indexOf('.'));
    if (settingPrefixes.indexOf(prefix) < 0) {
      settingPrefixes.push(prefix);
    }
  });

  const settingPrefixColors = {};
  settingPrefixes.forEach((prefix, i) => {
    settingPrefixColors[prefix] = CHART_COLORS[i];
  });

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader title="Walk Settings" />
        <Divider />
        <CardContent className={classes.content}>
          <Card>
            <PerfectScrollbar>
              <Table>
                <TableBody>
                  <TableRow className={classes.hashRow}>
                    <TableCell
                      className={clsx(classes.label, classes.hashLabel)}>
                      Setting
                    </TableCell>
                    {hashes.map(hash => (
                      <TableCell
                        key={hash}
                        className={clsx(classes.label, classes.hashLabel)}>
                        {hash}
                      </TableCell>
                    ))}
                  </TableRow>

                  {orderedUniqueSettings.map(setting => (
                    <TableRow
                      key={setting}
                      className={
                        valueDifferenceSettings.indexOf(setting) > -1
                          ? classes.differenceRow
                          : ''
                      }>
                      <TableCell
                        className={clsx(classes.settingLabel, classes.label)}
                        style={{
                          borderLeftColor:
                            valueDifferenceSettings.indexOf(setting) !== -1
                              ? colors.orange[50]
                              : settingPrefixColors[
                                  setting.substring(0, setting.indexOf('.'))
                                ]
                        }}>
                        {setting}
                      </TableCell>
                      {hashes.map(hash => (
                        <TableCell
                          key={hash + setting}
                          className={classes.value}>
                          {hashedEventSettings[hash][setting] && (
                            <>
                              {setting === EVENT_SETTING_COMMIT_HASH ? (
                                <Link
                                  className={classes.settingLink}
                                  color="inherit"
                                  rel="noopener"
                                  target="_blank"
                                  href={`${GIT_COMMIT_URL}${hashedEventSettings[hash][setting]}`}>
                                  {hashedEventSettings[hash][setting]}
                                </Link>
                              ) : (
                                <Tooltip
                                  placement="top-start"
                                  title={hashedEventSettings[hash][setting]}>
                                  <span>
                                    {hashedEventSettings[hash][setting]}
                                  </span>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Card>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SessionEventSettingsModal.defaultProps = {
  open: false
};

export default SessionEventSettingsModal;
