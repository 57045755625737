import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, colors, Typography } from '@material-ui/core';
import { NumberFormat, Label, OptimalAccuracies } from 'components';
import clsx from 'clsx';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import ws from 'utils/ws';

const useStyles = makeStyles(theme => ({
  sumAccuracyLabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  sensitivityRangeLabel: {
    marginLeft: theme.spacing(1)
  },
  accuracySumBias: {
    marginTop: theme.spacing(1) * 0.75,
    backgroundColor: colors.blueGrey[50],
    display: 'inline-flex',
    borderRadius: 4,
    backgroundColor: colors.grey[200],
    paddingLeft: 12,
    paddingRight: 12,
    height: 32,
    alignItems: 'center',
    whiteSpace: 'nowrap'
  },
  biasRangeSeparator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  calculationTime: {
    marginLeft: theme.spacing(1)
  },
  downloadButton: {
    fontSize: 11,
    padding: '1px 8px 0 8px'
  }
}));

const OptimalAccuracySumBias = props => {
  const { optimalAccuracySumBias, accuraciesDownloadUri, className } = props;

  const [optimalAccuraciesOpen, setOptimalAccuraciesOpen] = useState(false);
  const classes = useStyles();

  const handleDownloadAccuracies = () => {
    window.location = ws.url(accuraciesDownloadUri);
  };

  return !optimalAccuracySumBias ? null : (
    <>
      <div className={clsx(classes.accuracySumBias, className)}>
        <Typography color="textSecondary" component="p" variant="overline">
          Torso Max Accuracy Sum
        </Typography>

        <Label color={colors.blue[600]} className={classes.sumAccuracyLabel}>
          <NumberFormat
            value={optimalAccuracySumBias.maxAccuracySum}
            percentage={true}
            suffix="%"
            fixedDecimalScale={true}
            precision={2}
          />
        </Label>

        {optimalAccuracySumBias.optimalAccuraciesSize > 0 && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.downloadButton}
            onClick={handleDownloadAccuracies}>
            Download Thresholds
          </Button>
        )}

        {optimalAccuracySumBias.calculationTime > 2000 && (
          <Typography
            className={classes.calculationTime}
            color="textSecondary"
            component="p"
            variant="overline">
            Taking {(optimalAccuracySumBias.calculationTime / 1000).toFixed(2)}{' '}
            Seconds
          </Typography>
        )}
      </div>

      <OptimalAccuracies
        open={optimalAccuraciesOpen}
        onClose={() => setOptimalAccuraciesOpen(false)}
        optimalAccuracies={optimalAccuracySumBias.optimalAccuracies}
      />
    </>
  );
};

OptimalAccuracySumBias.propTypes = {
  value: PropTypes.number,
  suffix: PropTypes.string,
  prefix: PropTypes.string
};

export default OptimalAccuracySumBias;
