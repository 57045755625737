/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { NumberFormat } from 'components';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const EventObjectPercentStatus = props => {
  const { event } = props;
  const predictionCorrect =
    event === null || event.objectPredictionCorrect === null
      ? null
      : event.objectPredictionCorrect;

  return (
    <div>
      {predictionCorrect !== null && (
        <Label
          color={predictionCorrect ? colors.green[600] : colors.orange[600]}>
          <NumberFormat value={event && event.score} percentage suffix={'%'} />
        </Label>
      )}
    </div>
  );
};

EventObjectPercentStatus.propTypes = {
  event: PropTypes.object.isRequired
};

export default EventObjectPercentStatus;
