import React from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import clsx from 'clsx';
import { Label } from 'components';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Typography,
  LinearProgress
} from '@material-ui/core';
import {
  CHART_COLORS,
  EVENT_SETTING_COMMIT_HASH,
  GIT_COMMIT_URL
} from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 750,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    '& td': {
      paddingTop: 2,
      paddingBottom: 2
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    padding: theme.spacing(2)
  },
  errorCard: {
    marginTop: theme.spacing(2),
    padding: 10,
    background: colors.grey[100]
  },
  error: {
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
    fontSize: 12
  },
  errorDivider: {
    backgroundColor: colors.grey[300],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    marginTop: theme.spacing(2)
  },
  tableLabel: {
    width: 160
  }
}));

const ModelRunResponseModal = props => {
  const { open, runResponse, loading, onClose } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <CardHeader title="Model Run Request Response" />
        <Divider />
        {loading && <LinearProgress />}
        <CardContent className={classes.content}>
          {!loading && runResponse ? (
            <>
              <Card>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableLabel}>
                        Response Time
                      </TableCell>
                      <TableCell>
                        {moment(runResponse.responseTime).format(
                          'MMM Do YYYY, h:mm a'
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
              {runResponse.errorMessage && (
                <>
                  <Typography className={classes.sectionHeading} variant="h6">
                    Process Error
                  </Typography>
                  <Card className={classes.errorCard}>
                    <div className={classes.error}>
                      {runResponse.errorMessage.replace(/(<([^>]+)>)/gi, '')}
                    </div>
                  </Card>
                </>
              )}

              {runResponse.eventErrors && (
                <>
                  <Typography className={classes.sectionHeading} variant="h6">
                    Walk Errors
                  </Typography>
                  {runResponse.eventErrors.map((error, i) => (
                    <Card className={classes.errorCard} key={i}>
                      <Typography
                        component="h2"
                        gutterBottom
                        variant="overline">
                        Walk ID: {error.eventId}
                      </Typography>
                      <Divider className={classes.errorDivider} />
                      <div className={classes.error}>
                        {error.message &&
                          error.message.replace(/(<([^>]+)>)/gi, '')}
                      </div>
                    </Card>
                  ))}
                </>
              )}
            </>
          ) : (
            <Typography component="p">No data available</Typography>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ModelRunResponseModal.defaultProps = {
  open: false
};

export default ModelRunResponseModal;
