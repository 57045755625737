import ws from '../utils/ws';

/* Actions */

export const setSelectModelOpen = open => dispatch =>
  dispatch({
    type: SET_SELECT_MODEL_OPEN,
    payload: open
  });
export const setSelectModelSearch = search => dispatch =>
  dispatch({
    type: SET_SELECT_MODEL_SEARCH,
    payload: search
  });

export const findSelectModelModels = () => dispatch =>
  dispatch({
    type: FIND_SELECT_MODEL_MODELS,
    payload: ws.get(`/model_configurations/`)
  });

export const findSelectModelModelSets = () => dispatch =>
  dispatch({
    type: FIND_SELECT_MODEL_MODEL_SETS,
    payload: ws.get(`/model_sets/`)
  });

export const setSelectModelTab = tab => dispatch =>
  dispatch({
    type: SET_SELECT_MODEL_TAB,
    payload: tab
  });

/* Constants */

export const SET_SELECT_MODEL_OPEN = 'SET_SELECT_MODEL_OPEN';

export const SET_SELECT_MODEL_SEARCH = 'SET_SELECT_MODEL_SEARCH';

export const FIND_SELECT_MODEL_MODELS = 'FIND_SELECT_MODEL_MODELS';
export const FIND_SELECT_MODEL_MODELS_PENDING =
  'FIND_SELECT_MODEL_MODELS_PENDING';
export const FIND_SELECT_MODEL_MODELS_FULFILLED =
  'FIND_SELECT_MODEL_MODELS_FULFILLED';

export const FIND_SELECT_MODEL_MODEL_SETS = 'FIND_SELECT_MODEL_MODEL_SETS';
export const FIND_SELECT_MODEL_MODEL_SETS_PENDING =
  'FIND_SELECT_MODEL_MODEL_SETS_PENDING';
export const FIND_SELECT_MODEL_MODEL_SETS_FULFILLED =
  'FIND_SELECT_MODEL_MODEL_SETS_FULFILLED';

export const SET_SELECT_MODEL_TAB = 'SET_SELECT_MODEL_TAB';
