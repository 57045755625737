/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const EventPredictionResult = props => {
  const { predictionCorrect } = props;

  if (predictionCorrect === undefined || predictionCorrect == null) return null;

  return (
    <Label
      style={{ width: 70 }}
      color={predictionCorrect ? colors.green[600] : colors.blue[600]}>
      {predictionCorrect ? 'Correct' : 'Incorrect'}
    </Label>
  );
};

EventPredictionResult.propTypes = {
  predictionCorrect: PropTypes.bool
};

export default EventPredictionResult;
