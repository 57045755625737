import ws from '../utils/ws';

/* Actions */

export const portalUserPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: PORTAL_USERS_PAGINATED_LIST,
    payload: ws.get(`/portal_users/page/${pageNumber}/rows/${pageSize}`)
  });

export const findPortalUser = id => dispatch =>
  dispatch({
    type: FIND_PORTAL_USER,
    payload: ws.get(`/portal_users/${id}`)
  });

export const portalUserValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_PORTAL_USER_VALUE_UPDATE,
    payload: values
  });

export const portalUserValueReset = () => dispatch =>
  dispatch({
    type: CREATE_PORTAL_USER_VALUE_RESET
  });

export const savePortalUser = portalUser => dispatch =>
  dispatch({
    type: SAVE_PORTAL_USER,
    payload: ws.post('/portal_users/', portalUser)
  });

export const editPortalUser = id => dispatch =>
  dispatch({
    type: EDIT_PORTAL_USER,
    payload: ws.get(`/portal_users/${id}`)
  });

export const deletePortalUser = portalUser => dispatch =>
  dispatch({
    type: DELETE_PORTAL_USER,
    payload: ws.post(`/portal_users/${portalUser.id}/delete`)
  });

/* Constants */

export const PORTAL_USERS_PAGINATED_LIST = 'PORTAL_USERS_PAGINATED_LIST';
export const PORTAL_USERS_PAGINATED_LIST_PENDING =
  'PORTAL_USERS_PAGINATED_LIST_PENDING';
export const PORTAL_USERS_PAGINATED_LIST_FULFILLED =
  'PORTAL_USERS_PAGINATED_LIST_FULFILLED';

export const FIND_PORTAL_USER = 'FIND_PORTAL_USER';
export const FIND_PORTAL_USER_PENDING = 'FIND_PORTAL_USER_PENDING';
export const FIND_PORTAL_USER_FULFILLED = 'FIND_PORTAL_USER_FULFILLED';

export const CREATE_PORTAL_USER_VALUE_UPDATE =
  'CREATE_PORTAL_USER_VALUE_UPDATE';

export const CREATE_PORTAL_USER_VALUE_RESET = 'CREATE_PORTAL_USER_VALUE_RESET';

export const SAVE_PORTAL_USER = 'SAVE_PORTAL_USER';
export const SAVE_PORTAL_USER_PENDING = 'SAVE_PORTAL_USER_PENDING';
export const SAVE_PORTAL_USER_FULFILLED = 'SAVE_PORTAL_USER_FULFILLED';

export const EDIT_PORTAL_USER = 'EDIT_PORTAL_USER';
export const EDIT_PORTAL_USER_PENDING = 'EDIT_PORTAL_USER_PENDING';
export const EDIT_PORTAL_USER_FULFILLED = 'EDIT_PORTAL_USER_FULFILLED';

export const DELETE_PORTAL_USER = 'DELETE_PORTAL_USER';
export const DELETE_PORTAL_USER_PENDING = 'DELETE_PORTAL_USER_PENDING';
export const DELETE_PORTAL_USER_FULFILLED = 'DELETE_PORTAL_USER_FULFILLED';
