/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const CellOpenTicketCount = props => {
  const { cell, ...rest } = props;

  const openCount = cell && cell.openTicketCount ? cell.openTicketCount : 0;
  const closedCount =
    cell && cell.closedTicketCount ? cell.closedTicketCount : 0;

  return (
    <div>
      <Label {...rest} color={colors.blue[600]}>
        {openCount}
      </Label>
      <Label {...rest} style={{ marginLeft: 4 }} color={colors.green[600]}>
        {closedCount}
      </Label>
    </div>
  );
};

CellOpenTicketCount.propTypes = {
  cell: PropTypes.object
};

export default CellOpenTicketCount;
