import ws from '../utils/ws';

/* Actions */

export const resetUploadCellConfiguration = () => dispatch =>
  dispatch({
    type: RESET_UPLOAD_CELL_CONFIGURATION
  });

export const uploadCellConfigurationFile = (cellId, file) => dispatch => {
  const formData = new FormData();
  formData.append('file', file);

  dispatch({
    type: UPLOAD_CELL_CONFIGURATION_FILE,
    payload: ws.postFormData(`/cells/${cellId}/configuration/upload`, formData)
  });
};

export const verifyUploadedConfigurationFile = cellConfigurationId => dispatch => {
  dispatch({
    type: VERIFY_UPLOADED_CELL_CONFIGURATION_FILE,
    payload: ws.post(`/cell_configurations/${cellConfigurationId}/verify`)
  });
};

// export const cellLogEntryPaginatedList = (pageNumber, pageSize) => dispatch =>
//   dispatch({
//     type: CELL_LOG_ENTRIES_PAGINATED_LIST,
//     payload: ws.get(`/cell_log_entries/page/${pageNumber}/rows/${pageSize}`)
//   });

// export const findCellLogEntries = (cellId) => dispatch =>
//   dispatch({
//     type: FIND_CELL_LOG_ENTRIES,
//     payload: ws.get(`/cells/${cellId}/log_entries/`)
//   });

/* Constants */

export const RESET_UPLOAD_CELL_CONFIGURATION =
  'RESET_UPLOAD_CELL_CONFIGURATION';

export const UPLOAD_CELL_CONFIGURATION_FILE = 'UPLOAD_CELL_CONFIGURATION_FILE';
export const UPLOAD_CELL_CONFIGURATION_FILE_PENDING =
  'UPLOAD_CELL_CONFIGURATION_FILE_PENDING';
export const UPLOAD_CELL_CONFIGURATION_FILE_FULFILLED =
  'UPLOAD_CELL_CONFIGURATION_FILE_FULFILLED';

export const VERIFY_UPLOADED_CELL_CONFIGURATION_FILE =
  'VERIFY_UPLOADED_CELL_CONFIGURATION_FILE';
export const VERIFY_UPLOADED_CELL_CONFIGURATION_FILE_PENDING =
  'VERIFY_UPLOADED_CELL_CONFIGURATION_FILE_PENDING';
export const VERIFY_UPLOADED_CELL_CONFIGURATION_FILE_FULFILLED =
  'VERIFY_UPLOADED_CELL_CONFIGURATION_FILE_FULFILLED';
