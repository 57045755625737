import {
  DESELECT_ALL_CELL_SHARED_IDENTIFIERS,
  DESELECT_CELL_SHARED_IDENTIFIER,
  SELECT_ALL_CELL_SHARED_IDENTIFIERS,
  SELECT_CELL_SHARED_IDENTIFIER
} from 'actions';

const initialState = [];

export default function selectedCellSharedIdentifiersReducer(
                 state = initialState,
                 action
               ) {
                 switch (action.type) {
                   case SELECT_CELL_SHARED_IDENTIFIER:
                     return [...state, action.payload];
                   case DESELECT_CELL_SHARED_IDENTIFIER:
                     return state.filter(id => id !== action.payload);
                   case SELECT_ALL_CELL_SHARED_IDENTIFIERS:
                     return action.payload;
                   case DESELECT_ALL_CELL_SHARED_IDENTIFIERS:
                     return [];
                   default:
                     return state;
                 }
               }
