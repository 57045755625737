import * as actionTypes from 'actions';

const initialState = {
  modelConfigurationLoading: false,
  updated: false,
  modelConfiguration: {
    createdBy: {}
  },
  entityPhotos: [],
  sessionsLoading: false,
  sessions: [],
  testingAnalysisOverview: {
    loading: false,
    analysisOverview: {},
    setupAnalysesCount: 0,
    eventCount: 0,
    noObjectEventCount: 0,
    locationEventCounts: {}
  },
  modelConfigurationDetailsSet: false,
  modelPredictionResultUpload: {},
  responseS3File: null,
  modelPredictionResultUploading: false,
  maleBmiCategoryPercentages: [],
  maleBmiCategoryPercentagesLoading: false,
  femaleBmiCategoryPercentages: [],
  femaleBmiCategoryPercentagesLoading: false,
  maleHeightCategoryPercentages: [],
  maleHeightCategoryPercentagesLoading: false,
  femaleHeightCategoryPercentages: [],
  femaleHeightCategoryPercentagesLoading: false
};

const modelConfigurationDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_MODEL_CONFIGURATION_PENDING: {
      return {
        ...initialState,
        modelConfigurationLoading: true
      };
    }

    case actionTypes.FIND_MODEL_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        modelConfigurationLoading: false,
        updated: false,
        modelConfiguration: action.payload.data.result
      };
    }

    case actionTypes.SET_MODEL_PREDICTION_RESULT_UPLOAD_VALUES: {
      return {
        ...state,
        modelPredictionResultUpload: {
          ...state.modelPredictionResultUpload,
          ...action.payload
        }
      };
    }

    case actionTypes.SET_MODEL_PREDICTION_RESULT_UPLOAD_DETAILS: {
      return {
        ...state,
        modelPredictionResultUpload: {
          ...state.modelPredictionResultUpload,
          ...action.payload
        },
        modelConfigurationDetailsSet: true
      };
    }

    case actionTypes.PROCESS_MODEL_PREDICTION_RESULT_UPLOAD_PENDING: {
      return {
        ...state,
        responseS3File: initialState.responseS3File,
        modelPredictionResultUploading: true
      };
    }

    case actionTypes.PROCESS_MODEL_PREDICTION_RESULT_UPLOAD_FULFILLED: {
      return {
        ...state,
        responseS3File: action.payload.data.result,
        modelPredictionResultUploading: false
      };
    }

    case actionTypes.FIND_MODEL_MALE_BMI_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        modelConfiguration: {
          ...state.modelConfiguration,
          maleBmiCategoryPercentages: []
        },
        maleBmiCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_MODEL_MALE_BMI_CATEGORY_PERCENTAGES_FULFILLED: {
      return {
        ...state,
        modelConfiguration: {
          ...state.modelConfiguration,
          maleBmiCategoryPercentages: action.payload.data.result
        },
        maleBmiCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_MODEL_FEMALE_BMI_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        modelConfiguration: {
          ...state.modelConfiguration,
          femaleBmiCategoryPercentages: []
        },
        femaleBmiCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_MODEL_FEMALE_BMI_CATEGORY_PERCENTAGES_FULFILLED: {
      return {
        ...state,
        modelConfiguration: {
          ...state.modelConfiguration,
          femaleBmiCategoryPercentages: action.payload.data.result
        },
        femaleBmiCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_MODEL_MALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        modelConfiguration: {
          ...state.modelConfiguration,
          maleHeightCategoryPercentages: []
        },
        maleHeightCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_MODEL_MALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED: {
      return {
        ...state,
        modelConfiguration: {
          ...state.modelConfiguration,
          maleHeightCategoryPercentages: action.payload.data.result
        },
        maleHeightCategoryPercentagesLoading: false
      };
    }

    case actionTypes.FIND_MODEL_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_PENDING: {
      return {
        ...state,
        modelConfiguration: {
          ...state.modelConfiguration,
          femaleHeightCategoryPercentages: []
        },
        femaleHeightCategoryPercentagesLoading: true
      };
    }

    case actionTypes.FIND_MODEL_FEMALE_HEIGHT_CATEGORY_PERCENTAGES_FULFILLED: {
      return {
        ...state,
        modelConfiguration: {
          ...state.modelConfiguration,
          femaleHeightCategoryPercentages: action.payload.data.result
        },
        femaleHeightCategoryPercentagesLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default modelConfigurationDetailsReducer;
