import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Search } from 'components/SearchBar/components';
import { useSelector, useDispatch } from 'react-redux';
import { ModelConfigurationProcessingStatus } from 'components';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Divider,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Tabs,
  Tab
} from '@material-ui/core';
import {
  findSelectModelModels,
  findSelectModelModelSets,
  setSelectModelOpen,
  setSelectModelSearch,
  setSelectModelTab
} from 'actions';
import { MODEL_SET_CALUCLATION_METHODS } from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 850,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  search: {
    minWidth: 480,
    maxWidth: 480,
    flexBasis: 480
  },
  tableContainer: {
    minHeight: 431,
    maxHeight: 431,
    overflowY: 'auto'
  },
  searchContainer: {
    padding: theme.spacing(2)
  },
  buttonCell: {
    paddingTop: 0,
    paddingBottom: 0,
    width: 100
  },
  buttonExtraSmall: {
    padding: '1px 8px',
    fontSize: '0.8125rem'
  },
  labelCell: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const SelectModelModal = props => {
  const {
    model,
    modelSetsSupported,
    onSelectModel,
    onSelectModelSet,
    excludedModelIds,
    excludedModelSetIds,
    ...rest
  } = props;
  const { models, modelSets, open, loading, tab, search } = useSelector(
    state => state.selectModel
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted && open) {
      dispatch(findSelectModelModels());
      dispatch(findSelectModelModelSets());
    }

    return () => {
      mounted = false;
    };
  }, [open]);

  const findModelsByName = () => {
    return models.filter(model => {
      return (
        (!excludedModelIds || excludedModelIds.indexOf(model.id) < 0) &&
        (search === '' ||
          model.displayName.toUpperCase().indexOf(search.toUpperCase()) !== -1)
      );
    });
  };

  const findModelSetsByName = () => {
    return modelSets.filter(modelSet => {
      return (
        (!excludedModelSetIds ||
          excludedModelSetIds.indexOf(modelSet.id) < 0) &&
        (search === '' ||
          modelSet.name.toUpperCase().indexOf(search.toUpperCase()) !== -1)
      );
    });
  };

  if (!open) return null;

  const searchedModels = findModelsByName(models);
  const searchedModelSets = findModelSetsByName(modelSets);

  const tabs = [
    { value: 'models', label: 'Models' },
    { value: 'modelSets', label: 'Model Sets' }
  ];

  const handleClose = () => {
    dispatch(setSelectModelOpen(false));
  };

  const handleSearchFieldChange = search => {
    dispatch(setSelectModelSearch(search?.trim()));
  };

  const handleSelectModel = model => {
    onSelectModel(model);
    handleClose();
  };

  const handleSelectModelSet = modelSet => {
    onSelectModelSet(modelSet);
    handleClose();
  };

  const handleTabsChange = (event, value) => {
    event.persist && event.persist();
    dispatch(setSelectModelTab(value));
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Card {...rest} className={classes.root}>
        <CardHeader title="Select Model" />
        <Divider />

        <CardContent className={classes.content}>
          <div className={classes.searchContainer}>
            <Grid container spacing={3}>
              <Grid item>
                <Search
                  autoFocus={true}
                  placeholder="Name"
                  value={search}
                  onSearchValueChange={handleSearchFieldChange}
                  className={classes.search}
                  hideSearchButton={true}
                />
              </Grid>
            </Grid>
          </div>
          <Divider />

          {modelSetsSupported && (
            <>
              <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                value={tab}>
                {tabs.map(tab => (
                  <Tab
                    className={classes.tab}
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
              <Divider />
            </>
          )}

          <div className={classes.tableContainer}>
            {tab === 'models' && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '0%' }} />
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchedModels.map(model => (
                    <TableRow key={model.id} hover>
                      <TableCell className={classes.buttonCell}>
                        <Button
                          className={classes.buttonExtraSmall}
                          size="small"
                          variant="outlined"
                          onClick={() => handleSelectModel(model)}>
                          Select
                        </Button>
                      </TableCell>
                      <TableCell>{model.displayName}</TableCell>
                      <TableCell className={classes.labelCell}>
                        <ModelConfigurationProcessingStatus
                          modelConfiguration={model}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {!loading && searchedModels.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3}>No data available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
            {tab === 'modelSets' && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '0%' }} />
                    <TableCell>Name</TableCell>
                    <TableCell>Method</TableCell>
                    <TableCell>Models</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchedModelSets.map(modelSet => (
                    <TableRow key={modelSet.id} hover>
                      <TableCell className={classes.buttonCell}>
                        <Button
                          className={classes.buttonExtraSmall}
                          size="small"
                          variant="outlined"
                          onClick={() => handleSelectModelSet(modelSet)}>
                          Select
                        </Button>
                      </TableCell>
                      <TableCell>{modelSet.name}</TableCell>
                      <TableCell>
                        {
                          MODEL_SET_CALUCLATION_METHODS[
                            modelSet.calculationMethod
                          ]
                        }
                      </TableCell>
                      <TableCell>
                        {modelSet.models
                          .map(
                            m =>
                              m.model.displayName + ' (' + m.weight * 100 + '%)'
                          )
                          .join(', ')}
                      </TableCell>
                    </TableRow>
                  ))}
                  {!loading && searchedModelSets.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3}>No data available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            onClick={() => {
              handleSelectModel(null);
              handleSelectModelSet(null);
            }}
            variant="contained">
            Clear Selection
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

SelectModelModal.displayName = 'SelectModel';

SelectModelModal.propTypes = {
  className: PropTypes.string
};

export default SelectModelModal;
